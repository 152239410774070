/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  Decimal: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds in range [-922337203685, 922337203685]. */
  Seconds: any;
};

export enum ActionType {
  Process = 'PROCESS',
  Complete = 'COMPLETE',
  Cancel = 'CANCEL',
  Non = 'NON'
}

export type Attr = {
  __typename?: 'Attr';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  attrDetails: Array<AttrDetail>;
};

export type AttrDetail = {
  __typename?: 'AttrDetail';
  id: Scalars['Int'];
  value: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type AttrDetailInput = {
  id: Scalars['Int'];
  value: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type AttrInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  entityType: EntityType;
  description?: Maybe<Scalars['String']>;
  attrDetails: Array<AttrDetailInput>;
};

export type AttrPayload = {
  __typename?: 'AttrPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Attr>;
};

export type BoolPayload = {
  __typename?: 'BoolPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Scalars['Boolean']>;
};

export enum BreakBy {
  Quantity = 'QUANTITY',
  Amount = 'AMOUNT'
}

export type BuyerInput = {
  id: Scalars['Int'];
  buyerId: Scalars['Int'];
  refId?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  id: Scalars['Int'];
  year: Scalars['Int'];
  firstWeekend: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  calendarWeeks?: Maybe<Array<CalendarWeek>>;
};

export type CalendarInput = {
  id: Scalars['Int'];
  year: Scalars['Int'];
  firstWeekend?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};

export type CalendarListResult = {
  __typename?: 'CalendarListResult';
  total: Scalars['Int'];
  data: Array<Calendar>;
};

export type CalendarPayload = {
  __typename?: 'CalendarPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Calendar>;
};

export type CalendarWeek = {
  __typename?: 'CalendarWeek';
  id: Scalars['Int'];
  calendarId: Scalars['Int'];
  week: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type CdConfig = {
  __typename?: 'CdConfig';
  id: Scalars['Int'];
  numbering: Scalars['Int'];
  count: Scalars['Int'];
  prefix: Scalars['String'];
  description: Scalars['String'];
  basePrefix: CdPrefixType;
};

export type CdConfigInput = {
  id: Scalars['Int'];
  numbering: Scalars['Int'];
  count: Scalars['Int'];
  prefix: Scalars['String'];
};

export type CdConfigPayload = {
  __typename?: 'CdConfigPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CdConfig>;
};

export enum CdPrefixType {
  SaleOrder = 'SALE_ORDER',
  PurchaseReceipt = 'PURCHASE_RECEIPT',
  Exchange = 'EXCHANGE',
  Mcp = 'MCP',
  Discount = 'DISCOUNT',
  Trade = 'TRADE',
  Visual = 'VISUAL',
  Survey = 'SURVEY',
  Kpi = 'KPI',
  KpiFormula = 'KPI_FORMULA',
  ProductAttribute = 'PRODUCT_ATTRIBUTE',
  Product = 'PRODUCT',
  ProdGroup = 'PROD_GROUP',
  SalesPrice = 'SALES_PRICE',
  OtherProduct = 'OTHER_PRODUCT',
  MeasureUnit = 'MEASURE_UNIT',
  CustomerAttribute = 'CUSTOMER_ATTRIBUTE',
  CustomerClass = 'CUSTOMER_CLASS',
  CustomerPriceClass = 'CUSTOMER_PRICE_CLASS',
  Customer = 'CUSTOMER',
  Distributor = 'DISTRIBUTOR',
  DistributorType = 'DISTRIBUTOR_TYPE',
  Transaction = 'TRANSACTION',
  Shipper = 'SHIPPER',
  Employees = 'EMPLOYEES',
  SalesRegion = 'SALES_REGION',
  WorkGroup = 'WORK_GROUP',
  Commando = 'COMMANDO',
  SalesOrderBackUp = 'SALES_ORDER_BACK_UP'
}

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type CheckIn = {
  __typename?: 'CheckIn';
  id: Scalars['Int'];
  checkInAt: Scalars['DateTimeOffset'];
  checkOutAt: Scalars['DateTimeOffset'];
  visitDate?: Maybe<Scalars['DateTime']>;
  mcp: Mcp;
  salesman: Employee;
  customer: Customer;
  checkOutReasonText?: Maybe<Scalars['String']>;
  isVisit: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  isInRoute: Scalars['Boolean'];
  photo?: Maybe<Photo>;
  /** The check-in has any NON-REJECTED order or not */
  hasOrder: Scalars['Boolean'];
};

export type CheckInCommando = {
  __typename?: 'CheckInCommando';
  id: Scalars['Int'];
  checkInAt: Scalars['DateTimeOffset'];
  checkOutAt: Scalars['DateTimeOffset'];
  visitDate?: Maybe<Scalars['DateTime']>;
  commando: Commando;
  salesman: Employee;
  customer: Customer;
  checkOutReasonText?: Maybe<Scalars['String']>;
  isVisit: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  photo?: Maybe<Photo>;
  /** The check-in has any NON-REJECTED order or not */
  hasOrder: Scalars['Boolean'];
};

export type CheckInCommandoResult = {
  __typename?: 'CheckInCommandoResult';
  customer: Customer;
  orders: Array<SalesOrder>;
  returnOrders: Array<SalesOrder>;
};

export type CheckInCommandoResultPayload = {
  __typename?: 'CheckInCommandoResultPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CheckInCommandoResult>;
};

export type CheckInResult = {
  __typename?: 'CheckInResult';
  mcpLine: McpLine;
  orders: Array<SalesOrder>;
  returnOrders: Array<SalesOrder>;
};

export type CheckInResultPayload = {
  __typename?: 'CheckInResultPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CheckInResult>;
};

export type CheckOutReason = {
  __typename?: 'CheckOutReason';
  id: Scalars['Int'];
  name: Scalars['String'];
  minutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  requiredTasks: Array<McpCheckInTasks>;
  isVisit: Scalars['Boolean'];
};

export type CheckOutReasonInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  minutes: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  requiredTasks: Array<McpCheckInTasks>;
  isVisit: Scalars['Boolean'];
};

export type CheckOutReasonPayload = {
  __typename?: 'CheckOutReasonPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CheckOutReason>;
};

export type Commando = {
  __typename?: 'Commando';
  id: Scalars['Int'];
  cd: Scalars['String'];
  status: ProgramStatus;
  description?: Maybe<Scalars['String']>;
  sellTypes: Array<McpSellTypes>;
  inRangeMeter: Scalars['Int'];
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['Date']>;
  lastGeneratedDate?: Maybe<Scalars['Date']>;
  salesRegion: SalesRegion;
  salesman: Employee;
  commandoDistributors: Array<CommandoDistributor>;
  commandoLines: Array<CommandoLine>;
  provinces: Array<Province>;
  provinceSalesRegion: Array<Province>;
};

export type CommandoCustomerUpdate = {
  __typename?: 'CommandoCustomerUpdate';
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  status: McpLineUpdateStatus;
  customerClassId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  attrDetailIds?: Maybe<Array<Scalars['Int']>>;
  distributorIds?: Maybe<Array<Scalars['Int']>>;
  distributors: Array<Distributor>;
  name: Scalars['String'];
  photo?: Maybe<Photo>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  contactName1: Scalars['String'];
  phone11?: Maybe<Scalars['String']>;
  phone12?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  ward: Ward;
};

export type CommandoCustomerUpdateListResult = {
  __typename?: 'CommandoCustomerUpdateListResult';
  total: Scalars['Int'];
  data: Array<CommandoCustomerUpdate>;
};

export type CommandoDistributor = {
  __typename?: 'CommandoDistributor';
  id: Scalars['Int'];
  distributor: Distributor;
};

export type CommandoDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type CommandoInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  salesRegionId: Scalars['Int'];
  inRangeMeter: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  salesmanId: Scalars['Int'];
  commandoLines?: Maybe<Array<Maybe<CommandoLineInput>>>;
  sellTypes: Array<McpSellTypes>;
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  commandoDistributors: Array<CommandoDistributorInput>;
};

export type CommandoLine = {
  __typename?: 'CommandoLine';
  id: Scalars['Int'];
  distributor: Distributor;
  visitDate?: Maybe<Scalars['Date']>;
  addedAt?: Maybe<Scalars['DateTimeOffset']>;
  startHour?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['DateTime']>;
  targetAmount: Scalars['Decimal'];
  targetCustomer: Scalars['Decimal'];
  isActive: Scalars['Boolean'];
  ward?: Maybe<Ward>;
};

export type CommandoLineConnection = {
  __typename?: 'CommandoLineConnection';
  total: Scalars['Int'];
  targetCustomer: Scalars['Decimal'];
  actualCustomer: Scalars['Decimal'];
  targetAmount: Scalars['Decimal'];
  actualAmount: Scalars['Decimal'];
  nextCursor?: Maybe<Scalars['String']>;
  list: Array<Customer>;
};

export type CommandoLineInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
  commandoId: Scalars['Int'];
  visitDate?: Maybe<Scalars['Date']>;
  startHour?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['DateTime']>;
  targetAmount: Scalars['Decimal'];
  targetCustomer: Scalars['Decimal'];
  wardId: Scalars['Int'];
};

export type CommandoLineListResult = {
  __typename?: 'CommandoLineListResult';
  total: Scalars['Int'];
  data: Array<CommandoLine>;
};

export type CommandoLinePayload = {
  __typename?: 'CommandoLinePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CommandoLine>;
};

export type CommandoListResult = {
  __typename?: 'CommandoListResult';
  total: Scalars['Int'];
  data: Array<Commando>;
};

export type CommandoPayload = {
  __typename?: 'CommandoPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Commando>;
};

export type ControlMutationEndpoint = {
  __typename?: 'ControlMutationEndpoint';
  approveNewCustomer?: Maybe<BoolPayload>;
  rejectNewCustomer?: Maybe<BoolPayload>;
  closeCustomer?: Maybe<BoolPayload>;
  approveUpdateCustomer?: Maybe<BoolPayload>;
  rejectUpdateCustomer?: Maybe<BoolPayload>;
  approveTradeParticipation?: Maybe<BoolPayload>;
  rejectTradeParticipation?: Maybe<BoolPayload>;
  createOrUpdateTradeParticipationDistributor?: Maybe<TradeParticipationDistributorPayload>;
  approveTradeParticipationDistributor?: Maybe<BoolPayload>;
  rejectTradeParticipationDistributor?: Maybe<BoolPayload>;
  evaluatePhotoVisual?: Maybe<BoolPayload>;
  removeDeviceUser?: Maybe<BoolPayload>;
  acceptHoldingSalesOrder?: Maybe<BoolPayload>;
  cancelHoldingSalesOrder?: Maybe<BoolPayload>;
  acceptHoldingExchanges?: Maybe<BoolPayload>;
  cancelExchanges?: Maybe<BoolPayload>;
  generateMCPManual?: Maybe<BoolPayload>;
  calculateTradeManual?: Maybe<BoolPayload>;
  resetLocationCustomer?: Maybe<BoolPayload>;
  validateWarehouse?: Maybe<BoolPayload>;
  approveNewCommandoCustomer?: Maybe<BoolPayload>;
  rejectNewCommandoCustomer?: Maybe<BoolPayload>;
  approveUpdateCommandoCustomer?: Maybe<BoolPayload>;
  rejectUpdateCommandoCustomer?: Maybe<BoolPayload>;
};


export type ControlMutationEndpointApproveNewCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectNewCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointCloseCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointApproveUpdateCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectUpdateCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointApproveTradeParticipationArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectTradeParticipationArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointCreateOrUpdateTradeParticipationDistributorArgs = {
  data: TradeParticipationDistributorInput;
};


export type ControlMutationEndpointApproveTradeParticipationDistributorArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectTradeParticipationDistributorArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointEvaluatePhotoVisualArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRemoveDeviceUserArgs = {
  id: Scalars['Int'];
};


export type ControlMutationEndpointAcceptHoldingSalesOrderArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointCancelHoldingSalesOrderArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointAcceptHoldingExchangesArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointCancelExchangesArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointResetLocationCustomerArgs = {
  id: Scalars['Int'];
};


export type ControlMutationEndpointApproveNewCommandoCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectNewCommandoCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointApproveUpdateCommandoCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};


export type ControlMutationEndpointRejectUpdateCommandoCustomerArgs = {
  listIds: Array<Scalars['Int']>;
};

export type ControlQueryEndpoint = {
  __typename?: 'ControlQueryEndpoint';
  controlCustomerList: McpLineListResult;
  updateCustomerList: McpLineUpdateListResult;
  commandoNewCustomerList: CustomerListResult;
  updateCommandoCustomerList: CommandoCustomerUpdateListResult;
  controlTradeList: TradeListResult;
  tradeParticipationListByTradeId: TradeParticipationListResult;
  controlTradeListByTarget: TradeListResult;
  tradeParticipationDistributorListByTradeId: TradeParticipationDistributorListResult;
  controlVisualRelease: VisualListResult;
  visualPhotoListByVisualId: VisualPhotoListResult;
  deviceUserList: DeviceUserListResult;
  salesOrderNotTrackLotList: SalesOrderListResult;
  salesOrderErrorStatusCompareTransactionList: SalesOrderListResult;
  transactionErrorList: TransactionListResult;
};


export type ControlQueryEndpointControlCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  status?: Maybe<CustomerStatus>;
};


export type ControlQueryEndpointUpdateCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointCommandoNewCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointUpdateCommandoCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointControlTradeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
};


export type ControlQueryEndpointTradeParticipationListByTradeIdArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  tradeId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
};


export type ControlQueryEndpointControlTradeListByTargetArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  target?: Maybe<TradeApplyTarget>;
};


export type ControlQueryEndpointTradeParticipationDistributorListByTradeIdArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  tradeId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
};


export type ControlQueryEndpointControlVisualReleaseArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointVisualPhotoListByVisualIdArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  visualId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointDeviceUserListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointSalesOrderNotTrackLotListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  salesOrderCd?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointSalesOrderErrorStatusCompareTransactionListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  salesOrderCd?: Maybe<Scalars['String']>;
};


export type ControlQueryEndpointTransactionErrorListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  source?: Maybe<TransactionSource>;
  status?: Maybe<TransactionStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  createdAt: Scalars['DateTimeOffset'];
  modifiedAt: Scalars['DateTimeOffset'];
  cd: Scalars['String'];
  name: Scalars['String'];
  createdDate: Scalars['Date'];
  provinceId: Scalars['Int'];
  distributorId: Scalars['Int'];
  customerStatus: CustomerStatus;
  customerClassId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  photo?: Maybe<Photo>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  contactName1?: Maybe<Scalars['String']>;
  phone11?: Maybe<Scalars['String']>;
  phone12?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  isDistributor: Scalars['Boolean'];
  isPotential: Scalars['Boolean'];
  isEditPhoto: Scalars['Boolean'];
  isAlive?: Maybe<Scalars['Boolean']>;
  sourceCustomer: CustomerSource;
  customerClass: CustomerClass;
  creator?: Maybe<Employee>;
  customerPriceClass?: Maybe<CustomerPriceClass>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  ward?: Maybe<Ward>;
  customerDistributors: Array<CustomerDistributor>;
  attrDetailIds: Array<Scalars['Int']>;
  attrs: Array<Attr>;
  attrDetails: Array<AttrDetail>;
  fullAddress: Scalars['String'];
  hasCheckIn: Scalars['Boolean'];
  hasOrder: Scalars['Boolean'];
  hasCheckInCommando: Scalars['Boolean'];
  hasOrderCommando: Scalars['Boolean'];
  /** Last 5 recent check-in for pair <salesman, customer> */
  recentCheckIns: Array<CheckIn>;
  /** Last 5 recent check-in for pair <salesman, customer> */
  recentCheckInCommandos: Array<CheckInCommando>;
};

export type CustomerAttrDetailInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type CustomerClass = {
  __typename?: 'CustomerClass';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  customerClassAttrs: Array<CustomerClassAttr>;
};

export type CustomerClassAttr = {
  __typename?: 'CustomerClassAttr';
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  defaultAttrDetailId?: Maybe<Scalars['Int']>;
};

export type CustomerClassAttrInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  defaultAttrDetailId?: Maybe<Scalars['Int']>;
};

export type CustomerClassInput = {
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  customerClassAttrs: Array<CustomerClassAttrInput>;
};

export type CustomerClassPayload = {
  __typename?: 'CustomerClassPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CustomerClass>;
};

export type CustomerDistributor = {
  __typename?: 'CustomerDistributor';
  id: Scalars['Int'];
  distributor: Distributor;
};

export type CustomerDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type CustomerInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  customerClassId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  photoId?: Maybe<Scalars['Int']>;
  contactName1: Scalars['String'];
  phone11: Scalars['String'];
  phone12?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  isEditPhoto: Scalars['Boolean'];
  isAlive?: Maybe<Scalars['Boolean']>;
  wardId: Scalars['Int'];
  customerAttrDetails: Array<CustomerAttrDetailInput>;
  customerDistributors: Array<CustomerDistributorInput>;
};

export type CustomerListResult = {
  __typename?: 'CustomerListResult';
  total: Scalars['Int'];
  data: Array<Customer>;
};

export type CustomerPayload = {
  __typename?: 'CustomerPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Customer>;
};

export type CustomerPriceClass = {
  __typename?: 'CustomerPriceClass';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CustomerPriceClassInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CustomerPriceClassPayload = {
  __typename?: 'CustomerPriceClassPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<CustomerPriceClass>;
};

export enum CustomerSource {
  Manual = 'MANUAL',
  MobileMcp = 'MOBILE_MCP',
  MobileCommando = 'MOBILE_COMMANDO'
}

export enum CustomerStatus {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED'
}

export type CustomerSurvey = {
  __typename?: 'CustomerSurvey';
  id: Scalars['Int'];
  surveyId: Scalars['Int'];
  customerId: Scalars['Int'];
  checkinID?: Maybe<Scalars['Int']>;
  checkInCmdID?: Maybe<Scalars['Int']>;
  survey: Survey;
  customer: Customer;
  customerSurveyDetails?: Maybe<Array<Maybe<CustomerSurveyDetail>>>;
};

export type CustomerSurveyDetail = {
  __typename?: 'CustomerSurveyDetail';
  id: Scalars['Int'];
  surveyQuestionId: Scalars['Int'];
  surveyAnswerId?: Maybe<Scalars['Int']>;
  textAnswer?: Maybe<Scalars['String']>;
  surveyQuestion: SurveyQuestion;
  surveyAnswer?: Maybe<SurveyAnswer>;
};

export type CustomerSurveyListResult = {
  __typename?: 'CustomerSurveyListResult';
  total: Scalars['Int'];
  data: Array<CustomerSurvey>;
};

export type CustomerTrade = {
  __typename?: 'CustomerTrade';
  newTrades: Array<Trade>;
  tradeParticipations: Array<TradeParticipation>;
};

export type DashboardQueryEndpoint = {
  __typename?: 'DashboardQueryEndpoint';
  productDashboard: Array<ProductDashboard>;
  employeeDashboard: Array<EmployeeDashboard>;
};





export type DeliveryNote = {
  __typename?: 'DeliveryNote';
  id: Scalars['Int'];
  orderType: OrderType;
  status: DeliveryStatus;
  shipperId: Scalars['Int'];
  salesOrderId: Scalars['Int'];
  createdAt: Scalars['DateTimeOffset'];
  note?: Maybe<Scalars['String']>;
  shipper: Shipper;
  salesOrder?: Maybe<SalesOrder>;
  purchaseReceipt?: Maybe<PurchaseReceipt>;
};

export type DeliveryNoteIdInput = {
  id: Scalars['Int'];
};

export type DeliveryNoteInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  orderType: OrderType;
  shipperId: Scalars['Int'];
  distributorId: Scalars['Int'];
  salesOrderId: Scalars['Int'];
};

export type DeliveryNoteListResult = {
  __typename?: 'DeliveryNoteListResult';
  total: Scalars['Int'];
  data: Array<DeliveryNote>;
};

export type DeliveryNotePayload = {
  __typename?: 'DeliveryNotePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<DeliveryNote>;
};

export enum DeliveryStatus {
  Transporting = 'TRANSPORTING',
  Delivered = 'DELIVERED'
}

export type DeviceUser = {
  __typename?: 'DeviceUser';
  id: Scalars['Int'];
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
  deviceOs: Scalars['String'];
  employees?: Maybe<Employee>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DeviceUserInput = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
  deviceOs: Scalars['String'];
};

export type DeviceUserListResult = {
  __typename?: 'DeviceUserListResult';
  total: Scalars['Int'];
  data: Array<DeviceUser>;
};

export type DigitalMapQueryEndpoint = {
  __typename?: 'DigitalMapQueryEndpoint';
  activeLocationSalesman: Array<DmActiveLocation>;
  lastestLocationSalesmanList: Array<SalesmanFilter>;
  salesmanDto?: Maybe<SalesmanDto>;
  getAllOutletDto: Array<OutletDto>;
  outletDetailsDto?: Maybe<OutletDetailsDto>;
  getAllWorkGroupFilter: Array<WorkGroup>;
  getOutletDtoWeekdays: Array<OutletDto>;
  outletDetailsWeekdays?: Maybe<OutletDetailsDto>;
};


export type DigitalMapQueryEndpointActiveLocationSalesmanArgs = {
  salesmanId: Scalars['Int'];
  date: Scalars['Date'];
};


export type DigitalMapQueryEndpointLastestLocationSalesmanListArgs = {
  listIds: Array<Scalars['Int']>;
  date: Scalars['Date'];
};


export type DigitalMapQueryEndpointSalesmanDtoArgs = {
  id: Scalars['Int'];
};


export type DigitalMapQueryEndpointGetAllOutletDtoArgs = {
  salesmanId: Scalars['Int'];
  date: Scalars['Date'];
};


export type DigitalMapQueryEndpointOutletDetailsDtoArgs = {
  id: Scalars['Int'];
  date: Scalars['Date'];
};


export type DigitalMapQueryEndpointGetOutletDtoWeekdaysArgs = {
  weekdays: Array<Weekdays>;
  salesmanId: Scalars['Int'];
};


export type DigitalMapQueryEndpointOutletDetailsWeekdaysArgs = {
  id: Scalars['Int'];
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['Int'];
  cd: Scalars['String'];
  description: Scalars['String'];
  status: ProgramStatus;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isBundle: Scalars['Boolean'];
  breakBy: BreakBy;
  multiple: Scalars['Boolean'];
  highestLevel: Scalars['Boolean'];
  excluded: Scalars['Boolean'];
  allowChangeLevel: Scalars['Boolean'];
  isManual: Scalars['Boolean'];
  bonusPercentageMax?: Maybe<Scalars['Decimal']>;
  firstOrder: Scalars['Boolean'];
  buyGive: Scalars['Boolean'];
  internalRef?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  discountProducts: Array<DiscountProduct>;
  discountProductGroups: Array<DiscountProductGroup>;
  discountLevels: Array<DiscountLevel>;
  discountApplicableCustomers: Array<DiscountApplicableCustomer>;
  discountApplicableCustomerAttrs: Array<DiscountApplicableCustomerAttr>;
  discountApplicableSalesRegions: Array<DiscountApplicableSalesRegion>;
};

export type DiscountApplicableCustomer = {
  __typename?: 'DiscountApplicableCustomer';
  id: Scalars['Int'];
  customer: Customer;
};

export type DiscountApplicableCustomerAttr = {
  __typename?: 'DiscountApplicableCustomerAttr';
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type DiscountApplicableCustomerAttrInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type DiscountApplicableCustomerInput = {
  id: Scalars['Int'];
  customerId: Scalars['Int'];
};

export type DiscountApplicableSalesRegion = {
  __typename?: 'DiscountApplicableSalesRegion';
  id: Scalars['Int'];
  salesRegion: SalesRegion;
};

export type DiscountApplicableSalesRegionInput = {
  id: Scalars['Int'];
  salesRegionId: Scalars['Int'];
};

export type DiscountCalculatePass = {
  __typename?: 'DiscountCalculatePass';
  discountId: Scalars['Int'];
  discount: Discount;
  discountLevelCalculatePasses: Array<DiscountLevelCalculatePass>;
};

export type DiscountInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  breakBy: BreakBy;
  isBundle: Scalars['Boolean'];
  multiple: Scalars['Boolean'];
  highestLevel: Scalars['Boolean'];
  excluded: Scalars['Boolean'];
  allowChangeLevel: Scalars['Boolean'];
  isManual: Scalars['Boolean'];
  buyGive: Scalars['Boolean'];
  bonusPercentageMax?: Maybe<Scalars['Decimal']>;
  firstOrder: Scalars['Boolean'];
  internalRef?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  discountProducts: Array<DiscountProductInput>;
  discountProductGroups: Array<Maybe<DiscountProductGroupInput>>;
  discountLevels: Array<DiscountLevelInput>;
  discountApplicableCustomers: Array<DiscountApplicableCustomerInput>;
  discountApplicableCustomerAttrs: Array<DiscountApplicableCustomerAttrInput>;
  discountApplicableSalesRegions: Array<DiscountApplicableSalesRegionInput>;
};

export type DiscountLevel = {
  __typename?: 'DiscountLevel';
  id: Scalars['Int'];
  level: Scalars['Int'];
  description: Scalars['String'];
  breakValue: Scalars['Decimal'];
  discountLevelRewards: Array<DiscountLevelReward>;
  discountLevelConditions: Array<DiscountLevelCondition>;
};

export type DiscountLevelCalculatePass = {
  __typename?: 'DiscountLevelCalculatePass';
  discountLevelId: Scalars['Int'];
  totalPass: Scalars['Int'];
  discountLevel: DiscountLevel;
  orderItems: Array<DiscountOrderItem>;
};

export type DiscountLevelCondition = {
  __typename?: 'DiscountLevelCondition';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  minQty?: Maybe<Scalars['Decimal']>;
};

export type DiscountLevelConditionInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  minQty: Scalars['Decimal'];
};

export type DiscountLevelInput = {
  id: Scalars['Int'];
  level: Scalars['Int'];
  description: Scalars['String'];
  breakValue: Scalars['Decimal'];
  discountLevelRewards: Array<DiscountLevelRewardInput>;
  discountLevelConditions: Array<DiscountLevelConditionInput>;
};

export type DiscountLevelReward = {
  __typename?: 'DiscountLevelReward';
  id: Scalars['Int'];
  description: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isBundle: Scalars['Boolean'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  percentageMoney: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  discountLevelRewardProducts: Array<DiscountLevelRewardProduct>;
};

export type DiscountLevelRewardInput = {
  id: Scalars['Int'];
  description: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isBundle: Scalars['Boolean'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  discountLevelRewardProducts: Array<DiscountLevelRewardProductInput>;
};

export type DiscountLevelRewardProduct = {
  __typename?: 'DiscountLevelRewardProduct';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type DiscountLevelRewardProductInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type DiscountListResult = {
  __typename?: 'DiscountListResult';
  total: Scalars['Int'];
  data: Array<Discount>;
};

export type DiscountOrderItem = {
  __typename?: 'DiscountOrderItem';
  id: Scalars['Int'];
  unitId: Scalars['String'];
  qty: Scalars['Decimal'];
  passCount: Scalars['Decimal'];
  price: Scalars['Decimal'];
};

export type DiscountPayload = {
  __typename?: 'DiscountPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Discount>;
};

export type DiscountPrioritizationInput = {
  id: Scalars['Int'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type DiscountProduct = {
  __typename?: 'DiscountProduct';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
  product: Product;
};

export type DiscountProductGroup = {
  __typename?: 'DiscountProductGroup';
  id: Scalars['Int'];
  productGroupId: Scalars['Int'];
  productGroup: ProductGroup;
};

export type DiscountProductGroupInput = {
  id: Scalars['Int'];
  productGroupId: Scalars['Int'];
};

export type DiscountProductInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type DiscountSalesItemInput = {
  productId: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};

export type Distributor = {
  __typename?: 'Distributor';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  contactName1?: Maybe<Scalars['String']>;
  phone11?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  ward?: Maybe<Ward>;
  customer: Customer;
  creator?: Maybe<Customer>;
  distributorType: DistributorType;
  warehouses: Array<Warehouse>;
  buyers: Array<Vendor>;
  sellers: Array<Vendor>;
  salesmanProducts: Array<WarehouseProduct>;
  warehouseProducts: Array<WarehouseProduct>;
  fullAddress: Scalars['String'];
};

export type DistributorInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  distributorTypeId: Scalars['Int'];
  contactName1?: Maybe<Scalars['String']>;
  phone11?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wardId?: Maybe<Scalars['Int']>;
  warehouses: Array<WarehouseInput>;
  sellers: Array<SellerInput>;
  buyers: Array<BuyerInput>;
};

export type DistributorListResult = {
  __typename?: 'DistributorListResult';
  total: Scalars['Int'];
  data: Array<Distributor>;
};

export type DistributorPayload = {
  __typename?: 'DistributorPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Distributor>;
};

export type DistributorType = {
  __typename?: 'DistributorType';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
};

export type DistributorTypeInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DistributorTypePayload = {
  __typename?: 'DistributorTypePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<DistributorType>;
};

export type District = {
  __typename?: 'District';
  id: Scalars['Int'];
  name: Scalars['String'];
  province: Province;
  wards: Array<Ward>;
};

export type DmActiveLocation = {
  __typename?: 'DmActiveLocation';
  id: Scalars['Int'];
  salesmanId: Scalars['Int'];
  date: Scalars['DateTime'];
  lastSeenAt: Scalars['DateTime'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type DmActiveLocationInput = {
  id: Scalars['Int'];
  salesmanId: Scalars['Int'];
  lastSeenAt: Scalars['DateTime'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type DmActiveLocationPayload = {
  __typename?: 'DmActiveLocationPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<DmActiveLocation>;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['Int'];
  name: Scalars['String'];
  cd: Scalars['String'];
  username: Scalars['String'];
  requiredPasswordChanged: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  citizenIdentityCard?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  phone11?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  photo?: Maybe<Photo>;
  supervisor?: Maybe<Employee>;
  ward?: Maybe<Ward>;
  employeePositions: Array<EmployeePosition>;
  workingDistributors: Array<WorkingDistributor>;
  workingSalesRegions: Array<WorkingSalesRegion>;
};

export type EmployeeDashboard = {
  __typename?: 'EmployeeDashboard';
  salesmanId?: Maybe<Scalars['Int']>;
  total: Scalars['Decimal'];
  employee: Employee;
};

export type EmployeeInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  citizenIdentityCard?: Maybe<Scalars['String']>;
  supervisorId?: Maybe<Scalars['Int']>;
  requiredPasswordChanged: Scalars['Boolean'];
  newPassword?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  phone11?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
  employeePositions: Array<EmployeePositionInput>;
  workingDistributors: Array<WorkingDistributorInput>;
  workingSalesRegions: Array<WorkingSalesRegionInput>;
};

export type EmployeeListResult = {
  __typename?: 'EmployeeListResult';
  total: Scalars['Int'];
  data: Array<Employee>;
};

export type EmployeePayload = {
  __typename?: 'EmployeePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Employee>;
};

export type EmployeePosition = {
  __typename?: 'EmployeePosition';
  id: Scalars['Int'];
  position: Position;
};

export type EmployeePositionInput = {
  id: Scalars['Int'];
  positionId: Scalars['Int'];
};

export type EnjoyDiscount = {
  __typename?: 'EnjoyDiscount';
  id: Scalars['Int'];
  isManual: Scalars['Boolean'];
  salesOrderId: Scalars['Int'];
  discountId: Scalars['Int'];
  salesOrder: SalesOrder;
  discount: Discount;
  discountLevels: Array<EnjoyDiscountLevel>;
};

export type EnjoyDiscountInput = {
  id: Scalars['Int'];
  isManual: Scalars['Boolean'];
  discountId: Scalars['Int'];
  discountLevels: Array<EnjoyDiscountLevelInput>;
};

export type EnjoyDiscountLevel = {
  __typename?: 'EnjoyDiscountLevel';
  id: Scalars['Int'];
  rewardId: Scalars['Int'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  turnCount: Scalars['Int'];
  discountLevelId?: Maybe<Scalars['Int']>;
  productSelected: Array<EnjoyProduct>;
};

export type EnjoyDiscountLevelInput = {
  id: Scalars['Int'];
  rewardId: Scalars['Int'];
  amount: Scalars['Decimal'];
  turnCount: Scalars['Int'];
  percentage: Scalars['Decimal'];
  discountLevelId?: Maybe<Scalars['Int']>;
  productSelected: Array<EnjoyProductInput>;
};

export type EnjoyProduct = {
  __typename?: 'EnjoyProduct';
  id: Scalars['Int'];
  qty: Scalars['Int'];
  productId: Scalars['Int'];
  product: Product;
};

export type EnjoyProductInput = {
  id: Scalars['Int'];
  qty: Scalars['Int'];
  productId: Scalars['Int'];
};

export enum EntityType {
  Employee = 'EMPLOYEE',
  Customer = 'CUSTOMER',
  Visual = 'VISUAL',
  Product = 'PRODUCT'
}

export type ExcelCustomQueryEndpoint = {
  __typename?: 'ExcelCustomQueryEndpoint';
  exportMasterDataCustomers: Scalars['String'];
  exportSalesByCustomers: Scalars['String'];
  exportReportQualityOutlet: Scalars['String'];
  exportMasterDataProducts: Scalars['String'];
  exportSalesBySalesForce: Scalars['String'];
  exportProducts: Scalars['String'];
  exportSalesOrderDetails: Scalars['String'];
  exportMcpLineReport: Scalars['String'];
};


export type ExcelCustomQueryEndpointExportMasterDataCustomersArgs = {
  filter?: Maybe<Scalars['String']>;
  customerClassId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  sourceCustomer?: Maybe<CustomerSource>;
};


export type ExcelCustomQueryEndpointExportSalesByCustomersArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelCustomQueryEndpointExportReportQualityOutletArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesmanId?: Maybe<Scalars['Int']>;
  attrDetailId?: Maybe<Scalars['Int']>;
};


export type ExcelCustomQueryEndpointExportMasterDataProductsArgs = {
  filter?: Maybe<Scalars['String']>;
  cd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attrDetailId?: Maybe<Scalars['Int']>;
};


export type ExcelCustomQueryEndpointExportSalesBySalesForceArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
};


export type ExcelCustomQueryEndpointExportProductsArgs = {
  filter?: Maybe<Scalars['String']>;
  cd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attrDetailId?: Maybe<Scalars['Int']>;
};


export type ExcelCustomQueryEndpointExportSalesOrderDetailsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  salesmanCd?: Maybe<Scalars['String']>;
};


export type ExcelCustomQueryEndpointExportMcpLineReportArgs = {
  status?: Maybe<ProgramStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  customerFilter?: Maybe<Scalars['String']>;
};

export type ExcelMutationEndpoint = {
  __typename?: 'ExcelMutationEndpoint';
  importOtherProduct?: Maybe<BoolPayload>;
  importMeasureUnit?: Maybe<BoolPayload>;
  importKpiFormula?: Maybe<BoolPayload>;
  importKpiConfig?: Maybe<BoolPayload>;
  importCustomerAttributes?: Maybe<BoolPayload>;
  importProductAttributes?: Maybe<BoolPayload>;
  importCustomers?: Maybe<BoolPayload>;
  importCustomerClasses?: Maybe<BoolPayload>;
  importMcp?: Maybe<BoolPayload>;
  importCommando?: Maybe<BoolPayload>;
  importCommandoLine?: Maybe<BoolPayload>;
  importDistributor?: Maybe<BoolPayload>;
  importSalesPrices?: Maybe<BoolPayload>;
  importProducts?: Maybe<BoolPayload>;
  importEmployees?: Maybe<BoolPayload>;
  importTradeParticipation?: Maybe<BoolPayload>;
  importInventory?: Maybe<BoolPayload>;
  listPurchaseReceiptsExist: ImportPurchaseReceiptListResult;
  importPurchaseReceipt?: Maybe<BoolPayload>;
};


export type ExcelMutationEndpointImportOtherProductArgs = {
  data?: Maybe<Array<Maybe<OtherProductInput>>>;
};


export type ExcelMutationEndpointImportMeasureUnitArgs = {
  data?: Maybe<Array<Maybe<MeasureUnitInput>>>;
};


export type ExcelMutationEndpointImportKpiFormulaArgs = {
  data?: Maybe<Array<Maybe<KpiFormulaInput>>>;
};


export type ExcelMutationEndpointImportKpiConfigArgs = {
  data?: Maybe<Array<Maybe<KpiConfigInput>>>;
};


export type ExcelMutationEndpointImportCustomerAttributesArgs = {
  data?: Maybe<Array<Maybe<AttrInput>>>;
};


export type ExcelMutationEndpointImportProductAttributesArgs = {
  data?: Maybe<Array<Maybe<AttrInput>>>;
};


export type ExcelMutationEndpointImportCustomersArgs = {
  data?: Maybe<Array<Maybe<CustomerInput>>>;
};


export type ExcelMutationEndpointImportCustomerClassesArgs = {
  data?: Maybe<Array<Maybe<CustomerClassInput>>>;
};


export type ExcelMutationEndpointImportMcpArgs = {
  data?: Maybe<Array<Maybe<ImportMcpInput>>>;
};


export type ExcelMutationEndpointImportCommandoArgs = {
  data?: Maybe<Array<Maybe<ImportCommandoInput>>>;
};


export type ExcelMutationEndpointImportCommandoLineArgs = {
  data?: Maybe<Array<Maybe<ImportCommandoLineInput>>>;
};


export type ExcelMutationEndpointImportDistributorArgs = {
  data?: Maybe<Array<Maybe<DistributorInput>>>;
};


export type ExcelMutationEndpointImportSalesPricesArgs = {
  data?: Maybe<Array<Maybe<SalesPriceInput>>>;
};


export type ExcelMutationEndpointImportProductsArgs = {
  data?: Maybe<Array<Maybe<ProductInput>>>;
};


export type ExcelMutationEndpointImportEmployeesArgs = {
  data?: Maybe<Array<Maybe<EmployeeInput>>>;
};


export type ExcelMutationEndpointImportTradeParticipationArgs = {
  data?: Maybe<Array<Maybe<ImportTradeParticipationInput>>>;
};


export type ExcelMutationEndpointImportInventoryArgs = {
  data?: Maybe<Array<Maybe<ImportInventoryInput>>>;
};


export type ExcelMutationEndpointListPurchaseReceiptsExistArgs = {
  data: Array<Maybe<ImportPurchaseReceiptInput>>;
};


export type ExcelMutationEndpointImportPurchaseReceiptArgs = {
  data: Array<Maybe<ImportPurchaseReceiptInput>>;
  isUpdate?: Maybe<Scalars['Boolean']>;
};

export type ExcelQueryEndpoint = {
  __typename?: 'ExcelQueryEndpoint';
  exportMasterDataEmployees: Scalars['String'];
  exportMasterDataDistributors: Scalars['String'];
  exportPurchaseOrderDetails: Scalars['String'];
  exportPurchaseByProducts: Scalars['String'];
  exportPurchaseByDistributors: Scalars['String'];
  salesByProducts: Scalars['String'];
  orderRateByStatus: Scalars['String'];
  exportPercentOrderComplete: Scalars['String'];
  reportVisitCustomer: Scalars['String'];
  exportReportWarehouseXnt: Scalars['String'];
  exportWarehouseXntDetails: Scalars['String'];
  exportWarehouseXntDetailForDateSales: Scalars['String'];
  exportCurrentWarehouseProducts: Scalars['String'];
  exportPendingWarehouseProducts: Scalars['String'];
  exportVisitPlanSummaryReport: Scalars['String'];
  exportVisitPlanDetailsReport: Scalars['String'];
  exportProductStockCountReport: Scalars['String'];
  exportOtherProductStockCountReport: Scalars['String'];
  exportPromotionList: Scalars['String'];
  exportFollowPromotionList: Scalars['String'];
  exportPromotionFollowDistributor: Scalars['String'];
  exportPromotionSummarizingMoney: Scalars['String'];
  exportPromotionSummarizingProduct: Scalars['String'];
  exportSalesOrderDiscountLine: Scalars['String'];
  exportLoyaltyList: Scalars['String'];
  exportFollowLoyaltyRegistration: Scalars['String'];
  exportLoyaltiesEvaluation: Scalars['String'];
  exportKpisReport: Scalars['String'];
  exportKpiEvaluationSalesForce: Scalars['String'];
  exportFollowVisualReport: Scalars['String'];
  exportVisualListReport: Scalars['String'];
  exportSurveyListReport: Scalars['String'];
  exportCustomerSurveyReport: Scalars['String'];
  exportCustomers: Scalars['String'];
  exportCheckoutReasons: Scalars['String'];
  exportReasonEditOrdersList: Scalars['String'];
  exportPositions: Scalars['String'];
  exportProductsGroups: Scalars['String'];
  exportMeasureUnits: Scalars['String'];
  exportEmployees: Scalars['String'];
  exportWorkGroups: Scalars['String'];
  exportSalesRegions: Scalars['String'];
  exportSalesPrices: Scalars['String'];
  exportOtherProducts: Scalars['String'];
  exportProductAttributes: Scalars['String'];
  exportCustomerAttributes: Scalars['String'];
  exportCustomerClass: Scalars['String'];
  exportDistributorType: Scalars['String'];
  exportCustomerPriceClass: Scalars['String'];
  exportDistributors: Scalars['String'];
  exportShippers: Scalars['String'];
  exportMcpList: Scalars['String'];
  exportCommandoList: Scalars['String'];
  exportSalesOrders: Scalars['String'];
  exportSalesOrderReturns: Scalars['String'];
  exportPurchaseReceipts: Scalars['String'];
  exportTransactions: Scalars['String'];
  procedureExport: Scalars['String'];
  exportDiscount: Scalars['String'];
  exportTrade: Scalars['String'];
  exportVisual: Scalars['String'];
  exportKPIFormulas: Scalars['String'];
  exportKpiConfig: Scalars['String'];
  exportSurveys: Scalars['String'];
  exportReportPerformanceDistributor: Scalars['String'];
  exportSellProductCountReport: Scalars['String'];
};


export type ExcelQueryEndpointExportMasterDataEmployeesArgs = {
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  positionId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportMasterDataDistributorsArgs = {
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  distributorTypeId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPurchaseOrderDetailsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  vendorId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPurchaseByProductsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPurchaseByDistributorsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointSalesByProductsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointOrderRateByStatusArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportPercentOrderCompleteArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
};


export type ExcelQueryEndpointReportVisitCustomerArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportReportWarehouseXntArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportWarehouseXntDetailsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportWarehouseXntDetailForDateSalesArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportCurrentWarehouseProductsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  expireDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportPendingWarehouseProductsArgs = {
  source?: Maybe<TransactionSource>;
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportVisitPlanSummaryReportArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportVisitPlanDetailsReportArgs = {
  date: Scalars['Date'];
  salesmanId: Scalars['Int'];
};


export type ExcelQueryEndpointExportProductStockCountReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportOtherProductStockCountReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  otherProductId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPromotionListArgs = {
  date?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportFollowPromotionListArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportPromotionFollowDistributorArgs = {
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPromotionSummarizingMoneyArgs = {
  discountId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPromotionSummarizingProductArgs = {
  discountId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportSalesOrderDiscountLineArgs = {
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerCd?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportLoyaltyListArgs = {
  date?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  target?: Maybe<TradeApplyTarget>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportFollowLoyaltyRegistrationArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  startDateTrade?: Maybe<Scalars['Date']>;
  endDateTrade?: Maybe<Scalars['Date']>;
  statusTrade?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
};


export type ExcelQueryEndpointExportLoyaltiesEvaluationArgs = {
  tradeId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  breakBy?: Maybe<BreakBy>;
  tradePassed?: Maybe<Scalars['Boolean']>;
  visualPassed?: Maybe<Scalars['Boolean']>;
  totalPassed?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportKpisReportArgs = {
  employeeId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  target?: Maybe<KpiApplyTarget>;
  kpiConfigId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportKpiEvaluationSalesForceArgs = {
  employeeId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  kpiName?: Maybe<Scalars['String']>;
  minResult?: Maybe<Scalars['Decimal']>;
  maxResult?: Maybe<Scalars['Decimal']>;
};


export type ExcelQueryEndpointExportFollowVisualReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  visualId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportVisualListReportArgs = {
  visualId?: Maybe<Scalars['Int']>;
  minPass?: Maybe<Scalars['Int']>;
  maxPass?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportSurveyListReportArgs = {
  date?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  surveyId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportCustomerSurveyReportArgs = {
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ProgramStatus>;
  surveyId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportEmployeesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportSalesPricesArgs = {
  productId?: Maybe<Scalars['Int']>;
  priceClassId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportDistributorsArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportShippersArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportMcpListArgs = {
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  mcpCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportCommandoListArgs = {
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  commandoCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportSalesOrdersArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  salesOrderCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportSalesOrderReturnsArgs = {
  status?: Maybe<OrderStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorCd?: Maybe<Scalars['String']>;
  salesOrderReturnCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  reasonId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportPurchaseReceiptsArgs = {
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  vendorCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  employeeId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportTransactionsArgs = {
  source?: Maybe<TransactionSource>;
  status?: Maybe<TransactionStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointProcedureExportArgs = {
  procedureName?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportDiscountArgs = {
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  discountCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  externalReference?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  isBundle?: Maybe<Scalars['Int']>;
  isFirstOrder?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportTradeArgs = {
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  tradeCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportVisualArgs = {
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  visualCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
};


export type ExcelQueryEndpointExportKpiFormulasArgs = {
  status?: Maybe<ProgramStatus>;
  formulas?: Maybe<KpiFormulaType>;
  name?: Maybe<Scalars['String']>;
  kpiFormulaCd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportKpiConfigArgs = {
  status?: Maybe<ProgramStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  kpiConfigCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ExcelQueryEndpointExportSurveysArgs = {
  status?: Maybe<ProgramStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  surveyCd?: Maybe<Scalars['String']>;
};


export type ExcelQueryEndpointExportReportPerformanceDistributorArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

export type Exchange = {
  __typename?: 'Exchange';
  id: Scalars['Int'];
  cd: Scalars['String'];
  exchangeStatus: OrderStatus;
  exchangeSource: OrderSource;
  exchangeDate: Scalars['DateTime'];
  exchangeAt: Scalars['DateTimeOffset'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  distributorId: Scalars['Int'];
  reasonEditOrderId: Scalars['Int'];
  reasonEditOrder: ReasonEditOrder;
  customer: Customer;
  distributor: Distributor;
  salesman?: Maybe<Employee>;
  salesSupervisor?: Maybe<Employee>;
  exchangeItems: Array<ExchangeItem>;
};

export type ExchangeInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  exchangeDate: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  customerId: Scalars['Int'];
  reasonEditOrderId: Scalars['Int'];
  salesmanId?: Maybe<Scalars['Int']>;
  exchangeItems: Array<ExchangeItemInput>;
};

export type ExchangeItem = {
  __typename?: 'ExchangeItem';
  id: Scalars['Int'];
  exchangeId: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitPrice: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  returnLotNbr?: Maybe<Scalars['String']>;
  returnStartDate?: Maybe<Scalars['Date']>;
  returnEndDate?: Maybe<Scalars['Date']>;
  exchangeLotNbr?: Maybe<Scalars['String']>;
  exchangeStartDate?: Maybe<Scalars['Date']>;
  exchangeEndDate?: Maybe<Scalars['Date']>;
  product?: Maybe<Product>;
  warehouse?: Maybe<Warehouse>;
  exchange: Exchange;
};

export type ExchangeItemInput = {
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  qty: Scalars['Decimal'];
  returnLotNbr?: Maybe<Scalars['String']>;
  returnStartDate?: Maybe<Scalars['Date']>;
  returnEndDate?: Maybe<Scalars['Date']>;
  exchangeLotNbr?: Maybe<Scalars['String']>;
  exchangeStartDate?: Maybe<Scalars['Date']>;
  exchangeEndDate?: Maybe<Scalars['Date']>;
};

export type ExchangeListResult = {
  __typename?: 'ExchangeListResult';
  total: Scalars['Int'];
  data: Array<Exchange>;
};

export type ExchangePayload = {
  __typename?: 'ExchangePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Exchange>;
};

export type ImportCommandoInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  salesRegionCd: Scalars['String'];
  inRangeMeter: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  salesmanCd: Scalars['String'];
  commandoLines?: Maybe<Array<Maybe<ImportCommandoLineInput>>>;
  sellTypes: Array<McpSellTypes>;
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  commandoDistributors: Array<CommandoDistributorInput>;
};

export type ImportCommandoLineInput = {
  id: Scalars['Int'];
  distributorCd: Scalars['String'];
  commandoId: Scalars['Int'];
  visitDate?: Maybe<Scalars['Date']>;
  startHour?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['DateTime']>;
  targetAmount: Scalars['Decimal'];
  targetCustomer: Scalars['Decimal'];
  wardId: Scalars['Int'];
};

export type ImportInventoryInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  distributorCd: Scalars['String'];
  fromWarehouseName: Scalars['String'];
  toWarehouseName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  source: TransactionSource;
  transactionDate: Scalars['Date'];
  transactionItems: Array<TransactionItemInput>;
};

export type ImportMcpInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  salesRegionCd: Scalars['String'];
  inRangeMeter: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  salesmanCd: Scalars['String'];
  mcpLines?: Maybe<Array<Maybe<ImportMcpLineInput>>>;
  sellTypes: Array<McpSellTypes>;
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  mcpDistributors: Array<McpDistributorInput>;
};

export type ImportMcpLineDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type ImportMcpLineInput = {
  id: Scalars['Int'];
  customerCd: Scalars['String'];
  mcpId: Scalars['Int'];
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  mcpLineDistributors: Array<ImportMcpLineDistributorInput>;
};

export type ImportPurchaseReceipt = {
  __typename?: 'ImportPurchaseReceipt';
  id: Scalars['Int'];
  cd: Scalars['String'];
  orderDate: Scalars['DateTime'];
  orderType: OrderType;
  orderAt: Scalars['DateTimeOffset'];
  orderStatus: OrderStatus;
  orderTotal: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  vendor: Distributor;
  distributor: Distributor;
  employee?: Maybe<Employee>;
  creator?: Maybe<Employee>;
  orderItems?: Maybe<Array<ImportPurchaseReceiptItem>>;
  purchaseReceiptTrackLots?: Maybe<Array<ImportPurchaseReceiptTrackLot>>;
  deliveryNote?: Maybe<DeliveryNote>;
};

export type ImportPurchaseReceiptInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  orderDate: Scalars['Date'];
  orderType: OrderType;
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  distributorCd: Scalars['String'];
  vendorCd: Scalars['String'];
  orderItems: Array<ImportPurchaseReceiptItemInput>;
  purchaseReceiptTrackLots: Array<ImportPurchaseReceiptTrackLotInput>;
};

export type ImportPurchaseReceiptItem = {
  __typename?: 'ImportPurchaseReceiptItem';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  product: Product;
  warehouse: Warehouse;
  purchaseReceipt: PurchaseReceipt;
};

export type ImportPurchaseReceiptItemInput = {
  id: Scalars['Int'];
  warehouseName: Scalars['String'];
  productCd: Scalars['String'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
};

export type ImportPurchaseReceiptListResult = {
  __typename?: 'ImportPurchaseReceiptListResult';
  total: Scalars['Int'];
  data: Array<ImportPurchaseReceipt>;
};

export type ImportPurchaseReceiptTrackLot = {
  __typename?: 'ImportPurchaseReceiptTrackLot';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  product?: Maybe<Product>;
  warehouse?: Maybe<Warehouse>;
  purchaseReceipt: ImportPurchaseReceipt;
};

export type ImportPurchaseReceiptTrackLotInput = {
  id: Scalars['Int'];
  warehouseName: Scalars['String'];
  productCd: Scalars['String'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type ImportTradeParticipationInput = {
  id: Scalars['Int'];
  tradeCd: Scalars['String'];
  customerCd: Scalars['String'];
  level: Scalars['Int'];
};

export type InputReportDataConfig = {
  id: Scalars['Int'];
  name: Scalars['String'];
  procedureName: Scalars['String'];
  isWeb: Scalars['Boolean'];
  isMobile: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isAlive?: Maybe<Scalars['Boolean']>;
};

export type IntPayload = {
  __typename?: 'IntPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Scalars['Int']>;
};

export type InventoryDelivery = {
  __typename?: 'InventoryDelivery';
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  shipperId: Scalars['Int'];
  shipper: Shipper;
  deliveryNotes: Array<DeliveryNote>;
};

export type InventoryDeliveryInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  shipperId: Scalars['Int'];
  deliveryNotes: Array<DeliveryNoteIdInput>;
};

export type InventoryDeliveryListResult = {
  __typename?: 'InventoryDeliveryListResult';
  total: Scalars['Int'];
  data: Array<InventoryDelivery>;
};

export type InventoryDeliveryPayload = {
  __typename?: 'InventoryDeliveryPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<InventoryDelivery>;
};

export type InventoryMutationEndpoint = {
  __typename?: 'InventoryMutationEndpoint';
  createOrUpdateTransaction?: Maybe<TransactionPayload>;
  processTransaction?: Maybe<TransactionPayload>;
  actionProcessTransaction: Array<Maybe<Scalars['String']>>;
  completeTransaction?: Maybe<TransactionPayload>;
  actionCompleteTransaction: Array<Maybe<Scalars['String']>>;
  cancelTransaction?: Maybe<TransactionPayload>;
  actionCancelTransaction: Array<Maybe<Scalars['String']>>;
};


export type InventoryMutationEndpointCreateOrUpdateTransactionArgs = {
  data: TransactionInput;
};


export type InventoryMutationEndpointProcessTransactionArgs = {
  id: Scalars['Int'];
  source: TransactionSource;
};


export type InventoryMutationEndpointActionProcessTransactionArgs = {
  listIds: Array<Scalars['Int']>;
  source: TransactionSource;
};


export type InventoryMutationEndpointCompleteTransactionArgs = {
  id: Scalars['Int'];
  source: TransactionSource;
};


export type InventoryMutationEndpointActionCompleteTransactionArgs = {
  listIds: Array<Scalars['Int']>;
  source: TransactionSource;
};


export type InventoryMutationEndpointCancelTransactionArgs = {
  id: Scalars['Int'];
  source: TransactionSource;
};


export type InventoryMutationEndpointActionCancelTransactionArgs = {
  listIds: Array<Scalars['Int']>;
  source: TransactionSource;
};

export type InventoryQueryEndpoint = {
  __typename?: 'InventoryQueryEndpoint';
  transactionList: TransactionListResult;
  saleOrderTransaction: TransactionListResult;
  purchaseReceiptTransaction: TransactionListResult;
  transactionProcessList: TransactionListResult;
  transaction: Transaction;
  filterLotNumber: WarehouseProductListResult;
  filterAllLotNumber: WarehouseProductListResult;
  warehouseProductTrackLots: Array<WarehouseProduct>;
};


export type InventoryQueryEndpointTransactionListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  source?: Maybe<TransactionSource>;
  status?: Maybe<TransactionStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
};


export type InventoryQueryEndpointSaleOrderTransactionArgs = {
  id: Scalars['Int'];
};


export type InventoryQueryEndpointPurchaseReceiptTransactionArgs = {
  id: Scalars['Int'];
};


export type InventoryQueryEndpointTransactionProcessListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  source: TransactionSource;
  status?: Maybe<TransactionStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  action: ActionType;
  distributorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
};


export type InventoryQueryEndpointTransactionArgs = {
  id: Scalars['Int'];
  source?: Maybe<TransactionSource>;
};


export type InventoryQueryEndpointFilterLotNumberArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type InventoryQueryEndpointFilterAllLotNumberArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type InventoryQueryEndpointWarehouseProductTrackLotsArgs = {
  data: Array<TrackLotWarehouseInput>;
};

export enum KpiApplyTarget {
  Salesforce = 'SALESFORCE',
  Distributor = 'DISTRIBUTOR'
}

export type KpiConfig = {
  __typename?: 'KpiConfig';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  target: KpiApplyTarget;
  status: ProgramStatus;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  kpiConfigItems: Array<KpiConfigItem>;
  creator?: Maybe<Employee>;
};

export type KpiConfigInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  target: KpiApplyTarget;
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  kpiConfigItems: Array<KpiConfigItemInput>;
};

export type KpiConfigItem = {
  __typename?: 'KpiConfigItem';
  id: Scalars['Int'];
  actual: Scalars['Decimal'];
  percentageActual: Scalars['Decimal'];
  target: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  kpiFormula: KpiFormula;
  employee?: Maybe<Employee>;
  distributor?: Maybe<Distributor>;
  kpiConfig: KpiConfig;
};

export type KpiConfigItemInput = {
  id: Scalars['Int'];
  kpiFormulaId: Scalars['Int'];
  employeeId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  kpiFormulaCd?: Maybe<Scalars['String']>;
  employeeCd?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
  target: Scalars['Decimal'];
  rate: Scalars['Decimal'];
};

export type KpiConfigItemListResult = {
  __typename?: 'KpiConfigItemListResult';
  total: Scalars['Int'];
  data: Array<KpiConfigItem>;
};

export type KpiConfigListResult = {
  __typename?: 'KpiConfigListResult';
  total: Scalars['Int'];
  data: Array<KpiConfig>;
};

export type KpiConfigPayload = {
  __typename?: 'KpiConfigPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<KpiConfig>;
};

export type KpiFormula = {
  __typename?: 'KpiFormula';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeOffset'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  expiresBackDate: Scalars['Int'];
  status: ProgramStatus;
  formula: KpiFormulaType;
  attrDetail?: Maybe<AttrDetail>;
  kpiFormulaCategories: Array<KpiFormulaCategory>;
  kpiFormulaProducts: Array<KpiFormulaProduct>;
  creator?: Maybe<Employee>;
};

export type KpiFormulaCategory = {
  __typename?: 'KpiFormulaCategory';
  id: Scalars['Int'];
  percentage: Scalars['Decimal'];
  attrDetailId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  attrDetail: AttrDetail;
};

export type KpiFormulaCategoryInput = {
  id: Scalars['Int'];
  percentage: Scalars['Decimal'];
  attrDetailId: Scalars['Int'];
  attrDetailValue?: Maybe<Scalars['String']>;
  attributeCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type KpiFormulaInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  attrDetailId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  percentage?: Maybe<Scalars['Decimal']>;
  expiresBackDate?: Maybe<Scalars['Int']>;
  formula: KpiFormulaType;
  kpiFormulaCategories: Array<KpiFormulaCategoryInput>;
  kpiFormulaProducts: Array<KpiFormulaProductInput>;
};

export type KpiFormulaPayload = {
  __typename?: 'KpiFormulaPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<KpiFormula>;
};

export type KpiFormulaProduct = {
  __typename?: 'KpiFormulaProduct';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type KpiFormulaProductInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
  productCd?: Maybe<Scalars['String']>;
};

export enum KpiFormulaType {
  Amount = 'AMOUNT',
  Quantity = 'QUANTITY',
  SalesCare = 'SALES_CARE',
  SalesSell = 'SALES_SELL',
  FirstOrder = 'FIRST_ORDER',
  OrderCount = 'ORDER_COUNT',
  Sku = 'SKU',
  Date = 'DATE',
  Distribution = 'DISTRIBUTION',
  OpenNewCustomer = 'OPEN_NEW_CUSTOMER'
}

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  accessToken: Scalars['String'];
};

export type LoginResultPayload = {
  __typename?: 'LoginResultPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<LoginResult>;
};

export type MasterData = {
  __typename?: 'MasterData';
  checkOutReasons: Array<CheckOutReason>;
  customerClasses: Array<CustomerClass>;
  customerPriceClasses: Array<CustomerPriceClass>;
  customerAttrs: Array<Attr>;
  productAttrs: Array<Attr>;
  products: Array<Product>;
  otherProducts: Array<OtherProduct>;
  productGroups: Array<ProductGroup>;
  currentWeek: Scalars['Int'];
  reasonEditOrders: Array<ReasonEditOrder>;
};

export type MasterDataMutationEndpoint = {
  __typename?: 'MasterDataMutationEndpoint';
  deleteAttr?: Maybe<IntPayload>;
  createOrUpdateAttr?: Maybe<AttrPayload>;
  deleteProductGroup?: Maybe<IntPayload>;
  createOrUpdateProductGroup?: Maybe<ProductGroupPayload>;
  productGroupItems: Array<ProductGroupItem>;
  deleteProduct?: Maybe<IntPayload>;
  activeProduct?: Maybe<IntPayload>;
  createOrUpdateProduct?: Maybe<ProductPayload>;
  deleteOtherProduct?: Maybe<IntPayload>;
  createOrUpdateOtherProduct?: Maybe<OtherProductPayload>;
  deleteMeasureUnit?: Maybe<StringPayload>;
  createOrUpdateMeasureUnit?: Maybe<MeasureUnitPayload>;
  deleteSalesPrice?: Maybe<IntPayload>;
  createOrUpdateSalesPrice?: Maybe<SalesPricePayload>;
  createOrUpdateListSalesPrice?: Maybe<BoolPayload>;
  deleteCustomerClass?: Maybe<IntPayload>;
  createOrUpdateCustomerClass?: Maybe<CustomerClassPayload>;
  deleteCustomerPriceClass?: Maybe<IntPayload>;
  createOrUpdateCustomerPriceClass?: Maybe<CustomerPriceClassPayload>;
  createOrUpdateCustomer?: Maybe<CustomerPayload>;
  activeCustomer?: Maybe<IntPayload>;
  createOrUpdateDistributor?: Maybe<DistributorPayload>;
  createOrUpdateDistributorType?: Maybe<DistributorTypePayload>;
  activeDistributor?: Maybe<IntPayload>;
  createOrUpdateShipper?: Maybe<ShipperPayload>;
  deleteShipper?: Maybe<IntPayload>;
  statusShipper?: Maybe<IntPayload>;
  createOrUpdateEmployee?: Maybe<EmployeePayload>;
  createOrUpdatePosition?: Maybe<PositionPayload>;
  changePassword?: Maybe<LoginResultPayload>;
  activeEmployee?: Maybe<IntPayload>;
  deleteWorkGroup?: Maybe<IntPayload>;
  createOrUpdateWorkGroup?: Maybe<WorkGroupPayload>;
  updateCdConfig?: Maybe<CdConfigPayload>;
  deleteReasonEditOrder?: Maybe<IntPayload>;
  createOrUpdateReasonEditOrder?: Maybe<ReasonEditOrderPayload>;
};


export type MasterDataMutationEndpointDeleteAttrArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateAttrArgs = {
  data: AttrInput;
};


export type MasterDataMutationEndpointDeleteProductGroupArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateProductGroupArgs = {
  data: ProductGroupInput;
};


export type MasterDataMutationEndpointProductGroupItemsArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointActiveProductArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateProductArgs = {
  data: ProductInput;
};


export type MasterDataMutationEndpointDeleteOtherProductArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateOtherProductArgs = {
  data: OtherProductInput;
};


export type MasterDataMutationEndpointDeleteMeasureUnitArgs = {
  id: Scalars['String'];
};


export type MasterDataMutationEndpointCreateOrUpdateMeasureUnitArgs = {
  data: MeasureUnitInput;
};


export type MasterDataMutationEndpointDeleteSalesPriceArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateSalesPriceArgs = {
  data: SalesPriceInput;
};


export type MasterDataMutationEndpointCreateOrUpdateListSalesPriceArgs = {
  data: Array<SalesPriceInput>;
};


export type MasterDataMutationEndpointDeleteCustomerClassArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateCustomerClassArgs = {
  data: CustomerClassInput;
};


export type MasterDataMutationEndpointDeleteCustomerPriceClassArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateCustomerPriceClassArgs = {
  data: CustomerPriceClassInput;
};


export type MasterDataMutationEndpointCreateOrUpdateCustomerArgs = {
  data: CustomerInput;
};


export type MasterDataMutationEndpointActiveCustomerArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateDistributorArgs = {
  data: DistributorInput;
};


export type MasterDataMutationEndpointCreateOrUpdateDistributorTypeArgs = {
  data: DistributorTypeInput;
};


export type MasterDataMutationEndpointActiveDistributorArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateShipperArgs = {
  data: ShipperInput;
};


export type MasterDataMutationEndpointDeleteShipperArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointStatusShipperArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateEmployeeArgs = {
  data: EmployeeInput;
};


export type MasterDataMutationEndpointCreateOrUpdatePositionArgs = {
  data: PositionInput;
};


export type MasterDataMutationEndpointChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MasterDataMutationEndpointActiveEmployeeArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointDeleteWorkGroupArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateWorkGroupArgs = {
  data: WorkGroupInput;
};


export type MasterDataMutationEndpointUpdateCdConfigArgs = {
  data: CdConfigInput;
};


export type MasterDataMutationEndpointDeleteReasonEditOrderArgs = {
  id: Scalars['Int'];
};


export type MasterDataMutationEndpointCreateOrUpdateReasonEditOrderArgs = {
  data: ReasonEditOrderInput;
};

export type MasterDataQueryEndpoint = {
  __typename?: 'MasterDataQueryEndpoint';
  provinces: Array<Province>;
  districts: Array<District>;
  wards: Array<Ward>;
  attrs: Array<Attr>;
  attr: Attr;
  attrDetails: Array<AttrDetail>;
  productGroups: Array<ProductGroup>;
  productGroup: ProductGroup;
  products: Array<Product>;
  allProducts: Array<Product>;
  product: Product;
  otherProducts: Array<OtherProduct>;
  otherProduct: OtherProduct;
  measureUnits: Array<MeasureUnit>;
  measureUnit: MeasureUnit;
  salesPriceList: SalesPriceListResult;
  salesPriceListByCustomer: SalesPriceListResult;
  salesPriceListByDistributor: SalesPriceListResult;
  salesPrice: SalesPrice;
  customerClasses: Array<CustomerClass>;
  customerClass: CustomerClass;
  customerPriceClasses: Array<CustomerPriceClass>;
  customerPriceClass: CustomerPriceClass;
  customerList: CustomerListResult;
  allCustomer: Array<Customer>;
  customer: Customer;
  distributorList: DistributorListResult;
  distributor: Distributor;
  distributorAllList: Array<Distributor>;
  distributorTypeList: Array<DistributorType>;
  distributorType: DistributorType;
  shipperList: ShipperListResult;
  shipperByDeliveryList: ShipperListResult;
  shipper: Shipper;
  permissions: Array<Scalars['String']>;
  positions: Array<Position>;
  position: Position;
  employeeList: EmployeeListResult;
  allEmployeeList: EmployeeListResult;
  employeeListAll: Array<Maybe<Employee>>;
  employee: Employee;
  employeeLeaderList: EmployeeListResult;
  employeeMemberList: EmployeeListResult;
  workGroupList: WorkGroupListResult;
  workGroup: WorkGroup;
  cdsConfig: Array<CdConfig>;
  cdConfig: CdConfig;
  reasonEditOrderList: ReasonEditOrderListResult;
  reasonEditOrder: ReasonEditOrder;
};


export type MasterDataQueryEndpointDistrictsArgs = {
  provinceId: Scalars['Int'];
};


export type MasterDataQueryEndpointWardsArgs = {
  districtId: Scalars['Int'];
};


export type MasterDataQueryEndpointAttrsArgs = {
  type: EntityType;
};


export type MasterDataQueryEndpointAttrArgs = {
  id: Scalars['Int'];
  type: EntityType;
};


export type MasterDataQueryEndpointAttrDetailsArgs = {
  type: EntityType;
};


export type MasterDataQueryEndpointProductGroupsArgs = {
  productGroupCd?: Maybe<Scalars['String']>;
  productGroupName?: Maybe<Scalars['String']>;
  isBundle?: Maybe<Scalars['Boolean']>;
};


export type MasterDataQueryEndpointProductGroupArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointProductsArgs = {
  productFilter?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointAllProductsArgs = {
  productFilter?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointProductArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointOtherProductsArgs = {
  otherProductCd?: Maybe<Scalars['String']>;
  otherProductName?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointOtherProductArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointMeasureUnitArgs = {
  id: Scalars['String'];
};


export type MasterDataQueryEndpointSalesPriceListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  priceClassId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointSalesPriceListByCustomerArgs = {
  priceClassId?: Maybe<Scalars['Int']>;
};


export type MasterDataQueryEndpointSalesPriceListByDistributorArgs = {
  distributorId?: Maybe<Scalars['Int']>;
};


export type MasterDataQueryEndpointSalesPriceArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointCustomerClassArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointCustomerPriceClassArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  customerClassId?: Maybe<Scalars['Int']>;
  customerStatus?: Maybe<CustomerStatus>;
};


export type MasterDataQueryEndpointCustomerArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointDistributorListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type MasterDataQueryEndpointDistributorArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointDistributorTypeArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointShipperListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointShipperByDeliveryListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointShipperArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointPositionArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointEmployeeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  provinceId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
};


export type MasterDataQueryEndpointAllEmployeeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  provinceId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
};


export type MasterDataQueryEndpointEmployeeArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointEmployeeLeaderListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointEmployeeMemberListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  leaderId?: Maybe<Scalars['Int']>;
};


export type MasterDataQueryEndpointWorkGroupListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointWorkGroupArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointCdConfigArgs = {
  id: Scalars['Int'];
};


export type MasterDataQueryEndpointReasonEditOrderListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  reasonType?: Maybe<ReasonType>;
  filter?: Maybe<Scalars['String']>;
};


export type MasterDataQueryEndpointReasonEditOrderArgs = {
  id: Scalars['Int'];
};

export type Mcp = {
  __typename?: 'Mcp';
  id: Scalars['Int'];
  cd: Scalars['String'];
  status: ProgramStatus;
  description?: Maybe<Scalars['String']>;
  sellTypes: Array<McpSellTypes>;
  inRangeMeter: Scalars['Int'];
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['Date']>;
  lastGeneratedDate?: Maybe<Scalars['Date']>;
  creator?: Maybe<Employee>;
  salesRegion: SalesRegion;
  salesman: Employee;
  mcpDistributors: Array<McpDistributor>;
  mcpDetailConnection: McpDetailConnection;
  mcpLineConnection: McpLineConnection;
  distributors: Array<Distributor>;
  provinces: Array<Province>;
  discounts: Array<Discount>;
  trades: Array<Trade>;
};


export type McpMcpDetailConnectionArgs = {
  filter?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  cursor?: Maybe<Scalars['String']>;
};


export type McpMcpLineConnectionArgs = {
  isNew?: Maybe<Scalars['Boolean']>;
  isPotential?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};

export enum McpCheckInTasks {
  None = 'NONE',
  Visual = 'VISUAL',
  StockCount = 'STOCK_COUNT',
  Trade = 'TRADE',
  Survey = 'SURVEY',
  ViewDocs = 'VIEW_DOCS'
}

export type McpDetail = {
  __typename?: 'McpDetail';
  id: Scalars['String'];
  visitIndex: Scalars['Int'];
  mcpLine: McpLine;
};

export type McpDetailConnection = {
  __typename?: 'McpDetailConnection';
  total: Scalars['Int'];
  visitedCount: Scalars['Int'];
  orderCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['String']>;
  list: Array<McpDetail>;
};

export type McpDistributor = {
  __typename?: 'McpDistributor';
  id: Scalars['Int'];
  distributor: Distributor;
};

export type McpDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type McpInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  salesRegionId: Scalars['Int'];
  inRangeMeter: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  allowOutPlanVisit: Scalars['Boolean'];
  allowNewCustomerVisit: Scalars['Boolean'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  salesmanId: Scalars['Int'];
  mcpLines?: Maybe<Array<Maybe<McpLineInput>>>;
  sellTypes: Array<McpSellTypes>;
  hasOrderRequiredTasks: Array<McpCheckInTasks>;
  mcpDistributors: Array<McpDistributorInput>;
};

export type McpLine = {
  __typename?: 'McpLine';
  id: Scalars['Int'];
  mcp: Mcp;
  customerId: Scalars['Int'];
  addedAt?: Maybe<Scalars['DateTimeOffset']>;
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  inRoute: Scalars['Boolean'];
  distributors: Array<Distributor>;
  mcpDistributors: Array<McpDistributor>;
  customer: Customer;
  update?: Maybe<McpLineUpdate>;
};

export type McpLineConnection = {
  __typename?: 'McpLineConnection';
  total: Scalars['Int'];
  visitedCount: Scalars['Int'];
  orderCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['String']>;
  list: Array<McpLine>;
};

export type McpLineDistributorInput = {
  id: Scalars['Int'];
  mcpLineId: Scalars['Int'];
  mcpDistributorId: Scalars['Int'];
};

export type McpLineInput = {
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  mcpId: Scalars['Int'];
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  mcpLineDistributors: Array<McpLineDistributorInput>;
};

export type McpLineListResult = {
  __typename?: 'McpLineListResult';
  total: Scalars['Int'];
  data: Array<McpLine>;
};

export type McpLinePayload = {
  __typename?: 'McpLinePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<McpLine>;
};

export type McpLineUpdate = {
  __typename?: 'McpLineUpdate';
  id: Scalars['Int'];
  status: McpLineUpdateStatus;
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  customerClassId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  attrDetailIds?: Maybe<Array<Scalars['Int']>>;
  distributorIds?: Maybe<Array<Scalars['Int']>>;
  distributors: Array<Distributor>;
  name: Scalars['String'];
  photo?: Maybe<Photo>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  contactName1: Scalars['String'];
  phone11?: Maybe<Scalars['String']>;
  phone12?: Maybe<Scalars['String']>;
  mcpLine: McpLine;
  address1?: Maybe<Scalars['String']>;
  ward: Ward;
};

export type McpLineUpdateListResult = {
  __typename?: 'McpLineUpdateListResult';
  total: Scalars['Int'];
  data: Array<McpLineUpdate>;
};

export enum McpLineUpdateStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type McpListResult = {
  __typename?: 'McpListResult';
  total: Scalars['Int'];
  data: Array<Mcp>;
};

export type McpMutationEndpoint = {
  __typename?: 'McpMutationEndpoint';
  deleteCheckOutReason?: Maybe<IntPayload>;
  createOrUpdateCheckOutReason?: Maybe<CheckOutReasonPayload>;
  deleteSalesRegion?: Maybe<IntPayload>;
  createOrUpdateSalesRegion?: Maybe<SalesRegionPayload>;
  createOrUpdateMcp?: Maybe<McpPayload>;
  closeMcp?: Maybe<McpPayload>;
  releaseMcp?: Maybe<McpPayload>;
  copyMcp?: Maybe<McpPayload>;
  createOrUpdateMcpLine?: Maybe<McpLinePayload>;
  deleteMcpLine?: Maybe<IntPayload>;
  releaseMcpList?: Maybe<BoolPayload>;
  createOrUpdateCommando?: Maybe<CommandoPayload>;
  closeCommando?: Maybe<CommandoPayload>;
  releaseCommando?: Maybe<CommandoPayload>;
  createOrUpdateCommandoLine?: Maybe<CommandoLinePayload>;
  actionStatusCommandoLine?: Maybe<BoolPayload>;
  createOrUpdateCalendar?: Maybe<CalendarPayload>;
  calculateCalendar?: Maybe<IntPayload>;
  deleteCalendar?: Maybe<IntPayload>;
};


export type McpMutationEndpointDeleteCheckOutReasonArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCreateOrUpdateCheckOutReasonArgs = {
  data: CheckOutReasonInput;
};


export type McpMutationEndpointDeleteSalesRegionArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCreateOrUpdateSalesRegionArgs = {
  data: SalesRegionInput;
};


export type McpMutationEndpointCreateOrUpdateMcpArgs = {
  data: McpInput;
};


export type McpMutationEndpointCloseMcpArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointReleaseMcpArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCopyMcpArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCreateOrUpdateMcpLineArgs = {
  data: McpLineInput;
};


export type McpMutationEndpointDeleteMcpLineArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointReleaseMcpListArgs = {
  listId: Array<Scalars['Int']>;
};


export type McpMutationEndpointCreateOrUpdateCommandoArgs = {
  data: CommandoInput;
};


export type McpMutationEndpointCloseCommandoArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointReleaseCommandoArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCreateOrUpdateCommandoLineArgs = {
  data: CommandoLineInput;
};


export type McpMutationEndpointActionStatusCommandoLineArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointCreateOrUpdateCalendarArgs = {
  data: CalendarInput;
};


export type McpMutationEndpointCalculateCalendarArgs = {
  id: Scalars['Int'];
};


export type McpMutationEndpointDeleteCalendarArgs = {
  id: Scalars['Int'];
};

export type McpPayload = {
  __typename?: 'McpPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Mcp>;
};

export type McpQueryEndpoint = {
  __typename?: 'McpQueryEndpoint';
  checkOutReasons: Array<CheckOutReason>;
  checkOutReason: CheckOutReason;
  salesRegionList: SalesRegionListResult;
  salesRegion: SalesRegion;
  mcpList: McpListResult;
  mcp: Mcp;
  mcpLineList: McpLineListResult;
  mcpDetails: Array<McpDetail>;
  customersByDistributors: CustomerListResult;
  calendarList: CalendarListResult;
  calendar: Calendar;
  commandoList: CommandoListResult;
  commando: Commando;
  commandoLines: CommandoLineListResult;
};


export type McpQueryEndpointCheckOutReasonArgs = {
  id: Scalars['Int'];
};


export type McpQueryEndpointSalesRegionListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type McpQueryEndpointSalesRegionArgs = {
  id: Scalars['Int'];
};


export type McpQueryEndpointMcpListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  mcpCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type McpQueryEndpointMcpArgs = {
  id: Scalars['Int'];
};


export type McpQueryEndpointMcpLineListArgs = {
  mcpId: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type McpQueryEndpointMcpDetailsArgs = {
  mcpId: Scalars['Int'];
  visitDate: Scalars['Date'];
};


export type McpQueryEndpointCustomersByDistributorsArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorIds: Array<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type McpQueryEndpointCalendarListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type McpQueryEndpointCalendarArgs = {
  id: Scalars['Int'];
};


export type McpQueryEndpointCommandoListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  commandoCd?: Maybe<Scalars['String']>;
};


export type McpQueryEndpointCommandoArgs = {
  id: Scalars['Int'];
};


export type McpQueryEndpointCommandoLinesArgs = {
  commandoId: Scalars['Int'];
  visitDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};

export enum McpSellTypes {
  None = 'NONE',
  PreOrder = 'PRE_ORDER',
  VanSell = 'VAN_SELL'
}

export type Me = {
  __typename?: 'Me';
  id: Scalars['Int'];
  name: Scalars['String'];
  username: Scalars['String'];
  cd: Scalars['String'];
  permissions: Array<Maybe<Scalars['String']>>;
};

export type MeasureUnit = {
  __typename?: 'MeasureUnit';
  id: Scalars['String'];
  cd: Scalars['String'];
  description: Scalars['String'];
};

export type MeasureUnitInput = {
  id: Scalars['String'];
  cd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MeasureUnitPayload = {
  __typename?: 'MeasureUnitPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<MeasureUnit>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login?: Maybe<LoginResultPayload>;
  logout?: Maybe<BoolPayload>;
  uploadPhoto?: Maybe<PhotoPayload>;
  sfa?: Maybe<SfaMutationEndpoint>;
  mcp?: Maybe<McpMutationEndpoint>;
  o?: Maybe<OrderMutationEndpoint>;
  p?: Maybe<ProgramMutationEndpoint>;
  md?: Maybe<MasterDataMutationEndpoint>;
  in?: Maybe<InventoryMutationEndpoint>;
  ctrl?: Maybe<ControlMutationEndpoint>;
  excel?: Maybe<ExcelMutationEndpoint>;
};


export type MutationLoginArgs = {
  data: LoginInput;
  deviceUser?: Maybe<DeviceUserInput>;
};


export type MutationUploadPhotoArgs = {
  data: UploadPhotoInput;
};

export type OrderDto = {
  __typename?: 'OrderDto';
  code: Scalars['String'];
  amount: Scalars['Decimal'];
};

export type OrderMutationEndpoint = {
  __typename?: 'OrderMutationEndpoint';
  createOrUpdateSalesOrder?: Maybe<SalesOrderPayload>;
  salesOrderTrackLot: Array<SalesOrderTrackLot>;
  processSalesOrder?: Maybe<SalesOrderPayload>;
  cancelSalesOrder?: Maybe<SalesOrderPayload>;
  completeSalesOrder?: Maybe<SalesOrderPayload>;
  processProcessOrder: Array<Maybe<Scalars['String']>>;
  processCancelOrder: Array<Maybe<Scalars['String']>>;
  processCompleteOrder: Array<Maybe<Scalars['String']>>;
  backNewSalesOrder?: Maybe<SalesOrderPayload>;
  createOrUpdatePurchaseReceipt?: Maybe<PurchaseReceiptPayload>;
  purchaseReceiptTrackLot: Array<PurchaseReceiptTrackLot>;
  processPurchaseReceipt?: Maybe<PurchaseReceiptPayload>;
  actionProcessPurchaseReceipt: Array<Maybe<Scalars['String']>>;
  cancelPurchaseReceipt?: Maybe<PurchaseReceiptPayload>;
  actionCancelPurchaseReceipt: Array<Maybe<Scalars['String']>>;
  completePurchaseReceipt?: Maybe<PurchaseReceiptPayload>;
  actionCompletePurchaseReceipt: Array<Maybe<Scalars['String']>>;
  createOrUpdateDeliveryNoteOrder?: Maybe<DeliveryNotePayload>;
  cancelDeliveryNote?: Maybe<DeliveryNotePayload>;
  completeDeliveryNote?: Maybe<DeliveryNotePayload>;
  createInventoryDelivery?: Maybe<InventoryDeliveryPayload>;
  cancelInventoryDelivery?: Maybe<InventoryDeliveryPayload>;
  createOrUpdateExchange?: Maybe<ExchangePayload>;
  completeExchange?: Maybe<ExchangePayload>;
  changeReportConfig?: Maybe<BoolPayload>;
};


export type OrderMutationEndpointCreateOrUpdateSalesOrderArgs = {
  data: SalesOrderInput;
};


export type OrderMutationEndpointSalesOrderTrackLotArgs = {
  data: Array<SalesOrderItemInput>;
};


export type OrderMutationEndpointProcessSalesOrderArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCancelSalesOrderArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCompleteSalesOrderArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointProcessProcessOrderArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointProcessCancelOrderArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointProcessCompleteOrderArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointBackNewSalesOrderArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCreateOrUpdatePurchaseReceiptArgs = {
  data: PurchaseReceiptInput;
};


export type OrderMutationEndpointPurchaseReceiptTrackLotArgs = {
  data: Array<PurchaseReceiptItemInput>;
};


export type OrderMutationEndpointProcessPurchaseReceiptArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointActionProcessPurchaseReceiptArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointCancelPurchaseReceiptArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointActionCancelPurchaseReceiptArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointCompletePurchaseReceiptArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointActionCompletePurchaseReceiptArgs = {
  listIds: Array<Scalars['Int']>;
};


export type OrderMutationEndpointCreateOrUpdateDeliveryNoteOrderArgs = {
  data: DeliveryNoteInput;
};


export type OrderMutationEndpointCancelDeliveryNoteArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCompleteDeliveryNoteArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCreateInventoryDeliveryArgs = {
  data: InventoryDeliveryInput;
};


export type OrderMutationEndpointCancelInventoryDeliveryArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointCreateOrUpdateExchangeArgs = {
  data: ExchangeInput;
};


export type OrderMutationEndpointCompleteExchangeArgs = {
  id: Scalars['Int'];
};


export type OrderMutationEndpointChangeReportConfigArgs = {
  data: Array<InputReportDataConfig>;
};

export type OrderQueryEndpoint = {
  __typename?: 'OrderQueryEndpoint';
  salesOrderList: SalesOrderListResult;
  salesOrder: SalesOrder;
  salesOrderReturnList: SalesOrderListResult;
  salesOrderCompletedDate: Array<Maybe<SalesOrder>>;
  salesOrderProcessList: SalesOrderListResult;
  purchaseReceiptList: PurchaseReceiptListResult;
  purchaseReceiptProcessList: PurchaseReceiptListResult;
  purchaseReceipt: PurchaseReceipt;
  deliveryNotes: DeliveryNoteListResult;
  deliveryNote: DeliveryNote;
  salesOrderByTypeList: SalesOrderListResult;
  getPurchaseReceiptsByTypeAsync: PurchaseReceiptListResult;
  inventoryDeliveryList: InventoryDeliveryListResult;
  inventoryDelivery: InventoryDelivery;
  exchangeList: ExchangeListResult;
  exchange: Exchange;
  checkLotNbrDistributor: Array<SfaInventoryCustomerLot>;
  checkInventoryOnSalesOrder: Scalars['String'];
};


export type OrderQueryEndpointSalesOrderListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  salesOrderCd?: Maybe<Scalars['String']>;
};


export type OrderQueryEndpointSalesOrderArgs = {
  id: Scalars['Int'];
};


export type OrderQueryEndpointSalesOrderReturnListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<OrderStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorCd?: Maybe<Scalars['String']>;
  salesOrderReturnCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  reasonId?: Maybe<Scalars['Int']>;
};


export type OrderQueryEndpointSalesOrderCompletedDateArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type OrderQueryEndpointSalesOrderProcessListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  action: ActionType;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
};


export type OrderQueryEndpointPurchaseReceiptListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  vendorCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  employeeId?: Maybe<Scalars['Int']>;
};


export type OrderQueryEndpointPurchaseReceiptProcessListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  action: ActionType;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  vendorCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  employeeId?: Maybe<Scalars['Int']>;
};


export type OrderQueryEndpointPurchaseReceiptArgs = {
  id: Scalars['Int'];
};


export type OrderQueryEndpointDeliveryNotesArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  orderCd?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
  shipperCd?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};


export type OrderQueryEndpointDeliveryNoteArgs = {
  id: Scalars['Int'];
};


export type OrderQueryEndpointSalesOrderByTypeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type OrderQueryEndpointGetPurchaseReceiptsByTypeAsyncArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type OrderQueryEndpointInventoryDeliveryListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  orderCd?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
  shipperCd?: Maybe<Scalars['String']>;
};


export type OrderQueryEndpointInventoryDeliveryArgs = {
  id: Scalars['Int'];
};


export type OrderQueryEndpointExchangeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  employeeCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  reasonExchangeId?: Maybe<Scalars['Int']>;
};


export type OrderQueryEndpointExchangeArgs = {
  id: Scalars['Int'];
};


export type OrderQueryEndpointCheckLotNbrDistributorArgs = {
  distributorId: Scalars['Int'];
  productId: Scalars['Int'];
  expirationDate?: Maybe<Scalars['Date']>;
};


export type OrderQueryEndpointCheckInventoryOnSalesOrderArgs = {
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
};

export enum OrderSource {
  Manual = 'MANUAL',
  Mobile = 'MOBILE'
}

export enum OrderStatus {
  New = 'NEW',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Holding = 'HOLDING'
}

export enum OrderType {
  Cs = 'CS',
  Cr = 'CR'
}

export type OtherProduct = {
  __typename?: 'OtherProduct';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  measureUnitId: Scalars['String'];
  unit: Scalars['String'];
};

export type OtherProductInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  measureUnitId: Scalars['String'];
};

export type OtherProductPayload = {
  __typename?: 'OtherProductPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<OtherProduct>;
};

export type OtherProductStockCount = {
  __typename?: 'OtherProductStockCount';
  id: Scalars['Int'];
  otherProductId: Scalars['Int'];
  checkInId?: Maybe<Scalars['Int']>;
  checkInCommandoId?: Maybe<Scalars['Int']>;
  qty: Scalars['Decimal'];
  otherProduct: OtherProduct;
  checkIn: CheckIn;
  checkInCommando?: Maybe<CheckInCommando>;
};

export type OtherProductStockCountListResult = {
  __typename?: 'OtherProductStockCountListResult';
  total: Scalars['Int'];
  data: Array<OtherProductStockCount>;
};

export type OutletDetailsDto = {
  __typename?: 'OutletDetailsDto';
  name: Scalars['String'];
  photo?: Maybe<Photo>;
  customerClass?: Maybe<Scalars['String']>;
  customerPriceClass?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  contactName: Scalars['String'];
  routeCode: Scalars['String'];
  salesman: Scalars['String'];
  cdSalesman: Scalars['String'];
  distributors: Array<Distributor>;
  checkIns: Array<CheckIn>;
  orders: Array<OrderDto>;
  fullAddress: Scalars['String'];
};

export type OutletDto = {
  __typename?: 'OutletDto';
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  index?: Maybe<Scalars['Int']>;
  isInCallPlan: Scalars['Boolean'];
  hasOrder: Scalars['Boolean'];
  hasCheckedIn: Scalars['Boolean'];
  details: OutletDetailsDto;
};

export type PayloadMessage = {
  __typename?: 'PayloadMessage';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['Int'];
  url: Scalars['String'];
};


export type PhotoUrlArgs = {
  size?: Maybe<PhotoSize>;
};

export type PhotoPayload = {
  __typename?: 'PhotoPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Photo>;
};

export enum PhotoSize {
  XtraSmall = 'XTRA_SMALL',
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  XtraLarge = 'XTRA_LARGE',
  Original = 'ORIGINAL'
}

export type Position = {
  __typename?: 'Position';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  positionPermissions: Array<PositionPermission>;
};

export type PositionInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  positionPermissions: Array<PositionPermissionInput>;
};

export type PositionPayload = {
  __typename?: 'PositionPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Position>;
};

export type PositionPermission = {
  __typename?: 'PositionPermission';
  id: Scalars['Int'];
  permission: Scalars['String'];
};

export type PositionPermissionInput = {
  id: Scalars['Int'];
  permission: Scalars['String'];
};

export type ProcedureData = {
  __typename?: 'ProcedureData';
  name?: Maybe<Array<Scalars['String']>>;
  data?: Maybe<Array<Scalars['String']>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  basePrice: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  salesUnitId: Scalars['String'];
  purchaseUnitId: Scalars['String'];
  isPop: Scalars['Boolean'];
  isLot: Scalars['Boolean'];
  isExpirationDate: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  tax: Scalars['Decimal'];
  photo?: Maybe<Photo>;
  productAttrDetails: Array<ProductAttrDetail>;
  productMeasureUnits: Array<ProductMeasureUnit>;
};

export type ProductAttrDetail = {
  __typename?: 'ProductAttrDetail';
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type ProductAttrDetailInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type ProductDashboard = {
  __typename?: 'ProductDashboard';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  totalPrice: Scalars['Decimal'];
  product: Product;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isBundle: Scalars['Boolean'];
  productGroupItems: Array<ProductGroupItem>;
};

export type ProductGroupInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isBundle: Scalars['Boolean'];
  productGroupItems: Array<ProductGroupItemInput>;
};

export type ProductGroupItem = {
  __typename?: 'ProductGroupItem';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type ProductGroupItemInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type ProductGroupPayload = {
  __typename?: 'ProductGroupPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<ProductGroup>;
};

export type ProductInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  basePrice: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  salesUnitId: Scalars['String'];
  purchaseUnitId: Scalars['String'];
  isPop: Scalars['Boolean'];
  isLot: Scalars['Boolean'];
  isExpirationDate: Scalars['Boolean'];
  photoId?: Maybe<Scalars['Int']>;
  tax: Scalars['Decimal'];
  productAttrDetails: Array<ProductAttrDetailInput>;
  productMeasureUnits: Array<ProductMeasureUnitInput>;
};

export type ProductMeasureUnit = {
  __typename?: 'ProductMeasureUnit';
  id: Scalars['Int'];
  fromUnitId: Scalars['String'];
  saleable: Scalars['Boolean'];
  toUnitId: Scalars['String'];
  rate: Scalars['Decimal'];
};

export type ProductMeasureUnitInput = {
  id: Scalars['Int'];
  fromUnitId: Scalars['String'];
  saleable: Scalars['Boolean'];
  toUnitId: Scalars['String'];
  rate: Scalars['Decimal'];
};

export type ProductPayload = {
  __typename?: 'ProductPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Product>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  id: Scalars['String'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  price: Scalars['Decimal'];
};

export type ProductStockCount = {
  __typename?: 'ProductStockCount';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  checkInId?: Maybe<Scalars['Int']>;
  checkInCommandoId?: Maybe<Scalars['Int']>;
  qty: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  unitId: Scalars['String'];
  baseUnitId: Scalars['String'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  product: Product;
  checkIn: CheckIn;
  checkInCommando?: Maybe<CheckInCommando>;
};

export type SellProductStockCount = {
  __typename?: 'ProductStockCount';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  checkInId?: Maybe<Scalars['Int']>;
  checkInCommandoId?: Maybe<Scalars['Int']>;
  sellQty: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  unitId: Scalars['String'];
  baseUnitId: Scalars['String'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  product: Product;
  checkIn: CheckIn;
  checkInCommando?: Maybe<CheckInCommando>;
};

export type ProductStockCountListResult = {
  __typename?: 'ProductStockCountListResult';
  total: Scalars['Int'];
  data: Array<ProductStockCount>;
};

export type ProgramMutationEndpoint = {
  __typename?: 'ProgramMutationEndpoint';
  deleteVisual?: Maybe<IntPayload>;
  createOrUpdateVisual?: Maybe<VisualPayload>;
  releaseVisual?: Maybe<VisualPayload>;
  closeVisual?: Maybe<VisualPayload>;
  deleteKpiFormula?: Maybe<IntPayload>;
  createOrUpdateKpiFormula?: Maybe<KpiFormulaPayload>;
  releaseKpiFormula?: Maybe<KpiFormulaPayload>;
  closeKpiFormula?: Maybe<KpiFormulaPayload>;
  deleteKpiConfig?: Maybe<IntPayload>;
  createOrUpdateKpiConfig?: Maybe<KpiConfigPayload>;
  releaseKpiConfig?: Maybe<KpiConfigPayload>;
  calculateKpiConfig?: Maybe<BoolPayload>;
  closeKpiConfig?: Maybe<KpiConfigPayload>;
  deleteSurvey?: Maybe<IntPayload>;
  createOrUpdateSurvey?: Maybe<SurveyPayload>;
  releaseSurvey?: Maybe<SurveyPayload>;
  closeSurvey?: Maybe<SurveyPayload>;
  deleteTrade?: Maybe<IntPayload>;
  createOrUpdateTrade?: Maybe<TradePayload>;
  releaseTrade?: Maybe<TradePayload>;
  closeTrade?: Maybe<TradePayload>;
  deleteDiscount?: Maybe<IntPayload>;
  createOrUpdateDiscount?: Maybe<DiscountPayload>;
  releaseDiscount?: Maybe<DiscountPayload>;
  closeDiscount?: Maybe<DiscountPayload>;
  promotionPrioritization?: Maybe<BoolPayload>;
  createOrUpdateTradeReward?: Maybe<TradeRewardPayload>;
  acceptTradeReward?: Maybe<TradeRewardPayload>;
  rejectTradeReward?: Maybe<TradeRewardPayload>;
  tradeRewardProductTrackLot: Array<TradeRewardProduct>;
};


export type ProgramMutationEndpointDeleteVisualArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateVisualArgs = {
  data: VisualInput;
};


export type ProgramMutationEndpointReleaseVisualArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseVisualArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointDeleteKpiFormulaArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateKpiFormulaArgs = {
  data: KpiFormulaInput;
};


export type ProgramMutationEndpointReleaseKpiFormulaArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseKpiFormulaArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointDeleteKpiConfigArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateKpiConfigArgs = {
  data: KpiConfigInput;
};


export type ProgramMutationEndpointReleaseKpiConfigArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCalculateKpiConfigArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseKpiConfigArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointDeleteSurveyArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateSurveyArgs = {
  data: SurveyInput;
};


export type ProgramMutationEndpointReleaseSurveyArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseSurveyArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointDeleteTradeArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateTradeArgs = {
  data: TradeInput;
};


export type ProgramMutationEndpointReleaseTradeArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseTradeArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointDeleteDiscountArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCreateOrUpdateDiscountArgs = {
  data: DiscountInput;
};


export type ProgramMutationEndpointReleaseDiscountArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointCloseDiscountArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointPromotionPrioritizationArgs = {
  input: Array<DiscountPrioritizationInput>;
};


export type ProgramMutationEndpointCreateOrUpdateTradeRewardArgs = {
  data: TradeRewardInput;
};


export type ProgramMutationEndpointAcceptTradeRewardArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointRejectTradeRewardArgs = {
  id: Scalars['Int'];
};


export type ProgramMutationEndpointTradeRewardProductTrackLotArgs = {
  data: Array<SalesOrderItemInput>;
};

export type ProgramQueryEndpoint = {
  __typename?: 'ProgramQueryEndpoint';
  visualList: VisualListResult;
  visual: Visual;
  kpiFormulas: Array<KpiFormula>;
  kpiFormula: KpiFormula;
  kpiConfigList: KpiConfigListResult;
  kpiConfig: KpiConfig;
  surveyList: SurveyListResult;
  survey: Survey;
  tradeList: TradeListResult;
  trade: Trade;
  discountList: DiscountListResult;
  discountPrioritizationList: DiscountListResult;
  discount: Discount;
  customerDiscounts: Array<Discount>;
  allCustomerDiscounts: Array<Discount>;
  tradesAchieved: TradeParticipationListResult;
  tradeReward: TradeReward;
  tradesReward: TradeRewardListResult;
};


export type ProgramQueryEndpointVisualListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  visualCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
};


export type ProgramQueryEndpointVisualArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointKpiFormulasArgs = {
  status?: Maybe<ProgramStatus>;
  formulas?: Maybe<KpiFormulaType>;
  name?: Maybe<Scalars['String']>;
  kpiFormulaCd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
};


export type ProgramQueryEndpointKpiFormulaArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointKpiConfigListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  kpiConfigCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ProgramQueryEndpointKpiConfigArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointSurveyListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  surveyCd?: Maybe<Scalars['String']>;
};


export type ProgramQueryEndpointSurveyArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointTradeListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  tradeCd?: Maybe<Scalars['String']>;
};


export type ProgramQueryEndpointTradeArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointDiscountListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  discountCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  externalReference?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  isBundle?: Maybe<Scalars['Int']>;
  isFirstOrder?: Maybe<Scalars['Int']>;
};


export type ProgramQueryEndpointDiscountPrioritizationListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ProgramQueryEndpointDiscountArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointCustomerDiscountsArgs = {
  customerId: Scalars['Int'];
};


export type ProgramQueryEndpointAllCustomerDiscountsArgs = {
  customerId: Scalars['Int'];
};


export type ProgramQueryEndpointTradesAchievedArgs = {
  customerId: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type ProgramQueryEndpointTradeRewardArgs = {
  id: Scalars['Int'];
};


export type ProgramQueryEndpointTradesRewardArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export enum ProgramStatus {
  Open = 'OPEN',
  Released = 'RELEASED',
  Closed = 'CLOSED',
  Expire = 'EXPIRE'
}

export type Province = {
  __typename?: 'Province';
  id: Scalars['Int'];
  name: Scalars['String'];
  districts: Array<District>;
};

export type PurchaseReceipt = {
  __typename?: 'PurchaseReceipt';
  id: Scalars['Int'];
  cd: Scalars['String'];
  orderDate: Scalars['DateTime'];
  orderType: OrderType;
  orderAt: Scalars['DateTimeOffset'];
  orderStatus: OrderStatus;
  orderTotal: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  salesOrderId?: Maybe<Scalars['Int']>;
  vendor: Distributor;
  distributor: Distributor;
  employee?: Maybe<Employee>;
  creator?: Maybe<Employee>;
  orderItems?: Maybe<Array<PurchaseReceiptItem>>;
  purchaseReceiptTrackLots?: Maybe<Array<PurchaseReceiptTrackLot>>;
  deliveryNote?: Maybe<DeliveryNote>;
};

export type PurchaseReceiptInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  orderDate: Scalars['Date'];
  orderType: OrderType;
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  vendorId: Scalars['Int'];
  orderItems: Array<PurchaseReceiptItemInput>;
  purchaseReceiptTrackLots: Array<PurchaseReceiptTrackLotInput>;
};

export type PurchaseReceiptItem = {
  __typename?: 'PurchaseReceiptItem';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  product: Product;
  warehouse: Warehouse;
  purchaseReceipt: PurchaseReceipt;
};

export type PurchaseReceiptItemInput = {
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
};

export type PurchaseReceiptItemListResult = {
  __typename?: 'PurchaseReceiptItemListResult';
  total: Scalars['Int'];
  data: Array<PurchaseReceiptItem>;
};

export type PurchaseReceiptListResult = {
  __typename?: 'PurchaseReceiptListResult';
  total: Scalars['Int'];
  data: Array<PurchaseReceipt>;
};

export type PurchaseReceiptPayload = {
  __typename?: 'PurchaseReceiptPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<PurchaseReceipt>;
};

export type PurchaseReceiptTrackLot = {
  __typename?: 'PurchaseReceiptTrackLot';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  product?: Maybe<Product>;
  warehouse?: Maybe<Warehouse>;
  purchaseReceipt: PurchaseReceipt;
};

export type PurchaseReceiptTrackLotInput = {
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  ping?: Maybe<Scalars['String']>;
  me: Me;
  sfa: SfaQueryEndpoint;
  dbd: DashboardQueryEndpoint;
  dm: DigitalMapQueryEndpoint;
  mcp: McpQueryEndpoint;
  p: ProgramQueryEndpoint;
  md: MasterDataQueryEndpoint;
  excel: ExcelQueryEndpoint;
  exc: ExcelCustomQueryEndpoint;
  o: OrderQueryEndpoint;
  in: InventoryQueryEndpoint;
  rp: ReportQueryEndpoint;
  ctrl: ControlQueryEndpoint;
  productPrices: Array<ProductPrice>;
  workingDistributors: Array<Distributor>;
  sellersDistributorList: DistributorListResult;
  workingCustomerList: CustomerListResult;
  workingCustomerListByMcp: CustomerListResult;
  workingSalesmans: Array<Employee>;
  workingSalesmansByMcp: Array<Employee>;
};


export type QueryProductPricesArgs = {
  date?: Maybe<Scalars['Date']>;
  customerPriceClassId?: Maybe<Scalars['Int']>;
};


export type QuerySellersDistributorListArgs = {
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
};


export type QueryWorkingCustomerListArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  isDistributor?: Maybe<Scalars['Boolean']>;
};


export type QueryWorkingCustomerListByMcpArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  isDistributor?: Maybe<Scalars['Boolean']>;
};


export type QueryWorkingSalesmansArgs = {
  distributorId: Scalars['Int'];
};


export type QueryWorkingSalesmansByMcpArgs = {
  distributorId: Scalars['Int'];
  customerId: Scalars['Int'];
};

export type ReasonEditOrder = {
  __typename?: 'ReasonEditOrder';
  id: Scalars['Int'];
  name: Scalars['String'];
  reasonType: ReasonType;
  description: Scalars['String'];
};

export type ReasonEditOrderInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  reasonType: ReasonType;
  description: Scalars['String'];
};

export type ReasonEditOrderListResult = {
  __typename?: 'ReasonEditOrderListResult';
  total: Scalars['Int'];
  data: Array<ReasonEditOrder>;
};

export type ReasonEditOrderPayload = {
  __typename?: 'ReasonEditOrderPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<ReasonEditOrder>;
};

export enum ReasonType {
  EditOrder = 'EDIT_ORDER',
  Exchange = 'EXCHANGE',
  Issue = 'ISSUE',
  Adjust = 'ADJUST',
  Receipt = 'RECEIPT',
  Transfer = 'TRANSFER',
  Return = 'RETURN',
  MbOrderNote = 'MB_ORDER_NOTE'
}

export type ReportDataConfig = {
  __typename?: 'ReportDataConfig';
  id: Scalars['Int'];
  name: Scalars['String'];
  procedureName: Scalars['String'];
  isWeb: Scalars['Boolean'];
  isMobile: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isAlive?: Maybe<Scalars['Boolean']>;
};

export type ReportKpiEvaluationSalesForce = {
  __typename?: 'ReportKpiEvaluationSalesForce';
  employeeId?: Maybe<Scalars['Int']>;
  kpiConfigId: Scalars['Int'];
  kpiFormulaId: Scalars['Int'];
  rate: Scalars['Decimal'];
  result: Scalars['Decimal'];
  employee?: Maybe<Employee>;
  kpiFormula: KpiFormula;
  kpiConfig: KpiConfig;
};

export type ReportKpiEvaluationSalesForceListResult = {
  __typename?: 'ReportKpiEvaluationSalesForceListResult';
  total: Scalars['Int'];
  data: Array<ReportKpiEvaluationSalesForce>;
};

export type ReportLoyaltyEvaluation = {
  __typename?: 'ReportLoyaltyEvaluation';
  customerId: Scalars['Int'];
  tradeId: Scalars['Int'];
  tradeResult: Scalars['Decimal'];
  level: Scalars['Int'];
  tradeTarget: Scalars['Decimal'];
  visualResult: Scalars['Decimal'];
  tradePassed: Scalars['Boolean'];
  visualPassed: Scalars['Boolean'];
  totalPassed: Scalars['Boolean'];
  visualId?: Maybe<Scalars['Int']>;
  customer: Customer;
  trade: Trade;
  visual?: Maybe<Visual>;
};

export type ReportLoyaltyEvaluationListResult = {
  __typename?: 'ReportLoyaltyEvaluationListResult';
  total: Scalars['Int'];
  data: Array<ReportLoyaltyEvaluation>;
};

export type ReportLoyaltyList = {
  __typename?: 'ReportLoyaltyList';
  tradeId: Scalars['Int'];
  tradeLevelId?: Maybe<Scalars['Int']>;
  tradeLevelProductId?: Maybe<Scalars['Int']>;
  trade: Trade;
  tradeLevel?: Maybe<TradeLevel>;
  tradeLevelProduct?: Maybe<TradeLevelProduct>;
};

export type ReportLoyaltyListListResult = {
  __typename?: 'ReportLoyaltyListListResult';
  total: Scalars['Int'];
  data: Array<ReportLoyaltyList>;
};

export type ReportOrderRateByStatus = {
  __typename?: 'ReportOrderRateByStatus';
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
  salesmanId?: Maybe<Scalars['Int']>;
  totalSalesOrder: Scalars['Int'];
  totalOrderComplete: Scalars['Int'];
  totalOrderPending: Scalars['Int'];
  totalOrderNew: Scalars['Int'];
  totalOrderCancel: Scalars['Int'];
  totalOrderHolding: Scalars['Int'];
  distributor: Distributor;
  salesman?: Maybe<Employee>;
};

export type ReportOrderRateByStatusListResult = {
  __typename?: 'ReportOrderRateByStatusListResult';
  total: Scalars['Int'];
  data: Array<ReportOrderRateByStatus>;
};

export type ReportPercentOrderComplete = {
  __typename?: 'ReportPercentOrderComplete';
  salesmanId?: Maybe<Scalars['Int']>;
  customerId: Scalars['Int'];
  distributorId: Scalars['Int'];
  totalComplete: Scalars['Decimal'];
  totalOrder: Scalars['Decimal'];
  totalCancel: Scalars['Decimal'];
  percentComplete: Scalars['Decimal'];
  percentCancel: Scalars['Decimal'];
  customer: Customer;
  salesman?: Maybe<Employee>;
  distributor: Distributor;
};

export type ReportPercentOrderCompleteListResult = {
  __typename?: 'ReportPercentOrderCompleteListResult';
  total: Scalars['Int'];
  data: Array<ReportPercentOrderComplete>;
};

export type ReportPerformanceDistributor = {
  __typename?: 'ReportPerformanceDistributor';
  distributorId: Scalars['Int'];
  productId: Scalars['Int'];
  qtySellOut: Scalars['Decimal'];
  qtySellIn: Scalars['Decimal'];
  percentOutIn: Scalars['Decimal'];
  distributor?: Maybe<Distributor>;
  product?: Maybe<Product>;
};

export type ReportPerformanceDistributorListResult = {
  __typename?: 'ReportPerformanceDistributorListResult';
  total: Scalars['Int'];
  data: Array<ReportPerformanceDistributor>;
};

export type ReportProcedure = {
  __typename?: 'ReportProcedure';
  name: Scalars['String'];
};

export type ReportPromotionByDistributor = {
  __typename?: 'ReportPromotionByDistributor';
  discountId: Scalars['Int'];
  distributorId: Scalars['Int'];
  amount: Scalars['Decimal'];
  percentAmount: Scalars['Decimal'];
  productAmount: Scalars['Decimal'];
  total: Scalars['Decimal'];
  distributor: Distributor;
  discount: Discount;
};

export type ReportPromotionByDistributorListResult = {
  __typename?: 'ReportPromotionByDistributorListResult';
  total: Scalars['Int'];
  data: Array<ReportPromotionByDistributor>;
};

export type ReportPromotionFollow = {
  __typename?: 'ReportPromotionFollow';
  salesOrderDiscountId: Scalars['Int'];
  salesOrderDiscountLevelId: Scalars['Int'];
  salesOrderDiscount: SalesOrderDiscount;
  salesOrderDiscountLevel: SalesOrderDiscountLevel;
};

export type ReportPromotionFollowListResult = {
  __typename?: 'ReportPromotionFollowListResult';
  total: Scalars['Int'];
  data: Array<ReportPromotionFollow>;
};

export type ReportPromotionList = {
  __typename?: 'ReportPromotionList';
  discountId: Scalars['Int'];
  discountLevelId?: Maybe<Scalars['Int']>;
  discountLevelRewardId?: Maybe<Scalars['Int']>;
  discount: Discount;
  discountLevel?: Maybe<DiscountLevel>;
  discountLevelReward?: Maybe<DiscountLevelReward>;
};

export type ReportPromotionListListResult = {
  __typename?: 'ReportPromotionListListResult';
  total: Scalars['Int'];
  data: Array<ReportPromotionList>;
};

export type ReportPromotionSummarizing = {
  __typename?: 'ReportPromotionSummarizing';
  discountId: Scalars['Int'];
  distributorId: Scalars['Int'];
  customerId: Scalars['Int'];
  amountDiscount?: Maybe<Scalars['Decimal']>;
  amountOrder: Scalars['Decimal'];
  amount?: Maybe<Scalars['Decimal']>;
  amountPercentage?: Maybe<Scalars['Decimal']>;
  amountProduct?: Maybe<Scalars['Decimal']>;
  distributor: Distributor;
  customer: Customer;
  discount: Discount;
  salesOrderFreeItems?: Maybe<Array<Maybe<SalesOrderFreeItem>>>;
};

export type ReportPromotionSummarizingListResult = {
  __typename?: 'ReportPromotionSummarizingListResult';
  total: Scalars['Int'];
  data: Array<ReportPromotionSummarizing>;
};

export type ReportPurchaseByProduct = {
  __typename?: 'ReportPurchaseByProduct';
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  distributorId: Scalars['Int'];
  productId: Scalars['Int'];
  totalQty: Scalars['Decimal'];
  totalPrice: Scalars['Decimal'];
  totalUnit: Scalars['String'];
  distributor: Distributor;
  warehouse: Warehouse;
  product: Product;
};

export type ReportQualityOutlet = {
  __typename?: 'ReportQualityOutlet';
  customerId: Scalars['Int'];
  salesmanId?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  salesman?: Maybe<Employee>;
};

export type ReportQualityOutletListResult = {
  __typename?: 'ReportQualityOutletListResult';
  total: Scalars['Int'];
  data: Array<ReportQualityOutlet>;
};

export type ReportQueryEndpoint = {
  __typename?: 'ReportQueryEndpoint';
  masterDataEmployees: Array<Employee>;
  masterDataCustomers: CustomerListResult;
  masterDataDistributors: Array<Distributor>;
  masterDataProducts: Array<Product>;
  purchaseOrderDetails: PurchaseReceiptItemListResult;
  purchaseByProducts: Array<ReportPurchaseByProduct>;
  salesOrderDetails: SalesOrderItemListResult;
  salesByProducts: ReportSalesByProductListResult;
  salesByCustomers: ReportSalesByCustomerListResult;
  salesBySalesForce: Array<ReportSalesBySalesForce>;
  orderRateByStatus: ReportOrderRateByStatusListResult;
  reportPercentOrderComplete: ReportPercentOrderCompleteListResult;
  reportVisitCustomer: Array<ReportVisitCustomer>;
  reportWarehouseXnt: Array<ReportWarehouseXnt>;
  reportWarehouseXntShowDetails: ReportWarehouseXntDetailsListResult;
  reportWarehouseXntDetailForDateShowListSales: ReportWarehouseXntDetailsListResult;
  currentWarehouseProducts: Array<WarehouseProduct>;
  pendingWarehouseProducts: Array<WarehouseProductPending>;
  promotionList: ReportPromotionListListResult;
  followPromotions: ReportPromotionFollowListResult;
  reportPromotionByDistributor: ReportPromotionByDistributorListResult;
  reportPromotionSummarizingMoney: ReportPromotionSummarizingListResult;
  reportPromotionSummarizingProduct: ReportPromotionSummarizingListResult;
  reportSalesOrderDiscountLine: SalesOrderDiscountLineListResult;
  loyaltyList: ReportLoyaltyListListResult;
  followLoyaltyRegistration: TradeParticipationListResult;
  reportLoyaltyEvaluation: ReportLoyaltyEvaluationListResult;
  followVisuals: VisualPhotoListResult;
  reportVisualList: ReportVisualListListResult;
  reportKpis: KpiConfigItemListResult;
  filterKpiConfigReport: KpiConfigListResult;
  reportKpiEvaluationSalesForce: ReportKpiEvaluationSalesForceListResult;
  mcpLineReport: McpLineListResult;
  reportVisitPlanSummary: ReportVisitPlanSummaryListResult;
  reportVisitPlanDetails: ReportVisitPlanDetailsListResult;
  productStockCountReport: ProductStockCountListResult;
  otherProductStockCountReport: OtherProductStockCountListResult;
  surveyListReport: ReportSurveyListListResult;
  customerSurveyReport: CustomerSurveyListResult;
  reportQualityOutlet: ReportQualityOutletListResult;
  reportPerformanceDistributorList: ReportPerformanceDistributorListResult;
  reportProcedure: Array<ReportProcedure>;
  reportDataConfig: Array<ReportDataConfig>;
  procedureData: Array<ProcedureData>;
};


export type ReportQueryEndpointMasterDataEmployeesArgs = {
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  positionId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointMasterDataCustomersArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  customerClassId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  sourceCustomer?: Maybe<CustomerSource>;
};


export type ReportQueryEndpointMasterDataDistributorsArgs = {
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  distributorTypeId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointMasterDataProductsArgs = {
  filter?: Maybe<Scalars['String']>;
  cd?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attrDetailId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointPurchaseOrderDetailsArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointPurchaseByProductsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointSalesOrderDetailsArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  salesmanCd?: Maybe<Scalars['String']>;
};


export type ReportQueryEndpointSalesByProductsArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointSalesByCustomersArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointSalesBySalesForceArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointOrderRateByStatusArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
};


export type ReportQueryEndpointReportPercentOrderCompleteArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportVisitCustomerArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ReportQueryEndpointReportWarehouseXntArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type ReportQueryEndpointReportWarehouseXntShowDetailsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportWarehouseXntDetailForDateShowListSalesArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  type: Scalars['Int'];
};


export type ReportQueryEndpointCurrentWarehouseProductsArgs = {
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  expireDate?: Maybe<Scalars['Date']>;
};


export type ReportQueryEndpointPendingWarehouseProductsArgs = {
  source?: Maybe<TransactionSource>;
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};


export type ReportQueryEndpointPromotionListArgs = {
  date?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointFollowPromotionsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportPromotionByDistributorArgs = {
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportPromotionSummarizingMoneyArgs = {
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportPromotionSummarizingProductArgs = {
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportSalesOrderDiscountLineArgs = {
  discountId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerCd?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointLoyaltyListArgs = {
  date?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  target?: Maybe<TradeApplyTarget>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointFollowLoyaltyRegistrationArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  startDateTrade?: Maybe<Scalars['Date']>;
  endDateTrade?: Maybe<Scalars['Date']>;
  statusTrade?: Maybe<ProgramStatus>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportLoyaltyEvaluationArgs = {
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  breakBy?: Maybe<BreakBy>;
  tradePassed?: Maybe<Scalars['Boolean']>;
  visualPassed?: Maybe<Scalars['Boolean']>;
  totalPassed?: Maybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointFollowVisualsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  visualId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportVisualListArgs = {
  visualId?: Maybe<Scalars['Int']>;
  minPass?: Maybe<Scalars['Int']>;
  maxPass?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportKpisArgs = {
  employeeId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  target?: Maybe<KpiApplyTarget>;
  kpiConfigId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointFilterKpiConfigReportArgs = {
  target?: Maybe<KpiApplyTarget>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportKpiEvaluationSalesForceArgs = {
  employeeId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  kpiName?: Maybe<Scalars['String']>;
  minResult?: Maybe<Scalars['Decimal']>;
  maxResult?: Maybe<Scalars['Decimal']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointMcpLineReportArgs = {
  status?: Maybe<ProgramStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerFilter?: Maybe<Scalars['String']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportVisitPlanSummaryArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportVisitPlanDetailsArgs = {
  date: Scalars['Date'];
  salesmanId: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointProductStockCountReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointOtherProductStockCountReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  otherProductId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointSurveyListReportArgs = {
  date?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointCustomerSurveyReportArgs = {
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ProgramStatus>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportQualityOutletArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesmanId?: Maybe<Scalars['Int']>;
  attrDetailId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointReportPerformanceDistributorListArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
};


export type ReportQueryEndpointProcedureDataArgs = {
  procedureName?: Maybe<Scalars['String']>;
};

export type ReportSalesByCustomer = {
  __typename?: 'ReportSalesByCustomer';
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  productId: Scalars['Int'];
  totalQty: Scalars['Decimal'];
  totalSellQty: Scalars['Decimal'];
  totalKMQty: Scalars['Decimal'];
  totalPrice: Scalars['Decimal'];
  totalUnit: Scalars['String'];
  customer: Customer;
  product: Product;
};

export type ReportSalesByCustomerListResult = {
  __typename?: 'ReportSalesByCustomerListResult';
  total: Scalars['Int'];
  data: Array<ReportSalesByCustomer>;
};

export type ReportSalesByProduct = {
  __typename?: 'ReportSalesByProduct';
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  distributorId: Scalars['Int'];
  productId: Scalars['Int'];
  totalQty: Scalars['Decimal'];
  totalSellQty: Scalars['Decimal'];
  totalKMQty: Scalars['Decimal'];
  totalPrice: Scalars['Decimal'];
  totalUnit: Scalars['String'];
  distributor: Distributor;
  warehouse: Warehouse;
  product: Product;
};

export type ReportSalesByProductListResult = {
  __typename?: 'ReportSalesByProductListResult';
  total: Scalars['Int'];
  data: Array<ReportSalesByProduct>;
};

export type ReportSalesBySalesForce = {
  __typename?: 'ReportSalesBySalesForce';
  id: Scalars['Int'];
  salesmanId?: Maybe<Scalars['Int']>;
  productId: Scalars['Int'];
  totalQty: Scalars['Decimal'];
  totalSellQty: Scalars['Decimal'];
  totalKMQty: Scalars['Decimal'];
  totalPrice: Scalars['Decimal'];
  totalUnit: Scalars['String'];
  salesman: Employee;
  product: Product;
};

export type ReportSurveyList = {
  __typename?: 'ReportSurveyList';
  surveyId: Scalars['Int'];
  surveyQuestionId?: Maybe<Scalars['Int']>;
  survey: Survey;
  surveyQuestion?: Maybe<SurveyQuestion>;
};

export type ReportSurveyListListResult = {
  __typename?: 'ReportSurveyListListResult';
  total: Scalars['Int'];
  data: Array<ReportSurveyList>;
};

export type ReportVisitCustomer = {
  __typename?: 'ReportVisitCustomer';
  checkinId: Scalars['Int'];
  mcpId: Scalars['Int'];
  visitDate?: Maybe<Scalars['DateTime']>;
  salesmanId: Scalars['Int'];
  salesmanCd: Scalars['String'];
  salesmanName: Scalars['String'];
  positionId: Scalars['Int'];
  positionName: Scalars['String'];
  customerId: Scalars['Int'];
  customerCd: Scalars['String'];
  customerStatus: Scalars['Int'];
  customerName: Scalars['String'];
  customerContactName: Scalars['String'];
  customerPhone: Scalars['String'];
  customerAddress: Scalars['String'];
  wardId: Scalars['Int'];
  wardName: Scalars['String'];
  districtId: Scalars['Int'];
  districtName: Scalars['String'];
  provinceId: Scalars['Int'];
  provinceName: Scalars['String'];
  customerLat: Scalars['Float'];
  customerLng: Scalars['Float'];
  customerLinkAvatar: Scalars['String'];
  quyMoDiemBan: Scalars['String'];
  vungMien: Scalars['String'];
  loaiHinhDiemBan: Scalars['String'];
  kenhBanHang: Scalars['String'];
  npp: Scalars['String'];
  diemBanThuocNpp: Scalars['String'];
  checkInAt?: Maybe<Scalars['DateTime']>;
  checkInLat: Scalars['Float'];
  checkInLng: Scalars['Float'];
  checkInAccuracy: Scalars['Float'];
  distance: Scalars['Float'];
  checkOutAt?: Maybe<Scalars['DateTime']>;
  checkOutLat: Scalars['Float'];
  checkOutLng: Scalars['Float'];
  checkInAtTime: Scalars['String'];
  checkOutAtTime: Scalars['String'];
  checkTime: Scalars['Int'];
  checkOutAccuracy: Scalars['Float'];
  checkOutReasonId: Scalars['Int'];
  checkOutReasonText: Scalars['String'];
  movingSeconds: Scalars['Int'];
  isVisit: Scalars['Boolean'];
  linkPhoto_Visual1: Scalars['String'];
  linkPhoto_Visual2: Scalars['String'];
  checkInType: Scalars['String'];
};

export type ReportVisitPlanDetails = {
  __typename?: 'ReportVisitPlanDetails';
  mcpId: Scalars['Int'];
  date: Scalars['DateTime'];
  customerId: Scalars['Int'];
  checkInIndex: Scalars['Int'];
  isInRoute: Scalars['Boolean'];
  checkInAt: Scalars['DateTimeOffset'];
  checkOutAt: Scalars['DateTimeOffset'];
  totalMinutes: Scalars['String'];
  movingMinutes: Scalars['String'];
  orderTotal?: Maybe<Scalars['Decimal']>;
  visualId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  mcp: Mcp;
  customer: Customer;
  photo?: Maybe<Photo>;
  visual?: Maybe<Visual>;
};

export type ReportVisitPlanDetailsListResult = {
  __typename?: 'ReportVisitPlanDetailsListResult';
  total: Scalars['Int'];
  data: Array<ReportVisitPlanDetails>;
};

export type ReportVisitPlanSummary = {
  __typename?: 'ReportVisitPlanSummary';
  mcpId: Scalars['Int'];
  date: Scalars['DateTime'];
  inRouteRequiredVisitCount: Scalars['Int'];
  inRouteVisitedCount: Scalars['Int'];
  outRouteVisitedCount: Scalars['Int'];
  orderCount: Scalars['Int'];
  orderTotal: Scalars['Decimal'];
  inRouteOrderCount: Scalars['Int'];
  inRouteOrderTotal: Scalars['Decimal'];
  outRouteOrderCount: Scalars['Int'];
  outRouteOrderTotal: Scalars['Decimal'];
  visitTimeMinutes: Scalars['Seconds'];
  movingTimeMinutes: Scalars['Seconds'];
  visitTimeAverageMinutes: Scalars['Seconds'];
  mcp: Mcp;
};

export type ReportVisitPlanSummaryListResult = {
  __typename?: 'ReportVisitPlanSummaryListResult';
  total: Scalars['Int'];
  data: Array<ReportVisitPlanSummary>;
};

export type ReportVisualList = {
  __typename?: 'ReportVisualList';
  customerId: Scalars['Int'];
  visualId: Scalars['Int'];
  countPass: Scalars['Int'];
  totalPhoto: Scalars['Int'];
  customer: Customer;
  visualPhotos: Array<Maybe<VisualPhoto>>;
  visual: Visual;
};

export type ReportVisualListListResult = {
  __typename?: 'ReportVisualListListResult';
  total: Scalars['Int'];
  data: Array<ReportVisualList>;
};

export type ReportWarehouseXnt = {
  __typename?: 'ReportWarehouseXnt';
  id: Scalars['Int'];
  dataDate: Scalars['DateTime'];
  productId: Scalars['Int'];
  warehouseType?: Maybe<Scalars['String']>;
  ownerCd?: Maybe<Scalars['String']>;
  ownerId: Scalars['Int'];
  ownerName?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  openQty: Scalars['Decimal'];
  qtySalesOut: Scalars['Decimal'];
  qtySalesIn: Scalars['Decimal'];
  qtySalesReturn: Scalars['Decimal'];
  qtyReceiptOther: Scalars['Decimal'];
  qtyIssueOther: Scalars['Decimal'];
  qtyReceiptAdj: Scalars['Decimal'];
  qtyIssueOAdj: Scalars['Decimal'];
  qtyReceiptTransfer: Scalars['Decimal'];
  qtyReturnPo: Scalars['Decimal'];
  closeQty: Scalars['Decimal'];
  product: Product;
  warehouse: Warehouse;
  distributor: Distributor;
};

export type ReportWarehouseXntDetails = {
  __typename?: 'ReportWarehouseXntDetails';
  distributorId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  lotNbr: Scalars['String'];
  openQty: Scalars['Decimal'];
  salesOutQty: Scalars['Decimal'];
  returnPurchaseQty: Scalars['Decimal'];
  purchaseQty: Scalars['Decimal'];
  issueReturnQty: Scalars['Decimal'];
  issueQty: Scalars['Decimal'];
  receiptQty: Scalars['Decimal'];
  adjustReceiptQty: Scalars['Decimal'];
  adjustIssueQty: Scalars['Decimal'];
  transferIssueQty: Scalars['Decimal'];
  transferReceiptQty: Scalars['Decimal'];
  exchangeReceiptQty: Scalars['Decimal'];
  exchangeIssueQty: Scalars['Decimal'];
  rewardQty: Scalars['Decimal'];
  closeQty: Scalars['Decimal'];
  product: Product;
  warehouse: Warehouse;
  distributor: Distributor;
};

export type ReportWarehouseXntDetailsListResult = {
  __typename?: 'ReportWarehouseXntDetailsListResult';
  total: Scalars['Int'];
  data: Array<ReportWarehouseXntDetails>;
};

export type ModifyBy = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type SalesOrder = {
  __typename?: 'SalesOrder';
  id: Scalars['Int'];
  cd: Scalars['String'];
  orderDate: Scalars['DateTime'];
  orderAt: Scalars['DateTimeOffset'];
  orderStatus: OrderStatus;
  orderType: OrderType;
  orderTotal: Scalars['Decimal'];
  orderTotalAfterTax: Scalars['Decimal'];
  discountMoney: Scalars['Decimal'];
  percentTotal: Scalars['Decimal'];
  vat: Scalars['Decimal'];
  total: Scalars['Decimal'];
  orderSource: OrderSource;
  sellType?: Maybe<SellType>;
  isDistance: Scalars['Boolean'];
  salesOrderBeforeReturnId?: Maybe<Scalars['Int']>;
  reasonEditOrder?: Maybe<ReasonEditOrder>;
  transaction?: Maybe<Transaction>;
  reasonReturn?: Maybe<ReasonEditOrder>;
  salesOrderBeforeReturn?: Maybe<SalesOrder>;
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  customer: Customer;
  distributor: Distributor;
  salesman?: Maybe<Employee>;
  creator?: Maybe<Employee>;
  salesSupervisor?: Maybe<Employee>;
  deliveryAddress?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  enjoyDiscounts?: Maybe<Array<Maybe<EnjoyDiscount>>>;
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscount>>>;
  orderItems: Array<SalesOrderItem>;
  salesOrderTrackLots: Array<SalesOrderTrackLot>;
  deliveryNote?: Maybe<DeliveryNote>;
  // creator?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<ModifyBy>;
};

export type SalesOrderConnection = {
  __typename?: 'SalesOrderConnection';
  total: Scalars['Int'];
  nextCursor?: Maybe<Scalars['String']>;
  list: Array<SalesOrder>;
};

export type SalesOrderDiscount = {
  __typename?: 'SalesOrderDiscount';
  id: Scalars['Int'];
  isManual: Scalars['Boolean'];
  salesOrderId: Scalars['Int'];
  discountId: Scalars['Int'];
  salesOrder: SalesOrder;
  discount: Discount;
  salesOrderDiscountLevels: Array<SalesOrderDiscountLevel>;
};

export type SalesOrderDiscountInput = {
  id: Scalars['Int'];
  isManual: Scalars['Boolean'];
  discountId: Scalars['Int'];
  salesOrderDiscountLevels: Array<SalesOrderDiscountLevelInput>;
};

export type SalesOrderDiscountLevel = {
  __typename?: 'SalesOrderDiscountLevel';
  id: Scalars['Int'];
  totalAmount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  percentageMoney: Scalars['Decimal'];
  totalTurn: Scalars['Int'];
  isSelected: Scalars['Boolean'];
  discountLevelId: Scalars['Int'];
  salesOrderFreeItems: Array<SalesOrderFreeItem>;
};

export type SalesOrderDiscountLevelInput = {
  id: Scalars['Int'];
  totalAmount: Scalars['Decimal'];
  totalTurn: Scalars['Int'];
  percentage: Scalars['Decimal'];
  percentageMoney: Scalars['Decimal'];
  discountLevelId: Scalars['Int'];
  isSelected: Scalars['Boolean'];
  salesOrderFreeItems: Array<SalesOrderFreeItemInput>;
};

export type SalesOrderDiscountLine = {
  __typename?: 'SalesOrderDiscountLine';
  id: Scalars['Int'];
  discountId: Scalars['Int'];
  salesOrderItemId: Scalars['Int'];
  rewardTotal: Scalars['Decimal'];
  discount: Discount;
  salesOrderItem: SalesOrderItem;
};

export type SalesOrderDiscountLineListResult = {
  __typename?: 'SalesOrderDiscountLineListResult';
  total: Scalars['Int'];
  data: Array<SalesOrderDiscountLine>;
};

export type SalesOrderFreeItem = {
  __typename?: 'SalesOrderFreeItem';
  id: Scalars['Int'];
  qty: Scalars['Int'];
  productId: Scalars['Int'];
  product: Product;
};

export type SalesOrderFreeItemInput = {
  id: Scalars['Int'];
  qty: Scalars['Int'];
  productId: Scalars['Int'];
};

export type SalesOrderInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  orderDate: Scalars['Date'];
  orderType: OrderType;
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  customerId: Scalars['Int'];
  reasonEditOrderId?: Maybe<Scalars['Int']>;
  reasonReturnId?: Maybe<Scalars['Int']>;
  salesOrderBeforeReturnId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  discountMoney?: Maybe<Scalars['Decimal']>;
  enjoyDiscounts: Array<EnjoyDiscountInput>;
  salesOrderDiscounts: Array<SalesOrderDiscountInput>;
  orderItems: Array<SalesOrderItemInput>;
  salesOrderTrackLots: Array<SalesOrderTrackLotInput>;
};

export type SalesOrderItem = {
  __typename?: 'SalesOrderItem';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  moneyAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitPrice: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  isFreeItem: Scalars['Boolean'];
  product: Product;
  warehouse: Warehouse;
  salesOrder: SalesOrder;
  totalPrice: Scalars['Decimal'];
  totalPriceAfterTax: Scalars['Decimal'];
  discount?: Maybe<Discount>;
};

export type SalesOrderItemInput = {
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  isFreeItem: Scalars['Boolean'];
  discountId?: Maybe<Scalars['Int']>;
  unitPriceAfterTax: Scalars['Decimal'];
};

export type SalesOrderItemListResult = {
  __typename?: 'SalesOrderItemListResult';
  total: Scalars['Int'];
  data: Array<SalesOrderItem>;
};

export type SalesOrderListResult = {
  __typename?: 'SalesOrderListResult';
  total: Scalars['Int'];
  data: Array<SalesOrder>;
};

export type SalesOrderPayload = {
  __typename?: 'SalesOrderPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<SalesOrder>;
};

export type SalesOrderTrackLot = {
  __typename?: 'SalesOrderTrackLot';
  id: Scalars['Int'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceAfterTax: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitPrice: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  product?: Maybe<Product>;
  warehouse?: Maybe<Warehouse>;
  endDate?: Maybe<Scalars['Date']>;
  isReturned?: Maybe<Scalars['Boolean']>;
  salesOrder: SalesOrder;
};

export type SalesOrderTrackLotInput = {
  id: Scalars['Int'];
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isReturned?: Maybe<Scalars['Boolean']>;
};

export type SalesPrice = {
  __typename?: 'SalesPrice';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  unitId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};

export type SalesPriceInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  unitId: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};

export type SalesPriceListResult = {
  __typename?: 'SalesPriceListResult';
  total: Scalars['Int'];
  data: Array<SalesPrice>;
};

export type SalesPricePayload = {
  __typename?: 'SalesPricePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<SalesPrice>;
};

export type SalesRegion = {
  __typename?: 'SalesRegion';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  workGroupId?: Maybe<Scalars['Int']>;
  salesRegionAreas: Array<SalesRegionArea>;
};

export type SalesRegionArea = {
  __typename?: 'SalesRegionArea';
  id: Scalars['Int'];
  province: Province;
  district?: Maybe<District>;
  ward?: Maybe<Ward>;
};

export type SalesRegionAreaInput = {
  id: Scalars['Int'];
  provinceId: Scalars['Int'];
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
};

export type SalesRegionInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  salesRegionAreas: Array<SalesRegionAreaInput>;
};

export type SalesRegionListResult = {
  __typename?: 'SalesRegionListResult';
  total: Scalars['Int'];
  data: Array<SalesRegion>;
};

export type SalesRegionPayload = {
  __typename?: 'SalesRegionPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<SalesRegion>;
};

export type SalesRegionWorkGroupInput = {
  id: Scalars['Int'];
};

export type SalesmanDto = {
  __typename?: 'SalesmanDto';
  id: Scalars['Int'];
  code: Scalars['String'];
  name: Scalars['String'];
  photoId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  supervisorId?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  lastSeenAt: Scalars['DateTime'];
  supervisor?: Maybe<Employee>;
  photo?: Maybe<Photo>;
  fullAddress: Scalars['String'];
};

export type SalesmanFilter = {
  __typename?: 'SalesmanFilter';
  salesmanId: Scalars['Int'];
  salesmanName: Scalars['String'];
  date: Scalars['DateTime'];
  lastSeenAt: Scalars['DateTime'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export enum SellType {
  PreSell = 'PRE_SELL',
  VanSell = 'VAN_SELL'
}

export type SellerInput = {
  id: Scalars['Int'];
  sellerId: Scalars['Int'];
  refId?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
};

export type SfaCheckInCommandoInput = {
  customerId: Scalars['Int'];
  inRange: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  checkInAt: Scalars['DateTimeOffset'];
  checkInLocation: SfaLocationInput;
  checkOutAt: Scalars['DateTimeOffset'];
  checkOutLocation: SfaLocationInput;
  checkOutReasonId?: Maybe<Scalars['Int']>;
  visuals?: Maybe<Array<SfaVisualInput>>;
  surveys?: Maybe<Array<SfaSurveyInput>>;
  trades?: Maybe<Array<SfaTradeParticipationInput>>;
  orders?: Maybe<Array<SfaOrderInput>>;
  returnOrders?: Maybe<Array<SfaOrderInput>>;
  exchanges?: Maybe<Array<Maybe<SfaExchangeInput>>>;
  productStockCounts?: Maybe<Array<SfaStockCountInput>>;
  otherProductStockCounts?: Maybe<Array<SfaStockCountInput>>;
};

export type SfaCheckInInput = {
  mcpLineId: Scalars['Int'];
  inRange: Scalars['Boolean'];
  isInRoute: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  checkInAt: Scalars['DateTimeOffset'];
  checkInLocation: SfaLocationInput;
  checkOutAt: Scalars['DateTimeOffset'];
  checkOutLocation: SfaLocationInput;
  checkOutReasonId?: Maybe<Scalars['Int']>;
  visuals?: Maybe<Array<SfaVisualInput>>;
  surveys?: Maybe<Array<SfaSurveyInput>>;
  trades?: Maybe<Array<SfaTradeParticipationInput>>;
  orders?: Maybe<Array<SfaOrderInput>>;
  returnOrders?: Maybe<Array<SfaOrderInput>>;
  exchanges?: Maybe<Array<Maybe<SfaExchangeInput>>>;
  productStockCounts?: Maybe<Array<SfaStockCountInput>>;
  otherProductStockCounts?: Maybe<Array<SfaStockCountInput>>;
};

export type SfaCommandoDetail = {
  __typename?: 'SfaCommandoDetail';
  customerId: Scalars['Int'];
  mcpId?: Maybe<Scalars['Int']>;
  mcpLineId?: Maybe<Scalars['Int']>;
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  customer: Customer;
};

export type SfaCustomerInput = {
  id?: Maybe<Scalars['Int']>;
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  classId: Scalars['Int'];
  customerPriceClassId?: Maybe<Scalars['Int']>;
  attrDetailIds: Array<Scalars['Int']>;
  location: SfaLocationInput;
  photoId: Scalars['Int'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  contactPhone2?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  addressReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  wardId: Scalars['Int'];
};

export type SfaCustomerLocationCommandoInput = {
  customerId: Scalars['Int'];
  location: SfaLocationInput;
  photoId: Scalars['Int'];
};

export type SfaCustomerLocationInput = {
  mcpLineId: Scalars['Int'];
  location: SfaLocationInput;
  photoId: Scalars['Int'];
};

export type SfaDocument = {
  __typename?: 'SfaDocument';
  id: Scalars['Int'];
  fileUrl: Scalars['String'];
  descr: Scalars['String'];
};

export type SfaExchangeInput = {
  distributorId: Scalars['Int'];
  reasonExchangeId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  lines: Array<SfaExchangeLineInput>;
};

export type SfaExchangeLineInput = {
  productId: Scalars['Int'];
  qty: Scalars['Int'];
  unitId: Scalars['String'];
  returnLotNbr?: Maybe<Scalars['String']>;
  returnStartDate?: Maybe<Scalars['Date']>;
  returnEndDate?: Maybe<Scalars['Date']>;
  exchangeLotNbr?: Maybe<Scalars['String']>;
  exchangeStartDate?: Maybe<Scalars['Date']>;
  exchangeEndDate?: Maybe<Scalars['Date']>;
};

export type SfaInventoryCustomerLot = {
  __typename?: 'SfaInventoryCustomerLot';
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type SfaLocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  accuracy?: Maybe<Scalars['Float']>;
};

export type SfaMcpLineInput = {
  id: Scalars['Int'];
  sfaCustomer: SfaCustomerInput;
  weekdays: Array<Weekdays>;
  weeksOfMonth: Array<WeeksOfMonth>;
  distributorIds: Array<Scalars['Int']>;
};

export type SfaMutationEndpoint = {
  __typename?: 'SfaMutationEndpoint';
  updateCustomerLocation?: Maybe<CustomerPayload>;
  checkIn?: Maybe<CheckInResultPayload>;
  createOrUpdateMcpLine?: Maybe<McpLinePayload>;
  receiveActiveLocation?: Maybe<DmActiveLocationPayload>;
  createOrUpdateCustomerSfa?: Maybe<BoolPayload>;
  checkInCommando?: Maybe<CheckInCommandoResultPayload>;
  updateCustomerLocationCommando?: Maybe<CustomerPayload>;
};


export type SfaMutationEndpointUpdateCustomerLocationArgs = {
  data: SfaCustomerLocationInput;
};


export type SfaMutationEndpointCheckInArgs = {
  data: SfaCheckInInput;
};


export type SfaMutationEndpointCreateOrUpdateMcpLineArgs = {
  data: SfaMcpLineInput;
};


export type SfaMutationEndpointReceiveActiveLocationArgs = {
  data: DmActiveLocationInput;
};


export type SfaMutationEndpointCreateOrUpdateCustomerSfaArgs = {
  data: SfaMcpLineInput;
};


export type SfaMutationEndpointCheckInCommandoArgs = {
  data: SfaCheckInCommandoInput;
};


export type SfaMutationEndpointUpdateCustomerLocationCommandoArgs = {
  data: SfaCustomerLocationCommandoInput;
};

export type SfaOrderInput = {
  distributorId: Scalars['Int'];
  isDirectSell: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  discountMoney?: Maybe<Scalars['Decimal']>;
  enjoyDiscounts?: Maybe<Array<Maybe<EnjoyDiscountInput>>>;
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountInput>>>;
  lines: Array<SfaOrderLineInput>;
  lineLots?: Maybe<Array<Maybe<SfaOrderLineLotInput>>>;
};

export type SfaOrderLineInput = {
  productId: Scalars['Int'];
  qty: Scalars['Int'];
  isFreeItem: Scalars['Boolean'];
  discountId?: Maybe<Scalars['Int']>;
  unitPrice: Scalars['Decimal'];
  unitId: Scalars['String'];
};

export type SfaOrderLineLotInput = {
  productId: Scalars['Int'];
  qty: Scalars['Int'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  discountId?: Maybe<Scalars['Int']>;
  discountLevelId?: Maybe<Scalars['Int']>;
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type SfaQueryEndpoint = {
  __typename?: 'SfaQueryEndpoint';
  masterData: MasterData;
  mcp: Mcp;
  mcpLine: McpLine;
  customerOrders: SalesOrderConnection;
  order?: Maybe<SalesOrder>;
  customerSurveys: Array<Survey>;
  customerVisuals: Array<Visual>;
  allCustomerDiscounts: Array<Discount>;
  customerDiscounts: Array<Discount>;
  customerTrades: CustomerTrade;
  orders: SalesOrderConnection;
  salesmanKpis: Array<KpiConfigItem>;
  checkInventoryCustomerLot: Array<SfaInventoryCustomerLot>;
  calculateSalesOrderDiscount?: Maybe<Array<DiscountCalculatePass>>;
  commando: Commando;
  commandoLineConnection: CommandoLineConnection;
  commandoCustomer: SfaCommandoDetail;
  checkInDocument: Array<SfaDocument>;
};


export type SfaQueryEndpointMcpLineArgs = {
  id: Scalars['Int'];
};


export type SfaQueryEndpointCustomerOrdersArgs = {
  customerId: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
};


export type SfaQueryEndpointOrderArgs = {
  id: Scalars['Int'];
};


export type SfaQueryEndpointCustomerSurveysArgs = {
  customerId: Scalars['Int'];
};


export type SfaQueryEndpointCustomerVisualsArgs = {
  customerId: Scalars['Int'];
};


export type SfaQueryEndpointAllCustomerDiscountsArgs = {
  customerId: Scalars['Int'];
};


export type SfaQueryEndpointCustomerDiscountsArgs = {
  customerId: Scalars['Int'];
};


export type SfaQueryEndpointCustomerTradesArgs = {
  customerId: Scalars['Int'];
};


export type SfaQueryEndpointOrdersArgs = {
  cursor?: Maybe<Scalars['String']>;
};


export type SfaQueryEndpointCheckInventoryCustomerLotArgs = {
  customerId: Scalars['Int'];
  productId: Scalars['Int'];
  expirationDate?: Maybe<Scalars['Date']>;
};


export type SfaQueryEndpointCalculateSalesOrderDiscountArgs = {
  customerId: Scalars['Int'];
  items: Array<DiscountSalesItemInput>;
  discountIds: Array<Scalars['Int']>;
};


export type SfaQueryEndpointCommandoLineConnectionArgs = {
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  isPotential?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
};


export type SfaQueryEndpointCommandoCustomerArgs = {
  id: Scalars['Int'];
};

export type SfaStockCountInput = {
  productId: Scalars['Int'];
  qty: Scalars['Int'];
  unitId?: Maybe<Scalars['String']>;
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type SfaSurveyAnswerInput = {
  questionId: Scalars['Int'];
  answerId?: Maybe<Scalars['Int']>;
  answerText?: Maybe<Scalars['String']>;
};

export type SfaSurveyInput = {
  id: Scalars['Int'];
  answers: Array<SfaSurveyAnswerInput>;
};

export type SfaTradeParticipationInput = {
  tradeId: Scalars['Int'];
  level: Scalars['Int'];
};

export type SfaVisualInput = {
  visualId: Scalars['Int'];
  photoIds: Array<Scalars['Int']>;
};

export type Shipper = {
  __typename?: 'Shipper';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  phone11: Scalars['String'];
  isAlive?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  citizenIdentityCard: Scalars['String'];
  photoId?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  distributor: Distributor;
  photo?: Maybe<Photo>;
  creator?: Maybe<Employee>;
};

export type ShipperInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone11: Scalars['String'];
  citizenIdentityCard: Scalars['String'];
  photoId?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
};

export type ShipperListResult = {
  __typename?: 'ShipperListResult';
  total: Scalars['Int'];
  data: Array<Shipper>;
};

export type ShipperPayload = {
  __typename?: 'ShipperPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Shipper>;
};

export type StringPayload = {
  __typename?: 'StringPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Scalars['String']>;
};

export type Survey = {
  __typename?: 'Survey';
  id: Scalars['Int'];
  cd: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status: ProgramStatus;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['Date']>;
  multiple: Scalars['Boolean'];
  surveyQuestions: Array<SurveyQuestion>;
  surveyApplicableCustomerAttrs: Array<SurveyApplicableCustomerAttr>;
  surveyApplicableSalesRegions: Array<SurveyApplicableSalesRegion>;
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  id: Scalars['Int'];
  title: Scalars['String'];
  requiredOtherTextAnswer: Scalars['Boolean'];
};

export type SurveyAnswerInput = {
  id: Scalars['Int'];
  title: Scalars['String'];
  requiredOtherTextAnswer: Scalars['Boolean'];
};

export type SurveyApplicableCustomerAttr = {
  __typename?: 'SurveyApplicableCustomerAttr';
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type SurveyApplicableCustomerAttrInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type SurveyApplicableSalesRegion = {
  __typename?: 'SurveyApplicableSalesRegion';
  id: Scalars['Int'];
  salesRegion: SalesRegion;
};

export type SurveyApplicableSalesRegionInput = {
  id: Scalars['Int'];
  salesRegionId: Scalars['Int'];
};

export type SurveyInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  multiple: Scalars['Boolean'];
  surveyQuestions: Array<SurveyQuestionInput>;
  surveyApplicableCustomerAttrs: Array<SurveyApplicableCustomerAttrInput>;
  surveyApplicableSalesRegions: Array<SurveyApplicableSalesRegionInput>;
};

export type SurveyListResult = {
  __typename?: 'SurveyListResult';
  total: Scalars['Int'];
  data: Array<Survey>;
};

export type SurveyPayload = {
  __typename?: 'SurveyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Survey>;
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  id: Scalars['Int'];
  title: Scalars['String'];
  isFreeText: Scalars['Boolean'];
  isMultiple: Scalars['Boolean'];
  surveyAnswers: Array<SurveyAnswer>;
};

export type SurveyQuestionInput = {
  id: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isFreeText: Scalars['Boolean'];
  isMultiple: Scalars['Boolean'];
  surveyAnswers: Array<SurveyAnswerInput>;
};

export type TrackLotWarehouseInput = {
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
};

export type Trade = {
  __typename?: 'Trade';
  id: Scalars['Int'];
  cd: Scalars['String'];
  description: Scalars['String'];
  status: ProgramStatus;
  breakBy: BreakBy;
  multiple: Scalars['Boolean'];
  autoLevel: Scalars['Boolean'];
  registerStartDate: Scalars['DateTime'];
  registerEndDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  tradeApplyTarget: TradeApplyTarget;
  tradeProducts: Array<TradeProduct>;
  tradeProductGroups: Array<TradeProductGroup>;
  tradeLevels: Array<TradeLevel>;
  tradeApplicableCustomers: Array<TradeApplicableCustomer>;
  tradeApplicableDistributors: Array<TradeApplicableDistributor>;
  tradeApplicableCustomerAttrs: Array<TradeApplicableCustomerAttr>;
  tradeApplicableSalesRegions: Array<TradeApplicableSalesRegion>;
};

export type TradeApplicableCustomer = {
  __typename?: 'TradeApplicableCustomer';
  id: Scalars['Int'];
  customer: Customer;
};

export type TradeApplicableCustomerAttr = {
  __typename?: 'TradeApplicableCustomerAttr';
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type TradeApplicableCustomerAttrInput = {
  id: Scalars['Int'];
  attrId: Scalars['Int'];
  attrDetailId: Scalars['Int'];
};

export type TradeApplicableCustomerInput = {
  id: Scalars['Int'];
  customerId: Scalars['Int'];
};

export type TradeApplicableDistributor = {
  __typename?: 'TradeApplicableDistributor';
  id: Scalars['Int'];
  distributor: Distributor;
};

export type TradeApplicableDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type TradeApplicableSalesRegion = {
  __typename?: 'TradeApplicableSalesRegion';
  id: Scalars['Int'];
  salesRegion: SalesRegion;
};

export type TradeApplicableSalesRegionInput = {
  id: Scalars['Int'];
  salesRegionId: Scalars['Int'];
};

export enum TradeApplyTarget {
  Customer = 'CUSTOMER',
  Distributor = 'DISTRIBUTOR'
}

export type TradeInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  breakBy: BreakBy;
  multiple: Scalars['Boolean'];
  autoLevel: Scalars['Boolean'];
  registerStartDate: Scalars['Date'];
  registerEndDate: Scalars['Date'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  tradeApplyTarget: TradeApplyTarget;
  tradeProducts: Array<TradeProductInput>;
  tradeProductGroups: Array<TradeProductGroupInput>;
  tradeLevels: Array<TradeLevelInput>;
  tradeApplicableCustomers: Array<TradeApplicableCustomerInput>;
  tradeApplicableDistributors: Array<TradeApplicableDistributorInput>;
  tradeApplicableCustomerAttrs: Array<TradeApplicableCustomerAttrInput>;
  tradeApplicableSalesRegions: Array<TradeApplicableSalesRegionInput>;
};

export type TradeLevel = {
  __typename?: 'TradeLevel';
  id: Scalars['Int'];
  level: Scalars['Int'];
  description: Scalars['String'];
  breakValue: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  isBundle: Scalars['Boolean'];
  tradeLevelProducts: Array<TradeLevelProduct>;
};

export type TradeLevelInput = {
  id: Scalars['Int'];
  level: Scalars['Int'];
  description: Scalars['String'];
  breakValue: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  percentage: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  isBundle: Scalars['Boolean'];
  tradeLevelProducts: Array<TradeLevelProductInput>;
};

export type TradeLevelProduct = {
  __typename?: 'TradeLevelProduct';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
  product: Product;
};

export type TradeLevelProductInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type TradeListResult = {
  __typename?: 'TradeListResult';
  total: Scalars['Int'];
  data: Array<Trade>;
};

export type TradeParticipation = {
  __typename?: 'TradeParticipation';
  id: Scalars['Int'];
  status: TradeParticipationStatus;
  customerId: Scalars['Int'];
  tradeId: Scalars['Int'];
  customer: Customer;
  level: Scalars['Int'];
  createdAt: Scalars['DateTimeOffset'];
  approvedAt?: Maybe<Scalars['DateTimeOffset']>;
  lastCalculatedDate?: Maybe<Scalars['Date']>;
  result: Scalars['Decimal'];
  incentiveResult: Scalars['Decimal'];
  trade: Trade;
};

export type TradeParticipationDistributor = {
  __typename?: 'TradeParticipationDistributor';
  id: Scalars['Int'];
  status: TradeParticipationStatus;
  distributorId: Scalars['Int'];
  tradeId: Scalars['Int'];
  distributor: Distributor;
  level: Scalars['Int'];
  createdAt: Scalars['DateTimeOffset'];
  approvedAt?: Maybe<Scalars['DateTimeOffset']>;
  lastCalculatedDate?: Maybe<Scalars['Date']>;
  result: Scalars['Decimal'];
  incentiveResult: Scalars['Decimal'];
  trade: Trade;
};

export type TradeParticipationDistributorInput = {
  id: Scalars['Int'];
  tradeId: Scalars['Int'];
  distributorId: Scalars['Int'];
  level: Scalars['Int'];
};

export type TradeParticipationDistributorListResult = {
  __typename?: 'TradeParticipationDistributorListResult';
  total: Scalars['Int'];
  data: Array<TradeParticipationDistributor>;
};

export type TradeParticipationDistributorPayload = {
  __typename?: 'TradeParticipationDistributorPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<TradeParticipationDistributor>;
};

export type TradeParticipationListResult = {
  __typename?: 'TradeParticipationListResult';
  total: Scalars['Int'];
  data: Array<TradeParticipation>;
};

export enum TradeParticipationStatus {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type TradePayload = {
  __typename?: 'TradePayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Trade>;
};

export type TradeProduct = {
  __typename?: 'TradeProduct';
  id: Scalars['Int'];
  tradeId: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
  product: Product;
};

export type TradeProductGroup = {
  __typename?: 'TradeProductGroup';
  id: Scalars['Int'];
  productGroupId: Scalars['Int'];
  productGroup: ProductGroup;
};

export type TradeProductGroupInput = {
  id: Scalars['Int'];
  productGroupId: Scalars['Int'];
};

export type TradeProductInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  rate: Scalars['Decimal'];
};

export type TradeReward = {
  __typename?: 'TradeReward';
  id: Scalars['Int'];
  status: TradeParticipationStatus;
  customerId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  tradeParticipationId: Scalars['Int'];
  customer: Customer;
  distributor: Distributor;
  tradeParticipation: TradeParticipation;
  tradeRewardProducts: Array<TradeRewardProduct>;
};

export type TradeRewardInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  distributorId: Scalars['Int'];
  tradeParticipationId: Scalars['Int'];
  tradeRewardProducts: Array<TradeRewardProductInput>;
};

export type TradeRewardListResult = {
  __typename?: 'TradeRewardListResult';
  total: Scalars['Int'];
  data: Array<TradeReward>;
};

export type TradeRewardPayload = {
  __typename?: 'TradeRewardPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<TradeReward>;
};

export type TradeRewardProduct = {
  __typename?: 'TradeRewardProduct';
  id: Scalars['Int'];
  rate: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeRewardId: Scalars['Int'];
  product: Product;
  warehouse: Warehouse;
};

export type TradeRewardProductInput = {
  id: Scalars['Int'];
  rate: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
  tradeRewardId: Scalars['Int'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['Int'];
  cd: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  source: TransactionSource;
  status: TransactionStatus;
  transactionDate: Scalars['Date'];
  creator?: Maybe<Employee>;
  reason?: Maybe<ReasonEditOrder>;
  distributor: Distributor;
  fromWarehouse?: Maybe<Warehouse>;
  toWarehouse?: Maybe<Warehouse>;
  salesOrderId?: Maybe<Scalars['Int']>;
  salesOrder?: Maybe<SalesOrder>;
  purchaseReceiptId?: Maybe<Scalars['Int']>;
  purchaseReceipt?: Maybe<PurchaseReceipt>;
  exchangeId?: Maybe<Scalars['Int']>;
  tradeRewardId?: Maybe<Scalars['Int']>;
  tradeReward?: Maybe<TradeReward>;
  transactionItems: Array<TransactionItem>;
};

export type TransactionInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  distributorId: Scalars['Int'];
  fromWarehouseId: Scalars['Int'];
  toWarehouseId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  reasonId?: Maybe<Scalars['Int']>;
  source: TransactionSource;
  transactionDate: Scalars['Date'];
  transactionItems: Array<TransactionItemInput>;
};

export type TransactionItem = {
  __typename?: 'TransactionItem';
  id: Scalars['Int'];
  type: UnitItemType;
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  qty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  exchangeLotNbr?: Maybe<Scalars['String']>;
  exchangeStartDate?: Maybe<Scalars['Date']>;
  exchangeEndDate?: Maybe<Scalars['Date']>;
  warehouse: Warehouse;
  product: Product;
};

export type TransactionItemInput = {
  id: Scalars['Int'];
  type: UnitItemType;
  productId: Scalars['Int'];
  unitId: Scalars['String'];
  qty: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  exchangeLotNbr?: Maybe<Scalars['String']>;
  exchangeStartDate?: Maybe<Scalars['Date']>;
  exchangeEndDate?: Maybe<Scalars['Date']>;
};

export type TransactionListResult = {
  __typename?: 'TransactionListResult';
  total: Scalars['Int'];
  data: Array<Transaction>;
};

export type TransactionPayload = {
  __typename?: 'TransactionPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Transaction>;
};

export enum TransactionSource {
  Sales = 'SALES',
  Purchase = 'PURCHASE',
  Receipt = 'RECEIPT',
  Issue = 'ISSUE',
  Adjust = 'ADJUST',
  Transfer = 'TRANSFER',
  Exchange = 'EXCHANGE',
  Reward = 'REWARD'
}

export enum TransactionStatus {
  New = 'NEW',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum UnitItemType {
  Exc = 'EXC',
  Inc = 'INC',
  Dec = 'DEC'
}

export type UploadPhotoInput = {
  base64: Scalars['String'];
  type: EntityType;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['Int'];
  seller: Distributor;
  buyer: Distributor;
};

export type Visual = {
  __typename?: 'Visual';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: ProgramStatus;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  voteRequired: Scalars['Boolean'];
  multiple: Scalars['Boolean'];
  minPhotos: Scalars['Int'];
  maxPhotos: Scalars['Int'];
  photo?: Maybe<Photo>;
  trade?: Maybe<Trade>;
  creator?: Maybe<Employee>;
  visualApplicableSalesRegions: Array<Maybe<VisualApplicableSalesRegion>>;
};

export type VisualApplicableSalesRegion = {
  __typename?: 'VisualApplicableSalesRegion';
  id: Scalars['Int'];
  salesRegion: SalesRegion;
};

export type VisualApplicableSalesRegionInput = {
  id: Scalars['Int'];
  salesRegionId: Scalars['Int'];
};

export type VisualInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  voteRequired: Scalars['Boolean'];
  multiple: Scalars['Boolean'];
  minPhotos: Scalars['Int'];
  maxPhotos: Scalars['Int'];
  photoId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
  visualApplicableSalesRegions: Array<VisualApplicableSalesRegionInput>;
};

export type VisualListResult = {
  __typename?: 'VisualListResult';
  total: Scalars['Int'];
  data: Array<Visual>;
};

export type VisualPayload = {
  __typename?: 'VisualPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<Visual>;
};

export type VisualPhoto = {
  __typename?: 'VisualPhoto';
  id: Scalars['Int'];
  visualId: Scalars['Int'];
  checkInId?: Maybe<Scalars['Int']>;
  checkInCommandoId?: Maybe<Scalars['Int']>;
  photoId: Scalars['Int'];
  isPassed: Scalars['Boolean'];
  photo: Photo;
  visual: Visual;
  checkIn?: Maybe<CheckIn>;
  checkInCommando?: Maybe<CheckInCommando>;
};

export type VisualPhotoListResult = {
  __typename?: 'VisualPhotoListResult';
  total: Scalars['Int'];
  data: Array<VisualPhoto>;
};

export type Ward = {
  __typename?: 'Ward';
  id: Scalars['Int'];
  name: Scalars['String'];
  district: District;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  pickOrder: Scalars['Int'];
  allowSales: Scalars['Boolean'];
  allowPurchase: Scalars['Boolean'];
  allowAdjust: Scalars['Boolean'];
  allowIssue: Scalars['Boolean'];
  allowReceipt: Scalars['Boolean'];
  allowTransfer: Scalars['Boolean'];
  address1?: Maybe<Scalars['String']>;
  ward?: Maybe<Ward>;
  salesman?: Maybe<Employee>;
  warehouseProducts: Array<WarehouseProduct>;
  distributor: Distributor;
};

export type WarehouseInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  pickOrder: Scalars['Int'];
  allowPurchase: Scalars['Boolean'];
  allowSales: Scalars['Boolean'];
  allowReceipt: Scalars['Boolean'];
  allowIssue: Scalars['Boolean'];
  allowAdjust: Scalars['Boolean'];
  allowTransfer: Scalars['Boolean'];
  address1?: Maybe<Scalars['String']>;
  salesmanId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
};

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  id: Scalars['Int'];
  product: Product;
  qty: Scalars['Decimal'];
  availableQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  warehouse: Warehouse;
};

export type WarehouseProductListResult = {
  __typename?: 'WarehouseProductListResult';
  total: Scalars['Int'];
  data: Array<WarehouseProduct>;
};

export type WarehouseProductPending = {
  __typename?: 'WarehouseProductPending';
  id: Scalars['Int'];
  product: Product;
  type: UnitItemType;
  qty: Scalars['Decimal'];
  unitId: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  baseQty: Scalars['Decimal'];
  baseUnitId: Scalars['String'];
  baseUnitPrice: Scalars['Decimal'];
  lotNbr?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  warehouse: Warehouse;
  transaction: Transaction;
};

export enum Weekdays {
  None = 'NONE',
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN'
}

export enum WeeksOfMonth {
  None = 'NONE',
  Week_1 = 'WEEK_1',
  Week_2 = 'WEEK_2',
  Week_3 = 'WEEK_3',
  Week_4 = 'WEEK_4'
}

export type WorkGroup = {
  __typename?: 'WorkGroup';
  id: Scalars['Int'];
  cd: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  workGId?: Maybe<Scalars['Int']>;
  workGroups: Array<WorkGroup>;
  salesRegions: Array<SalesRegion>;
};

export type WorkGroupAddInput = {
  id: Scalars['Int'];
};

export type WorkGroupInput = {
  id: Scalars['Int'];
  cd?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  workGroups: Array<WorkGroupAddInput>;
  salesRegions: Array<SalesRegionWorkGroupInput>;
};

export type WorkGroupListResult = {
  __typename?: 'WorkGroupListResult';
  total: Scalars['Int'];
  data: Array<WorkGroup>;
};

export type WorkGroupPayload = {
  __typename?: 'WorkGroupPayload';
  success: Scalars['Boolean'];
  message?: Maybe<PayloadMessage>;
  content?: Maybe<WorkGroup>;
};

export type WorkingDistributor = {
  __typename?: 'WorkingDistributor';
  id: Scalars['Int'];
  distributor: Distributor;
};

export type WorkingDistributorInput = {
  id: Scalars['Int'];
  distributorId: Scalars['Int'];
};

export type WorkingSalesRegion = {
  __typename?: 'WorkingSalesRegion';
  id: Scalars['Int'];
  salesRegion: SalesRegion;
};

export type WorkingSalesRegionInput = {
  id: Scalars['Int'];
  salesRegionId: Scalars['Int'];
};

export type AttrsQueryVariables = Exact<{
  type: EntityType;
}>;


export type AttrsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { attrs: Array<(
      { __typename?: 'Attr' }
      & AttrFragment
    )> }
  ) }
);

export type AttrDetailsQueryVariables = Exact<{
  type: EntityType;
}>;


export type AttrDetailsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { attrDetails: Array<(
      { __typename?: 'AttrDetail' }
      & Pick<AttrDetail, 'id' | 'value' | 'description'>
    )> }
  ) }
);

export type AttrFragment = (
  { __typename: 'Attr' }
  & Pick<Attr, 'id' | 'cd' | 'name' | 'description'>
  & { attrDetails: Array<(
    { __typename?: 'AttrDetail' }
    & Pick<AttrDetail, 'id' | 'value' | 'description'>
  )> }
);

export type AttrQueryVariables = Exact<{
  id: Scalars['Int'];
  type: EntityType;
}>;


export type AttrQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { attr: (
      { __typename?: 'Attr' }
      & AttrFragment
    ) }
  ) }
);

export type CreateOrUpdateAttrMutationVariables = Exact<{
  data: AttrInput;
}>;


export type CreateOrUpdateAttrMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateAttr?: Maybe<(
      { __typename?: 'AttrPayload' }
      & Pick<AttrPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Attr' }
        & AttrFragment
      )> }
    )> }
  )> }
);

export type DeleteAttrMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAttrMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteAttr?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CalculateCalendarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CalculateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { calculateCalendar?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CalendarFragment = (
  { __typename: 'Calendar' }
  & Pick<Calendar, 'id' | 'year' | 'firstWeekend' | 'description'>
  & { calendarWeeks?: Maybe<Array<(
    { __typename?: 'CalendarWeek' }
    & Pick<CalendarWeek, 'id' | 'calendarId' | 'week' | 'startDate' | 'endDate'>
  )>> }
);

export type CalendarListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type CalendarListQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { calendarList: (
      { __typename?: 'CalendarListResult' }
      & Pick<CalendarListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Calendar' }
        & CalendarFragment
      )> }
    ) }
  ) }
);

export type CalendarQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CalendarQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { calendar: (
      { __typename?: 'Calendar' }
      & CalendarFragment
    ) }
  ) }
);

export type CreateOrUpdateCalendarMutationVariables = Exact<{
  data: CalendarInput;
}>;


export type CreateOrUpdateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateCalendar?: Maybe<(
      { __typename?: 'CalendarPayload' }
      & Pick<CalendarPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Calendar' }
        & CalendarFragment
      )> }
    )> }
  )> }
);

export type DeleteCalendarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCalendarMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { deleteCalendar?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CdsConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type CdsConfigQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { cdsConfig: Array<(
      { __typename?: 'CdConfig' }
      & CdConfigFragment
    )> }
  ) }
);

export type CdConfigFragment = (
  { __typename?: 'CdConfig' }
  & Pick<CdConfig, 'id' | 'count' | 'numbering' | 'description' | 'prefix'>
);

export type CdConfigQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CdConfigQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { cdConfig: (
      { __typename?: 'CdConfig' }
      & CdConfigFragment
    ) }
  ) }
);

export type UpdateCdConfigMutationVariables = Exact<{
  data: CdConfigInput;
}>;


export type UpdateCdConfigMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { updateCdConfig?: Maybe<(
      { __typename?: 'CdConfigPayload' }
      & Pick<CdConfigPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'CdConfig' }
        & CdConfigFragment
      )> }
    )> }
  )> }
);

export type CheckOutReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckOutReasonsQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { checkOutReasons: Array<(
      { __typename?: 'CheckOutReason' }
      & CheckOutReasonFragment
    )> }
  ) }
);

export type CheckOutReasonFragment = (
  { __typename: 'CheckOutReason' }
  & Pick<CheckOutReason, 'id' | 'name' | 'minutes' | 'description' | 'requiredTasks' | 'isVisit'>
);

export type CheckOutReasonQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CheckOutReasonQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { checkOutReason: (
      { __typename?: 'CheckOutReason' }
      & CheckOutReasonFragment
    ) }
  ) }
);

export type CreateOrUpdateCheckOutReasonMutationVariables = Exact<{
  data: CheckOutReasonInput;
}>;


export type CreateOrUpdateCheckOutReasonMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateCheckOutReason?: Maybe<(
      { __typename?: 'CheckOutReasonPayload' }
      & Pick<CheckOutReasonPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'CheckOutReason' }
        & CheckOutReasonFragment
      )> }
    )> }
  )> }
);

export type DeleteCheckOutReasonMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCheckOutReasonMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { deleteCheckOutReason?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ActionStatusCommandoLineMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActionStatusCommandoLineMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { actionStatusCommandoLine?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code'>
      )> }
    )> }
  )> }
);

export type CloseCommandoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseCommandoMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { closeCommando?: Maybe<(
      { __typename?: 'CommandoPayload' }
      & Pick<CommandoPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Commando' }
        & Pick<Commando, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CommandoLinesQueryVariables = Exact<{
  commandoId: Scalars['Int'];
  visitDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type CommandoLinesQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { commandoLines: (
      { __typename?: 'CommandoLineListResult' }
      & Pick<CommandoLineListResult, 'total'>
      & { data: Array<(
        { __typename?: 'CommandoLine' }
        & Pick<CommandoLine, 'id' | 'visitDate' | 'startHour' | 'endHour' | 'targetAmount' | 'targetCustomer' | 'isActive'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type CommandoListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  commandoCd?: Maybe<Scalars['String']>;
}>;


export type CommandoListQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { commandoList: (
      { __typename?: 'CommandoListResult' }
      & Pick<CommandoListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Commando' }
        & Pick<Commando, 'id' | 'cd' | 'status' | 'sellTypes' | 'description' | 'inRangeMeter' | 'hasOrderRequiredTasks' | 'allowOutPlanVisit' | 'allowNewCustomerVisit' | 'startDate' | 'endDate'>
        & { salesRegion: (
          { __typename?: 'SalesRegion' }
          & Pick<SalesRegion, 'id' | 'name'>
        ), salesman: (
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type CommandoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CommandoQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { commando: (
      { __typename?: 'Commando' }
      & Pick<Commando, 'id' | 'cd' | 'status' | 'description' | 'sellTypes' | 'inRangeMeter' | 'hasOrderRequiredTasks' | 'allowOutPlanVisit' | 'allowNewCustomerVisit' | 'startDate' | 'endDate' | 'lastGeneratedDate'>
      & { salesRegion: (
        { __typename?: 'SalesRegion' }
        & Pick<SalesRegion, 'id' | 'cd' | 'name' | 'description'>
      ), salesman: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name' | 'cd'>
      ), provinceSalesRegion: Array<(
        { __typename?: 'Province' }
        & Pick<Province, 'id' | 'name'>
        & { districts: Array<(
          { __typename?: 'District' }
          & Pick<District, 'id' | 'name'>
          & { wards: Array<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
          )> }
        )> }
      )>, commandoDistributors: Array<(
        { __typename?: 'CommandoDistributor' }
        & Pick<CommandoDistributor, 'id'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type CreateOrUpdateCommandoLineMutationVariables = Exact<{
  data: CommandoLineInput;
}>;


export type CreateOrUpdateCommandoLineMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateCommandoLine?: Maybe<(
      { __typename?: 'CommandoLinePayload' }
      & Pick<CommandoLinePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'CommandoLine' }
        & Pick<CommandoLine, 'id' | 'visitDate' | 'addedAt' | 'startHour' | 'endHour' | 'targetAmount' | 'targetCustomer'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type CreateOrUpdateCommandoMutationVariables = Exact<{
  data: CommandoInput;
}>;


export type CreateOrUpdateCommandoMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateCommando?: Maybe<(
      { __typename?: 'CommandoPayload' }
      & Pick<CommandoPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Commando' }
        & Pick<Commando, 'id' | 'cd' | 'status' | 'sellTypes' | 'description' | 'inRangeMeter' | 'hasOrderRequiredTasks' | 'allowOutPlanVisit' | 'allowNewCustomerVisit' | 'startDate' | 'endDate' | 'lastGeneratedDate'>
        & { salesman: (
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        ), commandoDistributors: Array<(
          { __typename?: 'CommandoDistributor' }
          & Pick<CommandoDistributor, 'id'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'cd' | 'name'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type ReleaseCommandoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseCommandoMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { releaseCommando?: Maybe<(
      { __typename?: 'CommandoPayload' }
      & Pick<CommandoPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Commando' }
        & Pick<Commando, 'id' | 'cd' | 'status'>
      )> }
    )> }
  )> }
);

export type AcceptHoldingSalesOrderMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AcceptHoldingSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { acceptHoldingSalesOrder?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type CancelHoldingSalesOrderMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CancelHoldingSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { cancelHoldingSalesOrder?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ApproveNewCommandoCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ApproveNewCommandoCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { approveNewCommandoCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ApproveNewCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ApproveNewCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { approveNewCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ApproveUpdateCommandoCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ApproveUpdateCommandoCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { approveUpdateCommandoCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ApproveUpdateCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ApproveUpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { approveUpdateCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type CloseCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CloseCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { closeCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type CommandoNewCustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type CommandoNewCustomerListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { commandoNewCustomerList: (
      { __typename?: 'CustomerListResult' }
      & Pick<CustomerListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name' | 'phone11' | 'customerStatus' | 'customerClassId' | 'customerPriceClassId' | 'lat' | 'lng' | 'contactName1' | 'fullAddress'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type ControlCustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  status: CustomerStatus;
  filter?: Maybe<Scalars['String']>;
}>;


export type ControlCustomerListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { controlCustomerList: (
      { __typename?: 'McpLineListResult' }
      & Pick<McpLineListResult, 'total'>
      & { data: Array<(
        { __typename?: 'McpLine' }
        & Pick<McpLine, 'id' | 'weekdays' | 'weeksOfMonth'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'id' | 'name' | 'customerStatus' | 'customerClassId' | 'customerPriceClassId' | 'contactName1' | 'phone11' | 'address1' | 'address2' | 'attrDetailIds'>
          & { photo?: Maybe<(
            { __typename?: 'Photo' }
            & Pick<Photo, 'id' | 'url'>
          )>, ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )>, customerClass: (
            { __typename?: 'CustomerClass' }
            & Pick<CustomerClass, 'cd' | 'name' | 'description'>
          ), customerPriceClass?: Maybe<(
            { __typename?: 'CustomerPriceClass' }
            & Pick<CustomerPriceClass, 'cd' | 'name' | 'description'>
          )>, customerDistributors: Array<(
            { __typename?: 'CustomerDistributor' }
            & { distributor: (
              { __typename?: 'Distributor' }
              & Pick<Distributor, 'cd' | 'name'>
            ) }
          )> }
        ), mcp: (
          { __typename?: 'Mcp' }
          & Pick<Mcp, 'cd'>
          & { salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type RejectNewCommandoCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RejectNewCommandoCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { rejectNewCommandoCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type RejectNewCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RejectNewCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { rejectNewCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type RejectUpdateCommandoCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RejectUpdateCommandoCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { rejectUpdateCommandoCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type RejectUpdateCustomerMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RejectUpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { rejectUpdateCustomer?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type UpdateCommandoCustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type UpdateCommandoCustomerListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { updateCommandoCustomerList: (
      { __typename?: 'CommandoCustomerUpdateListResult' }
      & Pick<CommandoCustomerUpdateListResult, 'total'>
      & { data: Array<(
        { __typename?: 'CommandoCustomerUpdate' }
        & Pick<CommandoCustomerUpdate, 'id' | 'customerId' | 'status' | 'customerClassId' | 'customerPriceClassId' | 'attrDetailIds' | 'distributorIds' | 'name' | 'contactName1' | 'phone11' | 'phone12' | 'address1'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward: (
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type UpdateCustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type UpdateCustomerListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { updateCustomerList: (
      { __typename?: 'McpLineUpdateListResult' }
      & Pick<McpLineUpdateListResult, 'total'>
      & { data: Array<(
        { __typename?: 'McpLineUpdate' }
        & Pick<McpLineUpdate, 'id' | 'name' | 'customerClassId' | 'phone11' | 'address1' | 'contactName1' | 'weekdays' | 'weeksOfMonth' | 'distributorIds' | 'attrDetailIds'>
        & { mcpLine: (
          { __typename?: 'McpLine' }
          & Pick<McpLine, 'customerId'>
          & { customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'cd'>
          ), mcp: (
            { __typename?: 'Mcp' }
            & { salesman: (
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            ) }
          ) }
        ), photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward: (
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type AcceptHoldingExchangesMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AcceptHoldingExchangesMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { acceptHoldingExchanges?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CancelExchangesMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CancelExchangesMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { cancelExchanges?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CalculateTradeManualMutationVariables = Exact<{ [key: string]: never; }>;


export type CalculateTradeManualMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { calculateTradeManual?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type GenerateMcpManualMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateMcpManualMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { generateMCPManual?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ApproveTradeParticipationMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ApproveTradeParticipationMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { approveTradeParticipation?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ControlTradeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  status: ProgramStatus;
  filter?: Maybe<Scalars['String']>;
}>;


export type ControlTradeListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { controlTradeList: (
      { __typename?: 'TradeListResult' }
      & Pick<TradeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Trade' }
        & Pick<Trade, 'id' | 'cd' | 'description' | 'status' | 'breakBy' | 'multiple' | 'autoLevel' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate'>
      )> }
    ) }
  ) }
);

export type RejectTradeParticipationMutationVariables = Exact<{
  listId: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RejectTradeParticipationMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { rejectTradeParticipation?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type TradeParticipationListByTradeIdQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  tradeId: Scalars['Int'];
  status: TradeParticipationStatus;
  filter?: Maybe<Scalars['String']>;
}>;


export type TradeParticipationListByTradeIdQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { tradeParticipationListByTradeId: (
      { __typename?: 'TradeParticipationListResult' }
      & Pick<TradeParticipationListResult, 'total'>
      & { data: Array<(
        { __typename?: 'TradeParticipation' }
        & Pick<TradeParticipation, 'id' | 'tradeId' | 'customerId' | 'status' | 'level' | 'approvedAt' | 'createdAt' | 'lastCalculatedDate' | 'result' | 'incentiveResult'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type VisualPhotoListByVisualIdQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  visualId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type VisualPhotoListByVisualIdQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { visualPhotoListByVisualId: (
      { __typename?: 'VisualPhotoListResult' }
      & Pick<VisualPhotoListResult, 'total'>
      & { data: Array<(
        { __typename?: 'VisualPhoto' }
        & Pick<VisualPhoto, 'id' | 'visualId' | 'checkInId' | 'photoId' | 'isPassed'>
        & { photo: (
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        ), visual: (
          { __typename?: 'Visual' }
          & Pick<Visual, 'id' | 'cd' | 'name' | 'description' | 'status' | 'startDate' | 'endDate'>
          & { trade?: Maybe<(
            { __typename?: 'Trade' }
            & Pick<Trade, 'id' | 'cd' | 'endDate'>
          )>, visualApplicableSalesRegions: Array<Maybe<(
            { __typename?: 'VisualApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'id' | 'cd' | 'description'>
            ) }
          )>> }
        ), checkIn?: Maybe<(
          { __typename?: 'CheckIn' }
          & { customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id' | 'cd' | 'name'>
          ), salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type ControlVisualReleaseQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type ControlVisualReleaseQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { controlVisualRelease: (
      { __typename?: 'VisualListResult' }
      & Pick<VisualListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Visual' }
        & Pick<Visual, 'id' | 'cd' | 'name' | 'description' | 'status' | 'startDate' | 'endDate' | 'multiple' | 'minPhotos' | 'maxPhotos'>
      )> }
    ) }
  ) }
);

export type EvaluatePhotoVisualMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type EvaluatePhotoVisualMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { evaluatePhotoVisual?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateCustomerClassMutationVariables = Exact<{
  data: CustomerClassInput;
}>;


export type CreateOrUpdateCustomerClassMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateCustomerClass?: Maybe<(
      { __typename?: 'CustomerClassPayload' }
      & Pick<CustomerClassPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'CustomerClass' }
        & CustomerClassFragment
      )> }
    )> }
  )> }
);

export type CustomerClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerClassesQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customerClasses: Array<(
      { __typename?: 'CustomerClass' }
      & CustomerClassFragment
    )> }
  ) }
);

export type CustomerClassFragment = (
  { __typename: 'CustomerClass' }
  & Pick<CustomerClass, 'id' | 'cd' | 'name' | 'description'>
  & { customerClassAttrs: Array<(
    { __typename?: 'CustomerClassAttr' }
    & Pick<CustomerClassAttr, 'id' | 'attrId' | 'defaultAttrDetailId'>
  )> }
);

export type CustomerClassQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CustomerClassQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customerClass: (
      { __typename?: 'CustomerClass' }
      & CustomerClassFragment
    ) }
  ) }
);

export type DeleteCustomerClassMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomerClassMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteCustomerClass?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateCustomerPriceClassMutationVariables = Exact<{
  data: CustomerPriceClassInput;
}>;


export type CreateOrUpdateCustomerPriceClassMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateCustomerPriceClass?: Maybe<(
      { __typename?: 'CustomerPriceClassPayload' }
      & Pick<CustomerPriceClassPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'CustomerPriceClass' }
        & CustomerPriceClassFragment
      )> }
    )> }
  )> }
);

export type CustomerPriceClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerPriceClassesQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customerPriceClasses: Array<(
      { __typename?: 'CustomerPriceClass' }
      & CustomerPriceClassFragment
    )> }
  ) }
);

export type CustomerPriceClassFragment = (
  { __typename: 'CustomerPriceClass' }
  & Pick<CustomerPriceClass, 'id' | 'cd' | 'name' | 'description'>
);

export type CustomerPriceClassQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CustomerPriceClassQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customerPriceClass: (
      { __typename?: 'CustomerPriceClass' }
      & CustomerPriceClassFragment
    ) }
  ) }
);

export type DeleteCustomerPriceClassMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomerPriceClassMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteCustomerPriceClass?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ActiveCustomerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActiveCustomerMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { activeCustomer?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateCustomerMutationVariables = Exact<{
  data: CustomerInput;
}>;


export type CreateOrUpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateCustomer?: Maybe<(
      { __typename?: 'CustomerPayload' }
      & Pick<CustomerPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Customer' }
        & CustomerFragment
      )> }
    )> }
  )> }
);

export type CustomerFragment = (
  { __typename: 'Customer' }
  & Pick<Customer, 'id' | 'cd' | 'name' | 'customerStatus' | 'customerClassId' | 'customerPriceClassId' | 'isDistributor' | 'isAlive' | 'lat' | 'lng' | 'isEditPhoto' | 'contactName1' | 'phone11' | 'phone12' | 'address1'>
  & { photo?: Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'id' | 'url'>
  )>, ward?: Maybe<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
    & { district: (
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
      & { province: (
        { __typename?: 'Province' }
        & Pick<Province, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type CustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  customerClassId?: Maybe<Scalars['Int']>;
  customerStatus?: Maybe<CustomerStatus>;
  createdDate?: Maybe<Scalars['Date']>;
  provinceId?:Maybe<Scalars['Int']>;
  distributorId?:Maybe<Scalars['Int']>;
}>;


export type CustomerListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customerList: (
      { __typename?: 'CustomerListResult' }
      & Pick<CustomerListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name' | 'lat' | 'lng' | 'customerStatus' | 'customerClassId' | 'customerPriceClassId' | 'isAlive' | 'isEditPhoto' | 'contactName1' | 'phone11' | 'address1' | 'createdAt' | 'modifiedAt'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'attrDetailIds'>
      & { customerDistributors: Array<(
        { __typename?: 'CustomerDistributor' }
        & Pick<CustomerDistributor, 'id'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
      & CustomerFragment
    ) }
  ) }
);

export type EmployeeDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeDashboardQuery = (
  { __typename?: 'Query' }
  & { dbd: (
    { __typename?: 'DashboardQueryEndpoint' }
    & { employeeDashboard: Array<(
      { __typename?: 'EmployeeDashboard' }
      & Pick<EmployeeDashboard, 'salesmanId' | 'total'>
      & { employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name'>
      ) }
    )> }
  ) }
);

export type ProductDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductDashboardQuery = (
  { __typename?: 'Query' }
  & { dbd: (
    { __typename?: 'DashboardQueryEndpoint' }
    & { productDashboard: Array<(
      { __typename?: 'ProductDashboard' }
      & Pick<ProductDashboard, 'totalPrice'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CancelDeliveryNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelDeliveryNoteMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { cancelDeliveryNote?: Maybe<(
      { __typename?: 'DeliveryNotePayload' }
      & Pick<DeliveryNotePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'DeliveryNote' }
        & DeliveryNoteFragment
      )> }
    )> }
  )> }
);

export type CancelInventoryDeliveryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelInventoryDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { cancelInventoryDelivery?: Maybe<(
      { __typename?: 'InventoryDeliveryPayload' }
      & Pick<InventoryDeliveryPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'InventoryDelivery' }
        & Pick<InventoryDelivery, 'id'>
      )> }
    )> }
  )> }
);

export type CompleteDeliveryNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteDeliveryNoteMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { completeDeliveryNote?: Maybe<(
      { __typename?: 'DeliveryNotePayload' }
      & Pick<DeliveryNotePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'DeliveryNote' }
        & DeliveryNoteFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdateDeliveryNoteOrderMutationVariables = Exact<{
  data: DeliveryNoteInput;
}>;


export type CreateOrUpdateDeliveryNoteOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { createOrUpdateDeliveryNoteOrder?: Maybe<(
      { __typename?: 'DeliveryNotePayload' }
      & Pick<DeliveryNotePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'DeliveryNote' }
        & DeliveryNoteFragment
      )> }
    )> }
  )> }
);

export type CreateInventoryDeliveryNoteOrderMutationVariables = Exact<{
  data: InventoryDeliveryInput;
}>;


export type CreateInventoryDeliveryNoteOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { createInventoryDelivery?: Maybe<(
      { __typename?: 'InventoryDeliveryPayload' }
      & Pick<InventoryDeliveryPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'InventoryDelivery' }
        & Pick<InventoryDelivery, 'id' | 'description'>
        & { deliveryNotes: Array<(
          { __typename?: 'DeliveryNote' }
          & DeliveryNoteFragment
        )> }
      )> }
    )> }
  )> }
);

export type DeliveryNotesQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorCd?: Maybe<Scalars['String']>;
  orderCd?: Maybe<Scalars['String']>;
  shipperCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type DeliveryNotesQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { deliveryNotes: (
      { __typename?: 'DeliveryNoteListResult' }
      & Pick<DeliveryNoteListResult, 'total'>
      & { data: Array<(
        { __typename?: 'DeliveryNote' }
        & DeliveryNoteFragment
      )> }
    ) }
  ) }
);

export type DeliveryNoteFragment = (
  { __typename: 'DeliveryNote' }
  & Pick<DeliveryNote, 'id' | 'status' | 'note' | 'createdAt'>
  & { shipper: (
    { __typename?: 'Shipper' }
    & Pick<Shipper, 'id' | 'cd' | 'name' | 'phone11' | 'email' | 'citizenIdentityCard' | 'photoId' | 'address1' | 'distributorId'>
  ), salesOrder?: Maybe<(
    { __typename?: 'SalesOrder' }
    & SalesOrderFragment
  )>, purchaseReceipt?: Maybe<(
    { __typename?: 'PurchaseReceipt' }
    & PurchaseReceiptFragment
  )> }
);

export type DeliveryNoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeliveryNoteQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { deliveryNote: (
      { __typename?: 'DeliveryNote' }
      & DeliveryNoteFragment
    ) }
  ) }
);

export type InventoryDeliveryListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  orderCd?: Maybe<Scalars['String']>;
  shipperCd?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type InventoryDeliveryListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { inventoryDeliveryList: (
      { __typename?: 'InventoryDeliveryListResult' }
      & Pick<InventoryDeliveryListResult, 'total'>
      & { data: Array<(
        { __typename?: 'InventoryDelivery' }
        & Pick<InventoryDelivery, 'id' | 'description'>
        & { shipper: (
          { __typename?: 'Shipper' }
          & Pick<Shipper, 'cd' | 'id' | 'name'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'cd' | 'name'>
          ) }
        ), deliveryNotes: Array<(
          { __typename?: 'DeliveryNote' }
          & DeliveryNoteFragment
        )> }
      )> }
    ) }
  ) }
);

export type InventoryDeliveryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InventoryDeliveryQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { inventoryDelivery: (
      { __typename?: 'InventoryDelivery' }
      & Pick<InventoryDelivery, 'id' | 'description'>
      & { shipper: (
        { __typename?: 'Shipper' }
        & Pick<Shipper, 'cd' | 'id' | 'name'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ) }
      ), deliveryNotes: Array<(
        { __typename?: 'DeliveryNote' }
        & DeliveryNoteFragment
      )> }
    ) }
  ) }
);

export type GetPurchaseReceiptsByTypeAsyncQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseReceiptsByTypeAsyncQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { getPurchaseReceiptsByTypeAsync: (
      { __typename?: 'PurchaseReceiptListResult' }
      & Pick<PurchaseReceiptListResult, 'total'>
      & { data: Array<(
        { __typename?: 'PurchaseReceipt' }
        & Pick<PurchaseReceipt, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderTotal' | 'description'>
        & { vendor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type SalesOrderByTypeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type SalesOrderByTypeListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrderByTypeList: (
      { __typename?: 'SalesOrderListResult' }
      & Pick<SalesOrderListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrder' }
        & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderSource' | 'orderTotal' | 'description'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name' | 'address1'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )> }
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type GetAllWorkGroupFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllWorkGroupFilterQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { getAllWorkGroupFilter: Array<(
      { __typename?: 'WorkGroup' }
      & Pick<WorkGroup, 'cd' | 'name'>
      & { workGroups: Array<(
        { __typename?: 'WorkGroup' }
        & Pick<WorkGroup, 'cd' | 'name'>
        & { workGroups: Array<(
          { __typename?: 'WorkGroup' }
          & Pick<WorkGroup, 'cd' | 'name'>
          & { workGroups: Array<(
            { __typename?: 'WorkGroup' }
            & Pick<WorkGroup, 'cd' | 'name'>
            & { workGroups: Array<(
              { __typename?: 'WorkGroup' }
              & Pick<WorkGroup, 'cd' | 'name'>
              & { workGroups: Array<(
                { __typename?: 'WorkGroup' }
                & Pick<WorkGroup, 'cd' | 'name'>
              )>, salesRegions: Array<(
                { __typename?: 'SalesRegion' }
                & Pick<SalesRegion, 'id' | 'cd' | 'name'>
              )> }
            )>, salesRegions: Array<(
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'id' | 'cd' | 'name'>
            )> }
          )>, salesRegions: Array<(
            { __typename?: 'SalesRegion' }
            & Pick<SalesRegion, 'id' | 'cd' | 'name'>
          )> }
        )>, salesRegions: Array<(
          { __typename?: 'SalesRegion' }
          & Pick<SalesRegion, 'id' | 'cd' | 'name'>
        )> }
      )>, salesRegions: Array<(
        { __typename?: 'SalesRegion' }
        & Pick<SalesRegion, 'id' | 'cd' | 'name'>
      )> }
    )> }
  ) }
);

export type OutletDetailsDtoQueryVariables = Exact<{
  id: Scalars['Int'];
  date: Scalars['Date'];
}>;


export type OutletDetailsDtoQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { outletDetailsDto?: Maybe<(
      { __typename?: 'OutletDetailsDto' }
      & Pick<OutletDetailsDto, 'name' | 'fullAddress' | 'customerClass' | 'customerPriceClass' | 'phone' | 'contactName' | 'routeCode' | 'cdSalesman' | 'salesman'>
      & { photo?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'url'>
      )>, distributors: Array<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'cd' | 'name'>
      )>, checkIns: Array<(
        { __typename?: 'CheckIn' }
        & Pick<CheckIn, 'checkInAt' | 'checkOutAt' | 'checkOutReasonText'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )> }
      )>, orders: Array<(
        { __typename?: 'OrderDto' }
        & Pick<OrderDto, 'code' | 'amount'>
      )> }
    )> }
  ) }
);

export type GetAllOutletDtoQueryVariables = Exact<{
  salesmanId: Scalars['Int'];
  date: Scalars['Date'];
}>;


export type GetAllOutletDtoQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { getAllOutletDto: Array<(
      { __typename?: 'OutletDto' }
      & Pick<OutletDto, 'id' | 'lat' | 'lng' | 'index' | 'isInCallPlan' | 'hasOrder' | 'hasCheckedIn'>
    )> }
  ) }
);

export type ActiveLocationSalesmanQueryVariables = Exact<{
  salesmanId: Scalars['Int'];
  date: Scalars['Date'];
}>;


export type ActiveLocationSalesmanQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { activeLocationSalesman: Array<(
      { __typename?: 'DmActiveLocation' }
      & Pick<DmActiveLocation, 'lat' | 'lng'>
    )> }
  ) }
);

export type SalesmanDtoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SalesmanDtoQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { salesmanDto?: Maybe<(
      { __typename?: 'SalesmanDto' }
      & Pick<SalesmanDto, 'id' | 'code' | 'name' | 'fullAddress' | 'phone' | 'lat' | 'lng' | 'lastSeenAt'>
      & { photo?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'url'>
      )>, supervisor?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'cd' | 'name'>
      )> }
    )> }
  ) }
);

export type LastestLocationSalesmanListQueryVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
  date: Scalars['Date'];
}>;


export type LastestLocationSalesmanListQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { lastestLocationSalesmanList: Array<(
      { __typename?: 'SalesmanFilter' }
      & Pick<SalesmanFilter, 'salesmanId' | 'salesmanName' | 'date' | 'lastSeenAt' | 'lat' | 'lng'>
    )> }
  ) }
);

export type OutletDetailsWeekdaysQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OutletDetailsWeekdaysQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { outletDetailsWeekdays?: Maybe<(
      { __typename?: 'OutletDetailsDto' }
      & Pick<OutletDetailsDto, 'name' | 'fullAddress' | 'customerClass' | 'customerPriceClass' | 'phone' | 'contactName' | 'routeCode' | 'cdSalesman' | 'salesman'>
      & { photo?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'url'>
      )>, distributors: Array<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'cd' | 'name'>
      )> }
    )> }
  ) }
);

export type GetOutletDtoWeekdaysQueryVariables = Exact<{
  weekdays: Array<Weekdays> | Weekdays;
  salesmanId: Scalars['Int'];
}>;


export type GetOutletDtoWeekdaysQuery = (
  { __typename?: 'Query' }
  & { dm: (
    { __typename?: 'DigitalMapQueryEndpoint' }
    & { getOutletDtoWeekdays: Array<(
      { __typename?: 'OutletDto' }
      & Pick<OutletDto, 'id' | 'lat' | 'lng'>
    )> }
  ) }
);

export type AllCustomerDiscountsQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type AllCustomerDiscountsQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { allCustomerDiscounts: Array<(
      { __typename?: 'Discount' }
      & DiscountFragment
    )> }
  ) }
);

export type CalculateSalesOrderDiscountQueryVariables = Exact<{
  customerId: Scalars['Int'];
  items: Array<DiscountSalesItemInput> | DiscountSalesItemInput;
  discountIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CalculateSalesOrderDiscountQuery = (
  { __typename?: 'Query' }
  & { sfa: (
    { __typename?: 'SfaQueryEndpoint' }
    & { calculateSalesOrderDiscount?: Maybe<Array<(
      { __typename?: 'DiscountCalculatePass' }
      & Pick<DiscountCalculatePass, 'discountId'>
      & { discount: (
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'cd' | 'description' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'sortOrder' | 'highestLevel' | 'buyGive' | 'excluded' | 'allowChangeLevel' | 'isManual' | 'firstOrder' | 'internalRef' | 'externalRef'>
        & { discountLevels: Array<(
          { __typename?: 'DiscountLevel' }
          & Pick<DiscountLevel, 'id' | 'level' | 'description' | 'breakValue'>
          & { discountLevelRewards: Array<(
            { __typename?: 'DiscountLevelReward' }
            & Pick<DiscountLevelReward, 'id' | 'description' | 'isDefault' | 'isBundle' | 'amount' | 'percentage' | 'percentageMoney' | 'qty'>
            & { discountLevelRewardProducts: Array<(
              { __typename?: 'DiscountLevelRewardProduct' }
              & Pick<DiscountLevelRewardProduct, 'id' | 'productId' | 'rate'>
            )> }
          )>, discountLevelConditions: Array<(
            { __typename?: 'DiscountLevelCondition' }
            & Pick<DiscountLevelCondition, 'id' | 'productId' | 'minQty'>
          )> }
        )> }
      ), discountLevelCalculatePasses: Array<(
        { __typename?: 'DiscountLevelCalculatePass' }
        & Pick<DiscountLevelCalculatePass, 'discountLevelId' | 'totalPass'>
        & { discountLevel: (
          { __typename?: 'DiscountLevel' }
          & Pick<DiscountLevel, 'id' | 'level' | 'description' | 'breakValue'>
          & { discountLevelRewards: Array<(
            { __typename?: 'DiscountLevelReward' }
            & Pick<DiscountLevelReward, 'id' | 'description' | 'isDefault' | 'isBundle' | 'amount' | 'percentage' | 'percentageMoney' | 'qty'>
            & { discountLevelRewardProducts: Array<(
              { __typename?: 'DiscountLevelRewardProduct' }
              & Pick<DiscountLevelRewardProduct, 'id' | 'productId' | 'rate'>
            )> }
          )>, discountLevelConditions: Array<(
            { __typename?: 'DiscountLevelCondition' }
            & Pick<DiscountLevelCondition, 'id' | 'productId' | 'minQty'>
          )> }
        ), orderItems: Array<(
          { __typename?: 'DiscountOrderItem' }
          & Pick<DiscountOrderItem, 'id' | 'unitId' | 'qty' | 'passCount' | 'price'>
        )> }
      )> }
    )>> }
  ) }
);

export type CloseDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseDiscountMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeDiscount?: Maybe<(
      { __typename?: 'DiscountPayload' }
      & Pick<DiscountPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateDiscountMutationVariables = Exact<{
  data: DiscountInput;
}>;


export type CreateOrUpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateDiscount?: Maybe<(
      { __typename?: 'DiscountPayload' }
      & Pick<DiscountPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Discount' }
        & DiscountFragment
      )> }
    )> }
  )> }
);

export type CustomerDiscountsQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type CustomerDiscountsQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { customerDiscounts: Array<(
      { __typename?: 'Discount' }
      & DiscountFragment
    )> }
  ) }
);

export type DeleteDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteDiscount?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type DiscountFragment = (
  { __typename: 'Discount' }
  & Pick<Discount, 'id' | 'cd' | 'description' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'sortOrder' | 'highestLevel' | 'excluded' | 'allowChangeLevel' | 'isManual' | 'firstOrder' | 'internalRef' | 'externalRef' | 'buyGive'>
  & { discountProducts: Array<(
    { __typename?: 'DiscountProduct' }
    & Pick<DiscountProduct, 'id' | 'productId' | 'rate'>
  )>, discountProductGroups: Array<(
    { __typename?: 'DiscountProductGroup' }
    & Pick<DiscountProductGroup, 'id' | 'productGroupId'>
  )>, discountLevels: Array<(
    { __typename?: 'DiscountLevel' }
    & Pick<DiscountLevel, 'id' | 'level' | 'description' | 'breakValue'>
    & { discountLevelRewards: Array<(
      { __typename?: 'DiscountLevelReward' }
      & Pick<DiscountLevelReward, 'id' | 'description' | 'isDefault' | 'isBundle' | 'amount' | 'percentage' | 'qty'>
      & { discountLevelRewardProducts: Array<(
        { __typename?: 'DiscountLevelRewardProduct' }
        & Pick<DiscountLevelRewardProduct, 'id' | 'productId' | 'rate'>
      )> }
    )>, discountLevelConditions: Array<(
      { __typename?: 'DiscountLevelCondition' }
      & Pick<DiscountLevelCondition, 'id' | 'productId' | 'minQty'>
    )> }
  )>, discountApplicableCustomers: Array<(
    { __typename?: 'DiscountApplicableCustomer' }
    & Pick<DiscountApplicableCustomer, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'cd' | 'name'>
    ) }
  )>, discountApplicableCustomerAttrs: Array<(
    { __typename?: 'DiscountApplicableCustomerAttr' }
    & Pick<DiscountApplicableCustomerAttr, 'id' | 'attrId' | 'attrDetailId'>
  )>, discountApplicableSalesRegions: Array<(
    { __typename?: 'DiscountApplicableSalesRegion' }
    & Pick<DiscountApplicableSalesRegion, 'id'>
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'id' | 'name'>
    ) }
  )> }
);

export type DiscountListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  discountCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creatorCd?: Maybe<Scalars['String']>;
  isBundle?: Maybe<Scalars['Int']>;
  isFirstOrder?: Maybe<Scalars['Int']>;
  externalReference?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type DiscountListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { discountList: (
      { __typename?: 'DiscountListResult' }
      & Pick<DiscountListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'cd' | 'description' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'highestLevel' | 'excluded' | 'isManual' | 'firstOrder' | 'allowChangeLevel' | 'internalRef' | 'externalRef' | 'sortOrder'>
      )> }
    ) }
  ) }
);

export type DiscountPrioritizationListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type DiscountPrioritizationListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { discountPrioritizationList: (
      { __typename?: 'DiscountListResult' }
      & Pick<DiscountListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'cd' | 'description' | 'sortOrder' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'highestLevel' | 'excluded' | 'allowChangeLevel' | 'internalRef' | 'externalRef'>
      )> }
    ) }
  ) }
);

export type DiscountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DiscountQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { discount: (
      { __typename?: 'Discount' }
      & DiscountFragment
    ) }
  ) }
);

export type PromotionPrioritizationMutationVariables = Exact<{
  input: Array<DiscountPrioritizationInput> | DiscountPrioritizationInput;
}>;


export type PromotionPrioritizationMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { promotionPrioritization?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'message'>
      )> }
    )> }
  )> }
);

export type ReleaseDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseDiscountMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseDiscount?: Maybe<(
      { __typename?: 'DiscountPayload' }
      & Pick<DiscountPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type ActiveDistributorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActiveDistributorMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { activeDistributor?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateDistributorMutationVariables = Exact<{
  data: DistributorInput;
}>;


export type CreateOrUpdateDistributorMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateDistributor?: Maybe<(
      { __typename?: 'DistributorPayload' }
      & Pick<DistributorPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Distributor' }
        & DistributorFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdateDistributorTypeMutationVariables = Exact<{
  data: DistributorTypeInput;
}>;


export type CreateOrUpdateDistributorTypeMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateDistributorType?: Maybe<(
      { __typename?: 'DistributorTypePayload' }
      & Pick<DistributorTypePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'message'>
      )>, content?: Maybe<(
        { __typename?: 'DistributorType' }
        & Pick<DistributorType, 'id' | 'cd' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateShipperMutationVariables = Exact<{
  data: ShipperInput;
}>;


export type CreateOrUpdateShipperMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateShipper?: Maybe<(
      { __typename?: 'ShipperPayload' }
      & Pick<ShipperPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Shipper' }
        & Pick<Shipper, 'id' | 'cd' | 'name' | 'phone11' | 'email' | 'citizenIdentityCard' | 'photoId' | 'address1' | 'distributorId'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )> }
      )> }
    )> }
  )> }
);

export type DeleteShipperMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShipperMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteShipper?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type DistributorAllListQueryVariables = Exact<{ [key: string]: never; }>;


export type DistributorAllListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { distributorAllList: Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name'>
    )> }
  ) }
);

export type DistributorFragment = (
  { __typename: 'Distributor' }
  & Pick<Distributor, 'id' | 'cd' | 'name' | 'contactName1' | 'phone11' | 'address1' | 'isActive' | 'email' | 'startDate' | 'endDate'>
  & { distributorType: (
    { __typename?: 'DistributorType' }
    & Pick<DistributorType, 'id' | 'cd' | 'name'>
  ), customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ), ward?: Maybe<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
    & { district: (
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
      & { province: (
        { __typename?: 'Province' }
        & Pick<Province, 'id' | 'name'>
      ) }
    ) }
  )>, warehouses: Array<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'name' | 'description' | 'pickOrder' | 'allowPurchase' | 'allowSales' | 'allowReceipt' | 'allowIssue' | 'allowAdjust' | 'allowTransfer' | 'address1'>
    & { salesman?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'cd' | 'name'>
    )>, ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
      & { district: (
        { __typename?: 'District' }
        & Pick<District, 'id' | 'name'>
        & { province: (
          { __typename?: 'Province' }
          & Pick<Province, 'id' | 'name'>
        ) }
      ) }
    )> }
  )>, buyers: Array<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { buyer: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name'>
    ) }
  )>, sellers: Array<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { seller: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name'>
    ) }
  )> }
);

export type DistributorListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
}>;


export type DistributorListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { distributorList: (
      { __typename?: 'DistributorListResult' }
      & Pick<DistributorListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'cd' | 'name' | 'contactName1' | 'phone11' | 'isActive'>
        & { distributorType: (
          { __typename?: 'DistributorType' }
          & Pick<DistributorType, 'id' | 'cd' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name' | 'customerPriceClassId'>
        ), ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type DistributorQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DistributorQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { distributor: (
      { __typename?: 'Distributor' }
      & DistributorFragment
    ) }
  ) }
);

export type DistributorTypeListQueryVariables = Exact<{ [key: string]: never; }>;


export type DistributorTypeListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { distributorTypeList: Array<(
      { __typename?: 'DistributorType' }
      & Pick<DistributorType, 'id' | 'cd' | 'name'>
    )> }
  ) }
);

export type DistributorTypeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DistributorTypeQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { distributorType: (
      { __typename?: 'DistributorType' }
      & Pick<DistributorType, 'id' | 'cd' | 'name'>
    ) }
  ) }
);

export type ShipperListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  distributorId?: Maybe<Scalars['Int']>;
}>;


export type ShipperListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { shipperList: (
      { __typename?: 'ShipperListResult' }
      & Pick<ShipperListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Shipper' }
        & Pick<Shipper, 'id' | 'cd' | 'name' | 'phone11' | 'email' | 'citizenIdentityCard' | 'address1' | 'isAlive' | 'photoId'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )> }
      )> }
    ) }
  ) }
);

export type ShipperQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShipperQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { shipper: (
      { __typename?: 'Shipper' }
      & Pick<Shipper, 'id' | 'cd' | 'name' | 'phone11' | 'email' | 'citizenIdentityCard' | 'photoId' | 'address1' | 'distributorId' | 'isAlive'>
      & { photo?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'url'>
      )> }
    ) }
  ) }
);

export type StatusShipperMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StatusShipperMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { statusShipper?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ActiveEmployeeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActiveEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { activeEmployee?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type AllEmployeeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  provinceId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  wardId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type AllEmployeeListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { allEmployeeList: (
      { __typename?: 'EmployeeListResult' }
      & Pick<EmployeeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name' | 'username' | 'email' | 'address1' | 'isActive' | 'citizenIdentityCard'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )>, employeePositions: Array<(
          { __typename?: 'EmployeePosition' }
          & Pick<EmployeePosition, 'id'>
          & { position: (
            { __typename?: 'Position' }
            & Pick<Position, 'id' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type CreateOrUpdateEmployeeMutationVariables = Exact<{
  data: EmployeeInput;
}>;


export type CreateOrUpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateEmployee?: Maybe<(
      { __typename?: 'EmployeePayload' }
      & Pick<EmployeePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Employee' }
        & EmployeeFragment
      )> }
    )> }
  )> }
);

export type DeviceUserListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type DeviceUserListQuery = (
  { __typename?: 'Query' }
  & { ctrl: (
    { __typename?: 'ControlQueryEndpoint' }
    & { deviceUserList: (
      { __typename?: 'DeviceUserListResult' }
      & Pick<DeviceUserListResult, 'total'>
      & { data: Array<(
        { __typename?: 'DeviceUser' }
        & Pick<DeviceUser, 'id' | 'deviceId' | 'deviceOs' | 'deviceName' | 'startDate' | 'endDate'>
        & { employees?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type EmployeeListAllQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeListAllQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { employeeListAll: Array<Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'cd' | 'name'>
    )>> }
  ) }
);

export type EmployeeFragment = (
  { __typename: 'Employee' }
  & Pick<Employee, 'id' | 'cd' | 'name' | 'username' | 'email' | 'address1' | 'isActive' | 'phone11' | 'startDate' | 'endDate' | 'citizenIdentityCard'>
  & { supervisor?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )>, photo?: Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'id' | 'url'>
  )>, ward?: Maybe<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
    & { district: (
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
      & { province: (
        { __typename?: 'Province' }
        & Pick<Province, 'id' | 'name'>
      ) }
    ) }
  )>, employeePositions: Array<(
    { __typename?: 'EmployeePosition' }
    & Pick<EmployeePosition, 'id'>
    & { position: (
      { __typename?: 'Position' }
      & Pick<Position, 'id' | 'name'>
    ) }
  )>, workingDistributors: Array<(
    { __typename?: 'WorkingDistributor' }
    & Pick<WorkingDistributor, 'id'>
    & { distributor: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name'>
    ) }
  )>, workingSalesRegions: Array<(
    { __typename?: 'WorkingSalesRegion' }
    & Pick<WorkingSalesRegion, 'id'>
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'id' | 'name'>
    ) }
  )> }
);

export type EmployeeLeaderListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type EmployeeLeaderListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { employeeLeaderList: (
      { __typename?: 'EmployeeListResult' }
      & Pick<EmployeeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name'>
      )> }
    ) }
  ) }
);

export type EmployeeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type EmployeeListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { employeeList: (
      { __typename?: 'EmployeeListResult' }
      & Pick<EmployeeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name' | 'username' | 'email' | 'address1' | 'isActive' | 'citizenIdentityCard'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )>, employeePositions: Array<(
          { __typename?: 'EmployeePosition' }
          & Pick<EmployeePosition, 'id'>
          & { position: (
            { __typename?: 'Position' }
            & Pick<Position, 'id' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type EmployeeMemberListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  leaderId?: Maybe<Scalars['Int']>;
}>;


export type EmployeeMemberListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { employeeMemberList: (
      { __typename?: 'EmployeeListResult' }
      & Pick<EmployeeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name'>
      )> }
    ) }
  ) }
);

export type EmployeeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EmployeeQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { employee: (
      { __typename?: 'Employee' }
      & EmployeeFragment
    ) }
  ) }
);

export type RemoveDeviceUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveDeviceUserMutation = (
  { __typename?: 'Mutation' }
  & { ctrl?: Maybe<(
    { __typename?: 'ControlMutationEndpoint' }
    & { removeDeviceUser?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success'>
    )> }
  )> }
);

export type ListPurchaseReceiptsExistMutationVariables = Exact<{
  data: Array<Maybe<ImportPurchaseReceiptInput>> | Maybe<ImportPurchaseReceiptInput>;
}>;


export type ListPurchaseReceiptsExistMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { listPurchaseReceiptsExist: (
      { __typename?: 'ImportPurchaseReceiptListResult' }
      & Pick<ImportPurchaseReceiptListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ImportPurchaseReceipt' }
        & Pick<ImportPurchaseReceipt, 'id' | 'cd'>
      )> }
    ) }
  )> }
);

export type ExportCheckoutReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportCheckoutReasonsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCheckoutReasons'>
  ) }
);

export type ExportCommandoListQueryVariables = Exact<{
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  commandoCd?: Maybe<Scalars['String']>;
}>;


export type ExportCommandoListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCommandoList'>
  ) }
);

export type ExportCurrentWarehouseProductsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportCurrentWarehouseProductsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCurrentWarehouseProducts'>
  ) }
);

export type ExportCustomersQueryVariables = Exact<{ [key: string]: never; }>;




export type ExportCustomersQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCustomers'>
  ) }
);

export type ExportCustomerAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportCustomerAttributesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCustomerAttributes'>
  ) }
);

export type ExportCustomerClassQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportCustomerClassQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCustomerClass'>
  ) }
);

export type ExportCustomerPriceClassQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportCustomerPriceClassQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCustomerPriceClass'>
  ) }
);

export type ExportCustomerSurveyReportQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ProgramStatus>;
}>;


export type ExportCustomerSurveyReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportCustomerSurveyReport'>
  ) }
);

export type ExportDistributorsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportDistributorsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportDistributors'>
  ) }
);

export type ExportDistributorTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportDistributorTypeQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportDistributorType'>
  ) }
);

export type ExportEmployeesQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportEmployeesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportEmployees'>
  ) }
);

export type ExportFollowLoyaltyRegistrationQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  startDateTrade?: Maybe<Scalars['Date']>;
  endDateTrade?: Maybe<Scalars['Date']>;
  statusTrade?: Maybe<ProgramStatus>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
}>;


export type ExportFollowLoyaltyRegistrationQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportFollowLoyaltyRegistration'>
  ) }
);

export type ExportFollowPromotionListQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportFollowPromotionListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportFollowPromotionList'>
  ) }
);

export type ExportPromotionSummarizingMoneyQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ExportPromotionSummarizingMoneyQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPromotionSummarizingMoney'>
  ) }
);

export type ExportPromotionSummarizingProductQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
}>;


export type ExportPromotionSummarizingProductQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPromotionSummarizingProduct'>
  ) }
);

export type ExportFollowVisualReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  visualId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ExportFollowVisualReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportFollowVisualReport'>
  ) }
);

export type ExportKpiEvaluationSalesForceQueryVariables = Exact<{
  leaderId?: Maybe<Scalars['Int']>;
  employeeId?: Maybe<Scalars['Int']>;
  kpiName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  minResult?: Maybe<Scalars['Decimal']>;
  maxResult?: Maybe<Scalars['Decimal']>;
}>;


export type ExportKpiEvaluationSalesForceQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportKpiEvaluationSalesForce'>
  ) }
);

export type ExportKpisReportQueryVariables = Exact<{
  employeeId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  target?: Maybe<KpiApplyTarget>;
  kpiConfigId?: Maybe<Scalars['Int']>;
}>;


export type ExportKpisReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportKpisReport'>
  ) }
);

export type ExportLoyaltiesEvaluationQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  tradePassed?: Maybe<Scalars['Boolean']>;
  breakBy?: Maybe<BreakBy>;
  totalPassed?: Maybe<Scalars['Boolean']>;
  visualPassed?: Maybe<Scalars['Boolean']>;
}>;


export type ExportLoyaltiesEvaluationQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportLoyaltiesEvaluation'>
  ) }
);

export type ExportLoyaltyListQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  target?: Maybe<TradeApplyTarget>;
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportLoyaltyListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportLoyaltyList'>
  ) }
);

export type ExportMcpLineReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerFilter?: Maybe<Scalars['String']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
}>;


export type ExportMcpLineReportQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportMcpLineReport'>
  ) }
);

export type ExportMcpListQueryVariables = Exact<{
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  mcpCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ExportMcpListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportMcpList'>
  ) }
);

export type ExportMasterDataCustomersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  distributorId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  sourceCustomer?: Maybe<CustomerSource>;
  provinceId?:Maybe<Scalars['Int']>;
  customerStatus?:Maybe<Scalars['String']>;
 
}>;


export type ExportMasterDataCustomerV2QueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  distributorId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  sourceCustomer?: Maybe<CustomerSource>;
  provinceId?:Maybe<Scalars['Int']>;
  customerStatus?:Maybe<Scalars['String']>;
}>;


export type ExportMasterDataCustomersQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportMasterDataCustomers'>
  ) }
);

export type ExportMasterDataDistributorsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportMasterDataDistributorsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportMasterDataDistributors'>
  ) }
);

export type ExportMasterDataEmployeesQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  positionId?: Maybe<Scalars['Int']>;
}>;


export type ExportMasterDataEmployeesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportMasterDataEmployees'>
  ) }
);

export type ExportMasterDataProductsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportMasterDataProductsQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportMasterDataProducts'>
  ) }
);

export type ExportMeasureUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportMeasureUnitsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportMeasureUnits'>
  ) }
);

export type ExportOrderRateByStatusQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportOrderRateByStatusQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'orderRateByStatus'>
  ) }
);

export type ExportOtherProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportOtherProductsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportOtherProducts'>
  ) }
);

export type ExportOtherProductStockCountReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  otherProductId?: Maybe<Scalars['Int']>;
}>;


export type ExportOtherProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportOtherProductStockCountReport'>
  ) }
);

export type ExportPendingWarehouseProductsQueryVariables = Exact<{
  source?: Maybe<TransactionSource>;
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportPendingWarehouseProductsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPendingWarehouseProducts'>
  ) }
);

export type ExportPercentOrderCompleteQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
}>;


export type ExportPercentOrderCompleteQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPercentOrderComplete'>
  ) }
);

export type ExportReportPerformanceDistributorQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportReportPerformanceDistributorQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportReportPerformanceDistributor'>
  ) }
);

export type ExportPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportPositionsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPositions'>
  ) }
);

export type ProcedureExportQueryVariables = Exact<{
  procedureName?: Maybe<Scalars['String']>;
}>;


export type ProcedureExportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'procedureExport'>
  ) }
);

export type ExportProductAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportProductAttributesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportProductAttributes'>
  ) }
);

export type ExportProductsGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportProductsGroupsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportProductsGroups'>
  ) }
);

export type ExportProductsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportProductsQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportProducts'>
  ) }
);

export type ExportProductStockCountReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
}>;


export type ExportProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportProductStockCountReport'>
  ) }
);

export type ExportSellProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSellProductCountReport'>
  ) }
);

export type ExportDiscountDiscountListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  discountCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creatorCd?: Maybe<Scalars['String']>;
  isBundle?: Maybe<Scalars['Int']>;
  isFirstOrder?: Maybe<Scalars['Int']>;
  externalReference?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ExportDiscountDiscountListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportDiscount'>
  ) }
);

export type ExportKpiConfigQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  description?: Maybe<Scalars['String']>;
  kpiConfigCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportKpiConfigQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportKpiConfig'>
  ) }
);

export type ExportKpiFormulasQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  formulas?: Maybe<KpiFormulaType>;
  kpiFormulaCd?: Maybe<Scalars['String']>;
}>;


export type ExportKpiFormulasQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportKPIFormulas'>
  ) }
);

export type ExportSurveysQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  description?: Maybe<Scalars['String']>;
  surveyCd?: Maybe<Scalars['String']>;
}>;


export type ExportSurveysQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSurveys'>
  ) }
);

export type ExportTradeQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  tradeCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ExportTradeQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportTrade'>
  ) }
);

export type ExportVisualQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  visualCd?: Maybe<Scalars['String']>;
}>;


export type ExportVisualQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportVisual'>
  ) }
);

export type ExportPromotionFollowDistributorQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
}>;


export type ExportPromotionFollowDistributorQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPromotionFollowDistributor'>
  ) }
);

export type ExportPromotionListQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportPromotionListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPromotionList'>
  ) }
);

export type ExportPurchaseByDistributorsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportPurchaseByDistributorsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPurchaseByDistributors'>
  ) }
);

export type ExportPurchaseByProductsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportPurchaseByProductsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPurchaseByProducts'>
  ) }
);

export type ExportPurchaseOrderDetailsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
}>;


export type ExportPurchaseOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPurchaseOrderDetails'>
  ) }
);

export type ExportPurchaseReceiptsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  vendorCd?: Maybe<Scalars['String']>;
  distributorCd?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
}>;


export type ExportPurchaseReceiptsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportPurchaseReceipts'>
  ) }
);

export type ExportReportQualityOutletQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesmanId?: Maybe<Scalars['Int']>;
  attrDetailId?: Maybe<Scalars['Int']>;
}>;


export type ExportReportQualityOutletQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportReportQualityOutlet'>
  ) }
);

export type ExportReasonEditOrdersListQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportReasonEditOrdersListQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportReasonEditOrdersList'>
  ) }
);

export type ExportWarehouseXntDetailsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportWarehouseXntDetailsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportWarehouseXntDetails'>
  ) }
);

export type ExportWarehouseXntDetailForDateSalesQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportWarehouseXntDetailForDateSalesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportWarehouseXntDetailForDateSales'>
  ) }
);

export type ExportReportWarehouseXntQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportReportWarehouseXntQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportReportWarehouseXnt'>
  ) }
);

export type ExportSalesByCustomersQueryVariables = Exact<{
  customerId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportSalesByCustomersQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportSalesByCustomers'>
  ) }
);

export type ExportSalesByProductsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportSalesByProductsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'salesByProducts'>
  ) }
);

export type ExportSalesBySalesForceQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ExportSalesBySalesForceQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportSalesBySalesForce'>
  ) }
);

export type ExportSalesOrdersQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
  salesOrderCd?: Maybe<Scalars['String']>;
}>;


export type ExportSalesOrdersQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSalesOrders'>
  ) }
);

export type ExportSalesOrderReturnsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  salesOrderReturnCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  reasonId?: Maybe<Scalars['Int']>;
}>;


export type ExportSalesOrderReturnsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSalesOrderReturns'>
  ) }
);

export type ExportSalesOrderDetailsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  salesmanCd?: Maybe<Scalars['String']>;
}>;


export type ExportSalesOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { exc: (
    { __typename?: 'ExcelCustomQueryEndpoint' }
    & Pick<ExcelCustomQueryEndpoint, 'exportSalesOrderDetails'>
  ) }
);

export type ExportSalesOrderDiscountLineQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerCd?: Maybe<Scalars['String']>;
}>;


export type ExportSalesOrderDiscountLineQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSalesOrderDiscountLine'>
  ) }
);

export type ExportSalesPricesQueryVariables = Exact<{
  productId?: Maybe<Scalars['Int']>;
  priceClassId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['String']>;
}>;


export type ExportSalesPricesQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSalesPrices'>
  ) }
);

export type ExportSalesRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportSalesRegionsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSalesRegions'>
  ) }
);

export type ExportShippersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type ExportShippersQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportShippers'>
  ) }
);

export type ExportSurveyListReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
}>;


export type ExportSurveyListReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportSurveyListReport'>
  ) }
);

export type ExportTransactionsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<TransactionStatus>;
  source?: Maybe<TransactionSource>;
  distributorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  creatorCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
}>;


export type ExportTransactionsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportTransactions'>
  ) }
);

export type ExportVisitCustomerQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ExportVisitCustomerQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'reportVisitCustomer'>
  ) }
);

export type ExportVisitPlanDetailsReportQueryVariables = Exact<{
  date: Scalars['Date'];
  salesmanId: Scalars['Int'];
}>;


export type ExportVisitPlanDetailsReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportVisitPlanDetailsReport'>
  ) }
);

export type ExportVisitPlanSummaryReportQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
}>;


export type ExportVisitPlanSummaryReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportVisitPlanSummaryReport'>
  ) }
);

export type ExportVisualListReportQueryVariables = Exact<{
  visualId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  minPass?: Maybe<Scalars['Int']>;
  maxPass?: Maybe<Scalars['Int']>;
}>;


export type ExportVisualListReportQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportVisualListReport'>
  ) }
);

export type ExportWorkGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportWorkGroupsQuery = (
  { __typename?: 'Query' }
  & { excel: (
    { __typename?: 'ExcelQueryEndpoint' }
    & Pick<ExcelQueryEndpoint, 'exportWorkGroups'>
  ) }
);

export type ImportCommandoLineMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ImportCommandoLineInput>> | Maybe<ImportCommandoLineInput>>;
}>;


export type ImportCommandoLineMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importCommandoLine?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportCommandoMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ImportCommandoInput>> | Maybe<ImportCommandoInput>>;
}>;


export type ImportCommandoMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importCommando?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportCustomerAttributesMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<AttrInput>> | Maybe<AttrInput>>;
}>;


export type ImportCustomerAttributesMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importCustomerAttributes?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportCustomerClassesMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<CustomerClassInput>> | Maybe<CustomerClassInput>>;
}>;


export type ImportCustomerClassesMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importCustomerClasses?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportCustomersMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<CustomerInput>> | Maybe<CustomerInput>>;
}>;


export type ImportCustomersMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importCustomers?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportDistributorMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<DistributorInput>> | Maybe<DistributorInput>>;
}>;


export type ImportDistributorMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importDistributor?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportEmployeesMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<EmployeeInput>> | Maybe<EmployeeInput>>;
}>;


export type ImportEmployeesMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importEmployees?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportInventoryMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ImportInventoryInput>> | Maybe<ImportInventoryInput>>;
}>;


export type ImportInventoryMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importInventory?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportKpiConfigMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<KpiConfigInput>> | Maybe<KpiConfigInput>>;
}>;


export type ImportKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importKpiConfig?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportKpiFormulaMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<KpiFormulaInput>> | Maybe<KpiFormulaInput>>;
}>;


export type ImportKpiFormulaMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importKpiFormula?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportMcpMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ImportMcpInput>> | Maybe<ImportMcpInput>>;
}>;


export type ImportMcpMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importMcp?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportMeasureUnitMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<MeasureUnitInput>> | Maybe<MeasureUnitInput>>;
}>;


export type ImportMeasureUnitMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importMeasureUnit?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportOtherProductMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<OtherProductInput>> | Maybe<OtherProductInput>>;
}>;


export type ImportOtherProductMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importOtherProduct?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportProductAttributesMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<AttrInput>> | Maybe<AttrInput>>;
}>;


export type ImportProductAttributesMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importProductAttributes?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportProductsMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ProductInput>> | Maybe<ProductInput>>;
}>;


export type ImportProductsMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importProducts?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportPurchaseReceiptMutationVariables = Exact<{
  data: Array<Maybe<ImportPurchaseReceiptInput>> | Maybe<ImportPurchaseReceiptInput>;
  isUpdate?: Maybe<Scalars['Boolean']>;
}>;


export type ImportPurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importPurchaseReceipt?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportSalesPricesMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<SalesPriceInput>> | Maybe<SalesPriceInput>>;
}>;


export type ImportSalesPricesMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importSalesPrices?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ImportTradeParticipationMutationVariables = Exact<{
  data?: Maybe<Array<Maybe<ImportTradeParticipationInput>> | Maybe<ImportTradeParticipationInput>>;
}>;


export type ImportTradeParticipationMutation = (
  { __typename?: 'Mutation' }
  & { excel?: Maybe<(
    { __typename?: 'ExcelMutationEndpoint' }
    & { importTradeParticipation?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CompleteExchangeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteExchangeMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { completeExchange?: Maybe<(
      { __typename?: 'ExchangePayload' }
      & Pick<ExchangePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Exchange' }
        & Pick<Exchange, 'id' | 'cd' | 'exchangeStatus' | 'exchangeSource' | 'exchangeDate' | 'exchangeAt' | 'description' | 'note' | 'customerId' | 'distributorId' | 'reasonEditOrderId'>
        & { reasonEditOrder: (
          { __typename?: 'ReasonEditOrder' }
          & Pick<ReasonEditOrder, 'id' | 'name' | 'reasonType' | 'description'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )>, exchangeItems: Array<(
          { __typename?: 'ExchangeItem' }
          & Pick<ExchangeItem, 'id' | 'warehouseId' | 'productId' | 'qty' | 'unitId' | 'returnLotNbr' | 'returnStartDate' | 'returnEndDate' | 'exchangeLotNbr' | 'exchangeStartDate' | 'exchangeEndDate'>
          & { product?: Maybe<(
            { __typename?: 'Product' }
            & Pick<Product, 'cd' | 'id' | 'name'>
          )>, warehouse?: Maybe<(
            { __typename?: 'Warehouse' }
            & Pick<Warehouse, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CreateOrUpdateExchangeMutationVariables = Exact<{
  data: ExchangeInput;
}>;


export type CreateOrUpdateExchangeMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { createOrUpdateExchange?: Maybe<(
      { __typename?: 'ExchangePayload' }
      & Pick<ExchangePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Exchange' }
        & Pick<Exchange, 'id' | 'cd' | 'exchangeStatus' | 'exchangeSource' | 'exchangeDate' | 'exchangeAt' | 'description' | 'note' | 'customerId' | 'distributorId' | 'reasonEditOrderId'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
        )>, exchangeItems: Array<(
          { __typename?: 'ExchangeItem' }
          & Pick<ExchangeItem, 'warehouseId' | 'productId' | 'exchangeId' | 'qty' | 'unitId' | 'returnLotNbr' | 'returnStartDate' | 'returnEndDate' | 'exchangeLotNbr' | 'exchangeStartDate' | 'exchangeEndDate'>
        )> }
      )> }
    )> }
  )> }
);

export type ExchangeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  employeeCd?: Maybe<Scalars['String']>;
  reasonExchangeId?: Maybe<Scalars['Int']>;
}>;


export type ExchangeListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { exchangeList: (
      { __typename?: 'ExchangeListResult' }
      & Pick<ExchangeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Exchange' }
        & Pick<Exchange, 'id' | 'cd' | 'exchangeStatus' | 'exchangeSource' | 'exchangeDate' | 'exchangeAt' | 'description' | 'note' | 'customerId' | 'distributorId' | 'reasonEditOrderId'>
        & { reasonEditOrder: (
          { __typename?: 'ReasonEditOrder' }
          & Pick<ReasonEditOrder, 'id' | 'name' | 'reasonType' | 'description'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type ExchangeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ExchangeQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { exchange: (
      { __typename?: 'Exchange' }
      & Pick<Exchange, 'id' | 'cd' | 'exchangeStatus' | 'exchangeSource' | 'exchangeDate' | 'exchangeAt' | 'description' | 'note' | 'customerId' | 'distributorId' | 'reasonEditOrderId'>
      & { reasonEditOrder: (
        { __typename?: 'ReasonEditOrder' }
        & Pick<ReasonEditOrder, 'id' | 'name' | 'reasonType' | 'description'>
      ), customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name'>
      ), distributor: (
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'cd' | 'name'>
      ), salesman?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name'>
      )>, exchangeItems: Array<(
        { __typename?: 'ExchangeItem' }
        & Pick<ExchangeItem, 'id' | 'warehouseId' | 'productId' | 'qty' | 'unitId' | 'returnLotNbr' | 'returnStartDate' | 'returnEndDate' | 'exchangeLotNbr' | 'exchangeStartDate' | 'exchangeEndDate'>
        & { product?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'id' | 'name'>
        )>, warehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type CloseKpiFormulaMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseKpiFormulaMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeKpiFormula?: Maybe<(
      { __typename?: 'KpiFormulaPayload' }
      & Pick<KpiFormulaPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiFormula' }
        & Pick<KpiFormula, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateKpiFormulaMutationVariables = Exact<{
  data: KpiFormulaInput;
}>;


export type CreateOrUpdateKpiFormulaMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateKpiFormula?: Maybe<(
      { __typename?: 'KpiFormulaPayload' }
      & Pick<KpiFormulaPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiFormula' }
        & KpiFormulaFragment
      )> }
    )> }
  )> }
);

export type DeleteKpiFormulaMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteKpiFormulaMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteKpiFormula?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type KpiFormulasQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  formulas?: Maybe<KpiFormulaType>;
  kpiFormulaCd?: Maybe<Scalars['String']>;
}>;


export type KpiFormulasQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { kpiFormulas: Array<(
      { __typename?: 'KpiFormula' }
      & Pick<KpiFormula, 'id' | 'cd' | 'status' | 'name' | 'formula' | 'description' | 'createdAt'>
      & { creator?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'cd' | 'name'>
      )> }
    )> }
  ) }
);

export type KpiFormulaFragment = (
  { __typename: 'KpiFormula' }
  & Pick<KpiFormula, 'id' | 'status' | 'name' | 'description' | 'formula' | 'amount' | 'percentage' | 'expiresBackDate'>
  & { attrDetail?: Maybe<(
    { __typename?: 'AttrDetail' }
    & Pick<AttrDetail, 'id' | 'value' | 'description'>
  )>, kpiFormulaCategories: Array<(
    { __typename?: 'KpiFormulaCategory' }
    & Pick<KpiFormulaCategory, 'id' | 'attrDetailId' | 'percentage'>
    & { attrDetail: (
      { __typename?: 'AttrDetail' }
      & Pick<AttrDetail, 'id' | 'value' | 'description'>
    ) }
  )>, kpiFormulaProducts: Array<(
    { __typename?: 'KpiFormulaProduct' }
    & Pick<KpiFormulaProduct, 'id' | 'rate' | 'productId'>
  )> }
);

export type KpiFormulaQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type KpiFormulaQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { kpiFormula: (
      { __typename?: 'KpiFormula' }
      & KpiFormulaFragment
    ) }
  ) }
);

export type ReleaseKpiFormulaMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseKpiFormulaMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseKpiFormula?: Maybe<(
      { __typename?: 'KpiFormulaPayload' }
      & Pick<KpiFormulaPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiFormula' }
        & Pick<KpiFormula, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CalculateKpiConfigMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CalculateKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { calculateKpiConfig?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CloseKpiConfigMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeKpiConfig?: Maybe<(
      { __typename?: 'KpiConfigPayload' }
      & Pick<KpiConfigPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiConfig' }
        & Pick<KpiConfig, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateKpiConfigMutationVariables = Exact<{
  data: KpiConfigInput;
}>;


export type CreateOrUpdateKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateKpiConfig?: Maybe<(
      { __typename?: 'KpiConfigPayload' }
      & Pick<KpiConfigPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiConfig' }
        & KpiConfigFragment
      )> }
    )> }
  )> }
);

export type DeleteKpiConfigMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteKpiConfig?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type KpiConfigFragment = (
  { __typename: 'KpiConfig' }
  & Pick<KpiConfig, 'id' | 'cd' | 'status' | 'target' | 'name' | 'description' | 'startDate' | 'endDate'>
  & { kpiConfigItems: Array<(
    { __typename?: 'KpiConfigItem' }
    & Pick<KpiConfigItem, 'id' | 'actual' | 'target' | 'rate' | 'percentageActual'>
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'cd' | 'name'>
    )>, kpiFormula: (
      { __typename?: 'KpiFormula' }
      & Pick<KpiFormula, 'id' | 'cd' | 'name'>
    ), distributor?: Maybe<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name'>
    )> }
  )> }
);

export type KpiConfigListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  description?: Maybe<Scalars['String']>;
  kpiConfigCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type KpiConfigListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { kpiConfigList: (
      { __typename?: 'KpiConfigListResult' }
      & Pick<KpiConfigListResult, 'total'>
      & { data: Array<(
        { __typename?: 'KpiConfig' }
        & Pick<KpiConfig, 'id' | 'cd' | 'status' | 'target' | 'name' | 'description' | 'startDate' | 'endDate'>
      )> }
    ) }
  ) }
);

export type KpiConfigQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type KpiConfigQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { kpiConfig: (
      { __typename?: 'KpiConfig' }
      & KpiConfigFragment
    ) }
  ) }
);

export type ReleaseKpiConfigMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseKpiConfigMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseKpiConfig?: Maybe<(
      { __typename?: 'KpiConfigPayload' }
      & Pick<KpiConfigPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'KpiConfig' }
        & Pick<KpiConfig, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CloseMcpMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseMcpMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { closeMcp?: Maybe<(
      { __typename?: 'McpPayload' }
      & Pick<McpPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Mcp' }
        & Pick<Mcp, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CopyMcpMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CopyMcpMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { copyMcp?: Maybe<(
      { __typename?: 'McpPayload' }
      & Pick<McpPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Mcp' }
        & Pick<Mcp, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateMcpLineMutationVariables = Exact<{
  data: McpLineInput;
}>;


export type CreateOrUpdateMcpLineMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateMcpLine?: Maybe<(
      { __typename?: 'McpLinePayload' }
      & Pick<McpLinePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'McpLine' }
        & Pick<McpLine, 'id' | 'weekdays' | 'weeksOfMonth'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateMcpMutationVariables = Exact<{
  data: McpInput;
}>;


export type CreateOrUpdateMcpMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateMcp?: Maybe<(
      { __typename?: 'McpPayload' }
      & Pick<McpPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Mcp' }
        & { mcpDistributors: Array<(
          { __typename?: 'McpDistributor' }
          & Pick<McpDistributor, 'id'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'name'>
          ) }
        )> }
        & McpFragment
      )> }
    )> }
  )> }
);

export type CustomersByDistributorsQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorIds: Array<Scalars['Int']> | Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type CustomersByDistributorsQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { customersByDistributors: (
      { __typename?: 'CustomerListResult' }
      & Pick<CustomerListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name' | 'customerPriceClassId' | 'isDistributor'>
      )> }
    ) }
  ) }
);

export type DeleteMcpLineMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMcpLineMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { deleteMcpLine?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type McpDetailsQueryVariables = Exact<{
  mcpId: Scalars['Int'];
  visitDate: Scalars['Date'];
}>;


export type McpDetailsQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { mcpDetails: Array<(
      { __typename?: 'McpDetail' }
      & Pick<McpDetail, 'id' | 'visitIndex'>
      & { mcpLine: (
        { __typename?: 'McpLine' }
        & Pick<McpLine, 'id' | 'weekdays' | 'weeksOfMonth'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type McpFragment = (
  { __typename: 'Mcp' }
  & Pick<Mcp, 'id' | 'cd' | 'status' | 'sellTypes' | 'description' | 'inRangeMeter' | 'hasOrderRequiredTasks' | 'allowOutPlanVisit' | 'allowNewCustomerVisit' | 'startDate' | 'endDate'>
  & { salesRegion: (
    { __typename?: 'SalesRegion' }
    & Pick<SalesRegion, 'id' | 'cd' | 'name'>
  ), creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )>, salesman: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  ) }
);

export type McpLineListQueryVariables = Exact<{
  mcpId: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  cd:  Scalars['String'];
  name : Scalars['String'];
}>;


export type McpLineListQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { mcpLineList: (
      { __typename?: 'McpLineListResult' }
      & Pick<McpLineListResult, 'total'>
      & { data: Array<(
        { __typename?: 'McpLine' }
        & Pick<McpLine, 'id' | 'weekdays' | 'weeksOfMonth'>
        & { distributors: Array<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        )>, mcpDistributors: Array<(
          { __typename?: 'McpDistributor' }
          & Pick<McpDistributor, 'id'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'cd' | 'name'>
          ) }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name' | 'phone11'>
        ) }
      )> }
    ) }
  ) }
);

export type McpListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<ProgramStatus>;
  salesmanCd?: Maybe<Scalars['String']>;
  mcpCd?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type McpListQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { mcpList: (
      { __typename?: 'McpListResult' }
      & Pick<McpListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Mcp' }
        & McpFragment
      )> }
    ) }
  ) }
);

export type McpQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type McpQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { mcp: (
      { __typename?: 'Mcp' }
      & { mcpDistributors: Array<(
        { __typename?: 'McpDistributor' }
        & Pick<McpDistributor, 'id'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
      & McpFragment
    ) }
  ) }
);

export type ReleaseMcpMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseMcpMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { releaseMcp?: Maybe<(
      { __typename?: 'McpPayload' }
      & Pick<McpPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Mcp' }
        & Pick<Mcp, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateMeasureUnitMutationVariables = Exact<{
  data: MeasureUnitInput;
}>;


export type CreateOrUpdateMeasureUnitMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateMeasureUnit?: Maybe<(
      { __typename?: 'MeasureUnitPayload' }
      & Pick<MeasureUnitPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'MeasureUnit' }
        & MeasureUnitFragment
      )> }
    )> }
  )> }
);

export type DeleteMeasureUnitMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMeasureUnitMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteMeasureUnit?: Maybe<(
      { __typename?: 'StringPayload' }
      & Pick<StringPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type MeasureUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type MeasureUnitsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { measureUnits: Array<(
      { __typename?: 'MeasureUnit' }
      & MeasureUnitFragment
    )> }
  ) }
);

export type MeasureUnitFragment = (
  { __typename: 'MeasureUnit' }
  & Pick<MeasureUnit, 'id' | 'cd' | 'description'>
);

export type MeasureUnitQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MeasureUnitQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { measureUnit: (
      { __typename?: 'MeasureUnit' }
      & MeasureUnitFragment
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { changePassword?: Maybe<(
      { __typename?: 'LoginResultPayload' }
      & Pick<LoginResultPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'LoginResult' }
        & Pick<LoginResult, 'accessToken'>
      )> }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  data: LoginInput;
  deviceUser?: Maybe<DeviceUserInput>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginResultPayload' }
    & Pick<LoginResultPayload, 'success'>
    & { message?: Maybe<(
      { __typename?: 'PayloadMessage' }
      & Pick<PayloadMessage, 'code' | 'message'>
    )>, content?: Maybe<(
      { __typename?: 'LoginResult' }
      & Pick<LoginResult, 'accessToken'>
    )> }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'BoolPayload' }
    & Pick<BoolPayload, 'success' | 'content'>
    & { message?: Maybe<(
      { __typename?: 'PayloadMessage' }
      & Pick<PayloadMessage, 'code' | 'message'>
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & Pick<Me, 'id' | 'cd' | 'name' | 'username' | 'permissions'>
  ) }
);

export type ProductPricesQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  customerPriceClassId?: Maybe<Scalars['Int']>;
}>;


export type ProductPricesQuery = (
  { __typename?: 'Query' }
  & { productPrices: Array<(
    { __typename?: 'ProductPrice' }
    & Pick<ProductPrice, 'id' | 'productId' | 'unitId' | 'price'>
  )> }
);

export type WorkingCustomerListByMcpQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  isDistributor?: Maybe<Scalars['Boolean']>;
}>;


export type WorkingCustomerListByMcpQuery = (
  { __typename?: 'Query' }
  & { workingCustomerListByMcp: (
    { __typename?: 'CustomerListResult' }
    & Pick<CustomerListResult, 'total'>
    & { data: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'cd' | 'name' | 'customerPriceClassId' | 'isDistributor'>
    )> }
  ) }
);

export type WorkingCustomerListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  isDistributor?: Maybe<Scalars['Boolean']>;
}>;


export type WorkingCustomerListQuery = (
  { __typename?: 'Query' }
  & { workingCustomerList: (
    { __typename?: 'CustomerListResult' }
    & Pick<CustomerListResult, 'total'>
    & { data: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'cd' | 'name' | 'customerPriceClassId' | 'isDistributor'>
    )> }
  ) }
);

export type WorkingDistributorsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkingDistributorsQuery = (
  { __typename?: 'Query' }
  & { workingDistributors: Array<(
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'cd' | 'name'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'customerPriceClassId'>
    ), warehouses: Array<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name' | 'pickOrder' | 'allowPurchase' | 'allowSales' | 'allowReceipt' | 'allowIssue' | 'allowAdjust' | 'allowTransfer'>
    )> }
  )> }
);

export type WorkingSalesmansByMcpQueryVariables = Exact<{
  distributorId: Scalars['Int'];
  customerId: Scalars['Int'];
}>;


export type WorkingSalesmansByMcpQuery = (
  { __typename?: 'Query' }
  & { workingSalesmansByMcp: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )> }
);

export type WorkingSalesmansQueryVariables = Exact<{
  distributorId: Scalars['Int'];
}>;


export type WorkingSalesmansQuery = (
  { __typename?: 'Query' }
  & { workingSalesmans: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )> }
);

export type CreateOrUpdateOtherProductMutationVariables = Exact<{
  data: OtherProductInput;
}>;


export type CreateOrUpdateOtherProductMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateOtherProduct?: Maybe<(
      { __typename?: 'OtherProductPayload' }
      & Pick<OtherProductPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'OtherProduct' }
        & OtherProductFragment
      )> }
    )> }
  )> }
);

export type DeleteOtherProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOtherProductMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteOtherProduct?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type OtherProductsQueryVariables = Exact<{
  otherProductCd?: Maybe<Scalars['String']>;
  otherProductName?: Maybe<Scalars['String']>;
}>;


export type OtherProductsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { otherProducts: Array<(
      { __typename?: 'OtherProduct' }
      & OtherProductFragment
    )> }
  ) }
);

export type OtherProductFragment = (
  { __typename: 'OtherProduct' }
  & Pick<OtherProduct, 'id' | 'cd' | 'name' | 'description' | 'measureUnitId'>
);

export type OtherProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OtherProductQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { otherProduct: (
      { __typename?: 'OtherProduct' }
      & OtherProductFragment
    ) }
  ) }
);

export type ReportPerformanceDistributorListQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  distributorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportPerformanceDistributorListQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportPerformanceDistributorList: (
      { __typename?: 'ReportPerformanceDistributorListResult' }
      & Pick<ReportPerformanceDistributorListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPerformanceDistributor' }
        & Pick<ReportPerformanceDistributor, 'distributorId' | 'productId' | 'qtySellOut' | 'qtySellIn' | 'percentOutIn'>
        & { distributor?: Maybe<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        )>, product?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name' | 'baseUnitId'>
        )> }
      )> }
    ) }
  ) }
);

export type UploadPhotoMutationVariables = Exact<{
  data: UploadPhotoInput;
}>;


export type UploadPhotoMutation = (
  { __typename?: 'Mutation' }
  & { uploadPhoto?: Maybe<(
    { __typename?: 'PhotoPayload' }
    & Pick<PhotoPayload, 'success'>
    & { message?: Maybe<(
      { __typename?: 'PayloadMessage' }
      & Pick<PayloadMessage, 'code' | 'message'>
    )>, content?: Maybe<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'url'>
    )> }
  )> }
);

export type CreateOrUpdatePositionMutationVariables = Exact<{
  data: PositionInput;
}>;


export type CreateOrUpdatePositionMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdatePosition?: Maybe<(
      { __typename?: 'PositionPayload' }
      & Pick<PositionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Position' }
        & Pick<Position, 'id' | 'name' | 'description'>
        & { positionPermissions: Array<(
          { __typename?: 'PositionPermission' }
          & Pick<PositionPermission, 'id' | 'permission'>
        )> }
      )> }
    )> }
  )> }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & Pick<MasterDataQueryEndpoint, 'permissions'>
  ) }
);

export type PositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PositionsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { positions: Array<(
      { __typename?: 'Position' }
      & PositionFragment
    )> }
  ) }
);

export type PositionFragment = (
  { __typename: 'Position' }
  & Pick<Position, 'id' | 'name' | 'description'>
  & { positionPermissions: Array<(
    { __typename?: 'PositionPermission' }
    & Pick<PositionPermission, 'id' | 'permission'>
  )> }
);

export type PositionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PositionQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { position: (
      { __typename?: 'Position' }
      & PositionFragment
    ) }
  ) }
);

export type CreateOrUpdateProductGroupMutationVariables = Exact<{
  data: ProductGroupInput;
}>;


export type CreateOrUpdateProductGroupMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateProductGroup?: Maybe<(
      { __typename?: 'ProductGroupPayload' }
      & Pick<ProductGroupPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'ProductGroup' }
        & ProductGroupFragment
      )> }
    )> }
  )> }
);

export type DeleteProductGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductGroupMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteProductGroup?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ProductGroupsQueryVariables = Exact<{
  productGroupCd?: Maybe<Scalars['String']>;
  productGroupName?: Maybe<Scalars['String']>;
  isBundle?: Maybe<Scalars['Boolean']>;
}>;


export type ProductGroupsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { productGroups: Array<(
      { __typename?: 'ProductGroup' }
      & ProductGroupFragment
    )> }
  ) }
);

export type ProductGroupFragment = (
  { __typename: 'ProductGroup' }
  & Pick<ProductGroup, 'id' | 'cd' | 'name' | 'description' | 'isBundle'>
  & { productGroupItems: Array<(
    { __typename?: 'ProductGroupItem' }
    & Pick<ProductGroupItem, 'id' | 'productId' | 'rate'>
  )> }
);

export type ProductGroupItemsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductGroupItemsMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { productGroupItems: Array<(
      { __typename?: 'ProductGroupItem' }
      & Pick<ProductGroupItem, 'id' | 'productId' | 'rate'>
    )> }
  )> }
);

export type ProductGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductGroupQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { productGroup: (
      { __typename?: 'ProductGroup' }
      & ProductGroupFragment
    ) }
  ) }
);

export type ActiveProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActiveProductMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { activeProduct?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type AllProductsQueryVariables = Exact<{
  productFilter?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type AllProductsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { allProducts: Array<(
      { __typename?: 'Product' }
      & ProductFragment
    )> }
  ) }
);

export type CreateOrUpdateProductMutationVariables = Exact<{
  data: ProductInput;
}>;


export type CreateOrUpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateProduct?: Maybe<(
      { __typename?: 'ProductPayload' }
      & Pick<ProductPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Product' }
        & { productAttrDetails: Array<(
          { __typename?: 'ProductAttrDetail' }
          & Pick<ProductAttrDetail, 'id' | 'attrId' | 'attrDetailId'>
        )> }
        & ProductFragment
      )> }
    )> }
  )> }
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteProduct?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { products: Array<(
      { __typename?: 'Product' }
      & ProductFragment
    )> }
  ) }
);

export type ProductFragment = (
  { __typename: 'Product' }
  & Pick<Product, 'id' | 'cd' | 'name' | 'description' | 'basePrice' | 'baseUnitId' | 'salesUnitId' | 'purchaseUnitId' | 'isPop' | 'isExpirationDate' | 'isLot' | 'isActive' | 'tax'>
  & { photo?: Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'id' | 'url'>
  )>, productMeasureUnits: Array<(
    { __typename?: 'ProductMeasureUnit' }
    & Pick<ProductMeasureUnit, 'id' | 'fromUnitId' | 'toUnitId' | 'rate' | 'saleable'>
  )> }
);

export type ProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { product: (
      { __typename?: 'Product' }
      & { productAttrDetails: Array<(
        { __typename?: 'ProductAttrDetail' }
        & Pick<ProductAttrDetail, 'id' | 'attrId' | 'attrDetailId'>
      )> }
      & ProductFragment
    ) }
  ) }
);

export type ActionCancelPurchaseReceiptMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ActionCancelPurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'actionCancelPurchaseReceipt'>
  )> }
);

export type ActionCompletePurchaseReceiptMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ActionCompletePurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'actionCompletePurchaseReceipt'>
  )> }
);

export type ActionProcessPurchaseReceiptMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ActionProcessPurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'actionProcessPurchaseReceipt'>
  )> }
);

export type CancelPurchaseReceiptMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelPurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { cancelPurchaseReceipt?: Maybe<(
      { __typename?: 'PurchaseReceiptPayload' }
      & Pick<PurchaseReceiptPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'PurchaseReceipt' }
        & PurchaseReceiptFragment
      )> }
    )> }
  )> }
);

export type CompletePurchaseReceiptMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompletePurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { completePurchaseReceipt?: Maybe<(
      { __typename?: 'PurchaseReceiptPayload' }
      & Pick<PurchaseReceiptPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'PurchaseReceipt' }
        & PurchaseReceiptFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdatePurchaseReceiptMutationVariables = Exact<{
  data: PurchaseReceiptInput;
}>;


export type CreateOrUpdatePurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { createOrUpdatePurchaseReceipt?: Maybe<(
      { __typename?: 'PurchaseReceiptPayload' }
      & Pick<PurchaseReceiptPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'PurchaseReceipt' }
        & PurchaseReceiptFragment
      )> }
    )> }
  )> }
);

export type ProcessPurchaseReceiptMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProcessPurchaseReceiptMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { processPurchaseReceipt?: Maybe<(
      { __typename?: 'PurchaseReceiptPayload' }
      & Pick<PurchaseReceiptPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'PurchaseReceipt' }
        & PurchaseReceiptFragment
      )> }
    )> }
  )> }
);

export type PurchaseReceiptFragment = (
  { __typename: 'PurchaseReceipt' }
  & Pick<PurchaseReceipt, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderTotal' | 'orderType' | 'description' | 'externalReference' | 'salesOrderId'>
  & { deliveryNote?: Maybe<(
    { __typename?: 'DeliveryNote' }
    & Pick<DeliveryNote, 'id'>
  )>, vendor: (
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'name' | 'phone11' | 'fullAddress'>
  ), distributor: (
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'cd' | 'name' | 'phone11'>
  ), employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )>, orderItems?: Maybe<Array<(
    { __typename?: 'PurchaseReceiptItem' }
    & Pick<PurchaseReceiptItem, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
    ), warehouse: (
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    ) }
  )>>, purchaseReceiptTrackLots?: Maybe<Array<(
    { __typename?: 'PurchaseReceiptTrackLot' }
    & Pick<PurchaseReceiptTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate' | 'warehouseId' | 'productId'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    )> }
  )>> }
);

export type PurchaseReceiptListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  vendorCd?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
}>;


export type PurchaseReceiptListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { purchaseReceiptList: (
      { __typename?: 'PurchaseReceiptListResult' }
      & Pick<PurchaseReceiptListResult, 'total'>
      & { data: Array<(
        { __typename?: 'PurchaseReceipt' }
        & Pick<PurchaseReceipt, 'id' | 'cd' | 'orderDate' | 'orderType' | 'orderStatus' | 'orderTotal' | 'description' | 'externalReference'>
        & { vendor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type PurchaseReceiptProcessListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  action: ActionType;
  distributorCd?: Maybe<Scalars['String']>;
  vendorCd?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['Int']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
}>;


export type PurchaseReceiptProcessListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { purchaseReceiptProcessList: (
      { __typename?: 'PurchaseReceiptListResult' }
      & Pick<PurchaseReceiptListResult, 'total'>
      & { data: Array<(
        { __typename?: 'PurchaseReceipt' }
        & Pick<PurchaseReceipt, 'id' | 'cd' | 'orderDate' | 'orderType' | 'orderStatus' | 'orderTotal' | 'description'>
        & { vendor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type PurchaseReceiptQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PurchaseReceiptQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { purchaseReceipt: (
      { __typename?: 'PurchaseReceipt' }
      & PurchaseReceiptFragment
    ) }
  ) }
);

export type PurchaseReceiptTrackLotMutationVariables = Exact<{
  data: Array<PurchaseReceiptItemInput> | PurchaseReceiptItemInput;
}>;


export type PurchaseReceiptTrackLotMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { purchaseReceiptTrackLot: Array<(
      { __typename?: 'PurchaseReceiptTrackLot' }
      & Pick<PurchaseReceiptTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate' | 'warehouseId' | 'productId'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
      )>, product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'cd' | 'name' | 'tax'>
      )> }
    )> }
  )> }
);

export type SellersDistributorListQueryVariables = Exact<{
  distributorId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type SellersDistributorListQuery = (
  { __typename?: 'Query' }
  & { sellersDistributorList: (
    { __typename?: 'DistributorListResult' }
    & Pick<DistributorListResult, 'total'>
    & { data: Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name'>
    )> }
  ) }
);

export type ReportQualityOutletQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salesmanId?: Maybe<Scalars['Int']>;
  attrDetailId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportQualityOutletQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportQualityOutlet: (
      { __typename?: 'ReportQualityOutletListResult' }
      & Pick<ReportQualityOutletListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportQualityOutlet' }
        & Pick<ReportQualityOutlet, 'customerId' | 'salesmanId'>
        & { customer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11' | 'address1' | 'attrDetailIds'>
          & { customerClass: (
            { __typename?: 'CustomerClass' }
            & Pick<CustomerClass, 'cd' | 'name'>
          ), customerPriceClass?: Maybe<(
            { __typename?: 'CustomerPriceClass' }
            & Pick<CustomerPriceClass, 'cd' | 'name'>
          )>, ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )> }
        )>, salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
          & { supervisor?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          )>, workingSalesRegions: Array<(
            { __typename?: 'WorkingSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name'>
            ) }
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type DistrictsQueryVariables = Exact<{
  provinceId: Scalars['Int'];
}>;


export type DistrictsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { districts: Array<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )> }
  ) }
);

export type ProvincesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProvincesQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { provinces: Array<(
      { __typename?: 'Province' }
      & Pick<Province, 'id' | 'name'>
    )> }
  ) }
);

export type WardsQueryVariables = Exact<{
  districtId: Scalars['Int'];
}>;


export type WardsQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { wards: Array<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
    )> }
  ) }
);

export type ReportPromotionSummarizingMoneyQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type ReportPromotionSummarizingMoneyQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportPromotionSummarizingMoney: (
      { __typename?: 'ReportPromotionSummarizingListResult' }
      & Pick<ReportPromotionSummarizingListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPromotionSummarizing' }
        & Pick<ReportPromotionSummarizing, 'distributorId' | 'customerId' | 'discountId' | 'amountOrder' | 'amount' | 'amountPercentage' | 'amountProduct' | 'amountDiscount'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'address1' | 'attrDetailIds'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'name'>
              ) }
            ) }
          )> }
        ), discount: (
          { __typename?: 'Discount' }
          & Pick<Discount, 'cd' | 'description'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportPromotionSummarizingProductQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  salesRegionId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type ReportPromotionSummarizingProductQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportPromotionSummarizingProduct: (
      { __typename?: 'ReportPromotionSummarizingListResult' }
      & Pick<ReportPromotionSummarizingListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPromotionSummarizing' }
        & Pick<ReportPromotionSummarizing, 'distributorId' | 'customerId' | 'discountId' | 'amountOrder' | 'amountProduct'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'address1' | 'attrDetailIds'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'name'>
              ) }
            ) }
          )> }
        ), discount: (
          { __typename?: 'Discount' }
          & Pick<Discount, 'cd' | 'description'>
        ), salesOrderFreeItems?: Maybe<Array<Maybe<(
          { __typename?: 'SalesOrderFreeItem' }
          & Pick<SalesOrderFreeItem, 'id' | 'qty' | 'productId'>
          & { product: (
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'cd' | 'name' | 'description' | 'basePrice'>
          ) }
        )>>> }
      )> }
    ) }
  ) }
);

export type ChangeReportConfigMutationVariables = Exact<{
  data: Array<InputReportDataConfig> | InputReportDataConfig;
}>;


export type ChangeReportConfigMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { changeReportConfig?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type CurrentWarehouseProductsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type CurrentWarehouseProductsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { currentWarehouseProducts: Array<(
      { __typename?: 'WarehouseProduct' }
      & Pick<WarehouseProduct, 'id' | 'qty' | 'availableQty' | 'baseUnitId' | 'lotNbr' | 'startDate' | 'endDate'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'cd' | 'id' | 'name' | 'purchaseUnitId'>
        & { productMeasureUnits: Array<(
          { __typename?: 'ProductMeasureUnit' }
          & Pick<ProductMeasureUnit, 'id' | 'fromUnitId' | 'toUnitId' | 'rate' | 'saleable'>
        )> }
      ), warehouse: (
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type CustomerSurveyReportQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ProgramStatus>;
}>;


export type CustomerSurveyReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { customerSurveyReport: (
      { __typename?: 'CustomerSurveyListResult' }
      & Pick<CustomerSurveyListResult, 'total'>
      & { data: Array<(
        { __typename?: 'CustomerSurvey' }
        & { survey: (
          { __typename?: 'Survey' }
          & Pick<Survey, 'cd' | 'title' | 'description' | 'status' | 'startDate' | 'endDate' | 'multiple'>
          & { surveyApplicableSalesRegions: Array<(
            { __typename?: 'SurveyApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name'>
            ) }
          )>, surveyApplicableCustomerAttrs: Array<(
            { __typename?: 'SurveyApplicableCustomerAttr' }
            & Pick<SurveyApplicableCustomerAttr, 'attrId' | 'attrDetailId'>
          )> }
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ), customerSurveyDetails?: Maybe<Array<Maybe<(
          { __typename?: 'CustomerSurveyDetail' }
          & Pick<CustomerSurveyDetail, 'textAnswer'>
          & { surveyQuestion: (
            { __typename?: 'SurveyQuestion' }
            & Pick<SurveyQuestion, 'title'>
          ), surveyAnswer?: Maybe<(
            { __typename?: 'SurveyAnswer' }
            & Pick<SurveyAnswer, 'title'>
          )> }
        )>>> }
      )> }
    ) }
  ) }
);

export type FilterKpiConfigReportQueryVariables = Exact<{
  target?: Maybe<KpiApplyTarget>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type FilterKpiConfigReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { filterKpiConfigReport: (
      { __typename?: 'KpiConfigListResult' }
      & Pick<KpiConfigListResult, 'total'>
      & { data: Array<(
        { __typename?: 'KpiConfig' }
        & Pick<KpiConfig, 'id' | 'cd' | 'name' | 'description'>
      )> }
    ) }
  ) }
);

export type FollowLoyaltyRegistrationQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  startDateTrade?: Maybe<Scalars['Date']>;
  endDateTrade?: Maybe<Scalars['Date']>;
  statusTrade?: Maybe<ProgramStatus>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<TradeParticipationStatus>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type FollowLoyaltyRegistrationQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { followLoyaltyRegistration: (
      { __typename?: 'TradeParticipationListResult' }
      & Pick<TradeParticipationListResult, 'total'>
      & { data: Array<(
        { __typename?: 'TradeParticipation' }
        & Pick<TradeParticipation, 'tradeId' | 'customerId' | 'level' | 'status' | 'result' | 'incentiveResult' | 'lastCalculatedDate'>
        & { trade: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'cd' | 'description' | 'status' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportProcedureQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportProcedureQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportProcedure: Array<(
      { __typename?: 'ReportProcedure' }
      & Pick<ReportProcedure, 'name'>
    )> }
  ) }
);

export type ReportDataConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportDataConfigQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportDataConfig: Array<(
      { __typename?: 'ReportDataConfig' }
      & Pick<ReportDataConfig, 'id' | 'name' | 'procedureName' | 'isWeb' | 'isMobile' | 'isActive' | 'isAlive'>
    )> }
  ) }
);

export type ProcedureDataQueryVariables = Exact<{
  procedureName: Scalars['String'];
}>;


export type ProcedureDataQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { procedureData: Array<(
      { __typename?: 'ProcedureData' }
      & Pick<ProcedureData, 'name' | 'data'>
    )> }
  ) }
);

export type MasterDataCustomersQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  customerClassId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  sourceCustomer?: Maybe<CustomerSource>;
  provinceId?:Maybe<Scalars['Int']>;
  customerStatus?:Maybe<Scalars['String']>;
 
}>;


export type MasterDataCustomersQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { masterDataCustomers: (
      { __typename?: 'CustomerListResult' }
      & Pick<CustomerListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name' | 'customerStatus' | 'customerClassId' | 'customerPriceClassId' | 'lat' | 'lng' | 'contactName1' | 'phone11' | 'phone12' | 'isDistributor' | 'address1' | 'attrDetailIds' | 'createdAt' | 'modifiedAt'>
        & { photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )>, customerClass: (
          { __typename?: 'CustomerClass' }
          & Pick<CustomerClass, 'id' | 'cd' | 'name'>
        ), customerDistributors: Array<(
          { __typename?: 'CustomerDistributor' }
          & Pick<CustomerDistributor, 'id'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'name' | 'cd'>
          ) }
        )>, customerPriceClass?: Maybe<(
          { __typename?: 'CustomerPriceClass' }
          & Pick<CustomerPriceClass, 'name'>
        )>, ward?: Maybe<(
          { __typename?: 'Ward' }
          & Pick<Ward, 'id' | 'name'>
          & { district: (
            { __typename?: 'District' }
            & Pick<District, 'id' | 'name'>
            & { province: (
              { __typename?: 'Province' }
              & Pick<Province, 'id' | 'name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type MasterDataDistributorsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  distributorTypeId?: Maybe<Scalars['Int']>;
}>;


export type MasterDataDistributorsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { masterDataDistributors: Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name' | 'contactName1' | 'phone11' | 'address1' | 'isActive' | 'startDate' | 'endDate' | 'email'>
      & { ward?: Maybe<(
        { __typename?: 'Ward' }
        & Pick<Ward, 'id' | 'name'>
        & { district: (
          { __typename?: 'District' }
          & Pick<District, 'id' | 'name'>
          & { province: (
            { __typename?: 'Province' }
            & Pick<Province, 'id' | 'name'>
          ) }
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name'>
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'cd' | 'name'>
      ), distributorType: (
        { __typename?: 'DistributorType' }
        & Pick<DistributorType, 'name'>
      ), warehouses: Array<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'name'>
      )>, buyers: Array<(
        { __typename?: 'Vendor' }
        & { buyer: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ) }
      )>, sellers: Array<(
        { __typename?: 'Vendor' }
        & { seller: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type MasterDataEmployeesQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  positionId?: Maybe<Scalars['Int']>;
}>;


export type MasterDataEmployeesQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { masterDataEmployees: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'cd' | 'username' | 'requiredPasswordChanged' | 'email' | 'phone11' | 'citizenIdentityCard' | 'address1' | 'isActive' | 'startDate' | 'endDate'>
      & { ward?: Maybe<(
        { __typename?: 'Ward' }
        & Pick<Ward, 'id' | 'name'>
        & { district: (
          { __typename?: 'District' }
          & Pick<District, 'id' | 'name'>
          & { province: (
            { __typename?: 'Province' }
            & Pick<Province, 'id' | 'name'>
          ) }
        ) }
      )>, supervisor?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'cd' | 'name'>
        & { employeePositions: Array<(
          { __typename?: 'EmployeePosition' }
          & { position: (
            { __typename?: 'Position' }
            & Pick<Position, 'name'>
          ) }
        )> }
      )>, employeePositions: Array<(
        { __typename?: 'EmployeePosition' }
        & { position: (
          { __typename?: 'Position' }
          & Pick<Position, 'name'>
        ) }
      )>, workingDistributors: Array<(
        { __typename?: 'WorkingDistributor' }
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ) }
      )>, workingSalesRegions: Array<(
        { __typename?: 'WorkingSalesRegion' }
        & { salesRegion: (
          { __typename?: 'SalesRegion' }
          & Pick<SalesRegion, 'cd' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type MasterDataProductsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type MasterDataProductsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { masterDataProducts: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'cd' | 'name' | 'description' | 'isPop' | 'isActive' | 'basePrice' | 'baseUnitId' | 'salesUnitId' | 'purchaseUnitId'>
      & { productMeasureUnits: Array<(
        { __typename?: 'ProductMeasureUnit' }
        & Pick<ProductMeasureUnit, 'fromUnitId' | 'rate' | 'toUnitId'>
      )>, productAttrDetails: Array<(
        { __typename?: 'ProductAttrDetail' }
        & Pick<ProductAttrDetail, 'attrId' | 'attrDetailId'>
      )> }
    )> }
  ) }
);

export type McpLineReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerFilter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  mcpId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
}>;


export type McpLineReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { mcpLineReport: (
      { __typename?: 'McpLineListResult' }
      & Pick<McpLineListResult, 'total'>
      & { data: Array<(
        { __typename?: 'McpLine' }
        & Pick<McpLine, 'id' | 'customerId' | 'addedAt' | 'weekdays' | 'weeksOfMonth'>
        & { mcp: (
          { __typename?: 'Mcp' }
          & Pick<Mcp, 'id' | 'startDate' | 'endDate' | 'cd' | 'status'>
          & { salesRegion: (
            { __typename?: 'SalesRegion' }
            & Pick<SalesRegion, 'cd' | 'name'>
          ), salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )> }
          ) }
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'address1' | 'contactName1' | 'phone11' | 'lat' | 'lng'>
          & { attrs: Array<(
            { __typename?: 'Attr' }
            & Pick<Attr, 'cd'>
            & { attrDetails: Array<(
              { __typename?: 'AttrDetail' }
              & Pick<AttrDetail, 'value'>
            )> }
          )>, attrDetails: Array<(
            { __typename?: 'AttrDetail' }
            & Pick<AttrDetail, 'value'>
          )>, ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'name'>
              ) }
            ) }
          )> }
        ), distributors: Array<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        )>, mcpDistributors: Array<(
          { __typename?: 'McpDistributor' }
          & Pick<McpDistributor, 'id'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'cd' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type PendingWarehouseProductsQueryVariables = Exact<{
  source?: Maybe<TransactionSource>;
  distributorId?: Maybe<Scalars['Int']>;
  warehouseId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type PendingWarehouseProductsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { pendingWarehouseProducts: Array<(
      { __typename?: 'WarehouseProductPending' }
      & Pick<WarehouseProductPending, 'id' | 'type' | 'qty' | 'unitId' | 'unitPrice' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'cd' | 'name'>
      ), warehouse: (
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'name'>
        ) }
      ), transaction: (
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'status' | 'source' | 'salesOrderId' | 'purchaseReceiptId'>
      ) }
    )> }
  ) }
);

export type PurchaseOrderDetailsQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  vendorId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  purchaseReceiptCd?: Maybe<Scalars['String']>;
}>;


export type PurchaseOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { purchaseOrderDetails: (
      { __typename?: 'PurchaseReceiptItemListResult' }
      & Pick<PurchaseReceiptItemListResult, 'total'>
      & { data: Array<(
        { __typename?: 'PurchaseReceiptItem' }
        & Pick<PurchaseReceiptItem, 'qty' | 'unitId' | 'unitPrice' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice'>
        & { purchaseReceipt: (
          { __typename?: 'PurchaseReceipt' }
          & Pick<PurchaseReceipt, 'cd' | 'orderDate' | 'orderType' | 'orderStatus' | 'orderTotal' | 'description' | 'externalReference'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'cd' | 'name' | 'contactName1' | 'phone11'>
          ), vendor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'cd' | 'name' | 'contactName1' | 'phone11'>
          ), employee?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          )> }
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name'>
        ), warehouse: (
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'name'>
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type PurchaseByProductsQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type PurchaseByProductsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { purchaseByProducts: Array<(
      { __typename?: 'ReportPurchaseByProduct' }
      & Pick<ReportPurchaseByProduct, 'totalQty' | 'totalUnit' | 'totalPrice'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'cd' | 'name' | 'description' | 'basePrice' | 'baseUnitId' | 'purchaseUnitId'>
      ), warehouse: (
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name' | 'description'>
      ), distributor: (
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'cd' | 'name' | 'contactName1' | 'phone11'>
      ) }
    )> }
  ) }
);

export type ReportKpiEvaluationSalesForceQueryVariables = Exact<{
  leaderId?: Maybe<Scalars['Int']>;
  employeeId?: Maybe<Scalars['Int']>;
  kpiName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  minResult?: Maybe<Scalars['Decimal']>;
  maxResult?: Maybe<Scalars['Decimal']>;
}>;


export type ReportKpiEvaluationSalesForceQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportKpiEvaluationSalesForce: (
      { __typename?: 'ReportKpiEvaluationSalesForceListResult' }
      & Pick<ReportKpiEvaluationSalesForceListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportKpiEvaluationSalesForce' }
        & Pick<ReportKpiEvaluationSalesForce, 'result'>
        & { employee?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
          & { supervisor?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'id' | 'cd' | 'name'>
          )> }
        )>, kpiConfig: (
          { __typename?: 'KpiConfig' }
          & Pick<KpiConfig, 'cd' | 'name' | 'startDate' | 'endDate'>
        ), kpiFormula: (
          { __typename?: 'KpiFormula' }
          & Pick<KpiFormula, 'cd' | 'name' | 'description'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportKpisQueryVariables = Exact<{
  employeeId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  target?: Maybe<KpiApplyTarget>;
  kpiConfigId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportKpisQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportKpis: (
      { __typename?: 'KpiConfigItemListResult' }
      & Pick<KpiConfigItemListResult, 'total'>
      & { data: Array<(
        { __typename?: 'KpiConfigItem' }
        & Pick<KpiConfigItem, 'rate' | 'actual' | 'target'>
        & { employee?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )>, kpiConfig: (
          { __typename?: 'KpiConfig' }
          & Pick<KpiConfig, 'id' | 'cd' | 'name' | 'description' | 'startDate' | 'endDate' | 'target'>
        ), kpiFormula: (
          { __typename?: 'KpiFormula' }
          & Pick<KpiFormula, 'cd' | 'name' | 'formula'>
        ), distributor?: Maybe<(
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type ReportLoyaltyEvaluationQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  tradePassed?: Maybe<Scalars['Boolean']>;
  breakBy?: Maybe<BreakBy>;
  totalPassed?: Maybe<Scalars['Boolean']>;
  visualPassed?: Maybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportLoyaltyEvaluationQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportLoyaltyEvaluation: (
      { __typename?: 'ReportLoyaltyEvaluationListResult' }
      & Pick<ReportLoyaltyEvaluationListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportLoyaltyEvaluation' }
        & Pick<ReportLoyaltyEvaluation, 'customerId' | 'tradeId' | 'level' | 'tradeResult' | 'tradeTarget' | 'visualResult' | 'tradePassed' | 'visualPassed' | 'totalPassed'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ), trade: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'cd' | 'description' | 'endDate' | 'startDate' | 'breakBy' | 'autoLevel'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportPromotionByDistributorQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportPromotionByDistributorQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportPromotionByDistributor: (
      { __typename?: 'ReportPromotionByDistributorListResult' }
      & Pick<ReportPromotionByDistributorListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPromotionByDistributor' }
        & Pick<ReportPromotionByDistributor, 'discountId' | 'distributorId' | 'total'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), discount: (
          { __typename?: 'Discount' }
          & Pick<Discount, 'cd' | 'description' | 'startDate' | 'endDate'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportSalesOrderDiscountLineQueryVariables = Exact<{
  discountId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerCd?: Maybe<Scalars['String']>;
}>;


export type ReportSalesOrderDiscountLineQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportSalesOrderDiscountLine: (
      { __typename?: 'SalesOrderDiscountLineListResult' }
      & Pick<SalesOrderDiscountLineListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrderDiscountLine' }
        & Pick<SalesOrderDiscountLine, 'rewardTotal'>
        & { salesOrderItem: (
          { __typename?: 'SalesOrderItem' }
          & Pick<SalesOrderItem, 'qty' | 'unitId'>
          & { salesOrder: (
            { __typename?: 'SalesOrder' }
            & Pick<SalesOrder, 'cd' | 'orderType' | 'sellType' | 'orderTotal'>
            & { distributor: (
              { __typename?: 'Distributor' }
              & Pick<Distributor, 'cd' | 'name'>
            ), customer: (
              { __typename?: 'Customer' }
              & Pick<Customer, 'cd' | 'name'>
            ) }
          ), product: (
            { __typename?: 'Product' }
            & Pick<Product, 'cd' | 'name'>
          ) }
        ), discount: (
          { __typename?: 'Discount' }
          & Pick<Discount, 'cd' | 'description' | 'startDate' | 'endDate'>
          & { discountProducts: Array<(
            { __typename?: 'DiscountProduct' }
            & Pick<DiscountProduct, 'rate'>
            & { product: (
              { __typename?: 'Product' }
              & Pick<Product, 'cd' | 'name'>
            ) }
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type ReportVisitPlanSummaryQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  salesmanId?: Maybe<Scalars['Int']>;
  leaderId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportVisitPlanSummaryQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportVisitPlanSummary: (
      { __typename?: 'ReportVisitPlanSummaryListResult' }
      & Pick<ReportVisitPlanSummaryListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportVisitPlanSummary' }
        & Pick<ReportVisitPlanSummary, 'mcpId' | 'date' | 'inRouteRequiredVisitCount' | 'inRouteVisitedCount' | 'outRouteVisitedCount' | 'orderCount' | 'orderTotal' | 'inRouteOrderCount' | 'inRouteOrderTotal' | 'outRouteOrderCount' | 'outRouteOrderTotal' | 'visitTimeMinutes' | 'movingTimeMinutes' | 'visitTimeAverageMinutes'>
        & { mcp: (
          { __typename?: 'Mcp' }
          & { mcpDistributors: Array<(
            { __typename?: 'McpDistributor' }
            & { distributor: (
              { __typename?: 'Distributor' }
              & Pick<Distributor, 'cd' | 'name'>
            ) }
          )>, salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'id' | 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )>, workingSalesRegions: Array<(
              { __typename?: 'WorkingSalesRegion' }
              & { salesRegion: (
                { __typename?: 'SalesRegion' }
                & Pick<SalesRegion, 'cd' | 'name'>
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type ReportVisitCustomerQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type ReportVisitCustomerQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportVisitCustomer: Array<(
      { __typename?: 'ReportVisitCustomer' }
      & Pick<ReportVisitCustomer, 'checkinId' | 'mcpId' | 'visitDate' | 'salesmanId' | 'salesmanCd' | 'salesmanName' | 'positionId' | 'positionName' | 'customerId' | 'customerCd' | 'customerStatus' | 'customerName' | 'customerContactName' | 'customerPhone' | 'customerAddress' | 'wardId' | 'wardName' | 'districtId' | 'districtName' | 'provinceId' | 'provinceName' | 'customerLat' | 'customerLng' | 'customerLinkAvatar' | 'quyMoDiemBan' | 'vungMien' | 'loaiHinhDiemBan' | 'kenhBanHang' | 'npp' | 'diemBanThuocNpp' | 'checkInAt' | 'checkInLat' | 'checkInLng' | 'checkInAccuracy' | 'distance' | 'checkOutAt' | 'checkOutLat' | 'checkOutLng' | 'checkInAtTime' | 'checkOutAtTime' | 'checkTime' | 'checkOutAccuracy' | 'checkOutReasonId' | 'checkOutReasonText' | 'movingSeconds' | 'isVisit' | 'linkPhoto_Visual1' | 'linkPhoto_Visual2' | 'checkInType'>
    )> }
  ) }
);

export type ReportVisitPlanDetailsQueryVariables = Exact<{
  date: Scalars['Date'];
  salesmanId: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportVisitPlanDetailsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportVisitPlanDetails: (
      { __typename?: 'ReportVisitPlanDetailsListResult' }
      & Pick<ReportVisitPlanDetailsListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportVisitPlanDetails' }
        & Pick<ReportVisitPlanDetails, 'mcpId' | 'isInRoute' | 'date' | 'checkInAt' | 'checkOutAt' | 'totalMinutes' | 'movingMinutes' | 'reason' | 'distance' | 'orderTotal' | 'checkInIndex'>
        & { mcp: (
          { __typename?: 'Mcp' }
          & { mcpDistributors: Array<(
            { __typename?: 'McpDistributor' }
            & { distributor: (
              { __typename?: 'Distributor' }
              & Pick<Distributor, 'cd' | 'name'>
            ) }
          )>, salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )> }
          ) }
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'lat' | 'lng'>
          & { photo?: Maybe<(
            { __typename?: 'Photo' }
            & Pick<Photo, 'id' | 'url'>
          )> }
        ), photo?: Maybe<(
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        )> }
      )> }
    ) }
  ) }
);

export type ReportWarehouseXntShowDetailsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ReportWarehouseXntShowDetailsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportWarehouseXntShowDetails: (
      { __typename?: 'ReportWarehouseXntDetailsListResult' }
      & Pick<ReportWarehouseXntDetailsListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportWarehouseXntDetails' }
        & Pick<ReportWarehouseXntDetails, 'distributorId' | 'warehouseId' | 'productId' | 'lotNbr' | 'openQty' | 'salesOutQty' | 'returnPurchaseQty' | 'purchaseQty' | 'issueReturnQty' | 'issueQty' | 'receiptQty' | 'adjustReceiptQty' | 'adjustIssueQty' | 'transferIssueQty' | 'transferReceiptQty' | 'exchangeReceiptQty' | 'exchangeIssueQty' | 'rewardQty' | 'closeQty'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name' | 'baseUnitId'>
        ), warehouse: (
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type ReportWarehouseXntDetailForDateShowListSalesQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  type: Scalars['Int'];
}>;


export type ReportWarehouseXntDetailForDateShowListSalesQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportWarehouseXntDetailForDateShowListSales: (
      { __typename?: 'ReportWarehouseXntDetailsListResult' }
      & Pick<ReportWarehouseXntDetailsListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportWarehouseXntDetails' }
        & Pick<ReportWarehouseXntDetails, 'distributorId' | 'warehouseId' | 'productId' | 'lotNbr' | 'openQty' | 'salesOutQty' | 'returnPurchaseQty' | 'purchaseQty' | 'issueReturnQty' | 'issueQty' | 'receiptQty' | 'adjustReceiptQty' | 'adjustIssueQty' | 'transferIssueQty' | 'transferReceiptQty' | 'exchangeReceiptQty' | 'exchangeIssueQty' | 'rewardQty' | 'closeQty'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name' | 'baseUnitId'>
        ), warehouse: (
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type SalesByCustomersQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type SalesByCustomersQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { salesByCustomers: (
      { __typename?: 'ReportSalesByCustomerListResult' }
      & Pick<ReportSalesByCustomerListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportSalesByCustomer' }
        & Pick<ReportSalesByCustomer, 'customerId' | 'productId' | 'totalQty' | 'totalSellQty' | 'totalKMQty' | 'totalPrice' | 'totalUnit'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11' | 'attrDetailIds'>
          & { customerClass: (
            { __typename?: 'CustomerClass' }
            & Pick<CustomerClass, 'cd' | 'name'>
          ), customerPriceClass?: Maybe<(
            { __typename?: 'CustomerPriceClass' }
            & Pick<CustomerPriceClass, 'cd' | 'name'>
          )>, ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'name'>
              ) }
            ) }
          )> }
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name' | 'description' | 'basePrice' | 'baseUnitId' | 'salesUnitId'>
          & { productAttrDetails: Array<(
            { __typename?: 'ProductAttrDetail' }
            & Pick<ProductAttrDetail, 'attrId' | 'attrDetailId'>
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type SalesByProductsQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type SalesByProductsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { salesByProducts: (
      { __typename?: 'ReportSalesByProductListResult' }
      & Pick<ReportSalesByProductListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportSalesByProduct' }
        & Pick<ReportSalesByProduct, 'warehouseId' | 'distributorId' | 'productId' | 'totalQty' | 'totalSellQty' | 'totalKMQty' | 'totalPrice' | 'totalUnit'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), warehouse: (
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'name'>
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name' | 'description' | 'salesUnitId' | 'basePrice' | 'baseUnitId'>
          & { productAttrDetails: Array<(
            { __typename?: 'ProductAttrDetail' }
            & Pick<ProductAttrDetail, 'attrId' | 'attrDetailId'>
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type SalesBySalesForceQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
}>;


export type SalesBySalesForceQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { salesBySalesForce: Array<(
      { __typename?: 'ReportSalesBySalesForce' }
      & Pick<ReportSalesBySalesForce, 'salesmanId' | 'productId' | 'totalQty' | 'totalSellQty' | 'totalKMQty' | 'totalPrice' | 'totalUnit'>
      & { salesman: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'cd' | 'name'>
        & { supervisor?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
        )>, workingSalesRegions: Array<(
          { __typename?: 'WorkingSalesRegion' }
          & { salesRegion: (
            { __typename?: 'SalesRegion' }
            & Pick<SalesRegion, 'cd' | 'name'>
          ) }
        )> }
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'cd' | 'name' | 'description' | 'basePrice' | 'baseUnitId' | 'salesUnitId'>
        & { productAttrDetails: Array<(
          { __typename?: 'ProductAttrDetail' }
          & Pick<ProductAttrDetail, 'attrId' | 'attrDetailId'>
        )> }
      ) }
    )> }
  ) }
);

export type SalesOrderDetailsQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  salesmanCd?: Maybe<Scalars['String']>;
}>;


export type SalesOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { salesOrderDetails: (
      { __typename?: 'SalesOrderItemListResult' }
      & Pick<SalesOrderItemListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrderItem' }
        & Pick<SalesOrderItem, 'id' | 'qty' | 'baseQty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseUnitId' | 'baseUnitPrice' | 'isFreeItem' | 'salesOrder'>
        & { salesOrder: (
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderType' | 'orderTotal' | 'total' | 'orderStatus' | 'description' | 'orderAt' | 'discountMoney' | 'creator' | 'modifiedBy'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'cd' | 'name' | 'contactName1' | 'phone11'>
          ), salesman?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )>, workingSalesRegions: Array<(
              { __typename?: 'WorkingSalesRegion' }
              & { salesRegion: (
                { __typename?: 'SalesRegion' }
                & Pick<SalesRegion, 'cd' | 'name'>
              ) }
            )> }
          )>, customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11' | 'address1'>
            & { attrs: Array<(
              { __typename?: 'Attr' }
              & Pick<Attr, 'cd'>
              & { attrDetails: Array<(
                { __typename?: 'AttrDetail' }
                & Pick<AttrDetail, 'value'>
              )> }
            )>, attrDetails: Array<(
              { __typename?: 'AttrDetail' }
              & Pick<AttrDetail, 'value'>
            )> }
          ) }
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name' | 'basePrice' | 'tax'>
        ), warehouse: (
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name' | 'description'>
          & { distributor: (
            { __typename?: 'Distributor' }
            & Pick<Distributor, 'id' | 'name'>
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type OrderRateByStatusQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type OrderRateByStatusQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { orderRateByStatus: (
      { __typename?: 'ReportOrderRateByStatusListResult' }
      & Pick<ReportOrderRateByStatusListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportOrderRateByStatus' }
        & Pick<ReportOrderRateByStatus, 'distributorId' | 'salesmanId' | 'totalSalesOrder' | 'totalOrderComplete' | 'totalOrderPending' | 'totalOrderNew' | 'totalOrderCancel' | 'totalOrderHolding'>
        & { distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name' | 'contactName1' | 'phone11'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
          & { supervisor?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type SurveyListReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  filter?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
}>;


export type SurveyListReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { surveyListReport: (
      { __typename?: 'ReportSurveyListListResult' }
      & Pick<ReportSurveyListListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportSurveyList' }
        & Pick<ReportSurveyList, 'surveyId' | 'surveyQuestionId'>
        & { survey: (
          { __typename?: 'Survey' }
          & Pick<Survey, 'cd' | 'title' | 'description' | 'status' | 'startDate' | 'endDate' | 'multiple'>
          & { surveyApplicableSalesRegions: Array<(
            { __typename?: 'SurveyApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name'>
            ) }
          )>, surveyApplicableCustomerAttrs: Array<(
            { __typename?: 'SurveyApplicableCustomerAttr' }
            & Pick<SurveyApplicableCustomerAttr, 'attrId' | 'attrDetailId'>
          )> }
        ), surveyQuestion?: Maybe<(
          { __typename?: 'SurveyQuestion' }
          & Pick<SurveyQuestion, 'title' | 'isFreeText'>
          & { surveyAnswers: Array<(
            { __typename?: 'SurveyAnswer' }
            & Pick<SurveyAnswer, 'title' | 'requiredOtherTextAnswer'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type FollowPromotionsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type FollowPromotionsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { followPromotions: (
      { __typename?: 'ReportPromotionFollowListResult' }
      & Pick<ReportPromotionFollowListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPromotionFollow' }
        & Pick<ReportPromotionFollow, 'salesOrderDiscountId' | 'salesOrderDiscountLevelId'>
        & { salesOrderDiscount: (
          { __typename?: 'SalesOrderDiscount' }
          & Pick<SalesOrderDiscount, 'id' | 'isManual' | 'salesOrderId' | 'discountId'>
          & { discount: (
            { __typename?: 'Discount' }
            & Pick<Discount, 'id' | 'cd' | 'description' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'highestLevel'>
            & { discountApplicableSalesRegions: Array<(
              { __typename?: 'DiscountApplicableSalesRegion' }
              & Pick<DiscountApplicableSalesRegion, 'id'>
              & { salesRegion: (
                { __typename?: 'SalesRegion' }
                & Pick<SalesRegion, 'id' | 'cd' | 'name'>
              ) }
            )>, discountProducts: Array<(
              { __typename?: 'DiscountProduct' }
              & Pick<DiscountProduct, 'id' | 'productId' | 'rate'>
              & { product: (
                { __typename?: 'Product' }
                & Pick<Product, 'id' | 'cd' | 'name'>
              ) }
            )>, discountLevels: Array<(
              { __typename?: 'DiscountLevel' }
              & Pick<DiscountLevel, 'id' | 'level' | 'description' | 'breakValue'>
            )> }
          ), salesOrder: (
            { __typename?: 'SalesOrder' }
            & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderAt' | 'orderStatus' | 'description' | 'orderType' | 'orderTotal' | 'orderTotalAfterTax' | 'discountMoney' | 'total' | 'orderSource' | 'sellType' | 'isDistance' | 'salesOrderBeforeReturnId'>
            & { customer: (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'cd' | 'customerStatus' | 'name' | 'customerClassId' | 'customerPriceClassId'>
            ), distributor: (
              { __typename?: 'Distributor' }
              & Pick<Distributor, 'id' | 'cd' | 'name'>
            ) }
          ) }
        ), salesOrderDiscountLevel: (
          { __typename?: 'SalesOrderDiscountLevel' }
          & Pick<SalesOrderDiscountLevel, 'id' | 'totalAmount' | 'percentage' | 'percentageMoney' | 'totalTurn' | 'isSelected' | 'discountLevelId'>
          & { salesOrderFreeItems: Array<(
            { __typename?: 'SalesOrderFreeItem' }
            & Pick<SalesOrderFreeItem, 'id' | 'qty' | 'productId'>
            & { product: (
              { __typename?: 'Product' }
              & Pick<Product, 'id' | 'cd' | 'name' | 'description' | 'basePrice' | 'baseUnitId' | 'salesUnitId' | 'purchaseUnitId' | 'isPop' | 'isLot' | 'isExpirationDate' | 'isActive' | 'tax'>
              & { photo?: Maybe<(
                { __typename?: 'Photo' }
                & Pick<Photo, 'id' | 'url'>
              )>, productAttrDetails: Array<(
                { __typename?: 'ProductAttrDetail' }
                & Pick<ProductAttrDetail, 'id' | 'attrId' | 'attrDetailId'>
              )>, productMeasureUnits: Array<(
                { __typename?: 'ProductMeasureUnit' }
                & Pick<ProductMeasureUnit, 'id' | 'fromUnitId' | 'saleable' | 'toUnitId' | 'rate'>
              )> }
            ) }
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type PromotionListQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  discountId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  creatorCd?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
}>;


export type PromotionListQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { promotionList: (
      { __typename?: 'ReportPromotionListListResult' }
      & Pick<ReportPromotionListListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPromotionList' }
        & Pick<ReportPromotionList, 'discountId' | 'discountLevelId' | 'discountLevelRewardId'>
        & { discount: (
          { __typename?: 'Discount' }
          & Pick<Discount, 'cd' | 'description' | 'status' | 'breakBy' | 'internalRef' | 'externalRef' | 'startDate' | 'endDate' | 'isBundle' | 'multiple' | 'highestLevel' | 'allowChangeLevel'>
          & { discountProducts: Array<(
            { __typename?: 'DiscountProduct' }
            & Pick<DiscountProduct, 'productId'>
          )>, discountApplicableCustomers: Array<(
            { __typename?: 'DiscountApplicableCustomer' }
            & { customer: (
              { __typename?: 'Customer' }
              & Pick<Customer, 'cd' | 'name'>
            ) }
          )>, discountApplicableSalesRegions: Array<(
            { __typename?: 'DiscountApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name'>
            ) }
          )>, discountApplicableCustomerAttrs: Array<(
            { __typename?: 'DiscountApplicableCustomerAttr' }
            & Pick<DiscountApplicableCustomerAttr, 'attrId' | 'attrDetailId'>
          )> }
        ), discountLevel?: Maybe<(
          { __typename?: 'DiscountLevel' }
          & Pick<DiscountLevel, 'level' | 'breakValue' | 'description'>
          & { discountLevelConditions: Array<(
            { __typename?: 'DiscountLevelCondition' }
            & Pick<DiscountLevelCondition, 'productId' | 'minQty'>
          )> }
        )>, discountLevelReward?: Maybe<(
          { __typename?: 'DiscountLevelReward' }
          & Pick<DiscountLevelReward, 'isBundle' | 'isDefault' | 'description' | 'amount' | 'percentage' | 'qty'>
          & { discountLevelRewardProducts: Array<(
            { __typename?: 'DiscountLevelRewardProduct' }
            & Pick<DiscountLevelRewardProduct, 'productId' | 'rate'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type OtherProductStockCountReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  otherProductId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  salesmanId?: Maybe<Scalars['Int']>;
  mcpId?: Maybe<Scalars['Int']>;
}>;


export type OtherProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { otherProductStockCountReport: (
      { __typename?: 'OtherProductStockCountListResult' }
      & Pick<OtherProductStockCountListResult, 'total'>
      & { data: Array<(
        { __typename?: 'OtherProductStockCount' }
        & Pick<OtherProductStockCount, 'id' | 'qty'>
        & { checkIn: (
          { __typename?: 'CheckIn' }
          & Pick<CheckIn, 'visitDate'>
          & { mcp: (
            { __typename?: 'Mcp' }
            & Pick<Mcp, 'cd'>
          ), salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )> }
          ), customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11'>
            & { ward?: Maybe<(
              { __typename?: 'Ward' }
              & Pick<Ward, 'name'>
              & { district: (
                { __typename?: 'District' }
                & Pick<District, 'name'>
                & { province: (
                  { __typename?: 'Province' }
                  & Pick<Province, 'name'>
                ) }
              ) }
            )> }
          ) }
        ), otherProduct: (
          { __typename?: 'OtherProduct' }
          & Pick<OtherProduct, 'cd' | 'name' | 'measureUnitId'>
        ) }
      )> }
    ) }
  ) }
);

export type ProductStockCountReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  productId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  salesmanId?: Maybe<Scalars['Int']>;
  mcpId?: Maybe<Scalars['Int']>;
}>;


export type ProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { productStockCountReport: (
      { __typename?: 'ProductStockCountListResult' }
      & Pick<ProductStockCountListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ProductStockCount' }
        & Pick<ProductStockCount, 'id' | 'unitId' | 'qty' | 'lotNbr' | 'startDate' | 'endDate'>
        & { checkIn: (
          { __typename?: 'CheckIn' }
          & Pick<CheckIn, 'visitDate'>
          & { mcp: (
            { __typename?: 'Mcp' }
            & Pick<Mcp, 'cd'>
          ), salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )> }
          ), customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11'>
            & { ward?: Maybe<(
              { __typename?: 'Ward' }
              & Pick<Ward, 'name'>
              & { district: (
                { __typename?: 'District' }
                & Pick<District, 'name'>
                & { province: (
                  { __typename?: 'Province' }
                  & Pick<Province, 'name'>
                ) }
              ) }
            )> }
          ) }
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type SellProductStockCountReportQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { sellProductCountReport: (
      { __typename?: 'ProductStockCountListResult' }
      & Pick<ProductStockCountListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ProductStockCount' }
        & Pick<SellProductStockCount, 'id' | 'unitId' | 'sellQty' | 'lotNbr' | 'startDate' | 'endDate'>
        & { checkIn: (
          { __typename?: 'CheckIn' }
          & Pick<CheckIn, 'visitDate'>
          & { mcp: (
            { __typename?: 'Mcp' }
            & Pick<Mcp, 'cd'>
          ), salesman: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
            & { supervisor?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'cd' | 'name'>
            )> }
          ), customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'cd' | 'name' | 'contactName1' | 'phone11'>
            & { ward?: Maybe<(
              { __typename?: 'Ward' }
              & Pick<Ward, 'name'>
              & { district: (
                { __typename?: 'District' }
                & Pick<District, 'name'>
                & { province: (
                  { __typename?: 'Province' }
                  & Pick<Province, 'name'>
                ) }
              ) }
            )> }
          ) }
        ), product: (
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type FollowVisualsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  visualId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  salesRegionId?: Maybe<Scalars['Int']>;
  take: Scalars['Int'];
}>;


export type FollowVisualsQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { followVisuals: (
      { __typename?: 'VisualPhotoListResult' }
      & Pick<VisualPhotoListResult, 'total'>
      & { data: Array<(
        { __typename?: 'VisualPhoto' }
        & Pick<VisualPhoto, 'id' | 'visualId' | 'checkInId' | 'photoId' | 'isPassed'>
        & { photo: (
          { __typename?: 'Photo' }
          & Pick<Photo, 'id' | 'url'>
        ), visual: (
          { __typename?: 'Visual' }
          & Pick<Visual, 'id' | 'cd' | 'name' | 'description' | 'status' | 'startDate' | 'endDate'>
          & { trade?: Maybe<(
            { __typename?: 'Trade' }
            & Pick<Trade, 'id' | 'cd' | 'description' | 'endDate'>
          )>, visualApplicableSalesRegions: Array<Maybe<(
            { __typename?: 'VisualApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'id' | 'cd' | 'name' | 'description'>
            ) }
          )>> }
        ), checkIn?: Maybe<(
          { __typename?: 'CheckIn' }
          & { customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id' | 'cd' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type ReportVisualListQueryVariables = Exact<{
  visualId?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  salesRegionId?: Maybe<Scalars['Int']>;
  minPass?: Maybe<Scalars['Int']>;
  maxPass?: Maybe<Scalars['Int']>;
}>;


export type ReportVisualListQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportVisualList: (
      { __typename?: 'ReportVisualListListResult' }
      & Pick<ReportVisualListListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportVisualList' }
        & Pick<ReportVisualList, 'customerId' | 'visualId' | 'countPass' | 'totalPhoto'>
        & { visual: (
          { __typename?: 'Visual' }
          & Pick<Visual, 'cd' | 'name' | 'description' | 'startDate' | 'endDate' | 'multiple' | 'minPhotos' | 'maxPhotos'>
          & { trade?: Maybe<(
            { __typename?: 'Trade' }
            & Pick<Trade, 'cd' | 'endDate'>
          )>, visualApplicableSalesRegions: Array<Maybe<(
            { __typename?: 'VisualApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name' | 'description'>
            ) }
          )>> }
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ), visualPhotos: Array<Maybe<(
          { __typename?: 'VisualPhoto' }
          & Pick<VisualPhoto, 'isPassed'>
          & { photo: (
            { __typename?: 'Photo' }
            & Pick<Photo, 'id' | 'url'>
          ) }
        )>> }
      )> }
    ) }
  ) }
);

export type BackNewSalesOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BackNewSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { backNewSalesOrder?: Maybe<(
      { __typename?: 'SalesOrderPayload' }
      & Pick<SalesOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesOrder' }
        & SalesOrderFragment
      )> }
    )> }
  )> }
);

export type CancelSalesOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { cancelSalesOrder?: Maybe<(
      { __typename?: 'SalesOrderPayload' }
      & Pick<SalesOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesOrder' }
        & SalesOrderFragment
      )> }
    )> }
  )> }
);

export type CheckInventoryOnSalesOrderQueryVariables = Exact<{
  warehouseId: Scalars['Int'];
  productId: Scalars['Int'];
  unitId: Scalars['String'];
}>;


export type CheckInventoryOnSalesOrderQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & Pick<OrderQueryEndpoint, 'checkInventoryOnSalesOrder'>
  ) }
);

export type ProcessCompleteOrderMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProcessCompleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'processCompleteOrder'>
  )> }
);

export type CompleteSalesOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { completeSalesOrder?: Maybe<(
      { __typename?: 'SalesOrderPayload' }
      & Pick<SalesOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesOrder' }
        & SalesOrderFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdateReasonEditOrderMutationVariables = Exact<{
  data: ReasonEditOrderInput;
}>;


export type CreateOrUpdateReasonEditOrderMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateReasonEditOrder?: Maybe<(
      { __typename?: 'ReasonEditOrderPayload' }
      & Pick<ReasonEditOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'ReasonEditOrder' }
        & Pick<ReasonEditOrder, 'id' | 'name' | 'description'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateSalesOrderMutationVariables = Exact<{
  data: SalesOrderInput;
}>;


export type CreateOrUpdateSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { createOrUpdateSalesOrder?: Maybe<(
      { __typename?: 'SalesOrderPayload' }
      & Pick<SalesOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesOrder' }
        & SalesOrderFragment
      )> }
    )> }
  )> }
);

export type DeleteReasonEditOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteReasonEditOrderMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteReasonEditOrder?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ReportPercentOrderCompleteQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  customerId?: Maybe<Scalars['Int']>;
  distributorId?: Maybe<Scalars['Int']>;
  salesmanId?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  minValue?: Maybe<Scalars['Decimal']>;
  maxValue?: Maybe<Scalars['Decimal']>;
}>;


export type ReportPercentOrderCompleteQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { reportPercentOrderComplete: (
      { __typename?: 'ReportPercentOrderCompleteListResult' }
      & Pick<ReportPercentOrderCompleteListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportPercentOrderComplete' }
        & Pick<ReportPercentOrderComplete, 'salesmanId' | 'customerId' | 'distributorId' | 'totalComplete' | 'totalCancel' | 'totalOrder' | 'percentComplete' | 'percentCancel'>
        & { salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
          & { supervisor?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'cd' | 'name'>
          )> }
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'cd' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type ProcessCancelOrderMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProcessCancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'processCancelOrder'>
  )> }
);

export type ProcessProcessOrderMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProcessProcessOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & Pick<OrderMutationEndpoint, 'processProcessOrder'>
  )> }
);

export type ProcessSalesOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProcessSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { processSalesOrder?: Maybe<(
      { __typename?: 'SalesOrderPayload' }
      & Pick<SalesOrderPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesOrder' }
        & SalesOrderFragment
      )> }
    )> }
  )> }
);

export type ReasonEditOrderListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type ReasonEditOrderListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { reasonEditOrderList: (
      { __typename?: 'ReasonEditOrderListResult' }
      & Pick<ReasonEditOrderListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReasonEditOrder' }
        & Pick<ReasonEditOrder, 'id' | 'name' | 'reasonType' | 'description'>
      )> }
    ) }
  ) }
);

export type ReasonEditOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReasonEditOrderQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { reasonEditOrder: (
      { __typename?: 'ReasonEditOrder' }
      & Pick<ReasonEditOrder, 'id' | 'name' | 'reasonType' | 'description'>
    ) }
  ) }
);

export type SalesOrderCompletedDateQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type SalesOrderCompletedDateQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrderCompletedDate: Array<Maybe<(
      { __typename?: 'SalesOrder' }
      & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderAt' | 'orderType' | 'orderTotal' | 'orderSource' | 'orderStatus' | 'salesOrderBeforeReturnId' | 'description'>
      & { reasonEditOrder?: Maybe<(
        { __typename?: 'ReasonEditOrder' }
        & Pick<ReasonEditOrder, 'id' | 'description'>
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name'>
      ), distributor: (
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'cd' | 'name'>
      ), salesman?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'cd' | 'name'>
      )>, salesOrderTrackLots: Array<(
        { __typename?: 'SalesOrderTrackLot' }
        & Pick<SalesOrderTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'isReturned' | 'lotNbr' | 'startDate' | 'endDate'>
        & { product?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name'>
        )>, warehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    )>> }
  ) }
);

export type SalesOrderFragment = (
  { __typename: 'SalesOrder' }
  & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderType' | 'sellType' | 'total' | 'orderSource' | 'orderTotal' | 'description' | 'deliveryAddress' | 'receiver' | 'salesOrderBeforeReturnId' | 'discountMoney' | 'percentTotal' | 'vat'>
  & { deliveryNote?: Maybe<(
    { __typename?: 'DeliveryNote' }
    & Pick<DeliveryNote, 'id' | 'status' | 'createdAt' | 'note'>
    & { shipper: (
      { __typename?: 'Shipper' }
      & Pick<Shipper, 'id' | 'cd' | 'name' | 'phone11' | 'email' | 'citizenIdentityCard' | 'photoId' | 'address1' | 'distributorId'>
    ) }
  )>, salesOrderBeforeReturn?: Maybe<(
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderType' | 'sellType' | 'orderSource' | 'orderTotal' | 'orderTotalAfterTax' | 'discountMoney' | 'description' | 'salesOrderBeforeReturnId'>
    & { deliveryNote?: Maybe<(
      { __typename?: 'DeliveryNote' }
      & Pick<DeliveryNote, 'id'>
    )>, salesOrderBeforeReturn?: Maybe<(
      { __typename?: 'SalesOrder' }
      & Pick<SalesOrder, 'id' | 'cd'>
      & { salesOrderTrackLots: Array<(
        { __typename?: 'SalesOrderTrackLot' }
        & Pick<SalesOrderTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate'>
        & { product?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
        )>, warehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    )>, reasonEditOrder?: Maybe<(
      { __typename?: 'ReasonEditOrder' }
      & Pick<ReasonEditOrder, 'id' | 'name' | 'description'>
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'cd' | 'name' | 'customerPriceClassId' | 'address1' | 'phone11' | 'fullAddress'>
      & { ward?: Maybe<(
        { __typename?: 'Ward' }
        & Pick<Ward, 'id' | 'name'>
        & { district: (
          { __typename?: 'District' }
          & Pick<District, 'id' | 'name'>
          & { province: (
            { __typename?: 'Province' }
            & Pick<Province, 'id' | 'name'>
          ) }
        ) }
      )> }
    ), distributor: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name' | 'phone11' | 'fullAddress'>
    ), salesman?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'cd' | 'name'>
    )>, enjoyDiscounts?: Maybe<Array<Maybe<(
      { __typename?: 'EnjoyDiscount' }
      & Pick<EnjoyDiscount, 'id' | 'isManual' | 'discountId'>
      & { discount: (
        { __typename?: 'Discount' }
        & Pick<Discount, 'description'>
      ), discountLevels: Array<(
        { __typename?: 'EnjoyDiscountLevel' }
        & Pick<EnjoyDiscountLevel, 'id' | 'rewardId' | 'turnCount' | 'percentage' | 'amount' | 'discountLevelId'>
        & { productSelected: Array<(
          { __typename?: 'EnjoyProduct' }
          & Pick<EnjoyProduct, 'id' | 'qty' | 'productId'>
        )> }
      )> }
    )>>>, orderItems: Array<(
      { __typename?: 'SalesOrderItem' }
      & Pick<SalesOrderItem, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'totalPrice' | 'totalPriceAfterTax' | 'isFreeItem' | 'salesOrder'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
      ), warehouse: (
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
      ) }
    )>, salesOrderTrackLots: Array<(
      { __typename?: 'SalesOrderTrackLot' }
      & Pick<SalesOrderTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
      )>, warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
      )> }
    )> }
  )>, reasonEditOrder?: Maybe<(
    { __typename?: 'ReasonEditOrder' }
    & Pick<ReasonEditOrder, 'id' | 'name' | 'description'>
  )>, customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'cd' | 'name' | 'customerPriceClassId' | 'address1' | 'phone11' | 'fullAddress'>
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
      & { district: (
        { __typename?: 'District' }
        & Pick<District, 'id' | 'name'>
        & { province: (
          { __typename?: 'Province' }
          & Pick<Province, 'id' | 'name'>
        ) }
      ) }
    )> }
  ), creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )>, distributor: (
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'cd' | 'name' | 'phone11' | 'fullAddress'>
  ), salesman?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'cd' | 'name'>
  )>, enjoyDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'EnjoyDiscount' }
    & Pick<EnjoyDiscount, 'id' | 'isManual' | 'discountId'>
    & { discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'description'>
    ), discountLevels: Array<(
      { __typename?: 'EnjoyDiscountLevel' }
      & Pick<EnjoyDiscountLevel, 'id' | 'rewardId' | 'turnCount' | 'percentage' | 'amount' | 'discountLevelId'>
      & { productSelected: Array<(
        { __typename?: 'EnjoyProduct' }
        & Pick<EnjoyProduct, 'id' | 'qty' | 'productId'>
      )> }
    )> }
  )>>>, salesOrderDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderDiscount' }
    & Pick<SalesOrderDiscount, 'id' | 'isManual' | 'salesOrderId' | 'discountId'>
    & { discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'cd' | 'description' | 'status' | 'startDate' | 'endDate' | 'isBundle' | 'breakBy' | 'multiple' | 'sortOrder' | 'highestLevel' | 'buyGive' | 'excluded' | 'allowChangeLevel' | 'isManual' | 'firstOrder' | 'internalRef' | 'externalRef'>
      & { discountLevels: Array<(
        { __typename?: 'DiscountLevel' }
        & Pick<DiscountLevel, 'id' | 'level' | 'description' | 'breakValue'>
        & { discountLevelRewards: Array<(
          { __typename?: 'DiscountLevelReward' }
          & Pick<DiscountLevelReward, 'id' | 'description' | 'isDefault' | 'isBundle' | 'amount' | 'percentage' | 'percentageMoney' | 'qty'>
          & { discountLevelRewardProducts: Array<(
            { __typename?: 'DiscountLevelRewardProduct' }
            & Pick<DiscountLevelRewardProduct, 'id' | 'productId' | 'rate'>
          )> }
        )>, discountLevelConditions: Array<(
          { __typename?: 'DiscountLevelCondition' }
          & Pick<DiscountLevelCondition, 'id' | 'productId' | 'minQty'>
        )> }
      )> }
    ), salesOrderDiscountLevels: Array<(
      { __typename?: 'SalesOrderDiscountLevel' }
      & Pick<SalesOrderDiscountLevel, 'id' | 'totalAmount' | 'percentage' | 'percentageMoney' | 'totalTurn' | 'discountLevelId' | 'isSelected'>
      & { salesOrderFreeItems: Array<(
        { __typename?: 'SalesOrderFreeItem' }
        & Pick<SalesOrderFreeItem, 'id' | 'qty' | 'productId'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name'>
        ) }
      )> }
    )> }
  )>>>, orderItems: Array<(
    { __typename?: 'SalesOrderItem' }
    & Pick<SalesOrderItem, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'totalPrice' | 'totalPriceAfterTax' | 'isFreeItem'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
    ), warehouse: (
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    ), discount?: Maybe<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'cd'>
    )> }
  )>, salesOrderTrackLots: Array<(
    { __typename?: 'SalesOrderTrackLot' }
    & Pick<SalesOrderTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate' | 'warehouseId' | 'productId' | 'discountId'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'cd' | 'id' | 'name' | 'tax'>
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    )> }
  )> }
);

export type SalesOrderListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  orderType?: Maybe<OrderType>;
  sellType?: Maybe<SellType>;
  salesOrderCd?: Maybe<Scalars['String']>;
  salesmanCd?: Maybe<Scalars['String']>;
}>;


export type SalesOrderListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrderList: (
      { __typename?: 'SalesOrderListResult' }
      & Pick<SalesOrderListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrder' }
        & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderType' | 'sellType' | 'total' | 'discountMoney' | 'orderStatus' | 'orderSource' | 'orderTotal' | 'description'>
        & { creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'cd' | 'name'>
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name' | 'address1'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )> }
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type SalesOrderProcessListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  action: ActionType;
  distributorId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
}>;


export type SalesOrderProcessListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrderProcessList: (
      { __typename?: 'SalesOrderListResult' }
      & Pick<SalesOrderListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrder' }
        & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderType' | 'total' | 'discountMoney' | 'orderStatus' | 'orderSource' | 'orderTotal' | 'description'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name' | 'address1'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )> }
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), salesman?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'cd' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type SalesOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SalesOrderQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrder: (
      { __typename?: 'SalesOrder' }
      & SalesOrderFragment
    ) }
  ) }
);

export type SalesOrderReturnListQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  distributorCd?: Maybe<Scalars['String']>;
  customerCd?: Maybe<Scalars['String']>;
  salesOrderReturnCd?: Maybe<Scalars['String']>;
}>;


export type SalesOrderReturnListQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { salesOrderReturnList: (
      { __typename?: 'SalesOrderListResult' }
      & Pick<SalesOrderListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesOrder' }
        & Pick<SalesOrder, 'id' | 'cd' | 'orderDate' | 'orderStatus' | 'orderSource' | 'orderTotal' | 'description'>
        & { salesOrderBeforeReturn?: Maybe<(
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'cd'>
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name' | 'address1'>
          & { ward?: Maybe<(
            { __typename?: 'Ward' }
            & Pick<Ward, 'id' | 'name'>
            & { district: (
              { __typename?: 'District' }
              & Pick<District, 'id' | 'name'>
              & { province: (
                { __typename?: 'Province' }
                & Pick<Province, 'id' | 'name'>
              ) }
            ) }
          )> }
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type SalesOrderTrackLotMutationVariables = Exact<{
  data: Array<SalesOrderItemInput> | SalesOrderItemInput;
}>;


export type SalesOrderTrackLotMutation = (
  { __typename?: 'Mutation' }
  & { o?: Maybe<(
    { __typename?: 'OrderMutationEndpoint' }
    & { salesOrderTrackLot: Array<(
      { __typename?: 'SalesOrderTrackLot' }
      & Pick<SalesOrderTrackLot, 'id' | 'qty' | 'unitId' | 'unitPrice' | 'unitPriceAfterTax' | 'baseQty' | 'baseUnitId' | 'baseUnitPrice' | 'lotNbr' | 'startDate' | 'endDate' | 'warehouseId' | 'productId'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
      )>, product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'cd' | 'name' | 'tax'>
      )> }
    )> }
  )> }
);

export type CheckLotNbrDistributorQueryVariables = Exact<{
  distributorId: Scalars['Int'];
  productId: Scalars['Int'];
  expirationDate?: Maybe<Scalars['Date']>;
}>;


export type CheckLotNbrDistributorQuery = (
  { __typename?: 'Query' }
  & { o: (
    { __typename?: 'OrderQueryEndpoint' }
    & { checkLotNbrDistributor: Array<(
      { __typename?: 'SfaInventoryCustomerLot' }
      & Pick<SfaInventoryCustomerLot, 'lotNbr' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type TradeRewardProductTrackLotMutationVariables = Exact<{
  data: Array<SalesOrderItemInput> | SalesOrderItemInput;
}>;


export type TradeRewardProductTrackLotMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { tradeRewardProductTrackLot: Array<(
      { __typename?: 'TradeRewardProduct' }
      & Pick<TradeRewardProduct, 'id' | 'qty' | 'unitId' | 'lotNbr' | 'startDate' | 'endDate' | 'warehouseId' | 'productId'>
    )> }
  )> }
);

export type WarehouseProductTrackLotsQueryVariables = Exact<{
  data: Array<TrackLotWarehouseInput> | TrackLotWarehouseInput;
}>;


export type WarehouseProductTrackLotsQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { warehouseProductTrackLots: Array<(
      { __typename?: 'WarehouseProduct' }
      & Pick<WarehouseProduct, 'availableQty' | 'baseUnitId' | 'lotNbr' | 'startDate' | 'endDate'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'cd' | 'name'>
      ), warehouse: (
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateOrUpdateListSalesPriceMutationVariables = Exact<{
  data: Array<SalesPriceInput> | SalesPriceInput;
}>;


export type CreateOrUpdateListSalesPriceMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateListSalesPrice?: Maybe<(
      { __typename?: 'BoolPayload' }
      & Pick<BoolPayload, 'content' | 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'message'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateSalesPriceMutationVariables = Exact<{
  data: SalesPriceInput;
}>;


export type CreateOrUpdateSalesPriceMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateSalesPrice?: Maybe<(
      { __typename?: 'SalesPricePayload' }
      & Pick<SalesPricePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesPrice' }
        & SalesPriceFragment
      )> }
    )> }
  )> }
);

export type DeleteSalesPriceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSalesPriceMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteSalesPrice?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type SalesPriceFragment = (
  { __typename: 'SalesPrice' }
  & Pick<SalesPrice, 'id' | 'productId' | 'customerPriceClassId' | 'price' | 'unitId' | 'startDate' | 'endDate' | 'description'>
);

export type SalesPriceListByCustomerQueryVariables = Exact<{
  priceClassId?: Maybe<Scalars['Int']>;
}>;


export type SalesPriceListByCustomerQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { salesPriceListByCustomer: (
      { __typename?: 'SalesPriceListResult' }
      & Pick<SalesPriceListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesPrice' }
        & SalesPriceFragment
      )> }
    ) }
  ) }
);

export type SalesPriceListByDistributorQueryVariables = Exact<{
  distributorId?: Maybe<Scalars['Int']>;
}>;


export type SalesPriceListByDistributorQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { salesPriceListByDistributor: (
      { __typename?: 'SalesPriceListResult' }
      & Pick<SalesPriceListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesPrice' }
        & SalesPriceFragment
      )> }
    ) }
  ) }
);

export type SalesPriceListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  priceClassId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['String']>;
}>;


export type SalesPriceListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { salesPriceList: (
      { __typename?: 'SalesPriceListResult' }
      & Pick<SalesPriceListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesPrice' }
        & SalesPriceFragment
      )> }
    ) }
  ) }
);

export type SalesPriceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SalesPriceQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { salesPrice: (
      { __typename?: 'SalesPrice' }
      & SalesPriceFragment
    ) }
  ) }
);

export type CreateOrUpdateSalesRegionMutationVariables = Exact<{
  data: SalesRegionInput;
}>;


export type CreateOrUpdateSalesRegionMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { createOrUpdateSalesRegion?: Maybe<(
      { __typename?: 'SalesRegionPayload' }
      & Pick<SalesRegionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'SalesRegion' }
        & SalesRegionFragment
      )> }
    )> }
  )> }
);

export type DeleteSalesRegionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSalesRegionMutation = (
  { __typename?: 'Mutation' }
  & { mcp?: Maybe<(
    { __typename?: 'McpMutationEndpoint' }
    & { deleteSalesRegion?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type SalesRegionFragment = (
  { __typename: 'SalesRegion' }
  & Pick<SalesRegion, 'id' | 'cd' | 'name' | 'workGroupId' | 'description'>
  & { salesRegionAreas: Array<(
    { __typename?: 'SalesRegionArea' }
    & Pick<SalesRegionArea, 'id'>
    & { province: (
      { __typename?: 'Province' }
      & Pick<Province, 'id' | 'name'>
    ), district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
    )> }
  )> }
);

export type SalesRegionListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type SalesRegionListQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { salesRegionList: (
      { __typename?: 'SalesRegionListResult' }
      & Pick<SalesRegionListResult, 'total'>
      & { data: Array<(
        { __typename?: 'SalesRegion' }
        & Pick<SalesRegion, 'id' | 'cd' | 'name' | 'workGroupId' | 'description'>
      )> }
    ) }
  ) }
);

export type SalesRegionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SalesRegionQuery = (
  { __typename?: 'Query' }
  & { mcp: (
    { __typename?: 'McpQueryEndpoint' }
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & SalesRegionFragment
    ) }
  ) }
);

export type CloseSurveyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseSurveyMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeSurvey?: Maybe<(
      { __typename?: 'SurveyPayload' }
      & Pick<SurveyPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Survey' }
        & Pick<Survey, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateSurveyMutationVariables = Exact<{
  data: SurveyInput;
}>;


export type CreateOrUpdateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateSurvey?: Maybe<(
      { __typename?: 'SurveyPayload' }
      & Pick<SurveyPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Survey' }
        & SurveyFragment
      )> }
    )> }
  )> }
);

export type DeleteSurveyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSurveyMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteSurvey?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ReleaseSurveyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseSurveyMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseSurvey?: Maybe<(
      { __typename?: 'SurveyPayload' }
      & Pick<SurveyPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Survey' }
        & Pick<Survey, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type SurveyFragment = (
  { __typename: 'Survey' }
  & Pick<Survey, 'id' | 'cd' | 'status' | 'title' | 'description' | 'startDate' | 'endDate' | 'multiple'>
  & { surveyQuestions: Array<(
    { __typename?: 'SurveyQuestion' }
    & Pick<SurveyQuestion, 'id' | 'title' | 'isFreeText'>
    & { surveyAnswers: Array<(
      { __typename?: 'SurveyAnswer' }
      & Pick<SurveyAnswer, 'id' | 'title' | 'requiredOtherTextAnswer'>
    )> }
  )>, surveyApplicableCustomerAttrs: Array<(
    { __typename?: 'SurveyApplicableCustomerAttr' }
    & Pick<SurveyApplicableCustomerAttr, 'id' | 'attrId' | 'attrDetailId'>
  )>, surveyApplicableSalesRegions: Array<(
    { __typename?: 'SurveyApplicableSalesRegion' }
    & Pick<SurveyApplicableSalesRegion, 'id'>
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'id' | 'name'>
    ) }
  )> }
);

export type SurveyListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  description?: Maybe<Scalars['String']>;
  surveyCd?: Maybe<Scalars['String']>;
}>;


export type SurveyListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { surveyList: (
      { __typename?: 'SurveyListResult' }
      & Pick<SurveyListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Survey' }
        & Pick<Survey, 'id' | 'cd' | 'status' | 'title' | 'description' | 'startDate' | 'endDate'>
      )> }
    ) }
  ) }
);

export type SurveyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SurveyQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { survey: (
      { __typename?: 'Survey' }
      & SurveyFragment
    ) }
  ) }
);

export type AcceptTradeRewardMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AcceptTradeRewardMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { acceptTradeReward?: Maybe<(
      { __typename?: 'TradeRewardPayload' }
      & Pick<TradeRewardPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'TradeReward' }
        & Pick<TradeReward, 'id' | 'description' | 'customerId' | 'distributorId' | 'tradeParticipationId'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateTradeRewardMutationVariables = Exact<{
  data: TradeRewardInput;
}>;


export type CreateOrUpdateTradeRewardMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateTradeReward?: Maybe<(
      { __typename?: 'TradeRewardPayload' }
      & Pick<TradeRewardPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'TradeReward' }
        & Pick<TradeReward, 'id' | 'description' | 'customerId' | 'distributorId' | 'tradeParticipationId'>
      )> }
    )> }
  )> }
);

export type RejectTradeRewardMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RejectTradeRewardMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { rejectTradeReward?: Maybe<(
      { __typename?: 'TradeRewardPayload' }
      & Pick<TradeRewardPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'TradeReward' }
        & Pick<TradeReward, 'id' | 'description' | 'customerId' | 'distributorId' | 'tradeParticipationId'>
      )> }
    )> }
  )> }
);

export type TradesAchievedQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  customerId: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type TradesAchievedQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { tradesAchieved: (
      { __typename?: 'TradeParticipationListResult' }
      & Pick<TradeParticipationListResult, 'total'>
      & { data: Array<(
        { __typename?: 'TradeParticipation' }
        & Pick<TradeParticipation, 'id' | 'tradeId' | 'customerId' | 'status' | 'level' | 'approvedAt' | 'createdAt' | 'lastCalculatedDate' | 'result' | 'incentiveResult'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'name'>
        ), trade: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'id' | 'cd' | 'description' | 'status' | 'breakBy' | 'multiple' | 'autoLevel' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate'>
          & { tradeProducts: Array<(
            { __typename?: 'TradeProduct' }
            & Pick<TradeProduct, 'id' | 'productId' | 'rate'>
          )>, tradeLevels: Array<(
            { __typename?: 'TradeLevel' }
            & Pick<TradeLevel, 'id' | 'level' | 'description' | 'breakValue' | 'amount' | 'percentage' | 'qty' | 'isBundle'>
            & { tradeLevelProducts: Array<(
              { __typename?: 'TradeLevelProduct' }
              & Pick<TradeLevelProduct, 'id' | 'productId' | 'rate'>
            )> }
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type TradeRewardQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TradeRewardQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { tradeReward: (
      { __typename?: 'TradeReward' }
      & Pick<TradeReward, 'id' | 'description' | 'status'>
      & { customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'cd' | 'name'>
      ), distributor: (
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'name'>
      ), tradeParticipation: (
        { __typename?: 'TradeParticipation' }
        & Pick<TradeParticipation, 'id'>
        & { trade: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'description'>
        ) }
      ), tradeRewardProducts: Array<(
        { __typename?: 'TradeRewardProduct' }
        & Pick<TradeRewardProduct, 'id' | 'productId' | 'rate' | 'qty' | 'warehouseId' | 'lotNbr' | 'startDate' | 'endDate'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'name' | 'basePrice' | 'baseUnitId' | 'salesUnitId'>
        ) }
      )> }
    ) }
  ) }
);

export type TradesRewardQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type TradesRewardQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { tradesReward: (
      { __typename?: 'TradeRewardListResult' }
      & Pick<TradeRewardListResult, 'total'>
      & { data: Array<(
        { __typename?: 'TradeReward' }
        & Pick<TradeReward, 'id' | 'description' | 'status' | 'note'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'cd' | 'name'>
        ), tradeParticipation: (
          { __typename?: 'TradeParticipation' }
          & Pick<TradeParticipation, 'id'>
          & { trade: (
            { __typename?: 'Trade' }
            & Pick<Trade, 'description'>
          ) }
        ), distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type CloseTradeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseTradeMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeTrade?: Maybe<(
      { __typename?: 'TradePayload' }
      & Pick<TradePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Trade' }
        & Pick<Trade, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateTradeMutationVariables = Exact<{
  data: TradeInput;
}>;


export type CreateOrUpdateTradeMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateTrade?: Maybe<(
      { __typename?: 'TradePayload' }
      & Pick<TradePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Trade' }
        & TradeFragment
      )> }
    )> }
  )> }
);

export type DeleteTradeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTradeMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteTrade?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type LoyaltyListQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tradeId?: Maybe<Scalars['Int']>;
  salesRegionId?: Maybe<Scalars['Int']>;
  status?: Maybe<ProgramStatus>;
  target?: Maybe<TradeApplyTarget>;
  filter?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type LoyaltyListQuery = (
  { __typename?: 'Query' }
  & { rp: (
    { __typename?: 'ReportQueryEndpoint' }
    & { loyaltyList: (
      { __typename?: 'ReportLoyaltyListListResult' }
      & Pick<ReportLoyaltyListListResult, 'total'>
      & { data: Array<(
        { __typename?: 'ReportLoyaltyList' }
        & Pick<ReportLoyaltyList, 'tradeId'>
        & { trade: (
          { __typename?: 'Trade' }
          & Pick<Trade, 'cd' | 'description' | 'status' | 'breakBy' | 'tradeApplyTarget' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate' | 'multiple' | 'autoLevel'>
          & { tradeProducts: Array<(
            { __typename?: 'TradeProduct' }
            & Pick<TradeProduct, 'productId' | 'rate'>
            & { product: (
              { __typename?: 'Product' }
              & Pick<Product, 'name'>
            ) }
          )>, tradeApplicableCustomers: Array<(
            { __typename?: 'TradeApplicableCustomer' }
            & { customer: (
              { __typename?: 'Customer' }
              & Pick<Customer, 'cd' | 'name'>
            ) }
          )>, tradeApplicableCustomerAttrs: Array<(
            { __typename?: 'TradeApplicableCustomerAttr' }
            & Pick<TradeApplicableCustomerAttr, 'attrId' | 'attrDetailId'>
          )>, tradeApplicableSalesRegions: Array<(
            { __typename?: 'TradeApplicableSalesRegion' }
            & { salesRegion: (
              { __typename?: 'SalesRegion' }
              & Pick<SalesRegion, 'cd' | 'name'>
            ) }
          )> }
        ), tradeLevel?: Maybe<(
          { __typename?: 'TradeLevel' }
          & Pick<TradeLevel, 'level' | 'isBundle' | 'description' | 'breakValue' | 'amount' | 'percentage' | 'qty'>
        )>, tradeLevelProduct?: Maybe<(
          { __typename?: 'TradeLevelProduct' }
          & Pick<TradeLevelProduct, 'productId' | 'rate'>
          & { product: (
            { __typename?: 'Product' }
            & Pick<Product, 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type ReleaseTradeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseTradeMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseTrade?: Maybe<(
      { __typename?: 'TradePayload' }
      & Pick<TradePayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Trade' }
        & Pick<Trade, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type TradeFragment = (
  { __typename: 'Trade' }
  & Pick<Trade, 'id' | 'cd' | 'description' | 'status' | 'breakBy' | 'multiple' | 'autoLevel' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate' | 'tradeApplyTarget'>
  & { tradeProducts: Array<(
    { __typename?: 'TradeProduct' }
    & Pick<TradeProduct, 'id' | 'productId' | 'rate'>
  )>, tradeProductGroups: Array<(
    { __typename?: 'TradeProductGroup' }
    & Pick<TradeProductGroup, 'id' | 'productGroupId'>
  )>, tradeLevels: Array<(
    { __typename?: 'TradeLevel' }
    & Pick<TradeLevel, 'id' | 'level' | 'description' | 'breakValue' | 'amount' | 'percentage' | 'qty' | 'isBundle'>
    & { tradeLevelProducts: Array<(
      { __typename?: 'TradeLevelProduct' }
      & Pick<TradeLevelProduct, 'id' | 'productId' | 'rate'>
    )> }
  )>, tradeApplicableCustomers: Array<(
    { __typename?: 'TradeApplicableCustomer' }
    & Pick<TradeApplicableCustomer, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'cd' | 'name'>
    ) }
  )>, tradeApplicableDistributors: Array<(
    { __typename?: 'TradeApplicableDistributor' }
    & Pick<TradeApplicableDistributor, 'id'>
    & { distributor: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'cd' | 'name'>
    ) }
  )>, tradeApplicableCustomerAttrs: Array<(
    { __typename?: 'TradeApplicableCustomerAttr' }
    & Pick<TradeApplicableCustomerAttr, 'id' | 'attrId' | 'attrDetailId'>
  )>, tradeApplicableSalesRegions: Array<(
    { __typename?: 'TradeApplicableSalesRegion' }
    & Pick<TradeApplicableSalesRegion, 'id'>
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'id' | 'name'>
    ) }
  )> }
);

export type TradeListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  tradeCd?: Maybe<Scalars['String']>;
  salesRegionId?: Maybe<Scalars['Int']>;
}>;


export type TradeListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { tradeList: (
      { __typename?: 'TradeListResult' }
      & Pick<TradeListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Trade' }
        & Pick<Trade, 'id' | 'cd' | 'description' | 'status' | 'breakBy' | 'multiple' | 'autoLevel' | 'registerStartDate' | 'registerEndDate' | 'startDate' | 'endDate'>
      )> }
    ) }
  ) }
);

export type TradeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TradeQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { trade: (
      { __typename?: 'Trade' }
      & TradeFragment
    ) }
  ) }
);

export type ActionCancelTransactionMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
  source: TransactionSource;
}>;


export type ActionCancelTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & Pick<InventoryMutationEndpoint, 'actionCancelTransaction'>
  )> }
);

export type ActionCompleteTransactionMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
  source: TransactionSource;
}>;


export type ActionCompleteTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & Pick<InventoryMutationEndpoint, 'actionCompleteTransaction'>
  )> }
);

export type ActionProcessTransactionMutationVariables = Exact<{
  listIds: Array<Scalars['Int']> | Scalars['Int'];
  source: TransactionSource;
}>;


export type ActionProcessTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & Pick<InventoryMutationEndpoint, 'actionProcessTransaction'>
  )> }
);

export type CancelTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
  source: TransactionSource;
}>;


export type CancelTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & { cancelTransaction?: Maybe<(
      { __typename?: 'TransactionPayload' }
      & Pick<TransactionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Transaction' }
        & TransactionFragment
      )> }
    )> }
  )> }
);

export type CompleteTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
  source: TransactionSource;
}>;


export type CompleteTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & { completeTransaction?: Maybe<(
      { __typename?: 'TransactionPayload' }
      & Pick<TransactionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Transaction' }
        & TransactionFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdateTransactionMutationVariables = Exact<{
  data: TransactionInput;
}>;


export type CreateOrUpdateTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & { createOrUpdateTransaction?: Maybe<(
      { __typename?: 'TransactionPayload' }
      & Pick<TransactionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Transaction' }
        & TransactionFragment
      )> }
    )> }
  )> }
);

export type FilterAllLotNumberQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
}>;


export type FilterAllLotNumberQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { filterAllLotNumber: (
      { __typename?: 'WarehouseProductListResult' }
      & Pick<WarehouseProductListResult, 'total'>
      & { data: Array<(
        { __typename?: 'WarehouseProduct' }
        & Pick<WarehouseProduct, 'id' | 'lotNbr' | 'startDate' | 'endDate' | 'availableQty'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type FilterLotNumberQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: Scalars['String'];
  productId: Scalars['Int'];
  warehouseId: Scalars['Int'];
}>;


export type FilterLotNumberQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { filterLotNumber: (
      { __typename?: 'WarehouseProductListResult' }
      & Pick<WarehouseProductListResult, 'total'>
      & { data: Array<(
        { __typename?: 'WarehouseProduct' }
        & Pick<WarehouseProduct, 'id' | 'lotNbr' | 'startDate' | 'endDate' | 'availableQty'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'cd' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type ProcessTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
  source: TransactionSource;
}>;


export type ProcessTransactionMutation = (
  { __typename?: 'Mutation' }
  & { in?: Maybe<(
    { __typename?: 'InventoryMutationEndpoint' }
    & { processTransaction?: Maybe<(
      { __typename?: 'TransactionPayload' }
      & Pick<TransactionPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Transaction' }
        & TransactionFragment
      )> }
    )> }
  )> }
);

export type PurchaseReceiptTransactionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PurchaseReceiptTransactionQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { purchaseReceiptTransaction: (
      { __typename?: 'TransactionListResult' }
      & Pick<TransactionListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'cd' | 'description' | 'transactionDate' | 'source' | 'status' | 'salesOrderId' | 'purchaseReceiptId'>
        & { salesOrder?: Maybe<(
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'cd'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )>, fromWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )>, toWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type SaleOrderTransactionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SaleOrderTransactionQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { saleOrderTransaction: (
      { __typename?: 'TransactionListResult' }
      & Pick<TransactionListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'cd' | 'description' | 'transactionDate' | 'source' | 'status' | 'salesOrderId' | 'purchaseReceiptId'>
        & { salesOrder?: Maybe<(
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'cd'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )>, fromWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )>, toWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type TransactionFragment = (
  { __typename: 'Transaction' }
  & Pick<Transaction, 'id' | 'source' | 'status' | 'description' | 'transactionDate' | 'salesOrderId' | 'purchaseReceiptId' | 'note'>
  & { reason?: Maybe<(
    { __typename?: 'ReasonEditOrder' }
    & Pick<ReasonEditOrder, 'id' | 'name' | 'description'>
  )>, distributor: (
    { __typename?: 'Distributor' }
    & Pick<Distributor, 'id' | 'name'>
  ), creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, fromWarehouse?: Maybe<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'name'>
  )>, toWarehouse?: Maybe<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'name'>
  )>, transactionItems: Array<(
    { __typename?: 'TransactionItem' }
    & Pick<TransactionItem, 'id' | 'type' | 'unitId' | 'unitPrice' | 'qty' | 'baseUnitId' | 'baseUnitPrice' | 'baseQty' | 'lotNbr' | 'startDate' | 'endDate' | 'exchangeLotNbr' | 'exchangeStartDate' | 'exchangeEndDate'>
    & { warehouse: (
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name'>
    ), product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    ) }
  )> }
);

export type TransactionListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorCd?: Maybe<Scalars['String']>;
  source?: Maybe<TransactionSource>;
  status?: Maybe<TransactionStatus>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
}>;


export type TransactionListQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { transactionList: (
      { __typename?: 'TransactionListResult' }
      & Pick<TransactionListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'cd' | 'description' | 'transactionDate' | 'source' | 'status' | 'salesOrderId' | 'purchaseReceiptId'>
        & { reason?: Maybe<(
          { __typename?: 'ReasonEditOrder' }
          & Pick<ReasonEditOrder, 'id' | 'name' | 'description'>
        )>, salesOrder?: Maybe<(
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'cd'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )>, fromWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )>, toWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type TransactionProcessListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  distributorCd?: Maybe<Scalars['String']>;
  source: TransactionSource;
  action: ActionType;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creatorCd?: Maybe<Scalars['String']>;
  productCd?: Maybe<Scalars['String']>;
  transactionCd?: Maybe<Scalars['String']>;
}>;


export type TransactionProcessListQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { transactionProcessList: (
      { __typename?: 'TransactionListResult' }
      & Pick<TransactionListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'cd' | 'description' | 'transactionDate' | 'source' | 'status' | 'salesOrderId' | 'purchaseReceiptId'>
        & { salesOrder?: Maybe<(
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'cd'>
        )>, distributor: (
          { __typename?: 'Distributor' }
          & Pick<Distributor, 'id' | 'cd' | 'name'>
        ), creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )>, fromWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )>, toWarehouse?: Maybe<(
          { __typename?: 'Warehouse' }
          & Pick<Warehouse, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type TransactionQueryVariables = Exact<{
  id: Scalars['Int'];
  source?: Maybe<TransactionSource>;
}>;


export type TransactionQuery = (
  { __typename?: 'Query' }
  & { in: (
    { __typename?: 'InventoryQueryEndpoint' }
    & { transaction: (
      { __typename?: 'Transaction' }
      & TransactionFragment
    ) }
  ) }
);

export type CloseVisualMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseVisualMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { closeVisual?: Maybe<(
      { __typename?: 'VisualPayload' }
      & Pick<VisualPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Visual' }
        & Pick<Visual, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type CreateOrUpdateVisualMutationVariables = Exact<{
  data: VisualInput;
}>;


export type CreateOrUpdateVisualMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { createOrUpdateVisual?: Maybe<(
      { __typename?: 'VisualPayload' }
      & Pick<VisualPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Visual' }
        & VisualFragment
      )> }
    )> }
  )> }
);

export type DeleteVisualMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVisualMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { deleteVisual?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type ReleaseVisualMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReleaseVisualMutation = (
  { __typename?: 'Mutation' }
  & { p?: Maybe<(
    { __typename?: 'ProgramMutationEndpoint' }
    & { releaseVisual?: Maybe<(
      { __typename?: 'VisualPayload' }
      & Pick<VisualPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'Visual' }
        & Pick<Visual, 'id' | 'status'>
      )> }
    )> }
  )> }
);

export type VisualFragment = (
  { __typename: 'Visual' }
  & Pick<Visual, 'id' | 'cd' | 'status' | 'name' | 'description' | 'startDate' | 'endDate' | 'voteRequired' | 'minPhotos' | 'maxPhotos'>
  & { photo?: Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'id' | 'url'>
  )>, trade?: Maybe<(
    { __typename?: 'Trade' }
    & Pick<Trade, 'id' | 'description' | 'startDate' | 'endDate'>
  )>, visualApplicableSalesRegions: Array<Maybe<(
    { __typename?: 'VisualApplicableSalesRegion' }
    & Pick<VisualApplicableSalesRegion, 'id'>
    & { salesRegion: (
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'id' | 'name'>
    ) }
  )>> }
);

export type VisualListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
  status?: Maybe<ProgramStatus>;
  visualCd?: Maybe<Scalars['String']>;
}>;


export type VisualListQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { visualList: (
      { __typename?: 'VisualListResult' }
      & Pick<VisualListResult, 'total'>
      & { data: Array<(
        { __typename?: 'Visual' }
        & Pick<Visual, 'id' | 'cd' | 'status' | 'name' | 'description' | 'startDate' | 'endDate'>
      )> }
    ) }
  ) }
);

export type VisualQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type VisualQuery = (
  { __typename?: 'Query' }
  & { p: (
    { __typename?: 'ProgramQueryEndpoint' }
    & { visual: (
      { __typename?: 'Visual' }
      & VisualFragment
    ) }
  ) }
);

export type CreateOrUpdateWorkGroupMutationVariables = Exact<{
  data: WorkGroupInput;
}>;


export type CreateOrUpdateWorkGroupMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { createOrUpdateWorkGroup?: Maybe<(
      { __typename?: 'WorkGroupPayload' }
      & Pick<WorkGroupPayload, 'success'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )>, content?: Maybe<(
        { __typename?: 'WorkGroup' }
        & WorkGroupFragment
      )> }
    )> }
  )> }
);

export type DeleteWorkGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWorkGroupMutation = (
  { __typename?: 'Mutation' }
  & { md?: Maybe<(
    { __typename?: 'MasterDataMutationEndpoint' }
    & { deleteWorkGroup?: Maybe<(
      { __typename?: 'IntPayload' }
      & Pick<IntPayload, 'success' | 'content'>
      & { message?: Maybe<(
        { __typename?: 'PayloadMessage' }
        & Pick<PayloadMessage, 'code' | 'message'>
      )> }
    )> }
  )> }
);

export type WorkGroupFragment = (
  { __typename: 'WorkGroup' }
  & Pick<WorkGroup, 'id' | 'cd' | 'name' | 'description'>
  & { workGroups: Array<(
    { __typename?: 'WorkGroup' }
    & Pick<WorkGroup, 'id' | 'name' | 'workGId' | 'description'>
  )>, salesRegions: Array<(
    { __typename?: 'SalesRegion' }
    & Pick<SalesRegion, 'id' | 'name' | 'workGroupId' | 'description'>
  )> }
);

export type WorkGroupListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter?: Maybe<Scalars['String']>;
}>;


export type WorkGroupListQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { workGroupList: (
      { __typename?: 'WorkGroupListResult' }
      & Pick<WorkGroupListResult, 'total'>
      & { data: Array<(
        { __typename?: 'WorkGroup' }
        & Pick<WorkGroup, 'id' | 'cd' | 'name' | 'workGId' | 'description'>
      )> }
    ) }
  ) }
);

export type WorkGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WorkGroupQuery = (
  { __typename?: 'Query' }
  & { md: (
    { __typename?: 'MasterDataQueryEndpoint' }
    & { workGroup: (
      { __typename?: 'WorkGroup' }
      & WorkGroupFragment
    ) }
  ) }
);

export const AttrFragmentDoc = gql`
    fragment AttrFragment on Attr {
  __typename
  id
  cd
  name
  description
  attrDetails {
    id
    value
    description
  }
}
    `;
export const CalendarFragmentDoc = gql`
    fragment CalendarFragment on Calendar {
  __typename
  id
  year
  firstWeekend
  description
  calendarWeeks {
    id
    calendarId
    week
    startDate
    endDate
  }
}
    `;
export const CdConfigFragmentDoc = gql`
    fragment CdConfigFragment on CdConfig {
  id
  count
  numbering
  description
  prefix
}
    `;
export const CheckOutReasonFragmentDoc = gql`
    fragment CheckOutReasonFragment on CheckOutReason {
  __typename
  id
  name
  minutes
  description
  requiredTasks
  isVisit
}
    `;
export const CustomerClassFragmentDoc = gql`
    fragment CustomerClassFragment on CustomerClass {
  __typename
  id
  cd
  name
  description
  customerClassAttrs {
    id
    attrId
    defaultAttrDetailId
  }
}
    `;
export const CustomerPriceClassFragmentDoc = gql`
    fragment CustomerPriceClassFragment on CustomerPriceClass {
  __typename
  id
  cd
  name
  description
}
    `;
export const CustomerFragmentDoc = gql`
    fragment CustomerFragment on Customer {
  __typename
  id
  cd
  name
  customerStatus
  customerClassId
  customerPriceClassId
  isDistributor
  isAlive
  photo {
    id
    url
  }
  lat
  lng
  isEditPhoto
  contactName1
  phone11
  phone12
  address1
  ward {
    id
    name
    district {
      id
      name
      province {
        id
        name
      }
    }
  }
}
    `;
export const SalesOrderFragmentDoc = gql`
    fragment SalesOrderFragment on SalesOrder {
  __typename
  id
  cd
  orderDate
  orderStatus
  orderType
  sellType
  total
  orderSource
  orderTotal
  description
  deliveryAddress
  receiver
  salesOrderBeforeReturnId
  discountMoney
  percentTotal
  vat
  deliveryNote {
    id
    status
    createdAt
    note
    shipper {
      id
      cd
      name
      phone11
      email
      citizenIdentityCard
      photoId
      address1
      distributorId
    }
  }
  salesOrderBeforeReturn {
    id
    cd
    orderDate
    orderStatus
    orderType
    sellType
    orderSource
    orderTotal
    orderTotalAfterTax
    discountMoney
    description
    salesOrderBeforeReturnId
    deliveryNote {
      id
    }
    salesOrderBeforeReturn {
      id
      cd
      salesOrderTrackLots {
        id
        qty
        unitId
        unitPrice
        unitPriceAfterTax
        baseQty
        baseUnitId
        baseUnitPrice
        lotNbr
        startDate
        endDate
        product {
          cd
          id
          name
          tax
        }
        warehouse {
          id
          name
        }
      }
    }
    reasonEditOrder {
      id
      name
      description
    }
    customer {
      id
      cd
      name
      customerPriceClassId
      address1
      phone11
      fullAddress
      ward {
        id
        name
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
    }
    distributor {
      id
      cd
      name
      phone11
      fullAddress
    }
    salesman {
      id
      cd
      name
    }
    enjoyDiscounts {
      id
      isManual
      discount {
        description
      }
      discountId
      discountLevels {
        id
        rewardId
        turnCount
        percentage
        amount
        discountLevelId
        productSelected {
          id
          qty
          productId
        }
      }
    }
    orderItems {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
      totalPrice
      totalPriceAfterTax
      isFreeItem
    }
    salesOrderTrackLots {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      product {
        cd
        id
        name
        tax
      }
      warehouse {
        id
        name
      }
    }
  }
  reasonEditOrder {
    id
    name
    description
  }
  customer {
    id
    cd
    name
    customerPriceClassId
    address1
    phone11
    fullAddress
    ward {
      id
      name
      district {
        id
        name
        province {
          id
          name
        }
      }
    }
  }
  creator {
    id
    cd
    name
  }
  distributor {
    id
    cd
    name
    phone11
    fullAddress
  }
  salesman {
    id
    cd
    name
  }
  enjoyDiscounts {
    id
    isManual
    discount {
      description
    }
    discountId
    discountLevels {
      id
      rewardId
      turnCount
      percentage
      amount
      discountLevelId
      productSelected {
        id
        qty
        productId
      }
    }
  }
  salesOrderDiscounts {
    id
    isManual
    salesOrderId
    discountId
    discount {
      id
      cd
      description
      status
      startDate
      endDate
      isBundle
      breakBy
      multiple
      sortOrder
      highestLevel
      buyGive
      excluded
      allowChangeLevel
      isManual
      firstOrder
      internalRef
      externalRef
      sortOrder
      buyGive
      discountLevels {
        id
        level
        description
        breakValue
        discountLevelRewards {
          id
          description
          isDefault
          isBundle
          amount
          percentage
          percentageMoney
          qty
          discountLevelRewardProducts {
            id
            productId
            rate
          }
        }
        discountLevelConditions {
          id
          productId
          minQty
        }
      }
    }
    salesOrderDiscountLevels {
      id
      totalAmount
      percentage
      percentageMoney
      totalTurn
      discountLevelId
      isSelected
      salesOrderFreeItems {
        id
        qty
        productId
        product {
          id
          cd
          name
        }
      }
    }
  }
  orderItems {
    id
    qty
    unitId
    unitPrice
    unitPriceAfterTax
    baseQty
    baseUnitId
    baseUnitPrice
    product {
      cd
      id
      name
      tax
    }
    warehouse {
      id
      name
    }
    totalPrice
    totalPriceAfterTax
    isFreeItem
    discount {
      id
      cd
    }
  }
  salesOrderTrackLots {
    id
    qty
    unitId
    unitPrice
    unitPriceAfterTax
    baseQty
    baseUnitId
    baseUnitPrice
    lotNbr
    startDate
    endDate
    warehouseId
    productId
    discountId
    product {
      cd
      id
      name
      tax
    }
    warehouse {
      id
      name
    }
  }
}
    `;
export const PurchaseReceiptFragmentDoc = gql`
    fragment PurchaseReceiptFragment on PurchaseReceipt {
  __typename
  id
  cd
  orderDate
  orderStatus
  orderTotal
  orderType
  description
  externalReference
  salesOrderId
  deliveryNote {
    id
  }
  vendor {
    id
    name
    phone11
    fullAddress
  }
  distributor {
    id
    cd
    name
    phone11
  }
  employee {
    id
    name
  }
  creator {
    id
    cd
    name
  }
  orderItems {
    id
    qty
    unitId
    unitPrice
    unitPriceAfterTax
    baseQty
    baseUnitId
    baseUnitPrice
    product {
      cd
      id
      name
      tax
    }
    warehouse {
      id
      name
    }
  }
  purchaseReceiptTrackLots {
    id
    qty
    unitId
    unitPrice
    unitPriceAfterTax
    baseQty
    baseUnitId
    baseUnitPrice
    lotNbr
    startDate
    endDate
    warehouseId
    productId
    product {
      cd
      id
      name
      tax
    }
    warehouse {
      id
      name
    }
  }
}
    `;
export const DeliveryNoteFragmentDoc = gql`
    fragment DeliveryNoteFragment on DeliveryNote {
  __typename
  id
  status
  note
  createdAt
  shipper {
    id
    cd
    name
    phone11
    email
    citizenIdentityCard
    photoId
    address1
    distributorId
  }
  salesOrder {
    ...SalesOrderFragment
  }
  purchaseReceipt {
    ...PurchaseReceiptFragment
  }
}
    ${SalesOrderFragmentDoc}
${PurchaseReceiptFragmentDoc}`;
export const DiscountFragmentDoc = gql`
    fragment DiscountFragment on Discount {
  __typename
  id
  cd
  description
  status
  startDate
  endDate
  isBundle
  breakBy
  multiple
  sortOrder
  highestLevel
  excluded
  allowChangeLevel
  isManual
  firstOrder
  internalRef
  externalRef
  sortOrder
  buyGive
  discountProducts {
    id
    productId
    rate
  }
  discountProductGroups {
    id
    productGroupId
  }
  discountLevels {
    id
    level
    description
    breakValue
    discountLevelRewards {
      id
      description
      isDefault
      isBundle
      amount
      percentage
      qty
      discountLevelRewardProducts {
        id
        productId
        rate
      }
    }
    discountLevelConditions {
      id
      productId
      minQty
    }
  }
  discountApplicableCustomers {
    id
    customer {
      id
      cd
      name
    }
  }
  discountApplicableCustomerAttrs {
    id
    attrId
    attrDetailId
  }
  discountApplicableSalesRegions {
    id
    salesRegion {
      id
      name
    }
  }
}
    `;
export const DistributorFragmentDoc = gql`
    fragment DistributorFragment on Distributor {
  __typename
  id
  cd
  name
  contactName1
  phone11
  address1
  isActive
  email
  startDate
  endDate
  distributorType {
    id
    cd
    name
  }
  customer {
    id
    name
  }
  ward {
    id
    name
    district {
      id
      name
      province {
        id
        name
      }
    }
  }
  warehouses {
    id
    name
    description
    pickOrder
    allowPurchase
    allowSales
    allowReceipt
    allowIssue
    allowAdjust
    allowTransfer
    address1
    salesman {
      id
      cd
      name
    }
    ward {
      id
      name
      district {
        id
        name
        province {
          id
          name
        }
      }
    }
  }
  buyers {
    id
    buyer {
      id
      name
    }
  }
  sellers {
    id
    seller {
      id
      name
    }
  }
}
    `;
export const EmployeeFragmentDoc = gql`
    fragment EmployeeFragment on Employee {
  __typename
  id
  cd
  name
  username
  email
  address1
  isActive
  phone11
  startDate
  endDate
  supervisor {
    id
    cd
    name
  }
  citizenIdentityCard
  photo {
    id
    url
  }
  ward {
    id
    name
    district {
      id
      name
      province {
        id
        name
      }
    }
  }
  employeePositions {
    id
    position {
      id
      name
    }
  }
  workingDistributors {
    id
    distributor {
      id
      cd
      name
    }
  }
  workingSalesRegions {
    id
    salesRegion {
      id
      name
    }
  }
}
    `;
export const KpiFormulaFragmentDoc = gql`
    fragment KpiFormulaFragment on KpiFormula {
  __typename
  id
  status
  name
  description
  formula
  amount
  percentage
  expiresBackDate
  attrDetail {
    id
    value
    description
  }
  kpiFormulaCategories {
    id
    attrDetailId
    percentage
    attrDetail {
      id
      value
      description
    }
  }
  kpiFormulaProducts {
    id
    rate
    productId
  }
}
    `;
export const KpiConfigFragmentDoc = gql`
    fragment KpiConfigFragment on KpiConfig {
  __typename
  id
  cd
  status
  target
  name
  description
  startDate
  endDate
  kpiConfigItems {
    id
    actual
    target
    rate
    percentageActual
    employee {
      id
      cd
      name
    }
    kpiFormula {
      id
      cd
      name
    }
    distributor {
      id
      cd
      name
    }
  }
}
    `;
export const McpFragmentDoc = gql`
    fragment McpFragment on Mcp {
  __typename
  id
  cd
  status
  sellTypes
  description
  inRangeMeter
  hasOrderRequiredTasks
  allowOutPlanVisit
  allowNewCustomerVisit
  startDate
  endDate
  salesRegion {
    id
    cd
    name
  }
  creator {
    id
    cd
    name
  }
  salesman {
    id
    cd
    name
  }
}
    `;
export const MeasureUnitFragmentDoc = gql`
    fragment MeasureUnitFragment on MeasureUnit {
  __typename
  id
  cd
  description
}
    `;
export const OtherProductFragmentDoc = gql`
    fragment OtherProductFragment on OtherProduct {
  __typename
  id
  cd
  name
  description
  measureUnitId
}
    `;
export const PositionFragmentDoc = gql`
    fragment PositionFragment on Position {
  __typename
  id
  name
  description
  positionPermissions {
    id
    permission
  }
}
    `;
export const ProductGroupFragmentDoc = gql`
    fragment ProductGroupFragment on ProductGroup {
  __typename
  id
  cd
  name
  description
  isBundle
  productGroupItems {
    id
    productId
    rate
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment ProductFragment on Product {
  __typename
  id
  cd
  name
  description
  basePrice
  baseUnitId
  salesUnitId
  purchaseUnitId
  isPop
  isExpirationDate
  isLot
  isActive
  tax
  photo {
    id
    url
  }
  productMeasureUnits {
    id
    fromUnitId
    toUnitId
    rate
    saleable
  }
}
    `;
export const SalesPriceFragmentDoc = gql`
    fragment SalesPriceFragment on SalesPrice {
  __typename
  id
  productId
  customerPriceClassId
  price
  unitId
  startDate
  endDate
  description
}
    `;
export const SalesRegionFragmentDoc = gql`
    fragment SalesRegionFragment on SalesRegion {
  __typename
  id
  cd
  name
  workGroupId
  description
  salesRegionAreas {
    id
    province {
      id
      name
    }
    district {
      id
      name
    }
    ward {
      id
      name
    }
  }
}
    `;
export const SurveyFragmentDoc = gql`
    fragment SurveyFragment on Survey {
  __typename
  id
  cd
  status
  title
  description
  startDate
  endDate
  multiple
  surveyQuestions {
    id
    title
    isFreeText
    surveyAnswers {
      id
      title
      requiredOtherTextAnswer
    }
  }
  surveyApplicableCustomerAttrs {
    id
    attrId
    attrDetailId
  }
  surveyApplicableSalesRegions {
    id
    salesRegion {
      id
      name
    }
  }
}
    `;
export const TradeFragmentDoc = gql`
    fragment TradeFragment on Trade {
  __typename
  id
  cd
  description
  status
  breakBy
  multiple
  autoLevel
  registerStartDate
  registerEndDate
  startDate
  endDate
  tradeProducts {
    id
    productId
    rate
  }
  tradeProductGroups {
    id
    productGroupId
  }
  tradeLevels {
    id
    level
    description
    breakValue
    amount
    percentage
    qty
    isBundle
    tradeLevelProducts {
      id
      productId
      rate
    }
  }
  tradeApplicableCustomers {
    id
    customer {
      id
      cd
      name
    }
  }
  tradeApplyTarget
  tradeApplicableDistributors {
    id
    distributor {
      id
      cd
      name
    }
  }
  tradeApplicableCustomerAttrs {
    id
    attrId
    attrDetailId
  }
  tradeApplicableSalesRegions {
    id
    salesRegion {
      id
      name
    }
  }
}
    `;
export const TransactionFragmentDoc = gql`
    fragment TransactionFragment on Transaction {
  __typename
  id
  source
  status
  description
  transactionDate
  salesOrderId
  purchaseReceiptId
  note
  reason {
    id
    name
    description
  }
  distributor {
    id
    name
  }
  creator {
    id
    name
  }
  fromWarehouse {
    id
    name
  }
  toWarehouse {
    id
    name
  }
  transactionItems {
    id
    type
    unitId
    unitPrice
    qty
    baseUnitId
    baseUnitPrice
    baseQty
    lotNbr
    startDate
    endDate
    exchangeLotNbr
    exchangeStartDate
    exchangeEndDate
    warehouse {
      id
      name
    }
    product {
      id
      name
    }
  }
}
    `;
export const VisualFragmentDoc = gql`
    fragment VisualFragment on Visual {
  __typename
  id
  cd
  status
  name
  description
  startDate
  endDate
  voteRequired
  minPhotos
  maxPhotos
  photo {
    id
    url
  }
  trade {
    id
    description
    startDate
    endDate
  }
  visualApplicableSalesRegions {
    id
    salesRegion {
      id
      name
    }
  }
}
    `;
export const WorkGroupFragmentDoc = gql`
    fragment WorkGroupFragment on WorkGroup {
  __typename
  id
  cd
  name
  description
  workGroups {
    id
    name
    workGId
    description
  }
  salesRegions {
    id
    name
    workGroupId
    description
  }
}
    `;
export const AttrsDocument = gql`
    query Attrs($type: EntityType!) {
  md {
    attrs(type: $type) {
      ...AttrFragment
    }
  }
}
    ${AttrFragmentDoc}`;

/**
 * __useAttrsQuery__
 *
 * To run a query within a React component, call `useAttrsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttrsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttrsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAttrsQuery(baseOptions: Apollo.QueryHookOptions<AttrsQuery, AttrsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttrsQuery, AttrsQueryVariables>(AttrsDocument, options);
      }
export function useAttrsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttrsQuery, AttrsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttrsQuery, AttrsQueryVariables>(AttrsDocument, options);
        }
export type AttrsQueryHookResult = ReturnType<typeof useAttrsQuery>;
export type AttrsLazyQueryHookResult = ReturnType<typeof useAttrsLazyQuery>;
export type AttrsQueryResult = Apollo.QueryResult<AttrsQuery, AttrsQueryVariables>;
export const AttrDetailsDocument = gql`
    query AttrDetails($type: EntityType!) {
  md {
    attrDetails(type: $type) {
      id
      value
      description
    }
  }
}
    `;

/**
 * __useAttrDetailsQuery__
 *
 * To run a query within a React component, call `useAttrDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttrDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttrDetailsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAttrDetailsQuery(baseOptions: Apollo.QueryHookOptions<AttrDetailsQuery, AttrDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttrDetailsQuery, AttrDetailsQueryVariables>(AttrDetailsDocument, options);
      }
export function useAttrDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttrDetailsQuery, AttrDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttrDetailsQuery, AttrDetailsQueryVariables>(AttrDetailsDocument, options);
        }
export type AttrDetailsQueryHookResult = ReturnType<typeof useAttrDetailsQuery>;
export type AttrDetailsLazyQueryHookResult = ReturnType<typeof useAttrDetailsLazyQuery>;
export type AttrDetailsQueryResult = Apollo.QueryResult<AttrDetailsQuery, AttrDetailsQueryVariables>;
export const AttrDocument = gql`
    query Attr($id: Int!, $type: EntityType!) {
  md {
    attr(id: $id, type: $type) {
      ...AttrFragment
    }
  }
}
    ${AttrFragmentDoc}`;

/**
 * __useAttrQuery__
 *
 * To run a query within a React component, call `useAttrQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttrQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAttrQuery(baseOptions: Apollo.QueryHookOptions<AttrQuery, AttrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttrQuery, AttrQueryVariables>(AttrDocument, options);
      }
export function useAttrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttrQuery, AttrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttrQuery, AttrQueryVariables>(AttrDocument, options);
        }
export type AttrQueryHookResult = ReturnType<typeof useAttrQuery>;
export type AttrLazyQueryHookResult = ReturnType<typeof useAttrLazyQuery>;
export type AttrQueryResult = Apollo.QueryResult<AttrQuery, AttrQueryVariables>;
export const CreateOrUpdateAttrDocument = gql`
    mutation CreateOrUpdateAttr($data: AttrInput!) {
  md {
    createOrUpdateAttr(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...AttrFragment
      }
    }
  }
}
    ${AttrFragmentDoc}`;
export type CreateOrUpdateAttrMutationFn = Apollo.MutationFunction<CreateOrUpdateAttrMutation, CreateOrUpdateAttrMutationVariables>;

/**
 * __useCreateOrUpdateAttrMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateAttrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateAttrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateAttrMutation, { data, loading, error }] = useCreateOrUpdateAttrMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateAttrMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateAttrMutation, CreateOrUpdateAttrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateAttrMutation, CreateOrUpdateAttrMutationVariables>(CreateOrUpdateAttrDocument, options);
      }
export type CreateOrUpdateAttrMutationHookResult = ReturnType<typeof useCreateOrUpdateAttrMutation>;
export type CreateOrUpdateAttrMutationResult = Apollo.MutationResult<CreateOrUpdateAttrMutation>;
export type CreateOrUpdateAttrMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateAttrMutation, CreateOrUpdateAttrMutationVariables>;
export const DeleteAttrDocument = gql`
    mutation DeleteAttr($id: Int!) {
  md {
    deleteAttr(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteAttrMutationFn = Apollo.MutationFunction<DeleteAttrMutation, DeleteAttrMutationVariables>;

/**
 * __useDeleteAttrMutation__
 *
 * To run a mutation, you first call `useDeleteAttrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttrMutation, { data, loading, error }] = useDeleteAttrMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttrMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttrMutation, DeleteAttrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttrMutation, DeleteAttrMutationVariables>(DeleteAttrDocument, options);
      }
export type DeleteAttrMutationHookResult = ReturnType<typeof useDeleteAttrMutation>;
export type DeleteAttrMutationResult = Apollo.MutationResult<DeleteAttrMutation>;
export type DeleteAttrMutationOptions = Apollo.BaseMutationOptions<DeleteAttrMutation, DeleteAttrMutationVariables>;
export const CalculateCalendarDocument = gql`
    mutation CalculateCalendar($id: Int!) {
  mcp {
    calculateCalendar(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type CalculateCalendarMutationFn = Apollo.MutationFunction<CalculateCalendarMutation, CalculateCalendarMutationVariables>;

/**
 * __useCalculateCalendarMutation__
 *
 * To run a mutation, you first call `useCalculateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateCalendarMutation, { data, loading, error }] = useCalculateCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalculateCalendarMutation(baseOptions?: Apollo.MutationHookOptions<CalculateCalendarMutation, CalculateCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateCalendarMutation, CalculateCalendarMutationVariables>(CalculateCalendarDocument, options);
      }
export type CalculateCalendarMutationHookResult = ReturnType<typeof useCalculateCalendarMutation>;
export type CalculateCalendarMutationResult = Apollo.MutationResult<CalculateCalendarMutation>;
export type CalculateCalendarMutationOptions = Apollo.BaseMutationOptions<CalculateCalendarMutation, CalculateCalendarMutationVariables>;
export const CalendarListDocument = gql`
    query CalendarList($page: Int!, $take: Int!) {
  mcp {
    calendarList(page: $page, take: $take) {
      total
      data {
        ...CalendarFragment
      }
    }
  }
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarListQuery__
 *
 * To run a query within a React component, call `useCalendarListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCalendarListQuery(baseOptions: Apollo.QueryHookOptions<CalendarListQuery, CalendarListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarListQuery, CalendarListQueryVariables>(CalendarListDocument, options);
      }
export function useCalendarListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarListQuery, CalendarListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarListQuery, CalendarListQueryVariables>(CalendarListDocument, options);
        }
export type CalendarListQueryHookResult = ReturnType<typeof useCalendarListQuery>;
export type CalendarListLazyQueryHookResult = ReturnType<typeof useCalendarListLazyQuery>;
export type CalendarListQueryResult = Apollo.QueryResult<CalendarListQuery, CalendarListQueryVariables>;
export const CalendarDocument = gql`
    query Calendar($id: Int!) {
  mcp {
    calendar(id: $id) {
      ...CalendarFragment
    }
  }
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarQuery(baseOptions: Apollo.QueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
      }
export function useCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;
export const CreateOrUpdateCalendarDocument = gql`
    mutation CreateOrUpdateCalendar($data: CalendarInput!) {
  mcp {
    createOrUpdateCalendar(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CalendarFragment
      }
    }
  }
}
    ${CalendarFragmentDoc}`;
export type CreateOrUpdateCalendarMutationFn = Apollo.MutationFunction<CreateOrUpdateCalendarMutation, CreateOrUpdateCalendarMutationVariables>;

/**
 * __useCreateOrUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCalendarMutation, { data, loading, error }] = useCreateOrUpdateCalendarMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCalendarMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCalendarMutation, CreateOrUpdateCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCalendarMutation, CreateOrUpdateCalendarMutationVariables>(CreateOrUpdateCalendarDocument, options);
      }
export type CreateOrUpdateCalendarMutationHookResult = ReturnType<typeof useCreateOrUpdateCalendarMutation>;
export type CreateOrUpdateCalendarMutationResult = Apollo.MutationResult<CreateOrUpdateCalendarMutation>;
export type CreateOrUpdateCalendarMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCalendarMutation, CreateOrUpdateCalendarMutationVariables>;
export const DeleteCalendarDocument = gql`
    mutation DeleteCalendar($id: Int!) {
  mcp {
    deleteCalendar(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteCalendarMutationFn = Apollo.MutationFunction<DeleteCalendarMutation, DeleteCalendarMutationVariables>;

/**
 * __useDeleteCalendarMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarMutation, { data, loading, error }] = useDeleteCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCalendarMutation, DeleteCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCalendarMutation, DeleteCalendarMutationVariables>(DeleteCalendarDocument, options);
      }
export type DeleteCalendarMutationHookResult = ReturnType<typeof useDeleteCalendarMutation>;
export type DeleteCalendarMutationResult = Apollo.MutationResult<DeleteCalendarMutation>;
export type DeleteCalendarMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarMutation, DeleteCalendarMutationVariables>;
export const CdsConfigDocument = gql`
    query CdsConfig {
  md {
    cdsConfig {
      ...CdConfigFragment
    }
  }
}
    ${CdConfigFragmentDoc}`;

/**
 * __useCdsConfigQuery__
 *
 * To run a query within a React component, call `useCdsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCdsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCdsConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCdsConfigQuery(baseOptions?: Apollo.QueryHookOptions<CdsConfigQuery, CdsConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CdsConfigQuery, CdsConfigQueryVariables>(CdsConfigDocument, options);
      }
export function useCdsConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CdsConfigQuery, CdsConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CdsConfigQuery, CdsConfigQueryVariables>(CdsConfigDocument, options);
        }
export type CdsConfigQueryHookResult = ReturnType<typeof useCdsConfigQuery>;
export type CdsConfigLazyQueryHookResult = ReturnType<typeof useCdsConfigLazyQuery>;
export type CdsConfigQueryResult = Apollo.QueryResult<CdsConfigQuery, CdsConfigQueryVariables>;
export const CdConfigDocument = gql`
    query CdConfig($id: Int!) {
  md {
    cdConfig(id: $id) {
      ...CdConfigFragment
    }
  }
}
    ${CdConfigFragmentDoc}`;

/**
 * __useCdConfigQuery__
 *
 * To run a query within a React component, call `useCdConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCdConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCdConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCdConfigQuery(baseOptions: Apollo.QueryHookOptions<CdConfigQuery, CdConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CdConfigQuery, CdConfigQueryVariables>(CdConfigDocument, options);
      }
export function useCdConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CdConfigQuery, CdConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CdConfigQuery, CdConfigQueryVariables>(CdConfigDocument, options);
        }
export type CdConfigQueryHookResult = ReturnType<typeof useCdConfigQuery>;
export type CdConfigLazyQueryHookResult = ReturnType<typeof useCdConfigLazyQuery>;
export type CdConfigQueryResult = Apollo.QueryResult<CdConfigQuery, CdConfigQueryVariables>;
export const UpdateCdConfigDocument = gql`
    mutation UpdateCdConfig($data: CdConfigInput!) {
  md {
    updateCdConfig(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CdConfigFragment
      }
    }
  }
}
    ${CdConfigFragmentDoc}`;
export type UpdateCdConfigMutationFn = Apollo.MutationFunction<UpdateCdConfigMutation, UpdateCdConfigMutationVariables>;

/**
 * __useUpdateCdConfigMutation__
 *
 * To run a mutation, you first call `useUpdateCdConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCdConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCdConfigMutation, { data, loading, error }] = useUpdateCdConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCdConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCdConfigMutation, UpdateCdConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCdConfigMutation, UpdateCdConfigMutationVariables>(UpdateCdConfigDocument, options);
      }
export type UpdateCdConfigMutationHookResult = ReturnType<typeof useUpdateCdConfigMutation>;
export type UpdateCdConfigMutationResult = Apollo.MutationResult<UpdateCdConfigMutation>;
export type UpdateCdConfigMutationOptions = Apollo.BaseMutationOptions<UpdateCdConfigMutation, UpdateCdConfigMutationVariables>;
export const CheckOutReasonsDocument = gql`
    query CheckOutReasons {
  mcp {
    checkOutReasons {
      ...CheckOutReasonFragment
    }
  }
}
    ${CheckOutReasonFragmentDoc}`;

/**
 * __useCheckOutReasonsQuery__
 *
 * To run a query within a React component, call `useCheckOutReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOutReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOutReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckOutReasonsQuery(baseOptions?: Apollo.QueryHookOptions<CheckOutReasonsQuery, CheckOutReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckOutReasonsQuery, CheckOutReasonsQueryVariables>(CheckOutReasonsDocument, options);
      }
export function useCheckOutReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckOutReasonsQuery, CheckOutReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckOutReasonsQuery, CheckOutReasonsQueryVariables>(CheckOutReasonsDocument, options);
        }
export type CheckOutReasonsQueryHookResult = ReturnType<typeof useCheckOutReasonsQuery>;
export type CheckOutReasonsLazyQueryHookResult = ReturnType<typeof useCheckOutReasonsLazyQuery>;
export type CheckOutReasonsQueryResult = Apollo.QueryResult<CheckOutReasonsQuery, CheckOutReasonsQueryVariables>;
export const CheckOutReasonDocument = gql`
    query CheckOutReason($id: Int!) {
  mcp {
    checkOutReason(id: $id) {
      ...CheckOutReasonFragment
    }
  }
}
    ${CheckOutReasonFragmentDoc}`;

/**
 * __useCheckOutReasonQuery__
 *
 * To run a query within a React component, call `useCheckOutReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOutReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOutReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckOutReasonQuery(baseOptions: Apollo.QueryHookOptions<CheckOutReasonQuery, CheckOutReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckOutReasonQuery, CheckOutReasonQueryVariables>(CheckOutReasonDocument, options);
      }
export function useCheckOutReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckOutReasonQuery, CheckOutReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckOutReasonQuery, CheckOutReasonQueryVariables>(CheckOutReasonDocument, options);
        }
export type CheckOutReasonQueryHookResult = ReturnType<typeof useCheckOutReasonQuery>;
export type CheckOutReasonLazyQueryHookResult = ReturnType<typeof useCheckOutReasonLazyQuery>;
export type CheckOutReasonQueryResult = Apollo.QueryResult<CheckOutReasonQuery, CheckOutReasonQueryVariables>;
export const CreateOrUpdateCheckOutReasonDocument = gql`
    mutation CreateOrUpdateCheckOutReason($data: CheckOutReasonInput!) {
  mcp {
    createOrUpdateCheckOutReason(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CheckOutReasonFragment
      }
    }
  }
}
    ${CheckOutReasonFragmentDoc}`;
export type CreateOrUpdateCheckOutReasonMutationFn = Apollo.MutationFunction<CreateOrUpdateCheckOutReasonMutation, CreateOrUpdateCheckOutReasonMutationVariables>;

/**
 * __useCreateOrUpdateCheckOutReasonMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCheckOutReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCheckOutReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCheckOutReasonMutation, { data, loading, error }] = useCreateOrUpdateCheckOutReasonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCheckOutReasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCheckOutReasonMutation, CreateOrUpdateCheckOutReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCheckOutReasonMutation, CreateOrUpdateCheckOutReasonMutationVariables>(CreateOrUpdateCheckOutReasonDocument, options);
      }
export type CreateOrUpdateCheckOutReasonMutationHookResult = ReturnType<typeof useCreateOrUpdateCheckOutReasonMutation>;
export type CreateOrUpdateCheckOutReasonMutationResult = Apollo.MutationResult<CreateOrUpdateCheckOutReasonMutation>;
export type CreateOrUpdateCheckOutReasonMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCheckOutReasonMutation, CreateOrUpdateCheckOutReasonMutationVariables>;
export const DeleteCheckOutReasonDocument = gql`
    mutation DeleteCheckOutReason($id: Int!) {
  mcp {
    deleteCheckOutReason(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteCheckOutReasonMutationFn = Apollo.MutationFunction<DeleteCheckOutReasonMutation, DeleteCheckOutReasonMutationVariables>;

/**
 * __useDeleteCheckOutReasonMutation__
 *
 * To run a mutation, you first call `useDeleteCheckOutReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCheckOutReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCheckOutReasonMutation, { data, loading, error }] = useDeleteCheckOutReasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCheckOutReasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCheckOutReasonMutation, DeleteCheckOutReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCheckOutReasonMutation, DeleteCheckOutReasonMutationVariables>(DeleteCheckOutReasonDocument, options);
      }
export type DeleteCheckOutReasonMutationHookResult = ReturnType<typeof useDeleteCheckOutReasonMutation>;
export type DeleteCheckOutReasonMutationResult = Apollo.MutationResult<DeleteCheckOutReasonMutation>;
export type DeleteCheckOutReasonMutationOptions = Apollo.BaseMutationOptions<DeleteCheckOutReasonMutation, DeleteCheckOutReasonMutationVariables>;
export const ActionStatusCommandoLineDocument = gql`
    mutation ActionStatusCommandoLine($id: Int!) {
  mcp {
    actionStatusCommandoLine(id: $id) {
      success
      message {
        code
        code
      }
      content
    }
  }
}
    `;
export type ActionStatusCommandoLineMutationFn = Apollo.MutationFunction<ActionStatusCommandoLineMutation, ActionStatusCommandoLineMutationVariables>;

/**
 * __useActionStatusCommandoLineMutation__
 *
 * To run a mutation, you first call `useActionStatusCommandoLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionStatusCommandoLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionStatusCommandoLineMutation, { data, loading, error }] = useActionStatusCommandoLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActionStatusCommandoLineMutation(baseOptions?: Apollo.MutationHookOptions<ActionStatusCommandoLineMutation, ActionStatusCommandoLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionStatusCommandoLineMutation, ActionStatusCommandoLineMutationVariables>(ActionStatusCommandoLineDocument, options);
      }
export type ActionStatusCommandoLineMutationHookResult = ReturnType<typeof useActionStatusCommandoLineMutation>;
export type ActionStatusCommandoLineMutationResult = Apollo.MutationResult<ActionStatusCommandoLineMutation>;
export type ActionStatusCommandoLineMutationOptions = Apollo.BaseMutationOptions<ActionStatusCommandoLineMutation, ActionStatusCommandoLineMutationVariables>;
export const CloseCommandoDocument = gql`
    mutation CloseCommando($id: Int!) {
  mcp {
    closeCommando(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseCommandoMutationFn = Apollo.MutationFunction<CloseCommandoMutation, CloseCommandoMutationVariables>;

/**
 * __useCloseCommandoMutation__
 *
 * To run a mutation, you first call `useCloseCommandoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseCommandoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeCommandoMutation, { data, loading, error }] = useCloseCommandoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseCommandoMutation(baseOptions?: Apollo.MutationHookOptions<CloseCommandoMutation, CloseCommandoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseCommandoMutation, CloseCommandoMutationVariables>(CloseCommandoDocument, options);
      }
export type CloseCommandoMutationHookResult = ReturnType<typeof useCloseCommandoMutation>;
export type CloseCommandoMutationResult = Apollo.MutationResult<CloseCommandoMutation>;
export type CloseCommandoMutationOptions = Apollo.BaseMutationOptions<CloseCommandoMutation, CloseCommandoMutationVariables>;
export const CommandoLinesDocument = gql`
    query CommandoLines($commandoId: Int!, $visitDate: Date, $page: Int!, $take: Int!) {
  mcp {
    commandoLines(
      commandoId: $commandoId
      visitDate: $visitDate
      page: $page
      take: $take
    ) {
      total
      data {
        id
        distributor {
          id
          cd
          name
        }
        visitDate
        startHour
        endHour
        targetAmount
        targetCustomer
        isActive
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCommandoLinesQuery__
 *
 * To run a query within a React component, call `useCommandoLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandoLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandoLinesQuery({
 *   variables: {
 *      commandoId: // value for 'commandoId'
 *      visitDate: // value for 'visitDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCommandoLinesQuery(baseOptions: Apollo.QueryHookOptions<CommandoLinesQuery, CommandoLinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandoLinesQuery, CommandoLinesQueryVariables>(CommandoLinesDocument, options);
      }
export function useCommandoLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandoLinesQuery, CommandoLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandoLinesQuery, CommandoLinesQueryVariables>(CommandoLinesDocument, options);
        }
export type CommandoLinesQueryHookResult = ReturnType<typeof useCommandoLinesQuery>;
export type CommandoLinesLazyQueryHookResult = ReturnType<typeof useCommandoLinesLazyQuery>;
export type CommandoLinesQueryResult = Apollo.QueryResult<CommandoLinesQuery, CommandoLinesQueryVariables>;
export const CommandoListDocument = gql`
    query CommandoList($page: Int!, $take: Int!, $status: ProgramStatus, $salesmanCd: String, $commandoCd: String) {
  mcp {
    commandoList(
      page: $page
      take: $take
      status: $status
      salesmanCd: $salesmanCd
      commandoCd: $commandoCd
    ) {
      total
      data {
        id
        cd
        status
        sellTypes
        description
        inRangeMeter
        hasOrderRequiredTasks
        allowOutPlanVisit
        allowNewCustomerVisit
        startDate
        endDate
        salesRegion {
          id
          name
        }
        salesman {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCommandoListQuery__
 *
 * To run a query within a React component, call `useCommandoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandoListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      status: // value for 'status'
 *      salesmanCd: // value for 'salesmanCd'
 *      commandoCd: // value for 'commandoCd'
 *   },
 * });
 */
export function useCommandoListQuery(baseOptions: Apollo.QueryHookOptions<CommandoListQuery, CommandoListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandoListQuery, CommandoListQueryVariables>(CommandoListDocument, options);
      }
export function useCommandoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandoListQuery, CommandoListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandoListQuery, CommandoListQueryVariables>(CommandoListDocument, options);
        }
export type CommandoListQueryHookResult = ReturnType<typeof useCommandoListQuery>;
export type CommandoListLazyQueryHookResult = ReturnType<typeof useCommandoListLazyQuery>;
export type CommandoListQueryResult = Apollo.QueryResult<CommandoListQuery, CommandoListQueryVariables>;
export const CommandoDocument = gql`
    query Commando($id: Int!) {
  mcp {
    commando(id: $id) {
      id
      cd
      status
      description
      sellTypes
      inRangeMeter
      hasOrderRequiredTasks
      allowOutPlanVisit
      allowNewCustomerVisit
      startDate
      endDate
      lastGeneratedDate
      salesRegion {
        id
        cd
        name
        description
      }
      salesman {
        id
        name
        cd
      }
      provinceSalesRegion {
        id
        name
        districts {
          id
          name
          wards {
            id
            name
          }
        }
      }
      commandoDistributors {
        id
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCommandoQuery__
 *
 * To run a query within a React component, call `useCommandoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommandoQuery(baseOptions: Apollo.QueryHookOptions<CommandoQuery, CommandoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandoQuery, CommandoQueryVariables>(CommandoDocument, options);
      }
export function useCommandoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandoQuery, CommandoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandoQuery, CommandoQueryVariables>(CommandoDocument, options);
        }
export type CommandoQueryHookResult = ReturnType<typeof useCommandoQuery>;
export type CommandoLazyQueryHookResult = ReturnType<typeof useCommandoLazyQuery>;
export type CommandoQueryResult = Apollo.QueryResult<CommandoQuery, CommandoQueryVariables>;
export const CreateOrUpdateCommandoLineDocument = gql`
    mutation createOrUpdateCommandoLine($data: CommandoLineInput!) {
  mcp {
    createOrUpdateCommandoLine(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        distributor {
          id
          cd
          name
        }
        visitDate
        addedAt
        startHour
        endHour
        targetAmount
        targetCustomer
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateOrUpdateCommandoLineMutationFn = Apollo.MutationFunction<CreateOrUpdateCommandoLineMutation, CreateOrUpdateCommandoLineMutationVariables>;

/**
 * __useCreateOrUpdateCommandoLineMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCommandoLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCommandoLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCommandoLineMutation, { data, loading, error }] = useCreateOrUpdateCommandoLineMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCommandoLineMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCommandoLineMutation, CreateOrUpdateCommandoLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCommandoLineMutation, CreateOrUpdateCommandoLineMutationVariables>(CreateOrUpdateCommandoLineDocument, options);
      }
export type CreateOrUpdateCommandoLineMutationHookResult = ReturnType<typeof useCreateOrUpdateCommandoLineMutation>;
export type CreateOrUpdateCommandoLineMutationResult = Apollo.MutationResult<CreateOrUpdateCommandoLineMutation>;
export type CreateOrUpdateCommandoLineMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCommandoLineMutation, CreateOrUpdateCommandoLineMutationVariables>;
export const CreateOrUpdateCommandoDocument = gql`
    mutation CreateOrUpdateCommando($data: CommandoInput!) {
  mcp {
    createOrUpdateCommando(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        cd
        status
        sellTypes
        description
        inRangeMeter
        hasOrderRequiredTasks
        allowOutPlanVisit
        allowNewCustomerVisit
        startDate
        endDate
        lastGeneratedDate
        salesman {
          id
          cd
          name
        }
        commandoDistributors {
          id
          distributor {
            id
            cd
            name
          }
        }
      }
    }
  }
}
    `;
export type CreateOrUpdateCommandoMutationFn = Apollo.MutationFunction<CreateOrUpdateCommandoMutation, CreateOrUpdateCommandoMutationVariables>;

/**
 * __useCreateOrUpdateCommandoMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCommandoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCommandoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCommandoMutation, { data, loading, error }] = useCreateOrUpdateCommandoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCommandoMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCommandoMutation, CreateOrUpdateCommandoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCommandoMutation, CreateOrUpdateCommandoMutationVariables>(CreateOrUpdateCommandoDocument, options);
      }
export type CreateOrUpdateCommandoMutationHookResult = ReturnType<typeof useCreateOrUpdateCommandoMutation>;
export type CreateOrUpdateCommandoMutationResult = Apollo.MutationResult<CreateOrUpdateCommandoMutation>;
export type CreateOrUpdateCommandoMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCommandoMutation, CreateOrUpdateCommandoMutationVariables>;
export const ReleaseCommandoDocument = gql`
    mutation ReleaseCommando($id: Int!) {
  mcp {
    releaseCommando(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        cd
        status
      }
    }
  }
}
    `;
export type ReleaseCommandoMutationFn = Apollo.MutationFunction<ReleaseCommandoMutation, ReleaseCommandoMutationVariables>;

/**
 * __useReleaseCommandoMutation__
 *
 * To run a mutation, you first call `useReleaseCommandoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseCommandoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseCommandoMutation, { data, loading, error }] = useReleaseCommandoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseCommandoMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseCommandoMutation, ReleaseCommandoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseCommandoMutation, ReleaseCommandoMutationVariables>(ReleaseCommandoDocument, options);
      }
export type ReleaseCommandoMutationHookResult = ReturnType<typeof useReleaseCommandoMutation>;
export type ReleaseCommandoMutationResult = Apollo.MutationResult<ReleaseCommandoMutation>;
export type ReleaseCommandoMutationOptions = Apollo.BaseMutationOptions<ReleaseCommandoMutation, ReleaseCommandoMutationVariables>;
export const AcceptHoldingSalesOrderDocument = gql`
    mutation AcceptHoldingSalesOrder($listId: [Int!]!) {
  ctrl {
    acceptHoldingSalesOrder(listIds: $listId) {
      success
    }
  }
}
    `;
export type AcceptHoldingSalesOrderMutationFn = Apollo.MutationFunction<AcceptHoldingSalesOrderMutation, AcceptHoldingSalesOrderMutationVariables>;

/**
 * __useAcceptHoldingSalesOrderMutation__
 *
 * To run a mutation, you first call `useAcceptHoldingSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptHoldingSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptHoldingSalesOrderMutation, { data, loading, error }] = useAcceptHoldingSalesOrderMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useAcceptHoldingSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<AcceptHoldingSalesOrderMutation, AcceptHoldingSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptHoldingSalesOrderMutation, AcceptHoldingSalesOrderMutationVariables>(AcceptHoldingSalesOrderDocument, options);
      }
export type AcceptHoldingSalesOrderMutationHookResult = ReturnType<typeof useAcceptHoldingSalesOrderMutation>;
export type AcceptHoldingSalesOrderMutationResult = Apollo.MutationResult<AcceptHoldingSalesOrderMutation>;
export type AcceptHoldingSalesOrderMutationOptions = Apollo.BaseMutationOptions<AcceptHoldingSalesOrderMutation, AcceptHoldingSalesOrderMutationVariables>;
export const CancelHoldingSalesOrderDocument = gql`
    mutation CancelHoldingSalesOrder($listId: [Int!]!) {
  ctrl {
    cancelHoldingSalesOrder(listIds: $listId) {
      success
    }
  }
}
    `;
export type CancelHoldingSalesOrderMutationFn = Apollo.MutationFunction<CancelHoldingSalesOrderMutation, CancelHoldingSalesOrderMutationVariables>;

/**
 * __useCancelHoldingSalesOrderMutation__
 *
 * To run a mutation, you first call `useCancelHoldingSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelHoldingSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelHoldingSalesOrderMutation, { data, loading, error }] = useCancelHoldingSalesOrderMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCancelHoldingSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelHoldingSalesOrderMutation, CancelHoldingSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelHoldingSalesOrderMutation, CancelHoldingSalesOrderMutationVariables>(CancelHoldingSalesOrderDocument, options);
      }
export type CancelHoldingSalesOrderMutationHookResult = ReturnType<typeof useCancelHoldingSalesOrderMutation>;
export type CancelHoldingSalesOrderMutationResult = Apollo.MutationResult<CancelHoldingSalesOrderMutation>;
export type CancelHoldingSalesOrderMutationOptions = Apollo.BaseMutationOptions<CancelHoldingSalesOrderMutation, CancelHoldingSalesOrderMutationVariables>;
export const ApproveNewCommandoCustomerDocument = gql`
    mutation ApproveNewCommandoCustomer($listId: [Int!]!) {
  ctrl {
    approveNewCommandoCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type ApproveNewCommandoCustomerMutationFn = Apollo.MutationFunction<ApproveNewCommandoCustomerMutation, ApproveNewCommandoCustomerMutationVariables>;

/**
 * __useApproveNewCommandoCustomerMutation__
 *
 * To run a mutation, you first call `useApproveNewCommandoCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveNewCommandoCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveNewCommandoCustomerMutation, { data, loading, error }] = useApproveNewCommandoCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useApproveNewCommandoCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ApproveNewCommandoCustomerMutation, ApproveNewCommandoCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveNewCommandoCustomerMutation, ApproveNewCommandoCustomerMutationVariables>(ApproveNewCommandoCustomerDocument, options);
      }
export type ApproveNewCommandoCustomerMutationHookResult = ReturnType<typeof useApproveNewCommandoCustomerMutation>;
export type ApproveNewCommandoCustomerMutationResult = Apollo.MutationResult<ApproveNewCommandoCustomerMutation>;
export type ApproveNewCommandoCustomerMutationOptions = Apollo.BaseMutationOptions<ApproveNewCommandoCustomerMutation, ApproveNewCommandoCustomerMutationVariables>;
export const ApproveNewCustomerDocument = gql`
    mutation ApproveNewCustomer($listId: [Int!]!) {
  ctrl {
    approveNewCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type ApproveNewCustomerMutationFn = Apollo.MutationFunction<ApproveNewCustomerMutation, ApproveNewCustomerMutationVariables>;

/**
 * __useApproveNewCustomerMutation__
 *
 * To run a mutation, you first call `useApproveNewCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveNewCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveNewCustomerMutation, { data, loading, error }] = useApproveNewCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useApproveNewCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ApproveNewCustomerMutation, ApproveNewCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveNewCustomerMutation, ApproveNewCustomerMutationVariables>(ApproveNewCustomerDocument, options);
      }
export type ApproveNewCustomerMutationHookResult = ReturnType<typeof useApproveNewCustomerMutation>;
export type ApproveNewCustomerMutationResult = Apollo.MutationResult<ApproveNewCustomerMutation>;
export type ApproveNewCustomerMutationOptions = Apollo.BaseMutationOptions<ApproveNewCustomerMutation, ApproveNewCustomerMutationVariables>;
export const ApproveUpdateCommandoCustomerDocument = gql`
    mutation ApproveUpdateCommandoCustomer($listId: [Int!]!) {
  ctrl {
    approveUpdateCommandoCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type ApproveUpdateCommandoCustomerMutationFn = Apollo.MutationFunction<ApproveUpdateCommandoCustomerMutation, ApproveUpdateCommandoCustomerMutationVariables>;

/**
 * __useApproveUpdateCommandoCustomerMutation__
 *
 * To run a mutation, you first call `useApproveUpdateCommandoCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUpdateCommandoCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUpdateCommandoCustomerMutation, { data, loading, error }] = useApproveUpdateCommandoCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useApproveUpdateCommandoCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUpdateCommandoCustomerMutation, ApproveUpdateCommandoCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUpdateCommandoCustomerMutation, ApproveUpdateCommandoCustomerMutationVariables>(ApproveUpdateCommandoCustomerDocument, options);
      }
export type ApproveUpdateCommandoCustomerMutationHookResult = ReturnType<typeof useApproveUpdateCommandoCustomerMutation>;
export type ApproveUpdateCommandoCustomerMutationResult = Apollo.MutationResult<ApproveUpdateCommandoCustomerMutation>;
export type ApproveUpdateCommandoCustomerMutationOptions = Apollo.BaseMutationOptions<ApproveUpdateCommandoCustomerMutation, ApproveUpdateCommandoCustomerMutationVariables>;
export const ApproveUpdateCustomerDocument = gql`
    mutation ApproveUpdateCustomer($listId: [Int!]!) {
  ctrl {
    approveUpdateCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type ApproveUpdateCustomerMutationFn = Apollo.MutationFunction<ApproveUpdateCustomerMutation, ApproveUpdateCustomerMutationVariables>;

/**
 * __useApproveUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useApproveUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUpdateCustomerMutation, { data, loading, error }] = useApproveUpdateCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useApproveUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUpdateCustomerMutation, ApproveUpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUpdateCustomerMutation, ApproveUpdateCustomerMutationVariables>(ApproveUpdateCustomerDocument, options);
      }
export type ApproveUpdateCustomerMutationHookResult = ReturnType<typeof useApproveUpdateCustomerMutation>;
export type ApproveUpdateCustomerMutationResult = Apollo.MutationResult<ApproveUpdateCustomerMutation>;
export type ApproveUpdateCustomerMutationOptions = Apollo.BaseMutationOptions<ApproveUpdateCustomerMutation, ApproveUpdateCustomerMutationVariables>;
export const CloseCustomerDocument = gql`
    mutation CloseCustomer($listId: [Int!]!) {
  ctrl {
    closeCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type CloseCustomerMutationFn = Apollo.MutationFunction<CloseCustomerMutation, CloseCustomerMutationVariables>;

/**
 * __useCloseCustomerMutation__
 *
 * To run a mutation, you first call `useCloseCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeCustomerMutation, { data, loading, error }] = useCloseCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCloseCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CloseCustomerMutation, CloseCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseCustomerMutation, CloseCustomerMutationVariables>(CloseCustomerDocument, options);
      }
export type CloseCustomerMutationHookResult = ReturnType<typeof useCloseCustomerMutation>;
export type CloseCustomerMutationResult = Apollo.MutationResult<CloseCustomerMutation>;
export type CloseCustomerMutationOptions = Apollo.BaseMutationOptions<CloseCustomerMutation, CloseCustomerMutationVariables>;
export const CommandoNewCustomerListDocument = gql`
    query CommandoNewCustomerList($page: Int!, $take: Int!, $filter: String) {
  ctrl {
    commandoNewCustomerList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
        phone11
        customerStatus
        customerClassId
        customerPriceClassId
        photo {
          id
          url
        }
        lat
        lng
        contactName1
        fullAddress
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCommandoNewCustomerListQuery__
 *
 * To run a query within a React component, call `useCommandoNewCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandoNewCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandoNewCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCommandoNewCustomerListQuery(baseOptions: Apollo.QueryHookOptions<CommandoNewCustomerListQuery, CommandoNewCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommandoNewCustomerListQuery, CommandoNewCustomerListQueryVariables>(CommandoNewCustomerListDocument, options);
      }
export function useCommandoNewCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommandoNewCustomerListQuery, CommandoNewCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommandoNewCustomerListQuery, CommandoNewCustomerListQueryVariables>(CommandoNewCustomerListDocument, options);
        }
export type CommandoNewCustomerListQueryHookResult = ReturnType<typeof useCommandoNewCustomerListQuery>;
export type CommandoNewCustomerListLazyQueryHookResult = ReturnType<typeof useCommandoNewCustomerListLazyQuery>;
export type CommandoNewCustomerListQueryResult = Apollo.QueryResult<CommandoNewCustomerListQuery, CommandoNewCustomerListQueryVariables>;
export const ControlCustomerListDocument = gql`
    query ControlCustomerList($page: Int!, $take: Int!, $status: CustomerStatus!, $filter: String) {
  ctrl {
    controlCustomerList(page: $page, take: $take, status: $status, filter: $filter) {
      total
      data {
        customer {
          cd
          id
          name
          customerStatus
          customerClassId
          customerPriceClassId
          photo {
            id
            url
          }
          contactName1
          phone11
          address1
          address2
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
          customerClass {
            cd
            name
            description
          }
          customerPriceClass {
            cd
            name
            description
          }
          customerDistributors {
            distributor {
              cd
              name
            }
          }
          attrDetailIds
        }
        mcp {
          cd
          salesman {
            cd
            name
          }
        }
        id
        weekdays
        weeksOfMonth
      }
    }
  }
}
    `;

/**
 * __useControlCustomerListQuery__
 *
 * To run a query within a React component, call `useControlCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useControlCustomerListQuery(baseOptions: Apollo.QueryHookOptions<ControlCustomerListQuery, ControlCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ControlCustomerListQuery, ControlCustomerListQueryVariables>(ControlCustomerListDocument, options);
      }
export function useControlCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ControlCustomerListQuery, ControlCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ControlCustomerListQuery, ControlCustomerListQueryVariables>(ControlCustomerListDocument, options);
        }
export type ControlCustomerListQueryHookResult = ReturnType<typeof useControlCustomerListQuery>;
export type ControlCustomerListLazyQueryHookResult = ReturnType<typeof useControlCustomerListLazyQuery>;
export type ControlCustomerListQueryResult = Apollo.QueryResult<ControlCustomerListQuery, ControlCustomerListQueryVariables>;
export const RejectNewCommandoCustomerDocument = gql`
    mutation RejectNewCommandoCustomer($listId: [Int!]!) {
  ctrl {
    rejectNewCommandoCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type RejectNewCommandoCustomerMutationFn = Apollo.MutationFunction<RejectNewCommandoCustomerMutation, RejectNewCommandoCustomerMutationVariables>;

/**
 * __useRejectNewCommandoCustomerMutation__
 *
 * To run a mutation, you first call `useRejectNewCommandoCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectNewCommandoCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectNewCommandoCustomerMutation, { data, loading, error }] = useRejectNewCommandoCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRejectNewCommandoCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RejectNewCommandoCustomerMutation, RejectNewCommandoCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectNewCommandoCustomerMutation, RejectNewCommandoCustomerMutationVariables>(RejectNewCommandoCustomerDocument, options);
      }
export type RejectNewCommandoCustomerMutationHookResult = ReturnType<typeof useRejectNewCommandoCustomerMutation>;
export type RejectNewCommandoCustomerMutationResult = Apollo.MutationResult<RejectNewCommandoCustomerMutation>;
export type RejectNewCommandoCustomerMutationOptions = Apollo.BaseMutationOptions<RejectNewCommandoCustomerMutation, RejectNewCommandoCustomerMutationVariables>;
export const RejectNewCustomerDocument = gql`
    mutation RejectNewCustomer($listId: [Int!]!) {
  ctrl {
    rejectNewCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type RejectNewCustomerMutationFn = Apollo.MutationFunction<RejectNewCustomerMutation, RejectNewCustomerMutationVariables>;

/**
 * __useRejectNewCustomerMutation__
 *
 * To run a mutation, you first call `useRejectNewCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectNewCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectNewCustomerMutation, { data, loading, error }] = useRejectNewCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRejectNewCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RejectNewCustomerMutation, RejectNewCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectNewCustomerMutation, RejectNewCustomerMutationVariables>(RejectNewCustomerDocument, options);
      }
export type RejectNewCustomerMutationHookResult = ReturnType<typeof useRejectNewCustomerMutation>;
export type RejectNewCustomerMutationResult = Apollo.MutationResult<RejectNewCustomerMutation>;
export type RejectNewCustomerMutationOptions = Apollo.BaseMutationOptions<RejectNewCustomerMutation, RejectNewCustomerMutationVariables>;
export const RejectUpdateCommandoCustomerDocument = gql`
    mutation RejectUpdateCommandoCustomer($listId: [Int!]!) {
  ctrl {
    rejectUpdateCommandoCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type RejectUpdateCommandoCustomerMutationFn = Apollo.MutationFunction<RejectUpdateCommandoCustomerMutation, RejectUpdateCommandoCustomerMutationVariables>;

/**
 * __useRejectUpdateCommandoCustomerMutation__
 *
 * To run a mutation, you first call `useRejectUpdateCommandoCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectUpdateCommandoCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectUpdateCommandoCustomerMutation, { data, loading, error }] = useRejectUpdateCommandoCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRejectUpdateCommandoCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RejectUpdateCommandoCustomerMutation, RejectUpdateCommandoCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectUpdateCommandoCustomerMutation, RejectUpdateCommandoCustomerMutationVariables>(RejectUpdateCommandoCustomerDocument, options);
      }
export type RejectUpdateCommandoCustomerMutationHookResult = ReturnType<typeof useRejectUpdateCommandoCustomerMutation>;
export type RejectUpdateCommandoCustomerMutationResult = Apollo.MutationResult<RejectUpdateCommandoCustomerMutation>;
export type RejectUpdateCommandoCustomerMutationOptions = Apollo.BaseMutationOptions<RejectUpdateCommandoCustomerMutation, RejectUpdateCommandoCustomerMutationVariables>;
export const RejectUpdateCustomerDocument = gql`
    mutation RejectUpdateCustomer($listId: [Int!]!) {
  ctrl {
    rejectUpdateCustomer(listIds: $listId) {
      success
    }
  }
}
    `;
export type RejectUpdateCustomerMutationFn = Apollo.MutationFunction<RejectUpdateCustomerMutation, RejectUpdateCustomerMutationVariables>;

/**
 * __useRejectUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useRejectUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectUpdateCustomerMutation, { data, loading, error }] = useRejectUpdateCustomerMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRejectUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RejectUpdateCustomerMutation, RejectUpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectUpdateCustomerMutation, RejectUpdateCustomerMutationVariables>(RejectUpdateCustomerDocument, options);
      }
export type RejectUpdateCustomerMutationHookResult = ReturnType<typeof useRejectUpdateCustomerMutation>;
export type RejectUpdateCustomerMutationResult = Apollo.MutationResult<RejectUpdateCustomerMutation>;
export type RejectUpdateCustomerMutationOptions = Apollo.BaseMutationOptions<RejectUpdateCustomerMutation, RejectUpdateCustomerMutationVariables>;
export const UpdateCommandoCustomerListDocument = gql`
    query UpdateCommandoCustomerList($page: Int!, $take: Int!, $filter: String) {
  ctrl {
    updateCommandoCustomerList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        customerId
        status
        customerClassId
        customerPriceClassId
        attrDetailIds
        distributorIds
        name
        photo {
          id
          url
        }
        contactName1
        phone11
        phone12
        address1
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUpdateCommandoCustomerListQuery__
 *
 * To run a query within a React component, call `useUpdateCommandoCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommandoCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateCommandoCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateCommandoCustomerListQuery(baseOptions: Apollo.QueryHookOptions<UpdateCommandoCustomerListQuery, UpdateCommandoCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateCommandoCustomerListQuery, UpdateCommandoCustomerListQueryVariables>(UpdateCommandoCustomerListDocument, options);
      }
export function useUpdateCommandoCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateCommandoCustomerListQuery, UpdateCommandoCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateCommandoCustomerListQuery, UpdateCommandoCustomerListQueryVariables>(UpdateCommandoCustomerListDocument, options);
        }
export type UpdateCommandoCustomerListQueryHookResult = ReturnType<typeof useUpdateCommandoCustomerListQuery>;
export type UpdateCommandoCustomerListLazyQueryHookResult = ReturnType<typeof useUpdateCommandoCustomerListLazyQuery>;
export type UpdateCommandoCustomerListQueryResult = Apollo.QueryResult<UpdateCommandoCustomerListQuery, UpdateCommandoCustomerListQueryVariables>;
export const UpdateCustomerListDocument = gql`
    query UpdateCustomerList($page: Int!, $take: Int!, $filter: String) {
  ctrl {
    updateCustomerList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        name
        customerClassId
        mcpLine {
          customer {
            cd
          }
          customerId
          mcp {
            salesman {
              cd
              name
            }
          }
        }
        phone11
        address1
        contactName1
        photo {
          id
          url
        }
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
        weekdays
        weeksOfMonth
        distributorIds
        attrDetailIds
      }
    }
  }
}
    `;

/**
 * __useUpdateCustomerListQuery__
 *
 * To run a query within a React component, call `useUpdateCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateCustomerListQuery(baseOptions: Apollo.QueryHookOptions<UpdateCustomerListQuery, UpdateCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateCustomerListQuery, UpdateCustomerListQueryVariables>(UpdateCustomerListDocument, options);
      }
export function useUpdateCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateCustomerListQuery, UpdateCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateCustomerListQuery, UpdateCustomerListQueryVariables>(UpdateCustomerListDocument, options);
        }
export type UpdateCustomerListQueryHookResult = ReturnType<typeof useUpdateCustomerListQuery>;
export type UpdateCustomerListLazyQueryHookResult = ReturnType<typeof useUpdateCustomerListLazyQuery>;
export type UpdateCustomerListQueryResult = Apollo.QueryResult<UpdateCustomerListQuery, UpdateCustomerListQueryVariables>;
export const AcceptHoldingExchangesDocument = gql`
    mutation AcceptHoldingExchanges($listIds: [Int!]!) {
  ctrl {
    acceptHoldingExchanges(listIds: $listIds) {
      success
      message {
        code
        message
      }
    }
  }
}
    `;
export type AcceptHoldingExchangesMutationFn = Apollo.MutationFunction<AcceptHoldingExchangesMutation, AcceptHoldingExchangesMutationVariables>;

/**
 * __useAcceptHoldingExchangesMutation__
 *
 * To run a mutation, you first call `useAcceptHoldingExchangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptHoldingExchangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptHoldingExchangesMutation, { data, loading, error }] = useAcceptHoldingExchangesMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useAcceptHoldingExchangesMutation(baseOptions?: Apollo.MutationHookOptions<AcceptHoldingExchangesMutation, AcceptHoldingExchangesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptHoldingExchangesMutation, AcceptHoldingExchangesMutationVariables>(AcceptHoldingExchangesDocument, options);
      }
export type AcceptHoldingExchangesMutationHookResult = ReturnType<typeof useAcceptHoldingExchangesMutation>;
export type AcceptHoldingExchangesMutationResult = Apollo.MutationResult<AcceptHoldingExchangesMutation>;
export type AcceptHoldingExchangesMutationOptions = Apollo.BaseMutationOptions<AcceptHoldingExchangesMutation, AcceptHoldingExchangesMutationVariables>;
export const CancelExchangesDocument = gql`
    mutation CancelExchanges($listIds: [Int!]!) {
  ctrl {
    cancelExchanges(listIds: $listIds) {
      success
      message {
        code
        message
      }
    }
  }
}
    `;
export type CancelExchangesMutationFn = Apollo.MutationFunction<CancelExchangesMutation, CancelExchangesMutationVariables>;

/**
 * __useCancelExchangesMutation__
 *
 * To run a mutation, you first call `useCancelExchangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelExchangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelExchangesMutation, { data, loading, error }] = useCancelExchangesMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useCancelExchangesMutation(baseOptions?: Apollo.MutationHookOptions<CancelExchangesMutation, CancelExchangesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelExchangesMutation, CancelExchangesMutationVariables>(CancelExchangesDocument, options);
      }
export type CancelExchangesMutationHookResult = ReturnType<typeof useCancelExchangesMutation>;
export type CancelExchangesMutationResult = Apollo.MutationResult<CancelExchangesMutation>;
export type CancelExchangesMutationOptions = Apollo.BaseMutationOptions<CancelExchangesMutation, CancelExchangesMutationVariables>;
export const CalculateTradeManualDocument = gql`
    mutation CalculateTradeManual {
  ctrl {
    calculateTradeManual {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type CalculateTradeManualMutationFn = Apollo.MutationFunction<CalculateTradeManualMutation, CalculateTradeManualMutationVariables>;

/**
 * __useCalculateTradeManualMutation__
 *
 * To run a mutation, you first call `useCalculateTradeManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateTradeManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateTradeManualMutation, { data, loading, error }] = useCalculateTradeManualMutation({
 *   variables: {
 *   },
 * });
 */
export function useCalculateTradeManualMutation(baseOptions?: Apollo.MutationHookOptions<CalculateTradeManualMutation, CalculateTradeManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateTradeManualMutation, CalculateTradeManualMutationVariables>(CalculateTradeManualDocument, options);
      }
export type CalculateTradeManualMutationHookResult = ReturnType<typeof useCalculateTradeManualMutation>;
export type CalculateTradeManualMutationResult = Apollo.MutationResult<CalculateTradeManualMutation>;
export type CalculateTradeManualMutationOptions = Apollo.BaseMutationOptions<CalculateTradeManualMutation, CalculateTradeManualMutationVariables>;
export const GenerateMcpManualDocument = gql`
    mutation GenerateMCPManual {
  ctrl {
    generateMCPManual {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type GenerateMcpManualMutationFn = Apollo.MutationFunction<GenerateMcpManualMutation, GenerateMcpManualMutationVariables>;

/**
 * __useGenerateMcpManualMutation__
 *
 * To run a mutation, you first call `useGenerateMcpManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMcpManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMcpManualMutation, { data, loading, error }] = useGenerateMcpManualMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMcpManualMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMcpManualMutation, GenerateMcpManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMcpManualMutation, GenerateMcpManualMutationVariables>(GenerateMcpManualDocument, options);
      }
export type GenerateMcpManualMutationHookResult = ReturnType<typeof useGenerateMcpManualMutation>;
export type GenerateMcpManualMutationResult = Apollo.MutationResult<GenerateMcpManualMutation>;
export type GenerateMcpManualMutationOptions = Apollo.BaseMutationOptions<GenerateMcpManualMutation, GenerateMcpManualMutationVariables>;
export const ApproveTradeParticipationDocument = gql`
    mutation ApproveTradeParticipation($listId: [Int!]!) {
  ctrl {
    approveTradeParticipation(listIds: $listId) {
      success
    }
  }
}
    `;
export type ApproveTradeParticipationMutationFn = Apollo.MutationFunction<ApproveTradeParticipationMutation, ApproveTradeParticipationMutationVariables>;

/**
 * __useApproveTradeParticipationMutation__
 *
 * To run a mutation, you first call `useApproveTradeParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTradeParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTradeParticipationMutation, { data, loading, error }] = useApproveTradeParticipationMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useApproveTradeParticipationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTradeParticipationMutation, ApproveTradeParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveTradeParticipationMutation, ApproveTradeParticipationMutationVariables>(ApproveTradeParticipationDocument, options);
      }
export type ApproveTradeParticipationMutationHookResult = ReturnType<typeof useApproveTradeParticipationMutation>;
export type ApproveTradeParticipationMutationResult = Apollo.MutationResult<ApproveTradeParticipationMutation>;
export type ApproveTradeParticipationMutationOptions = Apollo.BaseMutationOptions<ApproveTradeParticipationMutation, ApproveTradeParticipationMutationVariables>;
export const ControlTradeListDocument = gql`
    query ControlTradeList($page: Int!, $take: Int!, $status: ProgramStatus!, $filter: String) {
  ctrl {
    controlTradeList(page: $page, take: $take, status: $status, filter: $filter) {
      total
      data {
        id
        cd
        description
        status
        breakBy
        multiple
        autoLevel
        registerStartDate
        registerEndDate
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useControlTradeListQuery__
 *
 * To run a query within a React component, call `useControlTradeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlTradeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlTradeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useControlTradeListQuery(baseOptions: Apollo.QueryHookOptions<ControlTradeListQuery, ControlTradeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ControlTradeListQuery, ControlTradeListQueryVariables>(ControlTradeListDocument, options);
      }
export function useControlTradeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ControlTradeListQuery, ControlTradeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ControlTradeListQuery, ControlTradeListQueryVariables>(ControlTradeListDocument, options);
        }
export type ControlTradeListQueryHookResult = ReturnType<typeof useControlTradeListQuery>;
export type ControlTradeListLazyQueryHookResult = ReturnType<typeof useControlTradeListLazyQuery>;
export type ControlTradeListQueryResult = Apollo.QueryResult<ControlTradeListQuery, ControlTradeListQueryVariables>;
export const RejectTradeParticipationDocument = gql`
    mutation RejectTradeParticipation($listId: [Int!]!) {
  ctrl {
    rejectTradeParticipation(listIds: $listId) {
      success
    }
  }
}
    `;
export type RejectTradeParticipationMutationFn = Apollo.MutationFunction<RejectTradeParticipationMutation, RejectTradeParticipationMutationVariables>;

/**
 * __useRejectTradeParticipationMutation__
 *
 * To run a mutation, you first call `useRejectTradeParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTradeParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTradeParticipationMutation, { data, loading, error }] = useRejectTradeParticipationMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRejectTradeParticipationMutation(baseOptions?: Apollo.MutationHookOptions<RejectTradeParticipationMutation, RejectTradeParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTradeParticipationMutation, RejectTradeParticipationMutationVariables>(RejectTradeParticipationDocument, options);
      }
export type RejectTradeParticipationMutationHookResult = ReturnType<typeof useRejectTradeParticipationMutation>;
export type RejectTradeParticipationMutationResult = Apollo.MutationResult<RejectTradeParticipationMutation>;
export type RejectTradeParticipationMutationOptions = Apollo.BaseMutationOptions<RejectTradeParticipationMutation, RejectTradeParticipationMutationVariables>;
export const TradeParticipationListByTradeIdDocument = gql`
    query TradeParticipationListByTradeId($page: Int!, $take: Int!, $tradeId: Int!, $status: TradeParticipationStatus!, $filter: String) {
  ctrl {
    tradeParticipationListByTradeId(
      page: $page
      take: $take
      tradeId: $tradeId
      status: $status
      filter: $filter
    ) {
      total
      data {
        id
        tradeId
        customerId
        customer {
          cd
          name
        }
        status
        level
        approvedAt
        createdAt
        lastCalculatedDate
        result
        incentiveResult
      }
    }
  }
}
    `;

/**
 * __useTradeParticipationListByTradeIdQuery__
 *
 * To run a query within a React component, call `useTradeParticipationListByTradeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeParticipationListByTradeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeParticipationListByTradeIdQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      tradeId: // value for 'tradeId'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradeParticipationListByTradeIdQuery(baseOptions: Apollo.QueryHookOptions<TradeParticipationListByTradeIdQuery, TradeParticipationListByTradeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradeParticipationListByTradeIdQuery, TradeParticipationListByTradeIdQueryVariables>(TradeParticipationListByTradeIdDocument, options);
      }
export function useTradeParticipationListByTradeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradeParticipationListByTradeIdQuery, TradeParticipationListByTradeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradeParticipationListByTradeIdQuery, TradeParticipationListByTradeIdQueryVariables>(TradeParticipationListByTradeIdDocument, options);
        }
export type TradeParticipationListByTradeIdQueryHookResult = ReturnType<typeof useTradeParticipationListByTradeIdQuery>;
export type TradeParticipationListByTradeIdLazyQueryHookResult = ReturnType<typeof useTradeParticipationListByTradeIdLazyQuery>;
export type TradeParticipationListByTradeIdQueryResult = Apollo.QueryResult<TradeParticipationListByTradeIdQuery, TradeParticipationListByTradeIdQueryVariables>;
export const VisualPhotoListByVisualIdDocument = gql`
    query VisualPhotoListByVisualId($page: Int!, $take: Int!, $visualId: Int!, $filter: String) {
  ctrl {
    visualPhotoListByVisualId(
      page: $page
      take: $take
      visualId: $visualId
      filter: $filter
    ) {
      total
      data {
        id
        visualId
        checkInId
        photoId
        isPassed
        photo {
          id
          url
        }
        visual {
          id
          cd
          name
          description
          status
          startDate
          endDate
          trade {
            id
            cd
            endDate
          }
          visualApplicableSalesRegions {
            salesRegion {
              id
              cd
              description
            }
          }
        }
        checkIn {
          customer {
            id
            cd
            name
          }
          salesman {
            cd
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVisualPhotoListByVisualIdQuery__
 *
 * To run a query within a React component, call `useVisualPhotoListByVisualIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualPhotoListByVisualIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualPhotoListByVisualIdQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      visualId: // value for 'visualId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVisualPhotoListByVisualIdQuery(baseOptions: Apollo.QueryHookOptions<VisualPhotoListByVisualIdQuery, VisualPhotoListByVisualIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualPhotoListByVisualIdQuery, VisualPhotoListByVisualIdQueryVariables>(VisualPhotoListByVisualIdDocument, options);
      }
export function useVisualPhotoListByVisualIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualPhotoListByVisualIdQuery, VisualPhotoListByVisualIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualPhotoListByVisualIdQuery, VisualPhotoListByVisualIdQueryVariables>(VisualPhotoListByVisualIdDocument, options);
        }
export type VisualPhotoListByVisualIdQueryHookResult = ReturnType<typeof useVisualPhotoListByVisualIdQuery>;
export type VisualPhotoListByVisualIdLazyQueryHookResult = ReturnType<typeof useVisualPhotoListByVisualIdLazyQuery>;
export type VisualPhotoListByVisualIdQueryResult = Apollo.QueryResult<VisualPhotoListByVisualIdQuery, VisualPhotoListByVisualIdQueryVariables>;
export const ControlVisualReleaseDocument = gql`
    query ControlVisualRelease($page: Int!, $take: Int!, $filter: String) {
  ctrl {
    controlVisualRelease(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
        description
        status
        startDate
        endDate
        multiple
        minPhotos
        maxPhotos
      }
    }
  }
}
    `;

/**
 * __useControlVisualReleaseQuery__
 *
 * To run a query within a React component, call `useControlVisualReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlVisualReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlVisualReleaseQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useControlVisualReleaseQuery(baseOptions: Apollo.QueryHookOptions<ControlVisualReleaseQuery, ControlVisualReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ControlVisualReleaseQuery, ControlVisualReleaseQueryVariables>(ControlVisualReleaseDocument, options);
      }
export function useControlVisualReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ControlVisualReleaseQuery, ControlVisualReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ControlVisualReleaseQuery, ControlVisualReleaseQueryVariables>(ControlVisualReleaseDocument, options);
        }
export type ControlVisualReleaseQueryHookResult = ReturnType<typeof useControlVisualReleaseQuery>;
export type ControlVisualReleaseLazyQueryHookResult = ReturnType<typeof useControlVisualReleaseLazyQuery>;
export type ControlVisualReleaseQueryResult = Apollo.QueryResult<ControlVisualReleaseQuery, ControlVisualReleaseQueryVariables>;
export const EvaluatePhotoVisualDocument = gql`
    mutation EvaluatePhotoVisual($listIds: [Int!]!) {
  ctrl {
    evaluatePhotoVisual(listIds: $listIds) {
      success
      message {
        code
        message
      }
    }
  }
}
    `;
export type EvaluatePhotoVisualMutationFn = Apollo.MutationFunction<EvaluatePhotoVisualMutation, EvaluatePhotoVisualMutationVariables>;

/**
 * __useEvaluatePhotoVisualMutation__
 *
 * To run a mutation, you first call `useEvaluatePhotoVisualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluatePhotoVisualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluatePhotoVisualMutation, { data, loading, error }] = useEvaluatePhotoVisualMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useEvaluatePhotoVisualMutation(baseOptions?: Apollo.MutationHookOptions<EvaluatePhotoVisualMutation, EvaluatePhotoVisualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EvaluatePhotoVisualMutation, EvaluatePhotoVisualMutationVariables>(EvaluatePhotoVisualDocument, options);
      }
export type EvaluatePhotoVisualMutationHookResult = ReturnType<typeof useEvaluatePhotoVisualMutation>;
export type EvaluatePhotoVisualMutationResult = Apollo.MutationResult<EvaluatePhotoVisualMutation>;
export type EvaluatePhotoVisualMutationOptions = Apollo.BaseMutationOptions<EvaluatePhotoVisualMutation, EvaluatePhotoVisualMutationVariables>;
export const CreateOrUpdateCustomerClassDocument = gql`
    mutation CreateOrUpdateCustomerClass($data: CustomerClassInput!) {
  md {
    createOrUpdateCustomerClass(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CustomerClassFragment
      }
    }
  }
}
    ${CustomerClassFragmentDoc}`;
export type CreateOrUpdateCustomerClassMutationFn = Apollo.MutationFunction<CreateOrUpdateCustomerClassMutation, CreateOrUpdateCustomerClassMutationVariables>;

/**
 * __useCreateOrUpdateCustomerClassMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCustomerClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCustomerClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCustomerClassMutation, { data, loading, error }] = useCreateOrUpdateCustomerClassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCustomerClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCustomerClassMutation, CreateOrUpdateCustomerClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCustomerClassMutation, CreateOrUpdateCustomerClassMutationVariables>(CreateOrUpdateCustomerClassDocument, options);
      }
export type CreateOrUpdateCustomerClassMutationHookResult = ReturnType<typeof useCreateOrUpdateCustomerClassMutation>;
export type CreateOrUpdateCustomerClassMutationResult = Apollo.MutationResult<CreateOrUpdateCustomerClassMutation>;
export type CreateOrUpdateCustomerClassMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCustomerClassMutation, CreateOrUpdateCustomerClassMutationVariables>;
export const CustomerClassesDocument = gql`
    query CustomerClasses {
  md {
    customerClasses {
      ...CustomerClassFragment
    }
  }
}
    ${CustomerClassFragmentDoc}`;

/**
 * __useCustomerClassesQuery__
 *
 * To run a query within a React component, call `useCustomerClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerClassesQuery(baseOptions?: Apollo.QueryHookOptions<CustomerClassesQuery, CustomerClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerClassesQuery, CustomerClassesQueryVariables>(CustomerClassesDocument, options);
      }
export function useCustomerClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerClassesQuery, CustomerClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerClassesQuery, CustomerClassesQueryVariables>(CustomerClassesDocument, options);
        }
export type CustomerClassesQueryHookResult = ReturnType<typeof useCustomerClassesQuery>;
export type CustomerClassesLazyQueryHookResult = ReturnType<typeof useCustomerClassesLazyQuery>;
export type CustomerClassesQueryResult = Apollo.QueryResult<CustomerClassesQuery, CustomerClassesQueryVariables>;
export const CustomerClassDocument = gql`
    query CustomerClass($id: Int!) {
  md {
    customerClass(id: $id) {
      ...CustomerClassFragment
    }
  }
}
    ${CustomerClassFragmentDoc}`;

/**
 * __useCustomerClassQuery__
 *
 * To run a query within a React component, call `useCustomerClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerClassQuery(baseOptions: Apollo.QueryHookOptions<CustomerClassQuery, CustomerClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerClassQuery, CustomerClassQueryVariables>(CustomerClassDocument, options);
      }
export function useCustomerClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerClassQuery, CustomerClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerClassQuery, CustomerClassQueryVariables>(CustomerClassDocument, options);
        }
export type CustomerClassQueryHookResult = ReturnType<typeof useCustomerClassQuery>;
export type CustomerClassLazyQueryHookResult = ReturnType<typeof useCustomerClassLazyQuery>;
export type CustomerClassQueryResult = Apollo.QueryResult<CustomerClassQuery, CustomerClassQueryVariables>;
export const DeleteCustomerClassDocument = gql`
    mutation DeleteCustomerClass($id: Int!) {
  md {
    deleteCustomerClass(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteCustomerClassMutationFn = Apollo.MutationFunction<DeleteCustomerClassMutation, DeleteCustomerClassMutationVariables>;

/**
 * __useDeleteCustomerClassMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerClassMutation, { data, loading, error }] = useDeleteCustomerClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerClassMutation, DeleteCustomerClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerClassMutation, DeleteCustomerClassMutationVariables>(DeleteCustomerClassDocument, options);
      }
export type DeleteCustomerClassMutationHookResult = ReturnType<typeof useDeleteCustomerClassMutation>;
export type DeleteCustomerClassMutationResult = Apollo.MutationResult<DeleteCustomerClassMutation>;
export type DeleteCustomerClassMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerClassMutation, DeleteCustomerClassMutationVariables>;
export const CreateOrUpdateCustomerPriceClassDocument = gql`
    mutation CreateOrUpdateCustomerPriceClass($data: CustomerPriceClassInput!) {
  md {
    createOrUpdateCustomerPriceClass(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CustomerPriceClassFragment
      }
    }
  }
}
    ${CustomerPriceClassFragmentDoc}`;
export type CreateOrUpdateCustomerPriceClassMutationFn = Apollo.MutationFunction<CreateOrUpdateCustomerPriceClassMutation, CreateOrUpdateCustomerPriceClassMutationVariables>;

/**
 * __useCreateOrUpdateCustomerPriceClassMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCustomerPriceClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCustomerPriceClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCustomerPriceClassMutation, { data, loading, error }] = useCreateOrUpdateCustomerPriceClassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCustomerPriceClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCustomerPriceClassMutation, CreateOrUpdateCustomerPriceClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCustomerPriceClassMutation, CreateOrUpdateCustomerPriceClassMutationVariables>(CreateOrUpdateCustomerPriceClassDocument, options);
      }
export type CreateOrUpdateCustomerPriceClassMutationHookResult = ReturnType<typeof useCreateOrUpdateCustomerPriceClassMutation>;
export type CreateOrUpdateCustomerPriceClassMutationResult = Apollo.MutationResult<CreateOrUpdateCustomerPriceClassMutation>;
export type CreateOrUpdateCustomerPriceClassMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCustomerPriceClassMutation, CreateOrUpdateCustomerPriceClassMutationVariables>;
export const CustomerPriceClassesDocument = gql`
    query CustomerPriceClasses {
  md {
    customerPriceClasses {
      ...CustomerPriceClassFragment
    }
  }
}
    ${CustomerPriceClassFragmentDoc}`;

/**
 * __useCustomerPriceClassesQuery__
 *
 * To run a query within a React component, call `useCustomerPriceClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPriceClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPriceClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerPriceClassesQuery(baseOptions?: Apollo.QueryHookOptions<CustomerPriceClassesQuery, CustomerPriceClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPriceClassesQuery, CustomerPriceClassesQueryVariables>(CustomerPriceClassesDocument, options);
      }
export function useCustomerPriceClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPriceClassesQuery, CustomerPriceClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPriceClassesQuery, CustomerPriceClassesQueryVariables>(CustomerPriceClassesDocument, options);
        }
export type CustomerPriceClassesQueryHookResult = ReturnType<typeof useCustomerPriceClassesQuery>;
export type CustomerPriceClassesLazyQueryHookResult = ReturnType<typeof useCustomerPriceClassesLazyQuery>;
export type CustomerPriceClassesQueryResult = Apollo.QueryResult<CustomerPriceClassesQuery, CustomerPriceClassesQueryVariables>;
export const CustomerPriceClassDocument = gql`
    query CustomerPriceClass($id: Int!) {
  md {
    customerPriceClass(id: $id) {
      ...CustomerPriceClassFragment
    }
  }
}
    ${CustomerPriceClassFragmentDoc}`;

/**
 * __useCustomerPriceClassQuery__
 *
 * To run a query within a React component, call `useCustomerPriceClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPriceClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPriceClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerPriceClassQuery(baseOptions: Apollo.QueryHookOptions<CustomerPriceClassQuery, CustomerPriceClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPriceClassQuery, CustomerPriceClassQueryVariables>(CustomerPriceClassDocument, options);
      }
export function useCustomerPriceClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPriceClassQuery, CustomerPriceClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPriceClassQuery, CustomerPriceClassQueryVariables>(CustomerPriceClassDocument, options);
        }
export type CustomerPriceClassQueryHookResult = ReturnType<typeof useCustomerPriceClassQuery>;
export type CustomerPriceClassLazyQueryHookResult = ReturnType<typeof useCustomerPriceClassLazyQuery>;
export type CustomerPriceClassQueryResult = Apollo.QueryResult<CustomerPriceClassQuery, CustomerPriceClassQueryVariables>;
export const DeleteCustomerPriceClassDocument = gql`
    mutation DeleteCustomerPriceClass($id: Int!) {
  md {
    deleteCustomerPriceClass(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteCustomerPriceClassMutationFn = Apollo.MutationFunction<DeleteCustomerPriceClassMutation, DeleteCustomerPriceClassMutationVariables>;

/**
 * __useDeleteCustomerPriceClassMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerPriceClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerPriceClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerPriceClassMutation, { data, loading, error }] = useDeleteCustomerPriceClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerPriceClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerPriceClassMutation, DeleteCustomerPriceClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerPriceClassMutation, DeleteCustomerPriceClassMutationVariables>(DeleteCustomerPriceClassDocument, options);
      }
export type DeleteCustomerPriceClassMutationHookResult = ReturnType<typeof useDeleteCustomerPriceClassMutation>;
export type DeleteCustomerPriceClassMutationResult = Apollo.MutationResult<DeleteCustomerPriceClassMutation>;
export type DeleteCustomerPriceClassMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerPriceClassMutation, DeleteCustomerPriceClassMutationVariables>;
export const ActiveCustomerDocument = gql`
    mutation ActiveCustomer($id: Int!) {
  md {
    activeCustomer(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ActiveCustomerMutationFn = Apollo.MutationFunction<ActiveCustomerMutation, ActiveCustomerMutationVariables>;

/**
 * __useActiveCustomerMutation__
 *
 * To run a mutation, you first call `useActiveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeCustomerMutation, { data, loading, error }] = useActiveCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ActiveCustomerMutation, ActiveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveCustomerMutation, ActiveCustomerMutationVariables>(ActiveCustomerDocument, options);
      }
export type ActiveCustomerMutationHookResult = ReturnType<typeof useActiveCustomerMutation>;
export type ActiveCustomerMutationResult = Apollo.MutationResult<ActiveCustomerMutation>;
export type ActiveCustomerMutationOptions = Apollo.BaseMutationOptions<ActiveCustomerMutation, ActiveCustomerMutationVariables>;
export const CreateOrUpdateCustomerDocument = gql`
    mutation CreateOrUpdateCustomer($data: CustomerInput!) {
  md {
    createOrUpdateCustomer(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...CustomerFragment
      }
    }
  }
}
    ${CustomerFragmentDoc}`;
export type CreateOrUpdateCustomerMutationFn = Apollo.MutationFunction<CreateOrUpdateCustomerMutation, CreateOrUpdateCustomerMutationVariables>;

/**
 * __useCreateOrUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCustomerMutation, { data, loading, error }] = useCreateOrUpdateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCustomerMutation, CreateOrUpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCustomerMutation, CreateOrUpdateCustomerMutationVariables>(CreateOrUpdateCustomerDocument, options);
      }
export type CreateOrUpdateCustomerMutationHookResult = ReturnType<typeof useCreateOrUpdateCustomerMutation>;
export type CreateOrUpdateCustomerMutationResult = Apollo.MutationResult<CreateOrUpdateCustomerMutation>;
export type CreateOrUpdateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCustomerMutation, CreateOrUpdateCustomerMutationVariables>;
export const CustomerListDocument = gql`
    query CustomerList($page: Int!, $take: Int!, $filter: String, $name: String, $phone: String, $customerClassId: Int, $customerStatus: CustomerStatus,
    #  $createdDate: Date, $provinceId : Int, $distributorId: Int
     ) {
  md {
    customerList(
      page: $page
      take: $take
      filter: $filter
      name: $name
      phone: $phone
      customerClassId: $customerClassId
      customerStatus: $customerStatus
      # createdDate: $createdDate
      # provinceId: $provinceId
      # distributorId: $distributorId
    ) {
      total
      data {
        id
        cd
        name
        lat
        lng
        customerStatus
        customerClassId
        customerPriceClassId
        isAlive
        photo {
          id
          url
        }
        isEditPhoto
        contactName1
        phone11
        address1
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
        createdAt
        modifiedAt
      }
    }
  }
}
    `;

/**
 * __useCustomerListQuery__
 *
 * To run a query within a React component, call `useCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      customerClassId: // value for 'customerClassId'
 *      customerStatus: // value for 'customerStatus'
 *   },
 * });
 */
export function useCustomerListQuery(baseOptions: Apollo.QueryHookOptions<CustomerListQuery, CustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerListQuery, CustomerListQueryVariables>(CustomerListDocument, options);
      }
export function useCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerListQuery, CustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerListQuery, CustomerListQueryVariables>(CustomerListDocument, options);
        }
export type CustomerListQueryHookResult = ReturnType<typeof useCustomerListQuery>;
export type CustomerListLazyQueryHookResult = ReturnType<typeof useCustomerListLazyQuery>;
export type CustomerListQueryResult = Apollo.QueryResult<CustomerListQuery, CustomerListQueryVariables>;
export const CustomerDocument = gql`
    query Customer($id: Int!) {
  md {
    customer(id: $id) {
      ...CustomerFragment
      attrDetailIds
      customerDistributors {
        id
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const EmployeeDashboardDocument = gql`
    query EmployeeDashboard {
  dbd {
    employeeDashboard {
      salesmanId
      employee {
        id
        cd
        name
      }
      total
    }
  }
}
    `;

/**
 * __useEmployeeDashboardQuery__
 *
 * To run a query within a React component, call `useEmployeeDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeDashboardQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeDashboardQuery, EmployeeDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDashboardQuery, EmployeeDashboardQueryVariables>(EmployeeDashboardDocument, options);
      }
export function useEmployeeDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDashboardQuery, EmployeeDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDashboardQuery, EmployeeDashboardQueryVariables>(EmployeeDashboardDocument, options);
        }
export type EmployeeDashboardQueryHookResult = ReturnType<typeof useEmployeeDashboardQuery>;
export type EmployeeDashboardLazyQueryHookResult = ReturnType<typeof useEmployeeDashboardLazyQuery>;
export type EmployeeDashboardQueryResult = Apollo.QueryResult<EmployeeDashboardQuery, EmployeeDashboardQueryVariables>;
export const ProductDashboardDocument = gql`
    query ProductDashboard {
  dbd {
    productDashboard {
      product {
        id
        name
      }
      totalPrice
    }
  }
}
    `;

/**
 * __useProductDashboardQuery__
 *
 * To run a query within a React component, call `useProductDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ProductDashboardQuery, ProductDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductDashboardQuery, ProductDashboardQueryVariables>(ProductDashboardDocument, options);
      }
export function useProductDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDashboardQuery, ProductDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductDashboardQuery, ProductDashboardQueryVariables>(ProductDashboardDocument, options);
        }
export type ProductDashboardQueryHookResult = ReturnType<typeof useProductDashboardQuery>;
export type ProductDashboardLazyQueryHookResult = ReturnType<typeof useProductDashboardLazyQuery>;
export type ProductDashboardQueryResult = Apollo.QueryResult<ProductDashboardQuery, ProductDashboardQueryVariables>;
export const CancelDeliveryNoteDocument = gql`
    mutation CancelDeliveryNote($id: Int!) {
  o {
    cancelDeliveryNote(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...DeliveryNoteFragment
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;
export type CancelDeliveryNoteMutationFn = Apollo.MutationFunction<CancelDeliveryNoteMutation, CancelDeliveryNoteMutationVariables>;

/**
 * __useCancelDeliveryNoteMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryNoteMutation, { data, loading, error }] = useCancelDeliveryNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelDeliveryNoteMutation(baseOptions?: Apollo.MutationHookOptions<CancelDeliveryNoteMutation, CancelDeliveryNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDeliveryNoteMutation, CancelDeliveryNoteMutationVariables>(CancelDeliveryNoteDocument, options);
      }
export type CancelDeliveryNoteMutationHookResult = ReturnType<typeof useCancelDeliveryNoteMutation>;
export type CancelDeliveryNoteMutationResult = Apollo.MutationResult<CancelDeliveryNoteMutation>;
export type CancelDeliveryNoteMutationOptions = Apollo.BaseMutationOptions<CancelDeliveryNoteMutation, CancelDeliveryNoteMutationVariables>;
export const CancelInventoryDeliveryDocument = gql`
    mutation CancelInventoryDelivery($id: Int!) {
  o {
    cancelInventoryDelivery(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
      }
    }
  }
}
    `;
export type CancelInventoryDeliveryMutationFn = Apollo.MutationFunction<CancelInventoryDeliveryMutation, CancelInventoryDeliveryMutationVariables>;

/**
 * __useCancelInventoryDeliveryMutation__
 *
 * To run a mutation, you first call `useCancelInventoryDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInventoryDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInventoryDeliveryMutation, { data, loading, error }] = useCancelInventoryDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelInventoryDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CancelInventoryDeliveryMutation, CancelInventoryDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInventoryDeliveryMutation, CancelInventoryDeliveryMutationVariables>(CancelInventoryDeliveryDocument, options);
      }
export type CancelInventoryDeliveryMutationHookResult = ReturnType<typeof useCancelInventoryDeliveryMutation>;
export type CancelInventoryDeliveryMutationResult = Apollo.MutationResult<CancelInventoryDeliveryMutation>;
export type CancelInventoryDeliveryMutationOptions = Apollo.BaseMutationOptions<CancelInventoryDeliveryMutation, CancelInventoryDeliveryMutationVariables>;
export const CompleteDeliveryNoteDocument = gql`
    mutation CompleteDeliveryNote($id: Int!) {
  o {
    completeDeliveryNote(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...DeliveryNoteFragment
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;
export type CompleteDeliveryNoteMutationFn = Apollo.MutationFunction<CompleteDeliveryNoteMutation, CompleteDeliveryNoteMutationVariables>;

/**
 * __useCompleteDeliveryNoteMutation__
 *
 * To run a mutation, you first call `useCompleteDeliveryNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDeliveryNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDeliveryNoteMutation, { data, loading, error }] = useCompleteDeliveryNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteDeliveryNoteMutation(baseOptions?: Apollo.MutationHookOptions<CompleteDeliveryNoteMutation, CompleteDeliveryNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteDeliveryNoteMutation, CompleteDeliveryNoteMutationVariables>(CompleteDeliveryNoteDocument, options);
      }
export type CompleteDeliveryNoteMutationHookResult = ReturnType<typeof useCompleteDeliveryNoteMutation>;
export type CompleteDeliveryNoteMutationResult = Apollo.MutationResult<CompleteDeliveryNoteMutation>;
export type CompleteDeliveryNoteMutationOptions = Apollo.BaseMutationOptions<CompleteDeliveryNoteMutation, CompleteDeliveryNoteMutationVariables>;
export const CreateOrUpdateDeliveryNoteOrderDocument = gql`
    mutation CreateOrUpdateDeliveryNoteOrder($data: DeliveryNoteInput!) {
  o {
    createOrUpdateDeliveryNoteOrder(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...DeliveryNoteFragment
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;
export type CreateOrUpdateDeliveryNoteOrderMutationFn = Apollo.MutationFunction<CreateOrUpdateDeliveryNoteOrderMutation, CreateOrUpdateDeliveryNoteOrderMutationVariables>;

/**
 * __useCreateOrUpdateDeliveryNoteOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDeliveryNoteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDeliveryNoteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDeliveryNoteOrderMutation, { data, loading, error }] = useCreateOrUpdateDeliveryNoteOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateDeliveryNoteOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDeliveryNoteOrderMutation, CreateOrUpdateDeliveryNoteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDeliveryNoteOrderMutation, CreateOrUpdateDeliveryNoteOrderMutationVariables>(CreateOrUpdateDeliveryNoteOrderDocument, options);
      }
export type CreateOrUpdateDeliveryNoteOrderMutationHookResult = ReturnType<typeof useCreateOrUpdateDeliveryNoteOrderMutation>;
export type CreateOrUpdateDeliveryNoteOrderMutationResult = Apollo.MutationResult<CreateOrUpdateDeliveryNoteOrderMutation>;
export type CreateOrUpdateDeliveryNoteOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDeliveryNoteOrderMutation, CreateOrUpdateDeliveryNoteOrderMutationVariables>;
export const CreateInventoryDeliveryNoteOrderDocument = gql`
    mutation CreateInventoryDeliveryNoteOrder($data: InventoryDeliveryInput!) {
  o {
    createInventoryDelivery(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        description
        deliveryNotes {
          ...DeliveryNoteFragment
        }
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;
export type CreateInventoryDeliveryNoteOrderMutationFn = Apollo.MutationFunction<CreateInventoryDeliveryNoteOrderMutation, CreateInventoryDeliveryNoteOrderMutationVariables>;

/**
 * __useCreateInventoryDeliveryNoteOrderMutation__
 *
 * To run a mutation, you first call `useCreateInventoryDeliveryNoteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryDeliveryNoteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryDeliveryNoteOrderMutation, { data, loading, error }] = useCreateInventoryDeliveryNoteOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInventoryDeliveryNoteOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryDeliveryNoteOrderMutation, CreateInventoryDeliveryNoteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryDeliveryNoteOrderMutation, CreateInventoryDeliveryNoteOrderMutationVariables>(CreateInventoryDeliveryNoteOrderDocument, options);
      }
export type CreateInventoryDeliveryNoteOrderMutationHookResult = ReturnType<typeof useCreateInventoryDeliveryNoteOrderMutation>;
export type CreateInventoryDeliveryNoteOrderMutationResult = Apollo.MutationResult<CreateInventoryDeliveryNoteOrderMutation>;
export type CreateInventoryDeliveryNoteOrderMutationOptions = Apollo.BaseMutationOptions<CreateInventoryDeliveryNoteOrderMutation, CreateInventoryDeliveryNoteOrderMutationVariables>;
export const DeliveryNotesDocument = gql`
    query deliveryNotes($page: Int!, $take: Int!, $distributorCd: String, $orderCd: String, $shipperCd: String, $startDate: Date, $endDate: Date) {
  o {
    deliveryNotes(
      page: $page
      take: $take
      distributorCd: $distributorCd
      orderCd: $orderCd
      shipperCd: $shipperCd
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      data {
        ...DeliveryNoteFragment
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;

/**
 * __useDeliveryNotesQuery__
 *
 * To run a query within a React component, call `useDeliveryNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryNotesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorCd: // value for 'distributorCd'
 *      orderCd: // value for 'orderCd'
 *      shipperCd: // value for 'shipperCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDeliveryNotesQuery(baseOptions: Apollo.QueryHookOptions<DeliveryNotesQuery, DeliveryNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryNotesQuery, DeliveryNotesQueryVariables>(DeliveryNotesDocument, options);
      }
export function useDeliveryNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryNotesQuery, DeliveryNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryNotesQuery, DeliveryNotesQueryVariables>(DeliveryNotesDocument, options);
        }
export type DeliveryNotesQueryHookResult = ReturnType<typeof useDeliveryNotesQuery>;
export type DeliveryNotesLazyQueryHookResult = ReturnType<typeof useDeliveryNotesLazyQuery>;
export type DeliveryNotesQueryResult = Apollo.QueryResult<DeliveryNotesQuery, DeliveryNotesQueryVariables>;
export const DeliveryNoteDocument = gql`
    query deliveryNote($id: Int!) {
  o {
    deliveryNote(id: $id) {
      ...DeliveryNoteFragment
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;

/**
 * __useDeliveryNoteQuery__
 *
 * To run a query within a React component, call `useDeliveryNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliveryNoteQuery(baseOptions: Apollo.QueryHookOptions<DeliveryNoteQuery, DeliveryNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryNoteQuery, DeliveryNoteQueryVariables>(DeliveryNoteDocument, options);
      }
export function useDeliveryNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryNoteQuery, DeliveryNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryNoteQuery, DeliveryNoteQueryVariables>(DeliveryNoteDocument, options);
        }
export type DeliveryNoteQueryHookResult = ReturnType<typeof useDeliveryNoteQuery>;
export type DeliveryNoteLazyQueryHookResult = ReturnType<typeof useDeliveryNoteLazyQuery>;
export type DeliveryNoteQueryResult = Apollo.QueryResult<DeliveryNoteQuery, DeliveryNoteQueryVariables>;
export const InventoryDeliveryListDocument = gql`
    query InventoryDeliveryList($page: Int!, $take: Int!, $orderCd: String, $shipperCd: String, $distributorCd: String, $startDate: Date, $endDate: Date) {
  o {
    inventoryDeliveryList(
      take: $take
      page: $page
      orderCd: $orderCd
      shipperCd: $shipperCd
      distributorCd: $distributorCd
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      data {
        id
        description
        shipper {
          cd
          id
          name
          distributor {
            cd
            name
          }
        }
        deliveryNotes {
          ...DeliveryNoteFragment
        }
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;

/**
 * __useInventoryDeliveryListQuery__
 *
 * To run a query within a React component, call `useInventoryDeliveryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryDeliveryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryDeliveryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      orderCd: // value for 'orderCd'
 *      shipperCd: // value for 'shipperCd'
 *      distributorCd: // value for 'distributorCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInventoryDeliveryListQuery(baseOptions: Apollo.QueryHookOptions<InventoryDeliveryListQuery, InventoryDeliveryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryDeliveryListQuery, InventoryDeliveryListQueryVariables>(InventoryDeliveryListDocument, options);
      }
export function useInventoryDeliveryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryDeliveryListQuery, InventoryDeliveryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryDeliveryListQuery, InventoryDeliveryListQueryVariables>(InventoryDeliveryListDocument, options);
        }
export type InventoryDeliveryListQueryHookResult = ReturnType<typeof useInventoryDeliveryListQuery>;
export type InventoryDeliveryListLazyQueryHookResult = ReturnType<typeof useInventoryDeliveryListLazyQuery>;
export type InventoryDeliveryListQueryResult = Apollo.QueryResult<InventoryDeliveryListQuery, InventoryDeliveryListQueryVariables>;
export const InventoryDeliveryDocument = gql`
    query InventoryDelivery($id: Int!) {
  o {
    inventoryDelivery(id: $id) {
      id
      description
      shipper {
        cd
        id
        name
        distributor {
          cd
          name
        }
      }
      deliveryNotes {
        ...DeliveryNoteFragment
      }
    }
  }
}
    ${DeliveryNoteFragmentDoc}`;

/**
 * __useInventoryDeliveryQuery__
 *
 * To run a query within a React component, call `useInventoryDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryDeliveryQuery(baseOptions: Apollo.QueryHookOptions<InventoryDeliveryQuery, InventoryDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryDeliveryQuery, InventoryDeliveryQueryVariables>(InventoryDeliveryDocument, options);
      }
export function useInventoryDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryDeliveryQuery, InventoryDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryDeliveryQuery, InventoryDeliveryQueryVariables>(InventoryDeliveryDocument, options);
        }
export type InventoryDeliveryQueryHookResult = ReturnType<typeof useInventoryDeliveryQuery>;
export type InventoryDeliveryLazyQueryHookResult = ReturnType<typeof useInventoryDeliveryLazyQuery>;
export type InventoryDeliveryQueryResult = Apollo.QueryResult<InventoryDeliveryQuery, InventoryDeliveryQueryVariables>;
export const GetPurchaseReceiptsByTypeAsyncDocument = gql`
    query GetPurchaseReceiptsByTypeAsync($page: Int!, $take: Int!, $filter: String) {
  o {
    getPurchaseReceiptsByTypeAsync(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        orderDate
        orderStatus
        orderTotal
        description
        vendor {
          id
          name
        }
        distributor {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseReceiptsByTypeAsyncQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReceiptsByTypeAsyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReceiptsByTypeAsyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReceiptsByTypeAsyncQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPurchaseReceiptsByTypeAsyncQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseReceiptsByTypeAsyncQuery, GetPurchaseReceiptsByTypeAsyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchaseReceiptsByTypeAsyncQuery, GetPurchaseReceiptsByTypeAsyncQueryVariables>(GetPurchaseReceiptsByTypeAsyncDocument, options);
      }
export function useGetPurchaseReceiptsByTypeAsyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseReceiptsByTypeAsyncQuery, GetPurchaseReceiptsByTypeAsyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchaseReceiptsByTypeAsyncQuery, GetPurchaseReceiptsByTypeAsyncQueryVariables>(GetPurchaseReceiptsByTypeAsyncDocument, options);
        }
export type GetPurchaseReceiptsByTypeAsyncQueryHookResult = ReturnType<typeof useGetPurchaseReceiptsByTypeAsyncQuery>;
export type GetPurchaseReceiptsByTypeAsyncLazyQueryHookResult = ReturnType<typeof useGetPurchaseReceiptsByTypeAsyncLazyQuery>;
export type GetPurchaseReceiptsByTypeAsyncQueryResult = Apollo.QueryResult<GetPurchaseReceiptsByTypeAsyncQuery, GetPurchaseReceiptsByTypeAsyncQueryVariables>;
export const SalesOrderByTypeListDocument = gql`
    query SalesOrderByTypeList($page: Int!, $take: Int!, $filter: String) {
  o {
    salesOrderByTypeList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        orderDate
        orderStatus
        orderSource
        orderTotal
        description
        customer {
          id
          name
          address1
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
        distributor {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderByTypeListQuery__
 *
 * To run a query within a React component, call `useSalesOrderByTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderByTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderByTypeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSalesOrderByTypeListQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderByTypeListQuery, SalesOrderByTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderByTypeListQuery, SalesOrderByTypeListQueryVariables>(SalesOrderByTypeListDocument, options);
      }
export function useSalesOrderByTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderByTypeListQuery, SalesOrderByTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderByTypeListQuery, SalesOrderByTypeListQueryVariables>(SalesOrderByTypeListDocument, options);
        }
export type SalesOrderByTypeListQueryHookResult = ReturnType<typeof useSalesOrderByTypeListQuery>;
export type SalesOrderByTypeListLazyQueryHookResult = ReturnType<typeof useSalesOrderByTypeListLazyQuery>;
export type SalesOrderByTypeListQueryResult = Apollo.QueryResult<SalesOrderByTypeListQuery, SalesOrderByTypeListQueryVariables>;
export const GetAllWorkGroupFilterDocument = gql`
    query GetAllWorkGroupFilter {
  dm {
    getAllWorkGroupFilter {
      cd
      name
      workGroups {
        cd
        name
        workGroups {
          cd
          name
          workGroups {
            cd
            name
            workGroups {
              cd
              name
              workGroups {
                cd
                name
              }
              salesRegions {
                id
                cd
                name
              }
            }
            salesRegions {
              id
              cd
              name
            }
          }
          salesRegions {
            id
            cd
            name
          }
        }
        salesRegions {
          id
          cd
          name
        }
      }
      salesRegions {
        id
        cd
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllWorkGroupFilterQuery__
 *
 * To run a query within a React component, call `useGetAllWorkGroupFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWorkGroupFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWorkGroupFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllWorkGroupFilterQuery(baseOptions?: Apollo.QueryHookOptions<GetAllWorkGroupFilterQuery, GetAllWorkGroupFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllWorkGroupFilterQuery, GetAllWorkGroupFilterQueryVariables>(GetAllWorkGroupFilterDocument, options);
      }
export function useGetAllWorkGroupFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllWorkGroupFilterQuery, GetAllWorkGroupFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllWorkGroupFilterQuery, GetAllWorkGroupFilterQueryVariables>(GetAllWorkGroupFilterDocument, options);
        }
export type GetAllWorkGroupFilterQueryHookResult = ReturnType<typeof useGetAllWorkGroupFilterQuery>;
export type GetAllWorkGroupFilterLazyQueryHookResult = ReturnType<typeof useGetAllWorkGroupFilterLazyQuery>;
export type GetAllWorkGroupFilterQueryResult = Apollo.QueryResult<GetAllWorkGroupFilterQuery, GetAllWorkGroupFilterQueryVariables>;
export const OutletDetailsDtoDocument = gql`
    query OutletDetailsDto($id: Int!, $date: Date!) {
  dm {
    outletDetailsDto(id: $id, date: $date) {
      name
      photo {
        id
        url
      }
      fullAddress
      customerClass
      customerPriceClass
      phone
      contactName
      routeCode
      cdSalesman
      salesman
      distributors {
        cd
        name
      }
      checkIns {
        photo {
          id
          url
        }
        checkInAt
        checkOutAt
        checkOutReasonText
      }
      orders {
        code
        amount
      }
    }
  }
}
    `;

/**
 * __useOutletDetailsDtoQuery__
 *
 * To run a query within a React component, call `useOutletDetailsDtoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletDetailsDtoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletDetailsDtoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useOutletDetailsDtoQuery(baseOptions: Apollo.QueryHookOptions<OutletDetailsDtoQuery, OutletDetailsDtoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletDetailsDtoQuery, OutletDetailsDtoQueryVariables>(OutletDetailsDtoDocument, options);
      }
export function useOutletDetailsDtoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletDetailsDtoQuery, OutletDetailsDtoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletDetailsDtoQuery, OutletDetailsDtoQueryVariables>(OutletDetailsDtoDocument, options);
        }
export type OutletDetailsDtoQueryHookResult = ReturnType<typeof useOutletDetailsDtoQuery>;
export type OutletDetailsDtoLazyQueryHookResult = ReturnType<typeof useOutletDetailsDtoLazyQuery>;
export type OutletDetailsDtoQueryResult = Apollo.QueryResult<OutletDetailsDtoQuery, OutletDetailsDtoQueryVariables>;
export const GetAllOutletDtoDocument = gql`
    query GetAllOutletDto($salesmanId: Int!, $date: Date!) {
  dm {
    getAllOutletDto(salesmanId: $salesmanId, date: $date) {
      id
      lat
      lng
      index
      isInCallPlan
      hasOrder
      hasCheckedIn
    }
  }
}
    `;

/**
 * __useGetAllOutletDtoQuery__
 *
 * To run a query within a React component, call `useGetAllOutletDtoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOutletDtoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOutletDtoQuery({
 *   variables: {
 *      salesmanId: // value for 'salesmanId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAllOutletDtoQuery(baseOptions: Apollo.QueryHookOptions<GetAllOutletDtoQuery, GetAllOutletDtoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOutletDtoQuery, GetAllOutletDtoQueryVariables>(GetAllOutletDtoDocument, options);
      }
export function useGetAllOutletDtoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOutletDtoQuery, GetAllOutletDtoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOutletDtoQuery, GetAllOutletDtoQueryVariables>(GetAllOutletDtoDocument, options);
        }
export type GetAllOutletDtoQueryHookResult = ReturnType<typeof useGetAllOutletDtoQuery>;
export type GetAllOutletDtoLazyQueryHookResult = ReturnType<typeof useGetAllOutletDtoLazyQuery>;
export type GetAllOutletDtoQueryResult = Apollo.QueryResult<GetAllOutletDtoQuery, GetAllOutletDtoQueryVariables>;
export const ActiveLocationSalesmanDocument = gql`
    query ActiveLocationSalesman($salesmanId: Int!, $date: Date!) {
  dm {
    activeLocationSalesman(salesmanId: $salesmanId, date: $date) {
      lat
      lng
    }
  }
}
    `;

/**
 * __useActiveLocationSalesmanQuery__
 *
 * To run a query within a React component, call `useActiveLocationSalesmanQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLocationSalesmanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLocationSalesmanQuery({
 *   variables: {
 *      salesmanId: // value for 'salesmanId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useActiveLocationSalesmanQuery(baseOptions: Apollo.QueryHookOptions<ActiveLocationSalesmanQuery, ActiveLocationSalesmanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLocationSalesmanQuery, ActiveLocationSalesmanQueryVariables>(ActiveLocationSalesmanDocument, options);
      }
export function useActiveLocationSalesmanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLocationSalesmanQuery, ActiveLocationSalesmanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLocationSalesmanQuery, ActiveLocationSalesmanQueryVariables>(ActiveLocationSalesmanDocument, options);
        }
export type ActiveLocationSalesmanQueryHookResult = ReturnType<typeof useActiveLocationSalesmanQuery>;
export type ActiveLocationSalesmanLazyQueryHookResult = ReturnType<typeof useActiveLocationSalesmanLazyQuery>;
export type ActiveLocationSalesmanQueryResult = Apollo.QueryResult<ActiveLocationSalesmanQuery, ActiveLocationSalesmanQueryVariables>;
export const SalesmanDtoDocument = gql`
    query SalesmanDto($id: Int!) {
  dm {
    salesmanDto(id: $id) {
      id
      code
      name
      photo {
        id
        url
      }
      fullAddress
      phone
      supervisor {
        cd
        name
      }
      lat
      lng
      lastSeenAt
    }
  }
}
    `;

/**
 * __useSalesmanDtoQuery__
 *
 * To run a query within a React component, call `useSalesmanDtoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesmanDtoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesmanDtoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesmanDtoQuery(baseOptions: Apollo.QueryHookOptions<SalesmanDtoQuery, SalesmanDtoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesmanDtoQuery, SalesmanDtoQueryVariables>(SalesmanDtoDocument, options);
      }
export function useSalesmanDtoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesmanDtoQuery, SalesmanDtoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesmanDtoQuery, SalesmanDtoQueryVariables>(SalesmanDtoDocument, options);
        }
export type SalesmanDtoQueryHookResult = ReturnType<typeof useSalesmanDtoQuery>;
export type SalesmanDtoLazyQueryHookResult = ReturnType<typeof useSalesmanDtoLazyQuery>;
export type SalesmanDtoQueryResult = Apollo.QueryResult<SalesmanDtoQuery, SalesmanDtoQueryVariables>;
export const LastestLocationSalesmanListDocument = gql`
    query LastestLocationSalesmanList($listIds: [Int!]!, $date: Date!) {
  dm {
    lastestLocationSalesmanList(listIds: $listIds, date: $date) {
      salesmanId
      salesmanName
      date
      lastSeenAt
      lat
      lng
    }
  }
}
    `;

/**
 * __useLastestLocationSalesmanListQuery__
 *
 * To run a query within a React component, call `useLastestLocationSalesmanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastestLocationSalesmanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastestLocationSalesmanListQuery({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useLastestLocationSalesmanListQuery(baseOptions: Apollo.QueryHookOptions<LastestLocationSalesmanListQuery, LastestLocationSalesmanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastestLocationSalesmanListQuery, LastestLocationSalesmanListQueryVariables>(LastestLocationSalesmanListDocument, options);
      }
export function useLastestLocationSalesmanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastestLocationSalesmanListQuery, LastestLocationSalesmanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastestLocationSalesmanListQuery, LastestLocationSalesmanListQueryVariables>(LastestLocationSalesmanListDocument, options);
        }
export type LastestLocationSalesmanListQueryHookResult = ReturnType<typeof useLastestLocationSalesmanListQuery>;
export type LastestLocationSalesmanListLazyQueryHookResult = ReturnType<typeof useLastestLocationSalesmanListLazyQuery>;
export type LastestLocationSalesmanListQueryResult = Apollo.QueryResult<LastestLocationSalesmanListQuery, LastestLocationSalesmanListQueryVariables>;
export const OutletDetailsWeekdaysDocument = gql`
    query outletDetailsWeekdays($id: Int!) {
  dm {
    outletDetailsWeekdays(id: $id) {
      name
      photo {
        id
        url
      }
      fullAddress
      customerClass
      customerPriceClass
      phone
      contactName
      routeCode
      cdSalesman
      salesman
      distributors {
        cd
        name
      }
    }
  }
}
    `;

/**
 * __useOutletDetailsWeekdaysQuery__
 *
 * To run a query within a React component, call `useOutletDetailsWeekdaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletDetailsWeekdaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletDetailsWeekdaysQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutletDetailsWeekdaysQuery(baseOptions: Apollo.QueryHookOptions<OutletDetailsWeekdaysQuery, OutletDetailsWeekdaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletDetailsWeekdaysQuery, OutletDetailsWeekdaysQueryVariables>(OutletDetailsWeekdaysDocument, options);
      }
export function useOutletDetailsWeekdaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletDetailsWeekdaysQuery, OutletDetailsWeekdaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletDetailsWeekdaysQuery, OutletDetailsWeekdaysQueryVariables>(OutletDetailsWeekdaysDocument, options);
        }
export type OutletDetailsWeekdaysQueryHookResult = ReturnType<typeof useOutletDetailsWeekdaysQuery>;
export type OutletDetailsWeekdaysLazyQueryHookResult = ReturnType<typeof useOutletDetailsWeekdaysLazyQuery>;
export type OutletDetailsWeekdaysQueryResult = Apollo.QueryResult<OutletDetailsWeekdaysQuery, OutletDetailsWeekdaysQueryVariables>;
export const GetOutletDtoWeekdaysDocument = gql`
    query GetOutletDtoWeekdays($weekdays: [Weekdays!]!, $salesmanId: Int!) {
  dm {
    getOutletDtoWeekdays(weekdays: $weekdays, salesmanId: $salesmanId) {
      id
      lat
      lng
    }
  }
}
    `;

/**
 * __useGetOutletDtoWeekdaysQuery__
 *
 * To run a query within a React component, call `useGetOutletDtoWeekdaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletDtoWeekdaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletDtoWeekdaysQuery({
 *   variables: {
 *      weekdays: // value for 'weekdays'
 *      salesmanId: // value for 'salesmanId'
 *   },
 * });
 */
export function useGetOutletDtoWeekdaysQuery(baseOptions: Apollo.QueryHookOptions<GetOutletDtoWeekdaysQuery, GetOutletDtoWeekdaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutletDtoWeekdaysQuery, GetOutletDtoWeekdaysQueryVariables>(GetOutletDtoWeekdaysDocument, options);
      }
export function useGetOutletDtoWeekdaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutletDtoWeekdaysQuery, GetOutletDtoWeekdaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutletDtoWeekdaysQuery, GetOutletDtoWeekdaysQueryVariables>(GetOutletDtoWeekdaysDocument, options);
        }
export type GetOutletDtoWeekdaysQueryHookResult = ReturnType<typeof useGetOutletDtoWeekdaysQuery>;
export type GetOutletDtoWeekdaysLazyQueryHookResult = ReturnType<typeof useGetOutletDtoWeekdaysLazyQuery>;
export type GetOutletDtoWeekdaysQueryResult = Apollo.QueryResult<GetOutletDtoWeekdaysQuery, GetOutletDtoWeekdaysQueryVariables>;
export const AllCustomerDiscountsDocument = gql`
    query AllCustomerDiscounts($customerId: Int!) {
  p {
    allCustomerDiscounts(customerId: $customerId) {
      ...DiscountFragment
    }
  }
}
    ${DiscountFragmentDoc}`;

/**
 * __useAllCustomerDiscountsQuery__
 *
 * To run a query within a React component, call `useAllCustomerDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomerDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomerDiscountsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAllCustomerDiscountsQuery(baseOptions: Apollo.QueryHookOptions<AllCustomerDiscountsQuery, AllCustomerDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomerDiscountsQuery, AllCustomerDiscountsQueryVariables>(AllCustomerDiscountsDocument, options);
      }
export function useAllCustomerDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomerDiscountsQuery, AllCustomerDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomerDiscountsQuery, AllCustomerDiscountsQueryVariables>(AllCustomerDiscountsDocument, options);
        }
export type AllCustomerDiscountsQueryHookResult = ReturnType<typeof useAllCustomerDiscountsQuery>;
export type AllCustomerDiscountsLazyQueryHookResult = ReturnType<typeof useAllCustomerDiscountsLazyQuery>;
export type AllCustomerDiscountsQueryResult = Apollo.QueryResult<AllCustomerDiscountsQuery, AllCustomerDiscountsQueryVariables>;
export const CalculateSalesOrderDiscountDocument = gql`
    query CalculateSalesOrderDiscount($customerId: Int!, $items: [DiscountSalesItemInput!]!, $discountIds: [Int!]!) {
  sfa {
    calculateSalesOrderDiscount(
      customerId: $customerId
      items: $items
      discountIds: $discountIds
    ) {
      discountId
      discount {
        id
        cd
        description
        status
        startDate
        endDate
        isBundle
        breakBy
        multiple
        sortOrder
        highestLevel
        buyGive
        excluded
        allowChangeLevel
        isManual
        firstOrder
        internalRef
        externalRef
        sortOrder
        buyGive
        discountLevels {
          id
          level
          description
          breakValue
          discountLevelRewards {
            id
            description
            isDefault
            isBundle
            amount
            percentage
            percentageMoney
            qty
            discountLevelRewardProducts {
              id
              productId
              rate
            }
          }
          discountLevelConditions {
            id
            productId
            minQty
          }
        }
      }
      discountLevelCalculatePasses {
        discountLevelId
        totalPass
        discountLevel {
          id
          level
          description
          breakValue
          discountLevelRewards {
            id
            description
            isDefault
            isBundle
            amount
            percentage
            percentageMoney
            qty
            discountLevelRewardProducts {
              id
              productId
              rate
            }
          }
          discountLevelConditions {
            id
            productId
            minQty
          }
        }
        orderItems {
          id
          unitId
          qty
          passCount
          price
        }
      }
    }
  }
}
    `;

/**
 * __useCalculateSalesOrderDiscountQuery__
 *
 * To run a query within a React component, call `useCalculateSalesOrderDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateSalesOrderDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateSalesOrderDiscountQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      items: // value for 'items'
 *      discountIds: // value for 'discountIds'
 *   },
 * });
 */
export function useCalculateSalesOrderDiscountQuery(baseOptions: Apollo.QueryHookOptions<CalculateSalesOrderDiscountQuery, CalculateSalesOrderDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateSalesOrderDiscountQuery, CalculateSalesOrderDiscountQueryVariables>(CalculateSalesOrderDiscountDocument, options);
      }
export function useCalculateSalesOrderDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateSalesOrderDiscountQuery, CalculateSalesOrderDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateSalesOrderDiscountQuery, CalculateSalesOrderDiscountQueryVariables>(CalculateSalesOrderDiscountDocument, options);
        }
export type CalculateSalesOrderDiscountQueryHookResult = ReturnType<typeof useCalculateSalesOrderDiscountQuery>;
export type CalculateSalesOrderDiscountLazyQueryHookResult = ReturnType<typeof useCalculateSalesOrderDiscountLazyQuery>;
export type CalculateSalesOrderDiscountQueryResult = Apollo.QueryResult<CalculateSalesOrderDiscountQuery, CalculateSalesOrderDiscountQueryVariables>;
export const CloseDiscountDocument = gql`
    mutation CloseDiscount($id: Int!) {
  p {
    closeDiscount(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseDiscountMutationFn = Apollo.MutationFunction<CloseDiscountMutation, CloseDiscountMutationVariables>;

/**
 * __useCloseDiscountMutation__
 *
 * To run a mutation, you first call `useCloseDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeDiscountMutation, { data, loading, error }] = useCloseDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CloseDiscountMutation, CloseDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseDiscountMutation, CloseDiscountMutationVariables>(CloseDiscountDocument, options);
      }
export type CloseDiscountMutationHookResult = ReturnType<typeof useCloseDiscountMutation>;
export type CloseDiscountMutationResult = Apollo.MutationResult<CloseDiscountMutation>;
export type CloseDiscountMutationOptions = Apollo.BaseMutationOptions<CloseDiscountMutation, CloseDiscountMutationVariables>;
export const CreateOrUpdateDiscountDocument = gql`
    mutation CreateOrUpdateDiscount($data: DiscountInput!) {
  p {
    createOrUpdateDiscount(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...DiscountFragment
      }
    }
  }
}
    ${DiscountFragmentDoc}`;
export type CreateOrUpdateDiscountMutationFn = Apollo.MutationFunction<CreateOrUpdateDiscountMutation, CreateOrUpdateDiscountMutationVariables>;

/**
 * __useCreateOrUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDiscountMutation, { data, loading, error }] = useCreateOrUpdateDiscountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDiscountMutation, CreateOrUpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDiscountMutation, CreateOrUpdateDiscountMutationVariables>(CreateOrUpdateDiscountDocument, options);
      }
export type CreateOrUpdateDiscountMutationHookResult = ReturnType<typeof useCreateOrUpdateDiscountMutation>;
export type CreateOrUpdateDiscountMutationResult = Apollo.MutationResult<CreateOrUpdateDiscountMutation>;
export type CreateOrUpdateDiscountMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDiscountMutation, CreateOrUpdateDiscountMutationVariables>;
export const CustomerDiscountsDocument = gql`
    query CustomerDiscounts($customerId: Int!) {
  p {
    customerDiscounts(customerId: $customerId) {
      ...DiscountFragment
    }
  }
}
    ${DiscountFragmentDoc}`;

/**
 * __useCustomerDiscountsQuery__
 *
 * To run a query within a React component, call `useCustomerDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDiscountsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerDiscountsQuery(baseOptions: Apollo.QueryHookOptions<CustomerDiscountsQuery, CustomerDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDiscountsQuery, CustomerDiscountsQueryVariables>(CustomerDiscountsDocument, options);
      }
export function useCustomerDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDiscountsQuery, CustomerDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDiscountsQuery, CustomerDiscountsQueryVariables>(CustomerDiscountsDocument, options);
        }
export type CustomerDiscountsQueryHookResult = ReturnType<typeof useCustomerDiscountsQuery>;
export type CustomerDiscountsLazyQueryHookResult = ReturnType<typeof useCustomerDiscountsLazyQuery>;
export type CustomerDiscountsQueryResult = Apollo.QueryResult<CustomerDiscountsQuery, CustomerDiscountsQueryVariables>;
export const DeleteDiscountDocument = gql`
    mutation DeleteDiscount($id: Int!) {
  p {
    deleteDiscount(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteDiscountMutationFn = Apollo.MutationFunction<DeleteDiscountMutation, DeleteDiscountMutationVariables>;

/**
 * __useDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountMutation, { data, loading, error }] = useDeleteDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument, options);
      }
export type DeleteDiscountMutationHookResult = ReturnType<typeof useDeleteDiscountMutation>;
export type DeleteDiscountMutationResult = Apollo.MutationResult<DeleteDiscountMutation>;
export type DeleteDiscountMutationOptions = Apollo.BaseMutationOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>;
export const DiscountListDocument = gql`
    query DiscountList($page: Int!, $take: Int!, $filter: String, $status: ProgramStatus, $discountCd: String, $startDate: Date, $endDate: Date, $creatorCd: String, $isBundle: Int, $isFirstOrder: Int, $externalReference: String, $salesRegionId: Int) {
  p {
    discountList(
      page: $page
      take: $take
      filter: $filter
      status: $status
      discountCd: $discountCd
      startDate: $startDate
      endDate: $endDate
      creatorCd: $creatorCd
      isBundle: $isBundle
      isFirstOrder: $isFirstOrder
      externalReference: $externalReference
      salesRegionId: $salesRegionId
    ) {
      total
      data {
        id
        cd
        description
        status
        startDate
        endDate
        isBundle
        breakBy
        multiple
        highestLevel
        excluded
        isManual
        firstOrder
        allowChangeLevel
        internalRef
        externalRef
        sortOrder
      }
    }
  }
}
    `;

/**
 * __useDiscountListQuery__
 *
 * To run a query within a React component, call `useDiscountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      discountCd: // value for 'discountCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      creatorCd: // value for 'creatorCd'
 *      isBundle: // value for 'isBundle'
 *      isFirstOrder: // value for 'isFirstOrder'
 *      externalReference: // value for 'externalReference'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useDiscountListQuery(baseOptions: Apollo.QueryHookOptions<DiscountListQuery, DiscountListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountListQuery, DiscountListQueryVariables>(DiscountListDocument, options);
      }
export function useDiscountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountListQuery, DiscountListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountListQuery, DiscountListQueryVariables>(DiscountListDocument, options);
        }
export type DiscountListQueryHookResult = ReturnType<typeof useDiscountListQuery>;
export type DiscountListLazyQueryHookResult = ReturnType<typeof useDiscountListLazyQuery>;
export type DiscountListQueryResult = Apollo.QueryResult<DiscountListQuery, DiscountListQueryVariables>;
export const DiscountPrioritizationListDocument = gql`
    query DiscountPrioritizationList($page: Int!, $take: Int!, $startDate: Date, $endDate: Date) {
  p {
    discountPrioritizationList(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      data {
        id
        cd
        description
        sortOrder
        status
        startDate
        endDate
        isBundle
        breakBy
        multiple
        highestLevel
        excluded
        allowChangeLevel
        internalRef
        externalRef
      }
    }
  }
}
    `;

/**
 * __useDiscountPrioritizationListQuery__
 *
 * To run a query within a React component, call `useDiscountPrioritizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountPrioritizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountPrioritizationListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDiscountPrioritizationListQuery(baseOptions: Apollo.QueryHookOptions<DiscountPrioritizationListQuery, DiscountPrioritizationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountPrioritizationListQuery, DiscountPrioritizationListQueryVariables>(DiscountPrioritizationListDocument, options);
      }
export function useDiscountPrioritizationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountPrioritizationListQuery, DiscountPrioritizationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountPrioritizationListQuery, DiscountPrioritizationListQueryVariables>(DiscountPrioritizationListDocument, options);
        }
export type DiscountPrioritizationListQueryHookResult = ReturnType<typeof useDiscountPrioritizationListQuery>;
export type DiscountPrioritizationListLazyQueryHookResult = ReturnType<typeof useDiscountPrioritizationListLazyQuery>;
export type DiscountPrioritizationListQueryResult = Apollo.QueryResult<DiscountPrioritizationListQuery, DiscountPrioritizationListQueryVariables>;
export const DiscountDocument = gql`
    query Discount($id: Int!) {
  p {
    discount(id: $id) {
      ...DiscountFragment
    }
  }
}
    ${DiscountFragmentDoc}`;

/**
 * __useDiscountQuery__
 *
 * To run a query within a React component, call `useDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountQuery(baseOptions: Apollo.QueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
      }
export function useDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
        }
export type DiscountQueryHookResult = ReturnType<typeof useDiscountQuery>;
export type DiscountLazyQueryHookResult = ReturnType<typeof useDiscountLazyQuery>;
export type DiscountQueryResult = Apollo.QueryResult<DiscountQuery, DiscountQueryVariables>;
export const PromotionPrioritizationDocument = gql`
    mutation PromotionPrioritization($input: [DiscountPrioritizationInput!]!) {
  p {
    promotionPrioritization(input: $input) {
      success
      message {
        message
      }
      content
    }
  }
}
    `;
export type PromotionPrioritizationMutationFn = Apollo.MutationFunction<PromotionPrioritizationMutation, PromotionPrioritizationMutationVariables>;

/**
 * __usePromotionPrioritizationMutation__
 *
 * To run a mutation, you first call `usePromotionPrioritizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionPrioritizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionPrioritizationMutation, { data, loading, error }] = usePromotionPrioritizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromotionPrioritizationMutation(baseOptions?: Apollo.MutationHookOptions<PromotionPrioritizationMutation, PromotionPrioritizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromotionPrioritizationMutation, PromotionPrioritizationMutationVariables>(PromotionPrioritizationDocument, options);
      }
export type PromotionPrioritizationMutationHookResult = ReturnType<typeof usePromotionPrioritizationMutation>;
export type PromotionPrioritizationMutationResult = Apollo.MutationResult<PromotionPrioritizationMutation>;
export type PromotionPrioritizationMutationOptions = Apollo.BaseMutationOptions<PromotionPrioritizationMutation, PromotionPrioritizationMutationVariables>;
export const ReleaseDiscountDocument = gql`
    mutation ReleaseDiscount($id: Int!) {
  p {
    releaseDiscount(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseDiscountMutationFn = Apollo.MutationFunction<ReleaseDiscountMutation, ReleaseDiscountMutationVariables>;

/**
 * __useReleaseDiscountMutation__
 *
 * To run a mutation, you first call `useReleaseDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseDiscountMutation, { data, loading, error }] = useReleaseDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseDiscountMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseDiscountMutation, ReleaseDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseDiscountMutation, ReleaseDiscountMutationVariables>(ReleaseDiscountDocument, options);
      }
export type ReleaseDiscountMutationHookResult = ReturnType<typeof useReleaseDiscountMutation>;
export type ReleaseDiscountMutationResult = Apollo.MutationResult<ReleaseDiscountMutation>;
export type ReleaseDiscountMutationOptions = Apollo.BaseMutationOptions<ReleaseDiscountMutation, ReleaseDiscountMutationVariables>;
export const ActiveDistributorDocument = gql`
    mutation ActiveDistributor($id: Int!) {
  md {
    activeDistributor(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ActiveDistributorMutationFn = Apollo.MutationFunction<ActiveDistributorMutation, ActiveDistributorMutationVariables>;

/**
 * __useActiveDistributorMutation__
 *
 * To run a mutation, you first call `useActiveDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeDistributorMutation, { data, loading, error }] = useActiveDistributorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveDistributorMutation(baseOptions?: Apollo.MutationHookOptions<ActiveDistributorMutation, ActiveDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveDistributorMutation, ActiveDistributorMutationVariables>(ActiveDistributorDocument, options);
      }
export type ActiveDistributorMutationHookResult = ReturnType<typeof useActiveDistributorMutation>;
export type ActiveDistributorMutationResult = Apollo.MutationResult<ActiveDistributorMutation>;
export type ActiveDistributorMutationOptions = Apollo.BaseMutationOptions<ActiveDistributorMutation, ActiveDistributorMutationVariables>;
export const CreateOrUpdateDistributorDocument = gql`
    mutation CreateOrUpdateDistributor($data: DistributorInput!) {
  md {
    createOrUpdateDistributor(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...DistributorFragment
      }
    }
  }
}
    ${DistributorFragmentDoc}`;
export type CreateOrUpdateDistributorMutationFn = Apollo.MutationFunction<CreateOrUpdateDistributorMutation, CreateOrUpdateDistributorMutationVariables>;

/**
 * __useCreateOrUpdateDistributorMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDistributorMutation, { data, loading, error }] = useCreateOrUpdateDistributorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateDistributorMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDistributorMutation, CreateOrUpdateDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDistributorMutation, CreateOrUpdateDistributorMutationVariables>(CreateOrUpdateDistributorDocument, options);
      }
export type CreateOrUpdateDistributorMutationHookResult = ReturnType<typeof useCreateOrUpdateDistributorMutation>;
export type CreateOrUpdateDistributorMutationResult = Apollo.MutationResult<CreateOrUpdateDistributorMutation>;
export type CreateOrUpdateDistributorMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDistributorMutation, CreateOrUpdateDistributorMutationVariables>;
export const CreateOrUpdateDistributorTypeDocument = gql`
    mutation CreateOrUpdateDistributorType($data: DistributorTypeInput!) {
  md {
    createOrUpdateDistributorType(data: $data) {
      success
      message {
        message
      }
      content {
        id
        cd
        name
      }
    }
  }
}
    `;
export type CreateOrUpdateDistributorTypeMutationFn = Apollo.MutationFunction<CreateOrUpdateDistributorTypeMutation, CreateOrUpdateDistributorTypeMutationVariables>;

/**
 * __useCreateOrUpdateDistributorTypeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDistributorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDistributorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDistributorTypeMutation, { data, loading, error }] = useCreateOrUpdateDistributorTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateDistributorTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDistributorTypeMutation, CreateOrUpdateDistributorTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDistributorTypeMutation, CreateOrUpdateDistributorTypeMutationVariables>(CreateOrUpdateDistributorTypeDocument, options);
      }
export type CreateOrUpdateDistributorTypeMutationHookResult = ReturnType<typeof useCreateOrUpdateDistributorTypeMutation>;
export type CreateOrUpdateDistributorTypeMutationResult = Apollo.MutationResult<CreateOrUpdateDistributorTypeMutation>;
export type CreateOrUpdateDistributorTypeMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDistributorTypeMutation, CreateOrUpdateDistributorTypeMutationVariables>;
export const CreateOrUpdateShipperDocument = gql`
    mutation CreateOrUpdateShipper($data: ShipperInput!) {
  md {
    createOrUpdateShipper(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        cd
        name
        phone11
        email
        citizenIdentityCard
        photoId
        address1
        distributorId
        distributor {
          cd
          name
        }
        photo {
          id
          url
        }
      }
    }
  }
}
    `;
export type CreateOrUpdateShipperMutationFn = Apollo.MutationFunction<CreateOrUpdateShipperMutation, CreateOrUpdateShipperMutationVariables>;

/**
 * __useCreateOrUpdateShipperMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateShipperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateShipperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateShipperMutation, { data, loading, error }] = useCreateOrUpdateShipperMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateShipperMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateShipperMutation, CreateOrUpdateShipperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateShipperMutation, CreateOrUpdateShipperMutationVariables>(CreateOrUpdateShipperDocument, options);
      }
export type CreateOrUpdateShipperMutationHookResult = ReturnType<typeof useCreateOrUpdateShipperMutation>;
export type CreateOrUpdateShipperMutationResult = Apollo.MutationResult<CreateOrUpdateShipperMutation>;
export type CreateOrUpdateShipperMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateShipperMutation, CreateOrUpdateShipperMutationVariables>;
export const DeleteShipperDocument = gql`
    mutation DeleteShipper($id: Int!) {
  md {
    deleteShipper(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteShipperMutationFn = Apollo.MutationFunction<DeleteShipperMutation, DeleteShipperMutationVariables>;

/**
 * __useDeleteShipperMutation__
 *
 * To run a mutation, you first call `useDeleteShipperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipperMutation, { data, loading, error }] = useDeleteShipperMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShipperMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShipperMutation, DeleteShipperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShipperMutation, DeleteShipperMutationVariables>(DeleteShipperDocument, options);
      }
export type DeleteShipperMutationHookResult = ReturnType<typeof useDeleteShipperMutation>;
export type DeleteShipperMutationResult = Apollo.MutationResult<DeleteShipperMutation>;
export type DeleteShipperMutationOptions = Apollo.BaseMutationOptions<DeleteShipperMutation, DeleteShipperMutationVariables>;
export const DistributorAllListDocument = gql`
    query DistributorAllList {
  md {
    distributorAllList {
      id
      cd
      name
    }
  }
}
    `;

/**
 * __useDistributorAllListQuery__
 *
 * To run a query within a React component, call `useDistributorAllListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorAllListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorAllListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistributorAllListQuery(baseOptions?: Apollo.QueryHookOptions<DistributorAllListQuery, DistributorAllListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributorAllListQuery, DistributorAllListQueryVariables>(DistributorAllListDocument, options);
      }
export function useDistributorAllListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributorAllListQuery, DistributorAllListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributorAllListQuery, DistributorAllListQueryVariables>(DistributorAllListDocument, options);
        }
export type DistributorAllListQueryHookResult = ReturnType<typeof useDistributorAllListQuery>;
export type DistributorAllListLazyQueryHookResult = ReturnType<typeof useDistributorAllListLazyQuery>;
export type DistributorAllListQueryResult = Apollo.QueryResult<DistributorAllListQuery, DistributorAllListQueryVariables>;
export const DistributorListDocument = gql`
    query DistributorList($page: Int!, $take: Int!, $filter: String, $provinceId: Int, $districtId: Int, $wardId: Int) {
  md {
    distributorList(
      page: $page
      take: $take
      filter: $filter
      provinceId: $provinceId
      districtId: $districtId
      wardId: $wardId
    ) {
      total
      data {
        id
        cd
        name
        contactName1
        phone11
        isActive
        distributorType {
          id
          cd
          name
        }
        customer {
          id
          name
          customerPriceClassId
        }
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDistributorListQuery__
 *
 * To run a query within a React component, call `useDistributorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      provinceId: // value for 'provinceId'
 *      districtId: // value for 'districtId'
 *      wardId: // value for 'wardId'
 *   },
 * });
 */
export function useDistributorListQuery(baseOptions: Apollo.QueryHookOptions<DistributorListQuery, DistributorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributorListQuery, DistributorListQueryVariables>(DistributorListDocument, options);
      }
export function useDistributorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributorListQuery, DistributorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributorListQuery, DistributorListQueryVariables>(DistributorListDocument, options);
        }
export type DistributorListQueryHookResult = ReturnType<typeof useDistributorListQuery>;
export type DistributorListLazyQueryHookResult = ReturnType<typeof useDistributorListLazyQuery>;
export type DistributorListQueryResult = Apollo.QueryResult<DistributorListQuery, DistributorListQueryVariables>;
export const DistributorDocument = gql`
    query Distributor($id: Int!) {
  md {
    distributor(id: $id) {
      ...DistributorFragment
    }
  }
}
    ${DistributorFragmentDoc}`;

/**
 * __useDistributorQuery__
 *
 * To run a query within a React component, call `useDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorQuery(baseOptions: Apollo.QueryHookOptions<DistributorQuery, DistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributorQuery, DistributorQueryVariables>(DistributorDocument, options);
      }
export function useDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributorQuery, DistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributorQuery, DistributorQueryVariables>(DistributorDocument, options);
        }
export type DistributorQueryHookResult = ReturnType<typeof useDistributorQuery>;
export type DistributorLazyQueryHookResult = ReturnType<typeof useDistributorLazyQuery>;
export type DistributorQueryResult = Apollo.QueryResult<DistributorQuery, DistributorQueryVariables>;
export const DistributorTypeListDocument = gql`
    query DistributorTypeList {
  md {
    distributorTypeList {
      id
      cd
      name
    }
  }
}
    `;

/**
 * __useDistributorTypeListQuery__
 *
 * To run a query within a React component, call `useDistributorTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistributorTypeListQuery(baseOptions?: Apollo.QueryHookOptions<DistributorTypeListQuery, DistributorTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributorTypeListQuery, DistributorTypeListQueryVariables>(DistributorTypeListDocument, options);
      }
export function useDistributorTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributorTypeListQuery, DistributorTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributorTypeListQuery, DistributorTypeListQueryVariables>(DistributorTypeListDocument, options);
        }
export type DistributorTypeListQueryHookResult = ReturnType<typeof useDistributorTypeListQuery>;
export type DistributorTypeListLazyQueryHookResult = ReturnType<typeof useDistributorTypeListLazyQuery>;
export type DistributorTypeListQueryResult = Apollo.QueryResult<DistributorTypeListQuery, DistributorTypeListQueryVariables>;
export const DistributorTypeDocument = gql`
    query DistributorType($id: Int!) {
  md {
    distributorType(id: $id) {
      id
      cd
      name
    }
  }
}
    `;

/**
 * __useDistributorTypeQuery__
 *
 * To run a query within a React component, call `useDistributorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributorTypeQuery(baseOptions: Apollo.QueryHookOptions<DistributorTypeQuery, DistributorTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributorTypeQuery, DistributorTypeQueryVariables>(DistributorTypeDocument, options);
      }
export function useDistributorTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributorTypeQuery, DistributorTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributorTypeQuery, DistributorTypeQueryVariables>(DistributorTypeDocument, options);
        }
export type DistributorTypeQueryHookResult = ReturnType<typeof useDistributorTypeQuery>;
export type DistributorTypeLazyQueryHookResult = ReturnType<typeof useDistributorTypeLazyQuery>;
export type DistributorTypeQueryResult = Apollo.QueryResult<DistributorTypeQuery, DistributorTypeQueryVariables>;
export const ShipperListDocument = gql`
    query ShipperList($page: Int!, $take: Int!, $filter: String, $distributorId: Int) {
  md {
    shipperList(
      page: $page
      take: $take
      filter: $filter
      distributorId: $distributorId
    ) {
      total
      data {
        id
        cd
        name
        phone11
        email
        citizenIdentityCard
        address1
        isAlive
        photoId
        distributor {
          cd
          name
        }
        photo {
          id
          url
        }
      }
    }
  }
}
    `;

/**
 * __useShipperListQuery__
 *
 * To run a query within a React component, call `useShipperListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipperListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipperListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useShipperListQuery(baseOptions: Apollo.QueryHookOptions<ShipperListQuery, ShipperListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipperListQuery, ShipperListQueryVariables>(ShipperListDocument, options);
      }
export function useShipperListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipperListQuery, ShipperListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipperListQuery, ShipperListQueryVariables>(ShipperListDocument, options);
        }
export type ShipperListQueryHookResult = ReturnType<typeof useShipperListQuery>;
export type ShipperListLazyQueryHookResult = ReturnType<typeof useShipperListLazyQuery>;
export type ShipperListQueryResult = Apollo.QueryResult<ShipperListQuery, ShipperListQueryVariables>;
export const ShipperDocument = gql`
    query Shipper($id: Int!) {
  md {
    shipper(id: $id) {
      id
      cd
      name
      phone11
      email
      citizenIdentityCard
      photoId
      address1
      distributorId
      isAlive
      photo {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useShipperQuery__
 *
 * To run a query within a React component, call `useShipperQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipperQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShipperQuery(baseOptions: Apollo.QueryHookOptions<ShipperQuery, ShipperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipperQuery, ShipperQueryVariables>(ShipperDocument, options);
      }
export function useShipperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipperQuery, ShipperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipperQuery, ShipperQueryVariables>(ShipperDocument, options);
        }
export type ShipperQueryHookResult = ReturnType<typeof useShipperQuery>;
export type ShipperLazyQueryHookResult = ReturnType<typeof useShipperLazyQuery>;
export type ShipperQueryResult = Apollo.QueryResult<ShipperQuery, ShipperQueryVariables>;
export const StatusShipperDocument = gql`
    mutation StatusShipper($id: Int!) {
  md {
    statusShipper(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type StatusShipperMutationFn = Apollo.MutationFunction<StatusShipperMutation, StatusShipperMutationVariables>;

/**
 * __useStatusShipperMutation__
 *
 * To run a mutation, you first call `useStatusShipperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatusShipperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statusShipperMutation, { data, loading, error }] = useStatusShipperMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStatusShipperMutation(baseOptions?: Apollo.MutationHookOptions<StatusShipperMutation, StatusShipperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StatusShipperMutation, StatusShipperMutationVariables>(StatusShipperDocument, options);
      }
export type StatusShipperMutationHookResult = ReturnType<typeof useStatusShipperMutation>;
export type StatusShipperMutationResult = Apollo.MutationResult<StatusShipperMutation>;
export type StatusShipperMutationOptions = Apollo.BaseMutationOptions<StatusShipperMutation, StatusShipperMutationVariables>;
export const ActiveEmployeeDocument = gql`
    mutation ActiveEmployee($id: Int!) {
  md {
    activeEmployee(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ActiveEmployeeMutationFn = Apollo.MutationFunction<ActiveEmployeeMutation, ActiveEmployeeMutationVariables>;

/**
 * __useActiveEmployeeMutation__
 *
 * To run a mutation, you first call `useActiveEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeEmployeeMutation, { data, loading, error }] = useActiveEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<ActiveEmployeeMutation, ActiveEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveEmployeeMutation, ActiveEmployeeMutationVariables>(ActiveEmployeeDocument, options);
      }
export type ActiveEmployeeMutationHookResult = ReturnType<typeof useActiveEmployeeMutation>;
export type ActiveEmployeeMutationResult = Apollo.MutationResult<ActiveEmployeeMutation>;
export type ActiveEmployeeMutationOptions = Apollo.BaseMutationOptions<ActiveEmployeeMutation, ActiveEmployeeMutationVariables>;
export const AllEmployeeListDocument = gql`
    query AllEmployeeList($page: Int!, $take: Int!, $filter: String, $positionId: Int, $provinceId: Int, $districtId: Int, $wardId: Int, $name: String) {
  md {
    allEmployeeList(
      page: $page
      take: $take
      filter: $filter
      positionId: $positionId
      provinceId: $provinceId
      districtId: $districtId
      wardId: $wardId
      name: $name
    ) {
      total
      data {
        id
        cd
        name
        username
        email
        address1
        isActive
        email
        citizenIdentityCard
        photo {
          id
          url
        }
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
        employeePositions {
          id
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllEmployeeListQuery__
 *
 * To run a query within a React component, call `useAllEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEmployeeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      positionId: // value for 'positionId'
 *      provinceId: // value for 'provinceId'
 *      districtId: // value for 'districtId'
 *      wardId: // value for 'wardId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAllEmployeeListQuery(baseOptions: Apollo.QueryHookOptions<AllEmployeeListQuery, AllEmployeeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEmployeeListQuery, AllEmployeeListQueryVariables>(AllEmployeeListDocument, options);
      }
export function useAllEmployeeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEmployeeListQuery, AllEmployeeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEmployeeListQuery, AllEmployeeListQueryVariables>(AllEmployeeListDocument, options);
        }
export type AllEmployeeListQueryHookResult = ReturnType<typeof useAllEmployeeListQuery>;
export type AllEmployeeListLazyQueryHookResult = ReturnType<typeof useAllEmployeeListLazyQuery>;
export type AllEmployeeListQueryResult = Apollo.QueryResult<AllEmployeeListQuery, AllEmployeeListQueryVariables>;
export const CreateOrUpdateEmployeeDocument = gql`
    mutation CreateOrUpdateEmployee($data: EmployeeInput!) {
  md {
    createOrUpdateEmployee(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...EmployeeFragment
      }
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type CreateOrUpdateEmployeeMutationFn = Apollo.MutationFunction<CreateOrUpdateEmployeeMutation, CreateOrUpdateEmployeeMutationVariables>;

/**
 * __useCreateOrUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateEmployeeMutation, { data, loading, error }] = useCreateOrUpdateEmployeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateEmployeeMutation, CreateOrUpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateEmployeeMutation, CreateOrUpdateEmployeeMutationVariables>(CreateOrUpdateEmployeeDocument, options);
      }
export type CreateOrUpdateEmployeeMutationHookResult = ReturnType<typeof useCreateOrUpdateEmployeeMutation>;
export type CreateOrUpdateEmployeeMutationResult = Apollo.MutationResult<CreateOrUpdateEmployeeMutation>;
export type CreateOrUpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateEmployeeMutation, CreateOrUpdateEmployeeMutationVariables>;
export const DeviceUserListDocument = gql`
    query DeviceUserList($page: Int!, $take: Int!, $filter: String) {
  ctrl {
    deviceUserList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        deviceId
        deviceOs
        deviceName
        startDate
        endDate
        employees {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useDeviceUserListQuery__
 *
 * To run a query within a React component, call `useDeviceUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeviceUserListQuery(baseOptions: Apollo.QueryHookOptions<DeviceUserListQuery, DeviceUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserListQuery, DeviceUserListQueryVariables>(DeviceUserListDocument, options);
      }
export function useDeviceUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserListQuery, DeviceUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserListQuery, DeviceUserListQueryVariables>(DeviceUserListDocument, options);
        }
export type DeviceUserListQueryHookResult = ReturnType<typeof useDeviceUserListQuery>;
export type DeviceUserListLazyQueryHookResult = ReturnType<typeof useDeviceUserListLazyQuery>;
export type DeviceUserListQueryResult = Apollo.QueryResult<DeviceUserListQuery, DeviceUserListQueryVariables>;
export const EmployeeListAllDocument = gql`
    query EmployeeListAll {
  md {
    employeeListAll {
      id
      cd
      name
    }
  }
}
    `;

/**
 * __useEmployeeListAllQuery__
 *
 * To run a query within a React component, call `useEmployeeListAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeListAllQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeListAllQuery, EmployeeListAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeListAllQuery, EmployeeListAllQueryVariables>(EmployeeListAllDocument, options);
      }
export function useEmployeeListAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeListAllQuery, EmployeeListAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeListAllQuery, EmployeeListAllQueryVariables>(EmployeeListAllDocument, options);
        }
export type EmployeeListAllQueryHookResult = ReturnType<typeof useEmployeeListAllQuery>;
export type EmployeeListAllLazyQueryHookResult = ReturnType<typeof useEmployeeListAllLazyQuery>;
export type EmployeeListAllQueryResult = Apollo.QueryResult<EmployeeListAllQuery, EmployeeListAllQueryVariables>;
export const EmployeeLeaderListDocument = gql`
    query EmployeeLeaderList($page: Int!, $take: Int!, $filter: String) {
  md {
    employeeLeaderList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
      }
    }
  }
}
    `;

/**
 * __useEmployeeLeaderListQuery__
 *
 * To run a query within a React component, call `useEmployeeLeaderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLeaderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeLeaderListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEmployeeLeaderListQuery(baseOptions: Apollo.QueryHookOptions<EmployeeLeaderListQuery, EmployeeLeaderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeLeaderListQuery, EmployeeLeaderListQueryVariables>(EmployeeLeaderListDocument, options);
      }
export function useEmployeeLeaderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeLeaderListQuery, EmployeeLeaderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeLeaderListQuery, EmployeeLeaderListQueryVariables>(EmployeeLeaderListDocument, options);
        }
export type EmployeeLeaderListQueryHookResult = ReturnType<typeof useEmployeeLeaderListQuery>;
export type EmployeeLeaderListLazyQueryHookResult = ReturnType<typeof useEmployeeLeaderListLazyQuery>;
export type EmployeeLeaderListQueryResult = Apollo.QueryResult<EmployeeLeaderListQuery, EmployeeLeaderListQueryVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($page: Int!, $take: Int!, $filter: String) {
  md {
    employeeList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
        username
        email
        address1
        isActive
        citizenIdentityCard
        photo {
          id
          url
        }
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
        employeePositions {
          id
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeeListQuery__
 *
 * To run a query within a React component, call `useEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEmployeeListQuery(baseOptions: Apollo.QueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, options);
      }
export function useEmployeeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, options);
        }
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListLazyQueryHookResult = ReturnType<typeof useEmployeeListLazyQuery>;
export type EmployeeListQueryResult = Apollo.QueryResult<EmployeeListQuery, EmployeeListQueryVariables>;
export const EmployeeMemberListDocument = gql`
    query EmployeeMemberList($page: Int!, $take: Int!, $filter: String, $leaderId: Int) {
  md {
    employeeMemberList(
      page: $page
      take: $take
      filter: $filter
      leaderId: $leaderId
    ) {
      total
      data {
        id
        cd
        name
      }
    }
  }
}
    `;

/**
 * __useEmployeeMemberListQuery__
 *
 * To run a query within a React component, call `useEmployeeMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeMemberListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      leaderId: // value for 'leaderId'
 *   },
 * });
 */
export function useEmployeeMemberListQuery(baseOptions: Apollo.QueryHookOptions<EmployeeMemberListQuery, EmployeeMemberListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeMemberListQuery, EmployeeMemberListQueryVariables>(EmployeeMemberListDocument, options);
      }
export function useEmployeeMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeMemberListQuery, EmployeeMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeMemberListQuery, EmployeeMemberListQueryVariables>(EmployeeMemberListDocument, options);
        }
export type EmployeeMemberListQueryHookResult = ReturnType<typeof useEmployeeMemberListQuery>;
export type EmployeeMemberListLazyQueryHookResult = ReturnType<typeof useEmployeeMemberListLazyQuery>;
export type EmployeeMemberListQueryResult = Apollo.QueryResult<EmployeeMemberListQuery, EmployeeMemberListQueryVariables>;
export const EmployeeDocument = gql`
    query Employee($id: Int!) {
  md {
    employee(id: $id) {
      ...EmployeeFragment
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeeQuery__
 *
 * To run a query within a React component, call `useEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeQuery(baseOptions: Apollo.QueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
      }
export function useEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
        }
export type EmployeeQueryHookResult = ReturnType<typeof useEmployeeQuery>;
export type EmployeeLazyQueryHookResult = ReturnType<typeof useEmployeeLazyQuery>;
export type EmployeeQueryResult = Apollo.QueryResult<EmployeeQuery, EmployeeQueryVariables>;
export const RemoveDeviceUserDocument = gql`
    mutation RemoveDeviceUser($id: Int!) {
  ctrl {
    removeDeviceUser(id: $id) {
      success
    }
  }
}
    `;
export type RemoveDeviceUserMutationFn = Apollo.MutationFunction<RemoveDeviceUserMutation, RemoveDeviceUserMutationVariables>;

/**
 * __useRemoveDeviceUserMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceUserMutation, { data, loading, error }] = useRemoveDeviceUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDeviceUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDeviceUserMutation, RemoveDeviceUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDeviceUserMutation, RemoveDeviceUserMutationVariables>(RemoveDeviceUserDocument, options);
      }
export type RemoveDeviceUserMutationHookResult = ReturnType<typeof useRemoveDeviceUserMutation>;
export type RemoveDeviceUserMutationResult = Apollo.MutationResult<RemoveDeviceUserMutation>;
export type RemoveDeviceUserMutationOptions = Apollo.BaseMutationOptions<RemoveDeviceUserMutation, RemoveDeviceUserMutationVariables>;
export const ListPurchaseReceiptsExistDocument = gql`
    mutation ListPurchaseReceiptsExist($data: [ImportPurchaseReceiptInput]!) {
  excel {
    listPurchaseReceiptsExist(data: $data) {
      total
      data {
        id
        cd
      }
    }
  }
}
    `;
export type ListPurchaseReceiptsExistMutationFn = Apollo.MutationFunction<ListPurchaseReceiptsExistMutation, ListPurchaseReceiptsExistMutationVariables>;

/**
 * __useListPurchaseReceiptsExistMutation__
 *
 * To run a mutation, you first call `useListPurchaseReceiptsExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListPurchaseReceiptsExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listPurchaseReceiptsExistMutation, { data, loading, error }] = useListPurchaseReceiptsExistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListPurchaseReceiptsExistMutation(baseOptions?: Apollo.MutationHookOptions<ListPurchaseReceiptsExistMutation, ListPurchaseReceiptsExistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListPurchaseReceiptsExistMutation, ListPurchaseReceiptsExistMutationVariables>(ListPurchaseReceiptsExistDocument, options);
      }
export type ListPurchaseReceiptsExistMutationHookResult = ReturnType<typeof useListPurchaseReceiptsExistMutation>;
export type ListPurchaseReceiptsExistMutationResult = Apollo.MutationResult<ListPurchaseReceiptsExistMutation>;
export type ListPurchaseReceiptsExistMutationOptions = Apollo.BaseMutationOptions<ListPurchaseReceiptsExistMutation, ListPurchaseReceiptsExistMutationVariables>;
export const ExportCheckoutReasonsDocument = gql`
    query ExportCheckoutReasons {
  excel {
    exportCheckoutReasons
  }
}
    `;

/**
 * __useExportCheckoutReasonsQuery__
 *
 * To run a query within a React component, call `useExportCheckoutReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCheckoutReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCheckoutReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCheckoutReasonsQuery(baseOptions?: Apollo.QueryHookOptions<ExportCheckoutReasonsQuery, ExportCheckoutReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCheckoutReasonsQuery, ExportCheckoutReasonsQueryVariables>(ExportCheckoutReasonsDocument, options);
      }
export function useExportCheckoutReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCheckoutReasonsQuery, ExportCheckoutReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCheckoutReasonsQuery, ExportCheckoutReasonsQueryVariables>(ExportCheckoutReasonsDocument, options);
        }
export type ExportCheckoutReasonsQueryHookResult = ReturnType<typeof useExportCheckoutReasonsQuery>;
export type ExportCheckoutReasonsLazyQueryHookResult = ReturnType<typeof useExportCheckoutReasonsLazyQuery>;
export type ExportCheckoutReasonsQueryResult = Apollo.QueryResult<ExportCheckoutReasonsQuery, ExportCheckoutReasonsQueryVariables>;
export const ExportCommandoListDocument = gql`
    query ExportCommandoList($status: ProgramStatus, $salesmanCd: String, $commandoCd: String) {
  excel {
    exportCommandoList(
      status: $status
      salesmanCd: $salesmanCd
      commandoCd: $commandoCd
    )
  }
}
    `;

/**
 * __useExportCommandoListQuery__
 *
 * To run a query within a React component, call `useExportCommandoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCommandoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCommandoListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      salesmanCd: // value for 'salesmanCd'
 *      commandoCd: // value for 'commandoCd'
 *   },
 * });
 */
export function useExportCommandoListQuery(baseOptions?: Apollo.QueryHookOptions<ExportCommandoListQuery, ExportCommandoListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCommandoListQuery, ExportCommandoListQueryVariables>(ExportCommandoListDocument, options);
      }
export function useExportCommandoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCommandoListQuery, ExportCommandoListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCommandoListQuery, ExportCommandoListQueryVariables>(ExportCommandoListDocument, options);
        }
export type ExportCommandoListQueryHookResult = ReturnType<typeof useExportCommandoListQuery>;
export type ExportCommandoListLazyQueryHookResult = ReturnType<typeof useExportCommandoListLazyQuery>;
export type ExportCommandoListQueryResult = Apollo.QueryResult<ExportCommandoListQuery, ExportCommandoListQueryVariables>;
export const ExportCurrentWarehouseProductsDocument = gql`
    query ExportCurrentWarehouseProducts($distributorId: Int, $warehouseId: Int, $productId: Int) {
  excel {
    exportCurrentWarehouseProducts(
      distributorId: $distributorId
      warehouseId: $warehouseId
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportCurrentWarehouseProductsQuery__
 *
 * To run a query within a React component, call `useExportCurrentWarehouseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCurrentWarehouseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCurrentWarehouseProductsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportCurrentWarehouseProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportCurrentWarehouseProductsQuery, ExportCurrentWarehouseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCurrentWarehouseProductsQuery, ExportCurrentWarehouseProductsQueryVariables>(ExportCurrentWarehouseProductsDocument, options);
      }
export function useExportCurrentWarehouseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCurrentWarehouseProductsQuery, ExportCurrentWarehouseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCurrentWarehouseProductsQuery, ExportCurrentWarehouseProductsQueryVariables>(ExportCurrentWarehouseProductsDocument, options);
        }
export type ExportCurrentWarehouseProductsQueryHookResult = ReturnType<typeof useExportCurrentWarehouseProductsQuery>;
export type ExportCurrentWarehouseProductsLazyQueryHookResult = ReturnType<typeof useExportCurrentWarehouseProductsLazyQuery>;
export type ExportCurrentWarehouseProductsQueryResult = Apollo.QueryResult<ExportCurrentWarehouseProductsQuery, ExportCurrentWarehouseProductsQueryVariables>;
// export const ExportCustomersDocument = gql`
//     query ExportCustomers {
//   excel {
//     exportCustomers
//   }
// }
//     `;


export const ExportCustomersDocument = gql`
    query ExportCustomers($filter: String, $createdDate: Date, $customerStatus : CustomerStatus, $provinceId : Int, $distributorId : Int) {
      exc {
        ExportCustomers(filter: $filter, createdDate: $createdDate,customerStatus: $customerStatus, provinceId :$provinceId, distributorId: $distributorId )
      }
}
    `;

/**
 * __useExportCustomersQuery__
 *
 * To run a query within a React component, call `useExportCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCustomersQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomersQuery, ExportCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomersQuery, ExportCustomersQueryVariables>(ExportCustomersDocument, options);
      }

// export function useExportCustomersQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomersQuery, ExportMasterDataCustomerV2QueryVariables>) {
//         const options = {...defaultOptions, ...baseOptions}
//         return Apollo.useQuery<ExportCustomersQuery, ExportMasterDataCustomerV2QueryVariables>(ExportCustomersDocument, options);
// }
export function useExportCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomersQuery, ExportCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomersQuery, ExportCustomersQueryVariables>(ExportCustomersDocument, options);
        }
export type ExportCustomersQueryHookResult = ReturnType<typeof useExportCustomersQuery>;
export type ExportCustomersLazyQueryHookResult = ReturnType<typeof useExportCustomersLazyQuery>;
export type ExportCustomersQueryResult = Apollo.QueryResult<ExportCustomersQuery, ExportCustomersQueryVariables>;
export const ExportCustomerAttributesDocument = gql`
    query ExportCustomerAttributes {
  excel {
    exportCustomerAttributes
  }
}
    `;

/**
 * __useExportCustomerAttributesQuery__
 *
 * To run a query within a React component, call `useExportCustomerAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomerAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomerAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCustomerAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomerAttributesQuery, ExportCustomerAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomerAttributesQuery, ExportCustomerAttributesQueryVariables>(ExportCustomerAttributesDocument, options);
      }
export function useExportCustomerAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomerAttributesQuery, ExportCustomerAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomerAttributesQuery, ExportCustomerAttributesQueryVariables>(ExportCustomerAttributesDocument, options);
        }
export type ExportCustomerAttributesQueryHookResult = ReturnType<typeof useExportCustomerAttributesQuery>;
export type ExportCustomerAttributesLazyQueryHookResult = ReturnType<typeof useExportCustomerAttributesLazyQuery>;
export type ExportCustomerAttributesQueryResult = Apollo.QueryResult<ExportCustomerAttributesQuery, ExportCustomerAttributesQueryVariables>;
export const ExportCustomerClassDocument = gql`
    query exportCustomerClass {
  excel {
    exportCustomerClass
  }
}
    `;

/**
 * __useExportCustomerClassQuery__
 *
 * To run a query within a React component, call `useExportCustomerClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomerClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomerClassQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCustomerClassQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomerClassQuery, ExportCustomerClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomerClassQuery, ExportCustomerClassQueryVariables>(ExportCustomerClassDocument, options);
      }
export function useExportCustomerClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomerClassQuery, ExportCustomerClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomerClassQuery, ExportCustomerClassQueryVariables>(ExportCustomerClassDocument, options);
        }
export type ExportCustomerClassQueryHookResult = ReturnType<typeof useExportCustomerClassQuery>;
export type ExportCustomerClassLazyQueryHookResult = ReturnType<typeof useExportCustomerClassLazyQuery>;
export type ExportCustomerClassQueryResult = Apollo.QueryResult<ExportCustomerClassQuery, ExportCustomerClassQueryVariables>;
export const ExportCustomerPriceClassDocument = gql`
    query exportCustomerPriceClass {
  excel {
    exportCustomerPriceClass
  }
}
    `;

/**
 * __useExportCustomerPriceClassQuery__
 *
 * To run a query within a React component, call `useExportCustomerPriceClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomerPriceClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomerPriceClassQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCustomerPriceClassQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomerPriceClassQuery, ExportCustomerPriceClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomerPriceClassQuery, ExportCustomerPriceClassQueryVariables>(ExportCustomerPriceClassDocument, options);
      }
export function useExportCustomerPriceClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomerPriceClassQuery, ExportCustomerPriceClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomerPriceClassQuery, ExportCustomerPriceClassQueryVariables>(ExportCustomerPriceClassDocument, options);
        }
export type ExportCustomerPriceClassQueryHookResult = ReturnType<typeof useExportCustomerPriceClassQuery>;
export type ExportCustomerPriceClassLazyQueryHookResult = ReturnType<typeof useExportCustomerPriceClassLazyQuery>;
export type ExportCustomerPriceClassQueryResult = Apollo.QueryResult<ExportCustomerPriceClassQuery, ExportCustomerPriceClassQueryVariables>;
export const ExportCustomerSurveyReportDocument = gql`
    query ExportCustomerSurveyReport($filter: String, $surveyId: Int, $startDate: Date, $endDate: Date, $status: ProgramStatus) {
  excel {
    exportCustomerSurveyReport(
      filter: $filter
      surveyId: $surveyId
      startDate: $startDate
      endDate: $endDate
      status: $status
    )
  }
}
    `;

/**
 * __useExportCustomerSurveyReportQuery__
 *
 * To run a query within a React component, call `useExportCustomerSurveyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomerSurveyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomerSurveyReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      surveyId: // value for 'surveyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExportCustomerSurveyReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportCustomerSurveyReportQuery, ExportCustomerSurveyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomerSurveyReportQuery, ExportCustomerSurveyReportQueryVariables>(ExportCustomerSurveyReportDocument, options);
      }
export function useExportCustomerSurveyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomerSurveyReportQuery, ExportCustomerSurveyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomerSurveyReportQuery, ExportCustomerSurveyReportQueryVariables>(ExportCustomerSurveyReportDocument, options);
        }
export type ExportCustomerSurveyReportQueryHookResult = ReturnType<typeof useExportCustomerSurveyReportQuery>;
export type ExportCustomerSurveyReportLazyQueryHookResult = ReturnType<typeof useExportCustomerSurveyReportLazyQuery>;
export type ExportCustomerSurveyReportQueryResult = Apollo.QueryResult<ExportCustomerSurveyReportQuery, ExportCustomerSurveyReportQueryVariables>;
export const ExportDistributorsDocument = gql`
    query exportDistributors($filter: String) {
  excel {
    exportDistributors(filter: $filter)
  }
}
    `;

/**
 * __useExportDistributorsQuery__
 *
 * To run a query within a React component, call `useExportDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDistributorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<ExportDistributorsQuery, ExportDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDistributorsQuery, ExportDistributorsQueryVariables>(ExportDistributorsDocument, options);
      }
export function useExportDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDistributorsQuery, ExportDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDistributorsQuery, ExportDistributorsQueryVariables>(ExportDistributorsDocument, options);
        }
export type ExportDistributorsQueryHookResult = ReturnType<typeof useExportDistributorsQuery>;
export type ExportDistributorsLazyQueryHookResult = ReturnType<typeof useExportDistributorsLazyQuery>;
export type ExportDistributorsQueryResult = Apollo.QueryResult<ExportDistributorsQuery, ExportDistributorsQueryVariables>;
export const ExportDistributorTypeDocument = gql`
    query exportDistributorType {
  excel {
    exportDistributorType
  }
}
    `;

/**
 * __useExportDistributorTypeQuery__
 *
 * To run a query within a React component, call `useExportDistributorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDistributorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDistributorTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportDistributorTypeQuery(baseOptions?: Apollo.QueryHookOptions<ExportDistributorTypeQuery, ExportDistributorTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDistributorTypeQuery, ExportDistributorTypeQueryVariables>(ExportDistributorTypeDocument, options);
      }
export function useExportDistributorTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDistributorTypeQuery, ExportDistributorTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDistributorTypeQuery, ExportDistributorTypeQueryVariables>(ExportDistributorTypeDocument, options);
        }
export type ExportDistributorTypeQueryHookResult = ReturnType<typeof useExportDistributorTypeQuery>;
export type ExportDistributorTypeLazyQueryHookResult = ReturnType<typeof useExportDistributorTypeLazyQuery>;
export type ExportDistributorTypeQueryResult = Apollo.QueryResult<ExportDistributorTypeQuery, ExportDistributorTypeQueryVariables>;
export const ExportEmployeesDocument = gql`
    query exportEmployees($filter: String) {
  excel {
    exportEmployees(filter: $filter)
  }
}
    `;

/**
 * __useExportEmployeesQuery__
 *
 * To run a query within a React component, call `useExportEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<ExportEmployeesQuery, ExportEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportEmployeesQuery, ExportEmployeesQueryVariables>(ExportEmployeesDocument, options);
      }
export function useExportEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportEmployeesQuery, ExportEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportEmployeesQuery, ExportEmployeesQueryVariables>(ExportEmployeesDocument, options);
        }
export type ExportEmployeesQueryHookResult = ReturnType<typeof useExportEmployeesQuery>;
export type ExportEmployeesLazyQueryHookResult = ReturnType<typeof useExportEmployeesLazyQuery>;
export type ExportEmployeesQueryResult = Apollo.QueryResult<ExportEmployeesQuery, ExportEmployeesQueryVariables>;
export const ExportFollowLoyaltyRegistrationDocument = gql`
    query ExportFollowLoyaltyRegistration($startDate: Date, $endDate: Date, $startDateTrade: Date, $endDateTrade: Date, $statusTrade: ProgramStatus, $tradeId: Int, $filter: String, $status: TradeParticipationStatus) {
  excel {
    exportFollowLoyaltyRegistration(
      startDate: $startDate
      endDate: $endDate
      startDateTrade: $startDateTrade
      endDateTrade: $endDateTrade
      statusTrade: $statusTrade
      tradeId: $tradeId
      filter: $filter
      status: $status
    )
  }
}
    `;

/**
 * __useExportFollowLoyaltyRegistrationQuery__
 *
 * To run a query within a React component, call `useExportFollowLoyaltyRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportFollowLoyaltyRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportFollowLoyaltyRegistrationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDateTrade: // value for 'startDateTrade'
 *      endDateTrade: // value for 'endDateTrade'
 *      statusTrade: // value for 'statusTrade'
 *      tradeId: // value for 'tradeId'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExportFollowLoyaltyRegistrationQuery(baseOptions?: Apollo.QueryHookOptions<ExportFollowLoyaltyRegistrationQuery, ExportFollowLoyaltyRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportFollowLoyaltyRegistrationQuery, ExportFollowLoyaltyRegistrationQueryVariables>(ExportFollowLoyaltyRegistrationDocument, options);
      }
export function useExportFollowLoyaltyRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportFollowLoyaltyRegistrationQuery, ExportFollowLoyaltyRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportFollowLoyaltyRegistrationQuery, ExportFollowLoyaltyRegistrationQueryVariables>(ExportFollowLoyaltyRegistrationDocument, options);
        }
export type ExportFollowLoyaltyRegistrationQueryHookResult = ReturnType<typeof useExportFollowLoyaltyRegistrationQuery>;
export type ExportFollowLoyaltyRegistrationLazyQueryHookResult = ReturnType<typeof useExportFollowLoyaltyRegistrationLazyQuery>;
export type ExportFollowLoyaltyRegistrationQueryResult = Apollo.QueryResult<ExportFollowLoyaltyRegistrationQuery, ExportFollowLoyaltyRegistrationQueryVariables>;
export const ExportFollowPromotionListDocument = gql`
    query ExportFollowPromotionList($startDate: Date, $endDate: Date, $discountId: Int, $filter: String) {
  excel {
    exportFollowPromotionList(
      startDate: $startDate
      endDate: $endDate
      discountId: $discountId
      filter: $filter
    )
  }
}
    `;

/**
 * __useExportFollowPromotionListQuery__
 *
 * To run a query within a React component, call `useExportFollowPromotionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportFollowPromotionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportFollowPromotionListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      discountId: // value for 'discountId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportFollowPromotionListQuery(baseOptions?: Apollo.QueryHookOptions<ExportFollowPromotionListQuery, ExportFollowPromotionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportFollowPromotionListQuery, ExportFollowPromotionListQueryVariables>(ExportFollowPromotionListDocument, options);
      }
export function useExportFollowPromotionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportFollowPromotionListQuery, ExportFollowPromotionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportFollowPromotionListQuery, ExportFollowPromotionListQueryVariables>(ExportFollowPromotionListDocument, options);
        }
export type ExportFollowPromotionListQueryHookResult = ReturnType<typeof useExportFollowPromotionListQuery>;
export type ExportFollowPromotionListLazyQueryHookResult = ReturnType<typeof useExportFollowPromotionListLazyQuery>;
export type ExportFollowPromotionListQueryResult = Apollo.QueryResult<ExportFollowPromotionListQuery, ExportFollowPromotionListQueryVariables>;
export const ExportPromotionSummarizingMoneyDocument = gql`
    query ExportPromotionSummarizingMoney($discountId: Int, $distributorId: Int, $salesRegionId: Int) {
  excel {
    exportPromotionSummarizingMoney(
      discountId: $discountId
      distributorId: $distributorId
      salesRegionId: $salesRegionId
    )
  }
}
    `;

/**
 * __useExportPromotionSummarizingMoneyQuery__
 *
 * To run a query within a React component, call `useExportPromotionSummarizingMoneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPromotionSummarizingMoneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPromotionSummarizingMoneyQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useExportPromotionSummarizingMoneyQuery(baseOptions?: Apollo.QueryHookOptions<ExportPromotionSummarizingMoneyQuery, ExportPromotionSummarizingMoneyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPromotionSummarizingMoneyQuery, ExportPromotionSummarizingMoneyQueryVariables>(ExportPromotionSummarizingMoneyDocument, options);
      }
export function useExportPromotionSummarizingMoneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPromotionSummarizingMoneyQuery, ExportPromotionSummarizingMoneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPromotionSummarizingMoneyQuery, ExportPromotionSummarizingMoneyQueryVariables>(ExportPromotionSummarizingMoneyDocument, options);
        }
export type ExportPromotionSummarizingMoneyQueryHookResult = ReturnType<typeof useExportPromotionSummarizingMoneyQuery>;
export type ExportPromotionSummarizingMoneyLazyQueryHookResult = ReturnType<typeof useExportPromotionSummarizingMoneyLazyQuery>;
export type ExportPromotionSummarizingMoneyQueryResult = Apollo.QueryResult<ExportPromotionSummarizingMoneyQuery, ExportPromotionSummarizingMoneyQueryVariables>;
export const ExportPromotionSummarizingProductDocument = gql`
    query ExportPromotionSummarizingProduct($discountId: Int, $distributorId: Int) {
  excel {
    exportPromotionSummarizingProduct(
      discountId: $discountId
      distributorId: $distributorId
    )
  }
}
    `;

/**
 * __useExportPromotionSummarizingProductQuery__
 *
 * To run a query within a React component, call `useExportPromotionSummarizingProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPromotionSummarizingProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPromotionSummarizingProductQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useExportPromotionSummarizingProductQuery(baseOptions?: Apollo.QueryHookOptions<ExportPromotionSummarizingProductQuery, ExportPromotionSummarizingProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPromotionSummarizingProductQuery, ExportPromotionSummarizingProductQueryVariables>(ExportPromotionSummarizingProductDocument, options);
      }
export function useExportPromotionSummarizingProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPromotionSummarizingProductQuery, ExportPromotionSummarizingProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPromotionSummarizingProductQuery, ExportPromotionSummarizingProductQueryVariables>(ExportPromotionSummarizingProductDocument, options);
        }
export type ExportPromotionSummarizingProductQueryHookResult = ReturnType<typeof useExportPromotionSummarizingProductQuery>;
export type ExportPromotionSummarizingProductLazyQueryHookResult = ReturnType<typeof useExportPromotionSummarizingProductLazyQuery>;
export type ExportPromotionSummarizingProductQueryResult = Apollo.QueryResult<ExportPromotionSummarizingProductQuery, ExportPromotionSummarizingProductQueryVariables>;
export const ExportFollowVisualReportDocument = gql`
    query ExportFollowVisualReport($startDate: Date, $endDate: Date, $visualId: Int, $filter: String, $salesRegionId: Int) {
  excel {
    exportFollowVisualReport(
      startDate: $startDate
      endDate: $endDate
      visualId: $visualId
      filter: $filter
      salesRegionId: $salesRegionId
    )
  }
}
    `;

/**
 * __useExportFollowVisualReportQuery__
 *
 * To run a query within a React component, call `useExportFollowVisualReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportFollowVisualReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportFollowVisualReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      visualId: // value for 'visualId'
 *      filter: // value for 'filter'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useExportFollowVisualReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportFollowVisualReportQuery, ExportFollowVisualReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportFollowVisualReportQuery, ExportFollowVisualReportQueryVariables>(ExportFollowVisualReportDocument, options);
      }
export function useExportFollowVisualReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportFollowVisualReportQuery, ExportFollowVisualReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportFollowVisualReportQuery, ExportFollowVisualReportQueryVariables>(ExportFollowVisualReportDocument, options);
        }
export type ExportFollowVisualReportQueryHookResult = ReturnType<typeof useExportFollowVisualReportQuery>;
export type ExportFollowVisualReportLazyQueryHookResult = ReturnType<typeof useExportFollowVisualReportLazyQuery>;
export type ExportFollowVisualReportQueryResult = Apollo.QueryResult<ExportFollowVisualReportQuery, ExportFollowVisualReportQueryVariables>;
export const ExportKpiEvaluationSalesForceDocument = gql`
    query ExportKpiEvaluationSalesForce($leaderId: Int, $employeeId: Int, $kpiName: String, $startDate: Date, $endDate: Date, $minResult: Decimal, $maxResult: Decimal) {
  excel {
    exportKpiEvaluationSalesForce(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      kpiName: $kpiName
      leaderId: $leaderId
      minResult: $minResult
      maxResult: $maxResult
    )
  }
}
    `;

/**
 * __useExportKpiEvaluationSalesForceQuery__
 *
 * To run a query within a React component, call `useExportKpiEvaluationSalesForceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportKpiEvaluationSalesForceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportKpiEvaluationSalesForceQuery({
 *   variables: {
 *      leaderId: // value for 'leaderId'
 *      employeeId: // value for 'employeeId'
 *      kpiName: // value for 'kpiName'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      minResult: // value for 'minResult'
 *      maxResult: // value for 'maxResult'
 *   },
 * });
 */
export function useExportKpiEvaluationSalesForceQuery(baseOptions?: Apollo.QueryHookOptions<ExportKpiEvaluationSalesForceQuery, ExportKpiEvaluationSalesForceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportKpiEvaluationSalesForceQuery, ExportKpiEvaluationSalesForceQueryVariables>(ExportKpiEvaluationSalesForceDocument, options);
      }
export function useExportKpiEvaluationSalesForceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportKpiEvaluationSalesForceQuery, ExportKpiEvaluationSalesForceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportKpiEvaluationSalesForceQuery, ExportKpiEvaluationSalesForceQueryVariables>(ExportKpiEvaluationSalesForceDocument, options);
        }
export type ExportKpiEvaluationSalesForceQueryHookResult = ReturnType<typeof useExportKpiEvaluationSalesForceQuery>;
export type ExportKpiEvaluationSalesForceLazyQueryHookResult = ReturnType<typeof useExportKpiEvaluationSalesForceLazyQuery>;
export type ExportKpiEvaluationSalesForceQueryResult = Apollo.QueryResult<ExportKpiEvaluationSalesForceQuery, ExportKpiEvaluationSalesForceQueryVariables>;
export const ExportKpisReportDocument = gql`
    query ExportKpisReport($employeeId: Int, $distributorId: Int, $target: KpiApplyTarget, $kpiConfigId: Int) {
  excel {
    exportKpisReport(
      employeeId: $employeeId
      distributorId: $distributorId
      target: $target
      kpiConfigId: $kpiConfigId
    )
  }
}
    `;

/**
 * __useExportKpisReportQuery__
 *
 * To run a query within a React component, call `useExportKpisReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportKpisReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportKpisReportQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      distributorId: // value for 'distributorId'
 *      target: // value for 'target'
 *      kpiConfigId: // value for 'kpiConfigId'
 *   },
 * });
 */
export function useExportKpisReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportKpisReportQuery, ExportKpisReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportKpisReportQuery, ExportKpisReportQueryVariables>(ExportKpisReportDocument, options);
      }
export function useExportKpisReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportKpisReportQuery, ExportKpisReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportKpisReportQuery, ExportKpisReportQueryVariables>(ExportKpisReportDocument, options);
        }
export type ExportKpisReportQueryHookResult = ReturnType<typeof useExportKpisReportQuery>;
export type ExportKpisReportLazyQueryHookResult = ReturnType<typeof useExportKpisReportLazyQuery>;
export type ExportKpisReportQueryResult = Apollo.QueryResult<ExportKpisReportQuery, ExportKpisReportQueryVariables>;
export const ExportLoyaltiesEvaluationDocument = gql`
    query ExportLoyaltiesEvaluation($startDate: Date, $endDate: Date, $tradeId: Int, $filter: String, $tradePassed: Boolean, $breakBy: BreakBy, $totalPassed: Boolean, $visualPassed: Boolean) {
  excel {
    exportLoyaltiesEvaluation(
      startDate: $startDate
      tradeId: $tradeId
      endDate: $endDate
      filter: $filter
      tradePassed: $tradePassed
      breakBy: $breakBy
      totalPassed: $totalPassed
      visualPassed: $visualPassed
    )
  }
}
    `;

/**
 * __useExportLoyaltiesEvaluationQuery__
 *
 * To run a query within a React component, call `useExportLoyaltiesEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportLoyaltiesEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportLoyaltiesEvaluationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tradeId: // value for 'tradeId'
 *      filter: // value for 'filter'
 *      tradePassed: // value for 'tradePassed'
 *      breakBy: // value for 'breakBy'
 *      totalPassed: // value for 'totalPassed'
 *      visualPassed: // value for 'visualPassed'
 *   },
 * });
 */
export function useExportLoyaltiesEvaluationQuery(baseOptions?: Apollo.QueryHookOptions<ExportLoyaltiesEvaluationQuery, ExportLoyaltiesEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportLoyaltiesEvaluationQuery, ExportLoyaltiesEvaluationQueryVariables>(ExportLoyaltiesEvaluationDocument, options);
      }
export function useExportLoyaltiesEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportLoyaltiesEvaluationQuery, ExportLoyaltiesEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportLoyaltiesEvaluationQuery, ExportLoyaltiesEvaluationQueryVariables>(ExportLoyaltiesEvaluationDocument, options);
        }
export type ExportLoyaltiesEvaluationQueryHookResult = ReturnType<typeof useExportLoyaltiesEvaluationQuery>;
export type ExportLoyaltiesEvaluationLazyQueryHookResult = ReturnType<typeof useExportLoyaltiesEvaluationLazyQuery>;
export type ExportLoyaltiesEvaluationQueryResult = Apollo.QueryResult<ExportLoyaltiesEvaluationQuery, ExportLoyaltiesEvaluationQueryVariables>;
export const ExportLoyaltyListDocument = gql`
    query ExportLoyaltyList($startDate: Date, $endDate: Date, $tradeId: Int, $salesRegionId: Int, $status: ProgramStatus, $target: TradeApplyTarget, $filter: String) {
  excel {
    exportLoyaltyList(
      tradeId: $tradeId
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      salesRegionId: $salesRegionId
      status: $status
      target: $target
    )
  }
}
    `;

/**
 * __useExportLoyaltyListQuery__
 *
 * To run a query within a React component, call `useExportLoyaltyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportLoyaltyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportLoyaltyListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tradeId: // value for 'tradeId'
 *      salesRegionId: // value for 'salesRegionId'
 *      status: // value for 'status'
 *      target: // value for 'target'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportLoyaltyListQuery(baseOptions?: Apollo.QueryHookOptions<ExportLoyaltyListQuery, ExportLoyaltyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportLoyaltyListQuery, ExportLoyaltyListQueryVariables>(ExportLoyaltyListDocument, options);
      }
export function useExportLoyaltyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportLoyaltyListQuery, ExportLoyaltyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportLoyaltyListQuery, ExportLoyaltyListQueryVariables>(ExportLoyaltyListDocument, options);
        }
export type ExportLoyaltyListQueryHookResult = ReturnType<typeof useExportLoyaltyListQuery>;
export type ExportLoyaltyListLazyQueryHookResult = ReturnType<typeof useExportLoyaltyListLazyQuery>;
export type ExportLoyaltyListQueryResult = Apollo.QueryResult<ExportLoyaltyListQuery, ExportLoyaltyListQueryVariables>;
export const ExportMcpLineReportDocument = gql`
    query ExportMcpLineReport($startDate: Date, $endDate: Date, $customerFilter: String, $mcpId: Int, $salesmanId: Int, $leaderId: Int, $status: ProgramStatus) {
  exc {
    exportMcpLineReport(
      status: $status
      startDate: $startDate
      endDate: $endDate
      customerFilter: $customerFilter
      mcpId: $mcpId
      leaderId: $leaderId
      salesmanId: $salesmanId
    )
  }
}
    `;

/**
 * __useExportMcpLineReportQuery__
 *
 * To run a query within a React component, call `useExportMcpLineReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMcpLineReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMcpLineReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerFilter: // value for 'customerFilter'
 *      mcpId: // value for 'mcpId'
 *      salesmanId: // value for 'salesmanId'
 *      leaderId: // value for 'leaderId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExportMcpLineReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportMcpLineReportQuery, ExportMcpLineReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMcpLineReportQuery, ExportMcpLineReportQueryVariables>(ExportMcpLineReportDocument, options);
      }
export function useExportMcpLineReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMcpLineReportQuery, ExportMcpLineReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMcpLineReportQuery, ExportMcpLineReportQueryVariables>(ExportMcpLineReportDocument, options);
        }
export type ExportMcpLineReportQueryHookResult = ReturnType<typeof useExportMcpLineReportQuery>;
export type ExportMcpLineReportLazyQueryHookResult = ReturnType<typeof useExportMcpLineReportLazyQuery>;
export type ExportMcpLineReportQueryResult = Apollo.QueryResult<ExportMcpLineReportQuery, ExportMcpLineReportQueryVariables>;
export const ExportMcpListDocument = gql`
    query ExportMcpList($status: ProgramStatus, $salesmanCd: String, $mcpCd: String, $startDate: Date, $endDate: Date, $salesRegionId: Int) {
  excel {
    exportMcpList(
      status: $status
      startDate: $startDate
      endDate: $endDate
      salesmanCd: $salesmanCd
      mcpCd: $mcpCd
      salesRegionId: $salesRegionId
    )
  }
}
    `;

/**
 * __useExportMcpListQuery__
 *
 * To run a query within a React component, call `useExportMcpListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMcpListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMcpListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      salesmanCd: // value for 'salesmanCd'
 *      mcpCd: // value for 'mcpCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useExportMcpListQuery(baseOptions?: Apollo.QueryHookOptions<ExportMcpListQuery, ExportMcpListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMcpListQuery, ExportMcpListQueryVariables>(ExportMcpListDocument, options);
      }
export function useExportMcpListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMcpListQuery, ExportMcpListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMcpListQuery, ExportMcpListQueryVariables>(ExportMcpListDocument, options);
        }
export type ExportMcpListQueryHookResult = ReturnType<typeof useExportMcpListQuery>;
export type ExportMcpListLazyQueryHookResult = ReturnType<typeof useExportMcpListLazyQuery>;
export type ExportMcpListQueryResult = Apollo.QueryResult<ExportMcpListQuery, ExportMcpListQueryVariables>;
export const ExportMasterDataCustomersDocument = gql`
    query ExportMasterDataCustomers($filter: String, $createdDate: Date, $customerStatus : CustomerStatus, $provinceId : Int, $distributorId : Int) {
  exc {
    exportMasterDataCustomers(filter: $filter, createdDate: $createdDate,customerStatus: $customerStatus, provinceId :$provinceId, distributorId: $distributorId )
  }
}
    `;

/**
 * __useExportMasterDataCustomersQuery__
 *
 * To run a query within a React component, call `useExportMasterDataCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMasterDataCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMasterDataCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportMasterDataCustomersQuery(baseOptions?: Apollo.QueryHookOptions<ExportMasterDataCustomersQuery, ExportMasterDataCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMasterDataCustomersQuery, ExportMasterDataCustomersQueryVariables>(ExportMasterDataCustomersDocument, options);
      }
export function useExportMasterDataCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMasterDataCustomersQuery, ExportMasterDataCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMasterDataCustomersQuery, ExportMasterDataCustomersQueryVariables>(ExportMasterDataCustomersDocument, options);
        }
export type ExportMasterDataCustomersQueryHookResult = ReturnType<typeof useExportMasterDataCustomersQuery>;
export type ExportMasterDataCustomersLazyQueryHookResult = ReturnType<typeof useExportMasterDataCustomersLazyQuery>;
export type ExportMasterDataCustomersQueryResult = Apollo.QueryResult<ExportMasterDataCustomersQuery, ExportMasterDataCustomersQueryVariables>;
export const ExportMasterDataDistributorsDocument = gql`
    query ExportMasterDataDistributors($filter: String) {
  excel {
    exportMasterDataDistributors(filter: $filter)
  }
}
    `;

/**
 * __useExportMasterDataDistributorsQuery__
 *
 * To run a query within a React component, call `useExportMasterDataDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMasterDataDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMasterDataDistributorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportMasterDataDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<ExportMasterDataDistributorsQuery, ExportMasterDataDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMasterDataDistributorsQuery, ExportMasterDataDistributorsQueryVariables>(ExportMasterDataDistributorsDocument, options);
      }
export function useExportMasterDataDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMasterDataDistributorsQuery, ExportMasterDataDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMasterDataDistributorsQuery, ExportMasterDataDistributorsQueryVariables>(ExportMasterDataDistributorsDocument, options);
        }
export type ExportMasterDataDistributorsQueryHookResult = ReturnType<typeof useExportMasterDataDistributorsQuery>;
export type ExportMasterDataDistributorsLazyQueryHookResult = ReturnType<typeof useExportMasterDataDistributorsLazyQuery>;
export type ExportMasterDataDistributorsQueryResult = Apollo.QueryResult<ExportMasterDataDistributorsQuery, ExportMasterDataDistributorsQueryVariables>;
export const ExportMasterDataEmployeesDocument = gql`
    query ExportMasterDataEmployees($filter: String, $salesRegionId: Int, $distributorId: Int, $createdDate: Date, $positionId: Int) {
  excel {
    exportMasterDataEmployees(
      filter: $filter
      salesRegionId: $salesRegionId
      distributorId: $distributorId
      createdDate: $createdDate
      positionId: $positionId
    )
  }
}
    `;

/**
 * __useExportMasterDataEmployeesQuery__
 *
 * To run a query within a React component, call `useExportMasterDataEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMasterDataEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMasterDataEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      salesRegionId: // value for 'salesRegionId'
 *      distributorId: // value for 'distributorId'
 *      createdDate: // value for 'createdDate'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useExportMasterDataEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<ExportMasterDataEmployeesQuery, ExportMasterDataEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMasterDataEmployeesQuery, ExportMasterDataEmployeesQueryVariables>(ExportMasterDataEmployeesDocument, options);
      }
export function useExportMasterDataEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMasterDataEmployeesQuery, ExportMasterDataEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMasterDataEmployeesQuery, ExportMasterDataEmployeesQueryVariables>(ExportMasterDataEmployeesDocument, options);
        }
export type ExportMasterDataEmployeesQueryHookResult = ReturnType<typeof useExportMasterDataEmployeesQuery>;
export type ExportMasterDataEmployeesLazyQueryHookResult = ReturnType<typeof useExportMasterDataEmployeesLazyQuery>;
export type ExportMasterDataEmployeesQueryResult = Apollo.QueryResult<ExportMasterDataEmployeesQuery, ExportMasterDataEmployeesQueryVariables>;
export const ExportMasterDataProductsDocument = gql`
    query ExportMasterDataProducts($filter: String) {
  exc {
    exportMasterDataProducts(filter: $filter)
  }
}
    `;

/**
 * __useExportMasterDataProductsQuery__
 *
 * To run a query within a React component, call `useExportMasterDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMasterDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMasterDataProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportMasterDataProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportMasterDataProductsQuery, ExportMasterDataProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMasterDataProductsQuery, ExportMasterDataProductsQueryVariables>(ExportMasterDataProductsDocument, options);
      }
export function useExportMasterDataProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMasterDataProductsQuery, ExportMasterDataProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMasterDataProductsQuery, ExportMasterDataProductsQueryVariables>(ExportMasterDataProductsDocument, options);
        }
export type ExportMasterDataProductsQueryHookResult = ReturnType<typeof useExportMasterDataProductsQuery>;
export type ExportMasterDataProductsLazyQueryHookResult = ReturnType<typeof useExportMasterDataProductsLazyQuery>;
export type ExportMasterDataProductsQueryResult = Apollo.QueryResult<ExportMasterDataProductsQuery, ExportMasterDataProductsQueryVariables>;
export const ExportMeasureUnitsDocument = gql`
    query ExportMeasureUnits {
  excel {
    exportMeasureUnits
  }
}
    `;

/**
 * __useExportMeasureUnitsQuery__
 *
 * To run a query within a React component, call `useExportMeasureUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMeasureUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMeasureUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportMeasureUnitsQuery(baseOptions?: Apollo.QueryHookOptions<ExportMeasureUnitsQuery, ExportMeasureUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMeasureUnitsQuery, ExportMeasureUnitsQueryVariables>(ExportMeasureUnitsDocument, options);
      }
export function useExportMeasureUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMeasureUnitsQuery, ExportMeasureUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMeasureUnitsQuery, ExportMeasureUnitsQueryVariables>(ExportMeasureUnitsDocument, options);
        }
export type ExportMeasureUnitsQueryHookResult = ReturnType<typeof useExportMeasureUnitsQuery>;
export type ExportMeasureUnitsLazyQueryHookResult = ReturnType<typeof useExportMeasureUnitsLazyQuery>;
export type ExportMeasureUnitsQueryResult = Apollo.QueryResult<ExportMeasureUnitsQuery, ExportMeasureUnitsQueryVariables>;
export const ExportOrderRateByStatusDocument = gql`
    query ExportOrderRateByStatus($filter: String, $startDate: Date, $endDate: Date) {
  excel {
    orderRateByStatus(filter: $filter, startDate: $startDate, endDate: $endDate)
  }
}
    `;

/**
 * __useExportOrderRateByStatusQuery__
 *
 * To run a query within a React component, call `useExportOrderRateByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportOrderRateByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportOrderRateByStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportOrderRateByStatusQuery(baseOptions?: Apollo.QueryHookOptions<ExportOrderRateByStatusQuery, ExportOrderRateByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportOrderRateByStatusQuery, ExportOrderRateByStatusQueryVariables>(ExportOrderRateByStatusDocument, options);
      }
export function useExportOrderRateByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportOrderRateByStatusQuery, ExportOrderRateByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportOrderRateByStatusQuery, ExportOrderRateByStatusQueryVariables>(ExportOrderRateByStatusDocument, options);
        }
export type ExportOrderRateByStatusQueryHookResult = ReturnType<typeof useExportOrderRateByStatusQuery>;
export type ExportOrderRateByStatusLazyQueryHookResult = ReturnType<typeof useExportOrderRateByStatusLazyQuery>;
export type ExportOrderRateByStatusQueryResult = Apollo.QueryResult<ExportOrderRateByStatusQuery, ExportOrderRateByStatusQueryVariables>;
export const ExportOtherProductsDocument = gql`
    query exportOtherProducts {
  excel {
    exportOtherProducts
  }
}
    `;

/**
 * __useExportOtherProductsQuery__
 *
 * To run a query within a React component, call `useExportOtherProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportOtherProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportOtherProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportOtherProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportOtherProductsQuery, ExportOtherProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportOtherProductsQuery, ExportOtherProductsQueryVariables>(ExportOtherProductsDocument, options);
      }
export function useExportOtherProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportOtherProductsQuery, ExportOtherProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportOtherProductsQuery, ExportOtherProductsQueryVariables>(ExportOtherProductsDocument, options);
        }
export type ExportOtherProductsQueryHookResult = ReturnType<typeof useExportOtherProductsQuery>;
export type ExportOtherProductsLazyQueryHookResult = ReturnType<typeof useExportOtherProductsLazyQuery>;
export type ExportOtherProductsQueryResult = Apollo.QueryResult<ExportOtherProductsQuery, ExportOtherProductsQueryVariables>;
export const ExportOtherProductStockCountReportDocument = gql`
    query ExportOtherProductStockCountReport($startDate: Date, $endDate: Date, $filter: String, $otherProductId: Int) {
  excel {
    exportOtherProductStockCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      otherProductId: $otherProductId
    )
  }
}
    `;

/**
 * __useExportOtherProductStockCountReportQuery__
 *
 * To run a query within a React component, call `useExportOtherProductStockCountReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportOtherProductStockCountReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportOtherProductStockCountReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *      otherProductId: // value for 'otherProductId'
 *   },
 * });
 */
export function useExportOtherProductStockCountReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportOtherProductStockCountReportQuery, ExportOtherProductStockCountReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportOtherProductStockCountReportQuery, ExportOtherProductStockCountReportQueryVariables>(ExportOtherProductStockCountReportDocument, options);
      }
export function useExportOtherProductStockCountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportOtherProductStockCountReportQuery, ExportOtherProductStockCountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportOtherProductStockCountReportQuery, ExportOtherProductStockCountReportQueryVariables>(ExportOtherProductStockCountReportDocument, options);
        }
export type ExportOtherProductStockCountReportQueryHookResult = ReturnType<typeof useExportOtherProductStockCountReportQuery>;
export type ExportOtherProductStockCountReportLazyQueryHookResult = ReturnType<typeof useExportOtherProductStockCountReportLazyQuery>;
export type ExportOtherProductStockCountReportQueryResult = Apollo.QueryResult<ExportOtherProductStockCountReportQuery, ExportOtherProductStockCountReportQueryVariables>;
export const ExportPendingWarehouseProductsDocument = gql`
    query ExportPendingWarehouseProducts($source: TransactionSource, $distributorId: Int, $warehouseId: Int, $productId: Int) {
  excel {
    exportPendingWarehouseProducts(
      source: $source
      distributorId: $distributorId
      warehouseId: $warehouseId
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportPendingWarehouseProductsQuery__
 *
 * To run a query within a React component, call `useExportPendingWarehouseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPendingWarehouseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPendingWarehouseProductsQuery({
 *   variables: {
 *      source: // value for 'source'
 *      distributorId: // value for 'distributorId'
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportPendingWarehouseProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPendingWarehouseProductsQuery, ExportPendingWarehouseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPendingWarehouseProductsQuery, ExportPendingWarehouseProductsQueryVariables>(ExportPendingWarehouseProductsDocument, options);
      }
export function useExportPendingWarehouseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPendingWarehouseProductsQuery, ExportPendingWarehouseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPendingWarehouseProductsQuery, ExportPendingWarehouseProductsQueryVariables>(ExportPendingWarehouseProductsDocument, options);
        }
export type ExportPendingWarehouseProductsQueryHookResult = ReturnType<typeof useExportPendingWarehouseProductsQuery>;
export type ExportPendingWarehouseProductsLazyQueryHookResult = ReturnType<typeof useExportPendingWarehouseProductsLazyQuery>;
export type ExportPendingWarehouseProductsQueryResult = Apollo.QueryResult<ExportPendingWarehouseProductsQuery, ExportPendingWarehouseProductsQueryVariables>;
export const ExportPercentOrderCompleteDocument = gql`
    query ExportPercentOrderComplete($startDate: Date, $endDate: Date, $customerId: Int, $distributorId: Int, $salesmanId: Int, $minValue: Decimal, $maxValue: Decimal) {
  excel {
    exportPercentOrderComplete(
      startDate: $startDate
      endDate: $endDate
      customerId: $customerId
      distributorId: $distributorId
      salesmanId: $salesmanId
      minValue: $minValue
      maxValue: $maxValue
    )
  }
}
    `;

/**
 * __useExportPercentOrderCompleteQuery__
 *
 * To run a query within a React component, call `useExportPercentOrderCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPercentOrderCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPercentOrderCompleteQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerId: // value for 'customerId'
 *      distributorId: // value for 'distributorId'
 *      salesmanId: // value for 'salesmanId'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useExportPercentOrderCompleteQuery(baseOptions?: Apollo.QueryHookOptions<ExportPercentOrderCompleteQuery, ExportPercentOrderCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPercentOrderCompleteQuery, ExportPercentOrderCompleteQueryVariables>(ExportPercentOrderCompleteDocument, options);
      }
export function useExportPercentOrderCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPercentOrderCompleteQuery, ExportPercentOrderCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPercentOrderCompleteQuery, ExportPercentOrderCompleteQueryVariables>(ExportPercentOrderCompleteDocument, options);
        }
export type ExportPercentOrderCompleteQueryHookResult = ReturnType<typeof useExportPercentOrderCompleteQuery>;
export type ExportPercentOrderCompleteLazyQueryHookResult = ReturnType<typeof useExportPercentOrderCompleteLazyQuery>;
export type ExportPercentOrderCompleteQueryResult = Apollo.QueryResult<ExportPercentOrderCompleteQuery, ExportPercentOrderCompleteQueryVariables>;
export const ExportReportPerformanceDistributorDocument = gql`
    query ExportReportPerformanceDistributor($startDate: Date, $endDate: Date, $distributorId: Int, $productId: Int) {
  excel {
    exportReportPerformanceDistributor(
      startDate: $startDate
      endDate: $endDate
      distributorId: $distributorId
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportReportPerformanceDistributorQuery__
 *
 * To run a query within a React component, call `useExportReportPerformanceDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportPerformanceDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportPerformanceDistributorQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      distributorId: // value for 'distributorId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportReportPerformanceDistributorQuery(baseOptions?: Apollo.QueryHookOptions<ExportReportPerformanceDistributorQuery, ExportReportPerformanceDistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportReportPerformanceDistributorQuery, ExportReportPerformanceDistributorQueryVariables>(ExportReportPerformanceDistributorDocument, options);
      }
export function useExportReportPerformanceDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportReportPerformanceDistributorQuery, ExportReportPerformanceDistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportReportPerformanceDistributorQuery, ExportReportPerformanceDistributorQueryVariables>(ExportReportPerformanceDistributorDocument, options);
        }
export type ExportReportPerformanceDistributorQueryHookResult = ReturnType<typeof useExportReportPerformanceDistributorQuery>;
export type ExportReportPerformanceDistributorLazyQueryHookResult = ReturnType<typeof useExportReportPerformanceDistributorLazyQuery>;
export type ExportReportPerformanceDistributorQueryResult = Apollo.QueryResult<ExportReportPerformanceDistributorQuery, ExportReportPerformanceDistributorQueryVariables>;
export const ExportPositionsDocument = gql`
    query ExportPositions {
  excel {
    exportPositions
  }
}
    `;

/**
 * __useExportPositionsQuery__
 *
 * To run a query within a React component, call `useExportPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportPositionsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPositionsQuery, ExportPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPositionsQuery, ExportPositionsQueryVariables>(ExportPositionsDocument, options);
      }
export function useExportPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPositionsQuery, ExportPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPositionsQuery, ExportPositionsQueryVariables>(ExportPositionsDocument, options);
        }
export type ExportPositionsQueryHookResult = ReturnType<typeof useExportPositionsQuery>;
export type ExportPositionsLazyQueryHookResult = ReturnType<typeof useExportPositionsLazyQuery>;
export type ExportPositionsQueryResult = Apollo.QueryResult<ExportPositionsQuery, ExportPositionsQueryVariables>;
export const ProcedureExportDocument = gql`
    query ProcedureExport($procedureName: String) {
  excel {
    procedureExport(procedureName: $procedureName)
  }
}
    `;

/**
 * __useProcedureExportQuery__
 *
 * To run a query within a React component, call `useProcedureExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcedureExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcedureExportQuery({
 *   variables: {
 *      procedureName: // value for 'procedureName'
 *   },
 * });
 */
export function useProcedureExportQuery(baseOptions?: Apollo.QueryHookOptions<ProcedureExportQuery, ProcedureExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcedureExportQuery, ProcedureExportQueryVariables>(ProcedureExportDocument, options);
      }
export function useProcedureExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcedureExportQuery, ProcedureExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcedureExportQuery, ProcedureExportQueryVariables>(ProcedureExportDocument, options);
        }
export type ProcedureExportQueryHookResult = ReturnType<typeof useProcedureExportQuery>;
export type ProcedureExportLazyQueryHookResult = ReturnType<typeof useProcedureExportLazyQuery>;
export type ProcedureExportQueryResult = Apollo.QueryResult<ProcedureExportQuery, ProcedureExportQueryVariables>;
export const ExportProductAttributesDocument = gql`
    query exportProductAttributes {
  excel {
    exportProductAttributes
  }
}
    `;

/**
 * __useExportProductAttributesQuery__
 *
 * To run a query within a React component, call `useExportProductAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProductAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProductAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportProductAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ExportProductAttributesQuery, ExportProductAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProductAttributesQuery, ExportProductAttributesQueryVariables>(ExportProductAttributesDocument, options);
      }
export function useExportProductAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProductAttributesQuery, ExportProductAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProductAttributesQuery, ExportProductAttributesQueryVariables>(ExportProductAttributesDocument, options);
        }
export type ExportProductAttributesQueryHookResult = ReturnType<typeof useExportProductAttributesQuery>;
export type ExportProductAttributesLazyQueryHookResult = ReturnType<typeof useExportProductAttributesLazyQuery>;
export type ExportProductAttributesQueryResult = Apollo.QueryResult<ExportProductAttributesQuery, ExportProductAttributesQueryVariables>;
export const ExportProductsGroupsDocument = gql`
    query ExportProductsGroups {
  excel {
    exportProductsGroups
  }
}
    `;

/**
 * __useExportProductsGroupsQuery__
 *
 * To run a query within a React component, call `useExportProductsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProductsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProductsGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportProductsGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ExportProductsGroupsQuery, ExportProductsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProductsGroupsQuery, ExportProductsGroupsQueryVariables>(ExportProductsGroupsDocument, options);
      }
export function useExportProductsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProductsGroupsQuery, ExportProductsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProductsGroupsQuery, ExportProductsGroupsQueryVariables>(ExportProductsGroupsDocument, options);
        }
export type ExportProductsGroupsQueryHookResult = ReturnType<typeof useExportProductsGroupsQuery>;
export type ExportProductsGroupsLazyQueryHookResult = ReturnType<typeof useExportProductsGroupsLazyQuery>;
export type ExportProductsGroupsQueryResult = Apollo.QueryResult<ExportProductsGroupsQuery, ExportProductsGroupsQueryVariables>;
export const ExportProductsDocument = gql`
    query exportProducts($filter: String) {
  exc {
    exportProducts(filter: $filter)
  }
}
    `;

/**
 * __useExportProductsQuery__
 *
 * To run a query within a React component, call `useExportProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportProductsQuery, ExportProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProductsQuery, ExportProductsQueryVariables>(ExportProductsDocument, options);
      }
export function useExportProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProductsQuery, ExportProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProductsQuery, ExportProductsQueryVariables>(ExportProductsDocument, options);
        }
export type ExportProductsQueryHookResult = ReturnType<typeof useExportProductsQuery>;
export type ExportProductsLazyQueryHookResult = ReturnType<typeof useExportProductsLazyQuery>;
export type ExportProductsQueryResult = Apollo.QueryResult<ExportProductsQuery, ExportProductsQueryVariables>;
export const ExportProductStockCountReportDocument = gql`
    query ExportProductStockCountReport($startDate: Date, $endDate: Date, $filter: String, $productId: Int, $mcpId: Int, $salesmanId: Int) {
  excel {
    exportProductStockCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      productId: $productId
      mcpId: $mcpId
      salesmanId: $salesmanId
    )
  }
}
    `;
export const ExportSellProductStockCountReportDocument = gql`
query ExportSellProductStockCountReport($startDate: Date, $endDate: Date, $filter: String, $productId: Int, $mcpId: Int, $salesmanId: Int) {
  excel {
    exportSellProductCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      productId: $productId
      mcpId: $mcpId
      salesmanId: $salesmanId
    )
  }
}
`;

/**
 * __useExportProductStockCountReportQuery__
 *
 * To run a query within a React component, call `useExportProductStockCountReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProductStockCountReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProductStockCountReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *      productId: // value for 'productId'
 *      mcpId: // value for 'mcpId'
 *      salesmanId: // value for 'salesmanId'
 *   },
 * });
 */
export function useExportProductStockCountReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>(ExportProductStockCountReportDocument, options);
      }
export function useExportProductStockCountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>(ExportProductStockCountReportDocument, options);
        }
export function useExportSellProductStockCountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSellProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSellProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>(ExportSellProductStockCountReportDocument, options);
}
export type ExportProductStockCountReportQueryHookResult = ReturnType<typeof useExportProductStockCountReportQuery>;
export type ExportProductStockCountReportLazyQueryHookResult = ReturnType<typeof useExportProductStockCountReportLazyQuery>;
export type ExportProductStockCountReportQueryResult = Apollo.QueryResult<ExportProductStockCountReportQuery, ExportProductStockCountReportQueryVariables>;
export const ExportDiscountDiscountListDocument = gql`
    query ExportDiscountDiscountList($filter: String, $status: ProgramStatus, $discountCd: String, $startDate: Date, $endDate: Date, $creatorCd: String, $isBundle: Int, $isFirstOrder: Int, $externalReference: String, $salesRegionId: Int) {
  excel {
    exportDiscount(
      filter: $filter
      status: $status
      discountCd: $discountCd
      startDate: $startDate
      endDate: $endDate
      creatorCd: $creatorCd
      isBundle: $isBundle
      isFirstOrder: $isFirstOrder
      externalReference: $externalReference
      salesRegionId: $salesRegionId
    )
  }
}
    `;

/**
 * __useExportDiscountDiscountListQuery__
 *
 * To run a query within a React component, call `useExportDiscountDiscountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDiscountDiscountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDiscountDiscountListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      discountCd: // value for 'discountCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      creatorCd: // value for 'creatorCd'
 *      isBundle: // value for 'isBundle'
 *      isFirstOrder: // value for 'isFirstOrder'
 *      externalReference: // value for 'externalReference'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useExportDiscountDiscountListQuery(baseOptions?: Apollo.QueryHookOptions<ExportDiscountDiscountListQuery, ExportDiscountDiscountListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDiscountDiscountListQuery, ExportDiscountDiscountListQueryVariables>(ExportDiscountDiscountListDocument, options);
      }
export function useExportDiscountDiscountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDiscountDiscountListQuery, ExportDiscountDiscountListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDiscountDiscountListQuery, ExportDiscountDiscountListQueryVariables>(ExportDiscountDiscountListDocument, options);
        }
export type ExportDiscountDiscountListQueryHookResult = ReturnType<typeof useExportDiscountDiscountListQuery>;
export type ExportDiscountDiscountListLazyQueryHookResult = ReturnType<typeof useExportDiscountDiscountListLazyQuery>;
export type ExportDiscountDiscountListQueryResult = Apollo.QueryResult<ExportDiscountDiscountListQuery, ExportDiscountDiscountListQueryVariables>;
export const ExportKpiConfigDocument = gql`
    query ExportKpiConfig($name: String, $status: ProgramStatus, $description: String, $kpiConfigCd: String, $startDate: Date, $endDate: Date) {
  excel {
    exportKpiConfig(
      name: $name
      status: $status
      description: $description
      kpiConfigCd: $kpiConfigCd
      startDate: $startDate
      endDate: $endDate
    )
  }
}
    `;

/**
 * __useExportKpiConfigQuery__
 *
 * To run a query within a React component, call `useExportKpiConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportKpiConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportKpiConfigQuery({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      description: // value for 'description'
 *      kpiConfigCd: // value for 'kpiConfigCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportKpiConfigQuery(baseOptions?: Apollo.QueryHookOptions<ExportKpiConfigQuery, ExportKpiConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportKpiConfigQuery, ExportKpiConfigQueryVariables>(ExportKpiConfigDocument, options);
      }
export function useExportKpiConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportKpiConfigQuery, ExportKpiConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportKpiConfigQuery, ExportKpiConfigQueryVariables>(ExportKpiConfigDocument, options);
        }
export type ExportKpiConfigQueryHookResult = ReturnType<typeof useExportKpiConfigQuery>;
export type ExportKpiConfigLazyQueryHookResult = ReturnType<typeof useExportKpiConfigLazyQuery>;
export type ExportKpiConfigQueryResult = Apollo.QueryResult<ExportKpiConfigQuery, ExportKpiConfigQueryVariables>;
export const ExportKpiFormulasDocument = gql`
    query ExportKPIFormulas($name: String, $status: ProgramStatus, $formulas: KpiFormulaType, $kpiFormulaCd: String) {
  excel {
    exportKPIFormulas(
      name: $name
      status: $status
      formulas: $formulas
      kpiFormulaCd: $kpiFormulaCd
    )
  }
}
    `;

/**
 * __useExportKpiFormulasQuery__
 *
 * To run a query within a React component, call `useExportKpiFormulasQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportKpiFormulasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportKpiFormulasQuery({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      formulas: // value for 'formulas'
 *      kpiFormulaCd: // value for 'kpiFormulaCd'
 *   },
 * });
 */
export function useExportKpiFormulasQuery(baseOptions?: Apollo.QueryHookOptions<ExportKpiFormulasQuery, ExportKpiFormulasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportKpiFormulasQuery, ExportKpiFormulasQueryVariables>(ExportKpiFormulasDocument, options);
      }
export function useExportKpiFormulasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportKpiFormulasQuery, ExportKpiFormulasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportKpiFormulasQuery, ExportKpiFormulasQueryVariables>(ExportKpiFormulasDocument, options);
        }
export type ExportKpiFormulasQueryHookResult = ReturnType<typeof useExportKpiFormulasQuery>;
export type ExportKpiFormulasLazyQueryHookResult = ReturnType<typeof useExportKpiFormulasLazyQuery>;
export type ExportKpiFormulasQueryResult = Apollo.QueryResult<ExportKpiFormulasQuery, ExportKpiFormulasQueryVariables>;
export const ExportSurveysDocument = gql`
    query ExportSurveys($name: String, $status: ProgramStatus, $description: String, $surveyCd: String) {
  excel {
    exportSurveys(
      name: $name
      status: $status
      description: $description
      surveyCd: $surveyCd
    )
  }
}
    `;

/**
 * __useExportSurveysQuery__
 *
 * To run a query within a React component, call `useExportSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSurveysQuery({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      description: // value for 'description'
 *      surveyCd: // value for 'surveyCd'
 *   },
 * });
 */
export function useExportSurveysQuery(baseOptions?: Apollo.QueryHookOptions<ExportSurveysQuery, ExportSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSurveysQuery, ExportSurveysQueryVariables>(ExportSurveysDocument, options);
      }
export function useExportSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSurveysQuery, ExportSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSurveysQuery, ExportSurveysQueryVariables>(ExportSurveysDocument, options);
        }
export type ExportSurveysQueryHookResult = ReturnType<typeof useExportSurveysQuery>;
export type ExportSurveysLazyQueryHookResult = ReturnType<typeof useExportSurveysLazyQuery>;
export type ExportSurveysQueryResult = Apollo.QueryResult<ExportSurveysQuery, ExportSurveysQueryVariables>;
export const ExportTradeDocument = gql`
    query ExportTrade($filter: String, $status: ProgramStatus, $tradeCd: String, $salesRegionId: Int) {
  excel {
    exportTrade(
      filter: $filter
      status: $status
      tradeCd: $tradeCd
      salesRegionId: $salesRegionId
    )
  }
}
    `;

/**
 * __useExportTradeQuery__
 *
 * To run a query within a React component, call `useExportTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTradeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      tradeCd: // value for 'tradeCd'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useExportTradeQuery(baseOptions?: Apollo.QueryHookOptions<ExportTradeQuery, ExportTradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportTradeQuery, ExportTradeQueryVariables>(ExportTradeDocument, options);
      }
export function useExportTradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportTradeQuery, ExportTradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportTradeQuery, ExportTradeQueryVariables>(ExportTradeDocument, options);
        }
export type ExportTradeQueryHookResult = ReturnType<typeof useExportTradeQuery>;
export type ExportTradeLazyQueryHookResult = ReturnType<typeof useExportTradeLazyQuery>;
export type ExportTradeQueryResult = Apollo.QueryResult<ExportTradeQuery, ExportTradeQueryVariables>;
export const ExportVisualDocument = gql`
    query ExportVisual($filter: String, $status: ProgramStatus, $visualCd: String) {
  excel {
    exportVisual(filter: $filter, status: $status, visualCd: $visualCd)
  }
}
    `;

/**
 * __useExportVisualQuery__
 *
 * To run a query within a React component, call `useExportVisualQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVisualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVisualQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      visualCd: // value for 'visualCd'
 *   },
 * });
 */
export function useExportVisualQuery(baseOptions?: Apollo.QueryHookOptions<ExportVisualQuery, ExportVisualQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportVisualQuery, ExportVisualQueryVariables>(ExportVisualDocument, options);
      }
export function useExportVisualLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportVisualQuery, ExportVisualQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportVisualQuery, ExportVisualQueryVariables>(ExportVisualDocument, options);
        }
export type ExportVisualQueryHookResult = ReturnType<typeof useExportVisualQuery>;
export type ExportVisualLazyQueryHookResult = ReturnType<typeof useExportVisualLazyQuery>;
export type ExportVisualQueryResult = Apollo.QueryResult<ExportVisualQuery, ExportVisualQueryVariables>;
export const ExportPromotionFollowDistributorDocument = gql`
    query ExportPromotionFollowDistributor($discountId: Int, $distributorId: Int) {
  excel {
    exportPromotionFollowDistributor(
      discountId: $discountId
      distributorId: $distributorId
    )
  }
}
    `;

/**
 * __useExportPromotionFollowDistributorQuery__
 *
 * To run a query within a React component, call `useExportPromotionFollowDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPromotionFollowDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPromotionFollowDistributorQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useExportPromotionFollowDistributorQuery(baseOptions?: Apollo.QueryHookOptions<ExportPromotionFollowDistributorQuery, ExportPromotionFollowDistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPromotionFollowDistributorQuery, ExportPromotionFollowDistributorQueryVariables>(ExportPromotionFollowDistributorDocument, options);
      }
export function useExportPromotionFollowDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPromotionFollowDistributorQuery, ExportPromotionFollowDistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPromotionFollowDistributorQuery, ExportPromotionFollowDistributorQueryVariables>(ExportPromotionFollowDistributorDocument, options);
        }
export type ExportPromotionFollowDistributorQueryHookResult = ReturnType<typeof useExportPromotionFollowDistributorQuery>;
export type ExportPromotionFollowDistributorLazyQueryHookResult = ReturnType<typeof useExportPromotionFollowDistributorLazyQuery>;
export type ExportPromotionFollowDistributorQueryResult = Apollo.QueryResult<ExportPromotionFollowDistributorQuery, ExportPromotionFollowDistributorQueryVariables>;
export const ExportPromotionListDocument = gql`
    query ExportPromotionList($date: Date, $discountId: Int, $filter: String) {
  excel {
    exportPromotionList(date: $date, filter: $filter, discountId: $discountId)
  }
}
    `;

/**
 * __useExportPromotionListQuery__
 *
 * To run a query within a React component, call `useExportPromotionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPromotionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPromotionListQuery({
 *   variables: {
 *      date: // value for 'date'
 *      discountId: // value for 'discountId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportPromotionListQuery(baseOptions?: Apollo.QueryHookOptions<ExportPromotionListQuery, ExportPromotionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPromotionListQuery, ExportPromotionListQueryVariables>(ExportPromotionListDocument, options);
      }
export function useExportPromotionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPromotionListQuery, ExportPromotionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPromotionListQuery, ExportPromotionListQueryVariables>(ExportPromotionListDocument, options);
        }
export type ExportPromotionListQueryHookResult = ReturnType<typeof useExportPromotionListQuery>;
export type ExportPromotionListLazyQueryHookResult = ReturnType<typeof useExportPromotionListLazyQuery>;
export type ExportPromotionListQueryResult = Apollo.QueryResult<ExportPromotionListQuery, ExportPromotionListQueryVariables>;
export const ExportPurchaseByDistributorsDocument = gql`
    query ExportPurchaseByDistributors($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  excel {
    exportPurchaseByDistributors(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportPurchaseByDistributorsQuery__
 *
 * To run a query within a React component, call `useExportPurchaseByDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPurchaseByDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPurchaseByDistributorsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportPurchaseByDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPurchaseByDistributorsQuery, ExportPurchaseByDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPurchaseByDistributorsQuery, ExportPurchaseByDistributorsQueryVariables>(ExportPurchaseByDistributorsDocument, options);
      }
export function useExportPurchaseByDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPurchaseByDistributorsQuery, ExportPurchaseByDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPurchaseByDistributorsQuery, ExportPurchaseByDistributorsQueryVariables>(ExportPurchaseByDistributorsDocument, options);
        }
export type ExportPurchaseByDistributorsQueryHookResult = ReturnType<typeof useExportPurchaseByDistributorsQuery>;
export type ExportPurchaseByDistributorsLazyQueryHookResult = ReturnType<typeof useExportPurchaseByDistributorsLazyQuery>;
export type ExportPurchaseByDistributorsQueryResult = Apollo.QueryResult<ExportPurchaseByDistributorsQuery, ExportPurchaseByDistributorsQueryVariables>;
export const ExportPurchaseByProductsDocument = gql`
    query ExportPurchaseByProducts($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  excel {
    exportPurchaseByProducts(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportPurchaseByProductsQuery__
 *
 * To run a query within a React component, call `useExportPurchaseByProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPurchaseByProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPurchaseByProductsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportPurchaseByProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPurchaseByProductsQuery, ExportPurchaseByProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPurchaseByProductsQuery, ExportPurchaseByProductsQueryVariables>(ExportPurchaseByProductsDocument, options);
      }
export function useExportPurchaseByProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPurchaseByProductsQuery, ExportPurchaseByProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPurchaseByProductsQuery, ExportPurchaseByProductsQueryVariables>(ExportPurchaseByProductsDocument, options);
        }
export type ExportPurchaseByProductsQueryHookResult = ReturnType<typeof useExportPurchaseByProductsQuery>;
export type ExportPurchaseByProductsLazyQueryHookResult = ReturnType<typeof useExportPurchaseByProductsLazyQuery>;
export type ExportPurchaseByProductsQueryResult = Apollo.QueryResult<ExportPurchaseByProductsQuery, ExportPurchaseByProductsQueryVariables>;
export const ExportPurchaseOrderDetailsDocument = gql`
    query ExportPurchaseOrderDetails($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int, $vendorId: Int, $purchaseReceiptCd: String, $minValue: Decimal, $maxValue: Decimal) {
  excel {
    exportPurchaseOrderDetails(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
      vendorId: $vendorId
      purchaseReceiptCd: $purchaseReceiptCd
      minValue: $minValue
      maxValue: $maxValue
    )
  }
}
    `;

/**
 * __useExportPurchaseOrderDetailsQuery__
 *
 * To run a query within a React component, call `useExportPurchaseOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPurchaseOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPurchaseOrderDetailsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *      vendorId: // value for 'vendorId'
 *      purchaseReceiptCd: // value for 'purchaseReceiptCd'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useExportPurchaseOrderDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPurchaseOrderDetailsQuery, ExportPurchaseOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPurchaseOrderDetailsQuery, ExportPurchaseOrderDetailsQueryVariables>(ExportPurchaseOrderDetailsDocument, options);
      }
export function useExportPurchaseOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPurchaseOrderDetailsQuery, ExportPurchaseOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPurchaseOrderDetailsQuery, ExportPurchaseOrderDetailsQueryVariables>(ExportPurchaseOrderDetailsDocument, options);
        }
export type ExportPurchaseOrderDetailsQueryHookResult = ReturnType<typeof useExportPurchaseOrderDetailsQuery>;
export type ExportPurchaseOrderDetailsLazyQueryHookResult = ReturnType<typeof useExportPurchaseOrderDetailsLazyQuery>;
export type ExportPurchaseOrderDetailsQueryResult = Apollo.QueryResult<ExportPurchaseOrderDetailsQuery, ExportPurchaseOrderDetailsQueryVariables>;
export const ExportPurchaseReceiptsDocument = gql`
    query ExportPurchaseReceipts($startDate: Date, $endDate: Date, $status: OrderStatus, $vendorCd: String, $distributorCd: String, $employeeId: Int, $purchaseReceiptCd: String) {
  excel {
    exportPurchaseReceipts(
      status: $status
      startDate: $startDate
      endDate: $endDate
      vendorCd: $vendorCd
      distributorCd: $distributorCd
      employeeId: $employeeId
      purchaseReceiptCd: $purchaseReceiptCd
    )
  }
}
    `;

/**
 * __useExportPurchaseReceiptsQuery__
 *
 * To run a query within a React component, call `useExportPurchaseReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPurchaseReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPurchaseReceiptsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      vendorCd: // value for 'vendorCd'
 *      distributorCd: // value for 'distributorCd'
 *      employeeId: // value for 'employeeId'
 *      purchaseReceiptCd: // value for 'purchaseReceiptCd'
 *   },
 * });
 */
export function useExportPurchaseReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<ExportPurchaseReceiptsQuery, ExportPurchaseReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPurchaseReceiptsQuery, ExportPurchaseReceiptsQueryVariables>(ExportPurchaseReceiptsDocument, options);
      }
export function useExportPurchaseReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPurchaseReceiptsQuery, ExportPurchaseReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPurchaseReceiptsQuery, ExportPurchaseReceiptsQueryVariables>(ExportPurchaseReceiptsDocument, options);
        }
export type ExportPurchaseReceiptsQueryHookResult = ReturnType<typeof useExportPurchaseReceiptsQuery>;
export type ExportPurchaseReceiptsLazyQueryHookResult = ReturnType<typeof useExportPurchaseReceiptsLazyQuery>;
export type ExportPurchaseReceiptsQueryResult = Apollo.QueryResult<ExportPurchaseReceiptsQuery, ExportPurchaseReceiptsQueryVariables>;
export const ExportReportQualityOutletDocument = gql`
    query ExportReportQualityOutlet($startDate: Date, $endDate: Date, $salesmanId: Int, $attrDetailId: Int) {
  exc {
    exportReportQualityOutlet(
      startDate: $startDate
      endDate: $endDate
      salesmanId: $salesmanId
      attrDetailId: $attrDetailId
    )
  }
}
    `;

/**
 * __useExportReportQualityOutletQuery__
 *
 * To run a query within a React component, call `useExportReportQualityOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportQualityOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportQualityOutletQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesmanId: // value for 'salesmanId'
 *      attrDetailId: // value for 'attrDetailId'
 *   },
 * });
 */
export function useExportReportQualityOutletQuery(baseOptions?: Apollo.QueryHookOptions<ExportReportQualityOutletQuery, ExportReportQualityOutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportReportQualityOutletQuery, ExportReportQualityOutletQueryVariables>(ExportReportQualityOutletDocument, options);
      }
export function useExportReportQualityOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportReportQualityOutletQuery, ExportReportQualityOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportReportQualityOutletQuery, ExportReportQualityOutletQueryVariables>(ExportReportQualityOutletDocument, options);
        }
export type ExportReportQualityOutletQueryHookResult = ReturnType<typeof useExportReportQualityOutletQuery>;
export type ExportReportQualityOutletLazyQueryHookResult = ReturnType<typeof useExportReportQualityOutletLazyQuery>;
export type ExportReportQualityOutletQueryResult = Apollo.QueryResult<ExportReportQualityOutletQuery, ExportReportQualityOutletQueryVariables>;
export const ExportReasonEditOrdersListDocument = gql`
    query ExportReasonEditOrdersList {
  excel {
    exportReasonEditOrdersList
  }
}
    `;

/**
 * __useExportReasonEditOrdersListQuery__
 *
 * To run a query within a React component, call `useExportReasonEditOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReasonEditOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReasonEditOrdersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportReasonEditOrdersListQuery(baseOptions?: Apollo.QueryHookOptions<ExportReasonEditOrdersListQuery, ExportReasonEditOrdersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportReasonEditOrdersListQuery, ExportReasonEditOrdersListQueryVariables>(ExportReasonEditOrdersListDocument, options);
      }
export function useExportReasonEditOrdersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportReasonEditOrdersListQuery, ExportReasonEditOrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportReasonEditOrdersListQuery, ExportReasonEditOrdersListQueryVariables>(ExportReasonEditOrdersListDocument, options);
        }
export type ExportReasonEditOrdersListQueryHookResult = ReturnType<typeof useExportReasonEditOrdersListQuery>;
export type ExportReasonEditOrdersListLazyQueryHookResult = ReturnType<typeof useExportReasonEditOrdersListLazyQuery>;
export type ExportReasonEditOrdersListQueryResult = Apollo.QueryResult<ExportReasonEditOrdersListQuery, ExportReasonEditOrdersListQueryVariables>;
export const ExportWarehouseXntDetailsDocument = gql`
    query ExportWarehouseXntDetails($startDate: Date, $endDate: Date) {
  excel {
    exportWarehouseXntDetails(startDate: $startDate, endDate: $endDate)
  }
}
    `;

/**
 * __useExportWarehouseXntDetailsQuery__
 *
 * To run a query within a React component, call `useExportWarehouseXntDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportWarehouseXntDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportWarehouseXntDetailsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportWarehouseXntDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ExportWarehouseXntDetailsQuery, ExportWarehouseXntDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportWarehouseXntDetailsQuery, ExportWarehouseXntDetailsQueryVariables>(ExportWarehouseXntDetailsDocument, options);
      }
export function useExportWarehouseXntDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportWarehouseXntDetailsQuery, ExportWarehouseXntDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportWarehouseXntDetailsQuery, ExportWarehouseXntDetailsQueryVariables>(ExportWarehouseXntDetailsDocument, options);
        }
export type ExportWarehouseXntDetailsQueryHookResult = ReturnType<typeof useExportWarehouseXntDetailsQuery>;
export type ExportWarehouseXntDetailsLazyQueryHookResult = ReturnType<typeof useExportWarehouseXntDetailsLazyQuery>;
export type ExportWarehouseXntDetailsQueryResult = Apollo.QueryResult<ExportWarehouseXntDetailsQuery, ExportWarehouseXntDetailsQueryVariables>;
export const ExportWarehouseXntDetailForDateSalesDocument = gql`
    query ExportWarehouseXntDetailForDateSales($startDate: Date, $endDate: Date) {
  excel {
    exportWarehouseXntDetailForDateSales(startDate: $startDate, endDate: $endDate)
  }
}
    `;

/**
 * __useExportWarehouseXntDetailForDateSalesQuery__
 *
 * To run a query within a React component, call `useExportWarehouseXntDetailForDateSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportWarehouseXntDetailForDateSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportWarehouseXntDetailForDateSalesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportWarehouseXntDetailForDateSalesQuery(baseOptions?: Apollo.QueryHookOptions<ExportWarehouseXntDetailForDateSalesQuery, ExportWarehouseXntDetailForDateSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportWarehouseXntDetailForDateSalesQuery, ExportWarehouseXntDetailForDateSalesQueryVariables>(ExportWarehouseXntDetailForDateSalesDocument, options);
      }
export function useExportWarehouseXntDetailForDateSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportWarehouseXntDetailForDateSalesQuery, ExportWarehouseXntDetailForDateSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportWarehouseXntDetailForDateSalesQuery, ExportWarehouseXntDetailForDateSalesQueryVariables>(ExportWarehouseXntDetailForDateSalesDocument, options);
        }
export type ExportWarehouseXntDetailForDateSalesQueryHookResult = ReturnType<typeof useExportWarehouseXntDetailForDateSalesQuery>;
export type ExportWarehouseXntDetailForDateSalesLazyQueryHookResult = ReturnType<typeof useExportWarehouseXntDetailForDateSalesLazyQuery>;
export type ExportWarehouseXntDetailForDateSalesQueryResult = Apollo.QueryResult<ExportWarehouseXntDetailForDateSalesQuery, ExportWarehouseXntDetailForDateSalesQueryVariables>;
export const ExportReportWarehouseXntDocument = gql`
    query ExportReportWarehouseXnt($startDate: Date, $endDate: Date) {
  excel {
    exportReportWarehouseXnt(startDate: $startDate, endDate: $endDate)
  }
}
    `;

/**
 * __useExportReportWarehouseXntQuery__
 *
 * To run a query within a React component, call `useExportReportWarehouseXntQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportWarehouseXntQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportWarehouseXntQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportReportWarehouseXntQuery(baseOptions?: Apollo.QueryHookOptions<ExportReportWarehouseXntQuery, ExportReportWarehouseXntQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportReportWarehouseXntQuery, ExportReportWarehouseXntQueryVariables>(ExportReportWarehouseXntDocument, options);
      }
export function useExportReportWarehouseXntLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportReportWarehouseXntQuery, ExportReportWarehouseXntQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportReportWarehouseXntQuery, ExportReportWarehouseXntQueryVariables>(ExportReportWarehouseXntDocument, options);
        }
export type ExportReportWarehouseXntQueryHookResult = ReturnType<typeof useExportReportWarehouseXntQuery>;
export type ExportReportWarehouseXntLazyQueryHookResult = ReturnType<typeof useExportReportWarehouseXntLazyQuery>;
export type ExportReportWarehouseXntQueryResult = Apollo.QueryResult<ExportReportWarehouseXntQuery, ExportReportWarehouseXntQueryVariables>;
export const ExportSalesByCustomersDocument = gql`
    query ExportSalesByCustomers($customerId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  exc {
    exportSalesByCustomers(
      customerId: $customerId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportSalesByCustomersQuery__
 *
 * To run a query within a React component, call `useExportSalesByCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesByCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesByCustomersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportSalesByCustomersQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesByCustomersQuery, ExportSalesByCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesByCustomersQuery, ExportSalesByCustomersQueryVariables>(ExportSalesByCustomersDocument, options);
      }
export function useExportSalesByCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesByCustomersQuery, ExportSalesByCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesByCustomersQuery, ExportSalesByCustomersQueryVariables>(ExportSalesByCustomersDocument, options);
        }
export type ExportSalesByCustomersQueryHookResult = ReturnType<typeof useExportSalesByCustomersQuery>;
export type ExportSalesByCustomersLazyQueryHookResult = ReturnType<typeof useExportSalesByCustomersLazyQuery>;
export type ExportSalesByCustomersQueryResult = Apollo.QueryResult<ExportSalesByCustomersQuery, ExportSalesByCustomersQueryVariables>;
export const ExportSalesByProductsDocument = gql`
    query ExportSalesByProducts($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  excel {
    salesByProducts(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportSalesByProductsQuery__
 *
 * To run a query within a React component, call `useExportSalesByProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesByProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesByProductsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportSalesByProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesByProductsQuery, ExportSalesByProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesByProductsQuery, ExportSalesByProductsQueryVariables>(ExportSalesByProductsDocument, options);
      }
export function useExportSalesByProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesByProductsQuery, ExportSalesByProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesByProductsQuery, ExportSalesByProductsQueryVariables>(ExportSalesByProductsDocument, options);
        }
export type ExportSalesByProductsQueryHookResult = ReturnType<typeof useExportSalesByProductsQuery>;
export type ExportSalesByProductsLazyQueryHookResult = ReturnType<typeof useExportSalesByProductsLazyQuery>;
export type ExportSalesByProductsQueryResult = Apollo.QueryResult<ExportSalesByProductsQuery, ExportSalesByProductsQueryVariables>;
export const ExportSalesBySalesForceDocument = gql`
    query ExportSalesBySalesForce($filter: String, $startDate: Date, $endDate: Date, $productId: Int) {
  exc {
    exportSalesBySalesForce(
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    )
  }
}
    `;

/**
 * __useExportSalesBySalesForceQuery__
 *
 * To run a query within a React component, call `useExportSalesBySalesForceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesBySalesForceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesBySalesForceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useExportSalesBySalesForceQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesBySalesForceQuery, ExportSalesBySalesForceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesBySalesForceQuery, ExportSalesBySalesForceQueryVariables>(ExportSalesBySalesForceDocument, options);
      }
export function useExportSalesBySalesForceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesBySalesForceQuery, ExportSalesBySalesForceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesBySalesForceQuery, ExportSalesBySalesForceQueryVariables>(ExportSalesBySalesForceDocument, options);
        }
export type ExportSalesBySalesForceQueryHookResult = ReturnType<typeof useExportSalesBySalesForceQuery>;
export type ExportSalesBySalesForceLazyQueryHookResult = ReturnType<typeof useExportSalesBySalesForceLazyQuery>;
export type ExportSalesBySalesForceQueryResult = Apollo.QueryResult<ExportSalesBySalesForceQuery, ExportSalesBySalesForceQueryVariables>;
export const ExportSalesOrdersDocument = gql`
    query ExportSalesOrders($startDate: Date, $endDate: Date, $status: OrderStatus, $orderType: OrderType, $sellType: SellType, $distributorCd: String, $customerCd: String, $salesmanCd: String, $salesOrderCd: String) {
  excel {
    exportSalesOrders(
      startDate: $startDate
      endDate: $endDate
      status: $status
      orderType: $orderType
      sellType: $sellType
      distributorCd: $distributorCd
      customerCd: $customerCd
      salesmanCd: $salesmanCd
      salesOrderCd: $salesOrderCd
    )
  }
}
    `;

/**
 * __useExportSalesOrdersQuery__
 *
 * To run a query within a React component, call `useExportSalesOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesOrdersQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      orderType: // value for 'orderType'
 *      sellType: // value for 'sellType'
 *      distributorCd: // value for 'distributorCd'
 *      customerCd: // value for 'customerCd'
 *      salesmanCd: // value for 'salesmanCd'
 *      salesOrderCd: // value for 'salesOrderCd'
 *   },
 * });
 */
export function useExportSalesOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesOrdersQuery, ExportSalesOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesOrdersQuery, ExportSalesOrdersQueryVariables>(ExportSalesOrdersDocument, options);
      }
export function useExportSalesOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesOrdersQuery, ExportSalesOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesOrdersQuery, ExportSalesOrdersQueryVariables>(ExportSalesOrdersDocument, options);
        }
export type ExportSalesOrdersQueryHookResult = ReturnType<typeof useExportSalesOrdersQuery>;
export type ExportSalesOrdersLazyQueryHookResult = ReturnType<typeof useExportSalesOrdersLazyQuery>;
export type ExportSalesOrdersQueryResult = Apollo.QueryResult<ExportSalesOrdersQuery, ExportSalesOrdersQueryVariables>;
export const ExportSalesOrderReturnsDocument = gql`
    query ExportSalesOrderReturns($startDate: Date, $endDate: Date, $status: OrderStatus, $distributorCd: String, $salesOrderReturnCd: String, $customerCd: String, $creatorCd: String, $productCd: String, $reasonId: Int) {
  excel {
    exportSalesOrderReturns(
      status: $status
      startDate: $startDate
      endDate: $endDate
      distributorCd: $distributorCd
      salesOrderReturnCd: $salesOrderReturnCd
      customerCd: $customerCd
      creatorCd: $creatorCd
      productCd: $productCd
      reasonId: $reasonId
    )
  }
}
    `;

/**
 * __useExportSalesOrderReturnsQuery__
 *
 * To run a query within a React component, call `useExportSalesOrderReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesOrderReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesOrderReturnsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      distributorCd: // value for 'distributorCd'
 *      salesOrderReturnCd: // value for 'salesOrderReturnCd'
 *      customerCd: // value for 'customerCd'
 *      creatorCd: // value for 'creatorCd'
 *      productCd: // value for 'productCd'
 *      reasonId: // value for 'reasonId'
 *   },
 * });
 */
export function useExportSalesOrderReturnsQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesOrderReturnsQuery, ExportSalesOrderReturnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesOrderReturnsQuery, ExportSalesOrderReturnsQueryVariables>(ExportSalesOrderReturnsDocument, options);
      }
export function useExportSalesOrderReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesOrderReturnsQuery, ExportSalesOrderReturnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesOrderReturnsQuery, ExportSalesOrderReturnsQueryVariables>(ExportSalesOrderReturnsDocument, options);
        }
export type ExportSalesOrderReturnsQueryHookResult = ReturnType<typeof useExportSalesOrderReturnsQuery>;
export type ExportSalesOrderReturnsLazyQueryHookResult = ReturnType<typeof useExportSalesOrderReturnsLazyQuery>;
export type ExportSalesOrderReturnsQueryResult = Apollo.QueryResult<ExportSalesOrderReturnsQuery, ExportSalesOrderReturnsQueryVariables>;
export const ExportSalesOrderDetailsDocument = gql`
    query ExportSalesOrderDetails($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int, $customerId: Int, $salesmanCd: String) {
  exc {
    exportSalesOrderDetails(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
      customerId: $customerId
      salesmanCd: $salesmanCd
    )
  }
}
    `;

/**
 * __useExportSalesOrderDetailsQuery__
 *
 * To run a query within a React component, call `useExportSalesOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesOrderDetailsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *      customerId: // value for 'customerId'
 *      salesmanCd: // value for 'salesmanCd'
 *   },
 * });
 */
export function useExportSalesOrderDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesOrderDetailsQuery, ExportSalesOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesOrderDetailsQuery, ExportSalesOrderDetailsQueryVariables>(ExportSalesOrderDetailsDocument, options);
      }
export function useExportSalesOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesOrderDetailsQuery, ExportSalesOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesOrderDetailsQuery, ExportSalesOrderDetailsQueryVariables>(ExportSalesOrderDetailsDocument, options);
        }
export type ExportSalesOrderDetailsQueryHookResult = ReturnType<typeof useExportSalesOrderDetailsQuery>;
export type ExportSalesOrderDetailsLazyQueryHookResult = ReturnType<typeof useExportSalesOrderDetailsLazyQuery>;
export type ExportSalesOrderDetailsQueryResult = Apollo.QueryResult<ExportSalesOrderDetailsQuery, ExportSalesOrderDetailsQueryVariables>;
export const ExportSalesOrderDiscountLineDocument = gql`
    query ExportSalesOrderDiscountLine($discountId: Int, $distributorId: Int, $productId: Int, $startDate: Date, $endDate: Date, $customerCd: String) {
  excel {
    exportSalesOrderDiscountLine(
      discountId: $discountId
      distributorId: $distributorId
      productId: $productId
      startDate: $startDate
      endDate: $endDate
      customerCd: $customerCd
    )
  }
}
    `;

/**
 * __useExportSalesOrderDiscountLineQuery__
 *
 * To run a query within a React component, call `useExportSalesOrderDiscountLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesOrderDiscountLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesOrderDiscountLineQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *      productId: // value for 'productId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerCd: // value for 'customerCd'
 *   },
 * });
 */
export function useExportSalesOrderDiscountLineQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesOrderDiscountLineQuery, ExportSalesOrderDiscountLineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesOrderDiscountLineQuery, ExportSalesOrderDiscountLineQueryVariables>(ExportSalesOrderDiscountLineDocument, options);
      }
export function useExportSalesOrderDiscountLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesOrderDiscountLineQuery, ExportSalesOrderDiscountLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesOrderDiscountLineQuery, ExportSalesOrderDiscountLineQueryVariables>(ExportSalesOrderDiscountLineDocument, options);
        }
export type ExportSalesOrderDiscountLineQueryHookResult = ReturnType<typeof useExportSalesOrderDiscountLineQuery>;
export type ExportSalesOrderDiscountLineLazyQueryHookResult = ReturnType<typeof useExportSalesOrderDiscountLineLazyQuery>;
export type ExportSalesOrderDiscountLineQueryResult = Apollo.QueryResult<ExportSalesOrderDiscountLineQuery, ExportSalesOrderDiscountLineQueryVariables>;
export const ExportSalesPricesDocument = gql`
    query exportSalesPrices($productId: Int, $priceClassId: Int, $unitId: String) {
  excel {
    exportSalesPrices(
      productId: $productId
      priceClassId: $priceClassId
      unitId: $unitId
    )
  }
}
    `;

/**
 * __useExportSalesPricesQuery__
 *
 * To run a query within a React component, call `useExportSalesPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesPricesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      priceClassId: // value for 'priceClassId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useExportSalesPricesQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesPricesQuery, ExportSalesPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesPricesQuery, ExportSalesPricesQueryVariables>(ExportSalesPricesDocument, options);
      }
export function useExportSalesPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesPricesQuery, ExportSalesPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesPricesQuery, ExportSalesPricesQueryVariables>(ExportSalesPricesDocument, options);
        }
export type ExportSalesPricesQueryHookResult = ReturnType<typeof useExportSalesPricesQuery>;
export type ExportSalesPricesLazyQueryHookResult = ReturnType<typeof useExportSalesPricesLazyQuery>;
export type ExportSalesPricesQueryResult = Apollo.QueryResult<ExportSalesPricesQuery, ExportSalesPricesQueryVariables>;
export const ExportSalesRegionsDocument = gql`
    query exportSalesRegions {
  excel {
    exportSalesRegions
  }
}
    `;

/**
 * __useExportSalesRegionsQuery__
 *
 * To run a query within a React component, call `useExportSalesRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSalesRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSalesRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportSalesRegionsQuery(baseOptions?: Apollo.QueryHookOptions<ExportSalesRegionsQuery, ExportSalesRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSalesRegionsQuery, ExportSalesRegionsQueryVariables>(ExportSalesRegionsDocument, options);
      }
export function useExportSalesRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSalesRegionsQuery, ExportSalesRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSalesRegionsQuery, ExportSalesRegionsQueryVariables>(ExportSalesRegionsDocument, options);
        }
export type ExportSalesRegionsQueryHookResult = ReturnType<typeof useExportSalesRegionsQuery>;
export type ExportSalesRegionsLazyQueryHookResult = ReturnType<typeof useExportSalesRegionsLazyQuery>;
export type ExportSalesRegionsQueryResult = Apollo.QueryResult<ExportSalesRegionsQuery, ExportSalesRegionsQueryVariables>;
export const ExportShippersDocument = gql`
    query exportShippers($filter: String) {
  excel {
    exportShippers(filter: $filter)
  }
}
    `;

/**
 * __useExportShippersQuery__
 *
 * To run a query within a React component, call `useExportShippersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportShippersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportShippersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportShippersQuery(baseOptions?: Apollo.QueryHookOptions<ExportShippersQuery, ExportShippersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportShippersQuery, ExportShippersQueryVariables>(ExportShippersDocument, options);
      }
export function useExportShippersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportShippersQuery, ExportShippersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportShippersQuery, ExportShippersQueryVariables>(ExportShippersDocument, options);
        }
export type ExportShippersQueryHookResult = ReturnType<typeof useExportShippersQuery>;
export type ExportShippersLazyQueryHookResult = ReturnType<typeof useExportShippersLazyQuery>;
export type ExportShippersQueryResult = Apollo.QueryResult<ExportShippersQuery, ExportShippersQueryVariables>;
export const ExportSurveyListReportDocument = gql`
    query ExportSurveyListReport($startDate: Date, $endDate: Date, $filter: String, $surveyId: Int, $salesRegionId: Int, $status: ProgramStatus) {
  excel {
    exportSurveyListReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      surveyId: $surveyId
      salesRegionId: $salesRegionId
      status: $status
    )
  }
}
    `;

/**
 * __useExportSurveyListReportQuery__
 *
 * To run a query within a React component, call `useExportSurveyListReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSurveyListReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSurveyListReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *      surveyId: // value for 'surveyId'
 *      salesRegionId: // value for 'salesRegionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExportSurveyListReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportSurveyListReportQuery, ExportSurveyListReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSurveyListReportQuery, ExportSurveyListReportQueryVariables>(ExportSurveyListReportDocument, options);
      }
export function useExportSurveyListReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSurveyListReportQuery, ExportSurveyListReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSurveyListReportQuery, ExportSurveyListReportQueryVariables>(ExportSurveyListReportDocument, options);
        }
export type ExportSurveyListReportQueryHookResult = ReturnType<typeof useExportSurveyListReportQuery>;
export type ExportSurveyListReportLazyQueryHookResult = ReturnType<typeof useExportSurveyListReportLazyQuery>;
export type ExportSurveyListReportQueryResult = Apollo.QueryResult<ExportSurveyListReportQuery, ExportSurveyListReportQueryVariables>;
export const ExportTransactionsDocument = gql`
    query ExportTransactions($startDate: Date, $endDate: Date, $status: TransactionStatus, $source: TransactionSource, $distributorCd: String, $productCd: String, $creatorCd: String, $transactionCd: String) {
  excel {
    exportTransactions(
      startDate: $startDate
      endDate: $endDate
      status: $status
      source: $source
      distributorCd: $distributorCd
      productCd: $productCd
      creatorCd: $creatorCd
      transactionCd: $transactionCd
    )
  }
}
    `;

/**
 * __useExportTransactionsQuery__
 *
 * To run a query within a React component, call `useExportTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTransactionsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      source: // value for 'source'
 *      distributorCd: // value for 'distributorCd'
 *      productCd: // value for 'productCd'
 *      creatorCd: // value for 'creatorCd'
 *      transactionCd: // value for 'transactionCd'
 *   },
 * });
 */
export function useExportTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<ExportTransactionsQuery, ExportTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportTransactionsQuery, ExportTransactionsQueryVariables>(ExportTransactionsDocument, options);
      }
export function useExportTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportTransactionsQuery, ExportTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportTransactionsQuery, ExportTransactionsQueryVariables>(ExportTransactionsDocument, options);
        }
export type ExportTransactionsQueryHookResult = ReturnType<typeof useExportTransactionsQuery>;
export type ExportTransactionsLazyQueryHookResult = ReturnType<typeof useExportTransactionsLazyQuery>;
export type ExportTransactionsQueryResult = Apollo.QueryResult<ExportTransactionsQuery, ExportTransactionsQueryVariables>;
export const ExportVisitCustomerDocument = gql`
    query ExportVisitCustomer($startDate: Date, $endDate: Date) {
  excel {
    reportVisitCustomer(startDate: $startDate, endDate: $endDate)
  }
}
    `;

/**
 * __useExportVisitCustomerQuery__
 *
 * To run a query within a React component, call `useExportVisitCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVisitCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVisitCustomerQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportVisitCustomerQuery(baseOptions?: Apollo.QueryHookOptions<ExportVisitCustomerQuery, ExportVisitCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportVisitCustomerQuery, ExportVisitCustomerQueryVariables>(ExportVisitCustomerDocument, options);
      }
export function useExportVisitCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportVisitCustomerQuery, ExportVisitCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportVisitCustomerQuery, ExportVisitCustomerQueryVariables>(ExportVisitCustomerDocument, options);
        }
export type ExportVisitCustomerQueryHookResult = ReturnType<typeof useExportVisitCustomerQuery>;
export type ExportVisitCustomerLazyQueryHookResult = ReturnType<typeof useExportVisitCustomerLazyQuery>;
export type ExportVisitCustomerQueryResult = Apollo.QueryResult<ExportVisitCustomerQuery, ExportVisitCustomerQueryVariables>;
export const ExportVisitPlanDetailsReportDocument = gql`
    query ExportVisitPlanDetailsReport($date: Date!, $salesmanId: Int!) {
  excel {
    exportVisitPlanDetailsReport(date: $date, salesmanId: $salesmanId)
  }
}
    `;

/**
 * __useExportVisitPlanDetailsReportQuery__
 *
 * To run a query within a React component, call `useExportVisitPlanDetailsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVisitPlanDetailsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVisitPlanDetailsReportQuery({
 *   variables: {
 *      date: // value for 'date'
 *      salesmanId: // value for 'salesmanId'
 *   },
 * });
 */
export function useExportVisitPlanDetailsReportQuery(baseOptions: Apollo.QueryHookOptions<ExportVisitPlanDetailsReportQuery, ExportVisitPlanDetailsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportVisitPlanDetailsReportQuery, ExportVisitPlanDetailsReportQueryVariables>(ExportVisitPlanDetailsReportDocument, options);
      }
export function useExportVisitPlanDetailsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportVisitPlanDetailsReportQuery, ExportVisitPlanDetailsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportVisitPlanDetailsReportQuery, ExportVisitPlanDetailsReportQueryVariables>(ExportVisitPlanDetailsReportDocument, options);
        }
export type ExportVisitPlanDetailsReportQueryHookResult = ReturnType<typeof useExportVisitPlanDetailsReportQuery>;
export type ExportVisitPlanDetailsReportLazyQueryHookResult = ReturnType<typeof useExportVisitPlanDetailsReportLazyQuery>;
export type ExportVisitPlanDetailsReportQueryResult = Apollo.QueryResult<ExportVisitPlanDetailsReportQuery, ExportVisitPlanDetailsReportQueryVariables>;
export const ExportVisitPlanSummaryReportDocument = gql`
    query ExportVisitPlanSummaryReport($startDate: Date!, $endDate: Date!, $salesmanId: Int, $leaderId: Int) {
  excel {
    exportVisitPlanSummaryReport(
      startDate: $startDate
      endDate: $endDate
      salesmanId: $salesmanId
      leaderId: $leaderId
    )
  }
}
    `;

/**
 * __useExportVisitPlanSummaryReportQuery__
 *
 * To run a query within a React component, call `useExportVisitPlanSummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVisitPlanSummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVisitPlanSummaryReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesmanId: // value for 'salesmanId'
 *      leaderId: // value for 'leaderId'
 *   },
 * });
 */
export function useExportVisitPlanSummaryReportQuery(baseOptions: Apollo.QueryHookOptions<ExportVisitPlanSummaryReportQuery, ExportVisitPlanSummaryReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportVisitPlanSummaryReportQuery, ExportVisitPlanSummaryReportQueryVariables>(ExportVisitPlanSummaryReportDocument, options);
      }
export function useExportVisitPlanSummaryReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportVisitPlanSummaryReportQuery, ExportVisitPlanSummaryReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportVisitPlanSummaryReportQuery, ExportVisitPlanSummaryReportQueryVariables>(ExportVisitPlanSummaryReportDocument, options);
        }
export type ExportVisitPlanSummaryReportQueryHookResult = ReturnType<typeof useExportVisitPlanSummaryReportQuery>;
export type ExportVisitPlanSummaryReportLazyQueryHookResult = ReturnType<typeof useExportVisitPlanSummaryReportLazyQuery>;
export type ExportVisitPlanSummaryReportQueryResult = Apollo.QueryResult<ExportVisitPlanSummaryReportQuery, ExportVisitPlanSummaryReportQueryVariables>;
export const ExportVisualListReportDocument = gql`
    query ExportVisualListReport($visualId: Int, $filter: String, $salesRegionId: Int, $minPass: Int, $maxPass: Int) {
  excel {
    exportVisualListReport(
      visualId: $visualId
      filter: $filter
      salesRegionId: $salesRegionId
      minPass: $minPass
      maxPass: $maxPass
    )
  }
}
    `;

/**
 * __useExportVisualListReportQuery__
 *
 * To run a query within a React component, call `useExportVisualListReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportVisualListReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportVisualListReportQuery({
 *   variables: {
 *      visualId: // value for 'visualId'
 *      filter: // value for 'filter'
 *      salesRegionId: // value for 'salesRegionId'
 *      minPass: // value for 'minPass'
 *      maxPass: // value for 'maxPass'
 *   },
 * });
 */
export function useExportVisualListReportQuery(baseOptions?: Apollo.QueryHookOptions<ExportVisualListReportQuery, ExportVisualListReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportVisualListReportQuery, ExportVisualListReportQueryVariables>(ExportVisualListReportDocument, options);
      }
export function useExportVisualListReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportVisualListReportQuery, ExportVisualListReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportVisualListReportQuery, ExportVisualListReportQueryVariables>(ExportVisualListReportDocument, options);
        }
export type ExportVisualListReportQueryHookResult = ReturnType<typeof useExportVisualListReportQuery>;
export type ExportVisualListReportLazyQueryHookResult = ReturnType<typeof useExportVisualListReportLazyQuery>;
export type ExportVisualListReportQueryResult = Apollo.QueryResult<ExportVisualListReportQuery, ExportVisualListReportQueryVariables>;
export const ExportWorkGroupsDocument = gql`
    query exportWorkGroups {
  excel {
    exportWorkGroups
  }
}
    `;

/**
 * __useExportWorkGroupsQuery__
 *
 * To run a query within a React component, call `useExportWorkGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportWorkGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportWorkGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportWorkGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ExportWorkGroupsQuery, ExportWorkGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportWorkGroupsQuery, ExportWorkGroupsQueryVariables>(ExportWorkGroupsDocument, options);
      }
export function useExportWorkGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportWorkGroupsQuery, ExportWorkGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportWorkGroupsQuery, ExportWorkGroupsQueryVariables>(ExportWorkGroupsDocument, options);
        }
export type ExportWorkGroupsQueryHookResult = ReturnType<typeof useExportWorkGroupsQuery>;
export type ExportWorkGroupsLazyQueryHookResult = ReturnType<typeof useExportWorkGroupsLazyQuery>;
export type ExportWorkGroupsQueryResult = Apollo.QueryResult<ExportWorkGroupsQuery, ExportWorkGroupsQueryVariables>;
export const ImportCommandoLineDocument = gql`
    mutation ImportCommandoLine($data: [ImportCommandoLineInput]) {
  excel {
    importCommandoLine(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportCommandoLineMutationFn = Apollo.MutationFunction<ImportCommandoLineMutation, ImportCommandoLineMutationVariables>;

/**
 * __useImportCommandoLineMutation__
 *
 * To run a mutation, you first call `useImportCommandoLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCommandoLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCommandoLineMutation, { data, loading, error }] = useImportCommandoLineMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportCommandoLineMutation(baseOptions?: Apollo.MutationHookOptions<ImportCommandoLineMutation, ImportCommandoLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCommandoLineMutation, ImportCommandoLineMutationVariables>(ImportCommandoLineDocument, options);
      }
export type ImportCommandoLineMutationHookResult = ReturnType<typeof useImportCommandoLineMutation>;
export type ImportCommandoLineMutationResult = Apollo.MutationResult<ImportCommandoLineMutation>;
export type ImportCommandoLineMutationOptions = Apollo.BaseMutationOptions<ImportCommandoLineMutation, ImportCommandoLineMutationVariables>;
export const ImportCommandoDocument = gql`
    mutation ImportCommando($data: [ImportCommandoInput]) {
  excel {
    importCommando(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportCommandoMutationFn = Apollo.MutationFunction<ImportCommandoMutation, ImportCommandoMutationVariables>;

/**
 * __useImportCommandoMutation__
 *
 * To run a mutation, you first call `useImportCommandoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCommandoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCommandoMutation, { data, loading, error }] = useImportCommandoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportCommandoMutation(baseOptions?: Apollo.MutationHookOptions<ImportCommandoMutation, ImportCommandoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCommandoMutation, ImportCommandoMutationVariables>(ImportCommandoDocument, options);
      }
export type ImportCommandoMutationHookResult = ReturnType<typeof useImportCommandoMutation>;
export type ImportCommandoMutationResult = Apollo.MutationResult<ImportCommandoMutation>;
export type ImportCommandoMutationOptions = Apollo.BaseMutationOptions<ImportCommandoMutation, ImportCommandoMutationVariables>;
export const ImportCustomerAttributesDocument = gql`
    mutation ImportCustomerAttributes($data: [AttrInput]) {
  excel {
    importCustomerAttributes(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportCustomerAttributesMutationFn = Apollo.MutationFunction<ImportCustomerAttributesMutation, ImportCustomerAttributesMutationVariables>;

/**
 * __useImportCustomerAttributesMutation__
 *
 * To run a mutation, you first call `useImportCustomerAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomerAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomerAttributesMutation, { data, loading, error }] = useImportCustomerAttributesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportCustomerAttributesMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomerAttributesMutation, ImportCustomerAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomerAttributesMutation, ImportCustomerAttributesMutationVariables>(ImportCustomerAttributesDocument, options);
      }
export type ImportCustomerAttributesMutationHookResult = ReturnType<typeof useImportCustomerAttributesMutation>;
export type ImportCustomerAttributesMutationResult = Apollo.MutationResult<ImportCustomerAttributesMutation>;
export type ImportCustomerAttributesMutationOptions = Apollo.BaseMutationOptions<ImportCustomerAttributesMutation, ImportCustomerAttributesMutationVariables>;
export const ImportCustomerClassesDocument = gql`
    mutation ImportCustomerClasses($data: [CustomerClassInput]) {
  excel {
    importCustomerClasses(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportCustomerClassesMutationFn = Apollo.MutationFunction<ImportCustomerClassesMutation, ImportCustomerClassesMutationVariables>;

/**
 * __useImportCustomerClassesMutation__
 *
 * To run a mutation, you first call `useImportCustomerClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomerClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomerClassesMutation, { data, loading, error }] = useImportCustomerClassesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportCustomerClassesMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomerClassesMutation, ImportCustomerClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomerClassesMutation, ImportCustomerClassesMutationVariables>(ImportCustomerClassesDocument, options);
      }
export type ImportCustomerClassesMutationHookResult = ReturnType<typeof useImportCustomerClassesMutation>;
export type ImportCustomerClassesMutationResult = Apollo.MutationResult<ImportCustomerClassesMutation>;
export type ImportCustomerClassesMutationOptions = Apollo.BaseMutationOptions<ImportCustomerClassesMutation, ImportCustomerClassesMutationVariables>;
export const ImportCustomersDocument = gql`
    mutation ImportCustomers($data: [CustomerInput]) {
  excel {
    importCustomers(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportCustomersMutationFn = Apollo.MutationFunction<ImportCustomersMutation, ImportCustomersMutationVariables>;

/**
 * __useImportCustomersMutation__
 *
 * To run a mutation, you first call `useImportCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomersMutation, { data, loading, error }] = useImportCustomersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportCustomersMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomersMutation, ImportCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomersMutation, ImportCustomersMutationVariables>(ImportCustomersDocument, options);
      }
export type ImportCustomersMutationHookResult = ReturnType<typeof useImportCustomersMutation>;
export type ImportCustomersMutationResult = Apollo.MutationResult<ImportCustomersMutation>;
export type ImportCustomersMutationOptions = Apollo.BaseMutationOptions<ImportCustomersMutation, ImportCustomersMutationVariables>;
export const ImportDistributorDocument = gql`
    mutation ImportDistributor($data: [DistributorInput]) {
  excel {
    importDistributor(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportDistributorMutationFn = Apollo.MutationFunction<ImportDistributorMutation, ImportDistributorMutationVariables>;

/**
 * __useImportDistributorMutation__
 *
 * To run a mutation, you first call `useImportDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDistributorMutation, { data, loading, error }] = useImportDistributorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportDistributorMutation(baseOptions?: Apollo.MutationHookOptions<ImportDistributorMutation, ImportDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportDistributorMutation, ImportDistributorMutationVariables>(ImportDistributorDocument, options);
      }
export type ImportDistributorMutationHookResult = ReturnType<typeof useImportDistributorMutation>;
export type ImportDistributorMutationResult = Apollo.MutationResult<ImportDistributorMutation>;
export type ImportDistributorMutationOptions = Apollo.BaseMutationOptions<ImportDistributorMutation, ImportDistributorMutationVariables>;
export const ImportEmployeesDocument = gql`
    mutation ImportEmployees($data: [EmployeeInput]) {
  excel {
    importEmployees(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportEmployeesMutationFn = Apollo.MutationFunction<ImportEmployeesMutation, ImportEmployeesMutationVariables>;

/**
 * __useImportEmployeesMutation__
 *
 * To run a mutation, you first call `useImportEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEmployeesMutation, { data, loading, error }] = useImportEmployeesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportEmployeesMutation, ImportEmployeesMutationVariables>(ImportEmployeesDocument, options);
      }
export type ImportEmployeesMutationHookResult = ReturnType<typeof useImportEmployeesMutation>;
export type ImportEmployeesMutationResult = Apollo.MutationResult<ImportEmployeesMutation>;
export type ImportEmployeesMutationOptions = Apollo.BaseMutationOptions<ImportEmployeesMutation, ImportEmployeesMutationVariables>;
export const ImportInventoryDocument = gql`
    mutation ImportInventory($data: [ImportInventoryInput]) {
  excel {
    importInventory(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportInventoryMutationFn = Apollo.MutationFunction<ImportInventoryMutation, ImportInventoryMutationVariables>;

/**
 * __useImportInventoryMutation__
 *
 * To run a mutation, you first call `useImportInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importInventoryMutation, { data, loading, error }] = useImportInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportInventoryMutation(baseOptions?: Apollo.MutationHookOptions<ImportInventoryMutation, ImportInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportInventoryMutation, ImportInventoryMutationVariables>(ImportInventoryDocument, options);
      }
export type ImportInventoryMutationHookResult = ReturnType<typeof useImportInventoryMutation>;
export type ImportInventoryMutationResult = Apollo.MutationResult<ImportInventoryMutation>;
export type ImportInventoryMutationOptions = Apollo.BaseMutationOptions<ImportInventoryMutation, ImportInventoryMutationVariables>;
export const ImportKpiConfigDocument = gql`
    mutation ImportKpiConfig($data: [KpiConfigInput]) {
  excel {
    importKpiConfig(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportKpiConfigMutationFn = Apollo.MutationFunction<ImportKpiConfigMutation, ImportKpiConfigMutationVariables>;

/**
 * __useImportKpiConfigMutation__
 *
 * To run a mutation, you first call `useImportKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importKpiConfigMutation, { data, loading, error }] = useImportKpiConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<ImportKpiConfigMutation, ImportKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportKpiConfigMutation, ImportKpiConfigMutationVariables>(ImportKpiConfigDocument, options);
      }
export type ImportKpiConfigMutationHookResult = ReturnType<typeof useImportKpiConfigMutation>;
export type ImportKpiConfigMutationResult = Apollo.MutationResult<ImportKpiConfigMutation>;
export type ImportKpiConfigMutationOptions = Apollo.BaseMutationOptions<ImportKpiConfigMutation, ImportKpiConfigMutationVariables>;
export const ImportKpiFormulaDocument = gql`
    mutation ImportKpiFormula($data: [KpiFormulaInput]) {
  excel {
    importKpiFormula(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportKpiFormulaMutationFn = Apollo.MutationFunction<ImportKpiFormulaMutation, ImportKpiFormulaMutationVariables>;

/**
 * __useImportKpiFormulaMutation__
 *
 * To run a mutation, you first call `useImportKpiFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportKpiFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importKpiFormulaMutation, { data, loading, error }] = useImportKpiFormulaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportKpiFormulaMutation(baseOptions?: Apollo.MutationHookOptions<ImportKpiFormulaMutation, ImportKpiFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportKpiFormulaMutation, ImportKpiFormulaMutationVariables>(ImportKpiFormulaDocument, options);
      }
export type ImportKpiFormulaMutationHookResult = ReturnType<typeof useImportKpiFormulaMutation>;
export type ImportKpiFormulaMutationResult = Apollo.MutationResult<ImportKpiFormulaMutation>;
export type ImportKpiFormulaMutationOptions = Apollo.BaseMutationOptions<ImportKpiFormulaMutation, ImportKpiFormulaMutationVariables>;
export const ImportMcpDocument = gql`
    mutation ImportMcp($data: [ImportMcpInput]) {
  excel {
    importMcp(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportMcpMutationFn = Apollo.MutationFunction<ImportMcpMutation, ImportMcpMutationVariables>;

/**
 * __useImportMcpMutation__
 *
 * To run a mutation, you first call `useImportMcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMcpMutation, { data, loading, error }] = useImportMcpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportMcpMutation(baseOptions?: Apollo.MutationHookOptions<ImportMcpMutation, ImportMcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportMcpMutation, ImportMcpMutationVariables>(ImportMcpDocument, options);
      }
export type ImportMcpMutationHookResult = ReturnType<typeof useImportMcpMutation>;
export type ImportMcpMutationResult = Apollo.MutationResult<ImportMcpMutation>;
export type ImportMcpMutationOptions = Apollo.BaseMutationOptions<ImportMcpMutation, ImportMcpMutationVariables>;
export const ImportMeasureUnitDocument = gql`
    mutation ImportMeasureUnit($data: [MeasureUnitInput]) {
  excel {
    importMeasureUnit(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportMeasureUnitMutationFn = Apollo.MutationFunction<ImportMeasureUnitMutation, ImportMeasureUnitMutationVariables>;

/**
 * __useImportMeasureUnitMutation__
 *
 * To run a mutation, you first call `useImportMeasureUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMeasureUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMeasureUnitMutation, { data, loading, error }] = useImportMeasureUnitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportMeasureUnitMutation(baseOptions?: Apollo.MutationHookOptions<ImportMeasureUnitMutation, ImportMeasureUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportMeasureUnitMutation, ImportMeasureUnitMutationVariables>(ImportMeasureUnitDocument, options);
      }
export type ImportMeasureUnitMutationHookResult = ReturnType<typeof useImportMeasureUnitMutation>;
export type ImportMeasureUnitMutationResult = Apollo.MutationResult<ImportMeasureUnitMutation>;
export type ImportMeasureUnitMutationOptions = Apollo.BaseMutationOptions<ImportMeasureUnitMutation, ImportMeasureUnitMutationVariables>;
export const ImportOtherProductDocument = gql`
    mutation ImportOtherProduct($data: [OtherProductInput]) {
  excel {
    importOtherProduct(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportOtherProductMutationFn = Apollo.MutationFunction<ImportOtherProductMutation, ImportOtherProductMutationVariables>;

/**
 * __useImportOtherProductMutation__
 *
 * To run a mutation, you first call `useImportOtherProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportOtherProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importOtherProductMutation, { data, loading, error }] = useImportOtherProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportOtherProductMutation(baseOptions?: Apollo.MutationHookOptions<ImportOtherProductMutation, ImportOtherProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportOtherProductMutation, ImportOtherProductMutationVariables>(ImportOtherProductDocument, options);
      }
export type ImportOtherProductMutationHookResult = ReturnType<typeof useImportOtherProductMutation>;
export type ImportOtherProductMutationResult = Apollo.MutationResult<ImportOtherProductMutation>;
export type ImportOtherProductMutationOptions = Apollo.BaseMutationOptions<ImportOtherProductMutation, ImportOtherProductMutationVariables>;
export const ImportProductAttributesDocument = gql`
    mutation ImportProductAttributes($data: [AttrInput]) {
  excel {
    importProductAttributes(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportProductAttributesMutationFn = Apollo.MutationFunction<ImportProductAttributesMutation, ImportProductAttributesMutationVariables>;

/**
 * __useImportProductAttributesMutation__
 *
 * To run a mutation, you first call `useImportProductAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductAttributesMutation, { data, loading, error }] = useImportProductAttributesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportProductAttributesMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductAttributesMutation, ImportProductAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportProductAttributesMutation, ImportProductAttributesMutationVariables>(ImportProductAttributesDocument, options);
      }
export type ImportProductAttributesMutationHookResult = ReturnType<typeof useImportProductAttributesMutation>;
export type ImportProductAttributesMutationResult = Apollo.MutationResult<ImportProductAttributesMutation>;
export type ImportProductAttributesMutationOptions = Apollo.BaseMutationOptions<ImportProductAttributesMutation, ImportProductAttributesMutationVariables>;
export const ImportProductsDocument = gql`
    mutation ImportProducts($data: [ProductInput]) {
  excel {
    importProducts(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportProductsMutationFn = Apollo.MutationFunction<ImportProductsMutation, ImportProductsMutationVariables>;

/**
 * __useImportProductsMutation__
 *
 * To run a mutation, you first call `useImportProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductsMutation, { data, loading, error }] = useImportProductsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportProductsMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductsMutation, ImportProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportProductsMutation, ImportProductsMutationVariables>(ImportProductsDocument, options);
      }
export type ImportProductsMutationHookResult = ReturnType<typeof useImportProductsMutation>;
export type ImportProductsMutationResult = Apollo.MutationResult<ImportProductsMutation>;
export type ImportProductsMutationOptions = Apollo.BaseMutationOptions<ImportProductsMutation, ImportProductsMutationVariables>;
export const ImportPurchaseReceiptDocument = gql`
    mutation ImportPurchaseReceipt($data: [ImportPurchaseReceiptInput]!, $isUpdate: Boolean) {
  excel {
    importPurchaseReceipt(data: $data, isUpdate: $isUpdate) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportPurchaseReceiptMutationFn = Apollo.MutationFunction<ImportPurchaseReceiptMutation, ImportPurchaseReceiptMutationVariables>;

/**
 * __useImportPurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useImportPurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPurchaseReceiptMutation, { data, loading, error }] = useImportPurchaseReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *      isUpdate: // value for 'isUpdate'
 *   },
 * });
 */
export function useImportPurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ImportPurchaseReceiptMutation, ImportPurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPurchaseReceiptMutation, ImportPurchaseReceiptMutationVariables>(ImportPurchaseReceiptDocument, options);
      }
export type ImportPurchaseReceiptMutationHookResult = ReturnType<typeof useImportPurchaseReceiptMutation>;
export type ImportPurchaseReceiptMutationResult = Apollo.MutationResult<ImportPurchaseReceiptMutation>;
export type ImportPurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<ImportPurchaseReceiptMutation, ImportPurchaseReceiptMutationVariables>;
export const ImportSalesPricesDocument = gql`
    mutation ImportSalesPrices($data: [SalesPriceInput]) {
  excel {
    importSalesPrices(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportSalesPricesMutationFn = Apollo.MutationFunction<ImportSalesPricesMutation, ImportSalesPricesMutationVariables>;

/**
 * __useImportSalesPricesMutation__
 *
 * To run a mutation, you first call `useImportSalesPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSalesPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSalesPricesMutation, { data, loading, error }] = useImportSalesPricesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportSalesPricesMutation(baseOptions?: Apollo.MutationHookOptions<ImportSalesPricesMutation, ImportSalesPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSalesPricesMutation, ImportSalesPricesMutationVariables>(ImportSalesPricesDocument, options);
      }
export type ImportSalesPricesMutationHookResult = ReturnType<typeof useImportSalesPricesMutation>;
export type ImportSalesPricesMutationResult = Apollo.MutationResult<ImportSalesPricesMutation>;
export type ImportSalesPricesMutationOptions = Apollo.BaseMutationOptions<ImportSalesPricesMutation, ImportSalesPricesMutationVariables>;
export const ImportTradeParticipationDocument = gql`
    mutation ImportTradeParticipation($data: [ImportTradeParticipationInput]) {
  excel {
    importTradeParticipation(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ImportTradeParticipationMutationFn = Apollo.MutationFunction<ImportTradeParticipationMutation, ImportTradeParticipationMutationVariables>;

/**
 * __useImportTradeParticipationMutation__
 *
 * To run a mutation, you first call `useImportTradeParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTradeParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTradeParticipationMutation, { data, loading, error }] = useImportTradeParticipationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportTradeParticipationMutation(baseOptions?: Apollo.MutationHookOptions<ImportTradeParticipationMutation, ImportTradeParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportTradeParticipationMutation, ImportTradeParticipationMutationVariables>(ImportTradeParticipationDocument, options);
      }
export type ImportTradeParticipationMutationHookResult = ReturnType<typeof useImportTradeParticipationMutation>;
export type ImportTradeParticipationMutationResult = Apollo.MutationResult<ImportTradeParticipationMutation>;
export type ImportTradeParticipationMutationOptions = Apollo.BaseMutationOptions<ImportTradeParticipationMutation, ImportTradeParticipationMutationVariables>;
export const CompleteExchangeDocument = gql`
    mutation CompleteExchange($id: Int!) {
  o {
    completeExchange(id: $id) {
      success
      message {
        message
      }
      content {
        id
        cd
        exchangeStatus
        exchangeSource
        exchangeDate
        exchangeAt
        description
        note
        customerId
        distributorId
        reasonEditOrderId
        reasonEditOrder {
          id
          name
          reasonType
          description
        }
        customer {
          id
          cd
          name
        }
        distributor {
          id
          cd
          name
        }
        salesman {
          id
          cd
          name
        }
        exchangeItems {
          id
          product {
            cd
            id
            name
          }
          warehouse {
            id
            name
          }
          warehouseId
          productId
          qty
          unitId
          returnLotNbr
          returnStartDate
          returnEndDate
          exchangeLotNbr
          exchangeStartDate
          exchangeEndDate
        }
      }
    }
  }
}
    `;
export type CompleteExchangeMutationFn = Apollo.MutationFunction<CompleteExchangeMutation, CompleteExchangeMutationVariables>;

/**
 * __useCompleteExchangeMutation__
 *
 * To run a mutation, you first call `useCompleteExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeExchangeMutation, { data, loading, error }] = useCompleteExchangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteExchangeMutation(baseOptions?: Apollo.MutationHookOptions<CompleteExchangeMutation, CompleteExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteExchangeMutation, CompleteExchangeMutationVariables>(CompleteExchangeDocument, options);
      }
export type CompleteExchangeMutationHookResult = ReturnType<typeof useCompleteExchangeMutation>;
export type CompleteExchangeMutationResult = Apollo.MutationResult<CompleteExchangeMutation>;
export type CompleteExchangeMutationOptions = Apollo.BaseMutationOptions<CompleteExchangeMutation, CompleteExchangeMutationVariables>;
export const CreateOrUpdateExchangeDocument = gql`
    mutation CreateOrUpdateExchange($data: ExchangeInput!) {
  o {
    createOrUpdateExchange(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        cd
        exchangeStatus
        exchangeSource
        exchangeDate
        exchangeAt
        description
        note
        customerId
        distributorId
        reasonEditOrderId
        customer {
          cd
          name
        }
        distributor {
          cd
          name
        }
        salesman {
          cd
          name
        }
        exchangeItems {
          warehouseId
          productId
          exchangeId
          qty
          unitId
          returnLotNbr
          returnStartDate
          returnEndDate
          exchangeLotNbr
          exchangeStartDate
          exchangeEndDate
        }
      }
    }
  }
}
    `;
export type CreateOrUpdateExchangeMutationFn = Apollo.MutationFunction<CreateOrUpdateExchangeMutation, CreateOrUpdateExchangeMutationVariables>;

/**
 * __useCreateOrUpdateExchangeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateExchangeMutation, { data, loading, error }] = useCreateOrUpdateExchangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateExchangeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateExchangeMutation, CreateOrUpdateExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateExchangeMutation, CreateOrUpdateExchangeMutationVariables>(CreateOrUpdateExchangeDocument, options);
      }
export type CreateOrUpdateExchangeMutationHookResult = ReturnType<typeof useCreateOrUpdateExchangeMutation>;
export type CreateOrUpdateExchangeMutationResult = Apollo.MutationResult<CreateOrUpdateExchangeMutation>;
export type CreateOrUpdateExchangeMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateExchangeMutation, CreateOrUpdateExchangeMutationVariables>;
export const ExchangeListDocument = gql`
    query ExchangeList($page: Int!, $take: Int!, $filter: String, $date: Date, $status: OrderStatus, $distributorCd: String, $customerCd: String, $employeeCd: String, $reasonExchangeId: Int) {
  o {
    exchangeList(
      page: $page
      take: $take
      filter: $filter
      date: $date
      status: $status
      distributorCd: $distributorCd
      customerCd: $customerCd
      employeeCd: $employeeCd
      reasonExchangeId: $reasonExchangeId
    ) {
      total
      data {
        id
        cd
        exchangeStatus
        exchangeSource
        exchangeDate
        exchangeAt
        description
        note
        customerId
        distributorId
        reasonEditOrderId
        reasonEditOrder {
          id
          name
          reasonType
          description
        }
        customer {
          id
          cd
          name
        }
        distributor {
          id
          cd
          name
        }
        salesman {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useExchangeListQuery__
 *
 * To run a query within a React component, call `useExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      date: // value for 'date'
 *      status: // value for 'status'
 *      distributorCd: // value for 'distributorCd'
 *      customerCd: // value for 'customerCd'
 *      employeeCd: // value for 'employeeCd'
 *      reasonExchangeId: // value for 'reasonExchangeId'
 *   },
 * });
 */
export function useExchangeListQuery(baseOptions: Apollo.QueryHookOptions<ExchangeListQuery, ExchangeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangeListQuery, ExchangeListQueryVariables>(ExchangeListDocument, options);
      }
export function useExchangeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeListQuery, ExchangeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangeListQuery, ExchangeListQueryVariables>(ExchangeListDocument, options);
        }
export type ExchangeListQueryHookResult = ReturnType<typeof useExchangeListQuery>;
export type ExchangeListLazyQueryHookResult = ReturnType<typeof useExchangeListLazyQuery>;
export type ExchangeListQueryResult = Apollo.QueryResult<ExchangeListQuery, ExchangeListQueryVariables>;
export const ExchangeDocument = gql`
    query Exchange($id: Int!) {
  o {
    exchange(id: $id) {
      id
      cd
      exchangeStatus
      exchangeSource
      exchangeDate
      exchangeAt
      description
      note
      customerId
      distributorId
      reasonEditOrderId
      reasonEditOrder {
        id
        name
        reasonType
        description
      }
      customer {
        id
        cd
        name
      }
      distributor {
        id
        cd
        name
      }
      salesman {
        id
        cd
        name
      }
      exchangeItems {
        id
        product {
          cd
          id
          name
        }
        warehouse {
          id
          name
        }
        warehouseId
        productId
        qty
        unitId
        returnLotNbr
        returnStartDate
        returnEndDate
        exchangeLotNbr
        exchangeStartDate
        exchangeEndDate
      }
    }
  }
}
    `;

/**
 * __useExchangeQuery__
 *
 * To run a query within a React component, call `useExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeQuery(baseOptions: Apollo.QueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
      }
export function useExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
        }
export type ExchangeQueryHookResult = ReturnType<typeof useExchangeQuery>;
export type ExchangeLazyQueryHookResult = ReturnType<typeof useExchangeLazyQuery>;
export type ExchangeQueryResult = Apollo.QueryResult<ExchangeQuery, ExchangeQueryVariables>;
export const CloseKpiFormulaDocument = gql`
    mutation CloseKpiFormula($id: Int!) {
  p {
    closeKpiFormula(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseKpiFormulaMutationFn = Apollo.MutationFunction<CloseKpiFormulaMutation, CloseKpiFormulaMutationVariables>;

/**
 * __useCloseKpiFormulaMutation__
 *
 * To run a mutation, you first call `useCloseKpiFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseKpiFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeKpiFormulaMutation, { data, loading, error }] = useCloseKpiFormulaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseKpiFormulaMutation(baseOptions?: Apollo.MutationHookOptions<CloseKpiFormulaMutation, CloseKpiFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseKpiFormulaMutation, CloseKpiFormulaMutationVariables>(CloseKpiFormulaDocument, options);
      }
export type CloseKpiFormulaMutationHookResult = ReturnType<typeof useCloseKpiFormulaMutation>;
export type CloseKpiFormulaMutationResult = Apollo.MutationResult<CloseKpiFormulaMutation>;
export type CloseKpiFormulaMutationOptions = Apollo.BaseMutationOptions<CloseKpiFormulaMutation, CloseKpiFormulaMutationVariables>;
export const CreateOrUpdateKpiFormulaDocument = gql`
    mutation CreateOrUpdateKpiFormula($data: KpiFormulaInput!) {
  p {
    createOrUpdateKpiFormula(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...KpiFormulaFragment
      }
    }
  }
}
    ${KpiFormulaFragmentDoc}`;
export type CreateOrUpdateKpiFormulaMutationFn = Apollo.MutationFunction<CreateOrUpdateKpiFormulaMutation, CreateOrUpdateKpiFormulaMutationVariables>;

/**
 * __useCreateOrUpdateKpiFormulaMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateKpiFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateKpiFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateKpiFormulaMutation, { data, loading, error }] = useCreateOrUpdateKpiFormulaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateKpiFormulaMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateKpiFormulaMutation, CreateOrUpdateKpiFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateKpiFormulaMutation, CreateOrUpdateKpiFormulaMutationVariables>(CreateOrUpdateKpiFormulaDocument, options);
      }
export type CreateOrUpdateKpiFormulaMutationHookResult = ReturnType<typeof useCreateOrUpdateKpiFormulaMutation>;
export type CreateOrUpdateKpiFormulaMutationResult = Apollo.MutationResult<CreateOrUpdateKpiFormulaMutation>;
export type CreateOrUpdateKpiFormulaMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateKpiFormulaMutation, CreateOrUpdateKpiFormulaMutationVariables>;
export const DeleteKpiFormulaDocument = gql`
    mutation DeleteKpiFormula($id: Int!) {
  p {
    deleteKpiFormula(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteKpiFormulaMutationFn = Apollo.MutationFunction<DeleteKpiFormulaMutation, DeleteKpiFormulaMutationVariables>;

/**
 * __useDeleteKpiFormulaMutation__
 *
 * To run a mutation, you first call `useDeleteKpiFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKpiFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKpiFormulaMutation, { data, loading, error }] = useDeleteKpiFormulaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKpiFormulaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKpiFormulaMutation, DeleteKpiFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKpiFormulaMutation, DeleteKpiFormulaMutationVariables>(DeleteKpiFormulaDocument, options);
      }
export type DeleteKpiFormulaMutationHookResult = ReturnType<typeof useDeleteKpiFormulaMutation>;
export type DeleteKpiFormulaMutationResult = Apollo.MutationResult<DeleteKpiFormulaMutation>;
export type DeleteKpiFormulaMutationOptions = Apollo.BaseMutationOptions<DeleteKpiFormulaMutation, DeleteKpiFormulaMutationVariables>;
export const KpiFormulasDocument = gql`
    query KpiFormulas($name: String, $status: ProgramStatus, $formulas: KpiFormulaType, $kpiFormulaCd: String) {
  p {
    kpiFormulas(
      name: $name
      status: $status
      formulas: $formulas
      kpiFormulaCd: $kpiFormulaCd
    ) {
      id
      cd
      status
      name
      formula
      description
      creator {
        cd
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useKpiFormulasQuery__
 *
 * To run a query within a React component, call `useKpiFormulasQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiFormulasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiFormulasQuery({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      formulas: // value for 'formulas'
 *      kpiFormulaCd: // value for 'kpiFormulaCd'
 *   },
 * });
 */
export function useKpiFormulasQuery(baseOptions?: Apollo.QueryHookOptions<KpiFormulasQuery, KpiFormulasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpiFormulasQuery, KpiFormulasQueryVariables>(KpiFormulasDocument, options);
      }
export function useKpiFormulasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpiFormulasQuery, KpiFormulasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpiFormulasQuery, KpiFormulasQueryVariables>(KpiFormulasDocument, options);
        }
export type KpiFormulasQueryHookResult = ReturnType<typeof useKpiFormulasQuery>;
export type KpiFormulasLazyQueryHookResult = ReturnType<typeof useKpiFormulasLazyQuery>;
export type KpiFormulasQueryResult = Apollo.QueryResult<KpiFormulasQuery, KpiFormulasQueryVariables>;
export const KpiFormulaDocument = gql`
    query KpiFormula($id: Int!) {
  p {
    kpiFormula(id: $id) {
      ...KpiFormulaFragment
    }
  }
}
    ${KpiFormulaFragmentDoc}`;

/**
 * __useKpiFormulaQuery__
 *
 * To run a query within a React component, call `useKpiFormulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiFormulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiFormulaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKpiFormulaQuery(baseOptions: Apollo.QueryHookOptions<KpiFormulaQuery, KpiFormulaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpiFormulaQuery, KpiFormulaQueryVariables>(KpiFormulaDocument, options);
      }
export function useKpiFormulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpiFormulaQuery, KpiFormulaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpiFormulaQuery, KpiFormulaQueryVariables>(KpiFormulaDocument, options);
        }
export type KpiFormulaQueryHookResult = ReturnType<typeof useKpiFormulaQuery>;
export type KpiFormulaLazyQueryHookResult = ReturnType<typeof useKpiFormulaLazyQuery>;
export type KpiFormulaQueryResult = Apollo.QueryResult<KpiFormulaQuery, KpiFormulaQueryVariables>;
export const ReleaseKpiFormulaDocument = gql`
    mutation ReleaseKpiFormula($id: Int!) {
  p {
    releaseKpiFormula(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseKpiFormulaMutationFn = Apollo.MutationFunction<ReleaseKpiFormulaMutation, ReleaseKpiFormulaMutationVariables>;

/**
 * __useReleaseKpiFormulaMutation__
 *
 * To run a mutation, you first call `useReleaseKpiFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseKpiFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseKpiFormulaMutation, { data, loading, error }] = useReleaseKpiFormulaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseKpiFormulaMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseKpiFormulaMutation, ReleaseKpiFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseKpiFormulaMutation, ReleaseKpiFormulaMutationVariables>(ReleaseKpiFormulaDocument, options);
      }
export type ReleaseKpiFormulaMutationHookResult = ReturnType<typeof useReleaseKpiFormulaMutation>;
export type ReleaseKpiFormulaMutationResult = Apollo.MutationResult<ReleaseKpiFormulaMutation>;
export type ReleaseKpiFormulaMutationOptions = Apollo.BaseMutationOptions<ReleaseKpiFormulaMutation, ReleaseKpiFormulaMutationVariables>;
export const CalculateKpiConfigDocument = gql`
    mutation calculateKpiConfig($id: Int!) {
  p {
    calculateKpiConfig(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type CalculateKpiConfigMutationFn = Apollo.MutationFunction<CalculateKpiConfigMutation, CalculateKpiConfigMutationVariables>;

/**
 * __useCalculateKpiConfigMutation__
 *
 * To run a mutation, you first call `useCalculateKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateKpiConfigMutation, { data, loading, error }] = useCalculateKpiConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalculateKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<CalculateKpiConfigMutation, CalculateKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateKpiConfigMutation, CalculateKpiConfigMutationVariables>(CalculateKpiConfigDocument, options);
      }
export type CalculateKpiConfigMutationHookResult = ReturnType<typeof useCalculateKpiConfigMutation>;
export type CalculateKpiConfigMutationResult = Apollo.MutationResult<CalculateKpiConfigMutation>;
export type CalculateKpiConfigMutationOptions = Apollo.BaseMutationOptions<CalculateKpiConfigMutation, CalculateKpiConfigMutationVariables>;
export const CloseKpiConfigDocument = gql`
    mutation CloseKpiConfig($id: Int!) {
  p {
    closeKpiConfig(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseKpiConfigMutationFn = Apollo.MutationFunction<CloseKpiConfigMutation, CloseKpiConfigMutationVariables>;

/**
 * __useCloseKpiConfigMutation__
 *
 * To run a mutation, you first call `useCloseKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeKpiConfigMutation, { data, loading, error }] = useCloseKpiConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<CloseKpiConfigMutation, CloseKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseKpiConfigMutation, CloseKpiConfigMutationVariables>(CloseKpiConfigDocument, options);
      }
export type CloseKpiConfigMutationHookResult = ReturnType<typeof useCloseKpiConfigMutation>;
export type CloseKpiConfigMutationResult = Apollo.MutationResult<CloseKpiConfigMutation>;
export type CloseKpiConfigMutationOptions = Apollo.BaseMutationOptions<CloseKpiConfigMutation, CloseKpiConfigMutationVariables>;
export const CreateOrUpdateKpiConfigDocument = gql`
    mutation CreateOrUpdateKpiConfig($data: KpiConfigInput!) {
  p {
    createOrUpdateKpiConfig(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...KpiConfigFragment
      }
    }
  }
}
    ${KpiConfigFragmentDoc}`;
export type CreateOrUpdateKpiConfigMutationFn = Apollo.MutationFunction<CreateOrUpdateKpiConfigMutation, CreateOrUpdateKpiConfigMutationVariables>;

/**
 * __useCreateOrUpdateKpiConfigMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateKpiConfigMutation, { data, loading, error }] = useCreateOrUpdateKpiConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateKpiConfigMutation, CreateOrUpdateKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateKpiConfigMutation, CreateOrUpdateKpiConfigMutationVariables>(CreateOrUpdateKpiConfigDocument, options);
      }
export type CreateOrUpdateKpiConfigMutationHookResult = ReturnType<typeof useCreateOrUpdateKpiConfigMutation>;
export type CreateOrUpdateKpiConfigMutationResult = Apollo.MutationResult<CreateOrUpdateKpiConfigMutation>;
export type CreateOrUpdateKpiConfigMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateKpiConfigMutation, CreateOrUpdateKpiConfigMutationVariables>;
export const DeleteKpiConfigDocument = gql`
    mutation DeleteKpiConfig($id: Int!) {
  p {
    deleteKpiConfig(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteKpiConfigMutationFn = Apollo.MutationFunction<DeleteKpiConfigMutation, DeleteKpiConfigMutationVariables>;

/**
 * __useDeleteKpiConfigMutation__
 *
 * To run a mutation, you first call `useDeleteKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKpiConfigMutation, { data, loading, error }] = useDeleteKpiConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKpiConfigMutation, DeleteKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKpiConfigMutation, DeleteKpiConfigMutationVariables>(DeleteKpiConfigDocument, options);
      }
export type DeleteKpiConfigMutationHookResult = ReturnType<typeof useDeleteKpiConfigMutation>;
export type DeleteKpiConfigMutationResult = Apollo.MutationResult<DeleteKpiConfigMutation>;
export type DeleteKpiConfigMutationOptions = Apollo.BaseMutationOptions<DeleteKpiConfigMutation, DeleteKpiConfigMutationVariables>;
export const KpiConfigListDocument = gql`
    query KpiConfigList($page: Int!, $take: Int!, $name: String, $status: ProgramStatus, $description: String, $kpiConfigCd: String, $startDate: Date, $endDate: Date) {
  p {
    kpiConfigList(
      page: $page
      take: $take
      name: $name
      status: $status
      description: $description
      kpiConfigCd: $kpiConfigCd
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      data {
        id
        cd
        status
        target
        name
        description
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useKpiConfigListQuery__
 *
 * To run a query within a React component, call `useKpiConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiConfigListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      description: // value for 'description'
 *      kpiConfigCd: // value for 'kpiConfigCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useKpiConfigListQuery(baseOptions: Apollo.QueryHookOptions<KpiConfigListQuery, KpiConfigListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpiConfigListQuery, KpiConfigListQueryVariables>(KpiConfigListDocument, options);
      }
export function useKpiConfigListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpiConfigListQuery, KpiConfigListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpiConfigListQuery, KpiConfigListQueryVariables>(KpiConfigListDocument, options);
        }
export type KpiConfigListQueryHookResult = ReturnType<typeof useKpiConfigListQuery>;
export type KpiConfigListLazyQueryHookResult = ReturnType<typeof useKpiConfigListLazyQuery>;
export type KpiConfigListQueryResult = Apollo.QueryResult<KpiConfigListQuery, KpiConfigListQueryVariables>;
export const KpiConfigDocument = gql`
    query KpiConfig($id: Int!) {
  p {
    kpiConfig(id: $id) {
      ...KpiConfigFragment
    }
  }
}
    ${KpiConfigFragmentDoc}`;

/**
 * __useKpiConfigQuery__
 *
 * To run a query within a React component, call `useKpiConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKpiConfigQuery(baseOptions: Apollo.QueryHookOptions<KpiConfigQuery, KpiConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpiConfigQuery, KpiConfigQueryVariables>(KpiConfigDocument, options);
      }
export function useKpiConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpiConfigQuery, KpiConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpiConfigQuery, KpiConfigQueryVariables>(KpiConfigDocument, options);
        }
export type KpiConfigQueryHookResult = ReturnType<typeof useKpiConfigQuery>;
export type KpiConfigLazyQueryHookResult = ReturnType<typeof useKpiConfigLazyQuery>;
export type KpiConfigQueryResult = Apollo.QueryResult<KpiConfigQuery, KpiConfigQueryVariables>;
export const ReleaseKpiConfigDocument = gql`
    mutation ReleaseKpiConfig($id: Int!) {
  p {
    releaseKpiConfig(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseKpiConfigMutationFn = Apollo.MutationFunction<ReleaseKpiConfigMutation, ReleaseKpiConfigMutationVariables>;

/**
 * __useReleaseKpiConfigMutation__
 *
 * To run a mutation, you first call `useReleaseKpiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseKpiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseKpiConfigMutation, { data, loading, error }] = useReleaseKpiConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseKpiConfigMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseKpiConfigMutation, ReleaseKpiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseKpiConfigMutation, ReleaseKpiConfigMutationVariables>(ReleaseKpiConfigDocument, options);
      }
export type ReleaseKpiConfigMutationHookResult = ReturnType<typeof useReleaseKpiConfigMutation>;
export type ReleaseKpiConfigMutationResult = Apollo.MutationResult<ReleaseKpiConfigMutation>;
export type ReleaseKpiConfigMutationOptions = Apollo.BaseMutationOptions<ReleaseKpiConfigMutation, ReleaseKpiConfigMutationVariables>;
export const CloseMcpDocument = gql`
    mutation CloseMcp($id: Int!) {
  mcp {
    closeMcp(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseMcpMutationFn = Apollo.MutationFunction<CloseMcpMutation, CloseMcpMutationVariables>;

/**
 * __useCloseMcpMutation__
 *
 * To run a mutation, you first call `useCloseMcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseMcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeMcpMutation, { data, loading, error }] = useCloseMcpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseMcpMutation(baseOptions?: Apollo.MutationHookOptions<CloseMcpMutation, CloseMcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseMcpMutation, CloseMcpMutationVariables>(CloseMcpDocument, options);
      }
export type CloseMcpMutationHookResult = ReturnType<typeof useCloseMcpMutation>;
export type CloseMcpMutationResult = Apollo.MutationResult<CloseMcpMutation>;
export type CloseMcpMutationOptions = Apollo.BaseMutationOptions<CloseMcpMutation, CloseMcpMutationVariables>;
export const CopyMcpDocument = gql`
    mutation CopyMcp($id: Int!) {
  mcp {
    copyMcp(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CopyMcpMutationFn = Apollo.MutationFunction<CopyMcpMutation, CopyMcpMutationVariables>;

/**
 * __useCopyMcpMutation__
 *
 * To run a mutation, you first call `useCopyMcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMcpMutation, { data, loading, error }] = useCopyMcpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyMcpMutation(baseOptions?: Apollo.MutationHookOptions<CopyMcpMutation, CopyMcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyMcpMutation, CopyMcpMutationVariables>(CopyMcpDocument, options);
      }
export type CopyMcpMutationHookResult = ReturnType<typeof useCopyMcpMutation>;
export type CopyMcpMutationResult = Apollo.MutationResult<CopyMcpMutation>;
export type CopyMcpMutationOptions = Apollo.BaseMutationOptions<CopyMcpMutation, CopyMcpMutationVariables>;
export const CreateOrUpdateMcpLineDocument = gql`
    mutation CreateOrUpdateMcpLine($data: McpLineInput!) {
  mcp {
    createOrUpdateMcpLine(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        weekdays
        weeksOfMonth
      }
    }
  }
}
    `;
export type CreateOrUpdateMcpLineMutationFn = Apollo.MutationFunction<CreateOrUpdateMcpLineMutation, CreateOrUpdateMcpLineMutationVariables>;

/**
 * __useCreateOrUpdateMcpLineMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMcpLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMcpLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMcpLineMutation, { data, loading, error }] = useCreateOrUpdateMcpLineMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateMcpLineMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateMcpLineMutation, CreateOrUpdateMcpLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateMcpLineMutation, CreateOrUpdateMcpLineMutationVariables>(CreateOrUpdateMcpLineDocument, options);
      }
export type CreateOrUpdateMcpLineMutationHookResult = ReturnType<typeof useCreateOrUpdateMcpLineMutation>;
export type CreateOrUpdateMcpLineMutationResult = Apollo.MutationResult<CreateOrUpdateMcpLineMutation>;
export type CreateOrUpdateMcpLineMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateMcpLineMutation, CreateOrUpdateMcpLineMutationVariables>;
export const CreateOrUpdateMcpDocument = gql`
    mutation CreateOrUpdateMcp($data: McpInput!) {
  mcp {
    createOrUpdateMcp(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...McpFragment
        mcpDistributors {
          id
          distributor {
            id
            name
          }
        }
      }
    }
  }
}
    ${McpFragmentDoc}`;
export type CreateOrUpdateMcpMutationFn = Apollo.MutationFunction<CreateOrUpdateMcpMutation, CreateOrUpdateMcpMutationVariables>;

/**
 * __useCreateOrUpdateMcpMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMcpMutation, { data, loading, error }] = useCreateOrUpdateMcpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateMcpMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateMcpMutation, CreateOrUpdateMcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateMcpMutation, CreateOrUpdateMcpMutationVariables>(CreateOrUpdateMcpDocument, options);
      }
export type CreateOrUpdateMcpMutationHookResult = ReturnType<typeof useCreateOrUpdateMcpMutation>;
export type CreateOrUpdateMcpMutationResult = Apollo.MutationResult<CreateOrUpdateMcpMutation>;
export type CreateOrUpdateMcpMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateMcpMutation, CreateOrUpdateMcpMutationVariables>;
export const CustomersByDistributorsDocument = gql`
    query CustomersByDistributors($page: Int!, $take: Int!, $distributorIds: [Int!]!, $filter: String) {
  mcp {
    customersByDistributors(
      page: $page
      take: $take
      distributorIds: $distributorIds
      filter: $filter
    ) {
      total
      data {
        id
        cd
        name
        customerPriceClassId
        isDistributor
      }
    }
  }
}
    `;

/**
 * __useCustomersByDistributorsQuery__
 *
 * To run a query within a React component, call `useCustomersByDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersByDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersByDistributorsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorIds: // value for 'distributorIds'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomersByDistributorsQuery(baseOptions: Apollo.QueryHookOptions<CustomersByDistributorsQuery, CustomersByDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersByDistributorsQuery, CustomersByDistributorsQueryVariables>(CustomersByDistributorsDocument, options);
      }
export function useCustomersByDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersByDistributorsQuery, CustomersByDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersByDistributorsQuery, CustomersByDistributorsQueryVariables>(CustomersByDistributorsDocument, options);
        }
export type CustomersByDistributorsQueryHookResult = ReturnType<typeof useCustomersByDistributorsQuery>;
export type CustomersByDistributorsLazyQueryHookResult = ReturnType<typeof useCustomersByDistributorsLazyQuery>;
export type CustomersByDistributorsQueryResult = Apollo.QueryResult<CustomersByDistributorsQuery, CustomersByDistributorsQueryVariables>;
export const DeleteMcpLineDocument = gql`
    mutation DeleteMcpLine($id: Int!) {
  mcp {
    deleteMcpLine(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteMcpLineMutationFn = Apollo.MutationFunction<DeleteMcpLineMutation, DeleteMcpLineMutationVariables>;

/**
 * __useDeleteMcpLineMutation__
 *
 * To run a mutation, you first call `useDeleteMcpLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMcpLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMcpLineMutation, { data, loading, error }] = useDeleteMcpLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMcpLineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMcpLineMutation, DeleteMcpLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMcpLineMutation, DeleteMcpLineMutationVariables>(DeleteMcpLineDocument, options);
      }
export type DeleteMcpLineMutationHookResult = ReturnType<typeof useDeleteMcpLineMutation>;
export type DeleteMcpLineMutationResult = Apollo.MutationResult<DeleteMcpLineMutation>;
export type DeleteMcpLineMutationOptions = Apollo.BaseMutationOptions<DeleteMcpLineMutation, DeleteMcpLineMutationVariables>;
export const McpDetailsDocument = gql`
    query McpDetails($mcpId: Int!, $visitDate: Date!) {
  mcp {
    mcpDetails(mcpId: $mcpId, visitDate: $visitDate) {
      id
      visitIndex
      mcpLine {
        id
        weekdays
        weeksOfMonth
        customer {
          cd
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMcpDetailsQuery__
 *
 * To run a query within a React component, call `useMcpDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpDetailsQuery({
 *   variables: {
 *      mcpId: // value for 'mcpId'
 *      visitDate: // value for 'visitDate'
 *   },
 * });
 */
export function useMcpDetailsQuery(baseOptions: Apollo.QueryHookOptions<McpDetailsQuery, McpDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<McpDetailsQuery, McpDetailsQueryVariables>(McpDetailsDocument, options);
      }
export function useMcpDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<McpDetailsQuery, McpDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<McpDetailsQuery, McpDetailsQueryVariables>(McpDetailsDocument, options);
        }
export type McpDetailsQueryHookResult = ReturnType<typeof useMcpDetailsQuery>;
export type McpDetailsLazyQueryHookResult = ReturnType<typeof useMcpDetailsLazyQuery>;
export type McpDetailsQueryResult = Apollo.QueryResult<McpDetailsQuery, McpDetailsQueryVariables>;
export const McpLineListDocument = gql`
    query McpLineList($mcpId: Int!, $page: Int!, $take: Int!, $cd: String!, $name: String!) {
  mcp {
    mcpLineList(mcpId: $mcpId, page: $page, take: $take, cd: $cd, name: $name) {
      total
      data {
        id
        weekdays
        weeksOfMonth
        distributors {
          id
          cd
          name
        }
        mcpDistributors {
          id
          distributor {
            id
            cd
            name
          }
        }
        customer {
          id
          cd
          name
          phone11
        }
      }
    }
  }
}
    `;

/**
 * __useMcpLineListQuery__
 *
 * To run a query within a React component, call `useMcpLineListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpLineListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpLineListQuery({
 *   variables: {
 *      mcpId: // value for 'mcpId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMcpLineListQuery(baseOptions: Apollo.QueryHookOptions<McpLineListQuery, McpLineListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<McpLineListQuery, McpLineListQueryVariables>(McpLineListDocument, options);
      }
export function useMcpLineListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<McpLineListQuery, McpLineListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<McpLineListQuery, McpLineListQueryVariables>(McpLineListDocument, options);
        }
export type McpLineListQueryHookResult = ReturnType<typeof useMcpLineListQuery>;
export type McpLineListLazyQueryHookResult = ReturnType<typeof useMcpLineListLazyQuery>;
export type McpLineListQueryResult = Apollo.QueryResult<McpLineListQuery, McpLineListQueryVariables>;
export const McpListDocument = gql`
    query McpList($page: Int!, $take: Int!, $status: ProgramStatus, $salesmanCd: String, $mcpCd: String, $startDate: Date, $endDate: Date, $salesRegionId: Int) {
  mcp {
    mcpList(
      page: $page
      take: $take
      status: $status
      startDate: $startDate
      endDate: $endDate
      salesmanCd: $salesmanCd
      mcpCd: $mcpCd
      salesRegionId: $salesRegionId
    ) {
      total
      data {
        ...McpFragment
      }
    }
  }
}
    ${McpFragmentDoc}`;

/**
 * __useMcpListQuery__
 *
 * To run a query within a React component, call `useMcpListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      status: // value for 'status'
 *      salesmanCd: // value for 'salesmanCd'
 *      mcpCd: // value for 'mcpCd'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useMcpListQuery(baseOptions: Apollo.QueryHookOptions<McpListQuery, McpListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<McpListQuery, McpListQueryVariables>(McpListDocument, options);
      }
export function useMcpListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<McpListQuery, McpListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<McpListQuery, McpListQueryVariables>(McpListDocument, options);
        }
export type McpListQueryHookResult = ReturnType<typeof useMcpListQuery>;
export type McpListLazyQueryHookResult = ReturnType<typeof useMcpListLazyQuery>;
export type McpListQueryResult = Apollo.QueryResult<McpListQuery, McpListQueryVariables>;
export const McpDocument = gql`
    query Mcp($id: Int!) {
  mcp {
    mcp(id: $id) {
      ...McpFragment
      mcpDistributors {
        id
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    ${McpFragmentDoc}`;

/**
 * __useMcpQuery__
 *
 * To run a query within a React component, call `useMcpQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMcpQuery(baseOptions: Apollo.QueryHookOptions<McpQuery, McpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<McpQuery, McpQueryVariables>(McpDocument, options);
      }
export function useMcpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<McpQuery, McpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<McpQuery, McpQueryVariables>(McpDocument, options);
        }
export type McpQueryHookResult = ReturnType<typeof useMcpQuery>;
export type McpLazyQueryHookResult = ReturnType<typeof useMcpLazyQuery>;
export type McpQueryResult = Apollo.QueryResult<McpQuery, McpQueryVariables>;
export const ReleaseMcpDocument = gql`
    mutation ReleaseMcp($id: Int!) {
  mcp {
    releaseMcp(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseMcpMutationFn = Apollo.MutationFunction<ReleaseMcpMutation, ReleaseMcpMutationVariables>;

/**
 * __useReleaseMcpMutation__
 *
 * To run a mutation, you first call `useReleaseMcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseMcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseMcpMutation, { data, loading, error }] = useReleaseMcpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseMcpMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseMcpMutation, ReleaseMcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseMcpMutation, ReleaseMcpMutationVariables>(ReleaseMcpDocument, options);
      }
export type ReleaseMcpMutationHookResult = ReturnType<typeof useReleaseMcpMutation>;
export type ReleaseMcpMutationResult = Apollo.MutationResult<ReleaseMcpMutation>;
export type ReleaseMcpMutationOptions = Apollo.BaseMutationOptions<ReleaseMcpMutation, ReleaseMcpMutationVariables>;
export const CreateOrUpdateMeasureUnitDocument = gql`
    mutation CreateOrUpdateMeasureUnit($data: MeasureUnitInput!) {
  md {
    createOrUpdateMeasureUnit(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...MeasureUnitFragment
      }
    }
  }
}
    ${MeasureUnitFragmentDoc}`;
export type CreateOrUpdateMeasureUnitMutationFn = Apollo.MutationFunction<CreateOrUpdateMeasureUnitMutation, CreateOrUpdateMeasureUnitMutationVariables>;

/**
 * __useCreateOrUpdateMeasureUnitMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMeasureUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMeasureUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMeasureUnitMutation, { data, loading, error }] = useCreateOrUpdateMeasureUnitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateMeasureUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateMeasureUnitMutation, CreateOrUpdateMeasureUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateMeasureUnitMutation, CreateOrUpdateMeasureUnitMutationVariables>(CreateOrUpdateMeasureUnitDocument, options);
      }
export type CreateOrUpdateMeasureUnitMutationHookResult = ReturnType<typeof useCreateOrUpdateMeasureUnitMutation>;
export type CreateOrUpdateMeasureUnitMutationResult = Apollo.MutationResult<CreateOrUpdateMeasureUnitMutation>;
export type CreateOrUpdateMeasureUnitMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateMeasureUnitMutation, CreateOrUpdateMeasureUnitMutationVariables>;
export const DeleteMeasureUnitDocument = gql`
    mutation DeleteMeasureUnit($id: String!) {
  md {
    deleteMeasureUnit(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteMeasureUnitMutationFn = Apollo.MutationFunction<DeleteMeasureUnitMutation, DeleteMeasureUnitMutationVariables>;

/**
 * __useDeleteMeasureUnitMutation__
 *
 * To run a mutation, you first call `useDeleteMeasureUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeasureUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeasureUnitMutation, { data, loading, error }] = useDeleteMeasureUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMeasureUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeasureUnitMutation, DeleteMeasureUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMeasureUnitMutation, DeleteMeasureUnitMutationVariables>(DeleteMeasureUnitDocument, options);
      }
export type DeleteMeasureUnitMutationHookResult = ReturnType<typeof useDeleteMeasureUnitMutation>;
export type DeleteMeasureUnitMutationResult = Apollo.MutationResult<DeleteMeasureUnitMutation>;
export type DeleteMeasureUnitMutationOptions = Apollo.BaseMutationOptions<DeleteMeasureUnitMutation, DeleteMeasureUnitMutationVariables>;
export const MeasureUnitsDocument = gql`
    query MeasureUnits {
  md {
    measureUnits {
      ...MeasureUnitFragment
    }
  }
}
    ${MeasureUnitFragmentDoc}`;

/**
 * __useMeasureUnitsQuery__
 *
 * To run a query within a React component, call `useMeasureUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasureUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasureUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeasureUnitsQuery(baseOptions?: Apollo.QueryHookOptions<MeasureUnitsQuery, MeasureUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeasureUnitsQuery, MeasureUnitsQueryVariables>(MeasureUnitsDocument, options);
      }
export function useMeasureUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeasureUnitsQuery, MeasureUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeasureUnitsQuery, MeasureUnitsQueryVariables>(MeasureUnitsDocument, options);
        }
export type MeasureUnitsQueryHookResult = ReturnType<typeof useMeasureUnitsQuery>;
export type MeasureUnitsLazyQueryHookResult = ReturnType<typeof useMeasureUnitsLazyQuery>;
export type MeasureUnitsQueryResult = Apollo.QueryResult<MeasureUnitsQuery, MeasureUnitsQueryVariables>;
export const MeasureUnitDocument = gql`
    query MeasureUnit($id: String!) {
  md {
    measureUnit(id: $id) {
      ...MeasureUnitFragment
    }
  }
}
    ${MeasureUnitFragmentDoc}`;

/**
 * __useMeasureUnitQuery__
 *
 * To run a query within a React component, call `useMeasureUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasureUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasureUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeasureUnitQuery(baseOptions: Apollo.QueryHookOptions<MeasureUnitQuery, MeasureUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeasureUnitQuery, MeasureUnitQueryVariables>(MeasureUnitDocument, options);
      }
export function useMeasureUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeasureUnitQuery, MeasureUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeasureUnitQuery, MeasureUnitQueryVariables>(MeasureUnitDocument, options);
        }
export type MeasureUnitQueryHookResult = ReturnType<typeof useMeasureUnitQuery>;
export type MeasureUnitLazyQueryHookResult = ReturnType<typeof useMeasureUnitLazyQuery>;
export type MeasureUnitQueryResult = Apollo.QueryResult<MeasureUnitQuery, MeasureUnitQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  md {
    changePassword(data: $data) {
      success
      message {
        code
        message
      }
      content {
        accessToken
      }
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!, $deviceUser: DeviceUserInput) {
  login(data: $data, deviceUser: $deviceUser) {
    success
    message {
      code
      message
    }
    content {
      accessToken
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *      deviceUser: // value for 'deviceUser'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
    message {
      code
      message
    }
    content
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    cd
    name
    username
    permissions
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ProductPricesDocument = gql`
    query ProductPrices($date: Date, $customerPriceClassId: Int) {
  productPrices(date: $date, customerPriceClassId: $customerPriceClassId) {
    id
    productId
    unitId
    price
  }
}
    `;

/**
 * __useProductPricesQuery__
 *
 * To run a query within a React component, call `useProductPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPricesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      customerPriceClassId: // value for 'customerPriceClassId'
 *   },
 * });
 */
export function useProductPricesQuery(baseOptions?: Apollo.QueryHookOptions<ProductPricesQuery, ProductPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductPricesQuery, ProductPricesQueryVariables>(ProductPricesDocument, options);
      }
export function useProductPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductPricesQuery, ProductPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductPricesQuery, ProductPricesQueryVariables>(ProductPricesDocument, options);
        }
export type ProductPricesQueryHookResult = ReturnType<typeof useProductPricesQuery>;
export type ProductPricesLazyQueryHookResult = ReturnType<typeof useProductPricesLazyQuery>;
export type ProductPricesQueryResult = Apollo.QueryResult<ProductPricesQuery, ProductPricesQueryVariables>;
export const WorkingCustomerListByMcpDocument = gql`
    query WorkingCustomerListByMcp($page: Int!, $take: Int!, $distributorId: Int!, $filter: String, $isDistributor: Boolean) {
  workingCustomerListByMcp(
    page: $page
    take: $take
    distributorId: $distributorId
    filter: $filter
    isDistributor: $isDistributor
  ) {
    total
    data {
      id
      cd
      name
      customerPriceClassId
      isDistributor
    }
  }
}
    `;

/**
 * __useWorkingCustomerListByMcpQuery__
 *
 * To run a query within a React component, call `useWorkingCustomerListByMcpQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingCustomerListByMcpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingCustomerListByMcpQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorId: // value for 'distributorId'
 *      filter: // value for 'filter'
 *      isDistributor: // value for 'isDistributor'
 *   },
 * });
 */
export function useWorkingCustomerListByMcpQuery(baseOptions: Apollo.QueryHookOptions<WorkingCustomerListByMcpQuery, WorkingCustomerListByMcpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingCustomerListByMcpQuery, WorkingCustomerListByMcpQueryVariables>(WorkingCustomerListByMcpDocument, options);
      }
export function useWorkingCustomerListByMcpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingCustomerListByMcpQuery, WorkingCustomerListByMcpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingCustomerListByMcpQuery, WorkingCustomerListByMcpQueryVariables>(WorkingCustomerListByMcpDocument, options);
        }
export type WorkingCustomerListByMcpQueryHookResult = ReturnType<typeof useWorkingCustomerListByMcpQuery>;
export type WorkingCustomerListByMcpLazyQueryHookResult = ReturnType<typeof useWorkingCustomerListByMcpLazyQuery>;
export type WorkingCustomerListByMcpQueryResult = Apollo.QueryResult<WorkingCustomerListByMcpQuery, WorkingCustomerListByMcpQueryVariables>;
export const WorkingCustomerListDocument = gql`
    query WorkingCustomerList($page: Int!, $take: Int!, $distributorId: Int!, $filter: String, $isDistributor: Boolean) {
  workingCustomerList(
    page: $page
    take: $take
    distributorId: $distributorId
    filter: $filter
    isDistributor: $isDistributor
  ) {
    total
    data {
      id
      cd
      name
      customerPriceClassId
      isDistributor
    }
  }
}
    `;

/**
 * __useWorkingCustomerListQuery__
 *
 * To run a query within a React component, call `useWorkingCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingCustomerListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorId: // value for 'distributorId'
 *      filter: // value for 'filter'
 *      isDistributor: // value for 'isDistributor'
 *   },
 * });
 */
export function useWorkingCustomerListQuery(baseOptions: Apollo.QueryHookOptions<WorkingCustomerListQuery, WorkingCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingCustomerListQuery, WorkingCustomerListQueryVariables>(WorkingCustomerListDocument, options);
      }
export function useWorkingCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingCustomerListQuery, WorkingCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingCustomerListQuery, WorkingCustomerListQueryVariables>(WorkingCustomerListDocument, options);
        }
export type WorkingCustomerListQueryHookResult = ReturnType<typeof useWorkingCustomerListQuery>;
export type WorkingCustomerListLazyQueryHookResult = ReturnType<typeof useWorkingCustomerListLazyQuery>;
export type WorkingCustomerListQueryResult = Apollo.QueryResult<WorkingCustomerListQuery, WorkingCustomerListQueryVariables>;
export const WorkingDistributorsDocument = gql`
    query WorkingDistributors {
  workingDistributors {
    id
    cd
    name
    customer {
      id
      name
      customerPriceClassId
    }
    warehouses {
      id
      name
      pickOrder
      allowPurchase
      allowSales
      allowReceipt
      allowIssue
      allowAdjust
      allowTransfer
    }
  }
}
    `;

/**
 * __useWorkingDistributorsQuery__
 *
 * To run a query within a React component, call `useWorkingDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingDistributorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkingDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<WorkingDistributorsQuery, WorkingDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingDistributorsQuery, WorkingDistributorsQueryVariables>(WorkingDistributorsDocument, options);
      }
export function useWorkingDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingDistributorsQuery, WorkingDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingDistributorsQuery, WorkingDistributorsQueryVariables>(WorkingDistributorsDocument, options);
        }
export type WorkingDistributorsQueryHookResult = ReturnType<typeof useWorkingDistributorsQuery>;
export type WorkingDistributorsLazyQueryHookResult = ReturnType<typeof useWorkingDistributorsLazyQuery>;
export type WorkingDistributorsQueryResult = Apollo.QueryResult<WorkingDistributorsQuery, WorkingDistributorsQueryVariables>;
export const WorkingSalesmansByMcpDocument = gql`
    query WorkingSalesmansByMcp($distributorId: Int!, $customerId: Int!) {
  workingSalesmansByMcp(distributorId: $distributorId, customerId: $customerId) {
    id
    cd
    name
  }
}
    `;

/**
 * __useWorkingSalesmansByMcpQuery__
 *
 * To run a query within a React component, call `useWorkingSalesmansByMcpQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingSalesmansByMcpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingSalesmansByMcpQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useWorkingSalesmansByMcpQuery(baseOptions: Apollo.QueryHookOptions<WorkingSalesmansByMcpQuery, WorkingSalesmansByMcpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingSalesmansByMcpQuery, WorkingSalesmansByMcpQueryVariables>(WorkingSalesmansByMcpDocument, options);
      }
export function useWorkingSalesmansByMcpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingSalesmansByMcpQuery, WorkingSalesmansByMcpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingSalesmansByMcpQuery, WorkingSalesmansByMcpQueryVariables>(WorkingSalesmansByMcpDocument, options);
        }
export type WorkingSalesmansByMcpQueryHookResult = ReturnType<typeof useWorkingSalesmansByMcpQuery>;
export type WorkingSalesmansByMcpLazyQueryHookResult = ReturnType<typeof useWorkingSalesmansByMcpLazyQuery>;
export type WorkingSalesmansByMcpQueryResult = Apollo.QueryResult<WorkingSalesmansByMcpQuery, WorkingSalesmansByMcpQueryVariables>;
export const WorkingSalesmansDocument = gql`
    query WorkingSalesmans($distributorId: Int!) {
  workingSalesmans(distributorId: $distributorId) {
    id
    cd
    name
  }
}
    `;

/**
 * __useWorkingSalesmansQuery__
 *
 * To run a query within a React component, call `useWorkingSalesmansQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingSalesmansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingSalesmansQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useWorkingSalesmansQuery(baseOptions: Apollo.QueryHookOptions<WorkingSalesmansQuery, WorkingSalesmansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingSalesmansQuery, WorkingSalesmansQueryVariables>(WorkingSalesmansDocument, options);
      }
export function useWorkingSalesmansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingSalesmansQuery, WorkingSalesmansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingSalesmansQuery, WorkingSalesmansQueryVariables>(WorkingSalesmansDocument, options);
        }
export type WorkingSalesmansQueryHookResult = ReturnType<typeof useWorkingSalesmansQuery>;
export type WorkingSalesmansLazyQueryHookResult = ReturnType<typeof useWorkingSalesmansLazyQuery>;
export type WorkingSalesmansQueryResult = Apollo.QueryResult<WorkingSalesmansQuery, WorkingSalesmansQueryVariables>;
export const CreateOrUpdateOtherProductDocument = gql`
    mutation CreateOrUpdateOtherProduct($data: OtherProductInput!) {
  md {
    createOrUpdateOtherProduct(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...OtherProductFragment
      }
    }
  }
}
    ${OtherProductFragmentDoc}`;
export type CreateOrUpdateOtherProductMutationFn = Apollo.MutationFunction<CreateOrUpdateOtherProductMutation, CreateOrUpdateOtherProductMutationVariables>;

/**
 * __useCreateOrUpdateOtherProductMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateOtherProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateOtherProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateOtherProductMutation, { data, loading, error }] = useCreateOrUpdateOtherProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateOtherProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateOtherProductMutation, CreateOrUpdateOtherProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateOtherProductMutation, CreateOrUpdateOtherProductMutationVariables>(CreateOrUpdateOtherProductDocument, options);
      }
export type CreateOrUpdateOtherProductMutationHookResult = ReturnType<typeof useCreateOrUpdateOtherProductMutation>;
export type CreateOrUpdateOtherProductMutationResult = Apollo.MutationResult<CreateOrUpdateOtherProductMutation>;
export type CreateOrUpdateOtherProductMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateOtherProductMutation, CreateOrUpdateOtherProductMutationVariables>;
export const DeleteOtherProductDocument = gql`
    mutation DeleteOtherProduct($id: Int!) {
  md {
    deleteOtherProduct(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteOtherProductMutationFn = Apollo.MutationFunction<DeleteOtherProductMutation, DeleteOtherProductMutationVariables>;

/**
 * __useDeleteOtherProductMutation__
 *
 * To run a mutation, you first call `useDeleteOtherProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOtherProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOtherProductMutation, { data, loading, error }] = useDeleteOtherProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOtherProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOtherProductMutation, DeleteOtherProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOtherProductMutation, DeleteOtherProductMutationVariables>(DeleteOtherProductDocument, options);
      }
export type DeleteOtherProductMutationHookResult = ReturnType<typeof useDeleteOtherProductMutation>;
export type DeleteOtherProductMutationResult = Apollo.MutationResult<DeleteOtherProductMutation>;
export type DeleteOtherProductMutationOptions = Apollo.BaseMutationOptions<DeleteOtherProductMutation, DeleteOtherProductMutationVariables>;
export const OtherProductsDocument = gql`
    query OtherProducts($otherProductCd: String, $otherProductName: String) {
  md {
    otherProducts(
      otherProductCd: $otherProductCd
      otherProductName: $otherProductName
    ) {
      ...OtherProductFragment
    }
  }
}
    ${OtherProductFragmentDoc}`;

/**
 * __useOtherProductsQuery__
 *
 * To run a query within a React component, call `useOtherProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherProductsQuery({
 *   variables: {
 *      otherProductCd: // value for 'otherProductCd'
 *      otherProductName: // value for 'otherProductName'
 *   },
 * });
 */
export function useOtherProductsQuery(baseOptions?: Apollo.QueryHookOptions<OtherProductsQuery, OtherProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherProductsQuery, OtherProductsQueryVariables>(OtherProductsDocument, options);
      }
export function useOtherProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherProductsQuery, OtherProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherProductsQuery, OtherProductsQueryVariables>(OtherProductsDocument, options);
        }
export type OtherProductsQueryHookResult = ReturnType<typeof useOtherProductsQuery>;
export type OtherProductsLazyQueryHookResult = ReturnType<typeof useOtherProductsLazyQuery>;
export type OtherProductsQueryResult = Apollo.QueryResult<OtherProductsQuery, OtherProductsQueryVariables>;
export const OtherProductDocument = gql`
    query OtherProduct($id: Int!) {
  md {
    otherProduct(id: $id) {
      ...OtherProductFragment
    }
  }
}
    ${OtherProductFragmentDoc}`;

/**
 * __useOtherProductQuery__
 *
 * To run a query within a React component, call `useOtherProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOtherProductQuery(baseOptions: Apollo.QueryHookOptions<OtherProductQuery, OtherProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherProductQuery, OtherProductQueryVariables>(OtherProductDocument, options);
      }
export function useOtherProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherProductQuery, OtherProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherProductQuery, OtherProductQueryVariables>(OtherProductDocument, options);
        }
export type OtherProductQueryHookResult = ReturnType<typeof useOtherProductQuery>;
export type OtherProductLazyQueryHookResult = ReturnType<typeof useOtherProductLazyQuery>;
export type OtherProductQueryResult = Apollo.QueryResult<OtherProductQuery, OtherProductQueryVariables>;
export const ReportPerformanceDistributorListDocument = gql`
    query ReportPerformanceDistributorList($startDate: Date, $endDate: Date, $distributorId: Int, $productId: Int, $page: Int!, $take: Int!) {
  rp {
    reportPerformanceDistributorList(
      startDate: $startDate
      endDate: $endDate
      distributorId: $distributorId
      productId: $productId
      page: $page
      take: $take
    ) {
      total
      data {
        distributorId
        distributor {
          cd
          name
        }
        productId
        product {
          cd
          name
          baseUnitId
        }
        qtySellOut
        qtySellIn
        percentOutIn
      }
    }
  }
}
    `;

/**
 * __useReportPerformanceDistributorListQuery__
 *
 * To run a query within a React component, call `useReportPerformanceDistributorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPerformanceDistributorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPerformanceDistributorListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      distributorId: // value for 'distributorId'
 *      productId: // value for 'productId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportPerformanceDistributorListQuery(baseOptions: Apollo.QueryHookOptions<ReportPerformanceDistributorListQuery, ReportPerformanceDistributorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportPerformanceDistributorListQuery, ReportPerformanceDistributorListQueryVariables>(ReportPerformanceDistributorListDocument, options);
      }
export function useReportPerformanceDistributorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportPerformanceDistributorListQuery, ReportPerformanceDistributorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportPerformanceDistributorListQuery, ReportPerformanceDistributorListQueryVariables>(ReportPerformanceDistributorListDocument, options);
        }
export type ReportPerformanceDistributorListQueryHookResult = ReturnType<typeof useReportPerformanceDistributorListQuery>;
export type ReportPerformanceDistributorListLazyQueryHookResult = ReturnType<typeof useReportPerformanceDistributorListLazyQuery>;
export type ReportPerformanceDistributorListQueryResult = Apollo.QueryResult<ReportPerformanceDistributorListQuery, ReportPerformanceDistributorListQueryVariables>;
export const UploadPhotoDocument = gql`
    mutation UploadPhoto($data: UploadPhotoInput!) {
  uploadPhoto(data: $data) {
    success
    message {
      code
      message
    }
    content {
      id
      url
    }
  }
}
    `;
export type UploadPhotoMutationFn = Apollo.MutationFunction<UploadPhotoMutation, UploadPhotoMutationVariables>;

/**
 * __useUploadPhotoMutation__
 *
 * To run a mutation, you first call `useUploadPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPhotoMutation, { data, loading, error }] = useUploadPhotoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UploadPhotoMutation, UploadPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPhotoMutation, UploadPhotoMutationVariables>(UploadPhotoDocument, options);
      }
export type UploadPhotoMutationHookResult = ReturnType<typeof useUploadPhotoMutation>;
export type UploadPhotoMutationResult = Apollo.MutationResult<UploadPhotoMutation>;
export type UploadPhotoMutationOptions = Apollo.BaseMutationOptions<UploadPhotoMutation, UploadPhotoMutationVariables>;
export const CreateOrUpdatePositionDocument = gql`
    mutation CreateOrUpdatePosition($data: PositionInput!) {
  md {
    createOrUpdatePosition(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        name
        description
        positionPermissions {
          id
          permission
        }
      }
    }
  }
}
    `;
export type CreateOrUpdatePositionMutationFn = Apollo.MutationFunction<CreateOrUpdatePositionMutation, CreateOrUpdatePositionMutationVariables>;

/**
 * __useCreateOrUpdatePositionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePositionMutation, { data, loading, error }] = useCreateOrUpdatePositionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdatePositionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdatePositionMutation, CreateOrUpdatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdatePositionMutation, CreateOrUpdatePositionMutationVariables>(CreateOrUpdatePositionDocument, options);
      }
export type CreateOrUpdatePositionMutationHookResult = ReturnType<typeof useCreateOrUpdatePositionMutation>;
export type CreateOrUpdatePositionMutationResult = Apollo.MutationResult<CreateOrUpdatePositionMutation>;
export type CreateOrUpdatePositionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdatePositionMutation, CreateOrUpdatePositionMutationVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  md {
    permissions
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const PositionsDocument = gql`
    query Positions {
  md {
    positions {
      ...PositionFragment
    }
  }
}
    ${PositionFragmentDoc}`;

/**
 * __usePositionsQuery__
 *
 * To run a query within a React component, call `usePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePositionsQuery(baseOptions?: Apollo.QueryHookOptions<PositionsQuery, PositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
      }
export function usePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionsQuery, PositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
        }
export type PositionsQueryHookResult = ReturnType<typeof usePositionsQuery>;
export type PositionsLazyQueryHookResult = ReturnType<typeof usePositionsLazyQuery>;
export type PositionsQueryResult = Apollo.QueryResult<PositionsQuery, PositionsQueryVariables>;
export const PositionDocument = gql`
    query Position($id: Int!) {
  md {
    position(id: $id) {
      ...PositionFragment
    }
  }
}
    ${PositionFragmentDoc}`;

/**
 * __usePositionQuery__
 *
 * To run a query within a React component, call `usePositionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionQuery(baseOptions: Apollo.QueryHookOptions<PositionQuery, PositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionQuery, PositionQueryVariables>(PositionDocument, options);
      }
export function usePositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionQuery, PositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionQuery, PositionQueryVariables>(PositionDocument, options);
        }
export type PositionQueryHookResult = ReturnType<typeof usePositionQuery>;
export type PositionLazyQueryHookResult = ReturnType<typeof usePositionLazyQuery>;
export type PositionQueryResult = Apollo.QueryResult<PositionQuery, PositionQueryVariables>;
export const CreateOrUpdateProductGroupDocument = gql`
    mutation CreateOrUpdateProductGroup($data: ProductGroupInput!) {
  md {
    createOrUpdateProductGroup(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...ProductGroupFragment
      }
    }
  }
}
    ${ProductGroupFragmentDoc}`;
export type CreateOrUpdateProductGroupMutationFn = Apollo.MutationFunction<CreateOrUpdateProductGroupMutation, CreateOrUpdateProductGroupMutationVariables>;

/**
 * __useCreateOrUpdateProductGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateProductGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateProductGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateProductGroupMutation, { data, loading, error }] = useCreateOrUpdateProductGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateProductGroupMutation, CreateOrUpdateProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateProductGroupMutation, CreateOrUpdateProductGroupMutationVariables>(CreateOrUpdateProductGroupDocument, options);
      }
export type CreateOrUpdateProductGroupMutationHookResult = ReturnType<typeof useCreateOrUpdateProductGroupMutation>;
export type CreateOrUpdateProductGroupMutationResult = Apollo.MutationResult<CreateOrUpdateProductGroupMutation>;
export type CreateOrUpdateProductGroupMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateProductGroupMutation, CreateOrUpdateProductGroupMutationVariables>;
export const DeleteProductGroupDocument = gql`
    mutation DeleteProductGroup($id: Int!) {
  md {
    deleteProductGroup(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteProductGroupMutationFn = Apollo.MutationFunction<DeleteProductGroupMutation, DeleteProductGroupMutationVariables>;

/**
 * __useDeleteProductGroupMutation__
 *
 * To run a mutation, you first call `useDeleteProductGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductGroupMutation, { data, loading, error }] = useDeleteProductGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductGroupMutation, DeleteProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductGroupMutation, DeleteProductGroupMutationVariables>(DeleteProductGroupDocument, options);
      }
export type DeleteProductGroupMutationHookResult = ReturnType<typeof useDeleteProductGroupMutation>;
export type DeleteProductGroupMutationResult = Apollo.MutationResult<DeleteProductGroupMutation>;
export type DeleteProductGroupMutationOptions = Apollo.BaseMutationOptions<DeleteProductGroupMutation, DeleteProductGroupMutationVariables>;
export const ProductGroupsDocument = gql`
    query ProductGroups($productGroupCd: String, $productGroupName: String, $isBundle: Boolean) {
  md {
    productGroups(
      productGroupCd: $productGroupCd
      productGroupName: $productGroupName
      isBundle: $isBundle
    ) {
      ...ProductGroupFragment
    }
  }
}
    ${ProductGroupFragmentDoc}`;

/**
 * __useProductGroupsQuery__
 *
 * To run a query within a React component, call `useProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupsQuery({
 *   variables: {
 *      productGroupCd: // value for 'productGroupCd'
 *      productGroupName: // value for 'productGroupName'
 *      isBundle: // value for 'isBundle'
 *   },
 * });
 */
export function useProductGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
      }
export function useProductGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
        }
export type ProductGroupsQueryHookResult = ReturnType<typeof useProductGroupsQuery>;
export type ProductGroupsLazyQueryHookResult = ReturnType<typeof useProductGroupsLazyQuery>;
export type ProductGroupsQueryResult = Apollo.QueryResult<ProductGroupsQuery, ProductGroupsQueryVariables>;
export const ProductGroupItemsDocument = gql`
    mutation ProductGroupItems($id: Int!) {
  md {
    productGroupItems(id: $id) {
      id
      productId
      rate
    }
  }
}
    `;
export type ProductGroupItemsMutationFn = Apollo.MutationFunction<ProductGroupItemsMutation, ProductGroupItemsMutationVariables>;

/**
 * __useProductGroupItemsMutation__
 *
 * To run a mutation, you first call `useProductGroupItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductGroupItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productGroupItemsMutation, { data, loading, error }] = useProductGroupItemsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductGroupItemsMutation(baseOptions?: Apollo.MutationHookOptions<ProductGroupItemsMutation, ProductGroupItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductGroupItemsMutation, ProductGroupItemsMutationVariables>(ProductGroupItemsDocument, options);
      }
export type ProductGroupItemsMutationHookResult = ReturnType<typeof useProductGroupItemsMutation>;
export type ProductGroupItemsMutationResult = Apollo.MutationResult<ProductGroupItemsMutation>;
export type ProductGroupItemsMutationOptions = Apollo.BaseMutationOptions<ProductGroupItemsMutation, ProductGroupItemsMutationVariables>;
export const ProductGroupDocument = gql`
    query ProductGroup($id: Int!) {
  md {
    productGroup(id: $id) {
      ...ProductGroupFragment
    }
  }
}
    ${ProductGroupFragmentDoc}`;

/**
 * __useProductGroupQuery__
 *
 * To run a query within a React component, call `useProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductGroupQuery(baseOptions: Apollo.QueryHookOptions<ProductGroupQuery, ProductGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupQuery, ProductGroupQueryVariables>(ProductGroupDocument, options);
      }
export function useProductGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupQuery, ProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupQuery, ProductGroupQueryVariables>(ProductGroupDocument, options);
        }
export type ProductGroupQueryHookResult = ReturnType<typeof useProductGroupQuery>;
export type ProductGroupLazyQueryHookResult = ReturnType<typeof useProductGroupLazyQuery>;
export type ProductGroupQueryResult = Apollo.QueryResult<ProductGroupQuery, ProductGroupQueryVariables>;
export const ActiveProductDocument = gql`
    mutation ActiveProduct($id: Int!) {
  md {
    activeProduct(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ActiveProductMutationFn = Apollo.MutationFunction<ActiveProductMutation, ActiveProductMutationVariables>;

/**
 * __useActiveProductMutation__
 *
 * To run a mutation, you first call `useActiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeProductMutation, { data, loading, error }] = useActiveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActiveProductMutation(baseOptions?: Apollo.MutationHookOptions<ActiveProductMutation, ActiveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveProductMutation, ActiveProductMutationVariables>(ActiveProductDocument, options);
      }
export type ActiveProductMutationHookResult = ReturnType<typeof useActiveProductMutation>;
export type ActiveProductMutationResult = Apollo.MutationResult<ActiveProductMutation>;
export type ActiveProductMutationOptions = Apollo.BaseMutationOptions<ActiveProductMutation, ActiveProductMutationVariables>;
export const AllProductsDocument = gql`
    query AllProducts($productFilter: String, $productName: String, $unitId: String, $description: String) {
  md {
    allProducts(
      productFilter: $productFilter
      productName: $productName
      unitId: $unitId
      description: $description
    ) {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useAllProductsQuery__
 *
 * To run a query within a React component, call `useAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsQuery({
 *   variables: {
 *      productFilter: // value for 'productFilter'
 *      productName: // value for 'productName'
 *      unitId: // value for 'unitId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<AllProductsQuery, AllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, options);
      }
export function useAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProductsQuery, AllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, options);
        }
export type AllProductsQueryHookResult = ReturnType<typeof useAllProductsQuery>;
export type AllProductsLazyQueryHookResult = ReturnType<typeof useAllProductsLazyQuery>;
export type AllProductsQueryResult = Apollo.QueryResult<AllProductsQuery, AllProductsQueryVariables>;
export const CreateOrUpdateProductDocument = gql`
    mutation CreateOrUpdateProduct($data: ProductInput!) {
  md {
    createOrUpdateProduct(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...ProductFragment
        productAttrDetails {
          id
          attrId
          attrDetailId
        }
      }
    }
  }
}
    ${ProductFragmentDoc}`;
export type CreateOrUpdateProductMutationFn = Apollo.MutationFunction<CreateOrUpdateProductMutation, CreateOrUpdateProductMutationVariables>;

/**
 * __useCreateOrUpdateProductMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateProductMutation, { data, loading, error }] = useCreateOrUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateProductMutation, CreateOrUpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateProductMutation, CreateOrUpdateProductMutationVariables>(CreateOrUpdateProductDocument, options);
      }
export type CreateOrUpdateProductMutationHookResult = ReturnType<typeof useCreateOrUpdateProductMutation>;
export type CreateOrUpdateProductMutationResult = Apollo.MutationResult<CreateOrUpdateProductMutation>;
export type CreateOrUpdateProductMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateProductMutation, CreateOrUpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: Int!) {
  md {
    deleteProduct(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const ProductsDocument = gql`
    query Products {
  md {
    products {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: Int!) {
  md {
    product(id: $id) {
      ...ProductFragment
      productAttrDetails {
        id
        attrId
        attrDetailId
      }
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ActionCancelPurchaseReceiptDocument = gql`
    mutation ActionCancelPurchaseReceipt($listIds: [Int!]!) {
  o {
    actionCancelPurchaseReceipt(listIds: $listIds)
  }
}
    `;
export type ActionCancelPurchaseReceiptMutationFn = Apollo.MutationFunction<ActionCancelPurchaseReceiptMutation, ActionCancelPurchaseReceiptMutationVariables>;

/**
 * __useActionCancelPurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useActionCancelPurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionCancelPurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionCancelPurchaseReceiptMutation, { data, loading, error }] = useActionCancelPurchaseReceiptMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useActionCancelPurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ActionCancelPurchaseReceiptMutation, ActionCancelPurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionCancelPurchaseReceiptMutation, ActionCancelPurchaseReceiptMutationVariables>(ActionCancelPurchaseReceiptDocument, options);
      }
export type ActionCancelPurchaseReceiptMutationHookResult = ReturnType<typeof useActionCancelPurchaseReceiptMutation>;
export type ActionCancelPurchaseReceiptMutationResult = Apollo.MutationResult<ActionCancelPurchaseReceiptMutation>;
export type ActionCancelPurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<ActionCancelPurchaseReceiptMutation, ActionCancelPurchaseReceiptMutationVariables>;
export const ActionCompletePurchaseReceiptDocument = gql`
    mutation ActionCompletePurchaseReceipt($listIds: [Int!]!) {
  o {
    actionCompletePurchaseReceipt(listIds: $listIds)
  }
}
    `;
export type ActionCompletePurchaseReceiptMutationFn = Apollo.MutationFunction<ActionCompletePurchaseReceiptMutation, ActionCompletePurchaseReceiptMutationVariables>;

/**
 * __useActionCompletePurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useActionCompletePurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionCompletePurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionCompletePurchaseReceiptMutation, { data, loading, error }] = useActionCompletePurchaseReceiptMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useActionCompletePurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ActionCompletePurchaseReceiptMutation, ActionCompletePurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionCompletePurchaseReceiptMutation, ActionCompletePurchaseReceiptMutationVariables>(ActionCompletePurchaseReceiptDocument, options);
      }
export type ActionCompletePurchaseReceiptMutationHookResult = ReturnType<typeof useActionCompletePurchaseReceiptMutation>;
export type ActionCompletePurchaseReceiptMutationResult = Apollo.MutationResult<ActionCompletePurchaseReceiptMutation>;
export type ActionCompletePurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<ActionCompletePurchaseReceiptMutation, ActionCompletePurchaseReceiptMutationVariables>;
export const ActionProcessPurchaseReceiptDocument = gql`
    mutation ActionProcessPurchaseReceipt($listIds: [Int!]!) {
  o {
    actionProcessPurchaseReceipt(listIds: $listIds)
  }
}
    `;
export type ActionProcessPurchaseReceiptMutationFn = Apollo.MutationFunction<ActionProcessPurchaseReceiptMutation, ActionProcessPurchaseReceiptMutationVariables>;

/**
 * __useActionProcessPurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useActionProcessPurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionProcessPurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionProcessPurchaseReceiptMutation, { data, loading, error }] = useActionProcessPurchaseReceiptMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useActionProcessPurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ActionProcessPurchaseReceiptMutation, ActionProcessPurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionProcessPurchaseReceiptMutation, ActionProcessPurchaseReceiptMutationVariables>(ActionProcessPurchaseReceiptDocument, options);
      }
export type ActionProcessPurchaseReceiptMutationHookResult = ReturnType<typeof useActionProcessPurchaseReceiptMutation>;
export type ActionProcessPurchaseReceiptMutationResult = Apollo.MutationResult<ActionProcessPurchaseReceiptMutation>;
export type ActionProcessPurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<ActionProcessPurchaseReceiptMutation, ActionProcessPurchaseReceiptMutationVariables>;
export const CancelPurchaseReceiptDocument = gql`
    mutation CancelPurchaseReceipt($id: Int!) {
  o {
    cancelPurchaseReceipt(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...PurchaseReceiptFragment
      }
    }
  }
}
    ${PurchaseReceiptFragmentDoc}`;
export type CancelPurchaseReceiptMutationFn = Apollo.MutationFunction<CancelPurchaseReceiptMutation, CancelPurchaseReceiptMutationVariables>;

/**
 * __useCancelPurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useCancelPurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPurchaseReceiptMutation, { data, loading, error }] = useCancelPurchaseReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CancelPurchaseReceiptMutation, CancelPurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPurchaseReceiptMutation, CancelPurchaseReceiptMutationVariables>(CancelPurchaseReceiptDocument, options);
      }
export type CancelPurchaseReceiptMutationHookResult = ReturnType<typeof useCancelPurchaseReceiptMutation>;
export type CancelPurchaseReceiptMutationResult = Apollo.MutationResult<CancelPurchaseReceiptMutation>;
export type CancelPurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<CancelPurchaseReceiptMutation, CancelPurchaseReceiptMutationVariables>;
export const CompletePurchaseReceiptDocument = gql`
    mutation CompletePurchaseReceipt($id: Int!) {
  o {
    completePurchaseReceipt(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...PurchaseReceiptFragment
      }
    }
  }
}
    ${PurchaseReceiptFragmentDoc}`;
export type CompletePurchaseReceiptMutationFn = Apollo.MutationFunction<CompletePurchaseReceiptMutation, CompletePurchaseReceiptMutationVariables>;

/**
 * __useCompletePurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useCompletePurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePurchaseReceiptMutation, { data, loading, error }] = useCompletePurchaseReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletePurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CompletePurchaseReceiptMutation, CompletePurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePurchaseReceiptMutation, CompletePurchaseReceiptMutationVariables>(CompletePurchaseReceiptDocument, options);
      }
export type CompletePurchaseReceiptMutationHookResult = ReturnType<typeof useCompletePurchaseReceiptMutation>;
export type CompletePurchaseReceiptMutationResult = Apollo.MutationResult<CompletePurchaseReceiptMutation>;
export type CompletePurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<CompletePurchaseReceiptMutation, CompletePurchaseReceiptMutationVariables>;
export const CreateOrUpdatePurchaseReceiptDocument = gql`
    mutation CreateOrUpdatePurchaseReceipt($data: PurchaseReceiptInput!) {
  o {
    createOrUpdatePurchaseReceipt(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...PurchaseReceiptFragment
      }
    }
  }
}
    ${PurchaseReceiptFragmentDoc}`;
export type CreateOrUpdatePurchaseReceiptMutationFn = Apollo.MutationFunction<CreateOrUpdatePurchaseReceiptMutation, CreateOrUpdatePurchaseReceiptMutationVariables>;

/**
 * __useCreateOrUpdatePurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePurchaseReceiptMutation, { data, loading, error }] = useCreateOrUpdatePurchaseReceiptMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdatePurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdatePurchaseReceiptMutation, CreateOrUpdatePurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdatePurchaseReceiptMutation, CreateOrUpdatePurchaseReceiptMutationVariables>(CreateOrUpdatePurchaseReceiptDocument, options);
      }
export type CreateOrUpdatePurchaseReceiptMutationHookResult = ReturnType<typeof useCreateOrUpdatePurchaseReceiptMutation>;
export type CreateOrUpdatePurchaseReceiptMutationResult = Apollo.MutationResult<CreateOrUpdatePurchaseReceiptMutation>;
export type CreateOrUpdatePurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdatePurchaseReceiptMutation, CreateOrUpdatePurchaseReceiptMutationVariables>;
export const ProcessPurchaseReceiptDocument = gql`
    mutation ProcessPurchaseReceipt($id: Int!) {
  o {
    processPurchaseReceipt(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...PurchaseReceiptFragment
      }
    }
  }
}
    ${PurchaseReceiptFragmentDoc}`;
export type ProcessPurchaseReceiptMutationFn = Apollo.MutationFunction<ProcessPurchaseReceiptMutation, ProcessPurchaseReceiptMutationVariables>;

/**
 * __useProcessPurchaseReceiptMutation__
 *
 * To run a mutation, you first call `useProcessPurchaseReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPurchaseReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPurchaseReceiptMutation, { data, loading, error }] = useProcessPurchaseReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessPurchaseReceiptMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPurchaseReceiptMutation, ProcessPurchaseReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPurchaseReceiptMutation, ProcessPurchaseReceiptMutationVariables>(ProcessPurchaseReceiptDocument, options);
      }
export type ProcessPurchaseReceiptMutationHookResult = ReturnType<typeof useProcessPurchaseReceiptMutation>;
export type ProcessPurchaseReceiptMutationResult = Apollo.MutationResult<ProcessPurchaseReceiptMutation>;
export type ProcessPurchaseReceiptMutationOptions = Apollo.BaseMutationOptions<ProcessPurchaseReceiptMutation, ProcessPurchaseReceiptMutationVariables>;
export const PurchaseReceiptListDocument = gql`
    query PurchaseReceiptList($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $status: OrderStatus, $distributorCd: String, $vendorCd: String, $employeeId: Int, $purchaseReceiptCd: String) {
  o {
    purchaseReceiptList(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      status: $status
      vendorCd: $vendorCd
      distributorCd: $distributorCd
      employeeId: $employeeId
      purchaseReceiptCd: $purchaseReceiptCd
    ) {
      total
      data {
        id
        cd
        orderDate
        orderType
        orderStatus
        orderTotal
        description
        externalReference
        vendor {
          id
          cd
          name
        }
        creator {
          id
          cd
          name
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseReceiptListQuery__
 *
 * To run a query within a React component, call `usePurchaseReceiptListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReceiptListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReceiptListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      distributorCd: // value for 'distributorCd'
 *      vendorCd: // value for 'vendorCd'
 *      employeeId: // value for 'employeeId'
 *      purchaseReceiptCd: // value for 'purchaseReceiptCd'
 *   },
 * });
 */
export function usePurchaseReceiptListQuery(baseOptions: Apollo.QueryHookOptions<PurchaseReceiptListQuery, PurchaseReceiptListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseReceiptListQuery, PurchaseReceiptListQueryVariables>(PurchaseReceiptListDocument, options);
      }
export function usePurchaseReceiptListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseReceiptListQuery, PurchaseReceiptListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseReceiptListQuery, PurchaseReceiptListQueryVariables>(PurchaseReceiptListDocument, options);
        }
export type PurchaseReceiptListQueryHookResult = ReturnType<typeof usePurchaseReceiptListQuery>;
export type PurchaseReceiptListLazyQueryHookResult = ReturnType<typeof usePurchaseReceiptListLazyQuery>;
export type PurchaseReceiptListQueryResult = Apollo.QueryResult<PurchaseReceiptListQuery, PurchaseReceiptListQueryVariables>;
export const PurchaseReceiptProcessListDocument = gql`
    query purchaseReceiptProcessList($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $action: ActionType!, $distributorCd: String, $vendorCd: String, $employeeId: Int, $purchaseReceiptCd: String) {
  o {
    purchaseReceiptProcessList(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      action: $action
      vendorCd: $vendorCd
      distributorCd: $distributorCd
      employeeId: $employeeId
      purchaseReceiptCd: $purchaseReceiptCd
    ) {
      total
      data {
        id
        cd
        orderDate
        orderType
        orderStatus
        orderTotal
        description
        vendor {
          id
          cd
          name
        }
        creator {
          id
          cd
          name
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseReceiptProcessListQuery__
 *
 * To run a query within a React component, call `usePurchaseReceiptProcessListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReceiptProcessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReceiptProcessListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      action: // value for 'action'
 *      distributorCd: // value for 'distributorCd'
 *      vendorCd: // value for 'vendorCd'
 *      employeeId: // value for 'employeeId'
 *      purchaseReceiptCd: // value for 'purchaseReceiptCd'
 *   },
 * });
 */
export function usePurchaseReceiptProcessListQuery(baseOptions: Apollo.QueryHookOptions<PurchaseReceiptProcessListQuery, PurchaseReceiptProcessListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseReceiptProcessListQuery, PurchaseReceiptProcessListQueryVariables>(PurchaseReceiptProcessListDocument, options);
      }
export function usePurchaseReceiptProcessListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseReceiptProcessListQuery, PurchaseReceiptProcessListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseReceiptProcessListQuery, PurchaseReceiptProcessListQueryVariables>(PurchaseReceiptProcessListDocument, options);
        }
export type PurchaseReceiptProcessListQueryHookResult = ReturnType<typeof usePurchaseReceiptProcessListQuery>;
export type PurchaseReceiptProcessListLazyQueryHookResult = ReturnType<typeof usePurchaseReceiptProcessListLazyQuery>;
export type PurchaseReceiptProcessListQueryResult = Apollo.QueryResult<PurchaseReceiptProcessListQuery, PurchaseReceiptProcessListQueryVariables>;
export const PurchaseReceiptDocument = gql`
    query PurchaseReceipt($id: Int!) {
  o {
    purchaseReceipt(id: $id) {
      ...PurchaseReceiptFragment
    }
  }
}
    ${PurchaseReceiptFragmentDoc}`;

/**
 * __usePurchaseReceiptQuery__
 *
 * To run a query within a React component, call `usePurchaseReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePurchaseReceiptQuery(baseOptions: Apollo.QueryHookOptions<PurchaseReceiptQuery, PurchaseReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseReceiptQuery, PurchaseReceiptQueryVariables>(PurchaseReceiptDocument, options);
      }
export function usePurchaseReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseReceiptQuery, PurchaseReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseReceiptQuery, PurchaseReceiptQueryVariables>(PurchaseReceiptDocument, options);
        }
export type PurchaseReceiptQueryHookResult = ReturnType<typeof usePurchaseReceiptQuery>;
export type PurchaseReceiptLazyQueryHookResult = ReturnType<typeof usePurchaseReceiptLazyQuery>;
export type PurchaseReceiptQueryResult = Apollo.QueryResult<PurchaseReceiptQuery, PurchaseReceiptQueryVariables>;
export const PurchaseReceiptTrackLotDocument = gql`
    mutation PurchaseReceiptTrackLot($data: [PurchaseReceiptItemInput!]!) {
  o {
    purchaseReceiptTrackLot(data: $data) {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      warehouseId
      productId
      warehouse {
        id
        name
      }
      product {
        id
        cd
        name
        tax
      }
    }
  }
}
    `;
export type PurchaseReceiptTrackLotMutationFn = Apollo.MutationFunction<PurchaseReceiptTrackLotMutation, PurchaseReceiptTrackLotMutationVariables>;

/**
 * __usePurchaseReceiptTrackLotMutation__
 *
 * To run a mutation, you first call `usePurchaseReceiptTrackLotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReceiptTrackLotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseReceiptTrackLotMutation, { data, loading, error }] = usePurchaseReceiptTrackLotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePurchaseReceiptTrackLotMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseReceiptTrackLotMutation, PurchaseReceiptTrackLotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseReceiptTrackLotMutation, PurchaseReceiptTrackLotMutationVariables>(PurchaseReceiptTrackLotDocument, options);
      }
export type PurchaseReceiptTrackLotMutationHookResult = ReturnType<typeof usePurchaseReceiptTrackLotMutation>;
export type PurchaseReceiptTrackLotMutationResult = Apollo.MutationResult<PurchaseReceiptTrackLotMutation>;
export type PurchaseReceiptTrackLotMutationOptions = Apollo.BaseMutationOptions<PurchaseReceiptTrackLotMutation, PurchaseReceiptTrackLotMutationVariables>;
export const SellersDistributorListDocument = gql`
    query SellersDistributorList($distributorId: Int!, $filter: String) {
  sellersDistributorList(distributorId: $distributorId, filter: $filter) {
    total
    data {
      id
      cd
      name
    }
  }
}
    `;

/**
 * __useSellersDistributorListQuery__
 *
 * To run a query within a React component, call `useSellersDistributorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersDistributorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersDistributorListQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellersDistributorListQuery(baseOptions: Apollo.QueryHookOptions<SellersDistributorListQuery, SellersDistributorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellersDistributorListQuery, SellersDistributorListQueryVariables>(SellersDistributorListDocument, options);
      }
export function useSellersDistributorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellersDistributorListQuery, SellersDistributorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellersDistributorListQuery, SellersDistributorListQueryVariables>(SellersDistributorListDocument, options);
        }
export type SellersDistributorListQueryHookResult = ReturnType<typeof useSellersDistributorListQuery>;
export type SellersDistributorListLazyQueryHookResult = ReturnType<typeof useSellersDistributorListLazyQuery>;
export type SellersDistributorListQueryResult = Apollo.QueryResult<SellersDistributorListQuery, SellersDistributorListQueryVariables>;
export const ReportQualityOutletDocument = gql`
    query ReportQualityOutlet($startDate: Date, $endDate: Date, $salesmanId: Int, $attrDetailId: Int, $page: Int!, $take: Int!) {
  rp {
    reportQualityOutlet(
      startDate: $startDate
      endDate: $endDate
      salesmanId: $salesmanId
      attrDetailId: $attrDetailId
      page: $page
      take: $take
    ) {
      total
      data {
        customerId
        salesmanId
        customer {
          cd
          name
          contactName1
          phone11
          address1
          customerClass {
            cd
            name
          }
          customerPriceClass {
            cd
            name
          }
          address1
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
          attrDetailIds
        }
        salesman {
          cd
          name
          supervisor {
            cd
            name
          }
          workingSalesRegions {
            salesRegion {
              cd
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useReportQualityOutletQuery__
 *
 * To run a query within a React component, call `useReportQualityOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQualityOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQualityOutletQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesmanId: // value for 'salesmanId'
 *      attrDetailId: // value for 'attrDetailId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportQualityOutletQuery(baseOptions: Apollo.QueryHookOptions<ReportQualityOutletQuery, ReportQualityOutletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQualityOutletQuery, ReportQualityOutletQueryVariables>(ReportQualityOutletDocument, options);
      }
export function useReportQualityOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQualityOutletQuery, ReportQualityOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQualityOutletQuery, ReportQualityOutletQueryVariables>(ReportQualityOutletDocument, options);
        }
export type ReportQualityOutletQueryHookResult = ReturnType<typeof useReportQualityOutletQuery>;
export type ReportQualityOutletLazyQueryHookResult = ReturnType<typeof useReportQualityOutletLazyQuery>;
export type ReportQualityOutletQueryResult = Apollo.QueryResult<ReportQualityOutletQuery, ReportQualityOutletQueryVariables>;
export const DistrictsDocument = gql`
    query Districts($provinceId: Int!) {
  md {
    districts(provinceId: $provinceId) {
      id
      name
    }
  }
}
    `;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      provinceId: // value for 'provinceId'
 *   },
 * });
 */
export function useDistrictsQuery(baseOptions: Apollo.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
      }
export function useDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
        }
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = Apollo.QueryResult<DistrictsQuery, DistrictsQueryVariables>;
export const ProvincesDocument = gql`
    query Provinces {
  md {
    provinces {
      id
      name
    }
  }
}
    `;

/**
 * __useProvincesQuery__
 *
 * To run a query within a React component, call `useProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvincesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvincesQuery(baseOptions?: Apollo.QueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, options);
      }
export function useProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, options);
        }
export type ProvincesQueryHookResult = ReturnType<typeof useProvincesQuery>;
export type ProvincesLazyQueryHookResult = ReturnType<typeof useProvincesLazyQuery>;
export type ProvincesQueryResult = Apollo.QueryResult<ProvincesQuery, ProvincesQueryVariables>;
export const WardsDocument = gql`
    query Wards($districtId: Int!) {
  md {
    wards(districtId: $districtId) {
      id
      name
    }
  }
}
    `;

/**
 * __useWardsQuery__
 *
 * To run a query within a React component, call `useWardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useWardsQuery(baseOptions: Apollo.QueryHookOptions<WardsQuery, WardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardsQuery, WardsQueryVariables>(WardsDocument, options);
      }
export function useWardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardsQuery, WardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardsQuery, WardsQueryVariables>(WardsDocument, options);
        }
export type WardsQueryHookResult = ReturnType<typeof useWardsQuery>;
export type WardsLazyQueryHookResult = ReturnType<typeof useWardsLazyQuery>;
export type WardsQueryResult = Apollo.QueryResult<WardsQuery, WardsQueryVariables>;
export const ReportPromotionSummarizingMoneyDocument = gql`
    query ReportPromotionSummarizingMoney($discountId: Int, $distributorId: Int, $page: Int!, $take: Int!, $salesRegionId: Int) {
  rp {
    reportPromotionSummarizingMoney(
      discountId: $discountId
      distributorId: $distributorId
      page: $page
      take: $take
      salesRegionId: $salesRegionId
    ) {
      total
      data {
        distributorId
        distributor {
          cd
          name
        }
        customerId
        customer {
          cd
          name
          address1
          ward {
            name
            district {
              name
              province {
                name
              }
            }
          }
          attrDetailIds
        }
        discountId
        discount {
          cd
          description
        }
        amountOrder
        amount
        amountPercentage
        amountProduct
        amountDiscount
      }
    }
  }
}
    `;

/**
 * __useReportPromotionSummarizingMoneyQuery__
 *
 * To run a query within a React component, call `useReportPromotionSummarizingMoneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPromotionSummarizingMoneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPromotionSummarizingMoneyQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useReportPromotionSummarizingMoneyQuery(baseOptions: Apollo.QueryHookOptions<ReportPromotionSummarizingMoneyQuery, ReportPromotionSummarizingMoneyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportPromotionSummarizingMoneyQuery, ReportPromotionSummarizingMoneyQueryVariables>(ReportPromotionSummarizingMoneyDocument, options);
      }
export function useReportPromotionSummarizingMoneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportPromotionSummarizingMoneyQuery, ReportPromotionSummarizingMoneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportPromotionSummarizingMoneyQuery, ReportPromotionSummarizingMoneyQueryVariables>(ReportPromotionSummarizingMoneyDocument, options);
        }
export type ReportPromotionSummarizingMoneyQueryHookResult = ReturnType<typeof useReportPromotionSummarizingMoneyQuery>;
export type ReportPromotionSummarizingMoneyLazyQueryHookResult = ReturnType<typeof useReportPromotionSummarizingMoneyLazyQuery>;
export type ReportPromotionSummarizingMoneyQueryResult = Apollo.QueryResult<ReportPromotionSummarizingMoneyQuery, ReportPromotionSummarizingMoneyQueryVariables>;
export const ReportPromotionSummarizingProductDocument = gql`
    query ReportPromotionSummarizingProduct($discountId: Int, $distributorId: Int, $page: Int!, $take: Int!, $salesRegionId: Int, $productId: Int) {
  rp {
    reportPromotionSummarizingProduct(
      discountId: $discountId
      distributorId: $distributorId
      page: $page
      take: $take
      salesRegionId: $salesRegionId
      productId: $productId
    ) {
      total
      data {
        distributorId
        distributor {
          cd
          name
        }
        customerId
        customer {
          cd
          name
          address1
          ward {
            name
            district {
              name
              province {
                name
              }
            }
          }
          attrDetailIds
        }
        discountId
        discount {
          cd
          description
        }
        amountOrder
        salesOrderFreeItems {
          id
          qty
          productId
          product {
            id
            cd
            name
            description
            basePrice
          }
        }
        amountProduct
      }
    }
  }
}
    `;

/**
 * __useReportPromotionSummarizingProductQuery__
 *
 * To run a query within a React component, call `useReportPromotionSummarizingProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPromotionSummarizingProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPromotionSummarizingProductQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      salesRegionId: // value for 'salesRegionId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useReportPromotionSummarizingProductQuery(baseOptions: Apollo.QueryHookOptions<ReportPromotionSummarizingProductQuery, ReportPromotionSummarizingProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportPromotionSummarizingProductQuery, ReportPromotionSummarizingProductQueryVariables>(ReportPromotionSummarizingProductDocument, options);
      }
export function useReportPromotionSummarizingProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportPromotionSummarizingProductQuery, ReportPromotionSummarizingProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportPromotionSummarizingProductQuery, ReportPromotionSummarizingProductQueryVariables>(ReportPromotionSummarizingProductDocument, options);
        }
export type ReportPromotionSummarizingProductQueryHookResult = ReturnType<typeof useReportPromotionSummarizingProductQuery>;
export type ReportPromotionSummarizingProductLazyQueryHookResult = ReturnType<typeof useReportPromotionSummarizingProductLazyQuery>;
export type ReportPromotionSummarizingProductQueryResult = Apollo.QueryResult<ReportPromotionSummarizingProductQuery, ReportPromotionSummarizingProductQueryVariables>;
export const ChangeReportConfigDocument = gql`
    mutation ChangeReportConfig($data: [InputReportDataConfig!]!) {
  o {
    changeReportConfig(data: $data) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type ChangeReportConfigMutationFn = Apollo.MutationFunction<ChangeReportConfigMutation, ChangeReportConfigMutationVariables>;

/**
 * __useChangeReportConfigMutation__
 *
 * To run a mutation, you first call `useChangeReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReportConfigMutation, { data, loading, error }] = useChangeReportConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeReportConfigMutation(baseOptions?: Apollo.MutationHookOptions<ChangeReportConfigMutation, ChangeReportConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeReportConfigMutation, ChangeReportConfigMutationVariables>(ChangeReportConfigDocument, options);
      }
export type ChangeReportConfigMutationHookResult = ReturnType<typeof useChangeReportConfigMutation>;
export type ChangeReportConfigMutationResult = Apollo.MutationResult<ChangeReportConfigMutation>;
export type ChangeReportConfigMutationOptions = Apollo.BaseMutationOptions<ChangeReportConfigMutation, ChangeReportConfigMutationVariables>;
export const CurrentWarehouseProductsDocument = gql`
    query CurrentWarehouseProducts($distributorId: Int, $warehouseId: Int, $productId: Int) {
  rp {
    currentWarehouseProducts(
      distributorId: $distributorId
      warehouseId: $warehouseId
      productId: $productId
    ) {
      id
      qty
      availableQty
      baseUnitId
      lotNbr
      startDate
      endDate
      product {
        cd
        id
        name
        purchaseUnitId
        productMeasureUnits {
          id
          fromUnitId
          toUnitId
          rate
          saleable
        }
      }
      warehouse {
        id
        name
        distributor {
          cd
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCurrentWarehouseProductsQuery__
 *
 * To run a query within a React component, call `useCurrentWarehouseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWarehouseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWarehouseProductsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCurrentWarehouseProductsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentWarehouseProductsQuery, CurrentWarehouseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentWarehouseProductsQuery, CurrentWarehouseProductsQueryVariables>(CurrentWarehouseProductsDocument, options);
      }
export function useCurrentWarehouseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentWarehouseProductsQuery, CurrentWarehouseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentWarehouseProductsQuery, CurrentWarehouseProductsQueryVariables>(CurrentWarehouseProductsDocument, options);
        }
export type CurrentWarehouseProductsQueryHookResult = ReturnType<typeof useCurrentWarehouseProductsQuery>;
export type CurrentWarehouseProductsLazyQueryHookResult = ReturnType<typeof useCurrentWarehouseProductsLazyQuery>;
export type CurrentWarehouseProductsQueryResult = Apollo.QueryResult<CurrentWarehouseProductsQuery, CurrentWarehouseProductsQueryVariables>;
export const CustomerSurveyReportDocument = gql`
    query CustomerSurveyReport($filter: String, $surveyId: Int, $page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $status: ProgramStatus) {
  rp {
    customerSurveyReport(
      filter: $filter
      surveyId: $surveyId
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      total
      data {
        survey {
          cd
          title
          description
          status
          startDate
          endDate
          multiple
          surveyApplicableSalesRegions {
            salesRegion {
              cd
              name
            }
          }
          surveyApplicableCustomerAttrs {
            attrId
            attrDetailId
          }
        }
        customer {
          cd
          name
        }
        customerSurveyDetails {
          surveyQuestion {
            title
          }
          surveyAnswer {
            title
          }
          textAnswer
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerSurveyReportQuery__
 *
 * To run a query within a React component, call `useCustomerSurveyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSurveyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSurveyReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      surveyId: // value for 'surveyId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCustomerSurveyReportQuery(baseOptions: Apollo.QueryHookOptions<CustomerSurveyReportQuery, CustomerSurveyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSurveyReportQuery, CustomerSurveyReportQueryVariables>(CustomerSurveyReportDocument, options);
      }
export function useCustomerSurveyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSurveyReportQuery, CustomerSurveyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSurveyReportQuery, CustomerSurveyReportQueryVariables>(CustomerSurveyReportDocument, options);
        }
export type CustomerSurveyReportQueryHookResult = ReturnType<typeof useCustomerSurveyReportQuery>;
export type CustomerSurveyReportLazyQueryHookResult = ReturnType<typeof useCustomerSurveyReportLazyQuery>;
export type CustomerSurveyReportQueryResult = Apollo.QueryResult<CustomerSurveyReportQuery, CustomerSurveyReportQueryVariables>;
export const FilterKpiConfigReportDocument = gql`
    query FilterKpiConfigReport($target: KpiApplyTarget, $filter: String, $page: Int!, $take: Int!) {
  rp {
    filterKpiConfigReport(
      target: $target
      filter: $filter
      page: $page
      take: $take
    ) {
      total
      data {
        id
        cd
        name
        description
      }
    }
  }
}
    `;

/**
 * __useFilterKpiConfigReportQuery__
 *
 * To run a query within a React component, call `useFilterKpiConfigReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterKpiConfigReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterKpiConfigReportQuery({
 *   variables: {
 *      target: // value for 'target'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFilterKpiConfigReportQuery(baseOptions: Apollo.QueryHookOptions<FilterKpiConfigReportQuery, FilterKpiConfigReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterKpiConfigReportQuery, FilterKpiConfigReportQueryVariables>(FilterKpiConfigReportDocument, options);
      }
export function useFilterKpiConfigReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterKpiConfigReportQuery, FilterKpiConfigReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterKpiConfigReportQuery, FilterKpiConfigReportQueryVariables>(FilterKpiConfigReportDocument, options);
        }
export type FilterKpiConfigReportQueryHookResult = ReturnType<typeof useFilterKpiConfigReportQuery>;
export type FilterKpiConfigReportLazyQueryHookResult = ReturnType<typeof useFilterKpiConfigReportLazyQuery>;
export type FilterKpiConfigReportQueryResult = Apollo.QueryResult<FilterKpiConfigReportQuery, FilterKpiConfigReportQueryVariables>;
export const FollowLoyaltyRegistrationDocument = gql`
    query FollowLoyaltyRegistration($startDate: Date, $endDate: Date, $startDateTrade: Date, $endDateTrade: Date, $statusTrade: ProgramStatus, $tradeId: Int, $filter: String, $status: TradeParticipationStatus, $page: Int!, $take: Int!) {
  rp {
    followLoyaltyRegistration(
      startDate: $startDate
      endDate: $endDate
      startDateTrade: $startDateTrade
      endDateTrade: $endDateTrade
      statusTrade: $statusTrade
      tradeId: $tradeId
      filter: $filter
      status: $status
      page: $page
      take: $take
    ) {
      total
      data {
        tradeId
        customerId
        trade {
          cd
          description
          status
          registerStartDate
          registerEndDate
          startDate
          endDate
        }
        customer {
          id
          cd
          name
        }
        level
        status
        result
        incentiveResult
        lastCalculatedDate
      }
    }
  }
}
    `;

/**
 * __useFollowLoyaltyRegistrationQuery__
 *
 * To run a query within a React component, call `useFollowLoyaltyRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowLoyaltyRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowLoyaltyRegistrationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDateTrade: // value for 'startDateTrade'
 *      endDateTrade: // value for 'endDateTrade'
 *      statusTrade: // value for 'statusTrade'
 *      tradeId: // value for 'tradeId'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFollowLoyaltyRegistrationQuery(baseOptions: Apollo.QueryHookOptions<FollowLoyaltyRegistrationQuery, FollowLoyaltyRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowLoyaltyRegistrationQuery, FollowLoyaltyRegistrationQueryVariables>(FollowLoyaltyRegistrationDocument, options);
      }
export function useFollowLoyaltyRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowLoyaltyRegistrationQuery, FollowLoyaltyRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowLoyaltyRegistrationQuery, FollowLoyaltyRegistrationQueryVariables>(FollowLoyaltyRegistrationDocument, options);
        }
export type FollowLoyaltyRegistrationQueryHookResult = ReturnType<typeof useFollowLoyaltyRegistrationQuery>;
export type FollowLoyaltyRegistrationLazyQueryHookResult = ReturnType<typeof useFollowLoyaltyRegistrationLazyQuery>;
export type FollowLoyaltyRegistrationQueryResult = Apollo.QueryResult<FollowLoyaltyRegistrationQuery, FollowLoyaltyRegistrationQueryVariables>;
export const ReportProcedureDocument = gql`
    query ReportProcedure {
  rp {
    reportProcedure {
      name
    }
  }
}
    `;

/**
 * __useReportProcedureQuery__
 *
 * To run a query within a React component, call `useReportProcedureQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportProcedureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportProcedureQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportProcedureQuery(baseOptions?: Apollo.QueryHookOptions<ReportProcedureQuery, ReportProcedureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportProcedureQuery, ReportProcedureQueryVariables>(ReportProcedureDocument, options);
      }
export function useReportProcedureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportProcedureQuery, ReportProcedureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportProcedureQuery, ReportProcedureQueryVariables>(ReportProcedureDocument, options);
        }
export type ReportProcedureQueryHookResult = ReturnType<typeof useReportProcedureQuery>;
export type ReportProcedureLazyQueryHookResult = ReturnType<typeof useReportProcedureLazyQuery>;
export type ReportProcedureQueryResult = Apollo.QueryResult<ReportProcedureQuery, ReportProcedureQueryVariables>;
export const ReportDataConfigDocument = gql`
    query ReportDataConfig {
  rp {
    reportDataConfig {
      id
      name
      procedureName
      isWeb
      isMobile
      isActive
      isAlive
    }
  }
}
    `;

/**
 * __useReportDataConfigQuery__
 *
 * To run a query within a React component, call `useReportDataConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDataConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDataConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportDataConfigQuery(baseOptions?: Apollo.QueryHookOptions<ReportDataConfigQuery, ReportDataConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportDataConfigQuery, ReportDataConfigQueryVariables>(ReportDataConfigDocument, options);
      }
export function useReportDataConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportDataConfigQuery, ReportDataConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportDataConfigQuery, ReportDataConfigQueryVariables>(ReportDataConfigDocument, options);
        }
export type ReportDataConfigQueryHookResult = ReturnType<typeof useReportDataConfigQuery>;
export type ReportDataConfigLazyQueryHookResult = ReturnType<typeof useReportDataConfigLazyQuery>;
export type ReportDataConfigQueryResult = Apollo.QueryResult<ReportDataConfigQuery, ReportDataConfigQueryVariables>;
export const ProcedureDataDocument = gql`
    query ProcedureData($procedureName: String!) {
  rp {
    procedureData(procedureName: $procedureName) {
      name
      data
    }
  }
}
    `;

/**
 * __useProcedureDataQuery__
 *
 * To run a query within a React component, call `useProcedureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcedureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcedureDataQuery({
 *   variables: {
 *      procedureName: // value for 'procedureName'
 *   },
 * });
 */
export function useProcedureDataQuery(baseOptions: Apollo.QueryHookOptions<ProcedureDataQuery, ProcedureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcedureDataQuery, ProcedureDataQueryVariables>(ProcedureDataDocument, options);
      }
export function useProcedureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcedureDataQuery, ProcedureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcedureDataQuery, ProcedureDataQueryVariables>(ProcedureDataDocument, options);
        }
export type ProcedureDataQueryHookResult = ReturnType<typeof useProcedureDataQuery>;
export type ProcedureDataLazyQueryHookResult = ReturnType<typeof useProcedureDataLazyQuery>;
export type ProcedureDataQueryResult = Apollo.QueryResult<ProcedureDataQuery, ProcedureDataQueryVariables>;
export const MasterDataCustomersDocument = gql`
    query MasterDataCustomers($page: Int!, $take: Int!, $filter: String, $customerClassId: Int,$provinceId: Int, $customerStatus : CustomerStatus, $salesRegionId: Int, 
    $distributorId: Int, $creatorCd: String, $createdDate: Date, $sourceCustomer: CustomerSource) {
  rp {
    masterDataCustomers(
      customerStatus: $customerStatus
      filter: $filter
      page: $page
      take: $take
      customerClassId: $customerClassId
      salesRegionId: $salesRegionId
      distributorId: $distributorId
      creatorCd: $creatorCd
      createdDate: $createdDate
      sourceCustomer: $sourceCustomer
      provinceId : $provinceId
    ) {
      total
      data {
        id
        cd
        name
        customerStatus
        customerClassId
        customerPriceClassId
        lat
        lng
        contactName1
        phone11
        phone12
        isDistributor
        address1
        photo {
          id
          url
        }
        customerClass {
          id
          cd
          name
        }
        customerDistributors {
          id
          distributor {
            id
            name
            cd
          }
        }
        customerPriceClass {
          name
        }
        ward {
          id
          name
          district {
            id
            name
            province {
              id
              name
            }
          }
        }
        customerDistributors {
          id
          distributor {
            cd
            name
          }
        }
        attrDetailIds
        createdAt
        modifiedAt
      }
    }
  }
}
    `;

/**
 * __useMasterDataCustomersQuery__
 *
 * To run a query within a React component, call `useMasterDataCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterDataCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterDataCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      customerClassId: // value for 'customerClassId'
 *      salesRegionId: // value for 'salesRegionId'
 *      distributorId: // value for 'distributorId'
 *      creatorCd: // value for 'creatorCd'
 *      createdDate: // value for 'createdDate'
 *      sourceCustomer: // value for 'sourceCustomer'
 *   },
 * });
 */
export function useMasterDataCustomersQuery(baseOptions: Apollo.QueryHookOptions<MasterDataCustomersQuery, MasterDataCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterDataCustomersQuery, MasterDataCustomersQueryVariables>(MasterDataCustomersDocument, options);
      }
export function useMasterDataCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterDataCustomersQuery, MasterDataCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterDataCustomersQuery, MasterDataCustomersQueryVariables>(MasterDataCustomersDocument, options);
        }
export type MasterDataCustomersQueryHookResult = ReturnType<typeof useMasterDataCustomersQuery>;
export type MasterDataCustomersLazyQueryHookResult = ReturnType<typeof useMasterDataCustomersLazyQuery>;
export type MasterDataCustomersQueryResult = Apollo.QueryResult<MasterDataCustomersQuery, MasterDataCustomersQueryVariables>;
export const MasterDataDistributorsDocument = gql`
    query MasterDataDistributors($filter: String, $status: Boolean, $distributorTypeId: Int) {
  rp {
    masterDataDistributors(
      filter: $filter
      status: $status
      distributorTypeId: $distributorTypeId
    ) {
      id
      cd
      name
      contactName1
      phone11
      address1
      isActive
      startDate
      endDate
      email
      ward {
        id
        name
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
      creator {
        id
        cd
        name
      }
      customer {
        cd
        name
      }
      distributorType {
        name
      }
      warehouses {
        name
      }
      buyers {
        buyer {
          cd
          name
        }
      }
      sellers {
        seller {
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMasterDataDistributorsQuery__
 *
 * To run a query within a React component, call `useMasterDataDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterDataDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterDataDistributorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      distributorTypeId: // value for 'distributorTypeId'
 *   },
 * });
 */
export function useMasterDataDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<MasterDataDistributorsQuery, MasterDataDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterDataDistributorsQuery, MasterDataDistributorsQueryVariables>(MasterDataDistributorsDocument, options);
      }
export function useMasterDataDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterDataDistributorsQuery, MasterDataDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterDataDistributorsQuery, MasterDataDistributorsQueryVariables>(MasterDataDistributorsDocument, options);
        }
export type MasterDataDistributorsQueryHookResult = ReturnType<typeof useMasterDataDistributorsQuery>;
export type MasterDataDistributorsLazyQueryHookResult = ReturnType<typeof useMasterDataDistributorsLazyQuery>;
export type MasterDataDistributorsQueryResult = Apollo.QueryResult<MasterDataDistributorsQuery, MasterDataDistributorsQueryVariables>;
export const MasterDataEmployeesDocument = gql`
    query MasterDataEmployees($filter: String, $salesRegionId: Int, $distributorId: Int, $createdDate: Date, $positionId: Int) {
  rp {
    masterDataEmployees(
      filter: $filter
      salesRegionId: $salesRegionId
      distributorId: $distributorId
      createdDate: $createdDate
      positionId: $positionId
    ) {
      id
      name
      cd
      username
      requiredPasswordChanged
      email
      phone11
      citizenIdentityCard
      address1
      isActive
      startDate
      endDate
      ward {
        id
        name
        district {
          id
          name
          province {
            id
            name
          }
        }
      }
      supervisor {
        cd
        name
        employeePositions {
          position {
            name
          }
        }
      }
      employeePositions {
        position {
          name
        }
      }
      workingDistributors {
        distributor {
          cd
          name
        }
      }
      workingSalesRegions {
        salesRegion {
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMasterDataEmployeesQuery__
 *
 * To run a query within a React component, call `useMasterDataEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterDataEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterDataEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      salesRegionId: // value for 'salesRegionId'
 *      distributorId: // value for 'distributorId'
 *      createdDate: // value for 'createdDate'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useMasterDataEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<MasterDataEmployeesQuery, MasterDataEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterDataEmployeesQuery, MasterDataEmployeesQueryVariables>(MasterDataEmployeesDocument, options);
      }
export function useMasterDataEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterDataEmployeesQuery, MasterDataEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterDataEmployeesQuery, MasterDataEmployeesQueryVariables>(MasterDataEmployeesDocument, options);
        }
export type MasterDataEmployeesQueryHookResult = ReturnType<typeof useMasterDataEmployeesQuery>;
export type MasterDataEmployeesLazyQueryHookResult = ReturnType<typeof useMasterDataEmployeesLazyQuery>;
export type MasterDataEmployeesQueryResult = Apollo.QueryResult<MasterDataEmployeesQuery, MasterDataEmployeesQueryVariables>;
export const MasterDataProductsDocument = gql`
    query MasterDataProducts($filter: String) {
  rp {
    masterDataProducts(filter: $filter) {
      id
      cd
      name
      description
      isPop
      isActive
      basePrice
      baseUnitId
      salesUnitId
      purchaseUnitId
      productMeasureUnits {
        fromUnitId
        rate
        toUnitId
      }
      productAttrDetails {
        attrId
        attrDetailId
      }
    }
  }
}
    `;

/**
 * __useMasterDataProductsQuery__
 *
 * To run a query within a React component, call `useMasterDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterDataProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMasterDataProductsQuery(baseOptions?: Apollo.QueryHookOptions<MasterDataProductsQuery, MasterDataProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterDataProductsQuery, MasterDataProductsQueryVariables>(MasterDataProductsDocument, options);
      }
export function useMasterDataProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterDataProductsQuery, MasterDataProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterDataProductsQuery, MasterDataProductsQueryVariables>(MasterDataProductsDocument, options);
        }
export type MasterDataProductsQueryHookResult = ReturnType<typeof useMasterDataProductsQuery>;
export type MasterDataProductsLazyQueryHookResult = ReturnType<typeof useMasterDataProductsLazyQuery>;
export type MasterDataProductsQueryResult = Apollo.QueryResult<MasterDataProductsQuery, MasterDataProductsQueryVariables>;
export const McpLineReportDocument = gql`
    query McpLineReport($startDate: Date, $endDate: Date, $customerFilter: String, $page: Int!, $take: Int!, $mcpId: Int, $salesmanId: Int, $leaderId: Int, $status: ProgramStatus) {
  rp {
    mcpLineReport(
      startDate: $startDate
      endDate: $endDate
      customerFilter: $customerFilter
      page: $page
      take: $take
      mcpId: $mcpId
      leaderId: $leaderId
      salesmanId: $salesmanId
      status: $status
    ) {
      total
      data {
        id
        mcp {
          id
          startDate
          endDate
          cd
          status
          salesRegion {
            cd
            name
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
          }
        }
        customerId
        customer {
          cd
          name
          address1
          contactName1
          phone11
          lat
          lng
          attrs {
            cd
            attrDetails {
              value
            }
          }
          attrDetails {
            value
          }
          ward {
            name
            district {
              name
              province {
                name
              }
            }
          }
        }
        distributors {
          id
          cd
          name
        }
        mcpDistributors {
          id
          distributor {
            id
            cd
            name
          }
        }
        addedAt
        weekdays
        weeksOfMonth
      }
    }
  }
}
    `;

/**
 * __useMcpLineReportQuery__
 *
 * To run a query within a React component, call `useMcpLineReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpLineReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpLineReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerFilter: // value for 'customerFilter'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      mcpId: // value for 'mcpId'
 *      salesmanId: // value for 'salesmanId'
 *      leaderId: // value for 'leaderId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMcpLineReportQuery(baseOptions: Apollo.QueryHookOptions<McpLineReportQuery, McpLineReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<McpLineReportQuery, McpLineReportQueryVariables>(McpLineReportDocument, options);
      }
export function useMcpLineReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<McpLineReportQuery, McpLineReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<McpLineReportQuery, McpLineReportQueryVariables>(McpLineReportDocument, options);
        }
export type McpLineReportQueryHookResult = ReturnType<typeof useMcpLineReportQuery>;
export type McpLineReportLazyQueryHookResult = ReturnType<typeof useMcpLineReportLazyQuery>;
export type McpLineReportQueryResult = Apollo.QueryResult<McpLineReportQuery, McpLineReportQueryVariables>;
export const PendingWarehouseProductsDocument = gql`
    query PendingWarehouseProducts($source: TransactionSource, $distributorId: Int, $warehouseId: Int, $productId: Int) {
  rp {
    pendingWarehouseProducts(
      source: $source
      distributorId: $distributorId
      warehouseId: $warehouseId
      productId: $productId
    ) {
      id
      type
      qty
      unitId
      unitPrice
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      product {
        id
        cd
        name
      }
      warehouse {
        id
        name
        distributor {
          id
          name
        }
      }
      transaction {
        id
        status
        source
        salesOrderId
        purchaseReceiptId
      }
    }
  }
}
    `;

/**
 * __usePendingWarehouseProductsQuery__
 *
 * To run a query within a React component, call `usePendingWarehouseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingWarehouseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingWarehouseProductsQuery({
 *   variables: {
 *      source: // value for 'source'
 *      distributorId: // value for 'distributorId'
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function usePendingWarehouseProductsQuery(baseOptions?: Apollo.QueryHookOptions<PendingWarehouseProductsQuery, PendingWarehouseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingWarehouseProductsQuery, PendingWarehouseProductsQueryVariables>(PendingWarehouseProductsDocument, options);
      }
export function usePendingWarehouseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingWarehouseProductsQuery, PendingWarehouseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingWarehouseProductsQuery, PendingWarehouseProductsQueryVariables>(PendingWarehouseProductsDocument, options);
        }
export type PendingWarehouseProductsQueryHookResult = ReturnType<typeof usePendingWarehouseProductsQuery>;
export type PendingWarehouseProductsLazyQueryHookResult = ReturnType<typeof usePendingWarehouseProductsLazyQuery>;
export type PendingWarehouseProductsQueryResult = Apollo.QueryResult<PendingWarehouseProductsQuery, PendingWarehouseProductsQueryVariables>;
export const PurchaseOrderDetailsDocument = gql`
    query purchaseOrderDetails($page: Int!, $take: Int!, $distributorId: Int, $startDate: Date, $endDate: Date, $vendorId: Int, $productId: Int, $minValue: Decimal, $maxValue: Decimal, $purchaseReceiptCd: String) {
  rp {
    purchaseOrderDetails(
      page: $page
      take: $take
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      vendorId: $vendorId
      productId: $productId
      minValue: $minValue
      maxValue: $maxValue
      purchaseReceiptCd: $purchaseReceiptCd
    ) {
      total
      data {
        purchaseReceipt {
          cd
          distributor {
            cd
            name
            contactName1
            phone11
          }
          vendor {
            cd
            name
            contactName1
            phone11
          }
          employee {
            cd
            name
          }
          orderDate
          orderType
          orderStatus
          orderTotal
          description
          externalReference
        }
        product {
          cd
          name
        }
        qty
        unitId
        unitPrice
        baseQty
        baseUnitId
        baseUnitPrice
        warehouse {
          id
          name
          distributor {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseOrderDetailsQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderDetailsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      vendorId: // value for 'vendorId'
 *      productId: // value for 'productId'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *      purchaseReceiptCd: // value for 'purchaseReceiptCd'
 *   },
 * });
 */
export function usePurchaseOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>(PurchaseOrderDetailsDocument, options);
      }
export function usePurchaseOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>(PurchaseOrderDetailsDocument, options);
        }
export type PurchaseOrderDetailsQueryHookResult = ReturnType<typeof usePurchaseOrderDetailsQuery>;
export type PurchaseOrderDetailsLazyQueryHookResult = ReturnType<typeof usePurchaseOrderDetailsLazyQuery>;
export type PurchaseOrderDetailsQueryResult = Apollo.QueryResult<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>;
export const PurchaseByProductsDocument = gql`
    query PurchaseByProducts($distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  rp {
    purchaseByProducts(
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    ) {
      product {
        id
        cd
        name
        description
        basePrice
        baseUnitId
        purchaseUnitId
      }
      totalQty
      totalUnit
      totalPrice
      warehouse {
        id
        name
        description
      }
      distributor {
        id
        cd
        name
        contactName1
        phone11
      }
    }
  }
}
    `;

/**
 * __usePurchaseByProductsQuery__
 *
 * To run a query within a React component, call `usePurchaseByProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseByProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseByProductsQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function usePurchaseByProductsQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseByProductsQuery, PurchaseByProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseByProductsQuery, PurchaseByProductsQueryVariables>(PurchaseByProductsDocument, options);
      }
export function usePurchaseByProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseByProductsQuery, PurchaseByProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseByProductsQuery, PurchaseByProductsQueryVariables>(PurchaseByProductsDocument, options);
        }
export type PurchaseByProductsQueryHookResult = ReturnType<typeof usePurchaseByProductsQuery>;
export type PurchaseByProductsLazyQueryHookResult = ReturnType<typeof usePurchaseByProductsLazyQuery>;
export type PurchaseByProductsQueryResult = Apollo.QueryResult<PurchaseByProductsQuery, PurchaseByProductsQueryVariables>;
export const ReportKpiEvaluationSalesForceDocument = gql`
    query ReportKpiEvaluationSalesForce($leaderId: Int, $employeeId: Int, $kpiName: String, $startDate: Date, $endDate: Date, $page: Int!, $take: Int!, $minResult: Decimal, $maxResult: Decimal) {
  rp {
    reportKpiEvaluationSalesForce(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      kpiName: $kpiName
      leaderId: $leaderId
      minResult: $minResult
      maxResult: $maxResult
      page: $page
      take: $take
    ) {
      total
      data {
        employee {
          cd
          name
          supervisor {
            id
            cd
            name
          }
        }
        kpiConfig {
          cd
          name
          startDate
          endDate
        }
        kpiFormula {
          cd
          name
          description
        }
        result
      }
    }
  }
}
    `;

/**
 * __useReportKpiEvaluationSalesForceQuery__
 *
 * To run a query within a React component, call `useReportKpiEvaluationSalesForceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportKpiEvaluationSalesForceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportKpiEvaluationSalesForceQuery({
 *   variables: {
 *      leaderId: // value for 'leaderId'
 *      employeeId: // value for 'employeeId'
 *      kpiName: // value for 'kpiName'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      minResult: // value for 'minResult'
 *      maxResult: // value for 'maxResult'
 *   },
 * });
 */
export function useReportKpiEvaluationSalesForceQuery(baseOptions: Apollo.QueryHookOptions<ReportKpiEvaluationSalesForceQuery, ReportKpiEvaluationSalesForceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportKpiEvaluationSalesForceQuery, ReportKpiEvaluationSalesForceQueryVariables>(ReportKpiEvaluationSalesForceDocument, options);
      }
export function useReportKpiEvaluationSalesForceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportKpiEvaluationSalesForceQuery, ReportKpiEvaluationSalesForceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportKpiEvaluationSalesForceQuery, ReportKpiEvaluationSalesForceQueryVariables>(ReportKpiEvaluationSalesForceDocument, options);
        }
export type ReportKpiEvaluationSalesForceQueryHookResult = ReturnType<typeof useReportKpiEvaluationSalesForceQuery>;
export type ReportKpiEvaluationSalesForceLazyQueryHookResult = ReturnType<typeof useReportKpiEvaluationSalesForceLazyQuery>;
export type ReportKpiEvaluationSalesForceQueryResult = Apollo.QueryResult<ReportKpiEvaluationSalesForceQuery, ReportKpiEvaluationSalesForceQueryVariables>;
export const ReportKpisDocument = gql`
    query ReportKpis($employeeId: Int, $distributorId: Int, $target: KpiApplyTarget, $kpiConfigId: Int, $page: Int!, $take: Int!) {
  rp {
    reportKpis(
      employeeId: $employeeId
      distributorId: $distributorId
      target: $target
      kpiConfigId: $kpiConfigId
      page: $page
      take: $take
    ) {
      total
      data {
        employee {
          id
          cd
          name
        }
        kpiConfig {
          id
          cd
          name
          description
          startDate
          endDate
          target
        }
        kpiFormula {
          cd
          name
          formula
        }
        distributor {
          id
          cd
          name
        }
        rate
        actual
        target
      }
    }
  }
}
    `;

/**
 * __useReportKpisQuery__
 *
 * To run a query within a React component, call `useReportKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportKpisQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      distributorId: // value for 'distributorId'
 *      target: // value for 'target'
 *      kpiConfigId: // value for 'kpiConfigId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportKpisQuery(baseOptions: Apollo.QueryHookOptions<ReportKpisQuery, ReportKpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportKpisQuery, ReportKpisQueryVariables>(ReportKpisDocument, options);
      }
export function useReportKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportKpisQuery, ReportKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportKpisQuery, ReportKpisQueryVariables>(ReportKpisDocument, options);
        }
export type ReportKpisQueryHookResult = ReturnType<typeof useReportKpisQuery>;
export type ReportKpisLazyQueryHookResult = ReturnType<typeof useReportKpisLazyQuery>;
export type ReportKpisQueryResult = Apollo.QueryResult<ReportKpisQuery, ReportKpisQueryVariables>;
export const ReportLoyaltyEvaluationDocument = gql`
    query ReportLoyaltyEvaluation($startDate: Date, $endDate: Date, $tradeId: Int, $filter: String, $tradePassed: Boolean, $breakBy: BreakBy, $totalPassed: Boolean, $visualPassed: Boolean, $page: Int!, $take: Int!) {
  rp {
    reportLoyaltyEvaluation(
      startDate: $startDate
      tradeId: $tradeId
      endDate: $endDate
      filter: $filter
      tradePassed: $tradePassed
      breakBy: $breakBy
      totalPassed: $totalPassed
      visualPassed: $visualPassed
      page: $page
      take: $take
    ) {
      total
      data {
        customerId
        tradeId
        customer {
          cd
          name
        }
        trade {
          cd
          description
          endDate
          startDate
          endDate
          breakBy
          autoLevel
        }
        level
        tradeResult
        tradeTarget
        visualResult
        tradePassed
        visualPassed
        totalPassed
      }
    }
  }
}
    `;

/**
 * __useReportLoyaltyEvaluationQuery__
 *
 * To run a query within a React component, call `useReportLoyaltyEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportLoyaltyEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportLoyaltyEvaluationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tradeId: // value for 'tradeId'
 *      filter: // value for 'filter'
 *      tradePassed: // value for 'tradePassed'
 *      breakBy: // value for 'breakBy'
 *      totalPassed: // value for 'totalPassed'
 *      visualPassed: // value for 'visualPassed'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportLoyaltyEvaluationQuery(baseOptions: Apollo.QueryHookOptions<ReportLoyaltyEvaluationQuery, ReportLoyaltyEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportLoyaltyEvaluationQuery, ReportLoyaltyEvaluationQueryVariables>(ReportLoyaltyEvaluationDocument, options);
      }
export function useReportLoyaltyEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportLoyaltyEvaluationQuery, ReportLoyaltyEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportLoyaltyEvaluationQuery, ReportLoyaltyEvaluationQueryVariables>(ReportLoyaltyEvaluationDocument, options);
        }
export type ReportLoyaltyEvaluationQueryHookResult = ReturnType<typeof useReportLoyaltyEvaluationQuery>;
export type ReportLoyaltyEvaluationLazyQueryHookResult = ReturnType<typeof useReportLoyaltyEvaluationLazyQuery>;
export type ReportLoyaltyEvaluationQueryResult = Apollo.QueryResult<ReportLoyaltyEvaluationQuery, ReportLoyaltyEvaluationQueryVariables>;
export const ReportPromotionByDistributorDocument = gql`
    query ReportPromotionByDistributor($discountId: Int, $distributorId: Int, $page: Int!, $take: Int!) {
  rp {
    reportPromotionByDistributor(
      discountId: $discountId
      distributorId: $distributorId
      page: $page
      take: $take
    ) {
      total
      data {
        discountId
        distributorId
        distributor {
          cd
          name
        }
        discount {
          cd
          description
          startDate
          endDate
        }
        total
      }
    }
  }
}
    `;

/**
 * __useReportPromotionByDistributorQuery__
 *
 * To run a query within a React component, call `useReportPromotionByDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPromotionByDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPromotionByDistributorQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      distributorId: // value for 'distributorId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportPromotionByDistributorQuery(baseOptions: Apollo.QueryHookOptions<ReportPromotionByDistributorQuery, ReportPromotionByDistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportPromotionByDistributorQuery, ReportPromotionByDistributorQueryVariables>(ReportPromotionByDistributorDocument, options);
      }
export function useReportPromotionByDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportPromotionByDistributorQuery, ReportPromotionByDistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportPromotionByDistributorQuery, ReportPromotionByDistributorQueryVariables>(ReportPromotionByDistributorDocument, options);
        }
export type ReportPromotionByDistributorQueryHookResult = ReturnType<typeof useReportPromotionByDistributorQuery>;
export type ReportPromotionByDistributorLazyQueryHookResult = ReturnType<typeof useReportPromotionByDistributorLazyQuery>;
export type ReportPromotionByDistributorQueryResult = Apollo.QueryResult<ReportPromotionByDistributorQuery, ReportPromotionByDistributorQueryVariables>;
export const ReportSalesOrderDiscountLineDocument = gql`
    query ReportSalesOrderDiscountLine($discountId: Int, $productId: Int, $distributorId: Int, $page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $customerCd: String) {
  rp {
    reportSalesOrderDiscountLine(
      startDate: $startDate
      endDate: $endDate
      customerCd: $customerCd
      discountId: $discountId
      productId: $productId
      distributorId: $distributorId
      page: $page
      take: $take
    ) {
      total
      data {
        salesOrderItem {
          salesOrder {
            cd
            orderType
            sellType
            orderTotal
            distributor {
              cd
              name
            }
            customer {
              cd
              name
            }
          }
          product {
            cd
            name
          }
          qty
          unitId
        }
        discount {
          cd
          description
          startDate
          endDate
          discountProducts {
            product {
              cd
              name
            }
            rate
          }
        }
        rewardTotal
      }
    }
  }
}
    `;

/**
 * __useReportSalesOrderDiscountLineQuery__
 *
 * To run a query within a React component, call `useReportSalesOrderDiscountLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSalesOrderDiscountLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSalesOrderDiscountLineQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *      productId: // value for 'productId'
 *      distributorId: // value for 'distributorId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerCd: // value for 'customerCd'
 *   },
 * });
 */
export function useReportSalesOrderDiscountLineQuery(baseOptions: Apollo.QueryHookOptions<ReportSalesOrderDiscountLineQuery, ReportSalesOrderDiscountLineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportSalesOrderDiscountLineQuery, ReportSalesOrderDiscountLineQueryVariables>(ReportSalesOrderDiscountLineDocument, options);
      }
export function useReportSalesOrderDiscountLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportSalesOrderDiscountLineQuery, ReportSalesOrderDiscountLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportSalesOrderDiscountLineQuery, ReportSalesOrderDiscountLineQueryVariables>(ReportSalesOrderDiscountLineDocument, options);
        }
export type ReportSalesOrderDiscountLineQueryHookResult = ReturnType<typeof useReportSalesOrderDiscountLineQuery>;
export type ReportSalesOrderDiscountLineLazyQueryHookResult = ReturnType<typeof useReportSalesOrderDiscountLineLazyQuery>;
export type ReportSalesOrderDiscountLineQueryResult = Apollo.QueryResult<ReportSalesOrderDiscountLineQuery, ReportSalesOrderDiscountLineQueryVariables>;
export const ReportVisitPlanSummaryDocument = gql`
    query ReportVisitPlanSummary($startDate: Date!, $endDate: Date!, $salesmanId: Int, $leaderId: Int, $page: Int!, $take: Int!) {
  rp {
    reportVisitPlanSummary(
      startDate: $startDate
      endDate: $endDate
      salesmanId: $salesmanId
      leaderId: $leaderId
      page: $page
      take: $take
    ) {
      total
      data {
        mcpId
        mcp {
          mcpDistributors {
            distributor {
              cd
              name
            }
          }
          salesman {
            id
            cd
            name
            supervisor {
              cd
              name
            }
            workingSalesRegions {
              salesRegion {
                cd
                name
              }
            }
          }
        }
        date
        inRouteRequiredVisitCount
        inRouteVisitedCount
        outRouteVisitedCount
        orderCount
        orderTotal
        inRouteOrderCount
        inRouteOrderTotal
        outRouteOrderCount
        outRouteOrderTotal
        visitTimeMinutes
        movingTimeMinutes
        visitTimeAverageMinutes
      }
    }
  }
}
    `;

/**
 * __useReportVisitPlanSummaryQuery__
 *
 * To run a query within a React component, call `useReportVisitPlanSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportVisitPlanSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportVisitPlanSummaryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      salesmanId: // value for 'salesmanId'
 *      leaderId: // value for 'leaderId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportVisitPlanSummaryQuery(baseOptions: Apollo.QueryHookOptions<ReportVisitPlanSummaryQuery, ReportVisitPlanSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportVisitPlanSummaryQuery, ReportVisitPlanSummaryQueryVariables>(ReportVisitPlanSummaryDocument, options);
      }
export function useReportVisitPlanSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportVisitPlanSummaryQuery, ReportVisitPlanSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportVisitPlanSummaryQuery, ReportVisitPlanSummaryQueryVariables>(ReportVisitPlanSummaryDocument, options);
        }
export type ReportVisitPlanSummaryQueryHookResult = ReturnType<typeof useReportVisitPlanSummaryQuery>;
export type ReportVisitPlanSummaryLazyQueryHookResult = ReturnType<typeof useReportVisitPlanSummaryLazyQuery>;
export type ReportVisitPlanSummaryQueryResult = Apollo.QueryResult<ReportVisitPlanSummaryQuery, ReportVisitPlanSummaryQueryVariables>;
export const ReportVisitCustomerDocument = gql`
    query ReportVisitCustomer($startDate: Date, $endDate: Date) {
  rp {
    reportVisitCustomer(startDate: $startDate, endDate: $endDate) {
      checkinId
      mcpId
      visitDate
      salesmanId
      salesmanCd
      salesmanName
      positionId
      positionName
      customerId
      customerCd
      customerStatus
      customerName
      customerContactName
      customerPhone
      customerAddress
      wardId
      wardName
      districtId
      districtName
      provinceId
      provinceName
      customerLat
      customerLng
      customerLinkAvatar
      quyMoDiemBan
      vungMien
      loaiHinhDiemBan
      kenhBanHang
      npp
      diemBanThuocNpp
      checkInAt
      checkInLat
      checkInLng
      checkInAccuracy
      distance
      checkOutAt
      checkOutLat
      checkOutLng
      checkInAtTime
      checkOutAtTime
      checkTime
      checkOutAccuracy
      checkOutReasonId
      checkOutReasonText
      movingSeconds
      isVisit
      linkPhoto_Visual1
      linkPhoto_Visual2
      checkInType
    }
  }
}
    `;

/**
 * __useReportVisitCustomerQuery__
 *
 * To run a query within a React component, call `useReportVisitCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportVisitCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportVisitCustomerQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useReportVisitCustomerQuery(baseOptions?: Apollo.QueryHookOptions<ReportVisitCustomerQuery, ReportVisitCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportVisitCustomerQuery, ReportVisitCustomerQueryVariables>(ReportVisitCustomerDocument, options);
      }
export function useReportVisitCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportVisitCustomerQuery, ReportVisitCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportVisitCustomerQuery, ReportVisitCustomerQueryVariables>(ReportVisitCustomerDocument, options);
        }
export type ReportVisitCustomerQueryHookResult = ReturnType<typeof useReportVisitCustomerQuery>;
export type ReportVisitCustomerLazyQueryHookResult = ReturnType<typeof useReportVisitCustomerLazyQuery>;
export type ReportVisitCustomerQueryResult = Apollo.QueryResult<ReportVisitCustomerQuery, ReportVisitCustomerQueryVariables>;
export const ReportVisitPlanDetailsDocument = gql`
    query ReportVisitPlanDetails($date: Date!, $salesmanId: Int!, $page: Int!, $take: Int!) {
  rp {
    reportVisitPlanDetails(
      date: $date
      salesmanId: $salesmanId
      page: $page
      take: $take
    ) {
      total
      data {
        mcpId
        mcp {
          mcpDistributors {
            distributor {
              cd
              name
            }
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
          }
        }
        customer {
          cd
          name
          lat
          lng
          photo {
            id
            url
          }
        }
        isInRoute
        date
        checkInAt
        checkOutAt
        totalMinutes
        movingMinutes
        reason
        distance
        orderTotal
        checkInIndex
        photo {
          id
          url
        }
      }
    }
  }
}
    `;

/**
 * __useReportVisitPlanDetailsQuery__
 *
 * To run a query within a React component, call `useReportVisitPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportVisitPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportVisitPlanDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      salesmanId: // value for 'salesmanId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportVisitPlanDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReportVisitPlanDetailsQuery, ReportVisitPlanDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportVisitPlanDetailsQuery, ReportVisitPlanDetailsQueryVariables>(ReportVisitPlanDetailsDocument, options);
      }
export function useReportVisitPlanDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportVisitPlanDetailsQuery, ReportVisitPlanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportVisitPlanDetailsQuery, ReportVisitPlanDetailsQueryVariables>(ReportVisitPlanDetailsDocument, options);
        }
export type ReportVisitPlanDetailsQueryHookResult = ReturnType<typeof useReportVisitPlanDetailsQuery>;
export type ReportVisitPlanDetailsLazyQueryHookResult = ReturnType<typeof useReportVisitPlanDetailsLazyQuery>;
export type ReportVisitPlanDetailsQueryResult = Apollo.QueryResult<ReportVisitPlanDetailsQuery, ReportVisitPlanDetailsQueryVariables>;
export const ReportWarehouseXntShowDetailsDocument = gql`
    query ReportWarehouseXntShowDetails($startDate: Date, $endDate: Date, $page: Int!, $take: Int!) {
  rp {
    reportWarehouseXntShowDetails(
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
    ) {
      total
      data {
        distributorId
        warehouseId
        productId
        lotNbr
        openQty
        salesOutQty
        returnPurchaseQty
        purchaseQty
        issueReturnQty
        issueQty
        receiptQty
        adjustReceiptQty
        adjustIssueQty
        transferIssueQty
        transferReceiptQty
        exchangeReceiptQty
        exchangeIssueQty
        rewardQty
        closeQty
        product {
          id
          cd
          name
          baseUnitId
        }
        warehouse {
          id
          name
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useReportWarehouseXntShowDetailsQuery__
 *
 * To run a query within a React component, call `useReportWarehouseXntShowDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWarehouseXntShowDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWarehouseXntShowDetailsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useReportWarehouseXntShowDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReportWarehouseXntShowDetailsQuery, ReportWarehouseXntShowDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportWarehouseXntShowDetailsQuery, ReportWarehouseXntShowDetailsQueryVariables>(ReportWarehouseXntShowDetailsDocument, options);
      }
export function useReportWarehouseXntShowDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportWarehouseXntShowDetailsQuery, ReportWarehouseXntShowDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportWarehouseXntShowDetailsQuery, ReportWarehouseXntShowDetailsQueryVariables>(ReportWarehouseXntShowDetailsDocument, options);
        }
export type ReportWarehouseXntShowDetailsQueryHookResult = ReturnType<typeof useReportWarehouseXntShowDetailsQuery>;
export type ReportWarehouseXntShowDetailsLazyQueryHookResult = ReturnType<typeof useReportWarehouseXntShowDetailsLazyQuery>;
export type ReportWarehouseXntShowDetailsQueryResult = Apollo.QueryResult<ReportWarehouseXntShowDetailsQuery, ReportWarehouseXntShowDetailsQueryVariables>;
export const ReportWarehouseXntDetailForDateShowListSalesDocument = gql`
    query ReportWarehouseXntDetailForDateShowListSales($startDate: Date, $endDate: Date, $page: Int!, $take: Int!, $type: Int!) {
  rp {
    reportWarehouseXntDetailForDateShowListSales(
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      type: $type
    ) {
      total
      data {
        distributorId
        warehouseId
        productId
        lotNbr
        openQty
        salesOutQty
        returnPurchaseQty
        purchaseQty
        issueReturnQty
        issueQty
        receiptQty
        adjustReceiptQty
        adjustIssueQty
        transferIssueQty
        transferReceiptQty
        exchangeReceiptQty
        exchangeIssueQty
        rewardQty
        closeQty
        product {
          id
          cd
          name
          baseUnitId
        }
        warehouse {
          id
          name
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useReportWarehouseXntDetailForDateShowListSalesQuery__
 *
 * To run a query within a React component, call `useReportWarehouseXntDetailForDateShowListSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWarehouseXntDetailForDateShowListSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWarehouseXntDetailForDateShowListSalesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReportWarehouseXntDetailForDateShowListSalesQuery(baseOptions: Apollo.QueryHookOptions<ReportWarehouseXntDetailForDateShowListSalesQuery, ReportWarehouseXntDetailForDateShowListSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportWarehouseXntDetailForDateShowListSalesQuery, ReportWarehouseXntDetailForDateShowListSalesQueryVariables>(ReportWarehouseXntDetailForDateShowListSalesDocument, options);
      }
export function useReportWarehouseXntDetailForDateShowListSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportWarehouseXntDetailForDateShowListSalesQuery, ReportWarehouseXntDetailForDateShowListSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportWarehouseXntDetailForDateShowListSalesQuery, ReportWarehouseXntDetailForDateShowListSalesQueryVariables>(ReportWarehouseXntDetailForDateShowListSalesDocument, options);
        }
export type ReportWarehouseXntDetailForDateShowListSalesQueryHookResult = ReturnType<typeof useReportWarehouseXntDetailForDateShowListSalesQuery>;
export type ReportWarehouseXntDetailForDateShowListSalesLazyQueryHookResult = ReturnType<typeof useReportWarehouseXntDetailForDateShowListSalesLazyQuery>;
export type ReportWarehouseXntDetailForDateShowListSalesQueryResult = Apollo.QueryResult<ReportWarehouseXntDetailForDateShowListSalesQuery, ReportWarehouseXntDetailForDateShowListSalesQueryVariables>;
export const SalesByCustomersDocument = gql`
    query SalesByCustomers($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $customerId: Int, $productId: Int) {
  rp {
    salesByCustomers(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      customerId: $customerId
      productId: $productId
    ) {
      total
      data {
        customerId
        productId
        totalQty
        totalSellQty
        totalKMQty
        totalPrice
        totalUnit
        customer {
          cd
          name
          contactName1
          phone11
          customerClass {
            cd
            name
          }
          customerPriceClass {
            cd
            name
          }
          ward {
            name
            district {
              name
              province {
                name
              }
            }
          }
          attrDetailIds
        }
        product {
          cd
          name
          description
          basePrice
          baseUnitId
          salesUnitId
          productAttrDetails {
            attrId
            attrDetailId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSalesByCustomersQuery__
 *
 * To run a query within a React component, call `useSalesByCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesByCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesByCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useSalesByCustomersQuery(baseOptions: Apollo.QueryHookOptions<SalesByCustomersQuery, SalesByCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesByCustomersQuery, SalesByCustomersQueryVariables>(SalesByCustomersDocument, options);
      }
export function useSalesByCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesByCustomersQuery, SalesByCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesByCustomersQuery, SalesByCustomersQueryVariables>(SalesByCustomersDocument, options);
        }
export type SalesByCustomersQueryHookResult = ReturnType<typeof useSalesByCustomersQuery>;
export type SalesByCustomersLazyQueryHookResult = ReturnType<typeof useSalesByCustomersLazyQuery>;
export type SalesByCustomersQueryResult = Apollo.QueryResult<SalesByCustomersQuery, SalesByCustomersQueryVariables>;
export const SalesByProductsDocument = gql`
    query SalesByProducts($page: Int!, $take: Int!, $distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int) {
  rp {
    salesByProducts(
      page: $page
      take: $take
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
    ) {
      total
      data {
        warehouseId
        distributorId
        productId
        totalQty
        totalSellQty
        totalKMQty
        totalPrice
        totalUnit
        distributor {
          cd
          name
        }
        warehouse {
          name
        }
        product {
          cd
          name
          description
          salesUnitId
          basePrice
          baseUnitId
          productAttrDetails {
            attrId
            attrDetailId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSalesByProductsQuery__
 *
 * To run a query within a React component, call `useSalesByProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesByProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesByProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useSalesByProductsQuery(baseOptions: Apollo.QueryHookOptions<SalesByProductsQuery, SalesByProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesByProductsQuery, SalesByProductsQueryVariables>(SalesByProductsDocument, options);
      }
export function useSalesByProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesByProductsQuery, SalesByProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesByProductsQuery, SalesByProductsQueryVariables>(SalesByProductsDocument, options);
        }
export type SalesByProductsQueryHookResult = ReturnType<typeof useSalesByProductsQuery>;
export type SalesByProductsLazyQueryHookResult = ReturnType<typeof useSalesByProductsLazyQuery>;
export type SalesByProductsQueryResult = Apollo.QueryResult<SalesByProductsQuery, SalesByProductsQueryVariables>;
export const SalesBySalesForceDocument = gql`
    query SalesBySalesForce($startDate: Date, $endDate: Date, $filter: String, $productId: Int) {
  rp {
    salesBySalesForce(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      productId: $productId
    ) {
      salesmanId
      productId
      totalQty
      totalSellQty
      totalKMQty
      totalPrice
      totalUnit
      salesman {
        cd
        name
        supervisor {
          cd
          name
        }
        workingSalesRegions {
          salesRegion {
            cd
            name
          }
        }
      }
      product {
        cd
        name
        description
        basePrice
        baseUnitId
        salesUnitId
        productAttrDetails {
          attrId
          attrDetailId
        }
      }
    }
  }
}
    `;

/**
 * __useSalesBySalesForceQuery__
 *
 * To run a query within a React component, call `useSalesBySalesForceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesBySalesForceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesBySalesForceQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useSalesBySalesForceQuery(baseOptions?: Apollo.QueryHookOptions<SalesBySalesForceQuery, SalesBySalesForceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesBySalesForceQuery, SalesBySalesForceQueryVariables>(SalesBySalesForceDocument, options);
      }
export function useSalesBySalesForceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesBySalesForceQuery, SalesBySalesForceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesBySalesForceQuery, SalesBySalesForceQueryVariables>(SalesBySalesForceDocument, options);
        }
export type SalesBySalesForceQueryHookResult = ReturnType<typeof useSalesBySalesForceQuery>;
export type SalesBySalesForceLazyQueryHookResult = ReturnType<typeof useSalesBySalesForceLazyQuery>;
export type SalesBySalesForceQueryResult = Apollo.QueryResult<SalesBySalesForceQuery, SalesBySalesForceQueryVariables>;
export const SalesOrderDetailsDocument = gql`
    query SalesOrderDetails($page: Int!, $take: Int!, $distributorId: Int, $startDate: Date, $endDate: Date, $productId: Int, $customerId: Int, $salesmanCd: String) {
  rp {
    salesOrderDetails(
      page: $page
      take: $take
      distributorId: $distributorId
      startDate: $startDate
      endDate: $endDate
      productId: $productId
      customerId: $customerId
      salesmanCd: $salesmanCd
    ) {
      total
      data {
        id
        salesOrder {
          id
          cd
          creator {
            id
            name
          }
          modifiedBy {
            id
            name

          }
          distributor {
            cd
            name
            contactName1
            phone11
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
            workingSalesRegions {
              salesRegion {
                cd
                name
              }
            }
          }
          customer {
            cd
            name
            contactName1
            phone11
            address1
            attrs {
              cd
              attrDetails {
                value
              }
            }
            attrDetails {
              value
            }
          }
          orderDate
          orderType
          orderTotal
          total
          orderStatus
          description
          orderAt
          discountMoney
        }
        product {
          cd
          name
          basePrice
          tax
        }
        qty
        baseQty
        unitId
        unitPrice
        unitPriceAfterTax
        baseUnitId
        baseUnitPrice
        isFreeItem
        warehouse {
          id
          name
          description
          distributor {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderDetailsQuery__
 *
 * To run a query within a React component, call `useSalesOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderDetailsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorId: // value for 'distributorId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *      customerId: // value for 'customerId'
 *      salesmanCd: // value for 'salesmanCd'
 *   },
 * });
 */
export function useSalesOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderDetailsQuery, SalesOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderDetailsQuery, SalesOrderDetailsQueryVariables>(SalesOrderDetailsDocument, options);
      }
export function useSalesOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderDetailsQuery, SalesOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderDetailsQuery, SalesOrderDetailsQueryVariables>(SalesOrderDetailsDocument, options);
        }
export type SalesOrderDetailsQueryHookResult = ReturnType<typeof useSalesOrderDetailsQuery>;
export type SalesOrderDetailsLazyQueryHookResult = ReturnType<typeof useSalesOrderDetailsLazyQuery>;
export type SalesOrderDetailsQueryResult = Apollo.QueryResult<SalesOrderDetailsQuery, SalesOrderDetailsQueryVariables>;
export const OrderRateByStatusDocument = gql`
    query OrderRateByStatus($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $filter: String) {
  rp {
    orderRateByStatus(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      filter: $filter
    ) {
      total
      data {
        distributorId
        salesmanId
        totalSalesOrder
        totalOrderComplete
        totalOrderPending
        totalOrderNew
        totalOrderCancel
        totalOrderHolding
        distributor {
          cd
          name
          contactName1
          phone11
        }
        salesman {
          cd
          name
          supervisor {
            cd
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrderRateByStatusQuery__
 *
 * To run a query within a React component, call `useOrderRateByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRateByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRateByStatusQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderRateByStatusQuery(baseOptions: Apollo.QueryHookOptions<OrderRateByStatusQuery, OrderRateByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderRateByStatusQuery, OrderRateByStatusQueryVariables>(OrderRateByStatusDocument, options);
      }
export function useOrderRateByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderRateByStatusQuery, OrderRateByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderRateByStatusQuery, OrderRateByStatusQueryVariables>(OrderRateByStatusDocument, options);
        }
export type OrderRateByStatusQueryHookResult = ReturnType<typeof useOrderRateByStatusQuery>;
export type OrderRateByStatusLazyQueryHookResult = ReturnType<typeof useOrderRateByStatusLazyQuery>;
export type OrderRateByStatusQueryResult = Apollo.QueryResult<OrderRateByStatusQuery, OrderRateByStatusQueryVariables>;
export const SurveyListReportDocument = gql`
    query SurveyListReport($startDate: Date, $endDate: Date, $filter: String, $surveyId: Int, $page: Int!, $take: Int!, $salesRegionId: Int, $status: ProgramStatus) {
  rp {
    surveyListReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      surveyId: $surveyId
      page: $page
      take: $take
      salesRegionId: $salesRegionId
      status: $status
    ) {
      total
      data {
        surveyId
        survey {
          cd
          title
          description
          status
          startDate
          endDate
          multiple
          surveyApplicableSalesRegions {
            salesRegion {
              cd
              name
            }
          }
          surveyApplicableCustomerAttrs {
            attrId
            attrDetailId
          }
        }
        surveyQuestionId
        surveyQuestion {
          title
          isFreeText
          surveyAnswers {
            title
            requiredOtherTextAnswer
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSurveyListReportQuery__
 *
 * To run a query within a React component, call `useSurveyListReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyListReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyListReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *      surveyId: // value for 'surveyId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      salesRegionId: // value for 'salesRegionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSurveyListReportQuery(baseOptions: Apollo.QueryHookOptions<SurveyListReportQuery, SurveyListReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyListReportQuery, SurveyListReportQueryVariables>(SurveyListReportDocument, options);
      }
export function useSurveyListReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyListReportQuery, SurveyListReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyListReportQuery, SurveyListReportQueryVariables>(SurveyListReportDocument, options);
        }
export type SurveyListReportQueryHookResult = ReturnType<typeof useSurveyListReportQuery>;
export type SurveyListReportLazyQueryHookResult = ReturnType<typeof useSurveyListReportLazyQuery>;
export type SurveyListReportQueryResult = Apollo.QueryResult<SurveyListReportQuery, SurveyListReportQueryVariables>;
export const FollowPromotionsDocument = gql`
    query FollowPromotions($startDate: Date, $endDate: Date, $discountId: Int, $filter: String, $page: Int!, $take: Int!) {
  rp {
    followPromotions(
      startDate: $startDate
      endDate: $endDate
      discountId: $discountId
      filter: $filter
      page: $page
      take: $take
    ) {
      total
      data {
        salesOrderDiscountId
        salesOrderDiscountLevelId
        salesOrderDiscount {
          id
          isManual
          salesOrderId
          discountId
          discount {
            id
            cd
            description
            status
            startDate
            endDate
            discountApplicableSalesRegions {
              id
              salesRegion {
                id
                cd
                name
              }
            }
            isBundle
            breakBy
            multiple
            highestLevel
            discountProducts {
              id
              productId
              rate
              product {
                id
                cd
                name
              }
            }
            discountLevels {
              id
              level
              description
              breakValue
            }
          }
          salesOrder {
            id
            cd
            orderDate
            orderAt
            orderStatus
            customer {
              id
              cd
              customerStatus
              name
              customerClassId
              customerPriceClassId
            }
            distributor {
              id
              cd
              name
            }
            description
            orderType
            orderTotal
            orderTotalAfterTax
            discountMoney
            total
            orderSource
            sellType
            isDistance
            salesOrderBeforeReturnId
          }
        }
        salesOrderDiscountLevel {
          id
          totalAmount
          percentage
          percentageMoney
          totalTurn
          isSelected
          discountLevelId
          salesOrderFreeItems {
            id
            qty
            productId
            product {
              id
              cd
              name
              description
              basePrice
              baseUnitId
              salesUnitId
              purchaseUnitId
              isPop
              isLot
              isExpirationDate
              isActive
              tax
              photo {
                id
                url
              }
              productAttrDetails {
                id
                attrId
                attrDetailId
              }
              productMeasureUnits {
                id
                fromUnitId
                saleable
                toUnitId
                rate
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFollowPromotionsQuery__
 *
 * To run a query within a React component, call `useFollowPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowPromotionsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      discountId: // value for 'discountId'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFollowPromotionsQuery(baseOptions: Apollo.QueryHookOptions<FollowPromotionsQuery, FollowPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowPromotionsQuery, FollowPromotionsQueryVariables>(FollowPromotionsDocument, options);
      }
export function useFollowPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowPromotionsQuery, FollowPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowPromotionsQuery, FollowPromotionsQueryVariables>(FollowPromotionsDocument, options);
        }
export type FollowPromotionsQueryHookResult = ReturnType<typeof useFollowPromotionsQuery>;
export type FollowPromotionsLazyQueryHookResult = ReturnType<typeof useFollowPromotionsLazyQuery>;
export type FollowPromotionsQueryResult = Apollo.QueryResult<FollowPromotionsQuery, FollowPromotionsQueryVariables>;
export const PromotionListDocument = gql`
    query PromotionList($date: Date, $discountId: Int, $page: Int!, $take: Int!, $filter: String, $salesRegionId: Int, $creatorCd: String, $createdDate: Date) {
  rp {
    promotionList(
      date: $date
      filter: $filter
      discountId: $discountId
      page: $page
      take: $take
      salesRegionId: $salesRegionId
      creatorCd: $creatorCd
      createdDate: $createdDate
    ) {
      total
      data {
        discountId
        discount {
          cd
          description
          status
          breakBy
          internalRef
          externalRef
          startDate
          endDate
          isBundle
          multiple
          highestLevel
          allowChangeLevel
          discountProducts {
            productId
          }
          discountApplicableCustomers {
            customer {
              cd
              name
            }
          }
          discountApplicableSalesRegions {
            salesRegion {
              cd
              name
            }
          }
          discountApplicableCustomerAttrs {
            attrId
            attrDetailId
          }
        }
        discountLevelId
        discountLevel {
          level
          breakValue
          description
          discountLevelConditions {
            productId
            minQty
          }
        }
        discountLevelRewardId
        discountLevelReward {
          isBundle
          isDefault
          description
          amount
          percentage
          qty
          discountLevelRewardProducts {
            productId
            rate
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePromotionListQuery__
 *
 * To run a query within a React component, call `usePromotionListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionListQuery({
 *   variables: {
 *      date: // value for 'date'
 *      discountId: // value for 'discountId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      salesRegionId: // value for 'salesRegionId'
 *      creatorCd: // value for 'creatorCd'
 *      createdDate: // value for 'createdDate'
 *   },
 * });
 */
export function usePromotionListQuery(baseOptions: Apollo.QueryHookOptions<PromotionListQuery, PromotionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionListQuery, PromotionListQueryVariables>(PromotionListDocument, options);
      }
export function usePromotionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionListQuery, PromotionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionListQuery, PromotionListQueryVariables>(PromotionListDocument, options);
        }
export type PromotionListQueryHookResult = ReturnType<typeof usePromotionListQuery>;
export type PromotionListLazyQueryHookResult = ReturnType<typeof usePromotionListLazyQuery>;
export type PromotionListQueryResult = Apollo.QueryResult<PromotionListQuery, PromotionListQueryVariables>;
export const OtherProductStockCountReportDocument = gql`
    query otherProductStockCountReport($startDate: Date, $endDate: Date, $otherProductId: Int, $page: Int!, $take: Int!, $filter: String, $salesmanId: Int, $mcpId: Int) {
  rp {
    otherProductStockCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      otherProductId: $otherProductId
      page: $page
      take: $take
      salesmanId: $salesmanId
      mcpId: $mcpId
    ) {
      total
      data {
        id
        checkIn {
          mcp {
            cd
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
          }
          customer {
            cd
            name
            contactName1
            phone11
            ward {
              name
              district {
                name
                province {
                  name
                }
              }
            }
          }
          visitDate
        }
        otherProduct {
          cd
          name
          measureUnitId
        }
        qty
      }
    }
  }
}
    `;

/**
 * __useOtherProductStockCountReportQuery__
 *
 * To run a query within a React component, call `useOtherProductStockCountReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherProductStockCountReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherProductStockCountReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      otherProductId: // value for 'otherProductId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      salesmanId: // value for 'salesmanId'
 *      mcpId: // value for 'mcpId'
 *   },
 * });
 */
export function useOtherProductStockCountReportQuery(baseOptions: Apollo.QueryHookOptions<OtherProductStockCountReportQuery, OtherProductStockCountReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherProductStockCountReportQuery, OtherProductStockCountReportQueryVariables>(OtherProductStockCountReportDocument, options);
      }
export function useOtherProductStockCountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherProductStockCountReportQuery, OtherProductStockCountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherProductStockCountReportQuery, OtherProductStockCountReportQueryVariables>(OtherProductStockCountReportDocument, options);
        }
export type OtherProductStockCountReportQueryHookResult = ReturnType<typeof useOtherProductStockCountReportQuery>;
export type OtherProductStockCountReportLazyQueryHookResult = ReturnType<typeof useOtherProductStockCountReportLazyQuery>;
export type OtherProductStockCountReportQueryResult = Apollo.QueryResult<OtherProductStockCountReportQuery, OtherProductStockCountReportQueryVariables>;
export const SellProductStockCountReportDocument = gql`
    query SellProductStockCountReport($startDate: Date, $endDate: Date, $productId: Int, $page: Int!, $take: Int!, $filter: String, $salesmanId: Int, $mcpId: Int) {
  rp {
    sellProductCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      productId: $productId
      page: $page
      take: $take
      salesmanId: $salesmanId
      mcpId: $mcpId
    ) {
      total
      data {
        id
        checkIn {
          mcp {
            cd
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
          }
          customer {
            cd
            name
            contactName1
            phone11
            ward {
              name
              district {
                name
                province {
                  name
                }
              }
            }
          }
          visitDate
        }
        product {
          cd
          name
        }
        unitId
        sellQty
        lotNbr
        startDate
        endDate
      }
    }
  }
}
    `;
export const ProductStockCountReportDocument = gql`
    query ProductStockCountReport($startDate: Date, $endDate: Date, $productId: Int, $page: Int!, $take: Int!, $filter: String, $salesmanId: Int, $mcpId: Int) {
  rp {
    productStockCountReport(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      productId: $productId
      page: $page
      take: $take
      salesmanId: $salesmanId
      mcpId: $mcpId
    ) {
      total
      data {
        id
        checkIn {
          mcp {
            cd
          }
          salesman {
            cd
            name
            supervisor {
              cd
              name
            }
          }
          customer {
            cd
            name
            contactName1
            phone11
            ward {
              name
              district {
                name
                province {
                  name
                }
              }
            }
          }
          visitDate
        }
        product {
          cd
          name
        }
        unitId
        qty
        lotNbr
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useProductStockCountReportQuery__
 *
 * To run a query within a React component, call `useProductStockCountReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductStockCountReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductStockCountReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      productId: // value for 'productId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      salesmanId: // value for 'salesmanId'
 *      mcpId: // value for 'mcpId'
 *   },
 * });
 */
export function useProductStockCountReportQuery(baseOptions: Apollo.QueryHookOptions<ProductStockCountReportQuery, ProductStockCountReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductStockCountReportQuery, ProductStockCountReportQueryVariables>(ProductStockCountReportDocument, options);
      }
export function useSellProductStockCountReportQuery(baseOptions: Apollo.QueryHookOptions<SellProductStockCountReportQuery, ProductStockCountReportQueryVariables>) {
      const options = {...defaultOptions, ...baseOptions}
      return Apollo.useQuery<SellProductStockCountReportQuery, ProductStockCountReportQueryVariables>(SellProductStockCountReportDocument, options);
}


export function useProductStockCountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductStockCountReportQuery, ProductStockCountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductStockCountReportQuery, ProductStockCountReportQueryVariables>(ProductStockCountReportDocument, options);
        }
export type ProductStockCountReportQueryHookResult = ReturnType<typeof useProductStockCountReportQuery>;
export type ProductStockCountReportLazyQueryHookResult = ReturnType<typeof useProductStockCountReportLazyQuery>;
export type ProductStockCountReportQueryResult = Apollo.QueryResult<ProductStockCountReportQuery, ProductStockCountReportQueryVariables>;
export const FollowVisualsDocument = gql`
    query FollowVisuals($startDate: Date, $endDate: Date, $visualId: Int, $filter: String, $page: Int!, $salesRegionId: Int, $take: Int!) {
  rp {
    followVisuals(
      startDate: $startDate
      endDate: $endDate
      visualId: $visualId
      filter: $filter
      salesRegionId: $salesRegionId
      page: $page
      take: $take
    ) {
      total
      data {
        id
        visualId
        checkInId
        photoId
        isPassed
        photo {
          id
          url
        }
        visual {
          id
          cd
          name
          description
          status
          startDate
          endDate
          trade {
            id
            cd
            description
            endDate
          }
          visualApplicableSalesRegions {
            salesRegion {
              id
              cd
              name
              description
            }
          }
        }
        checkIn {
          customer {
            id
            cd
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFollowVisualsQuery__
 *
 * To run a query within a React component, call `useFollowVisualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowVisualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowVisualsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      visualId: // value for 'visualId'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      salesRegionId: // value for 'salesRegionId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFollowVisualsQuery(baseOptions: Apollo.QueryHookOptions<FollowVisualsQuery, FollowVisualsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowVisualsQuery, FollowVisualsQueryVariables>(FollowVisualsDocument, options);
      }
export function useFollowVisualsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowVisualsQuery, FollowVisualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowVisualsQuery, FollowVisualsQueryVariables>(FollowVisualsDocument, options);
        }
export type FollowVisualsQueryHookResult = ReturnType<typeof useFollowVisualsQuery>;
export type FollowVisualsLazyQueryHookResult = ReturnType<typeof useFollowVisualsLazyQuery>;
export type FollowVisualsQueryResult = Apollo.QueryResult<FollowVisualsQuery, FollowVisualsQueryVariables>;
export const ReportVisualListDocument = gql`
    query ReportVisualList($visualId: Int, $filter: String, $page: Int!, $take: Int!, $salesRegionId: Int, $minPass: Int, $maxPass: Int) {
  rp {
    reportVisualList(
      visualId: $visualId
      filter: $filter
      page: $page
      take: $take
      salesRegionId: $salesRegionId
      minPass: $minPass
      maxPass: $maxPass
    ) {
      total
      data {
        customerId
        visualId
        visual {
          cd
          name
          description
          startDate
          endDate
          multiple
          minPhotos
          maxPhotos
          trade {
            cd
            endDate
          }
          visualApplicableSalesRegions {
            salesRegion {
              cd
              name
              description
            }
          }
        }
        customer {
          cd
          name
        }
        visualPhotos {
          photo {
            id
            url
          }
          isPassed
        }
        countPass
        totalPhoto
      }
    }
  }
}
    `;

/**
 * __useReportVisualListQuery__
 *
 * To run a query within a React component, call `useReportVisualListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportVisualListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportVisualListQuery({
 *   variables: {
 *      visualId: // value for 'visualId'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      salesRegionId: // value for 'salesRegionId'
 *      minPass: // value for 'minPass'
 *      maxPass: // value for 'maxPass'
 *   },
 * });
 */
export function useReportVisualListQuery(baseOptions: Apollo.QueryHookOptions<ReportVisualListQuery, ReportVisualListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportVisualListQuery, ReportVisualListQueryVariables>(ReportVisualListDocument, options);
      }
export function useReportVisualListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportVisualListQuery, ReportVisualListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportVisualListQuery, ReportVisualListQueryVariables>(ReportVisualListDocument, options);
        }
export type ReportVisualListQueryHookResult = ReturnType<typeof useReportVisualListQuery>;
export type ReportVisualListLazyQueryHookResult = ReturnType<typeof useReportVisualListLazyQuery>;
export type ReportVisualListQueryResult = Apollo.QueryResult<ReportVisualListQuery, ReportVisualListQueryVariables>;
export const BackNewSalesOrderDocument = gql`
    mutation BackNewSalesOrder($id: Int!) {
  o {
    backNewSalesOrder(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...SalesOrderFragment
      }
    }
  }
}
    ${SalesOrderFragmentDoc}`;
export type BackNewSalesOrderMutationFn = Apollo.MutationFunction<BackNewSalesOrderMutation, BackNewSalesOrderMutationVariables>;

/**
 * __useBackNewSalesOrderMutation__
 *
 * To run a mutation, you first call `useBackNewSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackNewSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backNewSalesOrderMutation, { data, loading, error }] = useBackNewSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackNewSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<BackNewSalesOrderMutation, BackNewSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackNewSalesOrderMutation, BackNewSalesOrderMutationVariables>(BackNewSalesOrderDocument, options);
      }
export type BackNewSalesOrderMutationHookResult = ReturnType<typeof useBackNewSalesOrderMutation>;
export type BackNewSalesOrderMutationResult = Apollo.MutationResult<BackNewSalesOrderMutation>;
export type BackNewSalesOrderMutationOptions = Apollo.BaseMutationOptions<BackNewSalesOrderMutation, BackNewSalesOrderMutationVariables>;
export const CancelSalesOrderDocument = gql`
    mutation CancelSalesOrder($id: Int!) {
  o {
    cancelSalesOrder(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...SalesOrderFragment
      }
    }
  }
}
    ${SalesOrderFragmentDoc}`;
export type CancelSalesOrderMutationFn = Apollo.MutationFunction<CancelSalesOrderMutation, CancelSalesOrderMutationVariables>;

/**
 * __useCancelSalesOrderMutation__
 *
 * To run a mutation, you first call `useCancelSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSalesOrderMutation, { data, loading, error }] = useCancelSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelSalesOrderMutation, CancelSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSalesOrderMutation, CancelSalesOrderMutationVariables>(CancelSalesOrderDocument, options);
      }
export type CancelSalesOrderMutationHookResult = ReturnType<typeof useCancelSalesOrderMutation>;
export type CancelSalesOrderMutationResult = Apollo.MutationResult<CancelSalesOrderMutation>;
export type CancelSalesOrderMutationOptions = Apollo.BaseMutationOptions<CancelSalesOrderMutation, CancelSalesOrderMutationVariables>;
export const CheckInventoryOnSalesOrderDocument = gql`
    query CheckInventoryOnSalesOrder($warehouseId: Int!, $productId: Int!, $unitId: String!) {
  o {
    checkInventoryOnSalesOrder(
      warehouseId: $warehouseId
      productId: $productId
      unitId: $unitId
    )
  }
}
    `;

/**
 * __useCheckInventoryOnSalesOrderQuery__
 *
 * To run a query within a React component, call `useCheckInventoryOnSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInventoryOnSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInventoryOnSalesOrderQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useCheckInventoryOnSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<CheckInventoryOnSalesOrderQuery, CheckInventoryOnSalesOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInventoryOnSalesOrderQuery, CheckInventoryOnSalesOrderQueryVariables>(CheckInventoryOnSalesOrderDocument, options);
      }
export function useCheckInventoryOnSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInventoryOnSalesOrderQuery, CheckInventoryOnSalesOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInventoryOnSalesOrderQuery, CheckInventoryOnSalesOrderQueryVariables>(CheckInventoryOnSalesOrderDocument, options);
        }
export type CheckInventoryOnSalesOrderQueryHookResult = ReturnType<typeof useCheckInventoryOnSalesOrderQuery>;
export type CheckInventoryOnSalesOrderLazyQueryHookResult = ReturnType<typeof useCheckInventoryOnSalesOrderLazyQuery>;
export type CheckInventoryOnSalesOrderQueryResult = Apollo.QueryResult<CheckInventoryOnSalesOrderQuery, CheckInventoryOnSalesOrderQueryVariables>;
export const ProcessCompleteOrderDocument = gql`
    mutation ProcessCompleteOrder($listIds: [Int!]!) {
  o {
    processCompleteOrder(listIds: $listIds)
  }
}
    `;
export type ProcessCompleteOrderMutationFn = Apollo.MutationFunction<ProcessCompleteOrderMutation, ProcessCompleteOrderMutationVariables>;

/**
 * __useProcessCompleteOrderMutation__
 *
 * To run a mutation, you first call `useProcessCompleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCompleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCompleteOrderMutation, { data, loading, error }] = useProcessCompleteOrderMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useProcessCompleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<ProcessCompleteOrderMutation, ProcessCompleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessCompleteOrderMutation, ProcessCompleteOrderMutationVariables>(ProcessCompleteOrderDocument, options);
      }
export type ProcessCompleteOrderMutationHookResult = ReturnType<typeof useProcessCompleteOrderMutation>;
export type ProcessCompleteOrderMutationResult = Apollo.MutationResult<ProcessCompleteOrderMutation>;
export type ProcessCompleteOrderMutationOptions = Apollo.BaseMutationOptions<ProcessCompleteOrderMutation, ProcessCompleteOrderMutationVariables>;
export const CompleteSalesOrderDocument = gql`
    mutation CompleteSalesOrder($id: Int!) {
  o {
    completeSalesOrder(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...SalesOrderFragment
      }
    }
  }
}
    ${SalesOrderFragmentDoc}`;
export type CompleteSalesOrderMutationFn = Apollo.MutationFunction<CompleteSalesOrderMutation, CompleteSalesOrderMutationVariables>;

/**
 * __useCompleteSalesOrderMutation__
 *
 * To run a mutation, you first call `useCompleteSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSalesOrderMutation, { data, loading, error }] = useCompleteSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteSalesOrderMutation, CompleteSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteSalesOrderMutation, CompleteSalesOrderMutationVariables>(CompleteSalesOrderDocument, options);
      }
export type CompleteSalesOrderMutationHookResult = ReturnType<typeof useCompleteSalesOrderMutation>;
export type CompleteSalesOrderMutationResult = Apollo.MutationResult<CompleteSalesOrderMutation>;
export type CompleteSalesOrderMutationOptions = Apollo.BaseMutationOptions<CompleteSalesOrderMutation, CompleteSalesOrderMutationVariables>;
export const CreateOrUpdateReasonEditOrderDocument = gql`
    mutation CreateOrUpdateReasonEditOrder($data: ReasonEditOrderInput!) {
  md {
    createOrUpdateReasonEditOrder(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        name
        description
      }
    }
  }
}
    `;
export type CreateOrUpdateReasonEditOrderMutationFn = Apollo.MutationFunction<CreateOrUpdateReasonEditOrderMutation, CreateOrUpdateReasonEditOrderMutationVariables>;

/**
 * __useCreateOrUpdateReasonEditOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateReasonEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateReasonEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateReasonEditOrderMutation, { data, loading, error }] = useCreateOrUpdateReasonEditOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateReasonEditOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateReasonEditOrderMutation, CreateOrUpdateReasonEditOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateReasonEditOrderMutation, CreateOrUpdateReasonEditOrderMutationVariables>(CreateOrUpdateReasonEditOrderDocument, options);
      }
export type CreateOrUpdateReasonEditOrderMutationHookResult = ReturnType<typeof useCreateOrUpdateReasonEditOrderMutation>;
export type CreateOrUpdateReasonEditOrderMutationResult = Apollo.MutationResult<CreateOrUpdateReasonEditOrderMutation>;
export type CreateOrUpdateReasonEditOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateReasonEditOrderMutation, CreateOrUpdateReasonEditOrderMutationVariables>;
export const CreateOrUpdateSalesOrderDocument = gql`
    mutation CreateOrUpdateSalesOrder($data: SalesOrderInput!) {
  o {
    createOrUpdateSalesOrder(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...SalesOrderFragment
      }
    }
  }
}
    ${SalesOrderFragmentDoc}`;
export type CreateOrUpdateSalesOrderMutationFn = Apollo.MutationFunction<CreateOrUpdateSalesOrderMutation, CreateOrUpdateSalesOrderMutationVariables>;

/**
 * __useCreateOrUpdateSalesOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSalesOrderMutation, { data, loading, error }] = useCreateOrUpdateSalesOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalesOrderMutation, CreateOrUpdateSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalesOrderMutation, CreateOrUpdateSalesOrderMutationVariables>(CreateOrUpdateSalesOrderDocument, options);
      }
export type CreateOrUpdateSalesOrderMutationHookResult = ReturnType<typeof useCreateOrUpdateSalesOrderMutation>;
export type CreateOrUpdateSalesOrderMutationResult = Apollo.MutationResult<CreateOrUpdateSalesOrderMutation>;
export type CreateOrUpdateSalesOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSalesOrderMutation, CreateOrUpdateSalesOrderMutationVariables>;
export const DeleteReasonEditOrderDocument = gql`
    mutation DeleteReasonEditOrder($id: Int!) {
  md {
    deleteReasonEditOrder(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteReasonEditOrderMutationFn = Apollo.MutationFunction<DeleteReasonEditOrderMutation, DeleteReasonEditOrderMutationVariables>;

/**
 * __useDeleteReasonEditOrderMutation__
 *
 * To run a mutation, you first call `useDeleteReasonEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReasonEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReasonEditOrderMutation, { data, loading, error }] = useDeleteReasonEditOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReasonEditOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReasonEditOrderMutation, DeleteReasonEditOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReasonEditOrderMutation, DeleteReasonEditOrderMutationVariables>(DeleteReasonEditOrderDocument, options);
      }
export type DeleteReasonEditOrderMutationHookResult = ReturnType<typeof useDeleteReasonEditOrderMutation>;
export type DeleteReasonEditOrderMutationResult = Apollo.MutationResult<DeleteReasonEditOrderMutation>;
export type DeleteReasonEditOrderMutationOptions = Apollo.BaseMutationOptions<DeleteReasonEditOrderMutation, DeleteReasonEditOrderMutationVariables>;
export const ReportPercentOrderCompleteDocument = gql`
    query ReportPercentOrderComplete($startDate: Date, $endDate: Date, $customerId: Int, $distributorId: Int, $salesmanId: Int, $page: Int!, $take: Int!, $minValue: Decimal, $maxValue: Decimal) {
  rp {
    reportPercentOrderComplete(
      startDate: $startDate
      endDate: $endDate
      customerId: $customerId
      distributorId: $distributorId
      salesmanId: $salesmanId
      page: $page
      take: $take
      minValue: $minValue
      maxValue: $maxValue
    ) {
      total
      data {
        salesmanId
        customerId
        distributorId
        salesman {
          cd
          name
          supervisor {
            cd
            name
          }
        }
        distributor {
          cd
          name
        }
        customer {
          cd
          name
        }
        totalComplete
        totalCancel
        totalOrder
        percentComplete
        percentCancel
      }
    }
  }
}
    `;

/**
 * __useReportPercentOrderCompleteQuery__
 *
 * To run a query within a React component, call `useReportPercentOrderCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPercentOrderCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPercentOrderCompleteQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      customerId: // value for 'customerId'
 *      distributorId: // value for 'distributorId'
 *      salesmanId: // value for 'salesmanId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useReportPercentOrderCompleteQuery(baseOptions: Apollo.QueryHookOptions<ReportPercentOrderCompleteQuery, ReportPercentOrderCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportPercentOrderCompleteQuery, ReportPercentOrderCompleteQueryVariables>(ReportPercentOrderCompleteDocument, options);
      }
export function useReportPercentOrderCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportPercentOrderCompleteQuery, ReportPercentOrderCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportPercentOrderCompleteQuery, ReportPercentOrderCompleteQueryVariables>(ReportPercentOrderCompleteDocument, options);
        }
export type ReportPercentOrderCompleteQueryHookResult = ReturnType<typeof useReportPercentOrderCompleteQuery>;
export type ReportPercentOrderCompleteLazyQueryHookResult = ReturnType<typeof useReportPercentOrderCompleteLazyQuery>;
export type ReportPercentOrderCompleteQueryResult = Apollo.QueryResult<ReportPercentOrderCompleteQuery, ReportPercentOrderCompleteQueryVariables>;
export const ProcessCancelOrderDocument = gql`
    mutation ProcessCancelOrder($listIds: [Int!]!) {
  o {
    processCancelOrder(listIds: $listIds)
  }
}
    `;
export type ProcessCancelOrderMutationFn = Apollo.MutationFunction<ProcessCancelOrderMutation, ProcessCancelOrderMutationVariables>;

/**
 * __useProcessCancelOrderMutation__
 *
 * To run a mutation, you first call `useProcessCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCancelOrderMutation, { data, loading, error }] = useProcessCancelOrderMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useProcessCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<ProcessCancelOrderMutation, ProcessCancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessCancelOrderMutation, ProcessCancelOrderMutationVariables>(ProcessCancelOrderDocument, options);
      }
export type ProcessCancelOrderMutationHookResult = ReturnType<typeof useProcessCancelOrderMutation>;
export type ProcessCancelOrderMutationResult = Apollo.MutationResult<ProcessCancelOrderMutation>;
export type ProcessCancelOrderMutationOptions = Apollo.BaseMutationOptions<ProcessCancelOrderMutation, ProcessCancelOrderMutationVariables>;
export const ProcessProcessOrderDocument = gql`
    mutation ProcessProcessOrder($listIds: [Int!]!) {
  o {
    processProcessOrder(listIds: $listIds)
  }
}
    `;
export type ProcessProcessOrderMutationFn = Apollo.MutationFunction<ProcessProcessOrderMutation, ProcessProcessOrderMutationVariables>;

/**
 * __useProcessProcessOrderMutation__
 *
 * To run a mutation, you first call `useProcessProcessOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessProcessOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processProcessOrderMutation, { data, loading, error }] = useProcessProcessOrderMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useProcessProcessOrderMutation(baseOptions?: Apollo.MutationHookOptions<ProcessProcessOrderMutation, ProcessProcessOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessProcessOrderMutation, ProcessProcessOrderMutationVariables>(ProcessProcessOrderDocument, options);
      }
export type ProcessProcessOrderMutationHookResult = ReturnType<typeof useProcessProcessOrderMutation>;
export type ProcessProcessOrderMutationResult = Apollo.MutationResult<ProcessProcessOrderMutation>;
export type ProcessProcessOrderMutationOptions = Apollo.BaseMutationOptions<ProcessProcessOrderMutation, ProcessProcessOrderMutationVariables>;
export const ProcessSalesOrderDocument = gql`
    mutation ProcessSalesOrder($id: Int!) {
  o {
    processSalesOrder(id: $id) {
      success
      message {
        code
        message
      }
      content {
        ...SalesOrderFragment
      }
    }
  }
}
    ${SalesOrderFragmentDoc}`;
export type ProcessSalesOrderMutationFn = Apollo.MutationFunction<ProcessSalesOrderMutation, ProcessSalesOrderMutationVariables>;

/**
 * __useProcessSalesOrderMutation__
 *
 * To run a mutation, you first call `useProcessSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSalesOrderMutation, { data, loading, error }] = useProcessSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<ProcessSalesOrderMutation, ProcessSalesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessSalesOrderMutation, ProcessSalesOrderMutationVariables>(ProcessSalesOrderDocument, options);
      }
export type ProcessSalesOrderMutationHookResult = ReturnType<typeof useProcessSalesOrderMutation>;
export type ProcessSalesOrderMutationResult = Apollo.MutationResult<ProcessSalesOrderMutation>;
export type ProcessSalesOrderMutationOptions = Apollo.BaseMutationOptions<ProcessSalesOrderMutation, ProcessSalesOrderMutationVariables>;
export const ReasonEditOrderListDocument = gql`
    query ReasonEditOrderList($page: Int!, $take: Int!, $filter: String) {
  md {
    reasonEditOrderList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        name
        reasonType
        description
      }
    }
  }
}
    `;

/**
 * __useReasonEditOrderListQuery__
 *
 * To run a query within a React component, call `useReasonEditOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonEditOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonEditOrderListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReasonEditOrderListQuery(baseOptions: Apollo.QueryHookOptions<ReasonEditOrderListQuery, ReasonEditOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReasonEditOrderListQuery, ReasonEditOrderListQueryVariables>(ReasonEditOrderListDocument, options);
      }
export function useReasonEditOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReasonEditOrderListQuery, ReasonEditOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReasonEditOrderListQuery, ReasonEditOrderListQueryVariables>(ReasonEditOrderListDocument, options);
        }
export type ReasonEditOrderListQueryHookResult = ReturnType<typeof useReasonEditOrderListQuery>;
export type ReasonEditOrderListLazyQueryHookResult = ReturnType<typeof useReasonEditOrderListLazyQuery>;
export type ReasonEditOrderListQueryResult = Apollo.QueryResult<ReasonEditOrderListQuery, ReasonEditOrderListQueryVariables>;
export const ReasonEditOrderDocument = gql`
    query ReasonEditOrder($id: Int!) {
  md {
    reasonEditOrder(id: $id) {
      id
      name
      reasonType
      description
    }
  }
}
    `;

/**
 * __useReasonEditOrderQuery__
 *
 * To run a query within a React component, call `useReasonEditOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonEditOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonEditOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReasonEditOrderQuery(baseOptions: Apollo.QueryHookOptions<ReasonEditOrderQuery, ReasonEditOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReasonEditOrderQuery, ReasonEditOrderQueryVariables>(ReasonEditOrderDocument, options);
      }
export function useReasonEditOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReasonEditOrderQuery, ReasonEditOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReasonEditOrderQuery, ReasonEditOrderQueryVariables>(ReasonEditOrderDocument, options);
        }
export type ReasonEditOrderQueryHookResult = ReturnType<typeof useReasonEditOrderQuery>;
export type ReasonEditOrderLazyQueryHookResult = ReturnType<typeof useReasonEditOrderLazyQuery>;
export type ReasonEditOrderQueryResult = Apollo.QueryResult<ReasonEditOrderQuery, ReasonEditOrderQueryVariables>;
export const SalesOrderCompletedDateDocument = gql`
    query SalesOrderCompletedDate($page: Int!, $take: Int!, $filter: String) {
  o {
    salesOrderCompletedDate(page: $page, take: $take, filter: $filter) {
      id
      cd
      orderDate
      orderAt
      orderType
      orderTotal
      orderSource
      orderStatus
      salesOrderBeforeReturnId
      reasonEditOrder {
        id
        description
      }
      description
      customer {
        id
        cd
        name
      }
      distributor {
        id
        cd
        name
      }
      salesman {
        id
        cd
        name
      }
      salesOrderTrackLots {
        id
        qty
        unitId
        unitPrice
        baseQty
        baseUnitId
        baseUnitPrice
        isReturned
        lotNbr
        startDate
        endDate
        product {
          id
          cd
          name
        }
        warehouse {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderCompletedDateQuery__
 *
 * To run a query within a React component, call `useSalesOrderCompletedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderCompletedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderCompletedDateQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSalesOrderCompletedDateQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderCompletedDateQuery, SalesOrderCompletedDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderCompletedDateQuery, SalesOrderCompletedDateQueryVariables>(SalesOrderCompletedDateDocument, options);
      }
export function useSalesOrderCompletedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderCompletedDateQuery, SalesOrderCompletedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderCompletedDateQuery, SalesOrderCompletedDateQueryVariables>(SalesOrderCompletedDateDocument, options);
        }
export type SalesOrderCompletedDateQueryHookResult = ReturnType<typeof useSalesOrderCompletedDateQuery>;
export type SalesOrderCompletedDateLazyQueryHookResult = ReturnType<typeof useSalesOrderCompletedDateLazyQuery>;
export type SalesOrderCompletedDateQueryResult = Apollo.QueryResult<SalesOrderCompletedDateQuery, SalesOrderCompletedDateQueryVariables>;
export const SalesOrderListDocument = gql`
    query SalesOrderList($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $status: OrderStatus, $distributorCd: String, $customerCd: String, $orderType: OrderType, $sellType: SellType, $salesOrderCd: String, $salesmanCd: String) {
  o {
    salesOrderList(
      page: $page
      take: $take
      status: $status
      startDate: $startDate
      endDate: $endDate
      salesmanCd: $salesmanCd
      distributorCd: $distributorCd
      customerCd: $customerCd
      orderType: $orderType
      sellType: $sellType
      salesOrderCd: $salesOrderCd
    ) {
      total
      data {
        id
        cd
        orderDate
        orderType
        sellType
        total
        discountMoney
        orderStatus
        orderSource
        orderTotal
        description
        creator {
          id
          cd
          name
        }
        customer {
          id
          cd
          name
          address1
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
        distributor {
          id
          cd
          name
        }
        salesman {
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderListQuery__
 *
 * To run a query within a React component, call `useSalesOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      distributorCd: // value for 'distributorCd'
 *      customerCd: // value for 'customerCd'
 *      orderType: // value for 'orderType'
 *      sellType: // value for 'sellType'
 *      salesOrderCd: // value for 'salesOrderCd'
 *      salesmanCd: // value for 'salesmanCd'
 *   },
 * });
 */
export function useSalesOrderListQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderListQuery, SalesOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderListQuery, SalesOrderListQueryVariables>(SalesOrderListDocument, options);
      }
export function useSalesOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderListQuery, SalesOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderListQuery, SalesOrderListQueryVariables>(SalesOrderListDocument, options);
        }
export type SalesOrderListQueryHookResult = ReturnType<typeof useSalesOrderListQuery>;
export type SalesOrderListLazyQueryHookResult = ReturnType<typeof useSalesOrderListLazyQuery>;
export type SalesOrderListQueryResult = Apollo.QueryResult<SalesOrderListQuery, SalesOrderListQueryVariables>;
export const SalesOrderProcessListDocument = gql`
    query SalesOrderProcessList($page: Int!, $take: Int!, $startDate: Date, $endDate: Date, $action: ActionType!, $distributorId: Int, $customerId: Int) {
  o {
    salesOrderProcessList(
      page: $page
      take: $take
      startDate: $startDate
      endDate: $endDate
      action: $action
      distributorId: $distributorId
      customerId: $customerId
    ) {
      total
      data {
        id
        cd
        orderDate
        orderType
        total
        discountMoney
        orderStatus
        orderSource
        orderTotal
        description
        customer {
          id
          cd
          name
          address1
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
        distributor {
          id
          cd
          name
        }
        salesman {
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderProcessListQuery__
 *
 * To run a query within a React component, call `useSalesOrderProcessListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderProcessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderProcessListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      action: // value for 'action'
 *      distributorId: // value for 'distributorId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSalesOrderProcessListQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderProcessListQuery, SalesOrderProcessListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderProcessListQuery, SalesOrderProcessListQueryVariables>(SalesOrderProcessListDocument, options);
      }
export function useSalesOrderProcessListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderProcessListQuery, SalesOrderProcessListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderProcessListQuery, SalesOrderProcessListQueryVariables>(SalesOrderProcessListDocument, options);
        }
export type SalesOrderProcessListQueryHookResult = ReturnType<typeof useSalesOrderProcessListQuery>;
export type SalesOrderProcessListLazyQueryHookResult = ReturnType<typeof useSalesOrderProcessListLazyQuery>;
export type SalesOrderProcessListQueryResult = Apollo.QueryResult<SalesOrderProcessListQuery, SalesOrderProcessListQueryVariables>;
export const SalesOrderDocument = gql`
    query SalesOrder($id: Int!) {
  o {
    salesOrder(id: $id) {
      ...SalesOrderFragment
    }
  }
}
    ${SalesOrderFragmentDoc}`;

/**
 * __useSalesOrderQuery__
 *
 * To run a query within a React component, call `useSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderQuery, SalesOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderQuery, SalesOrderQueryVariables>(SalesOrderDocument, options);
      }
export function useSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderQuery, SalesOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderQuery, SalesOrderQueryVariables>(SalesOrderDocument, options);
        }
export type SalesOrderQueryHookResult = ReturnType<typeof useSalesOrderQuery>;
export type SalesOrderLazyQueryHookResult = ReturnType<typeof useSalesOrderLazyQuery>;
export type SalesOrderQueryResult = Apollo.QueryResult<SalesOrderQuery, SalesOrderQueryVariables>;
export const SalesOrderReturnListDocument = gql`
    query SalesOrderReturnList($startDate: Date, $endDate: Date, $page: Int!, $take: Int!, $creatorCd: String, $productCd: String, $status: OrderStatus, $distributorCd: String, $customerCd: String, $salesOrderReturnCd: String) {
  o {
    salesOrderReturnList(
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      creatorCd: $creatorCd
      productCd: $productCd
      status: $status
      distributorCd: $distributorCd
      customerCd: $customerCd
      salesOrderReturnCd: $salesOrderReturnCd
    ) {
      total
      data {
        id
        cd
        orderDate
        orderStatus
        orderSource
        orderTotal
        description
        salesOrderBeforeReturn {
          cd
        }
        customer {
          id
          cd
          name
          address1
          ward {
            id
            name
            district {
              id
              name
              province {
                id
                name
              }
            }
          }
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderReturnListQuery__
 *
 * To run a query within a React component, call `useSalesOrderReturnListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderReturnListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderReturnListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      creatorCd: // value for 'creatorCd'
 *      productCd: // value for 'productCd'
 *      status: // value for 'status'
 *      distributorCd: // value for 'distributorCd'
 *      customerCd: // value for 'customerCd'
 *      salesOrderReturnCd: // value for 'salesOrderReturnCd'
 *   },
 * });
 */
export function useSalesOrderReturnListQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderReturnListQuery, SalesOrderReturnListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderReturnListQuery, SalesOrderReturnListQueryVariables>(SalesOrderReturnListDocument, options);
      }
export function useSalesOrderReturnListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderReturnListQuery, SalesOrderReturnListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderReturnListQuery, SalesOrderReturnListQueryVariables>(SalesOrderReturnListDocument, options);
        }
export type SalesOrderReturnListQueryHookResult = ReturnType<typeof useSalesOrderReturnListQuery>;
export type SalesOrderReturnListLazyQueryHookResult = ReturnType<typeof useSalesOrderReturnListLazyQuery>;
export type SalesOrderReturnListQueryResult = Apollo.QueryResult<SalesOrderReturnListQuery, SalesOrderReturnListQueryVariables>;
export const SalesOrderTrackLotDocument = gql`
    mutation SalesOrderTrackLot($data: [SalesOrderItemInput!]!) {
  o {
    salesOrderTrackLot(data: $data) {
      id
      qty
      unitId
      unitPrice
      unitPriceAfterTax
      baseQty
      baseUnitId
      baseUnitPrice
      lotNbr
      startDate
      endDate
      warehouseId
      productId
      warehouse {
        id
        name
      }
      product {
        id
        cd
        name
        tax
      }
    }
  }
}
    `;
export type SalesOrderTrackLotMutationFn = Apollo.MutationFunction<SalesOrderTrackLotMutation, SalesOrderTrackLotMutationVariables>;

/**
 * __useSalesOrderTrackLotMutation__
 *
 * To run a mutation, you first call `useSalesOrderTrackLotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderTrackLotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesOrderTrackLotMutation, { data, loading, error }] = useSalesOrderTrackLotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesOrderTrackLotMutation(baseOptions?: Apollo.MutationHookOptions<SalesOrderTrackLotMutation, SalesOrderTrackLotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SalesOrderTrackLotMutation, SalesOrderTrackLotMutationVariables>(SalesOrderTrackLotDocument, options);
      }
export type SalesOrderTrackLotMutationHookResult = ReturnType<typeof useSalesOrderTrackLotMutation>;
export type SalesOrderTrackLotMutationResult = Apollo.MutationResult<SalesOrderTrackLotMutation>;
export type SalesOrderTrackLotMutationOptions = Apollo.BaseMutationOptions<SalesOrderTrackLotMutation, SalesOrderTrackLotMutationVariables>;
export const CheckLotNbrDistributorDocument = gql`
    query CheckLotNbrDistributor($distributorId: Int!, $productId: Int!, $expirationDate: Date) {
  o {
    checkLotNbrDistributor(
      distributorId: $distributorId
      productId: $productId
      expirationDate: $expirationDate
    ) {
      lotNbr
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useCheckLotNbrDistributorQuery__
 *
 * To run a query within a React component, call `useCheckLotNbrDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLotNbrDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLotNbrDistributorQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *      productId: // value for 'productId'
 *      expirationDate: // value for 'expirationDate'
 *   },
 * });
 */
export function useCheckLotNbrDistributorQuery(baseOptions: Apollo.QueryHookOptions<CheckLotNbrDistributorQuery, CheckLotNbrDistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckLotNbrDistributorQuery, CheckLotNbrDistributorQueryVariables>(CheckLotNbrDistributorDocument, options);
      }
export function useCheckLotNbrDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckLotNbrDistributorQuery, CheckLotNbrDistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckLotNbrDistributorQuery, CheckLotNbrDistributorQueryVariables>(CheckLotNbrDistributorDocument, options);
        }
export type CheckLotNbrDistributorQueryHookResult = ReturnType<typeof useCheckLotNbrDistributorQuery>;
export type CheckLotNbrDistributorLazyQueryHookResult = ReturnType<typeof useCheckLotNbrDistributorLazyQuery>;
export type CheckLotNbrDistributorQueryResult = Apollo.QueryResult<CheckLotNbrDistributorQuery, CheckLotNbrDistributorQueryVariables>;
export const TradeRewardProductTrackLotDocument = gql`
    mutation TradeRewardProductTrackLot($data: [SalesOrderItemInput!]!) {
  p {
    tradeRewardProductTrackLot(data: $data) {
      id
      qty
      unitId
      lotNbr
      startDate
      endDate
      warehouseId
      productId
    }
  }
}
    `;
export type TradeRewardProductTrackLotMutationFn = Apollo.MutationFunction<TradeRewardProductTrackLotMutation, TradeRewardProductTrackLotMutationVariables>;

/**
 * __useTradeRewardProductTrackLotMutation__
 *
 * To run a mutation, you first call `useTradeRewardProductTrackLotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeRewardProductTrackLotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeRewardProductTrackLotMutation, { data, loading, error }] = useTradeRewardProductTrackLotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTradeRewardProductTrackLotMutation(baseOptions?: Apollo.MutationHookOptions<TradeRewardProductTrackLotMutation, TradeRewardProductTrackLotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TradeRewardProductTrackLotMutation, TradeRewardProductTrackLotMutationVariables>(TradeRewardProductTrackLotDocument, options);
      }
export type TradeRewardProductTrackLotMutationHookResult = ReturnType<typeof useTradeRewardProductTrackLotMutation>;
export type TradeRewardProductTrackLotMutationResult = Apollo.MutationResult<TradeRewardProductTrackLotMutation>;
export type TradeRewardProductTrackLotMutationOptions = Apollo.BaseMutationOptions<TradeRewardProductTrackLotMutation, TradeRewardProductTrackLotMutationVariables>;
export const WarehouseProductTrackLotsDocument = gql`
    query WarehouseProductTrackLots($data: [TrackLotWarehouseInput!]!) {
  in {
    warehouseProductTrackLots(data: $data) {
      product {
        id
        cd
        name
      }
      warehouse {
        id
        name
      }
      availableQty
      baseUnitId
      lotNbr
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useWarehouseProductTrackLotsQuery__
 *
 * To run a query within a React component, call `useWarehouseProductTrackLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseProductTrackLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseProductTrackLotsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWarehouseProductTrackLotsQuery(baseOptions: Apollo.QueryHookOptions<WarehouseProductTrackLotsQuery, WarehouseProductTrackLotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehouseProductTrackLotsQuery, WarehouseProductTrackLotsQueryVariables>(WarehouseProductTrackLotsDocument, options);
      }
export function useWarehouseProductTrackLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehouseProductTrackLotsQuery, WarehouseProductTrackLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehouseProductTrackLotsQuery, WarehouseProductTrackLotsQueryVariables>(WarehouseProductTrackLotsDocument, options);
        }
export type WarehouseProductTrackLotsQueryHookResult = ReturnType<typeof useWarehouseProductTrackLotsQuery>;
export type WarehouseProductTrackLotsLazyQueryHookResult = ReturnType<typeof useWarehouseProductTrackLotsLazyQuery>;
export type WarehouseProductTrackLotsQueryResult = Apollo.QueryResult<WarehouseProductTrackLotsQuery, WarehouseProductTrackLotsQueryVariables>;
export const CreateOrUpdateListSalesPriceDocument = gql`
    mutation CreateOrUpdateListSalesPrice($data: [SalesPriceInput!]!) {
  md {
    createOrUpdateListSalesPrice(data: $data) {
      content
      success
      message {
        message
      }
    }
  }
}
    `;
export type CreateOrUpdateListSalesPriceMutationFn = Apollo.MutationFunction<CreateOrUpdateListSalesPriceMutation, CreateOrUpdateListSalesPriceMutationVariables>;

/**
 * __useCreateOrUpdateListSalesPriceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateListSalesPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateListSalesPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateListSalesPriceMutation, { data, loading, error }] = useCreateOrUpdateListSalesPriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateListSalesPriceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateListSalesPriceMutation, CreateOrUpdateListSalesPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateListSalesPriceMutation, CreateOrUpdateListSalesPriceMutationVariables>(CreateOrUpdateListSalesPriceDocument, options);
      }
export type CreateOrUpdateListSalesPriceMutationHookResult = ReturnType<typeof useCreateOrUpdateListSalesPriceMutation>;
export type CreateOrUpdateListSalesPriceMutationResult = Apollo.MutationResult<CreateOrUpdateListSalesPriceMutation>;
export type CreateOrUpdateListSalesPriceMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateListSalesPriceMutation, CreateOrUpdateListSalesPriceMutationVariables>;
export const CreateOrUpdateSalesPriceDocument = gql`
    mutation CreateOrUpdateSalesPrice($data: SalesPriceInput!) {
  md {
    createOrUpdateSalesPrice(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...SalesPriceFragment
      }
    }
  }
}
    ${SalesPriceFragmentDoc}`;
export type CreateOrUpdateSalesPriceMutationFn = Apollo.MutationFunction<CreateOrUpdateSalesPriceMutation, CreateOrUpdateSalesPriceMutationVariables>;

/**
 * __useCreateOrUpdateSalesPriceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSalesPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSalesPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSalesPriceMutation, { data, loading, error }] = useCreateOrUpdateSalesPriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateSalesPriceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalesPriceMutation, CreateOrUpdateSalesPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalesPriceMutation, CreateOrUpdateSalesPriceMutationVariables>(CreateOrUpdateSalesPriceDocument, options);
      }
export type CreateOrUpdateSalesPriceMutationHookResult = ReturnType<typeof useCreateOrUpdateSalesPriceMutation>;
export type CreateOrUpdateSalesPriceMutationResult = Apollo.MutationResult<CreateOrUpdateSalesPriceMutation>;
export type CreateOrUpdateSalesPriceMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSalesPriceMutation, CreateOrUpdateSalesPriceMutationVariables>;
export const DeleteSalesPriceDocument = gql`
    mutation DeleteSalesPrice($id: Int!) {
  md {
    deleteSalesPrice(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteSalesPriceMutationFn = Apollo.MutationFunction<DeleteSalesPriceMutation, DeleteSalesPriceMutationVariables>;

/**
 * __useDeleteSalesPriceMutation__
 *
 * To run a mutation, you first call `useDeleteSalesPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesPriceMutation, { data, loading, error }] = useDeleteSalesPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSalesPriceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesPriceMutation, DeleteSalesPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesPriceMutation, DeleteSalesPriceMutationVariables>(DeleteSalesPriceDocument, options);
      }
export type DeleteSalesPriceMutationHookResult = ReturnType<typeof useDeleteSalesPriceMutation>;
export type DeleteSalesPriceMutationResult = Apollo.MutationResult<DeleteSalesPriceMutation>;
export type DeleteSalesPriceMutationOptions = Apollo.BaseMutationOptions<DeleteSalesPriceMutation, DeleteSalesPriceMutationVariables>;
export const SalesPriceListByCustomerDocument = gql`
    query SalesPriceListByCustomer($priceClassId: Int) {
  md {
    salesPriceListByCustomer(priceClassId: $priceClassId) {
      total
      data {
        ...SalesPriceFragment
      }
    }
  }
}
    ${SalesPriceFragmentDoc}`;

/**
 * __useSalesPriceListByCustomerQuery__
 *
 * To run a query within a React component, call `useSalesPriceListByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPriceListByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPriceListByCustomerQuery({
 *   variables: {
 *      priceClassId: // value for 'priceClassId'
 *   },
 * });
 */
export function useSalesPriceListByCustomerQuery(baseOptions?: Apollo.QueryHookOptions<SalesPriceListByCustomerQuery, SalesPriceListByCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesPriceListByCustomerQuery, SalesPriceListByCustomerQueryVariables>(SalesPriceListByCustomerDocument, options);
      }
export function useSalesPriceListByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesPriceListByCustomerQuery, SalesPriceListByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesPriceListByCustomerQuery, SalesPriceListByCustomerQueryVariables>(SalesPriceListByCustomerDocument, options);
        }
export type SalesPriceListByCustomerQueryHookResult = ReturnType<typeof useSalesPriceListByCustomerQuery>;
export type SalesPriceListByCustomerLazyQueryHookResult = ReturnType<typeof useSalesPriceListByCustomerLazyQuery>;
export type SalesPriceListByCustomerQueryResult = Apollo.QueryResult<SalesPriceListByCustomerQuery, SalesPriceListByCustomerQueryVariables>;
export const SalesPriceListByDistributorDocument = gql`
    query SalesPriceListByDistributor($distributorId: Int) {
  md {
    salesPriceListByDistributor(distributorId: $distributorId) {
      total
      data {
        ...SalesPriceFragment
      }
    }
  }
}
    ${SalesPriceFragmentDoc}`;

/**
 * __useSalesPriceListByDistributorQuery__
 *
 * To run a query within a React component, call `useSalesPriceListByDistributorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPriceListByDistributorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPriceListByDistributorQuery({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useSalesPriceListByDistributorQuery(baseOptions?: Apollo.QueryHookOptions<SalesPriceListByDistributorQuery, SalesPriceListByDistributorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesPriceListByDistributorQuery, SalesPriceListByDistributorQueryVariables>(SalesPriceListByDistributorDocument, options);
      }
export function useSalesPriceListByDistributorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesPriceListByDistributorQuery, SalesPriceListByDistributorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesPriceListByDistributorQuery, SalesPriceListByDistributorQueryVariables>(SalesPriceListByDistributorDocument, options);
        }
export type SalesPriceListByDistributorQueryHookResult = ReturnType<typeof useSalesPriceListByDistributorQuery>;
export type SalesPriceListByDistributorLazyQueryHookResult = ReturnType<typeof useSalesPriceListByDistributorLazyQuery>;
export type SalesPriceListByDistributorQueryResult = Apollo.QueryResult<SalesPriceListByDistributorQuery, SalesPriceListByDistributorQueryVariables>;
export const SalesPriceListDocument = gql`
    query SalesPriceList($page: Int!, $take: Int!, $productId: Int, $priceClassId: Int, $unitId: String) {
  md {
    salesPriceList(
      page: $page
      take: $take
      productId: $productId
      priceClassId: $priceClassId
      unitId: $unitId
    ) {
      total
      data {
        ...SalesPriceFragment
      }
    }
  }
}
    ${SalesPriceFragmentDoc}`;

/**
 * __useSalesPriceListQuery__
 *
 * To run a query within a React component, call `useSalesPriceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPriceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPriceListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      productId: // value for 'productId'
 *      priceClassId: // value for 'priceClassId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useSalesPriceListQuery(baseOptions: Apollo.QueryHookOptions<SalesPriceListQuery, SalesPriceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesPriceListQuery, SalesPriceListQueryVariables>(SalesPriceListDocument, options);
      }
export function useSalesPriceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesPriceListQuery, SalesPriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesPriceListQuery, SalesPriceListQueryVariables>(SalesPriceListDocument, options);
        }
export type SalesPriceListQueryHookResult = ReturnType<typeof useSalesPriceListQuery>;
export type SalesPriceListLazyQueryHookResult = ReturnType<typeof useSalesPriceListLazyQuery>;
export type SalesPriceListQueryResult = Apollo.QueryResult<SalesPriceListQuery, SalesPriceListQueryVariables>;
export const SalesPriceDocument = gql`
    query SalesPrice($id: Int!) {
  md {
    salesPrice(id: $id) {
      ...SalesPriceFragment
    }
  }
}
    ${SalesPriceFragmentDoc}`;

/**
 * __useSalesPriceQuery__
 *
 * To run a query within a React component, call `useSalesPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPriceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesPriceQuery(baseOptions: Apollo.QueryHookOptions<SalesPriceQuery, SalesPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesPriceQuery, SalesPriceQueryVariables>(SalesPriceDocument, options);
      }
export function useSalesPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesPriceQuery, SalesPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesPriceQuery, SalesPriceQueryVariables>(SalesPriceDocument, options);
        }
export type SalesPriceQueryHookResult = ReturnType<typeof useSalesPriceQuery>;
export type SalesPriceLazyQueryHookResult = ReturnType<typeof useSalesPriceLazyQuery>;
export type SalesPriceQueryResult = Apollo.QueryResult<SalesPriceQuery, SalesPriceQueryVariables>;
export const CreateOrUpdateSalesRegionDocument = gql`
    mutation CreateOrUpdateSalesRegion($data: SalesRegionInput!) {
  mcp {
    createOrUpdateSalesRegion(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...SalesRegionFragment
      }
    }
  }
}
    ${SalesRegionFragmentDoc}`;
export type CreateOrUpdateSalesRegionMutationFn = Apollo.MutationFunction<CreateOrUpdateSalesRegionMutation, CreateOrUpdateSalesRegionMutationVariables>;

/**
 * __useCreateOrUpdateSalesRegionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSalesRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSalesRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSalesRegionMutation, { data, loading, error }] = useCreateOrUpdateSalesRegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateSalesRegionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalesRegionMutation, CreateOrUpdateSalesRegionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalesRegionMutation, CreateOrUpdateSalesRegionMutationVariables>(CreateOrUpdateSalesRegionDocument, options);
      }
export type CreateOrUpdateSalesRegionMutationHookResult = ReturnType<typeof useCreateOrUpdateSalesRegionMutation>;
export type CreateOrUpdateSalesRegionMutationResult = Apollo.MutationResult<CreateOrUpdateSalesRegionMutation>;
export type CreateOrUpdateSalesRegionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSalesRegionMutation, CreateOrUpdateSalesRegionMutationVariables>;
export const DeleteSalesRegionDocument = gql`
    mutation DeleteSalesRegion($id: Int!) {
  mcp {
    deleteSalesRegion(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteSalesRegionMutationFn = Apollo.MutationFunction<DeleteSalesRegionMutation, DeleteSalesRegionMutationVariables>;

/**
 * __useDeleteSalesRegionMutation__
 *
 * To run a mutation, you first call `useDeleteSalesRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesRegionMutation, { data, loading, error }] = useDeleteSalesRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSalesRegionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesRegionMutation, DeleteSalesRegionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesRegionMutation, DeleteSalesRegionMutationVariables>(DeleteSalesRegionDocument, options);
      }
export type DeleteSalesRegionMutationHookResult = ReturnType<typeof useDeleteSalesRegionMutation>;
export type DeleteSalesRegionMutationResult = Apollo.MutationResult<DeleteSalesRegionMutation>;
export type DeleteSalesRegionMutationOptions = Apollo.BaseMutationOptions<DeleteSalesRegionMutation, DeleteSalesRegionMutationVariables>;
export const SalesRegionListDocument = gql`
    query SalesRegionList($page: Int!, $take: Int!, $filter: String) {
  mcp {
    salesRegionList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
        workGroupId
        description
      }
    }
  }
}
    `;

/**
 * __useSalesRegionListQuery__
 *
 * To run a query within a React component, call `useSalesRegionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesRegionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesRegionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSalesRegionListQuery(baseOptions: Apollo.QueryHookOptions<SalesRegionListQuery, SalesRegionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesRegionListQuery, SalesRegionListQueryVariables>(SalesRegionListDocument, options);
      }
export function useSalesRegionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesRegionListQuery, SalesRegionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesRegionListQuery, SalesRegionListQueryVariables>(SalesRegionListDocument, options);
        }
export type SalesRegionListQueryHookResult = ReturnType<typeof useSalesRegionListQuery>;
export type SalesRegionListLazyQueryHookResult = ReturnType<typeof useSalesRegionListLazyQuery>;
export type SalesRegionListQueryResult = Apollo.QueryResult<SalesRegionListQuery, SalesRegionListQueryVariables>;
export const SalesRegionDocument = gql`
    query SalesRegion($id: Int!) {
  mcp {
    salesRegion(id: $id) {
      ...SalesRegionFragment
    }
  }
}
    ${SalesRegionFragmentDoc}`;

/**
 * __useSalesRegionQuery__
 *
 * To run a query within a React component, call `useSalesRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesRegionQuery(baseOptions: Apollo.QueryHookOptions<SalesRegionQuery, SalesRegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesRegionQuery, SalesRegionQueryVariables>(SalesRegionDocument, options);
      }
export function useSalesRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesRegionQuery, SalesRegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesRegionQuery, SalesRegionQueryVariables>(SalesRegionDocument, options);
        }
export type SalesRegionQueryHookResult = ReturnType<typeof useSalesRegionQuery>;
export type SalesRegionLazyQueryHookResult = ReturnType<typeof useSalesRegionLazyQuery>;
export type SalesRegionQueryResult = Apollo.QueryResult<SalesRegionQuery, SalesRegionQueryVariables>;
export const CloseSurveyDocument = gql`
    mutation CloseSurvey($id: Int!) {
  p {
    closeSurvey(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseSurveyMutationFn = Apollo.MutationFunction<CloseSurveyMutation, CloseSurveyMutationVariables>;

/**
 * __useCloseSurveyMutation__
 *
 * To run a mutation, you first call `useCloseSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeSurveyMutation, { data, loading, error }] = useCloseSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CloseSurveyMutation, CloseSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseSurveyMutation, CloseSurveyMutationVariables>(CloseSurveyDocument, options);
      }
export type CloseSurveyMutationHookResult = ReturnType<typeof useCloseSurveyMutation>;
export type CloseSurveyMutationResult = Apollo.MutationResult<CloseSurveyMutation>;
export type CloseSurveyMutationOptions = Apollo.BaseMutationOptions<CloseSurveyMutation, CloseSurveyMutationVariables>;
export const CreateOrUpdateSurveyDocument = gql`
    mutation CreateOrUpdateSurvey($data: SurveyInput!) {
  p {
    createOrUpdateSurvey(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...SurveyFragment
      }
    }
  }
}
    ${SurveyFragmentDoc}`;
export type CreateOrUpdateSurveyMutationFn = Apollo.MutationFunction<CreateOrUpdateSurveyMutation, CreateOrUpdateSurveyMutationVariables>;

/**
 * __useCreateOrUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSurveyMutation, { data, loading, error }] = useCreateOrUpdateSurveyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSurveyMutation, CreateOrUpdateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSurveyMutation, CreateOrUpdateSurveyMutationVariables>(CreateOrUpdateSurveyDocument, options);
      }
export type CreateOrUpdateSurveyMutationHookResult = ReturnType<typeof useCreateOrUpdateSurveyMutation>;
export type CreateOrUpdateSurveyMutationResult = Apollo.MutationResult<CreateOrUpdateSurveyMutation>;
export type CreateOrUpdateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSurveyMutation, CreateOrUpdateSurveyMutationVariables>;
export const DeleteSurveyDocument = gql`
    mutation DeleteSurvey($id: Int!) {
  p {
    deleteSurvey(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteSurveyMutationFn = Apollo.MutationFunction<DeleteSurveyMutation, DeleteSurveyMutationVariables>;

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyMutation, DeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
      }
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<DeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>;
export const ReleaseSurveyDocument = gql`
    mutation ReleaseSurvey($id: Int!) {
  p {
    releaseSurvey(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseSurveyMutationFn = Apollo.MutationFunction<ReleaseSurveyMutation, ReleaseSurveyMutationVariables>;

/**
 * __useReleaseSurveyMutation__
 *
 * To run a mutation, you first call `useReleaseSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseSurveyMutation, { data, loading, error }] = useReleaseSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseSurveyMutation, ReleaseSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseSurveyMutation, ReleaseSurveyMutationVariables>(ReleaseSurveyDocument, options);
      }
export type ReleaseSurveyMutationHookResult = ReturnType<typeof useReleaseSurveyMutation>;
export type ReleaseSurveyMutationResult = Apollo.MutationResult<ReleaseSurveyMutation>;
export type ReleaseSurveyMutationOptions = Apollo.BaseMutationOptions<ReleaseSurveyMutation, ReleaseSurveyMutationVariables>;
export const SurveyListDocument = gql`
    query SurveyList($page: Int!, $take: Int!, $name: String, $status: ProgramStatus, $description: String, $surveyCd: String) {
  p {
    surveyList(
      page: $page
      take: $take
      name: $name
      status: $status
      description: $description
      surveyCd: $surveyCd
    ) {
      total
      data {
        id
        cd
        status
        title
        description
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useSurveyListQuery__
 *
 * To run a query within a React component, call `useSurveyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      description: // value for 'description'
 *      surveyCd: // value for 'surveyCd'
 *   },
 * });
 */
export function useSurveyListQuery(baseOptions: Apollo.QueryHookOptions<SurveyListQuery, SurveyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyListQuery, SurveyListQueryVariables>(SurveyListDocument, options);
      }
export function useSurveyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyListQuery, SurveyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyListQuery, SurveyListQueryVariables>(SurveyListDocument, options);
        }
export type SurveyListQueryHookResult = ReturnType<typeof useSurveyListQuery>;
export type SurveyListLazyQueryHookResult = ReturnType<typeof useSurveyListLazyQuery>;
export type SurveyListQueryResult = Apollo.QueryResult<SurveyListQuery, SurveyListQueryVariables>;
export const SurveyDocument = gql`
    query Survey($id: Int!) {
  p {
    survey(id: $id) {
      ...SurveyFragment
    }
  }
}
    ${SurveyFragmentDoc}`;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const AcceptTradeRewardDocument = gql`
    mutation AcceptTradeReward($id: Int!) {
  p {
    acceptTradeReward(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        description
        customerId
        distributorId
        tradeParticipationId
      }
    }
  }
}
    `;
export type AcceptTradeRewardMutationFn = Apollo.MutationFunction<AcceptTradeRewardMutation, AcceptTradeRewardMutationVariables>;

/**
 * __useAcceptTradeRewardMutation__
 *
 * To run a mutation, you first call `useAcceptTradeRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTradeRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTradeRewardMutation, { data, loading, error }] = useAcceptTradeRewardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptTradeRewardMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTradeRewardMutation, AcceptTradeRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTradeRewardMutation, AcceptTradeRewardMutationVariables>(AcceptTradeRewardDocument, options);
      }
export type AcceptTradeRewardMutationHookResult = ReturnType<typeof useAcceptTradeRewardMutation>;
export type AcceptTradeRewardMutationResult = Apollo.MutationResult<AcceptTradeRewardMutation>;
export type AcceptTradeRewardMutationOptions = Apollo.BaseMutationOptions<AcceptTradeRewardMutation, AcceptTradeRewardMutationVariables>;
export const CreateOrUpdateTradeRewardDocument = gql`
    mutation CreateOrUpdateTradeReward($data: TradeRewardInput!) {
  p {
    createOrUpdateTradeReward(data: $data) {
      success
      message {
        code
        message
      }
      content {
        id
        description
        customerId
        distributorId
        tradeParticipationId
      }
    }
  }
}
    `;
export type CreateOrUpdateTradeRewardMutationFn = Apollo.MutationFunction<CreateOrUpdateTradeRewardMutation, CreateOrUpdateTradeRewardMutationVariables>;

/**
 * __useCreateOrUpdateTradeRewardMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTradeRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTradeRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTradeRewardMutation, { data, loading, error }] = useCreateOrUpdateTradeRewardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateTradeRewardMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTradeRewardMutation, CreateOrUpdateTradeRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTradeRewardMutation, CreateOrUpdateTradeRewardMutationVariables>(CreateOrUpdateTradeRewardDocument, options);
      }
export type CreateOrUpdateTradeRewardMutationHookResult = ReturnType<typeof useCreateOrUpdateTradeRewardMutation>;
export type CreateOrUpdateTradeRewardMutationResult = Apollo.MutationResult<CreateOrUpdateTradeRewardMutation>;
export type CreateOrUpdateTradeRewardMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTradeRewardMutation, CreateOrUpdateTradeRewardMutationVariables>;
export const RejectTradeRewardDocument = gql`
    mutation RejectTradeReward($id: Int!) {
  p {
    rejectTradeReward(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        description
        customerId
        distributorId
        tradeParticipationId
      }
    }
  }
}
    `;
export type RejectTradeRewardMutationFn = Apollo.MutationFunction<RejectTradeRewardMutation, RejectTradeRewardMutationVariables>;

/**
 * __useRejectTradeRewardMutation__
 *
 * To run a mutation, you first call `useRejectTradeRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTradeRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTradeRewardMutation, { data, loading, error }] = useRejectTradeRewardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectTradeRewardMutation(baseOptions?: Apollo.MutationHookOptions<RejectTradeRewardMutation, RejectTradeRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTradeRewardMutation, RejectTradeRewardMutationVariables>(RejectTradeRewardDocument, options);
      }
export type RejectTradeRewardMutationHookResult = ReturnType<typeof useRejectTradeRewardMutation>;
export type RejectTradeRewardMutationResult = Apollo.MutationResult<RejectTradeRewardMutation>;
export type RejectTradeRewardMutationOptions = Apollo.BaseMutationOptions<RejectTradeRewardMutation, RejectTradeRewardMutationVariables>;
export const TradesAchievedDocument = gql`
    query TradesAchieved($page: Int!, $take: Int!, $customerId: Int!, $filter: String) {
  p {
    tradesAchieved(
      page: $page
      take: $take
      customerId: $customerId
      filter: $filter
    ) {
      total
      data {
        id
        tradeId
        customerId
        customer {
          name
        }
        trade {
          id
          cd
          description
          status
          breakBy
          multiple
          autoLevel
          registerStartDate
          registerEndDate
          startDate
          endDate
          tradeProducts {
            id
            productId
            rate
          }
          tradeLevels {
            id
            level
            description
            breakValue
            amount
            percentage
            qty
            isBundle
            tradeLevelProducts {
              id
              productId
              rate
            }
          }
        }
        status
        level
        approvedAt
        createdAt
        lastCalculatedDate
        result
        incentiveResult
      }
    }
  }
}
    `;

/**
 * __useTradesAchievedQuery__
 *
 * To run a query within a React component, call `useTradesAchievedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradesAchievedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradesAchievedQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      customerId: // value for 'customerId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradesAchievedQuery(baseOptions: Apollo.QueryHookOptions<TradesAchievedQuery, TradesAchievedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradesAchievedQuery, TradesAchievedQueryVariables>(TradesAchievedDocument, options);
      }
export function useTradesAchievedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradesAchievedQuery, TradesAchievedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradesAchievedQuery, TradesAchievedQueryVariables>(TradesAchievedDocument, options);
        }
export type TradesAchievedQueryHookResult = ReturnType<typeof useTradesAchievedQuery>;
export type TradesAchievedLazyQueryHookResult = ReturnType<typeof useTradesAchievedLazyQuery>;
export type TradesAchievedQueryResult = Apollo.QueryResult<TradesAchievedQuery, TradesAchievedQueryVariables>;
export const TradeRewardDocument = gql`
    query TradeReward($id: Int!) {
  p {
    tradeReward(id: $id) {
      id
      description
      customer {
        id
        cd
        name
      }
      distributor {
        id
        name
      }
      status
      tradeParticipation {
        id
        trade {
          description
        }
      }
      tradeRewardProducts {
        id
        productId
        rate
        qty
        warehouseId
        lotNbr
        startDate
        endDate
        product {
          name
          basePrice
          baseUnitId
          salesUnitId
        }
      }
    }
  }
}
    `;

/**
 * __useTradeRewardQuery__
 *
 * To run a query within a React component, call `useTradeRewardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeRewardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeRewardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTradeRewardQuery(baseOptions: Apollo.QueryHookOptions<TradeRewardQuery, TradeRewardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradeRewardQuery, TradeRewardQueryVariables>(TradeRewardDocument, options);
      }
export function useTradeRewardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradeRewardQuery, TradeRewardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradeRewardQuery, TradeRewardQueryVariables>(TradeRewardDocument, options);
        }
export type TradeRewardQueryHookResult = ReturnType<typeof useTradeRewardQuery>;
export type TradeRewardLazyQueryHookResult = ReturnType<typeof useTradeRewardLazyQuery>;
export type TradeRewardQueryResult = Apollo.QueryResult<TradeRewardQuery, TradeRewardQueryVariables>;
export const TradesRewardDocument = gql`
    query TradesReward($page: Int!, $take: Int!, $startDate: Date, $endDate: Date) {
  p {
    tradesReward(page: $page, take: $take, startDate: $startDate, endDate: $endDate) {
      total
      data {
        id
        description
        status
        note
        customer {
          id
          cd
          name
        }
        tradeParticipation {
          id
          trade {
            description
          }
        }
        distributor {
          id
          cd
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTradesRewardQuery__
 *
 * To run a query within a React component, call `useTradesRewardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradesRewardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradesRewardQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTradesRewardQuery(baseOptions: Apollo.QueryHookOptions<TradesRewardQuery, TradesRewardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradesRewardQuery, TradesRewardQueryVariables>(TradesRewardDocument, options);
      }
export function useTradesRewardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradesRewardQuery, TradesRewardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradesRewardQuery, TradesRewardQueryVariables>(TradesRewardDocument, options);
        }
export type TradesRewardQueryHookResult = ReturnType<typeof useTradesRewardQuery>;
export type TradesRewardLazyQueryHookResult = ReturnType<typeof useTradesRewardLazyQuery>;
export type TradesRewardQueryResult = Apollo.QueryResult<TradesRewardQuery, TradesRewardQueryVariables>;
export const CloseTradeDocument = gql`
    mutation CloseTrade($id: Int!) {
  p {
    closeTrade(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseTradeMutationFn = Apollo.MutationFunction<CloseTradeMutation, CloseTradeMutationVariables>;

/**
 * __useCloseTradeMutation__
 *
 * To run a mutation, you first call `useCloseTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTradeMutation, { data, loading, error }] = useCloseTradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseTradeMutation(baseOptions?: Apollo.MutationHookOptions<CloseTradeMutation, CloseTradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseTradeMutation, CloseTradeMutationVariables>(CloseTradeDocument, options);
      }
export type CloseTradeMutationHookResult = ReturnType<typeof useCloseTradeMutation>;
export type CloseTradeMutationResult = Apollo.MutationResult<CloseTradeMutation>;
export type CloseTradeMutationOptions = Apollo.BaseMutationOptions<CloseTradeMutation, CloseTradeMutationVariables>;
export const CreateOrUpdateTradeDocument = gql`
    mutation CreateOrUpdateTrade($data: TradeInput!) {
  p {
    createOrUpdateTrade(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...TradeFragment
      }
    }
  }
}
    ${TradeFragmentDoc}`;
export type CreateOrUpdateTradeMutationFn = Apollo.MutationFunction<CreateOrUpdateTradeMutation, CreateOrUpdateTradeMutationVariables>;

/**
 * __useCreateOrUpdateTradeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTradeMutation, { data, loading, error }] = useCreateOrUpdateTradeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateTradeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTradeMutation, CreateOrUpdateTradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTradeMutation, CreateOrUpdateTradeMutationVariables>(CreateOrUpdateTradeDocument, options);
      }
export type CreateOrUpdateTradeMutationHookResult = ReturnType<typeof useCreateOrUpdateTradeMutation>;
export type CreateOrUpdateTradeMutationResult = Apollo.MutationResult<CreateOrUpdateTradeMutation>;
export type CreateOrUpdateTradeMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTradeMutation, CreateOrUpdateTradeMutationVariables>;
export const DeleteTradeDocument = gql`
    mutation DeleteTrade($id: Int!) {
  p {
    deleteTrade(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteTradeMutationFn = Apollo.MutationFunction<DeleteTradeMutation, DeleteTradeMutationVariables>;

/**
 * __useDeleteTradeMutation__
 *
 * To run a mutation, you first call `useDeleteTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTradeMutation, { data, loading, error }] = useDeleteTradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTradeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTradeMutation, DeleteTradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTradeMutation, DeleteTradeMutationVariables>(DeleteTradeDocument, options);
      }
export type DeleteTradeMutationHookResult = ReturnType<typeof useDeleteTradeMutation>;
export type DeleteTradeMutationResult = Apollo.MutationResult<DeleteTradeMutation>;
export type DeleteTradeMutationOptions = Apollo.BaseMutationOptions<DeleteTradeMutation, DeleteTradeMutationVariables>;
export const LoyaltyListDocument = gql`
    query LoyaltyList($startDate: Date, $endDate: Date, $tradeId: Int, $salesRegionId: Int, $status: ProgramStatus, $target: TradeApplyTarget, $filter: String, $page: Int!, $take: Int!) {
  rp {
    loyaltyList(
      tradeId: $tradeId
      filter: $filter
      page: $page
      startDate: $startDate
      endDate: $endDate
      take: $take
      salesRegionId: $salesRegionId
      status: $status
      target: $target
    ) {
      total
      data {
        tradeId
        trade {
          cd
          description
          status
          breakBy
          tradeApplyTarget
          registerStartDate
          registerEndDate
          startDate
          endDate
          multiple
          autoLevel
          tradeProducts {
            productId
            rate
            product {
              name
            }
          }
          tradeApplicableCustomers {
            customer {
              cd
              name
            }
          }
          tradeApplicableCustomerAttrs {
            attrId
            attrDetailId
          }
          tradeApplicableSalesRegions {
            salesRegion {
              cd
              name
            }
          }
        }
        tradeLevel {
          level
          isBundle
          description
          breakValue
          amount
          percentage
          qty
        }
        tradeLevelProduct {
          productId
          rate
          product {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoyaltyListQuery__
 *
 * To run a query within a React component, call `useLoyaltyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tradeId: // value for 'tradeId'
 *      salesRegionId: // value for 'salesRegionId'
 *      status: // value for 'status'
 *      target: // value for 'target'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useLoyaltyListQuery(baseOptions: Apollo.QueryHookOptions<LoyaltyListQuery, LoyaltyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoyaltyListQuery, LoyaltyListQueryVariables>(LoyaltyListDocument, options);
      }
export function useLoyaltyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoyaltyListQuery, LoyaltyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoyaltyListQuery, LoyaltyListQueryVariables>(LoyaltyListDocument, options);
        }
export type LoyaltyListQueryHookResult = ReturnType<typeof useLoyaltyListQuery>;
export type LoyaltyListLazyQueryHookResult = ReturnType<typeof useLoyaltyListLazyQuery>;
export type LoyaltyListQueryResult = Apollo.QueryResult<LoyaltyListQuery, LoyaltyListQueryVariables>;
export const ReleaseTradeDocument = gql`
    mutation ReleaseTrade($id: Int!) {
  p {
    releaseTrade(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseTradeMutationFn = Apollo.MutationFunction<ReleaseTradeMutation, ReleaseTradeMutationVariables>;

/**
 * __useReleaseTradeMutation__
 *
 * To run a mutation, you first call `useReleaseTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTradeMutation, { data, loading, error }] = useReleaseTradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseTradeMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseTradeMutation, ReleaseTradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseTradeMutation, ReleaseTradeMutationVariables>(ReleaseTradeDocument, options);
      }
export type ReleaseTradeMutationHookResult = ReturnType<typeof useReleaseTradeMutation>;
export type ReleaseTradeMutationResult = Apollo.MutationResult<ReleaseTradeMutation>;
export type ReleaseTradeMutationOptions = Apollo.BaseMutationOptions<ReleaseTradeMutation, ReleaseTradeMutationVariables>;
export const TradeListDocument = gql`
    query TradeList($page: Int!, $take: Int!, $filter: String, $status: ProgramStatus, $tradeCd: String, $salesRegionId: Int) {
  p {
    tradeList(
      page: $page
      take: $take
      filter: $filter
      status: $status
      tradeCd: $tradeCd
      salesRegionId: $salesRegionId
    ) {
      total
      data {
        id
        cd
        description
        status
        breakBy
        multiple
        autoLevel
        registerStartDate
        registerEndDate
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useTradeListQuery__
 *
 * To run a query within a React component, call `useTradeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      tradeCd: // value for 'tradeCd'
 *      salesRegionId: // value for 'salesRegionId'
 *   },
 * });
 */
export function useTradeListQuery(baseOptions: Apollo.QueryHookOptions<TradeListQuery, TradeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradeListQuery, TradeListQueryVariables>(TradeListDocument, options);
      }
export function useTradeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradeListQuery, TradeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradeListQuery, TradeListQueryVariables>(TradeListDocument, options);
        }
export type TradeListQueryHookResult = ReturnType<typeof useTradeListQuery>;
export type TradeListLazyQueryHookResult = ReturnType<typeof useTradeListLazyQuery>;
export type TradeListQueryResult = Apollo.QueryResult<TradeListQuery, TradeListQueryVariables>;
export const TradeDocument = gql`
    query Trade($id: Int!) {
  p {
    trade(id: $id) {
      ...TradeFragment
    }
  }
}
    ${TradeFragmentDoc}`;

/**
 * __useTradeQuery__
 *
 * To run a query within a React component, call `useTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTradeQuery(baseOptions: Apollo.QueryHookOptions<TradeQuery, TradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradeQuery, TradeQueryVariables>(TradeDocument, options);
      }
export function useTradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradeQuery, TradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradeQuery, TradeQueryVariables>(TradeDocument, options);
        }
export type TradeQueryHookResult = ReturnType<typeof useTradeQuery>;
export type TradeLazyQueryHookResult = ReturnType<typeof useTradeLazyQuery>;
export type TradeQueryResult = Apollo.QueryResult<TradeQuery, TradeQueryVariables>;
export const ActionCancelTransactionDocument = gql`
    mutation ActionCancelTransaction($listIds: [Int!]!, $source: TransactionSource!) {
  in {
    actionCancelTransaction(listIds: $listIds, source: $source)
  }
}
    `;
export type ActionCancelTransactionMutationFn = Apollo.MutationFunction<ActionCancelTransactionMutation, ActionCancelTransactionMutationVariables>;

/**
 * __useActionCancelTransactionMutation__
 *
 * To run a mutation, you first call `useActionCancelTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionCancelTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionCancelTransactionMutation, { data, loading, error }] = useActionCancelTransactionMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useActionCancelTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ActionCancelTransactionMutation, ActionCancelTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionCancelTransactionMutation, ActionCancelTransactionMutationVariables>(ActionCancelTransactionDocument, options);
      }
export type ActionCancelTransactionMutationHookResult = ReturnType<typeof useActionCancelTransactionMutation>;
export type ActionCancelTransactionMutationResult = Apollo.MutationResult<ActionCancelTransactionMutation>;
export type ActionCancelTransactionMutationOptions = Apollo.BaseMutationOptions<ActionCancelTransactionMutation, ActionCancelTransactionMutationVariables>;
export const ActionCompleteTransactionDocument = gql`
    mutation ActionCompleteTransaction($listIds: [Int!]!, $source: TransactionSource!) {
  in {
    actionCompleteTransaction(listIds: $listIds, source: $source)
  }
}
    `;
export type ActionCompleteTransactionMutationFn = Apollo.MutationFunction<ActionCompleteTransactionMutation, ActionCompleteTransactionMutationVariables>;

/**
 * __useActionCompleteTransactionMutation__
 *
 * To run a mutation, you first call `useActionCompleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionCompleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionCompleteTransactionMutation, { data, loading, error }] = useActionCompleteTransactionMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useActionCompleteTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ActionCompleteTransactionMutation, ActionCompleteTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionCompleteTransactionMutation, ActionCompleteTransactionMutationVariables>(ActionCompleteTransactionDocument, options);
      }
export type ActionCompleteTransactionMutationHookResult = ReturnType<typeof useActionCompleteTransactionMutation>;
export type ActionCompleteTransactionMutationResult = Apollo.MutationResult<ActionCompleteTransactionMutation>;
export type ActionCompleteTransactionMutationOptions = Apollo.BaseMutationOptions<ActionCompleteTransactionMutation, ActionCompleteTransactionMutationVariables>;
export const ActionProcessTransactionDocument = gql`
    mutation ActionProcessTransaction($listIds: [Int!]!, $source: TransactionSource!) {
  in {
    actionProcessTransaction(listIds: $listIds, source: $source)
  }
}
    `;
export type ActionProcessTransactionMutationFn = Apollo.MutationFunction<ActionProcessTransactionMutation, ActionProcessTransactionMutationVariables>;

/**
 * __useActionProcessTransactionMutation__
 *
 * To run a mutation, you first call `useActionProcessTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionProcessTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionProcessTransactionMutation, { data, loading, error }] = useActionProcessTransactionMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useActionProcessTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ActionProcessTransactionMutation, ActionProcessTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionProcessTransactionMutation, ActionProcessTransactionMutationVariables>(ActionProcessTransactionDocument, options);
      }
export type ActionProcessTransactionMutationHookResult = ReturnType<typeof useActionProcessTransactionMutation>;
export type ActionProcessTransactionMutationResult = Apollo.MutationResult<ActionProcessTransactionMutation>;
export type ActionProcessTransactionMutationOptions = Apollo.BaseMutationOptions<ActionProcessTransactionMutation, ActionProcessTransactionMutationVariables>;
export const CancelTransactionDocument = gql`
    mutation CancelTransaction($id: Int!, $source: TransactionSource!) {
  in {
    cancelTransaction(id: $id, source: $source) {
      success
      message {
        code
        message
      }
      content {
        ...TransactionFragment
      }
    }
  }
}
    ${TransactionFragmentDoc}`;
export type CancelTransactionMutationFn = Apollo.MutationFunction<CancelTransactionMutation, CancelTransactionMutationVariables>;

/**
 * __useCancelTransactionMutation__
 *
 * To run a mutation, you first call `useCancelTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransactionMutation, { data, loading, error }] = useCancelTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCancelTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CancelTransactionMutation, CancelTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTransactionMutation, CancelTransactionMutationVariables>(CancelTransactionDocument, options);
      }
export type CancelTransactionMutationHookResult = ReturnType<typeof useCancelTransactionMutation>;
export type CancelTransactionMutationResult = Apollo.MutationResult<CancelTransactionMutation>;
export type CancelTransactionMutationOptions = Apollo.BaseMutationOptions<CancelTransactionMutation, CancelTransactionMutationVariables>;
export const CompleteTransactionDocument = gql`
    mutation CompleteTransaction($id: Int!, $source: TransactionSource!) {
  in {
    completeTransaction(id: $id, source: $source) {
      success
      message {
        code
        message
      }
      content {
        ...TransactionFragment
      }
    }
  }
}
    ${TransactionFragmentDoc}`;
export type CompleteTransactionMutationFn = Apollo.MutationFunction<CompleteTransactionMutation, CompleteTransactionMutationVariables>;

/**
 * __useCompleteTransactionMutation__
 *
 * To run a mutation, you first call `useCompleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTransactionMutation, { data, loading, error }] = useCompleteTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCompleteTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTransactionMutation, CompleteTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTransactionMutation, CompleteTransactionMutationVariables>(CompleteTransactionDocument, options);
      }
export type CompleteTransactionMutationHookResult = ReturnType<typeof useCompleteTransactionMutation>;
export type CompleteTransactionMutationResult = Apollo.MutationResult<CompleteTransactionMutation>;
export type CompleteTransactionMutationOptions = Apollo.BaseMutationOptions<CompleteTransactionMutation, CompleteTransactionMutationVariables>;
export const CreateOrUpdateTransactionDocument = gql`
    mutation CreateOrUpdateTransaction($data: TransactionInput!) {
  in {
    createOrUpdateTransaction(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...TransactionFragment
      }
    }
  }
}
    ${TransactionFragmentDoc}`;
export type CreateOrUpdateTransactionMutationFn = Apollo.MutationFunction<CreateOrUpdateTransactionMutation, CreateOrUpdateTransactionMutationVariables>;

/**
 * __useCreateOrUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTransactionMutation, { data, loading, error }] = useCreateOrUpdateTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTransactionMutation, CreateOrUpdateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTransactionMutation, CreateOrUpdateTransactionMutationVariables>(CreateOrUpdateTransactionDocument, options);
      }
export type CreateOrUpdateTransactionMutationHookResult = ReturnType<typeof useCreateOrUpdateTransactionMutation>;
export type CreateOrUpdateTransactionMutationResult = Apollo.MutationResult<CreateOrUpdateTransactionMutation>;
export type CreateOrUpdateTransactionMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTransactionMutation, CreateOrUpdateTransactionMutationVariables>;
export const FilterAllLotNumberDocument = gql`
    query FilterAllLotNumber($page: Int!, $take: Int!, $filter: String!, $productId: Int!, $warehouseId: Int!) {
  in {
    filterAllLotNumber(
      page: $page
      take: $take
      filter: $filter
      productId: $productId
      warehouseId: $warehouseId
    ) {
      total
      data {
        id
        product {
          id
          cd
          name
        }
        lotNbr
        startDate
        endDate
        availableQty
      }
    }
  }
}
    `;

/**
 * __useFilterAllLotNumberQuery__
 *
 * To run a query within a React component, call `useFilterAllLotNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterAllLotNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterAllLotNumberQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      productId: // value for 'productId'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useFilterAllLotNumberQuery(baseOptions: Apollo.QueryHookOptions<FilterAllLotNumberQuery, FilterAllLotNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterAllLotNumberQuery, FilterAllLotNumberQueryVariables>(FilterAllLotNumberDocument, options);
      }
export function useFilterAllLotNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterAllLotNumberQuery, FilterAllLotNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterAllLotNumberQuery, FilterAllLotNumberQueryVariables>(FilterAllLotNumberDocument, options);
        }
export type FilterAllLotNumberQueryHookResult = ReturnType<typeof useFilterAllLotNumberQuery>;
export type FilterAllLotNumberLazyQueryHookResult = ReturnType<typeof useFilterAllLotNumberLazyQuery>;
export type FilterAllLotNumberQueryResult = Apollo.QueryResult<FilterAllLotNumberQuery, FilterAllLotNumberQueryVariables>;
export const FilterLotNumberDocument = gql`
    query FilterLotNumber($page: Int!, $take: Int!, $filter: String!, $productId: Int!, $warehouseId: Int!) {
  in {
    filterLotNumber(
      page: $page
      take: $take
      filter: $filter
      productId: $productId
      warehouseId: $warehouseId
    ) {
      total
      data {
        id
        product {
          id
          cd
          name
        }
        lotNbr
        startDate
        endDate
        availableQty
      }
    }
  }
}
    `;

/**
 * __useFilterLotNumberQuery__
 *
 * To run a query within a React component, call `useFilterLotNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterLotNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterLotNumberQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      productId: // value for 'productId'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useFilterLotNumberQuery(baseOptions: Apollo.QueryHookOptions<FilterLotNumberQuery, FilterLotNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterLotNumberQuery, FilterLotNumberQueryVariables>(FilterLotNumberDocument, options);
      }
export function useFilterLotNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterLotNumberQuery, FilterLotNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterLotNumberQuery, FilterLotNumberQueryVariables>(FilterLotNumberDocument, options);
        }
export type FilterLotNumberQueryHookResult = ReturnType<typeof useFilterLotNumberQuery>;
export type FilterLotNumberLazyQueryHookResult = ReturnType<typeof useFilterLotNumberLazyQuery>;
export type FilterLotNumberQueryResult = Apollo.QueryResult<FilterLotNumberQuery, FilterLotNumberQueryVariables>;
export const ProcessTransactionDocument = gql`
    mutation ProcessTransaction($id: Int!, $source: TransactionSource!) {
  in {
    processTransaction(id: $id, source: $source) {
      success
      message {
        code
        message
      }
      content {
        ...TransactionFragment
      }
    }
  }
}
    ${TransactionFragmentDoc}`;
export type ProcessTransactionMutationFn = Apollo.MutationFunction<ProcessTransactionMutation, ProcessTransactionMutationVariables>;

/**
 * __useProcessTransactionMutation__
 *
 * To run a mutation, you first call `useProcessTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processTransactionMutation, { data, loading, error }] = useProcessTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useProcessTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ProcessTransactionMutation, ProcessTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessTransactionMutation, ProcessTransactionMutationVariables>(ProcessTransactionDocument, options);
      }
export type ProcessTransactionMutationHookResult = ReturnType<typeof useProcessTransactionMutation>;
export type ProcessTransactionMutationResult = Apollo.MutationResult<ProcessTransactionMutation>;
export type ProcessTransactionMutationOptions = Apollo.BaseMutationOptions<ProcessTransactionMutation, ProcessTransactionMutationVariables>;
export const PurchaseReceiptTransactionDocument = gql`
    query PurchaseReceiptTransaction($id: Int!) {
  in {
    purchaseReceiptTransaction(id: $id) {
      total
      data {
        id
        cd
        description
        transactionDate
        source
        status
        salesOrderId
        purchaseReceiptId
        salesOrder {
          id
          cd
        }
        distributor {
          id
          cd
          name
        }
        creator {
          id
          name
        }
        fromWarehouse {
          id
          name
        }
        toWarehouse {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePurchaseReceiptTransactionQuery__
 *
 * To run a query within a React component, call `usePurchaseReceiptTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReceiptTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReceiptTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePurchaseReceiptTransactionQuery(baseOptions: Apollo.QueryHookOptions<PurchaseReceiptTransactionQuery, PurchaseReceiptTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseReceiptTransactionQuery, PurchaseReceiptTransactionQueryVariables>(PurchaseReceiptTransactionDocument, options);
      }
export function usePurchaseReceiptTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseReceiptTransactionQuery, PurchaseReceiptTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseReceiptTransactionQuery, PurchaseReceiptTransactionQueryVariables>(PurchaseReceiptTransactionDocument, options);
        }
export type PurchaseReceiptTransactionQueryHookResult = ReturnType<typeof usePurchaseReceiptTransactionQuery>;
export type PurchaseReceiptTransactionLazyQueryHookResult = ReturnType<typeof usePurchaseReceiptTransactionLazyQuery>;
export type PurchaseReceiptTransactionQueryResult = Apollo.QueryResult<PurchaseReceiptTransactionQuery, PurchaseReceiptTransactionQueryVariables>;
export const SaleOrderTransactionDocument = gql`
    query SaleOrderTransaction($id: Int!) {
  in {
    saleOrderTransaction(id: $id) {
      total
      data {
        id
        cd
        description
        transactionDate
        source
        status
        salesOrderId
        purchaseReceiptId
        salesOrder {
          id
          cd
        }
        distributor {
          id
          cd
          name
        }
        creator {
          id
          name
        }
        fromWarehouse {
          id
          name
        }
        toWarehouse {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSaleOrderTransactionQuery__
 *
 * To run a query within a React component, call `useSaleOrderTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleOrderTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleOrderTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaleOrderTransactionQuery(baseOptions: Apollo.QueryHookOptions<SaleOrderTransactionQuery, SaleOrderTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaleOrderTransactionQuery, SaleOrderTransactionQueryVariables>(SaleOrderTransactionDocument, options);
      }
export function useSaleOrderTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaleOrderTransactionQuery, SaleOrderTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaleOrderTransactionQuery, SaleOrderTransactionQueryVariables>(SaleOrderTransactionDocument, options);
        }
export type SaleOrderTransactionQueryHookResult = ReturnType<typeof useSaleOrderTransactionQuery>;
export type SaleOrderTransactionLazyQueryHookResult = ReturnType<typeof useSaleOrderTransactionLazyQuery>;
export type SaleOrderTransactionQueryResult = Apollo.QueryResult<SaleOrderTransactionQuery, SaleOrderTransactionQueryVariables>;
export const TransactionListDocument = gql`
    query TransactionList($page: Int!, $take: Int!, $distributorCd: String, $source: TransactionSource, $status: TransactionStatus, $startDate: Date, $endDate: Date, $creatorCd: String, $productCd: String, $transactionCd: String) {
  in {
    transactionList(
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      distributorCd: $distributorCd
      source: $source
      status: $status
      creatorCd: $creatorCd
      productCd: $productCd
      transactionCd: $transactionCd
    ) {
      total
      data {
        id
        cd
        description
        transactionDate
        source
        status
        salesOrderId
        purchaseReceiptId
        reason {
          id
          name
          description
        }
        salesOrder {
          id
          cd
        }
        distributor {
          id
          cd
          name
        }
        creator {
          id
          name
        }
        fromWarehouse {
          id
          name
        }
        toWarehouse {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTransactionListQuery__
 *
 * To run a query within a React component, call `useTransactionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorCd: // value for 'distributorCd'
 *      source: // value for 'source'
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      creatorCd: // value for 'creatorCd'
 *      productCd: // value for 'productCd'
 *      transactionCd: // value for 'transactionCd'
 *   },
 * });
 */
export function useTransactionListQuery(baseOptions: Apollo.QueryHookOptions<TransactionListQuery, TransactionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionListQuery, TransactionListQueryVariables>(TransactionListDocument, options);
      }
export function useTransactionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionListQuery, TransactionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionListQuery, TransactionListQueryVariables>(TransactionListDocument, options);
        }
export type TransactionListQueryHookResult = ReturnType<typeof useTransactionListQuery>;
export type TransactionListLazyQueryHookResult = ReturnType<typeof useTransactionListLazyQuery>;
export type TransactionListQueryResult = Apollo.QueryResult<TransactionListQuery, TransactionListQueryVariables>;
export const TransactionProcessListDocument = gql`
    query TransactionProcessList($page: Int!, $take: Int!, $distributorCd: String, $source: TransactionSource!, $action: ActionType!, $startDate: Date, $endDate: Date, $creatorCd: String, $productCd: String, $transactionCd: String) {
  in {
    transactionProcessList(
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      distributorCd: $distributorCd
      source: $source
      action: $action
      creatorCd: $creatorCd
      productCd: $productCd
      transactionCd: $transactionCd
    ) {
      total
      data {
        id
        cd
        description
        transactionDate
        source
        status
        salesOrderId
        purchaseReceiptId
        salesOrder {
          id
          cd
        }
        distributor {
          id
          cd
          name
        }
        creator {
          id
          name
        }
        fromWarehouse {
          id
          name
        }
        toWarehouse {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTransactionProcessListQuery__
 *
 * To run a query within a React component, call `useTransactionProcessListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionProcessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionProcessListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      distributorCd: // value for 'distributorCd'
 *      source: // value for 'source'
 *      action: // value for 'action'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      creatorCd: // value for 'creatorCd'
 *      productCd: // value for 'productCd'
 *      transactionCd: // value for 'transactionCd'
 *   },
 * });
 */
export function useTransactionProcessListQuery(baseOptions: Apollo.QueryHookOptions<TransactionProcessListQuery, TransactionProcessListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionProcessListQuery, TransactionProcessListQueryVariables>(TransactionProcessListDocument, options);
      }
export function useTransactionProcessListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionProcessListQuery, TransactionProcessListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionProcessListQuery, TransactionProcessListQueryVariables>(TransactionProcessListDocument, options);
        }
export type TransactionProcessListQueryHookResult = ReturnType<typeof useTransactionProcessListQuery>;
export type TransactionProcessListLazyQueryHookResult = ReturnType<typeof useTransactionProcessListLazyQuery>;
export type TransactionProcessListQueryResult = Apollo.QueryResult<TransactionProcessListQuery, TransactionProcessListQueryVariables>;
export const TransactionDocument = gql`
    query Transaction($id: Int!, $source: TransactionSource) {
  in {
    transaction(id: $id, source: $source) {
      ...TransactionFragment
    }
  }
}
    ${TransactionFragmentDoc}`;

/**
 * __useTransactionQuery__
 *
 * To run a query within a React component, call `useTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useTransactionQuery(baseOptions: Apollo.QueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
      }
export function useTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
        }
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<typeof useTransactionLazyQuery>;
export type TransactionQueryResult = Apollo.QueryResult<TransactionQuery, TransactionQueryVariables>;
export const CloseVisualDocument = gql`
    mutation CloseVisual($id: Int!) {
  p {
    closeVisual(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type CloseVisualMutationFn = Apollo.MutationFunction<CloseVisualMutation, CloseVisualMutationVariables>;

/**
 * __useCloseVisualMutation__
 *
 * To run a mutation, you first call `useCloseVisualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseVisualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeVisualMutation, { data, loading, error }] = useCloseVisualMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseVisualMutation(baseOptions?: Apollo.MutationHookOptions<CloseVisualMutation, CloseVisualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseVisualMutation, CloseVisualMutationVariables>(CloseVisualDocument, options);
      }
export type CloseVisualMutationHookResult = ReturnType<typeof useCloseVisualMutation>;
export type CloseVisualMutationResult = Apollo.MutationResult<CloseVisualMutation>;
export type CloseVisualMutationOptions = Apollo.BaseMutationOptions<CloseVisualMutation, CloseVisualMutationVariables>;
export const CreateOrUpdateVisualDocument = gql`
    mutation CreateOrUpdateVisual($data: VisualInput!) {
  p {
    createOrUpdateVisual(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...VisualFragment
      }
    }
  }
}
    ${VisualFragmentDoc}`;
export type CreateOrUpdateVisualMutationFn = Apollo.MutationFunction<CreateOrUpdateVisualMutation, CreateOrUpdateVisualMutationVariables>;

/**
 * __useCreateOrUpdateVisualMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateVisualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateVisualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateVisualMutation, { data, loading, error }] = useCreateOrUpdateVisualMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateVisualMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateVisualMutation, CreateOrUpdateVisualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateVisualMutation, CreateOrUpdateVisualMutationVariables>(CreateOrUpdateVisualDocument, options);
      }
export type CreateOrUpdateVisualMutationHookResult = ReturnType<typeof useCreateOrUpdateVisualMutation>;
export type CreateOrUpdateVisualMutationResult = Apollo.MutationResult<CreateOrUpdateVisualMutation>;
export type CreateOrUpdateVisualMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateVisualMutation, CreateOrUpdateVisualMutationVariables>;
export const DeleteVisualDocument = gql`
    mutation DeleteVisual($id: Int!) {
  p {
    deleteVisual(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteVisualMutationFn = Apollo.MutationFunction<DeleteVisualMutation, DeleteVisualMutationVariables>;

/**
 * __useDeleteVisualMutation__
 *
 * To run a mutation, you first call `useDeleteVisualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisualMutation, { data, loading, error }] = useDeleteVisualMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVisualMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVisualMutation, DeleteVisualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVisualMutation, DeleteVisualMutationVariables>(DeleteVisualDocument, options);
      }
export type DeleteVisualMutationHookResult = ReturnType<typeof useDeleteVisualMutation>;
export type DeleteVisualMutationResult = Apollo.MutationResult<DeleteVisualMutation>;
export type DeleteVisualMutationOptions = Apollo.BaseMutationOptions<DeleteVisualMutation, DeleteVisualMutationVariables>;
export const ReleaseVisualDocument = gql`
    mutation ReleaseVisual($id: Int!) {
  p {
    releaseVisual(id: $id) {
      success
      message {
        code
        message
      }
      content {
        id
        status
      }
    }
  }
}
    `;
export type ReleaseVisualMutationFn = Apollo.MutationFunction<ReleaseVisualMutation, ReleaseVisualMutationVariables>;

/**
 * __useReleaseVisualMutation__
 *
 * To run a mutation, you first call `useReleaseVisualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseVisualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseVisualMutation, { data, loading, error }] = useReleaseVisualMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseVisualMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseVisualMutation, ReleaseVisualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseVisualMutation, ReleaseVisualMutationVariables>(ReleaseVisualDocument, options);
      }
export type ReleaseVisualMutationHookResult = ReturnType<typeof useReleaseVisualMutation>;
export type ReleaseVisualMutationResult = Apollo.MutationResult<ReleaseVisualMutation>;
export type ReleaseVisualMutationOptions = Apollo.BaseMutationOptions<ReleaseVisualMutation, ReleaseVisualMutationVariables>;
export const VisualListDocument = gql`
    query VisualList($page: Int!, $take: Int!, $filter: String, $status: ProgramStatus, $visualCd: String) {
  p {
    visualList(
      page: $page
      take: $take
      filter: $filter
      status: $status
      visualCd: $visualCd
    ) {
      total
      data {
        id
        cd
        status
        name
        description
        startDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useVisualListQuery__
 *
 * To run a query within a React component, call `useVisualListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      visualCd: // value for 'visualCd'
 *   },
 * });
 */
export function useVisualListQuery(baseOptions: Apollo.QueryHookOptions<VisualListQuery, VisualListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualListQuery, VisualListQueryVariables>(VisualListDocument, options);
      }
export function useVisualListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualListQuery, VisualListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualListQuery, VisualListQueryVariables>(VisualListDocument, options);
        }
export type VisualListQueryHookResult = ReturnType<typeof useVisualListQuery>;
export type VisualListLazyQueryHookResult = ReturnType<typeof useVisualListLazyQuery>;
export type VisualListQueryResult = Apollo.QueryResult<VisualListQuery, VisualListQueryVariables>;
export const VisualDocument = gql`
    query Visual($id: Int!) {
  p {
    visual(id: $id) {
      ...VisualFragment
    }
  }
}
    ${VisualFragmentDoc}`;

/**
 * __useVisualQuery__
 *
 * To run a query within a React component, call `useVisualQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisualQuery(baseOptions: Apollo.QueryHookOptions<VisualQuery, VisualQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualQuery, VisualQueryVariables>(VisualDocument, options);
      }
export function useVisualLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualQuery, VisualQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualQuery, VisualQueryVariables>(VisualDocument, options);
        }
export type VisualQueryHookResult = ReturnType<typeof useVisualQuery>;
export type VisualLazyQueryHookResult = ReturnType<typeof useVisualLazyQuery>;
export type VisualQueryResult = Apollo.QueryResult<VisualQuery, VisualQueryVariables>;
export const CreateOrUpdateWorkGroupDocument = gql`
    mutation CreateOrUpdateWorkGroup($data: WorkGroupInput!) {
  md {
    createOrUpdateWorkGroup(data: $data) {
      success
      message {
        code
        message
      }
      content {
        ...WorkGroupFragment
      }
    }
  }
}
    ${WorkGroupFragmentDoc}`;
export type CreateOrUpdateWorkGroupMutationFn = Apollo.MutationFunction<CreateOrUpdateWorkGroupMutation, CreateOrUpdateWorkGroupMutationVariables>;

/**
 * __useCreateOrUpdateWorkGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateWorkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateWorkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateWorkGroupMutation, { data, loading, error }] = useCreateOrUpdateWorkGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateWorkGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateWorkGroupMutation, CreateOrUpdateWorkGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateWorkGroupMutation, CreateOrUpdateWorkGroupMutationVariables>(CreateOrUpdateWorkGroupDocument, options);
      }
export type CreateOrUpdateWorkGroupMutationHookResult = ReturnType<typeof useCreateOrUpdateWorkGroupMutation>;
export type CreateOrUpdateWorkGroupMutationResult = Apollo.MutationResult<CreateOrUpdateWorkGroupMutation>;
export type CreateOrUpdateWorkGroupMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateWorkGroupMutation, CreateOrUpdateWorkGroupMutationVariables>;
export const DeleteWorkGroupDocument = gql`
    mutation DeleteWorkGroup($id: Int!) {
  md {
    deleteWorkGroup(id: $id) {
      success
      message {
        code
        message
      }
      content
    }
  }
}
    `;
export type DeleteWorkGroupMutationFn = Apollo.MutationFunction<DeleteWorkGroupMutation, DeleteWorkGroupMutationVariables>;

/**
 * __useDeleteWorkGroupMutation__
 *
 * To run a mutation, you first call `useDeleteWorkGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkGroupMutation, { data, loading, error }] = useDeleteWorkGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkGroupMutation, DeleteWorkGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkGroupMutation, DeleteWorkGroupMutationVariables>(DeleteWorkGroupDocument, options);
      }
export type DeleteWorkGroupMutationHookResult = ReturnType<typeof useDeleteWorkGroupMutation>;
export type DeleteWorkGroupMutationResult = Apollo.MutationResult<DeleteWorkGroupMutation>;
export type DeleteWorkGroupMutationOptions = Apollo.BaseMutationOptions<DeleteWorkGroupMutation, DeleteWorkGroupMutationVariables>;
export const WorkGroupListDocument = gql`
    query WorkGroupList($page: Int!, $take: Int!, $filter: String) {
  md {
    workGroupList(page: $page, take: $take, filter: $filter) {
      total
      data {
        id
        cd
        name
        workGId
        description
      }
    }
  }
}
    `;

/**
 * __useWorkGroupListQuery__
 *
 * To run a query within a React component, call `useWorkGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkGroupListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWorkGroupListQuery(baseOptions: Apollo.QueryHookOptions<WorkGroupListQuery, WorkGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkGroupListQuery, WorkGroupListQueryVariables>(WorkGroupListDocument, options);
      }
export function useWorkGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkGroupListQuery, WorkGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkGroupListQuery, WorkGroupListQueryVariables>(WorkGroupListDocument, options);
        }
export type WorkGroupListQueryHookResult = ReturnType<typeof useWorkGroupListQuery>;
export type WorkGroupListLazyQueryHookResult = ReturnType<typeof useWorkGroupListLazyQuery>;
export type WorkGroupListQueryResult = Apollo.QueryResult<WorkGroupListQuery, WorkGroupListQueryVariables>;
export const WorkGroupDocument = gql`
    query WorkGroup($id: Int!) {
  md {
    workGroup(id: $id) {
      ...WorkGroupFragment
    }
  }
}
    ${WorkGroupFragmentDoc}`;

/**
 * __useWorkGroupQuery__
 *
 * To run a query within a React component, call `useWorkGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkGroupQuery(baseOptions: Apollo.QueryHookOptions<WorkGroupQuery, WorkGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkGroupQuery, WorkGroupQueryVariables>(WorkGroupDocument, options);
      }
export function useWorkGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkGroupQuery, WorkGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkGroupQuery, WorkGroupQueryVariables>(WorkGroupDocument, options);
        }
export type WorkGroupQueryHookResult = ReturnType<typeof useWorkGroupQuery>;
export type WorkGroupLazyQueryHookResult = ReturnType<typeof useWorkGroupLazyQuery>;
export type WorkGroupQueryResult = Apollo.QueryResult<WorkGroupQuery, WorkGroupQueryVariables>;